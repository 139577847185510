import React, { useRef, useEffect, useState } from "react";
import { Button } from "../ui/button";

const getPhpSessId = (): string => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let [key, value] = cookies[i].split("=");
    key = key.trim();
    if (key === "PHPSESSID") {
      return decodeURIComponent(value.trim());
    }
  }
  return "";
};

async function uploadFile(
  file: File,
  onProgress: (file: File, loaded: number, total: number) => void,
  onSuccess: (file: File, response: any) => void,
  onError: (file: File, errorCode: number, message: string) => void
) {
  const url = "/upload.php?PHPSESSID=" + getPhpSessId();
  const formData = new FormData();
  formData.append("file", file);

  const xhr = new XMLHttpRequest();

  xhr.open("POST", url, true);

  xhr.upload.onprogress = (event: ProgressEvent) => {
    if (event.lengthComputable) {
      onProgress(file, event.loaded, event.total);
    }
  };

  xhr.onload = function () {
    if (xhr.status === 200) {
      const jsonResponse = xhr.responseText;
      onSuccess(file, jsonResponse);
    } else {
      onError(
        file,
        xhr.status,
        "File upload failed with status: " + xhr.status
      );
    }
  };

  xhr.onerror = function () {
    onError(file, xhr.status, "An error occurred during the file upload.");
  };

  xhr.send(formData);
}

interface UploadMediaProps {
  onProgress: (file: File, loaded: number, total: number) => void;
  onSuccess: (file: File, response: any) => void;
  onError: (file: File, errorCode: number, message: string) => void;
  onStart: (file: File) => void;
}

export const UploadMedia: React.FC<UploadMediaProps> = ({
  onProgress,
  onSuccess,
  onError,
  onStart,
}) => {
  const [inputKey, setInputKey] = useState(0);
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleFileChange = (e: Event) => {
      e.preventDefault();
      const files = fileRef.current?.files;
      if (!files || files.length === 0) {
        return;
      }
      const file = files[0];
      onStart(file);
      uploadFile(
        file,
        onProgress,
        (file, response) => {
          onSuccess(file, response);
          setInputKey((prevKey) => prevKey + 1); // Reset input on success
        },
        (file, errorCode, message) => {
          onError(file, errorCode, message);
          setInputKey((prevKey) => prevKey + 1); // Reset input on error
        }
      );
    };

    const inputElement = fileRef.current;
    if (inputElement) {
      inputElement.addEventListener("change", handleFileChange);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("change", handleFileChange);
      }
    };
  }, [onProgress, onSuccess, onError, onStart, inputKey]);

  const handleClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  return (
    <div>
      <Button onClick={handleClick} label="Upload" />
      <input
        key={inputKey}
        type="file"
        ref={fileRef}
        accept="image/*, .mp4, .swf, .mov, .avi, .wmv, .mkv, .mp3, .flv, .f4v, .pdf, .mid, .zip"
        style={{ display: "none" }} // Hide the file input
      />
    </div>
  );
};
