IG.ChannelList = function() {
    var _this = this;
    this.query = "";
    this.searchLang = IG.customerConfig.defaultContentLanguage;
    this.defaultSearchVal = IG.label.get("info_object_list_enter_search_term");


    this.config = {
        hashkey: "channels"
    };

    this.hash = function() {
        return _this.config.hashkey;
    };


    this.refresh = function() {
        _this.render();
    };



    /* Creates and shows the preview qtip dialog: */
    this.buildPreviewDialogContent = function() {
        //Make sure to completely remove any previously created instances:
        var objDiv = $("#preview-div").get(0);
        if (objDiv) {
            $(objDiv).remove();
        }

        //Then (re)create it:
        objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "preview-div")
            .addClass("preview");


        //Build contents wrapper:
        var objDivContent = document.createElement("div");
        $(objDivContent).addClass("content clearfix");


        //Build block for selects:
        var objDivOptions = document.createElement("div");
        $(objDivOptions).addClass("options");


        //Build ratio select:
        var objSelectRatio = document.createElement("select");
        $(objSelectRatio).attr({
            "id": "preview-ratio",
            "name": "ratio"
        });

        $.each(IG.customerConfig.previewAspectRatios, function(i, n) {
            var objOption = document.createElement("option");
            $(objOption)
                .text(i)
                .val(i);

            $(objSelectRatio).append(objOption);
        });



        //Build resolution select:
        var objSelectRes = document.createElement("select");
        $(objSelectRes).attr({
            "id": "preview-resolution",
            "name": "resolution"
        });

        //Populate with available resolutions for selected ratio:
        $.each(IG.gui.checkSizes($(objSelectRatio).val()), function(i, n) {
            $(objSelectRes).append(n);
        });


        //Re-populate with available resolutions when changing ratio:
        $(objSelectRatio).change(function() {
            var objSel = $("#preview-resolution").get(0);
            if (objSel) {
                $(objSel).html("");

                var v = $(this).val();
                var resolutions = IG.gui.checkSizes(v);

                $.each(resolutions, function(i, n) {
                    $(objSel).append(n);
                });
            }
        });






        //Build gallery select:
        var objSelectGall = document.createElement("select");
        $(objSelectGall)
            .attr({
                'name': "gallery",
                'id': 'preview-gallery-select'
            });

        //Populate gallery select with available galleries:
        //Sort galleries by their names
        var sorted = _.sortBy(IG.lists.galleries, 'name.' + IG.config.language);
        $.each(sorted, function(i, n) {
            var objOption = document.createElement("option");
            $(objOption)
                .val(n.id['id'])
                .text(n.name[IG.config.language]);

            $(objSelectGall).append(objOption);
        });

        $(objDivOptions).append([objSelectRatio, objSelectRes, objSelectGall]);
        $(objDivContent).append(objDivOptions);
        $(objDiv).append(objDivContent);

        return objDiv;
    };


    this.render = function() {
        var bAdmin = IG.userHasPermission("OP_CHANNEL_ADMIN");
        var bCreate = IG.userHasPermission("OP_CHANNEL_CREATE") || bAdmin;


        //Create ObjectList:
        var IGObjectList = new IG.ObjectList({
            methodName: "searchChannels",
            page: this.config.hashkey
        });



        var objDiv = document.createElement("div");
        $(objDiv).attr("id", "ig-channels");


        var objH2 = document.createElement("h2");
        $(objH2)
            .text(IG.label.get("dashboard_channels"))
            .appendTo(objDiv);

        //SEARCHBAR :
        var objSearch = document.createElement("div");
        $(objSearch)
            .addClass("ig-search")
            .attr("id", "ig-channels-search");

        var displayVal = (_this.query != '' ? _this.query : _this.defaultSearchVal);
        var searchLang = _this.searchLang;

        var objSearchInput = document.createElement("input");
        $(objSearchInput)
            .attr({
                "type": "text",
                "name": "search-channels",
                "id": "search-channels",
                "value": displayVal
            })
            .addClass("search")
		    .click(function() {
		        if (_this.defaultSearchVal == $(this).val()) $(this).val('');
		        //some decoration stuff
		        $(this).removeClass('search-default-deco');
		        $(this).addClass('search-default');
		    })
            .blur(function() {
                if ($(this).val() == '') $(this).val(_this.defaultSearchVal);
                $(this).removeClass('search-default');
                $(this).addClass('search-default-deco');
            })
            .keyup(function(e) {
                var v = $(this).val();
                var key = e.which ? e.which : e.keyCode;
                if (key == 13) {
                    _this.query = (v != _this.defaultSearchVal ? v : '');
                    _this.searchLang = searchLang;
                    if (searchLang != IG.customerConfig.defaultContentLanguage) {
                        $(objLangSel).addClass('search-lang-deco');
                    } else $(objLangSel).removeClass('search-lang-deco');
                    
                    IGObjectList.setQuery(_this.query, _this.searchLang);
                    IGObjectList.refresh();

                    //_this.refresh({ history: true });
                }
            })
            .appendTo(objSearch);
        
        
        var objLangSel = IGObjectList.renderSearchLanguageSelector({
        	onChange: function(n) {
        		searchLang = n;
        	},
        	searchLang: searchLang
        });
        if (objLangSel) $(objSearch).append(objLangSel);
        
        //Build search button:
        var btnFind = document.createElement("a");
        $(btnFind)
            .attr('href', '#search')
            .addClass("btn-normal btn-dark")
            .html('<span>' + IG.label.get("channel_list_search") + '</span>') //'Søg'
            .click(function() {
                var v = $(objSearchInput).val();
                _this.query = (v != _this.defaultSearchVal ? v : '');
                _this.searchLang = searchLang;
                if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                    $(objLangSel).addClass('search-lang-deco');
                } else $(objLangSel).removeClass('search-lang-deco');
                IGObjectList.setQuery(_this.query, _this.searchLang);
                IGObjectList.refresh();

                //_this.refresh({ history: true });

                return false;
            })
            .appendTo(objSearch);


        //Build "create channel" button (iff user has permission to do so):
        if (bCreate) {
            var btnCreate = document.createElement("a");
            $(btnCreate)
                .addClass("btn-normal btn-pink btn-right")
                .html('<span>' + IG.label.get("channel_list_create_new_channel") + '</span>') //Opret kanal
                .click(function() {
                    IG.create.channel({
                        success: function(object) {
                            IG.showEditChannel({
                                object: object,
                                isNew: true,
                                history: true
                            });
                        },
                        error: function(err) {
                            IG.showErrorDialog(err);
                        }
                    });
                    return false;
                })
                .appendTo(objSearch);
        }

        $(objDiv).append(objSearch);

        var clearDiv = document.createElement("div");
        $(clearDiv)
            .css("clear", "both")
            .appendTo(objSearch);

        var minHeight = 330;
        var initHeight = $(window).height() - 220 - 84 - 63;
        initHeight = ( initHeight < minHeight ? minHeight : initHeight );

        //Determine available height for channels list:
        var fnGetAvailableHeight = function(obj) {
            var h = $(window).height() - $(obj).offset().top - $('#ig-footer').outerHeight() - 84; //ish...
            if (h < minHeight) {
                h = minHeight;
            }
            return h;
        };

        var objDivList = document.createElement("div");
        $(objDivList)
            .attr("id", "ig-channels-list")
            .addClass('hubber-list-wrap')
            .height( initHeight )
            .css("clear", "both")
            .appendTo(objDiv);


        $(window).resize(function() {
            $(objDivList).height(fnGetAvailableHeight(objDivList));
        });


        IGObjectList.config.parentObject = objDivList;

        //FIXME: define tips



        var fnBuildDeleteButton = function(item) {
            //May only delete if specific permission is set or if user is channel admin and the channel in question is not shared from another customer):
            if (IG.userHasPermission("OP_CHANNEL_DELETE", item) || bAdmin && (item.id.customerId == IG.config.customerId)) {
                var objA = document.createElement("a");
                $(objA)
                    .addClass("btn-small btn-dark")
                    .html('<span>' + IG.label.get("delete") + '</span>') // 'Delete'
                    .click(function() {
                        var _me = this;

                        var objDialog = IG.createConfirmDialog({
                            title: IG.label.get("channel_list_delete_popup_title"), //"Delete channel?
                            text: item.name[IG.config.language],
                            onConfirm: function() {
                                IG.remove.channel({
                                    id: item.id,
                                    success: function() {
                                        //Remove table row:
                                        IGObjectList.removeRow(item.id);
                                        objDialog.close();

                                    },
                                    error: function(err) {
                                        objDialog.close();
                                        IG.showErrorDialog("Error deleting channel: " + err);
                                    }
                                });
                            },
                            target: $(_me)
                        });

                        objDialog.show();
                    });
                return objA;
            }
            return null;
        };

        var fnBuildPreviewButton = function(item) {
            var objA = document.createElement("a");
            $(objA)
                .addClass("btn-small btn-light")
                .html('<span>' + IG.label.get("channel_list_channel_preview") + '</span>') //Preview
                .click(function() {
                    var _me = this;

                    $( "#modal-window" )
                        .html(_this.buildPreviewDialogContent())
                        .dialog({
                            modal: true,
                            position: { my: "center", at: "center", of: window, collision: 'fit' },
                            width: 300,
                            height: 'auto',
                            buttons: [
                                {
                                    text: IG.label.get("global_preview"),
                                    click: function() {
                                        //Open preview in overlay of proper size:
                                        var ratio = $("#preview-ratio").val();
                                        var resolution = $("#preview-resolution").val();

                                        var rx = /^(\d+)[^\d](\d+)$/i;
                                        var w = 0;
                                        var h = 0;

                                        //Specific resolution:
                                        if (rx.test(resolution)) {
                                            w = parseInt(resolution.replace(rx, "$1"), 10);
                                            h = parseInt(resolution.replace(rx, "$2"), 10);
                                        }
                                        //Fullscreen:
                                        else {
                                            //Adjust to match aspect ratio and fit inside screen:

                                            w = $(window).width() - 100;
                                            h = $(window).height() - 100;


                                            var ratX = parseInt(ratio.replace(rx, "$1"), 10);
                                            var ratY = parseInt(ratio.replace(rx, "$2"), 10);

                                            var rat = ratX / ratY;
                                            var ratWin = w / h;

                                            //Window wider than that of selected aspect ratio:
                                            if (ratWin > rat) {
                                                //Fit to height:
                                                w = h * rat;
                                            }
                                            //Window narrower than that of selected aspect ratio:
                                            else if (ratWin < rat) {
                                                //Fit to width:
                                                h = w / rat;
                                            }

                                            w = Math.round(w);
                                            h = Math.round(h);
                                        }

                                        var selectedGalleryId = $('#preview-gallery-select').val();
                                        var objGallery = IG.getGalleryById(selectedGalleryId, function(objGallery) {
                                            if (objGallery != null) {
                                                IG.urls.preview.channel(objGallery.id, item.id, w, h, function(url) {
                                                    $( "#modal-window").dialog('close');
                                                    if (url !== null) {
                                                        IG.showPreview({
                                                            href: url,
                                                            width: w,
                                                            height: h
                                                        });
                                                    } else {
                                                        IG.showErrorDialog(IG.label.get('gallery_missing_modules'));
                                                    }
                                                });
                                            }
                                        });
                                        return false;
                                    }
                                }
                            ],

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 150,
                            maxHeight: false,
                            title: ''
                        });

                    return false;
                });
            return objA;
        };

        var fnBuildRSSButton = function(item) {
            var objImg = document.createElement("img");
            $(objImg).attr({
                "src": "/images/rss.png",
                "alt": "RSS",
                "width": 14,
                "height": 14
            });
            $(objImg).click(function() {
                window.open(IG.urls.rss(item.id));
                return false;
            });

            return objImg;
        };

        
        IGObjectList.config.columns = [
            {
                name: "name",
                title: IG.label.get("channel_list_channel_name"), // "Kanalens navn",
                className: "title ellipsis",
                sortable: true,
                width: "auto",
                render: function(object) {
                    var s;
                    if (typeof object.name[_this.searchLang] === 'undefined') {
                        s = _.first(_.values(object.name));
                    }
                    else {
                        s = IG.fn.stripHTML(object.name[_this.searchLang]);
                    }

                    var objA = document.createElement("a");
                    $(objA)
                        .text(s)
                        .attr("title", s)
                        .click(function() {
                            IG.showEditChannel({
                                id: object.id["id"],
                                history: true,
                                searchLang: _this.searchLang
                            });
                            return false;
                        });
                    return objA;
                }
            },
            {
                name: "customerId", //FIXME: sorting name?
                title: IG.label.get("channel_list_channel_type"), //Type
                className: "",
                sortable: true,
                width: "110px",
                render: function(object) {
                    var ordinary = IG.label.get("channel_list_channel_type_ordinary");
                    var shared = IG.label.get("channel_list_channel_type_shared");
                    return object.id.customerId == IG.config.customerId ? ordinary : shared;
                }
            },
            {
                name: "", //FIXME: sorting name?
                title: IG.label.get("channel_list_no_of_elements"),  //"Elementer"
                className: "numeric",
                sortable: false,
                width: "110px",
                render: function(object) {
                    return object.infoObjectCount;
                }
            },
            {
                name: "", //FIXME: sorting name?
                title: IG.label.get("channel_list_shared_with"), // "Delt med",
                className: "numeric",
                sortable: false,
                width: "80px",
                render: function(object) {
                    return object.sharedWithCustomerIds.length > 0 ? object.sharedWithCustomerIds.length : IG.label.get("none");
                }
            },
            {
                name: "",
                title: "",
                className: "buttons",
                sortable: false,
                width: "170px",
                render: function(object) {
                    var btnDelete = fnBuildDeleteButton(object);
                    var btnPreview = fnBuildPreviewButton(object);
                    var btnRSS = fnBuildRSSButton(object);

                    var res = [];
                    if (btnRSS != null) {
                        res.push(btnRSS);
                    }
                    if (btnPreview != null) {
                        res.push(btnPreview);
                    }
                    if (btnDelete != null) {
                        res.push(btnDelete);
                    }

                    return res;
                }
            }
        ];

        //Initialize list and populate main contents:
        IGObjectList.setSorting('name', 'ASC');
        IGObjectList.init(function() {
            $("#ig-data").html(objDiv);
            IG.gui.loading.hide();

         // Focus the search field:
            if (_this.query != _this.defaultSearchVal && _this.query != '') {
            	$(objSearchInput).focus();
            	$(objSearchInput).removeClass('search-default-deco');
		        $(objSearchInput).addClass('search-default');
            }
        });

        // Filter results if there was a previous search string
        if (_this.query != _this.defaultSearchVal && _this.query != '' || _this.searchLang != IG.customerConfig.defaultContentLanguage) {
        	IGObjectList.setQuery(_this.query, _this.searchLang);
        } else {
        	_this.searchLang = IG.customerConfig.defaultContentLanguage;
        }
    };



    this.init = function() {
        _this.render();
        $.address.value(_this.config.hashkey);
    };

    return _this;
};


IG.showChannelList = function(options) {
    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);
    
    // Google Analytics:
    // IG.config.prodMode ? _gaq.push(['_trackPageview']) : null;
    
    IG.gui.loading.show();

    //Create channel list if needed:
    var objChannelList = _IG.objects.get("channellist");
    if (objChannelList == null) {
        objChannelList = _IG.objects.create("channellist", new _IG.ChannelList());
        objChannelList.init();
    }
    else {
        objChannelList.refresh({ history: false });
    }

    IG.gui.setTitle(IG.label.get("dashboard_channels"));

    //Add to history:
    if (options.history) {
        _IG.location.setCurrent(objChannelList.hash());
    }

    $('#ig-user-menu-sections').find('a.channels').parent().addClass('menu-active');
};
