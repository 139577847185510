/* Flash editor controller: */
import React from 'react'
import { createRoot } from "react-dom/client";
import {TextEditor, SinglelineEditor} from './components/TextEditor/text-editor';

IG.EditorController = {
    //Collection of active editors:
    editors: [],

    //Registers a new IG.Editor:
    register: function(editor) {
        if (editor != null) {
            this.editors.push(editor);
            editor.render();
        }
    },

    //Sets the current language for "multi-valued" editors:
    setLanguage: function(language) {
        $.each(this.editors, function(i, n) {
            if (n.options.texts != null) {
                n.setLanguage(language);
            }
        });
    },

    //Gets the text for the editor with id id:    
    getText: function(id) {
        var e = this.getEditorById(id);
        if (e != null) {
            var s = e.getText();
            // s = IG.Base64.decode(s);
            return s;
        }
        return "";
    },

    //Sets the text s for the editor with id k:
    setText: function(k, s) {
        var e = this.getEditorById(k);
        if (e != null) {
            e.setText(s);
            //Force onchange event for editor:
            e.onChange(s);
        }
    },

    //Returns the editor given id:
    getEditorById: function(id) {
        var res = null;
        $.each(this.editors, function(i, n) {
            if (n.id == id) {
                res = n;
                return false; //break loop;
            }
        });
        return res;
    },

    //Return the editor given id:
    getEditor: function(id) {
        return this.getEditorById(id);
    },

    //Handle "ready" callback event for the editor with provided id:
    ready: function(id) {
        var objEditor = this.getEditor(id);
        if (objEditor != null) {
            if (!objEditor.ready) {
                objEditor.init();
            }
            else {
                objEditor.reInit();
            }
        }
        else {

        }
    },

    //Handle "receivedFocus" callback event for the editor with provided id:
    receivedFocus: function(id) {
        $.each(this.editors, function(i, n) {
            //Remove focus from other editors:
            if (n.id != id) {
                n.killFocus();
            }
        });
    },

    //Handle "textChanged" callback event for the editor with provided id:
    textChanged: function(id, s) {
        var objEditor = this.getEditor(id);
        if (objEditor != null) {
            s = IG.Base64.decode(s);
            //console.log("CHANGED", s.replace(new RegExp("\u00AD", 'g'), "-"))
            objEditor.onChange(s);
        }
    },

    //Handle "nextEditor" callback event for the editor with provided id on tab:
    nextEditor: function(id, goBack) {
        
        var $me = $('#'+id);
        if (goBack) var d = -1;
        else var d = 1;
        var next = $(".input-editor:eq(" + ($(".input-editor").index($me[0]) + d) + ")");
        if (next.length != 0 && next!=$me) {
            IG_editorReceivedFocus(next.id);
            var fl = swfobject.getObjectById(next[0].id);
            fl.tabIndex = 0;
            fl.focus();
        } else {
            next = $(":focusable:eq(" + ($(":focusable").index($me[0]) + d) + ")").focus();
        }
    },

    destroyEditors: function() {
        $.each(this.editors, function(i, n) {
            n.destroy();
        });
        this.editors = [];
    },

    destroyEditorById: function(id) {
        var _this = this;
        $.each(_this.editors, function(i, n) {
            if (id == n.id) {
                n.destroy();
                _this.editors.splice(i,1);
            }
        });
    }
};

IG.Base64 = {
    keyString: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

    _UTF8Encode: function(string) {
        string = string.replace(/\x0d\x0a/g, "\x0a");
        var output = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                output += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                output += String.fromCharCode((c >> 6) | 192);
                output += String.fromCharCode((c & 63) | 128);
            } else {
                output += String.fromCharCode((c >> 12) | 224);
                output += String.fromCharCode(((c >> 6) & 63) | 128);
                output += String.fromCharCode((c & 63) | 128);
            }
        }
        return output;
    },

    _UTF8Decode: function(input) {
        var string = "";
        var i = 0;
        var c1, c2, c3;
        var c = c1 = c2 = 0;
        while ( i < input.length ) {
            c = input.charCodeAt(i);
            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if ((c > 191) && (c < 224)) {
                c2 = input.charCodeAt(i+1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = input.charCodeAt(i+1);
                c3 = input.charCodeAt(i+2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    },

    encode: function(input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        input = this._UTF8Encode(input);
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output + this.keyString.charAt(enc1) + this.keyString.charAt(enc2) + this.keyString.charAt(enc3) + this.keyString.charAt(enc4);
        }
        return output;
    },
    decode: function(input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
            enc1 = this.keyString.indexOf(input.charAt(i++));
            enc2 = this.keyString.indexOf(input.charAt(i++));
            enc3 = this.keyString.indexOf(input.charAt(i++));
            enc4 = this.keyString.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }
        output = this._UTF8Decode(output);
        return output;
    }
};

/* Handle flash editor callbacks: */
window.IG_ready = function(id) {
    IG.EditorController.ready(id);
}

window.IG_debug = function(message) {
    //console.log("IG_DEBUG: "+IG.Base64.decode(message));
    //IG.debug("IG_debug: " + message);
}

window.IG_editorReceivedFocus = function(id) {
    IG.EditorController.receivedFocus(id);
}

window.IG_textChanged = function(id, s) {
    IG.EditorController.textChanged(id, s);
}

window.IG_nextEditor = function(id, goBack) {
    IG.EditorController.nextEditor(id, goBack);
}


/* Flash editor instance: */
IG.Editor = function(options) {
    var _this = this;
    this.initialized = false;
    this.ready = false;

    this.flash = IG.flashText.enabled;
    this.innerContainer = null; //For non-flash
    this.id = "some_random_non-existing-id-234567645234";
    this.key = "";

    this.config = {
        swfVersion: "10.1.0",
        installUrl: IG.urls.editor.install,
        swfUrl: IG.urls.editor.flash
    };



    this.options = (typeof options == "object" ? options : {});

    //Key for identification:       //deprecated
    this.key = (typeof options.key == "string" ? options.key : this.key);              //deprecated

    //Parent DOM element:
    this.options.container = (typeof options.container != "undefined" ? options.container : null);

    //Initial text:
    this.currentText = this.options.text = (typeof options.text == "string" ? options.text : "");


    //Set related object holding texts (fx. headline, subheadline, body) with an entry for each language:
    this.options.texts = (typeof options.texts != "undefined" ? options.texts : null);

    //Sets default language (if handling multiple texts):
    this.options.language = (typeof options.language == "string" ? options.language : IG.config.language);


    //Initial dimensions:
    this.options.width = (typeof options.width != "undefined" ? options.width : "100%");
    this.options.height = (typeof options.height != "undefined" ? options.height : "39");

    //Singleline editor? Also disableds text formatting options
    this.options.singleline = (typeof options.singleline == "boolean" ? options.singleline : false);

    //Enable formatting options?
    this.options.formatting = (options.singleline ? false : (typeof options.singleline == "boolean" ? !options.singleline : false));

    //Enable soft hyphen button?
    this.options.enableSoftHyphen = (options.singleline ? false : (typeof options.enableSoftHyphen == "boolean" ? options.enableSoftHyphen : false));

    //Sets onchange function:
    this.options.onchange = (typeof options.onchange == "function" ? options.onchange : function() { });

    //Set class
    this.options.cssClass = (typeof options.cssClass == "string" ? options.cssClass : '');

    //Request focus - attempts to set focus on this input on load if true
    this.options.requestfocus = (typeof options.requestfocus == "boolean" ? options.requestfocus : false);

    // Determines the way the plugin is rendered. Transparent ommits the setFocus events
    this.options.wmode = (typeof BrowserDetect=="undefined"||BrowserDetect.browser=='Explorer'||BrowserDetect.browser=='Firefox'||BrowserDetect.browser=='Chrome'||BrowserDetect.browser=='Mozilla' ? 'opaque' : 'transparent');


    //Sets the current language (also changes currently viewed text):
    this.setLanguage = function(language) {
        _this.options.language = language;
        if (_this.options.texts != null && typeof _this.options.texts[_this.options.language] !== "undefined") {
            _this.setText(_this.options.texts[language]);
        }
        else {
            _this.setText(_this.options.text);
        }
    };

    //Gets the current text from flash editor:
    this.getText = function() {
        var s = "";

        if (_this.flash) {
            try {
                s = document.getElementById(_this.id).getText();
            }
            catch (e) {

            }
        }
        else {
            s = _this.currentText
        }

        return s;
    };

    //Sets the text in flash editor:
    this.setText = function(s) {
        if (!_this.options.formatting) {
            s = IG.fn.stripHTML(s);
        }

        if (_this.flash) {
            try {
                var s64 = IG.Base64.encode(s);
                var element = document.getElementById(_this.id);
                if (element !== null) {
                    element.setText(s64);
                }
            }
            catch (e) {
                console.error(e);
            }
        } else {
            $(_this.innerContainer).html(s != "" ? s : "&nbsp;");
        }

        // if (_this.options.requestfocus) {
        //     IG_editorReceivedFocus(_this.id);
        //     var fl = swfobject.getObjectById(_this.id);
        //     fl.tabIndex = 0;
        //     fl.focus();
        // }
    };

    //Removes focus from flash editor:
    this.killFocus = function() {
        if (_this.flash) {
            try {
                document.getElementById(_this.id).killFocus();
            }
            catch (e) {

            }
        }
    };

    //Handle changes
    this.onChange = function(s) {
        //Is formatting allowed for this editor?
        if (!_this.options.formatting) {
            //If not, remove any markup:
            s = IG.fn.stripHTML(s);
        }

        //Perform optional callback:
        _this.options.onchange(s, _this.options.language);

        //Update related fields:
        if (_this.options.texts != null && typeof _this.options.texts[_this.options.language] !== "undefined") {
            _this.options.texts[_this.options.language] = s;
        }
        else {
            _this.options.text = s;
        }
        _this.currentText = s;
    };



    //Internal callback when ready:
    this.init = function() {
        _this.ready = true;
        if (_this.options.texts != null && typeof _this.options.texts[_this.options.language] !== "undefined") {
            _this.setText(_this.options.texts[_this.options.language]);
        }
        else {
            _this.setText(_this.options.text);
        }
    };

    //Re-initialize?
    this.reInit = function() {
        if (_this.options.texts != null && typeof _this.options.texts[_this.options.language] !== "undefined") {
            _this.setText(_this.options.texts[_this.options.language]);
        }
        else {
            _this.setText(_this.options.text);
        }
    };

    this.destroy = function() {
        swfobject.removeSWF(_this.id);
    };

    //Renders the SWF using swfobject:
    this.render = function() {
        if (_this.options.container != null) {

            //Is flash supported?
            // if (_this.flash) {

            //     //Determine id for the flash object:
            //     _this.id = $(_this.options.container).attr("id");
            //     if (_this.id == "") {
            //         _this.id = "ig-flash-editor-" + IG.fn.getRand();
            //     }
            //     else {
            //         _this.id += "-" + IG.fn.getRand();
            //     }
            //     $(_this.options.container).attr("name", _this.id);

            //     //Create inner container block for flash object:
            //     var objDiv = document.createElement("div");
            //     $(objDiv)
            //         .attr("id", _this.id)
            //         .appendTo(_this.options.container);

            //     var flashvars = {
            //         id: _this.id,
            //         enableSingleLine: _this.options.singleline,
            //         disableFormattingOptions: !_this.options.formatting,
            //         enableSoftHyphen: _this.options.enableSoftHyphen
            //     };

            //     var params = {
            //         quality: "high",
            //         bgcolor: "#ffffff",
            //         allowScriptAccess: "always",
            //         enablefullscreen: "true",
            //         wmode: _this.options.wmode
            //     };

            //     var attributes = {
            //         id: _this.id,
            //         name: _this.id,
            //         align: "middle",
            //         tabIndex: IG.EditorController.editors.length,
            //         'class': _this.options.cssClass
            //     };

            //     //Embed the flash object:
            //     swfobject.embedSWF(
            //         _this.config.swfUrl,
            //         _this.id,
            //         _this.options.width,
            //         _this.options.height,
            //         _this.config.swfVersion,
            //         _this.config.installUrl,
            //         flashvars,
            //         params,
            //         attributes,
            //         function(e) {
            //             _this.initialized = e.success;
            //         }
            //     );

                
            // }
        
            _this.id = $(_this.options.container).attr("id");
            if (_this.id == "") {
                _this.id = "ig-react-editor-" + IG.fn.getRand();
            }
            else {
                _this.id += "-" + IG.fn.getRand();
            }
            $(_this.options.container).attr("name", _this.id);
            const rootEditorDomNode = createRoot(_this.options.container);
            if (_this.options.singleline) {
                rootEditorDomNode.render(<SinglelineEditor initialContent={_this.options.texts[_this.options.language]} onChange={this.onChange}/>)
            }
            else {
                rootEditorDomNode.render(<TextEditor formatting={_this.options.formatting} initialContent={_this.options.texts[_this.options.language]} onChange={this.onChange}/>)
            }

            _this.initialized = true;
            _this.init();
        }
    };

    return this;
};

