IG.GalleryList = function() {
    var _this = this;
    this.query = "";
    this.defaultSearchVal = IG.label.get("info_object_list_enter_search_term");
    this.searchLang = IG.customerConfig.defaultContentLanguage;

    this.config = {
        hashkey: "galleries"
    }

    this.hash = function() {
        var h = _this.config.hashkey;
        return h;
    };


    this.refresh = function() {
        _this.render();
    };





    this.render = function() {
        var bAdmin = IG.userHasPermission("OP_GALLERY_ADMIN");
        var bCreate = IG.userHasPermission("OP_GALLERY_CREATE") || bAdmin;


        //Create ObjectList:              
        var IGObjectList = new IG.ObjectList({
            methodName: "searchGalleries",
            page: this.config.hashkey
        });

        var objDiv = document.createElement("div");
        $(objDiv).attr("id", "ig-galleries");


        var objH2 = document.createElement("h2");
        $(objH2)
            .text(IG.label.get("dashboard_galleries"))
            .appendTo(objDiv);

        //SEARCHBAR :
        var objSearch = document.createElement("div");
        $(objSearch)
            .addClass("ig-search")
            .attr("id", "ig-galleries-search");

        var displayVal = (_this.query != '' ? _this.query : _this.defaultSearchVal),
            searchLang = _this.searchLang;

        var objSearchInput = document.createElement("input");
        $(objSearchInput)
            .attr({
                "type": "text",
                "name": "search-galleries",
                "id": "search-galleries",
                "value": displayVal
            })
            .addClass("search")
		    .click(function() {
		        if (_this.defaultSearchVal == $(this).val()) $(this).val('');
		        //some decoration stuff
		        $(this).removeClass('search-default-deco');
		        $(this).addClass('search-default');
		    })
            .blur(function() {
                if ($(this).val() == '') $(this).val(_this.defaultSearchVal);
                $(this).removeClass('search-default');
                $(this).addClass('search-default-deco');
            })
            .keyup(function(e) {
                var v = $(this).val();
                var key = e.which ? e.which : e.keyCode;
                if (key == 13) {
                    _this.query = (v != _this.defaultSearchVal ? v : '');
                    _this.searchLang = searchLang;
                    if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                        $(objLangSel).addClass('search-lang-deco');
                    } else $(objLangSel).removeClass('search-lang-deco');
                    IGObjectList.setQuery(_this.query, _this.searchLang);
                    IGObjectList.refresh();
                }
            })
            .appendTo(objSearch);

        // Language selector for search query
        var objLangSel = IGObjectList.renderSearchLanguageSelector({
            onChange: function(n) {
                searchLang = n;
            },
            searchLang: searchLang
        });
        if (objLangSel) $(objSearch).append(objLangSel);

        //Build search button:
        var btnFind = document.createElement("a");
        $(btnFind)
            .attr('href', '#search')
            .addClass("btn-normal btn-dark")
            .html('<span>' + IG.label.get("global_search") + '</span>')
            .click(function() {
                var v = $(objSearchInput).val();
                _this.query = (v != _this.defaultSearchVal ? v : '');
                _this.searchLang = searchLang;
                if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                    $(objLangSel).addClass('search-lang-deco');
                } else $(objLangSel).removeClass('search-lang-deco');
                IGObjectList.setQuery(_this.query, _this.searchLang);
                IGObjectList.refresh();
                return false;
            })
            .appendTo(objSearch);


        //Build "create gallery" button (iff user has permission to do so):
        if (bCreate) {
            var btnCreate = document.createElement("a");
            $(btnCreate)
                .addClass("btn-normal btn-pink btn-right")
                .html('<span>' + IG.label.get("gallery_list_create_gallery") + '</span>')
                .click(function() {
                    IG.create.gallery({
                        success: function(object) {
                            IG.showEditGallery({
                                object: object,
                                isNew: true,
                                history: true
                            });
                        },
                        error: function(err) {
                            IG.showErrorDialog(err);
                        }
                    });
                    return false;
                })
                .appendTo(objSearch);
        }

        $(objDiv).append(objSearch);

        var clearDiv = document.createElement("div");
        $(clearDiv)
            .css("clear", "both")
            .appendTo(objSearch);

        var minHeight = 330;
        var initHeight = $(window).height() - 220 - 84 - 63;
        initHeight = ( initHeight < minHeight ? minHeight : initHeight );

        //Determine available height for channels list:
        var fnGetAvailableHeight = function(obj) {
            var h = $(window).height() - $(obj).offset().top - $('#ig-footer').outerHeight() - 84; //ish...
            if (h < minHeight) {
                h = minHeight;
            }
            return h;
        };

        var objDivList = document.createElement("div");
        $(objDivList)
            .attr("id", "ig-galleries-list")
            .addClass('hubber-list-wrap')
            .height( initHeight )
            .css("clear", "both")
            .appendTo(objDiv);


        $(window).resize(function() {
            $(objDivList).height(fnGetAvailableHeight(objDivList));
        });


        IGObjectList.config.parentObject = objDivList;


        /* Creates and shows the preview qtip dialog: */
        var fnBuildPreviewDialogContent = function() {
            //Make sure to completely remove any previously created instances:
            var objDiv = $("#preview-div").get(0);
            if (objDiv) {
                $(objDiv).remove();
            }

            //Then (re)create it:
            objDiv = document.createElement("div");
            $(objDiv)
            .attr("id", "preview-div")
            .addClass("preview");


            //Build contents wrapper:
            var objDivContent = document.createElement("div");
            $(objDivContent).addClass("content clearfix");


            //Build block for selects:        
            var objDivOptions = document.createElement("div");
            $(objDivOptions).addClass("options");


            //Build ratio select:
            var objSelectRatio = document.createElement("select");
            $(objSelectRatio).attr({
                "id": "preview-ratio",
                "name": "ratio"
            });

            $.each(IG.customerConfig.previewAspectRatios, function(i, n) {
                var objOption = document.createElement("option");
                $(objOption)
                .text(i)
                .val(i);

                $(objSelectRatio).append(objOption);
            });



            //Build resolution select:
            var objSelectRes = document.createElement("select");
            $(objSelectRes).attr({
                "id": "preview-resolution",
                "name": "resolution"
            });

            //Populate with available resolutions for selected ratio:
            $.each(IG.gui.checkSizes($(objSelectRatio).val()), function(i, n) {
                $(objSelectRes).append(n);
            });


            //Re-populate with available resolutions when changing ratio:
            $(objSelectRatio).change(function() {
                var objSel = $("#preview-resolution").get(0);
                if (objSel) {
                    $(objSel).html("");

                    var v = $(this).val();
                    var resolutions = IG.gui.checkSizes(v);

                    $.each(resolutions, function(i, n) {
                        $(objSel).append(n);
                    });
                }
            });

            $(objDivOptions).append([objSelectRatio, objSelectRes]);
            $(objDivContent).append(objDivOptions);
            $(objDiv).append(objDivContent);

            return objDiv;
        };


        var fnBuildDeleteButton = function(item) {
            if (IG.userHasPermission("OP_GALLERY_DELETE", item) || bAdmin) {
                var objA = document.createElement("a");
                $(objA)
                    .addClass("btn-small btn-dark")
                    .html('<span>' + IG.label.get("delete") + '</span>')
                    .click(function() {
                        var _me = this;


                        var objDialog = IG.createConfirmDialog({
                            title: IG.label.get("gallery_list_delete_popup_title"),
                            text: item.name[IG.config.language],
                            onConfirm: function() {
                                IG.remove.gallery({
                                    id: item.id,
                                    success: function() {
                                        //Remove table row:
                                        IGObjectList.removeRow(item.id);
                                        delete IG.lists.galleries[item.id.id];
                                        objDialog.close();

                                    },
                                    error: function(err) {
                                        objDialog.close();
                                        IG.showErrorDialog(err);
                                    }
                                });
                            },
                            target: $(_me)
                        });

                        objDialog.show();
                    });
                return objA;
            }
            return null;
        };

        var fnBuildPreviewButton = function(item) {
            var objA = document.createElement("a");
            $(objA)
                .addClass("btn-small btn-light")
                .html('<span>' + IG.label.get("global_preview") + '</span>')
                .click(function() {
                    var _me = this;
                    $( "#modal-window" )
                        .html(fnBuildPreviewDialogContent())
                        .dialog({
                            modal: true,
                            position: { my: "center", at: "center", of: window, collision: 'fit' },
                            width: 300,
                            height: 'auto',
                            buttons: [
                                {
                                    text: IG.label.get("global_preview"),
                                    click: function() {
                                        //Open preview in overlay of proper size:
                                        var ratio = $("#preview-ratio").val();
                                        var resolution = $("#preview-resolution").val();

                                        var rx = /^(\d+)[^\d](\d+)$/i;
                                        var w = 0;
                                        var h = 0;

                                        //Specific resolution:
                                        if (rx.test(resolution)) {
                                            w = parseInt(resolution.replace(rx, "$1"), 10);
                                            h = parseInt(resolution.replace(rx, "$2"), 10);
                                        }
                                        //Fullscreen:
                                        else {
                                            //Adjust to match aspect ratio and fit inside screen:

                                            w = $(window).width() - 100;
                                            h = $(window).height() - 100;


                                            var ratX = parseInt(ratio.replace(rx, "$1"), 10);
                                            var ratY = parseInt(ratio.replace(rx, "$2"), 10);

                                            var rat = ratX / ratY;
                                            var ratWin = w / h;

                                            //Window wider than that of selected aspect ratio:
                                            if (ratWin > rat) {
                                                //Fit to height:
                                                w = h * rat;
                                            }
                                            //Window narrower than that of selected aspect ratio:
                                            else if (ratWin < rat) {
                                                //Fit to width:
                                                h = w / rat;
                                            }

                                            w = Math.round(w);
                                            h = Math.round(h);
                                        }
                                        IG.urls.preview.gallery(item.id, w, h, function(url) {
                                            $( "#modal-window").dialog('close');
                                            if (url !== null) {
                                                IG.showPreview({
                                                    href: url,
                                                    width: w,
                                                    height: h
                                                });
                                            } else {
                                                IG.showErrorDialog(IG.label.get('gallery_missing_modules'));
                                            }
                                        });

                                        return false;
                                    }
                                }
                            ],

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 150,
                            maxHeight: false,
                            title: ''
                        });

                    return false;
                });
            return objA;
        };
        

        IGObjectList.config.columns = [
            {
                name: "name",
                title: IG.label.get("gallery_list_name"),
                className: "title ellipsis",
                sortable: true,
                width: "auto",
                render: function(object) {
                    var s;
                    if (typeof object.name[_this.searchLang] === 'undefined') {
                        s = _.first(_.values(object.name));
                    }
                    else {
                        s = IG.fn.stripHTML(object.name[_this.searchLang]);
                    }

                    var objA = document.createElement("a");
                    $(objA)
                        .text(s)
                        .attr("title", s)
                        .click(function() {
                            IG.showEditGallery({
                                id: object.id["id"],
                                history: true,
                                searchLang: _this.searchLang
                            });
                            return false;
                        });
                    return objA;
                }
            },
            {
                name: "infoObjectCount",
                title: IG.label.get("gallery_list_elements_count"), 
                className: "numeric",
                sortable: true,
                width: "80px",
                render: function(object) {
                    return object.infoObjectCount > 0 ? object.infoObjectCount : IG.label.get("none"); 
                }
            },
            {
                name: "",
                title: "",
                className: "buttons",
                sortable: false,
                width: "160px",
                render: function(object) {
                    var btnDelete = fnBuildDeleteButton(object);
                    var btnPreview = fnBuildPreviewButton(object);

                    var res = [];

                    if (btnDelete != null) {
                        res.push(btnDelete);
                    }

                    if (btnPreview != null) {
                        res.push(btnPreview);
                    }

                    return res;
                }
            }
        ];

        // Filter results if there was a previous search string
        if (_this.query != _this.defaultSearchVal && _this.query != '' || _this.searchLang != IG.customerConfig.defaultContentLanguage) {
            IGObjectList.setQuery(_this.query, _this.searchLang);
        }

        //Initialize list and populate main contents:
        IGObjectList.init(function() {
            $("#ig-data").html(objDiv);
            IG.gui.loading.hide();
            
            // Focus the search field:
            if (_this.query != _this.defaultSearchVal && _this.query != '') {
            	$(objSearchInput).focus();
            	$(objSearchInput).removeClass('search-default-deco');
		        $(objSearchInput).addClass('search-default');
            }
        });

        
        
    };



    this.init = function() {
        _this.render();
        $.address.value(_this.config.hashkey);
    };

    return _this;
};




IG.showGalleryList = function(options) {
    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);

    // Google Analytics:
    // IG.config.prodMode ? _gaq.push(['_trackPageview']) : null;
    
    IG.gui.loading.show();

    //Create info objects list if needed:
    var objGalleryList = _IG.objects.get("gallerylist");
    if (objGalleryList == null) {
        objGalleryList = _IG.objects.create("gallerylist", new _IG.GalleryList());
        objGalleryList.init();
    }
    else {
        objGalleryList.refresh({ history: false });
    }

    IG.gui.setTitle(IG.label.get("dashboard_galleries")); 

    //Add to history:
    if (options.history) {
        _IG.location.setCurrent(objGalleryList.hash());
    }
    $('#ig-user-menu-sections').find('a.galleries').parent().addClass('menu-active');
};
