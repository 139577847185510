/* Dashboard object */
import React from 'react';
import {createRoot} from 'react-dom/client';
import { StatusLabel, StatusLabelLegend, StatusRefreshButton } from './components/GalleryStatusMonitor'

IG.DashBoard = function() {
    var _this = this;

    this.infoObjectList = null;
    this.channelsList = null;
    this.galleryView = null;

    //First time - create lists, gallery view etc.
    this.init = function() {
        if (IG.userHasPermission("OP_INFO_OBJECT_LIST")) {
            _this.infoObjectList = new IG.DashBoardInfoObjectList();
        }
        if (IG.userHasPermission("OP_CHANNEL_LIST")) {
            _this.channelsList = new IG.DashBoardChannelsList();
        }
        if (IG.userHasPermission("OP_GALLERY_LIST")) {
            _this.galleryView = new IG.GalleryView();
        }
        _this.render();
    };
    //Refresh dashboard - assume already rendered
    this.refresh = function() {
        var objDiv = $("#ig-dashboard").get(0);
        if (objDiv == null) {
            _this.init();
        }
        else {
            if (_this.infoObjectList != null) {
                _this.infoObjectList.refresh();
            }
            if (_this.channelsList != null) {
                _this.channelsList.refresh();
            }
            if (_this.galleryView != null) {
                _this.galleryView.refresh();
            }

            IG.gui.loading.hide();
        }
    };

    //Render HTML
    this.render = function() {
        var objDiv = $("#ig-dashboard").get(0);
        if (objDiv == null) {
            objDiv = document.createElement("div");
            $(objDiv).attr("id", "ig-dashboard");

            //Prepare container divs:
            var objColumnsDiv = document.createElement("div");
            $(objColumnsDiv).addClass("ig-two-columns clearfix");

            var objInfoDiv = null;
            var objChannelsDiv = null;
            var objGalleryDiv = null;

            if (_this.infoObjectList != null) {
                objInfoDiv = document.createElement("div");
                $(objColumnsDiv).append(objInfoDiv);
            }
            if (_this.channelsList != null) {
                objChannelsDiv = document.createElement("div");
                $(objColumnsDiv).append(objChannelsDiv);
            }

            if (_this.galleryView != null) {
                objGalleryDiv = document.createElement("div");
            }

            $(objDiv).append(objColumnsDiv);
            $(objDiv).append(objGalleryDiv);

            //Initialize lists/views:
            if (_this.infoObjectList != null) {
                _this.infoObjectList.init(objInfoDiv);
            }

            if (_this.channelsList != null) {
                _this.channelsList.init(objChannelsDiv);
            }

            if (_this.galleryView != null) {
                _this.galleryView.init(objGalleryDiv);
            }

            $("#ig-data").html(objDiv);
        }
    };

    //Get hash for current state:
    this.hash = function() {
        var h = "dashboard";
        return h;
    };
    return this;
};

/* GalleryView (thumbnails) object */
IG.GalleryView = function() {
    var _this = this;
    this.parentObj = null;

    this.sortMapping = {
        'updated' : IG.label.get("dashoboard_filter_last_edited"),
        'created' : IG.label.get("dashoboard_filter_last_created")
    };

    //Available options:
    this.config = {
        filters: ["mine", "mycontent", "all"], //FIXME: correct values, labels/translations?
        sorting: ["updated", "created"], //FIXME: correct values, labels/translations?
        limit: 0,
        hashkey: "galleries"
    };

    this.defaultOptions = {
        filter: "all",
        sorting: "updated"
    };

    //Currently selected options:
    this.options = {
        filter: "all",
        sorting: "updated"
    };


    //Currently loaded items
    this.items = [];

    //Error message if loading failed:
    this.errorMsg = "";
    this.showError = function() {
        var obj = $(_this.parentObj).find(".ig-gallery-view-items:first").get(0);
        var objSpan = document.createElement("span");
        $(objSpan)
            .addClass("error")
            .text(_this.errorMsg);
        $(obj).html(objSpan);
    };


    this.init = function(obj) {
        _this.parentObj = obj;
        _this.load(function() {
            _this.render();
            IG.gui.loading.hide();
        });
    };

    //Load data via JSON-RPC and perform supplied callback function, if any
    this.load = function(cb) {

        var sortingOpts = {};
        sortingOpts[_this.options.sorting] = "DESC";

        var filterOpts = {};
        if (_this.options.filter == 'mine') filterOpts = { owner: { id: IG.userConfig.userId.id, customerId: IG.userConfig.userId.customerId} };
        if (_this.options.filter == 'mycontent') filterOpts = { contentBy: { id: IG.userConfig.userId.id, customerId: IG.userConfig.userId.customerId} };

        var progParentDiv = $(".ig-gallery-view .ig-gallery-view-items");
        var progressId = 'gallery-filter-progress';
        IG.gui.loading.show(progParentDiv, progressId, 1);

        IG.request({
            method: 'searchGalleries',
            params: [IG.config.language, IG.config.language, '', true, filterOpts, sortingOpts, _this.config.limit, 0],
            success: function(response) {
                if (response.result) {
                    _this.errorMsg = "";
                    _this.items = response.data.data;
                    IG.gui.loading.hide(progressId);
                }
                else {
                    _this.errorMsg = response.message;
                }
                if ($.isFunction(cb)) {
                    cb();
                }
            },
            error: function(response) {

            }
        });
    };

    //Set/validate current filter option:
    this.setFilter = function(s) {
        if ($.inArray(s, _this.config.filters) > -1) {
            _this.options.filter = s;
        }
    };

    //Set/validate current sorting option:
    this.setSorting = function(s) {
        if ($.inArray(s, _this.config.sorting) > -1) {
            _this.options.sorting = s;
        }
    };

    //Validate supplied filter:
    this.validateFilter = function(s) {
        if ($.inArray(s, _this.config.filters) == -1) {
            s = _this.options.filter;
        }
        return s;
    };

    //Validate supplied sorting:
    this.validateSorting = function(s) {
        if ($.inArray(s, _this.config.sorting) == -1) {
            s = _this.options.sorting;
        }
        return s;
    };

    //Refresh filter according to current options:
    this.refreshFilter = function() {
        $(_this.parentObj).find(".ig-gallery-view-filter a").each(function() {
            if ($(this).data("filter") == _this.options.filter) {
                $(this).addClass("current");
            }
            else {
                $(this).removeClass("current");
            }
        });
    };

    //Refresh sorting according to current options:
    this.refreshSorting = function() {
        $("#ig-dashboard-gallery-sort span").text(_this.sortMapping[_this.options.sorting]);
    };

    //Refresh according to hash or options:
    this.refresh = function(opts) {
        opts = (typeof opts != "undefined" ? opts : {});
        opts.history = (typeof opts.history == "boolean" ? opts.history : false);

        var bLoad = true; //Should we load data?

        //Not adding a new entry - check current hash values and then set options
        if (!opts.history) {
            var h = $.address.parameter(_this.config.hashkey);
            var f = _this.options.filter;
            var s = _this.options.sorting;

            if (h) {
                var hArr = h.split("|");
                if (hArr.length == 2) {
                    f = _this.validateFilter(hArr[0]);
                    s = _this.validateSorting(hArr[1]);
                }
            }
            else {
                f = _this.defaultOptions.filter;
                s = _this.defaultOptions.sorting;
            }

            bLoad = (f != _this.options.filter || s != _this.options.sorting);

            _this.setFilter(f);
            _this.setSorting(s);
        }


        if (bLoad) {
            _this.load(function() {
                //Update selected filters, sorting:
                _this.refreshFilter();
                _this.refreshSorting();

                if (_this.errorMsg != "") {
                    _this.showError();
                }
                else {

                    //Build items and insert:
                    var objItems = $(_this.parentObj).find(".ig-gallery-view-items:first").get(0);
                    if (objItems) {
                        $(objItems).html(_this.buildItems());
                    }
                }
                if (opts.history) {
                    //Adjust current hash with filter + sorting values - do NOT replace entire hash
                    $.address.parameter(_this.config.hashkey, _this.options.filter + "|" + _this.options.sorting);
                }
            });
        }
    };


    /* Builds preview dialog contents - on demand */
    this.buildPreviewDialogContent = function() {
        //Make sure to completely remove any previously created instances:
        var objDiv = $("#preview-div").get(0);
        if (objDiv) {
            $(objDiv).remove();
        }

        //Then (re)create it:
        objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "preview-div")
            .addClass("preview");

        //Build contents wrapper:
        var objDivContent = document.createElement("div");
        $(objDivContent).addClass("content clearfix");


        //Build block for selects:        
        var objDivOptions = document.createElement("div");
        $(objDivOptions).addClass("options");


        //Build ratio select:
        var objSelectRatio = document.createElement("select");
        $(objSelectRatio).attr({
            "id": "preview-ratio",
            "name": "ratio"
        });

        $.each(IG.customerConfig.previewAspectRatios, function(i, n) {
            var objOption = document.createElement("option");
            $(objOption)
                .text(i)
                .val(i)
                .appendTo(objSelectRatio);
        });



        //Build resolution select:
        var objSelectRes = document.createElement("select");
        $(objSelectRes).attr({
            "id": "preview-resolution",
            "name": "resolution"
        });

        //Populate with available resolutions for selected ratio:
        $.each(IG.gui.checkSizes($(objSelectRatio).val()), function(i, n) {
            $(objSelectRes).append(n);
        });


        //Re-populate with available resolutions when changing ratio:
        $(objSelectRatio).change(function() {
            var objSel = $("#preview-resolution").get(0);
            if (objSel) {
                $(objSel).html("");

                var v = $(this).val();
                var resolutions = IG.gui.checkSizes(v);

                $.each(resolutions, function(i, n) {
                    $(objSel).append(n);
                });
            }
        });

        $(objDivOptions).append([objSelectRatio, objSelectRes]);
        $(objDivContent).append(objDivOptions);
        $(objDiv).append(objDivContent);

        return objDiv;
    };

    /* Builds gallery items: */
    this.buildItems = function() {
        if (_this.items.length > 0) {
            var arr = [];
            $.each(_this.items, function(i, n) {
                var obj = document.createElement("div");
                $(obj).addClass("ig-gallery-view-item");
                $(obj).attr("title", n.name[IG.config.language]);

                var objDivImg = document.createElement("div");
                $(objDivImg).addClass("img");

                //thumbnail image object
                var linkImg = document.createElement("a");
                var objImg = document.createElement("img");
                $(objImg).attr({
                    "src": n.screenshotThumbUrl
                });

                $(objDivImg).append(objImg);

                var objDivName = document.createElement("div");
                $(objDivName).text(n.name[IG.config.language]);
                $(objDivName).addClass("name");

                var objDivCount = document.createElement("div");
                $(objDivCount).attr("title", n.infoObjectCount + " " + IG.label.get("dashboard_infoobjects_count"));
                $(objDivCount).text(n.infoObjectCount + " " + IG.label.get("dashboard_infoobjects_count"));
                $(objDivCount).addClass("count");
                if (n.infoObjectCount == 0) {
                    $(objDivCount).addClass("zero");
                }

                if (IG.customerHasFunctionality('statusMonitor')) {
                    var objStatusDiv = document.createElement('div');
                    $(objStatusDiv).addClass('status');
                    const objStatusDivRoot = createRoot(objStatusDiv)
                    objStatusDivRoot.render(<StatusLabel updateTime={n.thumbnailUpdated}/>);
                }

                var objDivOptions = document.createElement("div");
                $(objDivOptions).addClass("options");

                //Build preview button:
                var objAPreview = document.createElement("a");
                $(objAPreview)
                    .text(IG.label.get("global_preview"))
                    .click(function() {
                        var _me = this;

                        $( "#modal-window" )
                            .html(_this.buildPreviewDialogContent())
                            .dialog({
                                modal: true,
                                position: { my: "center", at: "center", of: window, collision: 'fit' },
                                width: 300,
                                height: 'auto',
                                buttons: [
                                    {
                                        text: IG.label.get("global_preview"),
                                        click: function() {
                                            //Open preview in overlay of proper size:
                                            var ratio = $("#preview-ratio").val();
                                            var resolution = $("#preview-resolution").val();

                                            var rx = /^(\d+)[^\d](\d+)$/i;
                                            var w = 0;
                                            var h = 0;

                                            //Specific resolution:
                                            if (rx.test(resolution)) {
                                                w = parseInt(resolution.replace(rx, "$1"), 10);
                                                h = parseInt(resolution.replace(rx, "$2"), 10);
                                            }
                                            //Fullscreen:
                                            else {
                                                //Adjust to match aspect ratio and fit inside screen:

                                                w = $(window).width() - 100;
                                                h = $(window).height() - 100;


                                                var ratX = parseInt(ratio.replace(rx, "$1"), 10);
                                                var ratY = parseInt(ratio.replace(rx, "$2"), 10);

                                                var rat = ratX / ratY;
                                                var ratWin = w / h;

                                                //Window wider than that of selected aspect ratio:
                                                if (ratWin > rat) {
                                                    //Fit to height:
                                                    w = h * rat;
                                                }
                                                //Window narrower than that of selected aspect ratio:
                                                else if (ratWin < rat) {
                                                    //Fit to width:
                                                    h = w / rat;
                                                }

                                                w = Math.round(w);
                                                h = Math.round(h);
                                            }

                                            IG.urls.preview.gallery(n.id, w, h, function(url) {
                                                $( "#modal-window").dialog('close');
                                                if (url !== null) {
                                                    IG.showPreview({
                                                        href: url,
                                                        width: w,
                                                        height: h
                                                    });
                                                } else {
                                                    IG.showErrorDialog(IG.label.get('gallery_missing_modules'));
                                                }
                                            });
                                        }
                                    }
                                ],

                                // reset to defaults
                                minWidth: 150,
                                maxWidth: false,
                                minHeight: 150,
                                maxHeight: false,
                                title: ''
                            });

                        return false;

                    });

                var objADetails = document.createElement("a");
                $(objADetails).text(IG.label.get("dashboard_view_details"));
                $(objADetails).click(function() {
                    IG.showEditGallery({
                        id: n.id["id"],
                        history: true
                    });
                    return false;
                });

                $(objDivOptions).append([objAPreview, objADetails]);

                $(obj).mouseover(function() {
                    $(objDivOptions).show();
                });

                $(obj).mouseout(function() {
                    $(objDivOptions).hide();
                });

                $(obj).append([objDivImg, objDivName, objDivCount, objStatusDiv, objDivOptions]);

                arr[i] = obj;
            });

            return arr;
        }
        else {
            var objNoItem = document.createElement("div");
            $(objNoItem).addClass('ig-no-items');
            $(objNoItem).append(IG.label.get("dashboard_no_items_in_list"));
            return objNoItem;
        }
    };

    //Render default HTML:
    this.render = function() {
        if (_this.parentObj != null) {
            var obj = document.createElement("div");
            $(obj).addClass("ig-gallery-view");

            //Build gallery view header block:
            var objDivHeader = document.createElement("div");
            $(objDivHeader).addClass("ig-gallery-view-header");
            $(objDivHeader).addClass("clearfix");

            //Build gallery view header title:
            var objH2 = document.createElement("h2");
            $(objH2)
                .text(IG.label.get("dashboard_galleries"))
                .appendTo(objDivHeader);

            if (IG.customerHasFunctionality('statusMonitor')) {
                let objDivStatusRefreshButtonWrapper = document.createElement('div');
                $(objDivStatusRefreshButtonWrapper).addClass('status-monitor-wrapper')
                $(objDivHeader).append(objDivStatusRefreshButtonWrapper);
                const objDivStatusRefreshButtonWrapperRoot = createRoot(objDivStatusRefreshButtonWrapper)
                objDivStatusRefreshButtonWrapperRoot.render(<StatusRefreshButton title={IG.label.get('status_monitor_refresh')}/>);
            }

            //Build gallery view filter options:
            var objDivFilter = document.createElement("div");
            $(objDivFilter).addClass("ig-gallery-view-filter");

            //FIXME: "links", click handlers:
            $(objDivFilter).append('<span>' + IG.label.get("dashboard_filters") + ': </span>');

            $.each(_this.config.filters, function(i, n) {
                var objA = document.createElement("a");
                if (n == _this.options.filter) {
                    $(objA).addClass("current");
                }

                $(objA)
                    .data("filter", n)
                    .text(IG.label.getForFilter(n))
                    .click(function() {
                        _this.setFilter(n);
                        _this.refresh({ history: true });
                        $(this).addClass("current");
                        $(this).siblings("a").removeClass("current");
                        return false;
                    })
                    .appendTo(objDivFilter);


                if (i < _this.config.filters.length-1) {
                    var objPipe = document.createElement('span');
                    $(objPipe)
                        .text('|')
                        .addClass('list-pipe')
                        .appendTo(objDivFilter)
                }
            });

            $(objDivHeader).append(objDivFilter);


            //Build gallery view header sort options:
            var objSortTip = document.createElement('div');
            $(objSortTip).addClass('selector-list');

            $.each(_this.sortMapping, function(key, label) {
                var opts = document.createElement("a");
                $(opts)
                    .text(label)
                    .click(function() {
                        $(objSortTip).children('a').removeClass('selected');
                        $(this).addClass('selected');

                        $(objSelect).qtip('hide');
                        _this.setSorting(key); //set the new sorting
                        _this.refresh({ history: true }); //refresh data
                    })
                    .appendTo(objSortTip);
            });
            $(objSortTip).children('a').first().addClass('selected');

            var objSelect = document.createElement("a");
            $(objSelect)
                .html('<span class="btn-text">' + IG.label.get("dashoboard_filter_last_edited") + '</span>')
                .addClass('hubber-select hubber-select-small btn-icon btn-icon-right')
                .attr("id", "ig-dashboard-gallery-sort")
                .qtip({
                    content: {
                        text: objSortTip
                    },
                    show: {
                        delay: 50,
                        event: 'click'
                    },
                    hide: { event: 'unfocus'},
                    position: {
                        my: 'top center',
                        at: 'bottom center',
                        adjust: {
                            y:  2
                        }
                    },
                    style: {
                        classes: 'qtip-select qtip-select-small',
                        width: 'auto',
                        def: false,
                        background: 'none',
                        tip: {
                            width: 10,
                            height: 6
                        }
                    },
                    api: {
                        onShow: function(e) {
                            $(document).bind('keydown.language-selector', qtipKeyHandler);
                        },
                        onHide: function(e) {
                            $(document).unbind('keydown.language-selector', qtipKeyHandler);
                        }
                    }
                });


            $(objDivFilter).append(objSelect);
            $(obj).append(objDivHeader);

            var objDivItems = document.createElement("div");
            $(objDivItems).addClass("ig-gallery-view-items");
            $(objDivItems).addClass("clearfix");


            if (_this.errorMsg == "") {
                $(objDivItems).html(_this.buildItems());
            }

            $(obj).append(objDivItems);

            if (IG.customerHasFunctionality('statusMonitor')) {
                var statusLabelLegendWrapperDiv = document.createElement("div");
                $(obj).append(statusLabelLegendWrapperDiv);
                const statusLabelLegendWrapperDivRoot = createRoot(statusLabelLegendWrapperDiv)
                statusLabelLegendWrapperDivRoot.render(<StatusLabelLegend/>);
            }

            $(_this.parentObj).append(obj);

            if (_this.errorMsg != "") {
                _this.showError();
            }

        }
    };

    return this;
};

IG.DashBoardChannelsList = function() {
    var _this = this;
    this.parentObj = null;

    //Available options:
    this.config = {
        filters: [], //FIXME: correct values, labels/translations?
        limit: 6,
        hashkey: "channels"
    };

    this.defaultOptions = {
        filter: "mine"
    };

    //Currently selected options:
    this.options = {
        filter: "mine" //FIXME: legal value?
    };

    //Currently loaded items:
    this.items = [];

    //Error message if loading failed:
    this.errorMsg = "";
    this.showError = function() {
        var obj = $(_this.parentObj).find(".ig-infoobjects-items:first").get(0);
        var objSpan = document.createElement("span");
        $(objSpan).addClass("error");
        $(objSpan).text(_this.errorMsg);
        $(obj).html(objSpan);
    };

    this.init = function(obj) {
        _this.parentObj = obj;
        _this.load(function() {
            _this.render();

        });
    };

    //Load data via JSON-RPC and perform supplied callback function, if any
    this.load = function(cb) {


        var ChannelFilterOpts = { owners: [{ id: IG.userConfig.userId.id, customerId: IG.userConfig.userId.customerId}] };
        /*
        if(_this.options.filter == 'mine') GallObjfilterOpts = {owner : { id : IG.userConfig.userId.id , customerId : IG.userConfig.userId.customerId}};
        */

        var progParentDiv = $("#ig-channels-list .ig-dashboard-filter");
        var progressId = 'channel-filter-progress';
        IG.gui.loading.show(progParentDiv, progressId);

        IG.request({
            method: 'searchChannels',
            params: [IG.config.language, IG.config.language, "", false, ChannelFilterOpts, {updated:"DESC"}, _this.config.limit, 0],
            success: function(response) {
                if (response.result) {
                    _this.errorMsg = "";
                    _this.items = response.data['data'];
                    IG.gui.loading.hide(progressId);
                }
                else {
                    _this.errorMsg = response.message;
                }
                if ($.isFunction(cb)) {
                    cb();
                }
            }
        });
    };

    //Set/validate current filter option:
    this.setFilter = function(s) {
        if ($.inArray(s, _this.config.filters) > -1) {
            _this.options.filter = s;
        }
    };

    //Validate supplied filter:
    this.validateFilter = function(s) {
        if ($.inArray(s, _this.config.filters) == -1) {
            s = _this.options.filter;
        }
        return s;
    };

    //Refresh filter according to current options:
    this.refreshFilter = function() {
        $(_this.parentObj).find(".ig-dashboard-filter a").each(function() {
            if ($(this).data("filter") == _this.options.filter) {
                $(this).addClass("current");
            }
            else {
                $(this).removeClass("current");
            }
        });
    };

    //Refresh according to hash or options:
    this.refresh = function(opts) {

        opts = (typeof opts != "undefined" ? opts : {});
        opts.history = (typeof opts.history == "boolean" ? opts.history : false);

        var bLoad = true; //Should we load data?

        //Not adding a new entry - check current hash values and then set options
        if (!opts.history) {
            var h = $.address.parameter(_this.config.hashkey);
            var f = _this.options.filter;

            if (h) {
                var hArr = h.split("|");
                if (hArr.length == 1) {
                    f = _this.validateFilter(hArr[0]);
                }
            }
            else {
                f = _this.defaultOptions.filter;
            }
            bLoad = (f != _this.options.filter);
            _this.setFilter(f);
        }

        if (bLoad) {
            _this.load(function() {
                //Update selected filter:
                _this.refreshFilter();

                if (_this.errorMsg != "") {
                    _this.showError();
                }
                else {
                    //Build items and insert:
                    var objItems = $(_this.parentObj).find(".ig-infoobjects-items:first").get(0);
                    if (objItems) {
                        $(objItems).html(_this.buildItems());
                    }
                }

                if (opts.history) {
                    //Adjust current hash with filter value - do NOT replace entire hash
                    $.address.parameter(_this.config.hashkey, _this.options.filter);
                }
            });
        }
    };


    this.buildItems = function() {
        if (_this.items.length > 0) {
            var strColGroup = ''
                + '<colgroup>'
                + '<col />'
                + '<col style="width: 130px" />'
                + '</colgroup>';


            var objTable = document.createElement("table");
            $(objTable)
                .addClass("items")
                .append(strColGroup);

            var objTBody = document.createElement("tbody");

            $.each(_this.items, function(i, n) {
                var objTR = document.createElement("tr");
                var objTDtitle = document.createElement("td");
                var objTDdt = document.createElement("td");

                var name = _.find(_.values(n.name), function (val) {
                    return typeof val === 'string' && val.length > 0;
                });

                $(objTDtitle)
                    .addClass("title ellipsis")
                    .attr("title", name)
				    .text(name)
				    .click(function() {
				        IG.showEditChannel({
				            id: n.id["id"],
				            history: true
				        });
				        return false;
				    });

                //dummy unix time for testing
                var formatedTime = IG.fn.formatTime(n.updated);
                var dateContainer = document.createElement("div");
                $(dateContainer)
                //.text(IG.fn.formatTime(n.updated))
                    .text(formatedTime)
                    .addClass('formated-date')
                    .appendTo(objTDdt);

                $(objTR).append([objTDtitle, objTDdt]);
                $(objTBody).append(objTR);
            });
            $(objTable).append(objTBody);

            return objTable;
        }
        else {
            var objNoItem = document.createElement("div");
            $(objNoItem).addClass('ig-no-items');
            $(objNoItem).append(IG.label.get("dashboard_no_items_in_list"));
            return objNoItem;
        }
    };

    this.render = function() {
        if (_this.parentObj != null) {
            var obj = document.createElement("div");
            $(obj).addClass("ig-dashboard-list");
            $(obj).attr({ "id": "ig-channels-list" });

            //Build infoobject list header title:
            var objH2 = document.createElement("h2");
            $(objH2).text(IG.label.get("dashboard_channels"));



            var objDivFilter = document.createElement("div");
            $(objDivFilter).addClass("ig-dashboard-filter");

            $(objDivFilter).append("<span>&nbsp;</span>");

            var spanUpdated = document.createElement("span");
            $(spanUpdated)
                .addClass("span-updated")
                .text(IG.label.get("global_created"))
                .appendTo(objDivFilter);

            //Build infoobject list items container:
            var objDivItems = document.createElement("div");
            $(objDivItems).addClass("ig-infoobjects-items");

            if (_this.errorMsg == "") {
                $(objDivItems).html(_this.buildItems());
            }

            var objAInfo = document.createElement("a");
            $(objAInfo).text(IG.label.get("dashoboard_view_all"));
            $(objAInfo).addClass("href-info");
            $(objAInfo).click(function() {
                IG.showChannelList({ history: true });
                return false;
            });

            $(obj).append([objH2, objDivFilter, objDivItems, objAInfo]);

            $(_this.parentObj).append(obj);

            if (_this.errorMsg != "") {
                _this.showError();
            }

        }
    };

    return this;
};


IG.DashBoardInfoObjectList = function() {
    var _this = this;
    this.parentObj = null;

    //Available options:
    this.config = {
        filters: ["mine", "all"], //FIXME: correct values, labels/translations?
        limit: 6,
        hashkey: "infoobjects"
    };

    this.defaultOptions = {
        filter: "mine"
    };

    //Currently selected options:
    this.options = {
        filter: "mine" //FIXME: legal value?
    };

    //Currently loaded items:
    this.items = [];

    //Error message if loading failed:
    this.errorMsg = "";
    this.showError = function() {
        var obj = $(_this.parentObj).find(".ig-infoobjects-items:first").get(0);
        var objSpan = document.createElement("span");
        $(objSpan).addClass("error");
        $(objSpan).text(_this.errorMsg);
        $(obj).html(objSpan);
    };

    this.init = function(obj) {
        _this.parentObj = obj;
        _this.load(function() {
            _this.render();

        });
    };

    //Load data via JSON-RPC and perform supplied callback function, if any
    this.load = function(cb) {

        var InfoObjfilterOpts = {"parentsOnly":true}; //parentsOnly: true
        if (_this.options.filter == 'mine') {
            InfoObjfilterOpts.owners = [{ 
                id: IG.userConfig.userId.id, 
                customerId: IG.userConfig.userId.customerId
            }]
        }

        var progParentDiv = $("#ig-infoobjects-list .ig-infoobjects-items");
        var progressId = 'info-filter-progress';
        IG.gui.loading.show(progParentDiv, progressId, 1);
        
        IG.request({
            method: 'searchInfoObjects',
            //params: [IG.config.language, IG.config.language, "", true, {}, { infoObjectType: "standard" }, _this.config.limit, 0],
            params: [IG.config.language, IG.config.language, "", true, InfoObjfilterOpts, {"lastModified":"DESC"}, 6, 0], // _this.config.limit
            success: function(response) {
                if (response.result) {
                    _this.errorMsg = "";
                    _this.items = response.data['data'];
                    IG.gui.loading.hide(progressId);
                }
                else {
                    _this.errorMsg = response.message;
                }
                if ($.isFunction(cb)) {
                    cb();
                }
            }
        });
    };

    //Set/validate current filter option:
    this.setFilter = function(s) {
        if ($.inArray(s, _this.config.filters) > -1) {
            _this.options.filter = s;
        }
    };

    //Validate supplied filter:
    this.validateFilter = function(s) {
        if ($.inArray(s, _this.config.filters) == -1) {
            s = _this.options.filter;
        }
        return s;
    };

    //Refresh filter according to current options:
    this.refreshFilter = function() {
        $(_this.parentObj).find(".ig-dashboard-filter a").each(function() {
            if ($(this).data("filter") == _this.options.filter) {
                $(this).addClass("current");
            }
            else {
                $(this).removeClass("current");
            }
        });
    };

    //Refresh according to hash or options:
    this.refresh = function(opts) {
        opts = (typeof opts != "undefined" ? opts : {});
        opts.history = (typeof opts.history == "boolean" ? opts.history : false);

        var bLoad = true; //Should we load data?

        //Not adding a new entry - check current hash values and then set options
        if (!opts.history) {
            var h = $.address.parameter(_this.config.hashkey);
            var f = _this.options.filter;

            if (h) {
                var hArr = h.split("|");
                if (hArr.length == 1) {
                    f = _this.validateFilter(hArr[0]);
                }
            }
            else {
                f = _this.defaultOptions.filter;
            }

            bLoad = (f != _this.options.filter);

            _this.setFilter(f);
        }

        if (bLoad) {
            _this.load(function() {
                //Update selected filter:
                _this.refreshFilter();

                if (_this.errorMsg != "") {
                    _this.showError();
                }
                else {
                    //Build items and insert:
                    var objItems = $(_this.parentObj).find(".ig-infoobjects-items:first").get(0);
                    if (objItems) {
                        $(objItems).html(_this.buildItems());
                    }
                }

                if (opts.history) {
                    //Adjust current hash with filter value - do NOT replace entire hash
                    $.address.parameter(_this.config.hashkey, _this.options.filter);
                }
            });
        }
    };


    this.buildItems = function() {
        if (_this.items.length > 0) {
            var strColGroup = ''
                + '<colgroup>'
                + '<col />'
                + '<col style="width: 130px" />'
                + '</colgroup>';

            var objTable = document.createElement("table");

            $(objTable)
                .addClass("items")
                .append(strColGroup);


            var objTBody = document.createElement("tbody");
            $.each(_this.items, function(i, n) {
                
                var objTR = document.createElement("tr");
                var objTDtitle = document.createElement("td");
                var objTDdt = document.createElement("td");
                var headline = IG.label.get("INFOOBJECT_HEADLINE_NOT_TRANSLATED");
                if (typeof n.headline[IG.customerConfig["defaultContentLanguage"]] === "string" && n.headline[IG.customerConfig["defaultContentLanguage"]].length > 0) {
                    headline = n.headline[IG.customerConfig["defaultContentLanguage"]];
                }
                                
                $(objTDtitle)
                    .addClass("title ellipsis")
                    .attr("title", headline)
				    .text(headline)
				    .click(function() {
				        IG.showEditInfoObject({
				            id: n.id["id"],
				            history: true
				        });
				    });

                var dateContainer = document.createElement("div");
                $(dateContainer)
                    .text(IG.fn.formatTime(n.lastModified))
                    .addClass('formated-date')
                    .appendTo(objTDdt);

                $(objTR).append([objTDtitle, objTDdt]);
                $(objTBody).append(objTR);
            });

            $(objTable).append(objTBody);

            return objTable;
        }
        else {
            var objNoItem = document.createElement("div");
            $(objNoItem).addClass('ig-no-items');
            $(objNoItem).append(IG.label.get("dashboard_no_items_in_list"));
            return objNoItem;
        }
    }

    this.render = function() {
        if (_this.parentObj != null) {
            var obj = document.createElement("div");
            $(obj).addClass("ig-dashboard-list");
            $(obj).attr({ "id": "ig-infoobjects-list" });

            //Build infoobject list header title:
            var objH2 = document.createElement("h2");
            $(objH2).text(IG.label.get("dashboard_info_objects"));

            //Build infoobject list header filter:
            var objDivFilter = document.createElement("div");
            $(objDivFilter)
                .addClass("ig-dashboard-filter")
                .append('<span>' + IG.label.get("dashboard_filters") + ': </span>');

            $.each(_this.config.filters, function(i, n) {
                var objA = document.createElement("a");
                if (n == _this.options.filter) {
                    $(objA).addClass("current");
                }

                $(objA)
                    .data("filter", n)
                    .text(IG.label.getForFilter(n))
                    .click(function() {
                        _this.setFilter(n);
                        _this.refresh({ history: true });
                        $(this).addClass("current");
                        $(this).siblings("a").removeClass("current");
                        return false;
                    })
                    .appendTo(objDivFilter);

                if (i < _this.config.filters.length-1) {
                    var objPipe = document.createElement('span');
                    $(objPipe)
                        .text('|')
                        .addClass('list-pipe')
                        .appendTo(objDivFilter)
                }
            });

            var spanUpdated = document.createElement("span");
            $(spanUpdated)
                .addClass("span-updated")
                .text(IG.label.get("global_created"))
                .appendTo(objDivFilter);


            //Build infoobject list items container:
            var objDivItems = document.createElement("div");
            $(objDivItems).addClass("ig-infoobjects-items");

            if (_this.errorMsg == "") {
                $(objDivItems).html(_this.buildItems());
            }

            var objAInfo = document.createElement("a");
            $(objAInfo)
                .text(IG.label.get("dashoboard_view_all"))
                .addClass("href-info")
                .click(function() {
                    IG.showInfoObjectList({ history: true });
                    return false;
                });

            $(obj).append([objH2, objDivFilter, objDivItems, objAInfo]);

            $(_this.parentObj).append(obj);

            if (_this.errorMsg != "") {
                _this.showError();
            }

        }
    };

    return this;
};


/* 
Build and show dashboard.
*/
IG.showDashboard = function(options) {
    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);
    
    // Google Analytics:
    // IG.config.prodMode ? _gaq.push(['_trackPageview']) : null;
    
    IG.gui.loading.show();

    //Create dashboard if needed:
    var objDashBoard = _IG.objects.get("dashboard");
    if (objDashBoard == null) {
        objDashBoard = _IG.objects.create("dashboard", new _IG.DashBoard());
        objDashBoard.init();
    }
    else {
        objDashBoard.refresh();
    }

    IG.gui.setTitle(IG.label.get("right_menu_dashboard"));

    //Add to history:
    if (options.history) {
        _IG.location.setCurrent(objDashBoard.hash());
    }

    $('#ig-user-menu-sections').find('a.dashboard').parent().addClass('menu-active');
};
