

import React from 'react';
import {HeaderMenu} from './components/HeaderMenu';

var IG = window.IG;
/* Customer configuration */
IG.customerConfig = null;

/* User configuration */
IG.userConfig = null;


IG.storage = function() {
    var that = {},
        storageObj = null,
        storageId = 'customer-' + IG.config.customerId;

    var hasStorageSupport = function() {
        return (typeof Storage !== 'undefined' && typeof sessionStorage !== 'undefined');
    };

    that.setValue = function(key, value) {
        if (typeof key === 'undefined') {
            throw {'name': 'Hubber.ERROR.storage', 'error': 'Undefined key for setting value in storage.'};
        }
        if (typeof value === 'undefined') {
            throw {'name': 'Hubber.ERROR.storage', 'error': 'Undefined value for setting in storage by key:' + key};
        }
        if (!hasStorageSupport()) { return; }
        storageObj = sessionStorage.getItem(storageId);
        storageObj = ( storageObj ? $.parseJSON(storageObj) : null );
        storageObj = storageObj || {};
        storageObj[key] = value;
        sessionStorage.setItem(storageId, JSON.stringify(storageObj)); // JSON.stringify(storageObj)
    };

    that.getValue = function(key) {
        if (typeof key === 'undefined') {
            throw {'name': 'Hubber.ERROR.storage', 'error': 'Undefined key for getting value in storage.'};
        }
        if (!hasStorageSupport()) { return null; }
        storageObj = sessionStorage.getItem(storageId); // $.parseJSON(sessionStorage.getItem(storageId));
        storageObj = ( storageObj ? $.parseJSON(storageObj) : null ); //  && storageObj.match(/^\{/)
        storageObj = storageObj || {};
        if (storageObj.hasOwnProperty(key)) {
            return storageObj[key];
        }
        return null;
    };
    return that;
};

/* Location handling */
IG.location = {
    current: "",

    setCurrent: function(locationHash) {
        if (locationHash !== null) {
            $.address.value(locationHash);
        }

        var store = IG.storage();
        store.setValue('location', locationHash);
    },

    loadCurrent: function() {
        var store = IG.storage();
        return store.getValue('location');
    },

    //Forcibly redirect to front page, dropping all hash-parameters:
    front: function() {
        window.location = window.location.href.replace(/^([^\?#]+).*/i, "$1");
    },

    //Handles primary hash-parameter:
    handle: function(useStoredLocation) {

        if (useStoredLocation) {
            // check for a location in Web Storage
            var lastLocation = this.loadCurrent();
            if (lastLocation !== null && lastLocation !== 'null') {
                $.address.value(lastLocation); // set the hash
                this.current = (lastLocation.match(/^\//) !== null ? lastLocation : '/'+lastLocation);
            }
        }


        var v = this.current.replace(/\/([^\?]*).*/i, "$1");
        //Password-reset:
        if (v == "password-reset") {
            IG.showPasswordResetDialog();
        }

        if (IG.session.active()) {

            switch (v) {
                //Create/edit infoobject:
                case "infoobject":
                    IG.showEditInfoObject({ history: false });
                    break;

                //Create/edit channel:
                case "channel":
                    IG.showEditChannel({ history: false });
                    break;

                //Create/edit gallery:
                case "gallery":
                    IG.showEditGallery({ history: false });
                    break;

                //Edit profile:
                case "profile":
                    IG.showEditProfile({ history: false });
                    break;

                //Edit users:
                case "users":
                    IG.showEditUsers({ history: false });
                    break;

                //info object list
                case "infoobjectlist":
                    IG.showInfoObjectList({ history: false });
                    break;

                //Gallery list:
                case "galleries":
                    IG.showGalleryList({ history: false });
                    break;

                //Channel list :
                case "channels":
                    IG.showChannelList({ history: false });
                    break;


                //FIXME: others?

                //Dashboard:
                case "":
                case "dashboard":
                    IG.showDashboard({ history: false });
                    break;
            }
        }
    }
};

/* Object for storing and retrieving user interface texts/labels */
IG.label = {
    data: [],

    //Get label given key and optional language:
    get: function(k, lang) {
        var s = "";
        lang = (typeof lang == "string" ? lang : IG.config.language);

        try {
            var obj = this.data[k];
            if (typeof obj != "undefined") {
                var l = obj[lang];
                if (typeof l != "undefined") {
                    s = l;
                }
            }
        }
        catch (e) {

        }

        if (s == "") s = k;
        return s;
    },

    //Minor helper function for dashboard:
    getForFilter: function(f) {
        var s = "#NO_TRANSLATION#";
        switch (f) {
            case "all":
                s = this.get("dashboard_all");
                break;
            case "mycontent":
                s = this.get("dashboard_my_content");
                break;
            case "mine":
                s = this.get("dashboard_mine");
                break;
        }
        return s;
    }
};


/* Collection of user interface objects (lists etc.) */
IG.objects = {
    items: {},
    current : null,
    get: function(key) {
        // "unload" the last object view
        var lastObj = (this.current != null ? this.items[this.current] : null);
        if (key !== this.current && lastObj !== null && $.isFunction(lastObj.unloadObject)) {
            lastObj.unloadObject();
        }
        if (typeof this.items[key] !== 'undefined' && this.items[key] !== null) {
            this.current = key;
            return this.items[key];
        }
        return null;
    },
    create: function(key, obj) {
        this.current = key;
        this.items[key] = obj;
        return this.items[key];
    },
    remove: function(key) {
        delete this.items[key];
    },
    kill: function() {
        var _this = this;
        $.each(_this.items, function(i, n) {
            if ($.isFunction(n.unloadObject)) {
                n.unloadObject();
            }
            _this.remove(i);
        });
    }
};


/* Lists of objects needed later - populated in initWhenLoggedIn and init functions. */
IG.lists = {
    users: [],
    groups: [],
    channels: [],
    galleries: {},
    templates: [],
    categories: [],
    subcategories: [],
    modules: [],
    skins: [],
    tags: [],
    documenttypes: [] // Custom documenttypes (not standard, event or poll)
};
IG.maps = {
    documentTypes: {}
};

IG.standardInfoObjectTypes =[
    "standard",
    "arrangement",
    "poll"
];

/*
Defines if flash editor is enabled.
Requires active flash plugin and swfobject javascript library.
Also, flash editor is not available if page is not run from a webserver.
*/
IG.flashText = {
    enabled: swfobject.hasFlashPlayerVersion("10.1.0") && !window.location.href.match(/file.*/),
    message: "",
    disabledText: ""
};




/* Contains helper functions for updating user interface elements */
IG.gui = {
    //Sets the <title> tag
    setTitle: function(s) {
        if (typeof s == "string") {
            s = $.trim(s);
            document.title = 'Hubber' + (s != '' ? ' | ' + s : '');
        }
    },

    //Returns the date format for the given language:
    getDateFormat: function(language) {
        var res = null;

        if (typeof language != "undefined") {
            res = IG.dateFormat[language];
        }
        else {
            res = IG.dateFormat[IG.config.language];
        }

        if (typeof res == "undefined") {
            res = IG.dateFormat["en"];
        }

        return res;
    },

    //Returns the datetime format for the given language:
    getDateTimeFormat: function(language) {
        var res = null;

        if (typeof language != "undefined") {
            res = IG.dateTimeFormat[language];
        }
        else {
            res = IG.dateTimeFormat[IG.config.language];
        }

        if (typeof res == "undefined") {
            res = IG.dateTimeFormat["en"];
        }

        return res;
    },

    //Returns the current datetime format:


    //Returns an array of month names given language key:
    getMonthNames: function(language) {
        var res = null;

        if (typeof language != "undefined") {
            res = IG.monthNames[language];
        }
        else {
            res = IG.monthNames[IG.config.language];
        }

        if (typeof res == "undefined") {
            res = IG.monthNames["en"];
        }

        return res;
    },

    //Returns an array of short month names given language key:
    getMonthNamesShort: function(language) {
        var res = null;

        if (typeof language != "undefined") {
            res = IG.monthNamesShort[language];
        }
        else {
            res = IG.monthNamesShort[IG.config.language];
        }

        if (typeof res == "undefined") {
            res = IG.monthNamesShort["en"];
        }
        return res;
    },

    //Returns an array of day names given language key:
    getDayNames: function(language) {
        var res = null;

        if (typeof language != "undefined") {
            res = IG.dayNames[language];
        }
        else {
            res = IG.dayNames[IG.config.language];
        }

        if (typeof res == "undefined") {
            res = IG.dayNames["en"];
        }
        return res;
    },

    //Returns an array of short day names given language key:
    getDayNamesShort: function(language) {
        var res = null;

        if (typeof language != "undefined") {
            res = IG.dayNamesShort[language];
        }
        else {
            res = IG.dayNamesShort[IG.config.language];
        }

        if (typeof res == "undefined") {
            res = IG.dayNamesShort["en"];
        }
        return res;
    },


    //Checks if the given dimensions match the window size:
    checkFit: function(w, h) {
        return ($(window).width() > w && $(window).height() > h);
    },

    //Returns list of resolutions options matching given ratio:
    checkSizes: function(ratio) {
        var _this = this;
        var resolutions = [];

        //Default full screen option:
        var objOptFullScreen = document.createElement("option");
        $(objOptFullScreen)
		        .text(IG.label.get("full_screen"))
		        .val("fullscreen");
        resolutions.push(objOptFullScreen);

        //Fetch available resolutions for the given aspect ratio:
        $.each(IG.customerConfig.previewAspectRatios[ratio], function(i, n) {
            var size = n.split(":");
            if (_this.checkFit(size[0], size[1])) {
                var objOption = document.createElement("option");
                $(objOption)
                        .text(n)
                        .val(n);

                resolutions.push(objOption);
            }
        });

        return resolutions;
    },


    //Shows/hides modal dialog overlay/blanket:
    blanket: {
        get: function() {
            var id = "ig-blanket";
            var obj = $("#" + id).get(0);
            if (!obj) {
                obj = document.createElement("div");
                $(obj)
                    .attr("id", id)
                    .appendTo(document.body)
                    .hide();
            }
            return obj;
        },
        show: function() {
            $(IG.gui.blanket.get()).show();
        },
        hide: function() {
            $(IG.gui.blanket.get()).hide();
        }
    },


    //Handle loading animation:
    loading: {
    	isShown : false,
    	timer : null,

        //Show loading animation:
        show: function(objParent, id, bOverlay) {

            //Parent object in which to place loading animation - defaults to main area:
            objParent = (typeof objParent != "undefined" ? objParent : $("#ig-data").get(0));

            //Id of loading animation:
            id = (typeof id != "undefined" ? id : "ig-data-loading");

            //Show loading animation as an overlay or inline image?
            bOverlay = (typeof bOverlay !== "undefined" ? !!bOverlay : true);

            var obj = $("#" + id).get(0);
            if (!obj) {
                this.isShown = true;

                if (bOverlay) {
                    obj = document.createElement("div");
                    $(obj)
                        .attr("id", id)
                        .addClass("ig-loading")
                        .css("opacity", "0.7")
                        .appendTo(objParent);

                    // Set a timer to prevent the loader to stay for ever
                    var _thisLoader = this;
                    var timeOutTime = 180000; // 3 mins = 3*60*1000 = 180000

                    // clear old timeout when creating a new
                    if (_thisLoader.timer !== null) {
                        clearTimeout(_thisLoader.timer);
                    }

                    _thisLoader.timer = setTimeout(function() {
                    	if (_thisLoader.isShown) {
                    		_thisLoader.hide();
                            IG.showErrorDialog(IG.label.get("unknown_error_popup_message"));
                    	}
                    }, timeOutTime);
                }
                else {
                    obj = document.createElement("span");
                    $(obj)
                        .attr("id", id)
                        .addClass("ig-loading")
                        .appendTo(objParent);
                }

            }
        },
        //Hide loading animation:
        hide: function(id) {
            id = (typeof id != "undefined" ? id : "ig-data-loading");
            $("#" + id).remove();
            this.isShown = false;
            if (this.timer !== null) {
            	window.clearTimeout(this.timer);
            }
        }
    }
};


/* Contains various helper functions for string manipulation, value comparisons, formatting etc. */
IG.fn = {
    /**
     * @method createRawInfoObject
     * @param {Object} options
     * @param {String} options.parentid
     * @param {String|undefined} options.type Optional document type. Defaults to "standard".
     * @param {integer} displayStartTime
     * @param {integer} displayEndTime
     * @returns {Object}
     */
    createRawInfoObject: function(options, displayStartTime, displayEndTime) {

        options = (options !== 'undefined' ? options : {});
        options.type = (typeof options.type != "undefined" ? options.type : "standard");
        options.parentid = (typeof options.parentid !== "undefined" && options.parentid !== "" ? [options.parentid] : []);

        return {
            id: 0,
            type: options.type,
            headline: {},
            subHeadline: {},
            body: {},
            created: IG.fn.dateToTimestamp(IG.getCurrentUTCDate()),
            lastModified: IG.fn.dateToTimestamp(IG.getCurrentUTCDate()),
            displayStartTime: displayStartTime,
            displayEndTime: displayEndTime,
            metadata: {},
            comments: [],
            templateId: null,
            channelIds: [],
            urls: [],
            source: null,
            editor: IG.userConfig.userId,
            tags: [],
            referencedInfoObjectIds: [],
            referencingInfoObjectIds: options.parentid,
            location: {},
            arrangementStartTime: IG.fn.dateToTimestamp(IG.getCurrentUTCDate()),
            arrangementEndTime: IG.fn.dateToTimestamp(dateAdd("d", 1, IG.getCurrentUTCDate())),
            pollOptions: [],
            published: false,
            categoryIds: [],
            mediaIds: [],
            exposureDuration: 30,
            permissions: {
                users: {
                    "OP_INFO_OBJECT_DELETE": [IG.userConfig.userId],
                    "OP_INFO_OBJECT_READ": [IG.userConfig.userId],
                    "OP_INFO_OBJECT_WRITE": [IG.userConfig.userId]
                },
                groups: {}
            },
            geoLocations: [],
            matchingReferencedInfoObjectIds: [],
            fieldGroups: {}
        }
    },

    hasCustomDocumentTypes: function() {
        var result = false;
        $.each(IG.lists.documenttypes, function(i, dt) {
            result = true;
        });
        return result;
    },

    // look up galleries with specified IDs - won't work with more than 100 ids
    searchGalleries: function(ids, callback) {
        var filters = {
            ids: ids
        };
        var params = [IG.config.language, IG.config.language, '', false, filters, {}, 0, 0];
        IG.request({
            method: 'searchGalleries',
            params: params,
            success: function(response) {
                if (response.result) {
                    $.each(response.data.data, function(i, gal) {
                        IG.lists.galleries[gal.id.id] = gal;
                    });
                }
                if ($.isFunction(callback)) {
                    callback();
                }
            },
            error: function(err) {
                if ($.isFunction(callback)) {
                    callback();
                }
            }
        });
    },

    // Clean up infoobject. Remove unwanted/deprecated values like __isobj:
    cleanUpDocument : function(document) {
        if (typeof document.headline['__isobj'] !== 'undefined') {
            delete document.headline['__isobj'];
        }
        if (typeof document.headline['__isObj'] !== 'undefined') {
            delete document.headline['__isObj'];
        }

        if (typeof document.subHeadline['__isobj'] !== 'undefined') {
            delete document.subHeadline['__isobj'];
        }
        if (typeof document.subHeadline['__isObj'] !== 'undefined') {
            delete document.subHeadline['__isObj'];
        }

        if (typeof document.body['__isobj'] !== 'undefined') {
            delete document.body['__isobj'];
        }
        if (typeof document.body['__isObj'] !== 'undefined') {
            delete document.body['__isObj'];
        }

        if (document.editor !== null) {
            if (typeof document.editor['__isobj'] !== 'undefined') {
                delete document.editor['__isobj'];
            }
            if (typeof document.editor['__isObj'] !== 'undefined') {
                delete document.editor['__isObj'];
            }
        }

        if (typeof document.metadata['__isObj'] !== 'undefined') {
            delete document.metadata['__isObj'];
        }
        if (typeof document.metadata['__isobj'] !== 'undefined') {
            delete document.metadata['__isobj'];
        }


        if (document.hasOwnProperty('location')) {
          if (typeof document.location['__isObj'] !== 'undefined') {
              delete document.location['__isObj'];
          }
          if (typeof document.location['__isobj'] !== 'undefined') {
              delete document.location['__isobj'];
          }
        }

        return document;
    },

    cleanUpChannel: function(channel) {
        if ($.isArray(channel.metadata)) {
            channel.metadata = {};
        }
        return channel;
    },

    cleanUpGallery: function(gallery) {
        if (typeof gallery.metadata === 'undefined' || $.isArray(gallery.metadata)) {
            gallery.metadata = {};
        }
        if (typeof gallery.rssIconMediaId === 'undefined'
                || (gallery.rssIconMediaId !== null && (gallery.rssIconMediaId.customerId == null || gallery.rssIconMediaId.id == null))) {
            gallery.rssIconMediaId = null;
        }

        return gallery;
    },

    cleanUpMedium: function(medium) {
        if (typeof medium.source === 'undefined' || $.isArray(medium.source)) {
            medium.source = {};
        }
        if (medium.name.hasOwnProperty('__isobj') || medium.name.hasOwnProperty('__isObj')) {
            delete medium.name['__isobj'];
            delete medium.name['__isObj'];
        }
        if (medium.source.hasOwnProperty('__isobj') || medium.source.hasOwnProperty('__isObj')) {
            delete medium.source['__isobj'];
            delete medium.source['__isObj'];
        }
        if (medium.description.hasOwnProperty('__isobj') || medium.description.hasOwnProperty('__isObj')) {
            delete medium.description['__isobj'];
            delete medium.description['__isObj'];
        }
        if (medium.inThePicture.hasOwnProperty('__isobj') || medium.inThePicture.hasOwnProperty('__isObj')) {
            delete medium.inThePicture['__isobj'];
            delete medium.inThePicture['__isObj'];
        }
        $.each(medium.regions, function(i, region) {
            if (region.description === null) {
                region.description = {};
            }
            if (region.description.hasOwnProperty('__isobj') || region.description.hasOwnProperty('__isObj')) {
                delete region.description['__isobj'];
                delete region.description['__isObj'];
            }
        });
        return medium;
    },

    //Strips markup from a string:
    stripHTML: function(s) {
        if (typeof s == "undefined") {
            s = "";
        }
        else if (s == null) {
            s = "";
        }

        //Create a dummy element and then fetch the text
        var objDummy = document.createElement("div");
        $(objDummy).html(s);
        s = $(objDummy).text()+"";
        s = s.replace(new RegExp("<", 'g'), "&lt;").replace(new RegExp(">", 'g'), "&gt;");
        objDummy = null;

        return s;
    },

    //Truncates a string to specified length and adds ellipsis:
    truncate: function(str, length) {
        var res = IG.fn.stripHTML(str);
        if (res.length > length + 3) {
            res = (res).substring(0, length - 3) + "...";
        }
        return res;
    },

    //Checks if string is a valid URL:
    isValidUrl: function(url) {
        var rx = /^(http|https|ftp):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
        return rx.test(url);
    },

    //Compares two geoLocation objects by basic values:
    geoLocationsEqual: function(a, b) {
        var res = true;

        if (a && b) {
            res = res && (a.address1 == b.address1);
            res = res && (a.address2 == b.address2);
            res = res && (a.city == b.city);
            res = res && (a.zip == b.zip);
            res = res && (a.latitude == b.latitude);
            res = res && (a.longitude == b.longitude);

            if (a.name.length == b.name.length) {
                $.each(a.name, function(i, n) {
                    res = res && (a[i] == b[i]);
                });
            }
            else {
                res = false;
            }
        }

        return res;
    },

    //Return a pseudo-random string (useful for generating ids):
    getRand: function() {
        return IG.fn.getTimestamp() + "-" + Math.floor(Math.random() * 1000);
    },

    //Return current time as unix timestamp
    getTimestamp: function() {
        return IG.fn.dateToTimestamp(IG.getCurrentUTCDate());
    },

    //Returns a unix timestamp given a date
    dateToTimestamp: function(dt) {
        var y = dt.getFullYear();
        var m = dt.getMonth();
        var d = dt.getDate();
        var h = dt.getHours();
        var n = dt.getMinutes();
        //return IG.fn.toTimestamp(y, m, d, h, n, 0);
        return Math.round(Date.UTC(y, m, d, h, n, 0, 0) / 1000);
    },

    //Creates a unix timestamp:
    toTimestamp: function(year, month, day, hour, minute, second) {
        //second = 0;
        //var datum = new Date(Date.UTC(year, month - 1, day, hour, minute, second, 0));
        //return Math.round(datum.getTime() / 1000);
        return Math.round(Date.UTC(year, month, day, hour, minute, 0, 0) / 1000);
    },

    // Converts a unix timestamp to a date
    // time stamps is assumed to be a real UNIX time stamp (ie. UTC time)
    timestampToDate: function(ts) {
        return new Date(ts * 1000);
    },

    // Converts a date string to a unix timestamp (ie a UTC based UNIX time stamp):
    // The string is assumed to represent local time
    dateStrToTimeStamp: function(str) {
        var dt = new Date();

        var y = dt.getFullYear();
        var m = dt.getMonth() + 1;
        var d = dt.getDate();
        var h = dt.getHours();
        var n = dt.getMinutes();
        var rx = /(\d+)[^\d](\d+)[^\d](\d+)[^\d](\d+)[^\d](\d+)/i;
        if (rx.test(str)) {
            switch (IG.config.language) {
                case "da":
                    y = str.replace(rx, "$3");
                    m = str.replace(rx, "$2");
                    d = str.replace(rx, "$1");
                    h = str.replace(rx, "$4");
                    n = str.replace(rx, "$5");
                    break;
                default:
                    y = str.replace(rx, "$3");
                    m = str.replace(rx, "$1");
                    d = str.replace(rx, "$2");
                    h = str.replace(rx, "$4");
                    n = str.replace(rx, "$5");
                    break;
            }
        }
        m = parseInt(m)-1; // m from str is 1-12, while Date takes month as 0-11
        var l = new Date(y, m, d, h, n, 0);
        var utcTimestamp = Date.UTC(l.getUTCFullYear(), l.getUTCMonth(), l.getUTCDate(), l.getUTCHours(), l.getUTCMinutes(), 0);
        return Math.round(utcTimestamp / 1000);
    },


    //Formats a unix timestamp according to config language:
    formatTime: function(s, bTime) {
        bTime = (typeof bTime == "boolean" ? bTime : true);



        var dt = IG.fn.timestampToDate(s);
        // TODO seems to cause an error:
        //dt = dateAdd("h", (dt.getTimezoneOffset() / 60), dt);


        if (bTime) {
            return dt.format(IG.gui.getDateTimeFormat());
        }
        else {
            return dt.format(IG.gui.getDateFormat());
        }
    },

    //Rounds and presents the number v to fixed number with d decimals:
    toFixed: function(v, d) {
        return (Math.round(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
    },

    getInfoObjectStatuses: function() {
        var result = [];
        result.push({
            caption: IG.label.get("info_object_list_published"),
            filter: "public"
        });
        result.push({
            caption: IG.label.get("info_object_list_to_be_published"),
            filter: "publishing"
        });
        result.push({
            caption: IG.label.get("info_object_list_expired"),
            filter: "expired"
        });
        result.push({
            caption: IG.label.get("info_object_list_draft"),
            filter: "draft"
        });
        return result;
    },

    //Returns status for an InfoObject:
    getInfoObjectStatus: function(item) {
        var status = {};

        if (item.published) {
            var now = this.getTimestamp();
            var d = this.formatTime(now, true);
            var d2 = this.formatTime(item.displayStartTime, true);

            if (now >= item.displayStartTime && now < item.displayEndTime) {
                status = {
                    caption: IG.label.get("info_object_list_published"),
                    statusClass: "published"
                }
            }
            else if (now < item.displayStartTime) {
                status = {
                    caption: IG.label.get("info_object_list_to_be_published"),
                    statusClass: "to_be"
                };
            }
            else if (now >= item.displayEndTime) {
                status = {
                    caption: IG.label.get("info_object_list_expired"),
                    statusClass: "expired"
                };
            }
        }
        else {
            status = {
                caption: IG.label.get("info_object_list_draft"),
                statusClass: "draft"
            };
        }

        return status;
    },

    getMediaThumbnailUrl: function(media, lblCallback) {


        var result = '',
            lbl = null;
        if (media.thumbnailMediaFiles.length > 0) {
            var thumb = media.thumbnailMediaFiles[0];
            result = thumb.url;
        } else {
            lbl = IG.label.get('MEDIA_NO_THUMBNAIL_YET');
            result = "https://static.redia.dk/redia/nothumb-v2.png";
        }
        if ($.isFunction(lblCallback)) {
            lblCallback(lbl);
        }
        return result;
    },

    //Sorts a list of id objects b to comply with the order of id objects list a:
    sortIds: function(a, b) {
        var res = [];
        if ($.isArray(a) && $.isArray(b)) {
            $.each(a, function(i, n) {
                $.each(b, function(j, m) {
                    if (IG.IdsEqual(n, m)) {
                        res.push(m);
                        return false; //break inner loop
                    }
                });
            });
        }
        return res;
    },

    //Sorts a list of objects according to a list of ids:
    sortObjects: function(ids, objects) {
        var res = [];
        if ($.isArray(ids) && $.isArray(objects)) {
            $.each(ids, function(i, n) {
                $.each(objects, function(j, m) {
                    if (n.id == m.id["id"]) {
                        res.push(m);
                        return false; //break loop
                    }
                });
            });
        }
        return res;
    },
    //Get size of associative array
    objSize:function(obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    },

    channelExistsInIGList: function(channelIdObj) {
        var chnExists = false;
        $.each(IG.lists.channels, function(i, chnObj) {
            if (IG.IdsEqual(channelIdObj, chnObj.id)) {
                chnExists = true;
                return false;
            }
        });
        return chnExists;
    },

    updateChannelInIGList: function(channelObj) {
        if (typeof channelObj !== 'undefined' && channelObj.id) {
            var chnExists = false;
            $.each(IG.lists.channels, function(i, chnObj) {
                if (IG.IdsEqual(channelObj.id, chnObj.id)) {
                    IG.lists.channels[i] = channelObj;
                    chnExists = true;
                    return false;
                }
            });
            if (!chnExists) {
                IG.lists.channels.push(channelObj);
            }
        }
    },

    removeChannelFromIGList: function(channelIdObj) {
        if (typeof channelIdObj !== 'undefined' && channelIdObj.id) {
            $.each(IG.lists.channels, function(i, chnObj) {
                if (IG.IdsEqual(channelIdObj, chnObj.id)) {
                    IG.lists.channels.splice(i, 1);
                    return false;
                }
            });
        }
    }
};


IG.getCurrentUTCDate = function() {
    var localTime = new Date();
    var utcTime = new Date(
        localTime.getUTCFullYear(),
        localTime.getUTCMonth(),
        localTime.getUTCDate(),
        localTime.getUTCHours(),
        localTime.getUTCMinutes(),
        0,
        0);

    return utcTime;
};


/* Functions for creating new objects: */
IG.create = {
    //Create an info object:
    infoobject: function(options) {
        options = (typeof options != "undefined" ? options : {});
        options.type = (typeof options.type != "undefined" ? options.type : "standard");
        options.forceSync = (typeof options.forceSync != "undefined" ? options.forceSync : false);
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        options.parentid = (typeof options.parentid !== "undefined" && options.parentid != "" ? options.parentid : '');

        var displayStartTime = null; // An integer
        var displayEndDate = new Date(); // A Date object
        if (typeof options.displayStart !== 'undefined' && !isNaN(parseInt(options.displayStart))) {
            displayStartTime = parseInt(options.displayStart);
            displayEndDate = new Date(displayStartTime * 1000);
        } else {
            displayStartTime = IG.fn.dateToTimestamp(IG.getCurrentUTCDate());
        }

        if (typeof IG.customerConfig.defaultPublishDuration !== 'undefined'
                && !isNaN(IG.customerConfig.defaultPublishDuration)) {
            displayEndDate.setSeconds( displayEndDate.getSeconds() + parseInt(IG.customerConfig.defaultPublishDuration));
        } else {
            displayEndDate.setUTCMonth(displayEndDate.getUTCMonth() + 1);
        }
        var displayEndTime = Math.round(displayEndDate.getTime() / 1000);
        var newInfoObject = IG.fn.createRawInfoObject(options, displayStartTime, displayEndTime);

        //Set headline, subheadline and body texts:
        $.each(IG.customerConfig.contentLanguages, function(i, n) {
            newInfoObject.headline[i] = "";
            newInfoObject.subHeadline[i] = "";
            newInfoObject.body[i] = "";
            newInfoObject.location[i] = "";
        });

        options.success(newInfoObject);

        //Attempt to create the new InfoObject:
        //        IG.request({
        //            method: "updateInfoObject",
        //            params: [newInfoObject, options.forceSync, true],
        //            success: function(response) {
        //                if (response.result) {
        //                    options.success(response.data);
        //                }
        //                else {
        //                    options.error(response.message);
        //                }
        //            },
        //            error: function(err) {
        //                options.error(err);
        //            }
        //        });

    },

    //Create a channel:
    channel: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });

        var newChannelObject = {
            id: 0,
            infoObjectIds: [],
            metadata: {},
            name: {},
            description: {},
            permissions: {
                users: {
                    "OP_CHANNEL_DELETE": [IG.userConfig.userId],
                    "OP_CHANNEL_READ": [IG.userConfig.userId],
                    "OP_CHANNEL_WRITE": [IG.userConfig.userId]
                },
                groups: {}
            },
            created: IG.fn.dateToTimestamp(IG.getCurrentUTCDate()),
            updated: IG.fn.dateToTimestamp(IG.getCurrentUTCDate()),
            sharedWithCustomerIds: [],
            infoObjectCount: 0
        };

        //Set name and descriptions:
        $.each(IG.customerConfig.contentLanguages, function(i, n) {
            newChannelObject.name[i] = "";
            newChannelObject.description[i] = "";
        });

        options.success(newChannelObject);

        //Attempt to create the new Channel:
        //        IG.request({
        //            method: "updateChannel",
        //            params: [newChannelObject],
        //            success: function(response) {
        //                if (response.result) {
        //                    options.success(response.data);
        //                }
        //                else {
        //                    options.error(response.message);
        //                }
        //            },
        //            error: function(err) {
        //                options.error(err);
        //            }
        //        });
    },

    //Create a gallery:
    gallery: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });

        var newGalleryObject = {
            id: 0,
            name: {},
            title: {},
            description: {},
            rssEnabled: false,
            rssUrl: "",
            rssName: "",
            rssIconMediaId: null,
            interactive: false,
            moduleId: null,
            skinId: {},
            ownerUserId: IG.userConfig.userId,
            channelIds: [],
            infoObjectCount: 0,
            screenshotThumbUrl: "",
            screenshotThumbTime: 0,
            metadata: {},
            permissions: {
                users: {
                    "OP_GALLERY_DELETE": [IG.userConfig.userId],
                    "OP_GALLERY_READ": [IG.userConfig.userId],
                    "OP_GALLERY_WRITE": [IG.userConfig.userId]
                },
                groups: {}
            },
            created: IG.fn.dateToTimestamp(IG.getCurrentUTCDate()),
            updated: IG.fn.dateToTimestamp(IG.getCurrentUTCDate())
        };

        $.each(IG.customerConfig.contentLanguages, function(i, n) {
            newGalleryObject.name[i] = "";
            newGalleryObject.title[i] = "";
            newGalleryObject.description[i] = "";
        });

        options.success(newGalleryObject);
        //        //Attempt to create the new Gallery:
        //        IG.request({
        //            method: "updateGallery",
        //            params: [newGalleryObject],
        //            success: function(response) {
        //                if (response.result) {
        //                    options.success(response.data);
        //                }
        //                else {
        //                    options.error(response.message);
        //                }
        //            },
        //            error: function(err) {
        //                options.error(err);
        //            }
        //        });
    },

    //Create a user:
    user: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        options.name = (typeof options.name != "undefined" ? options.name : "");
        options.username = (typeof options.middlename != "undefined" ? options.middlename : "");
        options.password = (typeof options.kod != "undefined" ? options.kod : "");

        var newUser = {
            name: options.name,
            userName: options.username,
            profileImageUrl: "",
            password: options.password,
            permissions: [
                "OP_INFO_OBJECT_CREATE",
                "OP_INFO_OBJECT_LIST",
                "OP_MEDIA_CREATE",
                "OP_MEDIA_LIST",
                "OP_CHANNEL_CREATE",
                "OP_CHANNEL_LIST",
                "OP_GALLERY_CREATE",
                "OP_GALLERY_LIST"
            ],
            lastLogin: 0,
            defaultCreatePermissions: {
                "groups": {},
                "users": {}
            }
        };

        IG.request({
            method: "updateUser",
            params: [newUser],
            success: function(response) {
                if (response.result) {
                    IG.lists.users.push(response.data);
                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                //options.success(newUser);
                options.error(err.message);
            }
        });
    },

    userGroup: function(options) {
        options = (typeof options != "undefined" ? options : {});
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });

        var newUserGroup = {
            name: options.name,
            userIds: []
        };

        IG.request({
            method: "updateUserGroup",
            params: [newUserGroup, true, IG.config.language],
            success: function(response) {
                if (response.result) {
                    IG.lists.groups.push(response.data);
                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err.message);
            }
        });
    }
};


/* Functions for removing items: */
IG.remove = {
    media: function(options) {
        options = (typeof options != "undefined" ? options : {});
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        IG.request({
            method: "deleteMedia",
            params: [IG.config.language, options.id, true],
            success: function(response) {
                if (response.result) {
                    options.success(response.data);
                } else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err);
            }
        });
    },

    userGroup: function(options) {
        options = (typeof options != "undefined" ? options : {});
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        IG.request({
            method: "deleteUserGroup",
            params: [options.id, IG.config.language],
            success: function(response) {
                if (response.result) {
                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err.message);
            }
        });
    },

    //Deletes a User
    user: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        IG.request({
            method: "deleteUser",
            params: [options.id, IG.config.language],
            success: function(response) {
                if (response.result) {
                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err.message);
            }
        });
    },

    //Deletes an InfoObject:
    infoobject: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        options.parent = (typeof options.parent != "undefined" ? options.parent : false);

        IG.request({
            method: "deleteInfoObject",
            params: [options.id, IG.config.language, true, options.parent, false],
            success: function(response) {
                if (response.result) {
                    IG.documentTitleCache.unset(options.id);
                    options.success();
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err);
            }
        });
    },

    //Deletes a channel:
    channel: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });

        IG.request({
            method: "deleteChannel",
            params: [options.id, IG.config.language],
            success: function(response) {
                if (response.result) {
                    IG.fn.removeChannelFromIGList(options.id);
                    options.success();
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err);
            }
        });
    },

    //Deletes a gallery:
    gallery: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });

        IG.request({
            method: "deleteGallery",
            params: [options.id, IG.config.language],
            success: function(response) {
                if (response.result) {
                    if ($.isFunction(options.success)) {
                        options.success();
                    }
                }
                else {
                    if ($.isFunction(options.error)) {
                        options.error(response.message);
                    }
                }
            },
            error: function(err) {
                if ($.isFunction(options.error)) {
                    options.error(err);
                };
            }
        });
    }
};


/* Functions for copying objects */
IG.copy = {
    infoobject: function(options) {
        IG.debug("IG.copy.infoobject");
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });

        IG.request({
            method: "copyInfoObject",
            params: [options.id],
            success: function(response) {
                if (response.result) {
                    options.success( IG.fn.cleanUpDocument(response.data) );
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err);
            }
        });

    }
};

/* Functions for updating objects */
IG.update = {
    media: function(options) {
        options = (typeof options != "undefined" ? options : {});
        options.success == (typeof options.success ? options.success : function() { });
        options.error = (typeof options.error ? options.error : function() { });

        IG.request({
            method: "updateMedia",
            params: [IG.config.language, options.media, true],
            success: function(response) {
                if (response.result) {
                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                var errorMsg = err.message ? err.message : err;
                options.error(errorMsg);
            }
        });
    },

    userGroup: function(options) {
        options = (typeof options != "undefined" ? options : {});
        options.success == (typeof options.success ? options.success : function() { });
        options.error = (typeof options.error ? options.error : function() { });
        var userGroup = {
            id: options.id,
            name: options.name,
            userIds: options.userIds
        };

        IG.request({
            method: "updateUserGroup",
            params: [userGroup, true, IG.config.language],
            success: function(response) {
                if (response.result) {
                    //Update local grouplist with updated data:
                    $.each(IG.lists.groups, function(i, g) {
                        if (IG.getId(g.id) == IG.getId(options.id.id)) {
                            IG.lists.groups[i] = userGroup;
                            return false;
                        }
                    });

                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                //options.success(userGroup);
                options.error(err.message);
            }
        });
    },

    //updates user
    user: function(options) {
        options = (typeof options !== 'undefined' ? options : {});
        options.success = (typeof options.success ? options.success : function() { });
        options.error = (typeof options.error ? options.error : function() { });

        IG.request({
            method: "updateUser",
            params: [options.object],
            success: function(response) {
                if (response.result) {
                    //Update local userlist with updated data:
                    $.each(IG.lists.users, function(i, n) {
                        if (IG.getId(n.id) == IG.getId(options.object.id)) {
                            IG.lists.users[i] = options.object;
                            return false;
                        }
                    });

                    //Updated own profile?
                    if (IG.getId(options.object.id) == IG.getId(IG.userConfig.userId)) {
                        IG.userConfig.permissions = options.object.permissions;
                    }

                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.success('null');
                //options.error(err.message);
            }
        });
    },

    //Updates an InfoObject:
    infoobject: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        options.isParent = (typeof options.isParent != "undefined" ? options.isParent : false);
        options.forceSync = (typeof options.forceSync !== 'undefined' ? !!options.forceSync : false);

        // Make sure there is a fieldGroups object
        if (typeof options.object.fieldGroups == "undefined") {
        	options.object.fieldGroups = {};
        }

        // if it's an empty Array overwrite with an object
        if (options.object.fieldGroups instanceof Array && options.object.fieldGroups.length == 0) {
        	options.object.fieldGroups = {};
        }

        // Make sure there is a fieldGroups object
        if (typeof options.object.fieldGroups == "undefined") {
            options.object.fieldGroups = {};
        }

        // if it's an empty Array overwrite with an object
        if (options.object.fieldGroups instanceof Array && options.object.fieldGroups.length == 0) {
            options.object.fieldGroups = {};
        }

        IG.request({
            method: "updateInfoObject",
            params: [options.object, options.forceSync, options.isParent],
            post: true,
            success: function(response) {
                if (response.result) {
                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                if (err.message) {
                    options.error(err.message);
                }
                else {
                    options.error(err);
                }
            }
        });
    },

    //Updates a Channel:
    channel: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });


        IG.request({
            method: "updateChannel",
            //post: true,
            params: [options.object],
            success: function(response) {
                if (response.result) {
                    options.success(response.data);
                    IG.fn.updateChannelInIGList(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err);
            }
        });
    },

    //Updates a Gallery:
    gallery: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        var gallery = options.object;

        var newGalleryObject = {
            id: gallery.id,
            name: {},
            title: {},
            description: {},
            rssEnabled: gallery.rssEnabled,
            rssUrl: gallery.rssUrl,
            rssName: gallery.rssName,
            rssIconMediaId: gallery.rssIconMediaId,
            interactive: gallery.interactive,
            touchscreen: gallery.touchscreen,
            moduleId: gallery.moduleId,
            ownerUserId: gallery.ownerUserId,
            channelIds: [],
            infoObjectCount: gallery.infoObjectCount,
            screenshotThumbUrl: gallery.screenshotThumbUrl,
            screenshotThumbTime: gallery.screenshotThumbTime,
            metadata: gallery.metadata,
            skinId: gallery.skinId,
            permissions: {
                users: gallery.permissions.users,
                groups: gallery.permissions.groups
            },
            created: gallery.created,
            updated: IG.fn.dateToTimestamp(IG.getCurrentUTCDate())
        };

        $.each(gallery.channelIds, function(i, c) {
            newGalleryObject.channelIds.push(c);
        });

        $.each(IG.customerConfig.contentLanguages, function(i, n) {
            newGalleryObject.name[i] = gallery.name[i];
            newGalleryObject.title[i] = gallery.title[i];
            newGalleryObject.description[i] = gallery.description[i];
        });

        IG.request({
            method: "updateGallery",
            post: true,
            params: [newGalleryObject],
            success: function(response) {
                if (response.result) {
                    options.success(response.data);
                }
                else {
                    options.error(response.message);
                }
            },
            error: function(err) {
                options.error(err);
            }
        });
    },

    //Updates profile (ie. the User object for current user):
    profile: function(options) {
        IG.update.user(options);
    }
};


/* Functions for loading single, deep objects */
IG.load = {

    //Loads an InfoObject given id:
    infoobject: function(options) {

        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        options.id = (typeof options.id == "string" ? options.id : "");
        options.searchLang = (typeof options.searchLang == "string" ? options.searchLang : IG.customerConfig.defaultContentLanguage);

        IG.request({
            method: "searchInfoObjects",
            params: [
                IG.config.language,
                options.searchLang,
                "",
                false,
                { ids: [{ id: options.id, customerId: IG.config.customerId}] },
                {},
                1,
                0
            ],
            success: function(response) {
                if (response.result) {
                    var responseData = response.data["data"];
                    var retObj = (typeof responseData != "undefined" ? responseData[0] : null);

                    if (typeof retObj !== "undefined" && retObj != null) {
                        retObj = IG.fn.cleanUpDocument(retObj);
                        options.success(retObj);
                    }
                    else {
                        IG.location.setCurrent(null); // to prevent Hubber from trying to load the unknown document again
                        options.error(IG.label.get("infoobject_unknown_infoobject"));
                    }
                }
                else {
                    IG.location.setCurrent(null); // to prevent Hubber from trying to load the unknown document again
                    options.error(IG.label.get("infoobject_error_retreiving"));
                }
            },
            error: function(err) {
                IG.location.setCurrent(null); // to prevent Hubber from trying to load the unknown document again
                options.error(err);
            }
        });

    },

    //Loads a Channel given id:
    channel: function(options) {
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });
        options.id = (typeof options.id == "string" ? options.id : "");
        options.searchLang = (typeof options.elementsLang == "string" ? options.elementsLang : IG.customerConfig.defaultContentLanguage);

        IG.request({
            method: "searchChannels",
            params: [
                IG.config.language,
                options.searchLang,
                "",
                false,
                { ids: [{ id: options.id, customerId: IG.config.customerId}] },
                {},
                1,
                0
            ],
            success: function(response) {
                if (response.result) {
                    var responseData = response.data["data"]; ;
                    var retObj = (typeof responseData != "undefined" ? responseData[0] : null);

                    if ((typeof retObj != "undefined") && (retObj != null)) {
                        retObj = IG.fn.cleanUpChannel(retObj);
                        options.success(retObj);
                    }
                    else {
                        IG.location.setCurrent(null); // to prevent Hubber from trying to load the unknown channel again
                        options.error(IG.label.get("channel_unknown_channel"));
                    }
                }
                else {
                    IG.location.setCurrent(null); // to prevent Hubber from trying to load the unknown channel again
                    options.error(IG.label.get("channel_error_retrieving"));
                }
            },
            error: function(err) {
                IG.location.setCurrent(null); // to prevent Hubber from trying to load the unknown channel again
                options.error(err);
            }
        });
    }
};



//Checks if two id objects are equal - i.e. both customerId and id parts match
IG.IdsEqual = function(a, b) {
    var res = false;
    if ((typeof a == "object") && (a != null) && (typeof b == "object") && (b != null)) {
        if ((typeof a.customerId == "string") && (typeof b.customerId == "string") && (typeof a.id == "string") && (typeof b.id == "string")) {
            res = (a.customerId == b.customerId) && (a.id == b.id);
        }
    }
    return res;
};

//Returns the id string part of an Infogalleri id object (validates argument):
IG.getId = function(id) {
    var res = "";
    if (typeof id !== "undefined" && id != null) {
        if (typeof id == "object") {
            if (typeof id["id"] != "undefined") {
                res = id["id"];
            }
        } else if (typeof id == "string") {
            res = id;
        }
    }
    return res;
};

IG.getIdFromString = function(idString) {
    var res = null;
    if (typeof idString !== 'undefined' && idString !== null && idString.length > 0) {
        var splitId = idString.split('::');
        if ($.isArray(splitId) && splitId.length == 2) {
            res = {
                customerId: splitId[0],
                id: splitId[1]
            }
        }
    }
    return res;
};

//Returns the channel matching given id:
IG.getUserGroupById = function(id) {
    var res = null;
    id = IG.getId(id);

    if (id != "") {
        $.each(IG.lists.groups, function(i, n) {
            if (n.id['id'] == id) {
                res = n;
                return false;
            }
        });
    }
    return res;
};


//Returns the channel matching given id:
IG.getChannelById = function(id) {
    var res = null;
    id = IG.getId(id);

    if (id != "") {
        $.each(IG.lists.channels, function(i, n) {
            if (n.id['id'] == id) {
                res = n;
                return false;
            }
        });
    }
    return res;
};

//Returns the gallery matching given id:
IG.getGalleryById = function(id, callback) {
    var res = null;
    var idString = IG.getId(id);

    if (idString != "") {
        if (typeof IG.lists.galleries[idString] !== 'undefined') {
            res = IG.lists.galleries[idString];
        }
    }
    if (res === null) {
        // try to find the requested channel on the server
        IG.fn.searchGalleries([id], function() {
            if (typeof IG.lists.galleries[idString] !== 'undefined') {
                res = IG.lists.galleries[idString];
            }
            if ($.isFunction(callback)) {
                callback(res);
            }
        });
    } else {
        if ($.isFunction(callback)) {
            callback(res);
        }
    }
};

//Returns the user matching given id:
IG.getUserById = function(id) {
    var res = null;
    id = IG.getId(id);

    if (id != "") {
        $.each(IG.lists.users, function(i, n) {
            if (IG.getId(n.id) == id) {
                res = n;
                return false;
            }
        });
    }
    return res;
};

//Returns the template matching given id:
IG.getTemplateById = function(id) {
    var res = null;
    id = IG.getId(id);

    if (id != "") {
        $.each(IG.lists.templates, function(i, n) {
            if (n.id['id'] == id) {
                res = n;
                return false;
            }
        });
    }
    return res;
};

//Returns the category matching given id:
IG.getCategoryById = function(id) {
    var c = null;
    id = IG.getId(id);

    if (id != "") {
        //Check main categories:
        $.each(IG.lists.categories, function(i, n) {
            if (n.id['id'] == id) {
                c = n;
                return false; //break loop
            }
        });

        //Check subcategories:
        if (c == null) {
            c = IG.getSubCategory(IG.lists.subcategories, c, id);
        }
    }
    return c;
};

//Recursively search sub-categories with the given id:
IG.getSubCategory = function(list, c, id) {
    $.each(list, function(i, n) {
        if (n.id['id'] == id) {
            c = n;
            return false; //break loop
        }
        if (n.childCategories.length > 0) {
            c = IG.getSubCategory(n.childCategories, c, id);
            if (c !== null) {
                return false; //break loop
            }
        }
    });
    return c;
};

//Returns the content source matching given id:
IG.getContentSourceById = function(id) {
    var res = null;
    id = IG.getId(id);

    if (id != "") {
        $.each(IG.lists.contentsources, function(i, n) {
            if (n.id["id"] == id) {
                res = n;
                return false;
            }
        });
    }
    return res;
};


/**
 * Get the document type definition from a type name.
 * @param typeName {string} The unique document type identifier.
 * @returns {object|null} Either the document type definition or null if it isn't found.
 */
IG.getDocumentType = function(typeName) {
    var result = null;
    if (typeof IG.maps.documentTypes[typeName] !== 'undefined') {
        result = IG.maps.documentTypes[typeName];
    } else {
        $.each(IG.lists.documenttypes, function(k, dt) {
            IG.maps.documentTypes[dt.typeName] = dt;
            if (dt.typeName === typeName) {
                result = dt;
            }
        });
    }
    return result;
};

/**
 * Return the translated document type name
 * @param typeName {string} The unique document type identifier.
 * @returns {String} Either the translated name or the input type string.
 */
IG.getDocumentTypeName = function(typeName) {
    var result = typeName;
    var dt = IG.getDocumentType(typeName);
    if (dt !== null) {
        if (typeof dt.name[IG.config.language] !== 'undefined') {
            result = dt.name[IG.config.language];
        }
    }
    return result;
};



//Checks if current user has specified permission p (for optional object o):
IG.userHasPermission = function(p, o) {
    var _IG = this;

    //Check supplied object (may be infoobject, gallery, channel)
    if (typeof o == "undefined") {
        o = null;
    }

    if (IG.session.active()) {
        //No object supplied - check basic user permissions:
        if (o == null) {
            if (_IG.userConfig.permissions && _IG.userConfig.permissions.length > 0) {
                return ($.inArray(p, _IG.userConfig.permissions) > -1);
            }
        }
        //Check supplied object permissions:
        else {
            if (typeof o.permissions != "undefined") {
                var bFound = false;

                //Not enforced; all users currently have these read permissions:
                switch (p) {
                    case "OP_CHANNEL_READ":
                    case "OP_GALLERY_READ":
                    case "OP_GROUP_READ":
                    case "OP_INFO_OBJECT_READ":
                    case "OP_MEDIA_READ":
                        bFound = true;
                        break;
                }

                //This object was originally created by the current user:

                if (typeof o.editor == "object") {
                    bFound = _IG.IdsEqual(o.editor, _IG.userConfig.userId);
                }


                if (typeof o.ownerUserId == "object") {
                    bFound = _IG.IdsEqual(o.ownerUserId, _IG.userConfig.userId);
                }


                if (!bFound) {
                    //Check if current user has desired permission:
                    if (typeof o.permissions.users[p] != "undefined") {
                        $.each(o.permissions.users[p], function(i, n) {
                            if (_IG.IdsEqual(n, _IG.userConfig.userId)) {
                                bFound = true;
                                return false; //break loop
                            }
                        });
                    }
                }

                //compare group permisions with user configuration groups
                if (!bFound) {
                    //Check if a group the current user belongs to has desired permission:
                    if (typeof o.permissions.groups[p] != "undefined") {
                        $.each(o.permissions.groups[p], function(i, n) {
                            if (!bFound) {
                                $.each(_IG.userConfig.groupIds, function(j, m) {
                                    if (IG.IdsEqual(n,m)) {
                                        bFound = true;
                                        return false; //break loop
                                    }
                                });
                            }
                            else {
                                return false; //break loop;
                            }
                        });
                    }
                }

                //check group permisions with all group userids
                if (!bFound) {
                    //Check if a group the current user belongs to has desired permission:
                    if (typeof o.permissions.groups[p] != "undefined") {
                        $.each(o.permissions.groups[p], function(i, n) {
                            if (!bFound) {
                                $.each(_IG.lists.groups, function(j, m) {
                                    if (n.id == m.id.id) {
                                        if ($.inArray(_IG.userConfig.userId, m.userIds) > -1) {
                                            bFound = true;
                                            return false; //break loop
                                        }
                                    }
                                });
                            }
                            else {
                                return false; //break loop;
                            }
                        });
                    }
                }
                return bFound;
            }
        }
    }
    return false;
};


/* Checks if current customer has the desired functionality enabled */
IG.customerHasFunctionality = function(key) {
    var _IG = this;

    var b = false;
    if (_IG.customerConfig != null) {
        if (_IG.customerConfig.functionality) {
            $.each(_IG.customerConfig.functionality, function(i, n) {
                if (n.id == key && n.enabled == true) {
                    b = true;
                    return false; //break loop;
                }
            });
        }
    }


    return b;
};






/* Setup default behavior for all form elements (input, textarea etc.) */
IG.initFormElements = function() {
    //Trim text and textarea input fields:
    $("input[type=text],textarea").on("blur", function() {
        $(this).val($.trim($(this).val()));
    });

    //Handle clicks on section headers:
    $(document).on("click", "div.ig-edit-section > h4", function() {
        var obj = $(this).parent();
        if (obj.hasClass("collapsable")) {
            obj.toggleClass("open");
        }
    });
};

IG.users = {
    refreshInterval: 2, // minutes
    intervalId: null,

    loadUsers: function(callback) {
        var _IG = IG;
        var _this = this;

        var usersCountTotal = 0;
        var usersCountLoaded = 0;
        var usersLoaded = [];

        var maxCalls = 50; // just to make sure that the recursion breaks!
        var counter = 0;

        var loadUsersBatch = function() {

            _IG.request({
                method: 'searchUsers',
                params: [_IG.config.language, "", true, { enabled: true }, { name: 'ASC' }, 0, usersCountLoaded],
                success: function(response) {
                    if (response.result) {
                        usersCountTotal = response.data['count'];
                        usersCountLoaded = usersCountLoaded + response.data['data'].length;

                        $.each(response.data['data'], function(i, user) {
                            if (typeof user.defaultCreatePermissions !== 'undefined') {
                                if ($.isArray(user.defaultCreatePermissions.groups)) {
                                    user.defaultCreatePermissions.groups = {};
                                }
                                if ($.isArray(user.defaultCreatePermissions.users)) {
                                    user.defaultCreatePermissions.users = {};
                                }
                            }
                        });

                        usersLoaded = usersLoaded.concat(response.data['data']);

                        if (usersCountLoaded < usersCountTotal && counter < maxCalls && response.data['data'].length > 0) {
                            // Help IE8 get a break
                            window.setTimeout(function() {
                                loadUsersBatch();
                                counter++;
                            }, 10);
                        } else {
                            // Done loading:
                            _IG.lists.users = usersLoaded;

                            if ($.isFunction(callback)) {
                                callback();
                            }
                        }
                    } else {
                        window.clearInterval(_this.intervalId);
                        IG.showErrorDialog('Something went wrong when updating the list of users.');
                    }
                },
                error: function(err) {
                    window.clearInterval(_this.intervalId);
                    IG.showErrorDialog(err);
                }
            });
        };
        loadUsersBatch();
    },

    initRefresh: function() {
        var _this = this;
        _this.intervalId = window.setInterval(
            function() {
                _this.loadUsers();
            },
            Math.round(_this.refreshInterval * 60 * 1000)
        );
    },

    loadEnabled: function(options, callback) {
        if (typeof options === 'undefined') {
            if ($.isFunction(callback)) {
                callback(null);
            }
            return false;
        }
        options.limit = (typeof options.limit !== 'undefined' ? options.limit : 10);
        options.offset = (typeof options.offset !== 'undefined' ? options.offset : 0);
        options.count = (typeof options.count !== 'undefined' ? options.count : 0);

        IG.request({
            method: 'searchUsers',
            params: [
                IG.config.language, // language
                '', // query
                true, // light weight
                {   // filters
                    'enabled': true
                },
                { name: 'ASC' }, // sort
                options.limit, // limit
                options.offset // offset
            ],
            success: function(response) {
                if (response.result) {
                    options.count = response.data.count;

                    if ($.isFunction(callback)) {
                        callback(response.data);
                    }
                } else {
                    if ($.isFunction(callback)) {
                        callback(null);
                    }
                }
            },
            error: function(err) {
                IG.showErrorDialog('Something went wrong when getting the list of users.');
            }
        });
    }
};


/* Initialize upon login: */
IG.initWhenLoggedIn = function() {
    var _IG = this;
    var channelCount = 0;

    // Refresh list of users now and then:
    IG.users.initRefresh();

    // remove special class from body tag
    $('body').removeClass('not-authenticated');

    //Things to do when done fetching configs etc.:
    var fn = function() {
        // needs to be done before rendering
        loadAllChannels(function() {
            _IG.destroyLogin();
            _IG.buildUserMenu();
            _IG.buildHeaderMenu();
            _IG.location.handle(true);
        })
    };

    _IG.users.loadUsers(function() {

        _IG.batchRequest({
            requests: [
                {
                    method: "getCustomerConfiguration",
                    params: [_IG.config.language, _IG.config.customerId],
                    success: function(response) {
                        if (response.result) {
                            _IG.customerConfig = response.data;
                        }
                        else {
                            //Could not load customer configuration ...
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: "getUserConfiguration",
                    params: [_IG.config.language],
                    success: function(response) {
                        if (response.result) {
                            _IG.userConfig = response.data;
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: 'searchUserGroups',
                    params: [_IG.config.language, "", true, {}, { name: 'ASC'}],
                    success: function(response) {
                        if (response.result) {
                            _IG.lists.groups = response.data['data'];
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    // just get the channel count, hence limit = 1
                    method: 'searchChannels',
                    params: [_IG.config.language, _IG.customerConfig.defaultContentLanguage, "", true, {}, {}, 1, 0],
                    success: function(response) {
                        if (response.result) {
                            channelCount = response.data.count;
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: 'searchGalleries',
                    params: [_IG.config.language, IG.customerConfig.defaultContentLanguage, "", true, {}, {}, 0, 0],
                    success: function(response) {
                        if (response.result) {
                            $.each(response.data['data'], function(i, gal) {
                                _IG.lists.galleries[gal.id.id] = gal;
                            });
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: 'getTemplates',
                    params: [],
                    success: function(response) {
                        if (response.result) {
                            _IG.lists.templates = response.data;
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: 'getCategories',
                    params: [_IG.config.language],
                    success: function(response) {
                        if (response.result) {
                            _IG.lists.categories = response.data;
                            $.each(_IG.lists.categories, function(key, cat) {
                                $.each(cat.childCategories, function(key1, subcat1) {
                                    _IG.lists.subcategories[_IG.lists.subcategories.length] = subcat1;
                                });
                            });
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: 'getContentSources',
                    params: [],
                    success: function(response) {
                        if (response.result) {
                            _IG.lists.contentsources = response.data;
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: 'getModules',
                    params: [_IG.config.customerId, _IG.config.language],
                    success: function(response) {
                        if (response.result) {
                            _IG.lists.modules = response.data;
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: 'getSkins',
                    params: [_IG.config.customerId, _IG.config.language],
                    success: function(response) {
                        if (response.result) {
                            _IG.lists.skins = response.data;
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {

                    method: 'getTags',
                    params: [_IG.config.language, _IG.config.customerId],
                    success: function(response) {
                        if (response.result) {
                            _IG.lists.tags = response.data;
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                },
                {
                    method: 'getDocumentTypes',
                    params: [_IG.config.customerId, _IG.config.language],
                    success: function(response) {
                        if (response.result) {
                            _IG.lists.documenttypes = response.data;
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                }
            ],
            success: function(response) {
                fn();
            },
            error: function(err) {
                //Something bad happened ...
                IG.showErrorDialog(err);
                fn();
            }
        });

    });

    var limit = 100;
    var offset = 0;
    var recursionCounter = 0; // for making sure that the recursion stops
    function loadAllChannels(callback) {
        loadChannels(limit, offset, channelLoadDone);

        function channelLoadDone() {
            offset += limit;
            if (offset < channelCount-1 && recursionCounter < 50) {
                // help IE8 get its breath:
                window.setTimeout(function() {
                    loadChannels(limit, offset, channelLoadDone);
                    recursionCounter++;
                }, 10);
            } else {
                callback();
            }
        }
    }

    function loadChannels(limit, offset, callback) {
        IG.request({
            method: 'searchChannels',
            params: [IG.config.language, IG.config.language, "", true, {}, {updated: "ASC"}, limit, offset],
            success: function(response) {
                if (response.result) {
                    $.each(response.data['data'], function(i, chnObj) {
                        _IG.lists.channels.push(chnObj);
                    });
                }
                callback();
            },
            error: function(err) {
                IG.showErrorDialog(err);
            }
        });
    }
};


/*
Builds the right side menu with user image, edit, logout, help links
Depends on data retrieved from getUserConfiguration
*/

IG.RoutingActions = {
    showDashboard() {
        if (IG.infoObjectChanged()) {
            IG.showInfoObjectChangedDialog({
                target: window,
                confirm: function() {
                    IG.showDashboard({ history: true });
                }
            });
        } else {
            IG.showDashboard({ history: true });
        }
    },
    showInfoObjectList() {
        if (IG.infoObjectChanged()) {
            IG.showInfoObjectChangedDialog({
                target: window,
                confirm: function() {
                    IG.showInfoObjectList({ history: true });
                }
            });
        } else {
            IG.showInfoObjectList({ history: true });
        }
    },
    showChannels() {
        if (IG.infoObjectChanged()) {
            IG.showInfoObjectChangedDialog({
                target: window,
                confirm: function() {
                    IG.showChannelList({ history: true });
                }
            });
        } else {
            IG.showChannelList({ history: true });
        }
    },
    showGalleries() {
        if (IG.infoObjectChanged()) {
            IG.showInfoObjectChangedDialog({
                target: window,
                confirm: function() {
                    IG.showGalleryList({ history: true });
                }
            });
        } else {
            IG.showGalleryList({ history: true });
        }
    },

}

IG.buildUserMenu = function() {
    _IG = this;
    // if (_IG.userConfig) {
    //     ReactDOM.render(<HeaderMenu RoutingActions={IG.RoutingActions} labels={_IG.label} userConfig={_IG.userConfig}
    //                                 userHasPermission={_IG.userHasPermission.bind(this)}/>, document.getElementById('ig-header-menu'));
    // }

    // make the logo a link to the dashboard
    $('#ig-header-logo').click(function(e) {
        e.preventDefault();
        if (_IG.infoObjectChanged()) {
            _IG.showInfoObjectChangedDialog({
                target: window,
                confirm: function() {
                    _IG.showDashboard({ history: true });
                }
            });
        } else {
            _IG.showDashboard({ history: true });
        }
        return false;
    });

//    return;
    var _IG = this;

    if (_IG.userConfig) {

        // make the logo a link to the dashboard
        $('#ig-header-logo').click(function(e) {
            e.preventDefault();
            if (_IG.infoObjectChanged()) {
                _IG.showInfoObjectChangedDialog({
                    target: window,
                    confirm: function() {
                        _IG.showDashboard({ history: true });
                    }
                });
            } else {
                _IG.showDashboard({ history: true });
            }
            return false;
        });



        // Menu button, user:
        var welcomeLabel = _IG.label.get("HEADER_USER_WELCOME", _IG.config.language);
        welcomeLabel = welcomeLabel.replace("{0}", IG.userConfig.name);

        var objMenuUser = document.createElement('div');
        $(objMenuUser)
            .addClass('menu-user')
            .appendTo('#ig-header');

        var objDivWelcome = document.createElement("a");
        $(objDivWelcome).text(welcomeLabel).appendTo(objMenuUser);


        // Options dropdown, user
        var objDivOptions = document.createElement("div");
        $(objDivOptions).attr("id", "ig-user-menu-options");


        var objAEditProfile = document.createElement("a");
        $(objAEditProfile)
            .text(IG.label.get("right_menu_profile"))
            .click(function(event) {
                event.preventDefault();
                $(objDivWelcome).qtip('hide');

                if (_IG.infoObjectChanged()) {
                    _IG.showInfoObjectChangedDialog({
                        target: window,
                        confirm: function() {
                            _IG.showEditProfile({
                                id: _IG.userConfig.userId["id"],
                                history: true
                            });
                        }
                    });
                } else {
                    _IG.showEditProfile({
                        id: _IG.userConfig.userId["id"],
                        history: true
                    });
                }
                return false;
            })
            .appendTo(objDivOptions);

        var objALogOut = document.createElement("a");
        $(objALogOut)
            .text(IG.label.get("right_menu_logout"))
            .click(function() {
                IG.gui.blanket.show();
                IG.session.deauthenticate(function() {
                    IG.location.setCurrent(null); // important on logout
                    IG.location.front();
                });
                return false;
            })
            .appendTo(objDivOptions);

        var objAHelp = document.createElement("a");
        /* Disabled until we have actual up-to-date help
         * documents
        $(objAHelp)
            .text(IG.label.get("right_menu_help"))
            .click(function() {
                alert("FIXME");
                return false;
            })
            .appendTo(objDivOptions);
        */

        $(objDivWelcome).qtip({
            content: {
                text: objDivOptions,
                title: false
            },
            show: {
                event: 'click',
                delay: 0
            },
            hide: {
                event: 'unfocus click',
                delay: 0
            },
            position: {
                my: 'right top',
                at: 'right bottom'
            },
            style: {
                classes: 'qtip-menu',
                width: $(objMenuUser).width(),
                def: false,
                background: 'none',
                tip: false
            }
        });


        //Sections - depends on user's current priviliges:
        if (_IG.userConfig.permissions && (_IG.userConfig.permissions.length > 0)) {
            var objDivSections = document.createElement("div");
            $(objDivSections)
                .attr("id", "ig-user-menu-sections")
                .appendTo('#ig-header-menu');

            var objUL = document.createElement("ul");
            $(objUL).appendTo(objDivSections);

            var p = _IG.userConfig.permissions;

            //Dashboard:
            var objLI = document.createElement("li");
            $(objLI).appendTo(objUL);

            var objA = document.createElement("a");
            $(objA)
                .text(IG.label.get("right_menu_dashboard"))
                .addClass('dashboard')
                .click(function() {
                    if (_IG.infoObjectChanged()) {
                        _IG.showInfoObjectChangedDialog({
                            target: window,
                            confirm: function() {
                                _IG.showDashboard({ history: true });
                            }
                        });
                    } else {
                        _IG.showDashboard({ history: true });
                    }
                    return false;

                })
                .appendTo(objLI);


            //Info objects:
            if (_IG.userHasPermission("OP_INFO_OBJECT_LIST")) {
                var objLI = document.createElement("li");
                $(objLI).appendTo(objUL);

                var objA = document.createElement("a");
                $(objA)
                    .text(IG.label.get("right_menu_info_objects"))
                    .addClass('documents')
                    .click(function() {
                        if (_IG.infoObjectChanged()) {
                            _IG.showInfoObjectChangedDialog({
                                target: window,
                                confirm: function() {
                                    _IG.showInfoObjectList({ history: true });
                                }
                            });
                        } else {
                            _IG.showInfoObjectList({ history: true });
                        }
                        return false;
                    })
                    .appendTo(objLI);
            }

            //Channels:
            if (_IG.userHasPermission("OP_CHANNEL_LIST")) {
                var objLI = document.createElement("li");
                $(objLI).appendTo(objUL);

                var channelsLabel = IG.label.get("right_menu_channels");
                var objA = document.createElement("a");
                $(objA)
                    .text(channelsLabel)
                    .addClass('channels')
                    .click(function() {
                        if (_IG.infoObjectChanged()) {
                            _IG.showInfoObjectChangedDialog({
                                target: window,
                                confirm: function() {
                                    _IG.showChannelList({ history: true });
                                }
                            });
                        } else {
                            _IG.showChannelList({ history: true });
                        }
                        return false;
                    })
                    .appendTo(objLI);
            }

            //Galleries:
            if (_IG.userHasPermission("OP_GALLERY_LIST")) {
                var objLI = document.createElement("li");
                $(objLI).appendTo(objUL);

                var objA = document.createElement("a");
                $(objA)
                    .text(IG.label.get("right_menu_galleries"))
                    .addClass('galleries')
                    .click(function() {
                        if (_IG.infoObjectChanged()) {
                            _IG.showInfoObjectChangedDialog({
                                target: window,
                                confirm: function() {
                                    _IG.showGalleryList({ history: true });
                                }
                            });
                        } else {
                            _IG.showGalleryList({ history: true });
                        }
                        return false;
                    })
                .appendTo(objLI);
            }


            // Extra menus
            var menuExtraContent = [];

            //User list:
            if (_IG.userHasPermission("OP_USER_ADMIN")) {
                menuExtraContent.push({
                    label: IG.label.get("right_menu_users"),
                    classes: 'users',
                    onClick: function(e) {
                        e.preventDefault();
                        $(objLI).qtip('hide');

                        if (_IG.infoObjectChanged()) {
                            _IG.showInfoObjectChangedDialog({
                                target: window,
                                confirm: function() {
                                    _IG.showEditUsers({ history: true });
                                }
                            });
                        } else {
                            _IG.showEditUsers({ history: true });
                        }
                        return false;
                    }
                });
            }

            // Media gallery
            var mediaArchiveSingle = null;
            menuExtraContent.push({
                label: IG.label.get('edit_infoobject_media_archive'),
                classes: 'media',
                onClick: function(e) {
                    e.preventDefault();
                    $(objLI).qtip('hide');

                    if (mediaArchiveSingle === null) {
                        mediaArchiveSingle = new IG.MediaArchive({
                            hasContext: false,
                            elementsLang: IG.customerConfig.defaultContentLanguage,
                            onload: function() {
                                // console.log('DEBUG: media archive onload');
                            },
                            onMediaRemoved: function(media) {
                                // Do nothing
                            },
                            onResized: function() {
                                // Do nothing
                            }
                        });
                    }
                    mediaArchiveSingle.show();

                    return false;
                }
            });

            if (menuExtraContent.length === 1) {
                var objLI = document.createElement("li");
                $(objLI).appendTo(objUL);

                var objA = document.createElement("a");
                $(objA)
                    .text(menuExtraContent[0].label)
                    .addClass(menuExtraContent[0].classes)
                    .click(menuExtraContent[0].onClick)
                    .appendTo(objLI);
            } else if (menuExtraContent.length > 1) {
                var objLI = document.createElement("li");
                $(objLI).appendTo(objUL);

                var objA = document.createElement("a");
                $(objA)
                    .text(IG.label.get('TOP_MENU_MORE'))
                    .addClass('more')
                    .appendTo(objLI);

                var elemTipMenuItems = document.createElement('div');

                $.each(menuExtraContent, function(i, mItem) {
                    var objA = document.createElement("a");
                    $(objA)
                        .text(mItem.label)
                        .click(mItem.onClick)
                        .appendTo(elemTipMenuItems);
                });

                $(objLI).qtip({
                    content: {
                        text: elemTipMenuItems,
                        title: false
                    },
                    show: {
                        event: 'click',
                        delay: 0
                    },
                    hide: {
                        event: 'unfocus click',
                        delay: 0
                    },
                    position: {
                        my: 'left top',
                        at: 'left bottom'
                    },
                    style: {
                        classes: 'qtip-menu',
                        width: false,
                        def: false,
                        background: 'none',
                        tip: false
                    }
                });


            }


            //Text list:
            /*if (_IG.userHasPermission("OP_TEXT_LIST")) {
                var objLI = document.createElement("li");
                $(objLI).appendTo(objUL);

                var objA = document.createElement("a");
                $(objA)
                    .text(IG.label.get("right_menu_texts"))
                    .click(function() {
                        if (_IG.infoObjectChanged()) {
                            _IG.showInfoObjectChangedDialog({
                                target: window,
                                confirm: function() {
                                    alert("FIXME");
                                }
                            });
                        } else {
                            alert("FIXME");
                        }
                        return false;
                    })
                    .appendTo(objLI);
            }*/
        }

        var kontaktLabel = IG.label.get("right_menu_contact");
        $("#footer-kontakt").text(kontaktLabel);

        var supportLabel = IG.label.get("right_menu_support");
        $("#footer-support").text(supportLabel);
    }
};

IG.infoObjectChanged = function() {
    var _IG = this;
    var infoObjectChanged = false;
    var objEditInfoObject = _IG.objects.get("infoobject");
    if (objEditInfoObject != null) {
        infoObjectChanged = objEditInfoObject.itemHasChanged;
    }
    return infoObjectChanged;
};

IG.showInfoObjectChangedDialog = function(options) {
    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.confirm = (typeof options.confirm == "function" ? options.confirm : function() { });
    options.target = (typeof options.target != "undefined" ? options.target : window);
    options.text = (typeof options.text === "string" ? options.text : IG.label.get("right_menu_infoobject_changed_popup_text"));
    options.title = (typeof options.title === "string" ? options.title : '');

    var dialog = IG.createConfirmDialog({
        title: options.title,
        text: options.text,
        onConfirm: function() {
            var objEditInfoObject = _IG.objects.get("infoobject");
            objEditInfoObject.itemHasChanged = false;
            dialog.close();
            options.confirm();
        },
        target: options.target
    });
};

IG.showMissingHeadlineDialog = function(options) {
    options = (typeof options != "undefined" ? options : {});
    options.close = ($.isFunction(options.close) ? options.close : function() { });
    options.text = (typeof options.text === "string" ? options.text : IG.label.get("INFOOBJECT_MISSING_HEADLINE"));
    var objDialog = IG.showErrorDialog(options.text, options.close);
};

/* Builds top menu */
IG.buildHeaderMenu = function()
{
    //return;
    var _IG = this;

    //Only show if user is logged in and has permission to create info objects:
    if (_IG.userConfig && _IG.userHasPermission("OP_INFO_OBJECT_CREATE")) {

        var objDocsCreateNew = document.createElement('div');
        $(objDocsCreateNew)
            .addClass('menu-pink menu-link')
            .appendTo('#ig-user-menu-sections');

        var objDocsCreateNewInner = document.createElement('div');
        $(objDocsCreateNewInner)
            .text(IG.label.get('GLOBAL_CREATE_NEW'))
            .addClass('create-doc')
            .appendTo(objDocsCreateNew);


        //Define available types:
        var arrBuildInTypes = [
            {
                title: IG.label.get("top_menu_standard_info_object"),
                typeName: "standard",
                key: "infoobjects",
                enabled: false
            },
            {
                title: IG.label.get("top_menu_arrangement_info_object"),
                typeName: "arrangement",
                key: "arrangements",
                enabled: false
            },
            {
                title: IG.label.get("top_menu_poll_info_object"),
                typeName: "poll",
                key: "polls",
                enabled: false
            }
        ];

        //Assume we may not show types:
        var bShowBuildInTypes = false;

        //Check each type and check if it is enabled for this customer:
        $.each(arrBuildInTypes, function(i, n) {
            n.enabled = IG.customerHasFunctionality(n.key);
            //At least one item is enabled - go ahead and show the selector:
            if (n.enabled) {
                bShowBuildInTypes = true;
            }
        });

        var customTypesEnabled = IG.customerHasFunctionality('customDocTypes');

        var elemTipDocTypes = document.createElement("div");

        //Build create InfoObject links for each supported type:
        if (bShowBuildInTypes) {


            $.each(arrBuildInTypes, function(i, n) {
                if (n.enabled) {
                    var objA = document.createElement("a");
                    $(objA)
                        .text(n.title)
                        .click(function(event) {
                            event.preventDefault();
                            $(objDocsCreateNew).qtip('hide');

                            if (IG.infoObjectChanged()) {
                                IG.showInfoObjectChangedDialog({
                                    target: window,
                                    confirm: function() {
                                        IG.gui.loading.show();

                                        _IG.create.infoobject({
                                            type: n.typeName,
                                            forceSync: true,
                                            success: function(obj) {

                                                IG.showEditInfoObject({
                                                    object: obj,
                                                    type: n.typeName,
                                                    isNew: true,
                                                    history: true
                                                });

                                                IG.gui.loading.hide();
                                            },
                                            error: function(err) {
                                                IG.gui.loading.hide();
                                                IG.showErrorDialog(err);
                                            }
                                        });
                                    }
                                });
                            } else {
                                IG.gui.loading.show();

                                _IG.create.infoobject({
                                    type: n.typeName,
                                    forceSync: true,
                                    success: function(obj) {
                                        _IG.showEditInfoObject({
                                            object: obj,
                                            type: n.typeName,
                                            isNew: true,
                                            history: true
                                        });

                                        IG.gui.loading.hide();
                                    },
                                    error: function(err) {
                                        IG.gui.loading.hide();
                                        IG.showErrorDialog(err);
                                    }
                                });
                            }
                            return false;
                        })
                        .appendTo(elemTipDocTypes);
                }
            });
        }

        if (customTypesEnabled) {

            // Sort the document types
            var aName = '', bName='';
            _IG.lists.documenttypes.sort(function(a, b) {
                aName = IG.getDocumentTypeName(a.typeName);
                bName = IG.getDocumentTypeName(b.typeName);

                if (aName > bName) {
                    return 1;
                } else if (aName < bName) {
                    return -1;
                }
                return 0;
            });

            $.each(_IG.lists.documenttypes, function(i, dt) {

                if (typeof dt['hiddenInPublish'] !== 'undefined' && dt['hiddenInPublish']) {
                    return;
                }

                var elemADocType = document.createElement("a");
                $(elemADocType)
                    .attr('id','doc-type-'+dt.typeName)
                    .attr('data-doc-type', dt.typeName)
                    .attr('title', IG.getDocumentTypeName(dt.typeName))
                    .text(IG.getDocumentTypeName(dt.typeName))
                    .click(function(event) {
                        event.preventDefault();
                        $(objDocsCreateNew).qtip('hide');

                        if (IG.infoObjectChanged()) {
                            IG.showInfoObjectChangedDialog({
                                target: window,
                                confirm: function() {
                                    IG.gui.loading.show();

                                    _IG.create.infoobject({
                                        type: dt.typeName,
                                        forceSync: true,
                                        success: function(obj) {

                                            IG.showEditInfoObject({
                                                object: obj,
                                                type: dt.typeName,
                                                isNew: true,
                                                history: true
                                            });

                                            IG.gui.loading.hide();
                                        },
                                        error: function(err) {
                                            IG.gui.loading.hide();
                                            IG.showErrorDialog(err);
                                        }
                                    });
                                }
                            });
                        } else {

                            IG.gui.loading.show();

                            _IG.create.infoobject({
                                type: dt.typeName,
                                forceSync: true,
                                success: function(obj) {
                                    _IG.showEditInfoObject({
                                        object: obj,
                                        type: dt.typeName,
                                        isNew: true,
                                        history: true
                                    });

                                    IG.gui.loading.hide();
                                },
                                error: function(err) {
                                    IG.gui.loading.hide();
                                    IG.showErrorDialog(err);
                                }
                            });
                        }
                    })
                    .appendTo(elemTipDocTypes);

            });
        }




        $(objDocsCreateNew).qtip({
            content: {
                text: elemTipDocTypes,
                title: false
            },
            show: {
                event: 'click',
                delay: 0
            },
            hide: {
                event: 'unfocus click',
                delay: 0
            },
            position: {
                my: 'left top',
                at: 'left bottom'
            },
            style: {
                classes: 'qtip-menu',
                width: false,
                def: false,
                background: 'none',
                tip: false
            }
        });


/* TODO remove, new Hubber
        //Build customer logo:
        var logoImg = document.createElement("img");
        $(logoImg).attr({
            "src": IG.customerConfig.logoUrl,
            "id": "img-logo",
            "alt": "logo"
        });

        var logoImgLink = document.createElement("a");
        $(logoImgLink).append([logoImg]);

        $("#ig-header").append([logoImgLink]);*/
    }

};

/* Builds login menu for right side: */
IG.buildLogin = function() {

    $('body').addClass('not-authenticated');

    var _IG = this;

    var obj = $("#ig-data").get(0);
    if (obj) {
        var objLoginWrap = document.createElement('div');
        $(objLoginWrap)
            .appendTo(obj)
            .attr("id", "ig-login-menu");


        var objLoginHeader = document.createElement('h2');
        $(objLoginHeader)
            .text(_IG.label.get('frontpage_login'))
            .appendTo(objLoginWrap);



        var objDiv = document.createElement("form");
        $(objDiv)
            .addClass('form-wrap');


        //Create username label and input:
        var objLabelUsr = document.createElement("label");
        $(objLabelUsr)
            .attr({
                "for": "login-username",
                "id": "label-username"
            })
            .data("label", "frontpage_username")
            .text(_IG.label.get("frontpage_username") + ":")
            .appendTo(objDiv);


        var objInputUsr = document.createElement("input");
        $(objInputUsr)
            .attr({
                "type": "text",
                "name": "username",
                "id": "login-username",
                "autocomplete": "username"
            })
            .keypress(function(e) {
                var key = e.which ? e.which : e.keyCode;
                if (key == 13) {
                    $(this).blur();
                    $('#login-button').focus().click();
                }
            })
            .appendTo(objDiv);

        //Create password label and input:
        var objLabelPwd = document.createElement("label");
        $(objLabelPwd)
            .attr({
                "for": "login-password",
                "id": "label-password"
            })
            .data("label", "frontpage_password")
            .text(_IG.label.get("frontpage_password") + ":")
            .appendTo(objDiv);

        var objInputPwd = document.createElement("input");
        $(objInputPwd)
            .attr({
                "type": "password",
                "name": "password",
                "id": "login-password",
                "autocomplete": "password"
            })
            .keypress(function(e) {
                var key = e.which ? e.which : e.keyCode;
                if (key == 13) {
                    jQuery(this).blur();
                    jQuery('#login-button').focus().click();
                }
            })
            .appendTo(objDiv);


        //Create language selector:
        var objDivLang = document.createElement("div");
        $(objDivLang)
            .addClass("languages-container clearfix")
            .appendTo(objDiv);

        var objLabelLang = document.createElement("label");
        $(objLabelLang)
            .text(_IG.label.get("frontpage_select_language") + ':')
            .addClass("radio-label")
            .attr({ "id": "login-language" })
            .appendTo(objDivLang);

        var objSelectLang = document.createElement("select");
        $(objSelectLang)
            .addClass('hubber-select')
            .attr("id", "language-select")
            .change(function() {
                _IG.config.language = this.value;

                //Update labels for login fields when selecting language:
                var lblUsername = _IG.label.get("frontpage_username", this.value);
                if (lblUsername == '') {
                    lblUsername = _IG.label.get("frontpage_username", 'da');
                }
                $('#label-username').text(lblUsername);

                var lblPassword = _IG.label.get("frontpage_password", this.value);
                if (lblPassword == '') {
                    lblPassword = _IG.label.get("frontpage_password", 'da');
                }
                $('#label-password').text(lblPassword);


                var lblLogin = _IG.label.get("frontpage_login", this.value);
                if (lblLogin == '') {
                    lblLogin = _IG.label.get("frontpage_login", 'da');
                }
                $('#login-button').val(lblLogin);

                var lblLanguage = _IG.label.get("frontpage_select_language", this.value);
                if (lblLanguage == '') {
                    lblLanguage = _IG.label.get("frontpage_select_language", 'da');
                }

                $('#login-language').text(lblLanguage + ':');

                var lblRequest = _IG.label.get("frontpage_forgot_password", this.value);
                if (lblRequest == '') {
                    lblRequest = _IG.label.get("frontpage_forgot_password", "da");
                }
                if (lblRequest != '') {
                    $("#login-request").text(lblRequest);
                }
            })
            .appendTo(objDivLang);

        $.each(_IG.customerConfig.interfaceLanguages, function(key, currLanguage) {
            var objOption = document.createElement("option");
            $(objOption)
                .text(currLanguage)
                .attr({
                    "value": key
                })
                .appendTo(objSelectLang);

            var userLanguage = _IG.customerConfig.defaultContentLanguage;
            var res = $.cookie("ig-language");
            if (res != null) {
                userLanguage = IG.config.language;
            }

            if (key == userLanguage) {
                $(objOption).attr("selected", "selected");
            }
        });


        var btnLogin = document.createElement("a");
        $(btnLogin)
            .addClass('btn-normal btn-dark')
            .data("label", "frontpage_login")
            .attr({
                "type": "button",
                "id": "login-button"
            })
            .html('<span>'+_IG.label.get("frontpage_login")+'</span>')
            .click(function() {
                var progressId = 'login-progress';
                    _IG.gui.loading.show(objDiv, progressId);

                    _IG.session.authenticate({
                        usr: $(objInputUsr).val(),
                        pwd: $(objInputPwd).val(),
                        success: function() {
                            _IG.setLanguage($(objSelectLang).val());
                            _IG.initWhenLoggedIn();
                        },
                        failure: function() {
                            IG.showErrorDialog(IG.label.get("login_fail_message"));
                            _IG.gui.loading.hide(progressId);
                        },
                        error: function(err) {
                            IG.showErrorDialog(err);
                            _IG.gui.loading.hide(progressId);
                        }
                    });
                $(this).blur();
                return false;
            })
            .appendTo(objDiv);


        var fnBuildRequestLoginDialogContent = function() {
            var objDiv = document.createElement("div");
            $(objDiv).addClass("request-login");

            var strText = IG.label.get("forgot_password_enter_email_message");

            var objDivContent = document.createElement("div");
            $(objDivContent).addClass("content").appendTo(objDiv);

            var objText = document.createElement("p");
            $(objText)
                .attr("id", "ig-request-login-text")
                .text(strText)
                .appendTo(objDivContent);

            var objTextResponse = document.createElement("p");
            $(objTextResponse)
                .attr("id", "ig-request-login-response")
                .addClass("response")
                .appendTo(objDivContent);

            var objLabel = document.createElement("label");
            $(objLabel)
                .attr("for", "ig-request-login-email")
                .text(IG.label.get("forgot_password_email") + ' ')
                .appendTo(objDivContent);

            var objInput = document.createElement("input");
            $(objInput)
                .attr({
                    "type": "text",
                    "name": "ig-request-login-email",
                    "id": "ig-request-login-email"
                })
                .appendTo(objDivContent)
                .wrap('<span class="input"></span>');

            return objDiv;
        };


        var objDivReq = document.createElement("div");
        $(objDivReq)
            .addClass("ig-request-login")
            .appendTo(objDiv);

        //Build forgot password-link and dialog functionality:
        var objAreq = document.createElement("a");
        $(objAreq)
            .attr("id", "login-request")
            .text(IG.label.get("forgot_password_link_text"))
            .click(function() {

                var strTextOK = IG.label.get("forgot_password_email_sent");
                var strTextErr = IG.label.get("forgot_password_email_dont_match");

                $( "#modal-window" )
                    .html(fnBuildRequestLoginDialogContent())
                    .dialog({
                        modal: true,
                        title: IG.label.get("forgot_password_popup_title"),
                        dialogClass: "no-close",
                        position: { my: "center", at: "center", of: window },
                        width: 360,
                        height: 'auto',
                        buttons: [
                            {
                                text: IG.label.get("ok"),
                                click: function() {

                                    var objText = $("#ig-request-login-text").get(0);
                                    var objTextResponse = $("#ig-request-login-response").get(0);
                                    var objInput = $("#ig-request-login-email").get(0);

                                    $(objText).css("visibility", "hidden");
                                    $(objTextResponse)
                                        .text('...')
                                        .removeClass("error")
                                        .css("visibility", "visible");

                                    _IG.request({
                                        method: "emailPasswordResetToken",
                                        params: [_IG.config.customerId, _IG.config.language, $(objInput).val()],
                                        success: function(response) {
                                            if (response.result) {

                                                $('#modal-window').dialog('close');

                                                var fnCreateOkDialog = function() {
                                                    var objDiv = document.createElement("div");
                                                    $(objDiv).addClass("request-login");

                                                    var objDivContent = document.createElement("div");
                                                    $(objDivContent).addClass("content").appendTo(objDiv);
                                                    var strTextOK = IG.label.get("forgot_password_email_sent");

                                                    var objText = document.createElement("p");
                                                    $(objText)
                                                        .attr("id", "ig-request-login-text")
                                                        .text(strTextOK)
                                                        .appendTo(objDivContent);

                                                    return $(objDiv);
                                                };

                                                $( "#modal-window" )
                                                    .html(fnCreateOkDialog())
                                                    .dialog({
                                                        modal: true,
                                                        title: IG.label.get("forgot_password_popup_title"),
                                                        dialogClass: "no-close",
                                                        position: { my: "center", at: "center", of: window },
                                                        width: 300,
                                                        height: 'auto',
                                                        buttons: [
                                                            {
                                                                text: IG.label.get("ok"),
                                                                click: function() {
                                                                    $( this ).dialog( "close" );
                                                                }
                                                            }
                                                        ],

                                                        // reset to defaults
                                                        minWidth: 150,
                                                        maxWidth: false,
                                                        minHeight: 150,
                                                        maxHeight: false
                                                    });
                                            } else {
                                                $('#modal-window').dialog('close');
                                                _IG.showErrorDialog(strTextErr);
                                            }
                                        },
                                        error: function(err) {
                                            $('#modal-window').dialog('close');
                                            _IG.showErrorDialog(err);
                                        }
                                    });


                                }
                            },
                            {
                                text: IG.label.get("cancel"),
                                click: function() {
                                    $('#modal-window').dialog('close');
                                }
                            }
                        ],

                        // reset to defaults
                        minWidth: 150,
                        maxWidth: false,
                        minHeight: 150,
                        maxHeight: false
                    });
            })
            .appendTo(objDivReq);



        $(objLoginWrap).append(objDiv);
        $(objInputUsr).focus();
    }
};

/* Removes login menu: */
IG.destroyLogin = function() {
    $("#ig-login-menu").remove();
};


/* Init function performed when document is ready */
IG.init = function() {
    var _IG = this;


    //Refresh currently selected language:
    _IG.setLanguage(_IG.getLanguage());

    //Setup JSON-RPC behavior:
    _IG.initJSONRPC();

    //Initialize default behavior for all input fields etc.:
    _IG.initFormElements();

    // Set locale globally in Moment JS
    moment.locale(_IG.getLanguage());

    //Separate requests each with their own params, success and error callback functions (optional):
    _IG.batchRequest({

        requests: [
            { //Get user interface texts:
                method: "getUserInterfaceTexts",
                params: [_IG.config.language, _IG.config.customerId],
                success: function(response) {
                    if (response.result) {
                        _IG.label.data = response.data;
                    }
                }
            },
            { //Get customer configuration:
                method: "getCustomerConfiguration",
                params: [_IG.config.language, _IG.config.customerId],
                success: function(response) {
                    if (response.result) {
                        _IG.customerConfig = response.data;
                    }
                }
            },

            { //Get user configuration:
                method: "getUserConfiguration",
                params: [_IG.config.language],
                success: function(response) {
                    if (response.result) {
                        _IG.userConfig = response.data;
                    }
                }
            }
        ],
        success: function(response) {
            if (_IG.session.active()) {
                _IG.initWhenLoggedIn();
            }
            else {
                _IG.buildLogin();
            }
        },
        error: function(err) {
            //something bad happened ...
        }
    });
};




/* Object with methods for handling current session, timeouts, login, logouts etc. */
IG.session = {
    refreshInterval: (typeof IG.sessionRefreshInterval == "number" ? IG.sessionRefreshInterval : 1), //minutes
    intervalId: null,

    //Authenticate user given username and password:
    authenticate: function(options) {
        options = (typeof options == "object" ? options : {});
        options.usr = (typeof options.usr == "string" ? options.usr : "");
        options.pwd = (typeof options.pwd == "string" ? options.pwd : "");
        options.success = (typeof options.success == "function" ? options.success : null);
        options.failure = (typeof options.failure == "function" ? options.failure : null);
        options.error = (typeof options.error == "function" ? options.error : null);

        IG.request({
            method: "authenticate",
            params: [IG.config.customerId, options.usr, options.pwd],
            success: function(response) {
                // Set locale globally in Moment JS
                moment.locale(IG.config.language);

                //Logged in:
                if (response.result) {
                    if (typeof options.success == "function") {
                        options.success();
                    }
                }
                else {
                    if (typeof options.failure == "function") {
                        options.failure();
                    }
                }
            },
            error: function(err) {
                if (typeof options.error == "function") {
                    options.error(err);
                }
            }
        });
    },

    //Deauthenticate user (logout):
    deauthenticate: function(callback) {
        IG.request({
            method: "deauthenticate",
            success: function(response) {
                if (typeof callback == "function") {
                    callback();
                }
            },
            error: function(err) {
                IG.showErrorDialog(err, function() {
                    if (typeof callback == "function") {
                        callback();
                    }
                });
            }
        });
    },

    //Is the session active? I.e. is the user logged in?
    active: function() {
        return (IG.userConfig != null); //FIXME: not pretty
    },

    //Refresh current session by sending small request to server:
    refresh: function() {
        var _this = this;

        if (_this.active()) {
            IG.request({
                method: "refreshSession",
                success: function(response) {
                    //Session timed out:
                    if (response['result'] == false) {
                        clearInterval(IG.users.intervalId);
                        clearInterval(_this.intervalId);
                        IG.showErrorDialog(IG.label.get("session_expired"), function() {
                            IG.gui.blanket.show();
                            IG.location.front();
                        });
                    }
                },

                //Error refreshing session:
                error: function(err) {
                    clearInterval(IG.users.intervalId);
                    clearInterval(_this.intervalId);
                    IG.showErrorDialog(IG.label.get("session_expired"), function() {
                        IG.location.front();
                    });
                }
            });
        }
    },

    initRefresh: function() {
        var _this = this;
        _this.intervalId = setInterval(
                function() {
                    _this.refresh();
                },
                _this.refreshInterval * 60 * 1000
        );
    }
};


/* Handle objectlist persistance */
IG.objList = {
    "lists": {},
    "setFilter" : function(page, filter) {
        if (page == null) {
            return false;
        }
        if (typeof this.lists[page] === "undefined") {
            this.lists[page] = {};
        }
        this.lists[page].filter = filter;
    },
    "getFilter" : function(page) {
        if (page == null) {
            return false;
        }
        if (typeof this.lists[page] !== "undefined" && typeof this.lists[page].filter !== "undefined") {
            return this.lists[page].filter;
        }
        return false;
    },

    "setOrder" : function(page, column, order) {
        if (page == null) {
            return false;
        }
        if (typeof this.lists[page] === "undefined") {
            this.lists[page] = {};
        }
        if (typeof column === "string" && typeof order === "string") {
            this.lists[page].sorting = {
                "col": column,
                "order": order
            };
        }
    },

    "getOrder": function(page) {
        if (page == null) {
            return false;
        }
        if (typeof this.lists[page] !== "undefined" && typeof this.lists[page].sorting !== "undefined") {
            return this.lists[page].sorting;
        }
        return false;
    }
};

// Used by reference lists for caching titles:
IG.documentTitleCache = {
    data: {},
    count: 0,
    maxAge: 30*60*1000, // milliseconds
    searchQueue: {},
    countQueue: 0,
    maxToSearch: 100,

    get: function(id) {
        var idStr = IG.getId(id);
        var nowDate = new Date(),
            nowTime = nowDate.getTime();

        // check existence and age
        if (typeof this.data[idStr] !== 'undefined') {
            if ( (nowTime-this.data[idStr].time) < this.maxAge ) {
                return this.data[idStr].title;
            } else {
                // too old, delete and return
                delete this.data[idStr];
                this.count--;
                return false;
            }
        } else {
            return false;
        }
    },
    set: function(id, title) {

        var idStr = IG.getId(id);
        if (idStr.length > 0 && typeof title === 'object') {
            // if a new one is added, increment count
            if (typeof this.data[idStr] === 'undefined') {
                this.count++;
            }
            var saveDate = new Date(),
                saveTimeMillis = saveDate.getTime();

            this.data[idStr] = {
                title: title,
                time: saveTimeMillis
            };
        }

    },
    unset: function(id) {
        var idStr = IG.getId(id);
        if (typeof this.data[idStr] !== 'undefined') {
            delete this.data[idStr];
            this.count--;
            return true;
        } else {
            return false;
        }
    },
    cleanUp: function() {
        this.searchQueue = {};
        if (this.count > 2000) {
            this.data = {};
        }
    },
    registrer: function(docId, callback) {

        var _this = this;
        if (typeof docId !== 'undefined' && docId.length > 0 && $.isFunction(callback)) {

            if (typeof _this.searchQueue[docId] === 'undefined') {
                _this.searchQueue[docId] = {
                    cbs: [],
                    locked: false
                };
                _this.countQueue++;
            }
            _this.searchQueue[docId].cbs.push(callback);


        }
        if (_this.countQueue >= _this.maxToSearch) {
            _this.triggerSearch();
        }
    },
    triggerSearch: function() {

        var _this = this;

        // Pick document IDs from the searchQueue and search for these
        var idsToSearch = [],
            // cbCallbacks = {},
            id = null,
            count = 0,
            titleCached = false,
            callbackObjs = null;

        for (id in _this.searchQueue) {
            titleCached = _this.get({
                'id': id,
                'customerId': IG.config.customerId
            });

            if (titleCached) {
                callbackObjs = _this.searchQueue[id].cbs.slice(0);
                if ($.isArray(callbackObjs)) {
                    $.each(callbackObjs, function(j, cb) {
                        cb(titleCached);
                    });
                    callbackObjs = null;
                    // break;
                }

            }

            if (count >= _this.maxToSearch) {
                break;
            }

            if (!_this.searchQueue[id].locked && _this.searchQueue[id].cbs.length > 0) {
                idsToSearch.push({
                    'id': id,
                    'customerId': IG.config.customerId
                });

                _this.searchQueue[id].locked = true;
                count++;
                _this.countQueue--;
            }

        }

        if (idsToSearch.length > 0) {
            // console.log('[DEBUG] triggerSearch > sending request; Number of IDs: ', idsToSearch.length);

            IG.request({
                method: "searchInfoObjects",
                params: [
                    IG.config.language,
                    IG.config.language,
                    "",
                    true,
                    {
                        "id": {
                            '$in': idsToSearch
                        }
                    },
                    {}, 0, 0, 2
                ],
                success: function(response) {
                    var documents = response.data["data"];

                    if (response.data.count > 0) {
                        // Save the new title in this.data
                        // Call the callback for each registered doc ID


                        $.each(documents, function(i, doc) {
                            if (typeof _this.searchQueue[doc.id.id] !== 'undefined') {
                                callbackObjs = _this.searchQueue[doc.id.id].cbs.slice(0);
                                _this.searchQueue[doc.id.id].cbs = []; // Empty the now old callbacks
                                // console.log('====> emptying callbacks for ', doc.id);

                                if ($.isArray(callbackObjs)) {

                                    $.each(callbackObjs, function(j, cb) {
                                        _this.set(doc.id, doc.headline);
                                        cb(doc.headline);
                                    });
                                }
                                _this.searchQueue[doc.id.id].locked = false;
                            }
                        });
                    }
                },
                error: function(err) {
                    console.log("err: ", err);
                }
            });
        }
    }
};

// Used by reference lists for caching titles:
IG.channelNameCache = {
    data: {},
    count: 0,
    maxAge: 30*60*1000, // milliseconds
    searchQueue: {},
    countQueue: 0,
    maxToSearch: 100,

    get: function(id) {
        var idStr = IG.getId(id);
        var nowDate = new Date(),
            nowTime = nowDate.getTime();

        // check existence and age
        if (typeof this.data[idStr] !== 'undefined') {
            if ( (nowTime-this.data[idStr].time) < this.maxAge ) {
                return this.data[idStr].title;
            } else {
                // too old, delete and return
                delete this.data[idStr];
                this.count--;
                return false;
            }
        } else {
            return false;
        }
    },
    set: function(id, title) {

        var idStr = IG.getId(id);
        if (idStr.length > 0 && typeof title === 'object') {
            // if a new one is added, increment count
            if (typeof this.data[idStr] === 'undefined') {
                this.count++;
            }
            var saveDate = new Date(),
                saveTimeMillis = saveDate.getTime();

            this.data[idStr] = {
                title: title,
                time: saveTimeMillis
            };
        }

    },
    unset: function(id) {
        var idStr = IG.getId(id);
        if (typeof this.data[idStr] !== 'undefined') {
            delete this.data[idStr];
            this.count--;
            return true;
        } else {
            return false;
        }
    },
    cleanUp: function() {
        this.searchQueue = {};
        if (this.count > 2000) {
            this.data = {};
        }
    },
    registrer: function(docId, callback) {

        var _this = this;
        if (typeof docId !== 'undefined' && docId.length > 0 && $.isFunction(callback)) {

            if (typeof _this.searchQueue[docId] === 'undefined') {
                _this.searchQueue[docId] = {
                    cbs: [],
                    locked: false
                };
                _this.countQueue++;
            }
            _this.searchQueue[docId].cbs.push(callback);


        }
        if (_this.countQueue >= _this.maxToSearch) {
            _this.triggerSearch();
        }
    },
    triggerSearch: function() {
        var _this = this;

        // Pick document IDs from the searchQueue and search for these
        var idsToSearch = [],
            id = null,
            count = 0,
            titleCached = false,
            callbackObjs = null;

        for (id in _this.searchQueue) {
            titleCached = _this.get({
                'id': id,
                'customerId': IG.config.customerId
            });

            if (titleCached) {
                callbackObjs = _this.searchQueue[id].cbs.slice(0);
                if ($.isArray(callbackObjs)) {
                    $.each(callbackObjs, function(j, cb) {
                        cb(titleCached);
                    });
                    callbackObjs = null;
                    // break;
                }

            }

            if (count >= _this.maxToSearch) {
                break;
            }

            if (!_this.searchQueue[id].locked && _this.searchQueue[id].cbs.length > 0) {
                idsToSearch.push({
                    'id': id,
                    'customerId': IG.config.customerId
                });

                _this.searchQueue[id].locked = true;
                count++;
                _this.countQueue--;
            }

        }


        if (idsToSearch.length > 0) {
            // console.log('[IG.channelNameCache] triggerSearch > sending request; Number of IDs: ', idsToSearch.length);

            IG.request({
                method: "searchChannels",
                params: [
                    IG.config.language,
                    IG.config.language,
                    "",
                    true,
                    {
                        "ids": idsToSearch
                    },
                    {}, 0, 0
                ],
                success: function(response) {
                    var documents = response.data["data"];

                    if (response.data.count > 0) {
                        // Save the new title in this.data
                        // Call the callback for each registered doc ID


                        $.each(documents, function(i, doc) {
                            if (typeof _this.searchQueue[doc.id.id] !== 'undefined') {
                                callbackObjs = _this.searchQueue[doc.id.id].cbs.slice(0);
                                _this.searchQueue[doc.id.id].cbs = []; // Empty the now old callbacks
                                // console.log('====> emptying callbacks for ', doc.id);

                                if ($.isArray(callbackObjs)) {

                                    $.each(callbackObjs, function(j, cb) {
                                        _this.set(doc.id, doc.name);
                                        cb(doc.name);
                                    });
                                }
                                _this.searchQueue[doc.id.id].locked = false;
                            }
                        });
                    }
                },
                error: function(err) {
                    console.log("err: ", err);
                }
            });
        }
    }
};




/* Gets currently selected GUI language */
IG.getLanguage = function() {
    var res = $.cookie("ig-language");
    if (res == null) {
        res = IG.config.language;
    }
    return res;
};

/* Set selected GUI language */
IG.setLanguage = function(inLanguage) {
    $.cookie("ig-language", inLanguage);
    IG.config.language = inLanguage;
};


IG.debug = function(s) {
    if (this.config.debug) {
        console.log(s);
    }
};


/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/
/******************************************************************************************/

/* Run when document is ready: */
$(function() {
	if (!IG.customer.idProvided) {
		setTimeout(function(){
			IG.showErrorDialog("No customer id provided. Please set the \'c\' parameter to the required customer id.");
		}, 100);
    } else {
    	if (!IG.customer.idValid) {
    		setTimeout(function(){
    			IG.showErrorDialog("Invalid customer id provided.");
    		}, 100);
		} else {
			IG.init();

	        //Refresh session every now and then:
	        IG.session.initRefresh();
		}

    }
});



/* Prepare history/location handling using jquery.address plugin: */
$.address.init(function(event) {
    //...
}).externalChange(function(event) {
    IG.location.current = $.address.value();
    IG.location.handle();
}).change(function(event) {
    // remove all "active" states from menu links
    $('#ig-header').find('.menu-active').removeClass('menu-active');
});
