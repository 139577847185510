/* Wrapper methods for JSON-RPC, requires jquery.jsonrpc-infogalleri.js */

/* Single JSON-RPC request: */
IG.request = function(options) {
    var _IG = this;

    try {
        $.jsonRPC.request(options.method, {
            post: options.post,
            params: options.params,
            success: function(response) {
                if ($.isFunction(options.complete)) {
                    options.complete();
                }

                if ($.isFunction(options.success)) {
                    options.success(typeof response.result == "boolean" ? response : response.result);
                }
            },
            error: function(response) {
                if ($.isFunction(options.complete)) {
                    options.complete();
                }

                if ($.isFunction(options.error)) {
                    if (response.error) {
                        options.error(response.error);
                    }
                    else {
                        options.error(response);
                    }
                }
            }
        });
    }
    catch (e) {
        if ($.isFunction(options.error)) {
            options.error(e);
        }
    }
};

/* Batch JSON-RPC request. Each request may have its own success, error and complete callback functions: */
IG.batchRequest = function(options) {
    var _IG = this;

    try {
        $.jsonRPC.batchRequest(options.requests,
            {
                success: function(response) {
                    $.each(response, function(i, n) {
                        var r = options.requests[i];

                        if (n.error) {
                            if ($.isFunction(r.error)) {
                                r.error(n.error);
                            }
                        }
                        else {
                            if ($.isFunction(r.success)) {
                                r.success(typeof n.result == "boolean" ? n : n.result);
                            }
                        }

                        if ($.isFunction(r.complete)) {
                            r.complete();
                        }
                    });

                    if ($.isFunction(options.success)) {
                        options.success(response);
                    }

                    if ($.isFunction(options.complete)) {
                        options.complete();
                    }
                },
                error: function(response) {

                    $.each(response, function(i, n) {
                        var r = options.requests[i];

                        if (n.error) {
                            if ($.isFunction(r.error)) {
                                r.error(n.error);
                            }

                            if ($.isFunction(r.complete)) {
                                r.complete();
                            }
                        }
                    });

                    if ($.isFunction(options.error)) {
                        options.error(response);
                    }

                    if ($.isFunction(options.complete)) {
                        options.complete();
                    }
                }
            }
        );
    }
    catch (e) {
        if ($.isFunction(options.error)) {
            options.error(e);
        }
    }
};

/* Setup JSON-RPC default behavior */
IG.initJSONRPC = function() {
    var _IG = this;
    $.jsonRPC.setup({
        endPoint: _IG.config.endPoint,
        jsonp: _IG.config.jsonp
    });
};