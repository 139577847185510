IG.InfoObjectList = function() {
    var _this = this;
    this.query = "";
    this.searchLang = IG.customerConfig.defaultContentLanguage;
    this.defaultSearchVal = IG.label.get("info_object_list_enter_search_term");

    this.config = {
        hashkey: "infoobjectlist"
    };

    this.hash = function() {
        var h = _this.config.hashkey;
        return h;
    };

    this.refresh = function() {
        _this.render();
    };

    this.fn = {
        isParent: function(object) {
            console.log(object, object.referencedInfoObjectIds);
            return !!(typeof object.referencedInfoObjectIds == "undefined" || object.referencedInfoObjectIds.length == 0);
        }
    };


    this.render = function() {
    	// Empty query string and thereby search field
    	// this.query = "";
    	
        var bAdmin = IG.userHasPermission("OP_INFO_OBJECT_ADMIN");
        var bCreate = IG.userHasPermission("OP_INFO_OBJECT_CREATE");


        //Create ObjectList:
        var IGObjectList = new IG.ObjectList({
            methodName: "searchInfoObjects",
            subelements: true,
            filters: {
                hasParent: false
            },
            page: this.config.hashkey,
            filterMode: 2,
            sorting: 'lastModified',
            order: 'DESC'
        });


        var objDiv = document.createElement("div");
        $(objDiv).attr("id", "ig-infoobjects");


        var objH2 = document.createElement("h2");
        $(objH2)
            .text(IG.label.get("dashboard_info_objects"))
            .appendTo(objDiv);

        //SEARCHBAR :
        var objSearch = document.createElement("div");
        $(objSearch)
            .addClass("ig-search")
            .attr("id", "ig-infoobjects-search");

        var displayVal = (_this.query != '' ? _this.query : _this.defaultSearchVal);
        var searchLang = _this.searchLang;

        var objSearchInput = document.createElement("input");
        $(objSearchInput)
            .attr({
                "type": "text",
                "name": "search-infoobjects",
                "id": "search-infoobjects",
                "value": displayVal
            })
            .addClass("search")
		    .click(function() {
		        if (_this.defaultSearchVal == $(this).val()) $(this).val('');
		        //some decoration stuff
		        $(this).removeClass('search-default-deco');
		        $(this).addClass('search-default');
		    })
            .blur(function() {
                if ($(this).val() == '') $(this).val(_this.defaultSearchVal);
                $(this).removeClass('search-default');
                $(this).addClass('search-default-deco');
            })
            .keyup(function(e) {
                var v = $(this).val();
                var key = e.which ? e.which : e.keyCode;
                if (key == 13) {
                    _this.query = (v != _this.defaultSearchVal ? v : '');
                    _this.searchLang = searchLang;
                    if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                        $(objLangSel).addClass('search-lang-deco');
                    } else $(objLangSel).removeClass('search-lang-deco');
                    IGObjectList.setQuery(_this.query, _this.searchLang);
                    IGObjectList.refresh();
                }
            })
            .appendTo(objSearch);

        

        var objLangSel = IGObjectList.renderSearchLanguageSelector({
        	onChange: function(n) {
        		searchLang = n;
        	},
        	searchLang: searchLang
        });
        if (objLangSel) $(objSearch).append(objLangSel);


        //Build search button:
        var btnFind = document.createElement("a");
        $(btnFind)
            .attr('href', '#search')
            .addClass("btn-normal btn-dark")
            .html('<span>' + IG.label.get("global_search") + '</span>')
            .click(function() {
                var v = $(objSearchInput).val();
                _this.query = (v != _this.defaultSearchVal ? v : '');
                _this.searchLang = searchLang;
                if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                    $(objLangSel).addClass('search-lang-deco');
                } else $(objLangSel).removeClass('search-lang-deco');
                IGObjectList.setQuery(_this.query, _this.searchLang);
                IGObjectList.refresh();

                return false;
            })
            .appendTo(objSearch);

        $(objDiv).append(objSearch);
        
        var clearDiv = document.createElement("div");
        $(clearDiv)
            .css("clear", "both")
            .appendTo(objSearch);

        var minHeight = 330;
        var initHeight = $(window).height() - 220 - 84 - 63;
        initHeight = ( initHeight < minHeight ? minHeight : initHeight );

        //Determine available height for list:
        var fnGetAvailableHeight = function(obj) {
            var h = $(window).height() - $(obj).offset().top - $('#ig-footer').outerHeight() - 84; //ish...
            if (h < minHeight) {
                h = minHeight;
            }
            return h;
        };

        var objDivList = document.createElement("div");
        $(objDivList)
            .attr("id", "ig-infoobjects-list")
            .addClass('hubber-list-wrap')
            .height( initHeight ) // just an initial height that is within the window bounds
            .appendTo(objDiv);

        /*  Adjust the height after rendering is done
        window.setTimeout(function() {
            $(objDivList).height( fnGetAvailableHeight(objDivList) );
        }, 1);*/

        var objDivForceWidth = document.createElement('div');
        $(objDivForceWidth)
            .addClass('list-force-with')
            .appendTo(objDivList);


        $(window).resize(function() {
            $(objDivList).height(fnGetAvailableHeight(objDivList));
        });


        IGObjectList.config.parentObject = objDivList;


        //Render type tip contents:
        var fnTypeTipContent = function() {
            var k = "infoObjectType";
            var currentFilter = IGObjectList.getFilter(k);

            var types = [
                { key: "standard", title: IG.label.get("info_object_list_standard_info_object") },
                { key: "poll", title: IG.label.get("info_object_list_poll_info_object") },
                { key: "arrangement", title: IG.label.get("info_object_list_arrangement_info_object") }
            ];
            
            // Add special document types
            $.each(IG.lists.documenttypes, function(i, obj) {
            	var type = {};
            	type.key = obj.typeName;
            	type.title = obj.name[IG.config.language];
            	types.push(type);
            });

            types.sort(function(a,b) {
                if (a.title > b.title) {
                    return 1;
                } else if (a.title < b.title) {
                    return -1;
                }
                return 0;
            });
            types.unshift({ key: IG.constants.DOCUMENT_TYPES_ALL, title: IG.label.get("INFOOBJECT_TYPE_SEARCH_ALL") });

            var objDiv = document.createElement("div");

            $.each(types, function(i, n) {
                var objA = document.createElement("a");
                if (currentFilter == n.key) {
                    $(objA).addClass("active");
                }
                $(objA)
                    .html('<span title="'+ n.title+'">' + n.title + '</span>')
                    .click(function() {
                        if ($(this).hasClass("active")) {
                            $(this).removeClass("active");
                            IGObjectList.removeFilter(k);
                        } else {
                            $(this).addClass("active");
                            $(this).siblings("a").removeClass("active");
                            if (n.key == IG.constants.DOCUMENT_TYPES_ALL) {
                                IGObjectList.removeFilter(k);
                            } else {
                                IGObjectList.setFilter(k, n.key);
                            }
                        }
                        IGObjectList.refresh();
                    })
                    .appendTo(objDiv);

                if (n.key == IG.constants.DOCUMENT_TYPES_ALL && IGObjectList.getFilter(k) == '') {
                    $(objA).addClass("active");
                }
            });

            $(objDiv).find('a').last().addClass('last-qtip-list-ele');
            return objDiv;
        };

        //Render editor tip contents:
        var fnEditorTipContent = function() {
            var k = "owners";

            var objDiv = document.createElement("div");

            $.each(IG.lists.users, function(i, n) {
                var bFound = IGObjectList.hasIdFilter(k, n.id);

                var objA = document.createElement("a");
                if (bFound) {
                    $(objA).addClass("active");
                }

                $(objA)
                    .html('<span>' + n.name + '</span>')
                    .click(function() {
                        if ($(this).hasClass("active")) {
                            $(this).removeClass("active");
                            IGObjectList.removeIdFilter(k, n.id);
                        }
                        else {
                            $(this).addClass("active");
                            IGObjectList.addIdFilter(k, n.id);
                        }
                        IGObjectList.refresh();
                    })
                    .appendTo(objDiv);

            });
            $(objDiv).find('a').last().addClass('last-qtip-list-ele');

            return objDiv;
        };

        var fnStatusTipContent = function() {
            var k = "publishState";

            var objDiv = document.createElement("div");

            var statuses = IG.fn.getInfoObjectStatuses();
            $.each(statuses, function(i, s) {
                var bFound = IGObjectList.getFilter(k) == s.filter;

                var objA = document.createElement("a");
                if (bFound) {
                    $(objA).addClass("active");
                }

                $(objA)
                    .html('<span>' + s.caption + '</span>')
                    .click(function() {
                        if ($(this).hasClass("active")) {
                            $(this).removeClass("active");
                            IGObjectList.removeFilter(k, s.filter);
                        }
                        else {
                            $(this).addClass("active");
                            $(this).siblings("a").removeClass("active");
                            IGObjectList.setFilter(k, s.filter);
                        }
                        IGObjectList.refresh();
                    })
                    .appendTo(objDiv);
            });

            $(objDiv).find('a').last().addClass('last-qtip-list-ele');
            return $(objDiv);
        };

        
        //Builds delete button:
        var fnBuildDeleteButton = function(item) {
            if (IG.userHasPermission("OP_INFO_OBJECT_DELETE", item) || bAdmin) {
                var objA = document.createElement("a");
                $(objA)
                    .addClass("btn-small btn-dark")
                    .html('<span>' + IG.label.get("info_object_list_delete") + '</span>')
                    .click(function() {
                        var _me = this;

                        var thisRowElem = $('.ITEM_'+item.id.id);
                        var parentId = null;
                        if (thisRowElem && thisRowElem.length == 1) {
                            parentId = thisRowElem.data('parentId');
                        }

                        var dialog = IG.createConfirmDialog({
                            title: "",
                            text: IG.label.get("edit_infoobject_delete_popup_message"),
                            onConfirm: function() {
                                var isParent = !!(typeof item.referencingInfoObjectIds == "undefined" || item.referencingInfoObjectIds.length > 0);
                                IG.remove.infoobject({
                                    id: item.id,
                                    parent: isParent,
                                    success: function() {
                                        //Remove table row:
                                        IGObjectList.removeRow(item.id, function() {

                                            // Determine if this was the last child element and manipulate the parent as needed
                                            if (parentId) {
                                                var siblingRows = $('.'+parentId.id);
                                                if (siblingRows && siblingRows.length === 0) {
                                                    var parentElem = $('.ITEM_'+parentId.id);
                                                    if (parentElem && parentElem.length === 1) {
                                                        parentElem.children('.sub').html('&nbsp;')
                                                    }
                                                }
                                            }


                                        });



                                        dialog.close();
                                    },
                                    error: function(err) {
                                        dialog.close();
                                        IG.showErrorDialog(err);
                                    }
                                });
                            },
                            target: $(_me)
                        });

                        return false;
                    });

                return objA;
            }
            else {
                return "";
            }
        };

        IGObjectList.config.columns = [
            {
                name: "headline",
                title: IG.label.get("info_object_list_title"),
                className: "title",
                sortable: true,
                width: "auto",
                renderTitle: function(object) {
                    var s = "...";
                    if (typeof object.headline !== "undefined") {
                        s = IG.fn.stripHTML(object.headline[IG.config.language]);
                    }
                    return s;
                },
                render: function(object) {
                    var s = '[' +IG.label.get("INFOOBJECT_HEADLINE_NOT_TRANSLATED") + ']';
                    if (typeof object.headline != "undefined" && typeof object.headline[_this.searchLang] !== 'undefined'
                            && object.headline[_this.searchLang].length > 0) {
                        s = IG.fn.stripHTML(object.headline[_this.searchLang]);
                    }

                    var objA = document.createElement("a");
                    $(objA)
                        .text(s)
                        .attr("title", s)
                        .click(function() {
                            IG.showEditInfoObject({
                                id: object.id["id"],
                                history: true,
                                searchLang: _this.searchLang
                            });
                        });
                    return objA;
                }
            },
            {
                name: "details",
                title: '', // IG.label.get("info_object_list_details_popup"),
                className: "details",
                sortable: false,
                width: "24px",
                renderTitle: function(object) {
                    // Let column header be empty.
                    return "";
                },
                render: function(object) {
                    var spanElm = document.createElement("span");
                    // TODO: Find image path in a robust way.
                    $(spanElm).addClass('icon-info-sign');
//                     spanElm.innerHTML = "<span class='icon-info-sign'></span>";
                    $(spanElm).qtip({
                        content: {
                            title: false,
                            text: (function () {
                                var divElm = document.createElement("div");
                                divElm.innerHTML = "<div id='info-object-popup-" + object.id.id + "'>" +
                                        "<div class='infoobject_subheadline'>" + IG.label.get("edit_infoobject_subHeadline") + ":</div><div id='ig-objectlist-subheadline'>" + object.subHeadline[IG.config.language] + "</div>" +
                                        "<hr>" +
                                        "<div class='publication-time'>" + IG.label.get('infoobject_publication_time_title') + "</div>" +
                                		"<div class='pub-time'><label for='ig-objectlist-starttime-popup'>" + IG.label.get("edit_infoobject_display_start_time") + "</label>: <span id='ig-objectlist-starttime-popup'>" + IG.fn.formatTime(object.displayStartTime) + "</span></div>" +
                                		"<div class='pub-time'><label for='ig-objectlist-endtime-popup'>" + IG.label.get("edit_infoobject_display_end_time") + "</label>: <span id='ig-objectlist-endtime-popup'>" + IG.fn.formatTime(object.displayEndTime) + "</span></div>" +
                                		"<hr>" +
                                		"<div class='channel-title'>" + IG.label.get('edit_infoobject_channels_title') + " (" + object.channelIds.length + ")</div>" +
                                		"<div class='channel-container'><span class='ig-loading'></span></div>" + 
                                        "<hr>" +
                                        "<div class='media-title'>" + IG.label.get('edit_infoobject_media_title') + " (" + object.mediaIds.length + ")</div>" +
                                        "<div class='media-container'><span class='ig-loading'></span></div></div>";
                                return divElm.innerHTML;
                            })()
                        },
                        events: {
                            // Register 'onShow' callback, cf. http://craigsworks.com/projects/qtip/docs/api/#callbacks
                            render: function (event) {
                            	
                            	// Resolve media IDs to media objects.
                                if (object.mediaIds.length > 0) {
                                	IG.request({
                                        method: "searchMedia",
                                        params: [IG.config.language, IG.config.language, "", false, { ids: object.mediaIds }, {}, 0, 0],
                                        success: function (response) {
                                        	
                                        	$('#info-object-popup-' + object.id.id + ' .media-container').innerHTML = '';
                                        	
                                            var mediaObjects = response.data.data,
                                                i = 0,
                                                objImages = document.createElement("div");
                                            $(objImages).addClass('media-div-images');
                                            
                                            for (i = 0; i < mediaObjects.length; i += 1) {

                                                if (mediaObjects[i].thumbnailMediaFiles.length > 0 && 
                                                        typeof mediaObjects[i].thumbnailMediaFiles[0].url === 'string') {
                                                	
                                                	var fancyContent = document.createElement("img");
                                                    $(fancyContent).attr("src", mediaObjects[i].originalMediaFile.url);

                                                	var innerHTML = document.createElement("div");
                                                	$(innerHTML)
                                                		.addClass('media-div-image-container')
                                                		.appendTo(objImages);
                                                	
                                                	var objA = document.createElement('a');
                                                	$(objA)
                                                		.attr('href', '#')
                                                		.attr('title', mediaObjects[i].name[IG.config.language])
                                                		.data('image', fancyContent)
                                                		.click(function(event) {
                                                		    
                                                			event.preventDefault();
                                                			
                                                			var imageContent = document.createElement('div'); // '<div class="modal-image-preview-inner">' + $(this).data('image') + '</div>';
                                                            $(imageContent)
                                                                .addClass('modal-image-preview-inner')
                                                                .append($(this).data('image'));

                                                            $( "#modal-window" )
                                                                .html(imageContent)
                                                                .dialog({
                                                                    dialogClass: "modal-image-preview",
                                                                    modal: false,
                                                                    title: '',
                                                                    position: { my: "center", at: "center", of: window },
                                                                    width: 800,
                                                                    height: 600,
                                                                    buttons: null,
                                                                    open: function( event, ui ) {
                                                                        $(spanElm).qtip("hide");

                                                                        var isFullSize = false;
                                                                        var eleImg = $(imageContent);
                                                                        eleImg.css({
                                                                            'width': 538+"px",
                                                                            'cursor':'pointer'
                                                                        });
                                                                        eleImg.click(function(event) {
                                                                            event.preventDefault();

                                                                            if (!isFullSize) {
                                                                                eleImg.css({
                                                                                    'width' : 'auto'
                                                                                });
                                                                                // eleImg.draggable();
                                                                                isFullSize = true;
                                                                            } else {
                                                                                eleImg.css({
                                                                                    'width' : 538+"px"
                                                                                });
                                                                                isFullSize = false;
                                                                            }

                                                                        });
                                                                    },
                                                                    resize: function( event, ui ) {

                                                                    },
                                                                    close: function(event, ui) {

                                                                    },
                                                                    beforeClose: function( event, ui ) {

                                                                    }
                                                                });

	                                                    })
                                                		.appendTo(innerHTML);
                                                	
                                                	var objImg = document.createElement('img');
                                                	$(objImg)
                                                		.attr('src', mediaObjects[i].thumbnailMediaFiles[0].url)
                                                		.appendTo(objA);
                                                	
                                                } else {
                                                	objImages = document.createElement("span");
                                                	$(objImages).text('No thumbnail image found.');
                                                }
                                            }
                                            $('#info-object-popup-' + object.id.id + ' .media-container').html(objImages);
                                        },
                                        error: function (error) {
                                            $('#info-object-popup-' + object.id.id + ' .media-container').html(IG.label.get('info_object_list_error_searching_for_media_popup'));
                                        }
                                    });
                                } else {
                                    $('#info-object-popup-' + object.id.id + ' .media-container').html(IG.label.get('info_object_list_no_media_attached_popup'));
                                }

                                // Resolve channel IDs to channel objects.
                                if (object.channelIds.length > 0) {
                                    IG.request({
                                        method: "searchChannels",
                                        params: [IG.config.language, IG.config.language, "", false, { ids: object.channelIds }, {}, 0, 0],
                                        success: function (response) {
                                            var channels = response.data.data,
                                                innerHTML = '',
                                                i = 0;
                                            for (i = 0; i < channels.length; i += 1) {
                                                innerHTML += '<div class="channel-metadata"><div>' + IG.label.get('channel_list_channel_name') + ': ' + channels[i].name[IG.config.language] + '.</div><div>' + IG.label.get('infoobject_list_channel_description_popup') + ': ' + channels[i].description[IG.config.language] + '</div></div>';
                                            }
                                            $('#info-object-popup-' + object.id.id + ' .channel-container').html(innerHTML);
                                        },
                                        error: function (error) {
                                            $('#info-object-popup-' + object.id.id + ' .channel-container').html(IG.label.get('info_object_list_error_searching_for_channels_popup'));
                                        }
                                    });
                                } else {
                                    $('#info-object-popup-' + object.id.id + ' .channel-container').html(IG.label.get('info_object_list_no_channels_attached_popup'));
                                }
                            }
                        },
                        show: {event: 'click'},
                        hide: { event: 'unfocus' },
                        position: {
                            adjust: {
                                // scroll: true,
                                resize: true,
                                x: 12 // Fine-tuning the placement to the icon.
                            },
                            my: 'left middle',
                            at: 'right middle'
                        },
                        style: {
                            classes: 'qtip-document-info',
                            width: 600,
                            tip: {
                                width: 10,
                                height: 6
                            },
                            def: false
                        }
                    });
                    return spanElm;
                }
            },
            {
                name: "infoObjectType",
                title: IG.label.get("info_object_list_type"),
                className: "type",
                sortable: true,
                width: "110px",
                tip: {
                    overflow: "none",
                    content: fnTypeTipContent()
                },
                renderTitle: function(object) {
                    var s = IG.label.get("info_object_list_" + object.type + "_info_object");
                    if (s == "info_object_list_" + object.type + "_info_object") {
                        s = IG.getDocumentTypeName(object.type);
                        if (s === object.type) {
                            s = IG.label.get('doc_type_unknown_translation');
                        }
                    }
                    return s;
                },
                render: function(object) {

                    var s = IG.label.get("info_object_list_" + object.type + "_info_object");
                    if (s == "info_object_list_" + object.type + "_info_object") {
                        s = IG.getDocumentTypeName(object.type);
                        if (s === object.type) {
                            s = IG.label.get('doc_type_unknown_translation');
                        }
                    }
                    return s;
                }
            },
            {
                name: "lastModified",
                title: IG.label.get("info_object_list_last_modified"),
                className: "",
                sortable: true,
                width: "160px",
                render: function(object) {
                    var s = "...";
                    if (typeof object.lastModified != "undefined") {
                        s = IG.fn.formatTime(object.lastModified);
                    }

                    return s;
                }
            },
            {
                name: "displayStartTime",
                title: IG.label.get("info_object_list_displayed_from"),
                className: "",
                sortable: true,
                width: "160px",
                render: function(object) {
                    var s = "...";
                    if (typeof object.displayStartTime != "undefined") {
                        s = IG.fn.formatTime(object.displayStartTime);
                    }

                    return s;
                }
            },
            {
                name: "published",
                title: IG.label.get("info_object_list_status"),
                className: "status",
                sortable: true,
                width: "100px",
                tip: {
                    maxheight: "300px",
                    overflow: "auto",
                    content: fnStatusTipContent()
                },
                render: function(object) {
                    var objStatus = IG.fn.getInfoObjectStatus(object);
                    var objSpan = document.createElement("span");

                    $(objSpan)
                        .addClass("status " + objStatus.statusClass)
                        .text(objStatus.caption)
                        .attr("title", objStatus.caption);

                    return objSpan;
                }
            },
            {
                name: "editor",
                title: IG.label.get("info_object_list_editor"),
                className: "editor",
                sortable: false,
                width: "120px",
                tip: {
                    maxheight: "300px",
                    overflow: "auto",
                    content: fnEditorTipContent()
                },
                renderTitle: function(object) {
                    var objEditor = { name: "" };
                    if (object.editor) {
                        objEditor = IG.getUserById(object.editor["id"]);
                    }
                    if (objEditor) {
                        if (objEditor.name) {
                            return objEditor.name;
                        } else {
                            return "";
                        }
                    } else {
                        return IG.label.get('GLOBAL_MISSING_EDITOR_NAME');
                    }
                },
                render: function(object) {
                    var objEditor = { name: "" };
                    if (object.editor) {
                        objEditor = IG.getUserById(object.editor["id"]);
                    }
                    var objSpan = document.createElement("span");
                    var name = IG.label.get('GLOBAL_MISSING_EDITOR_NAME');
                    if (objEditor && objEditor.name) {
                        name = objEditor.name;
                    }
                    $(objSpan)
                        .css("white-space", "nowrap")   //this is the ie 7 workarround (nowrap in td)
                        .append(name);
                    return $(objSpan);
                }
            },
            {
                name: "",
                title: "",
                className: "buttons",
                sortable: false,
                width: "70px",
                render: function(object) {
                    return fnBuildDeleteButton(object);
                }
            }
        ];

        //Initialize list and populate main contents:
        IGObjectList.init(function() {
            $("#ig-data").html(objDiv);
            IG.gui.loading.hide();
            
            // Focus the search field:
            if (_this.query != _this.defaultSearchVal && _this.query != '') {
            	$(objSearchInput).focus();
            	$(objSearchInput).removeClass('search-default-deco');
		        $(objSearchInput).addClass('search-default');
            }            
        });
        
        // Filter results if there was a previous search string
        if (_this.query != _this.defaultSearchVal && _this.query != '' || _this.searchLang != IG.customerConfig.defaultContentLanguage) {
        	IGObjectList.setQuery(_this.query, _this.searchLang);
        } else {
        	_this.searchLang = IG.customerConfig.defaultContentLanguage;
        }
        
    };


    this.init = function() {
        _this.render();
        $.address.value(_this.config.hashkey);
    };

    return this;
};

IG.showInfoObjectList = function(options) {
    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);
    
    // Google Analytics:
    // IG.config.prodMode ? _gaq.push(['_trackPageview']) : null;
    
    IG.gui.loading.show();

    //Create infoobject list if needed:
    var objInfoObjectList = _IG.objects.get("infoobjectlist");
    if (objInfoObjectList == null) {
        objInfoObjectList = _IG.objects.create("infoobjectlist", new _IG.InfoObjectList());
        objInfoObjectList.init();
    }
    else {
        objInfoObjectList.refresh({ history: false });
    }

    IG.gui.setTitle(IG.label.get("dashboard_info_objects"));
    //Add to history:
    if (options.history) {
        _IG.location.setCurrent(objInfoObjectList.hash());
    }


    $('#ig-user-menu-sections').find('a.documents').parent().addClass('menu-active');
};
