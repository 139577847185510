/* Generic ObjectList */

IG.ObjectList = function(opts) {
    var _this = this;
    var rowHeight = null;

    var resultObjects = [];
    var desiredResultCount = 0;
    var recursionCounter = 0;
    var tableWidth = null;

    opts = (typeof opts != "undefined" ? opts : {});

    this.id = "objectlist-" + IG.fn.getTimestamp() + "-" + Math.floor(Math.random() * 10000);

    this.origItemCount = 0;
    this.itemcount = 0;
    this.items = [];
    this.tips = [];

    this.headerTable = null;


    this.throbber = null;
    this.itemsScroller = null;
    this.itemsContent = null;
    this.itemsTable = null;
    this.itemsTableBody = null;

    this.events = {
        scrolled: function() {
            if (typeof (opts.scrolled) == "function") {
                opts.scrolled();
            }
        }
    };

    function prepareSearch(limit) {
        resultObjects = [];
        desiredResultCount = limit;
        recursionCounter = 0;
    }

    //Configuration:
    this.config = {
        //Columns specifications (width, type, title, rendering):
        columns: opts.columns,

        //HTML element to render inside:
        parentObject: opts.parentObject,

        //Method name to call (also used to derive appropriate parameters):
        methodName: opts.methodName,

        //Row height in pixels (used to calculate number of rows to load upon scrolling):
        rowHeight: 29,

        //Enable scrolling based loading of data:
        scrolling: (typeof opts.scrolling == "boolean" ? opts.scrolling : true),

        //Enable sortable rows:
        sortable: (typeof opts.sortable == "boolean" ? opts.sortable : false),

        //Set function to perform after sorting:
        afterSort: (typeof opts.afterSort == "function" ? opts.afterSort : function() { }),

        //Auto-adjust height on window-resize?
        adjustHeight: (typeof opts.adjustHeight == "boolean" ? opts.adjustHeight : true),

        //Max number of items to retrieve upon scrolling:
        maxrows: IG.config.maxItemsPerRequest || 50,

        //Enable subelements (InfoObjects):
        subelements: (typeof opts.subelements == "boolean" ? opts.subelements : false),
        
        page: (typeof opts.page == "string" ? opts.page : null),

        orderByList: (typeof opts.orderByList !== 'undefined' && $.isArray(opts.orderByList) ? opts.orderByList : false)
    };

    //Search options:
    this.options = {
        sorting: (typeof opts.sorting !== 'undefined' ? opts.sorting : ''),
        order: (typeof opts.sorting !== 'undefined' && typeof opts.order !== 'undefined' && (opts.order==='DESC' || opts.order==='ASC')
            ? opts.order : 'ASC'),
        query: "",
        searchLang: IG.customerConfig.defaultContentLanguage,
        filters: opts.filters,
        offset: 0,
        limit: _this.config.maxrows,
        filterMode: (typeof opts.filterMode !== 'undefined' && (opts.filterMode==1||opts.filterMode==2) ? opts.filterMode : 1)
    };
    
    
    //Get saved filters and ordering
    var savedFilters = IG.objList.getFilter(_this.config.page);
    if (savedFilters) {
        _this.options.filters = savedFilters;
    }
    
    var savedOrdering = IG.objList.getOrder(_this.config.page);
    if (savedOrdering) {
        _this.options.sorting = savedOrdering.col;
        _this.options.order = savedOrdering.order;
    }


    //Object holding current scrolling information:
    this.scrolling = {
        busy: false,

        start: 0,
        stop: 0,
        delta: 0,

        startRow: function() {
            return Math.ceil(this.start / _this.config.rowHeight);
        },
        stopRow: function() {
            return Math.ceil(this.stop / _this.config.rowHeight);
        },
        deltaRow: function() {
            return Math.ceil((this.stop - this.start) / _this.config.rowHeight);
        },


        minRow: 0, //Current min row
        maxRow: 0, //Current max row


        reset: function() {
            this.busy = false;
            this.start = 0;
            this.stop = 0;
            this.delta = 0;
            this.minRow = 0;
            this.maxRow = 0;
        }
    };

    // specify the type of document to search for
    this.setDocumentType = function(documentType) {
        if (typeof _this.options.filters !== 'undefined') {

            if ($.isArray(documentType)) {
                _this.options.filterMode = 2;
                _this.options.filters = {
                    parentsOnly: true,
                    '$or' : []
                };

                $.each(documentType, function(i, dt) {
                    _this.options.filters['$or'].push({
                        infoObjectType: dt
                    });
                });
            } else {
                _this.options.filterMode = 1;
                _this.options.filters = {
                    parentsOnly: true,
                    infoObjectType: documentType
                };
            }
        }
    };

    /**
     * Set a list of objects that should not be shown. I.e. used in lists of typed references fields, where you don't
     * want to show the chosen ones.
     * @param list
     */

    this.setOrderByList = function(list) {
        _this.config.orderByList = list;
    };


    /**
     * Find the index of the ID in the full list that is used to keep the order etc.
     * @param id The ID string part of the IG ID object.
     * @returns {boolean|number} Returns false if there was no match. Otherwise an int is returned.
     */
    this.getIndexOfItem = function(id) {
        var res = false;
        if (_this.config.orderByList) {
            $.each(_this.config.orderByList, function(i, listObj) {
                if (listObj.id == id) {
                    res = i;
                    return false;
                }
            });
        }
        return res;
    };


    //Checks if an id filter exists (compares id field):
    this.hasIdFilter = function(k, id) {
        var bFound = false;
        if ($.isArray(_this.options.filters[k])) {
            $.each(_this.options.filters[k], function(i, n) {
                if (n["id"] == id["id"]) {
                    bFound = true;
                    return false; //break loop;
                }
            });
        }
        return bFound;
    };

    //Adds an id filter
    this.addIdFilter = function(k, id) {
        if (!_this.hasIdFilter(k, id)) {
            if ($.isArray(_this.options.filters[k])) {
                _this.options.filters[k].push(id);
            }
            else {
                _this.options.filters[k] = [id];
            }
            return true;
        }
        return false;
    };

    //Removes an id filter:
    this.removeIdFilter = function(k, id) {
        if (_this.hasIdFilter(k, id)) {
            _this.options.filters[k] = $.grep(_this.options.filters[k], function(n, i) {
                return n["id"] != id["id"];
            });

            if (_this.options.filters[k].length == 0) {
                delete _this.options.filters[k];
            }
            return true;
        }
        return false;
    };

    //Set filter: 
    this.setFilter = function(k, v) {
        _this.options.filters[k] = v;
    };
    
    this.setFilterObj = function(filter) {
        _this.options.filters = filter;
    };

    //Gets a filter value
    this.getFilter = function(k) {

        var res = "";
        $.each(_this.options.filters, function(i, n) {
            if (i == k) {
                res = n;
                return false; //break loop
            }
        });

        return res;
    };

    //Removes filter:
    this.removeFilter = function(k) {
        delete _this.options.filters[k];
    };

    //Set sorting order:
    this.setOrder = function(o) {
        if (o != null) {
            switch (o) {
                case "ASC":
                case "DESC":
                    _this.options.order = o;
                    break;
            }
        }
    };

    //Toggles order (ASC/DESC):
    this.toggleOrder = function() {
        _this.options.order = (_this.options.order == "ASC" ? "DESC" : "ASC");
    };

    //Sets sorting key:
    this.setSorting = function(k, direction) {
        if (typeof k !== 'undefined' && k != null) {
            if (typeof direction !== 'undefined' && (direction.toUpperCase() == 'ASC' || direction.toUpperCase() == 'DESC')) {
                _this.options.order = direction;
            } else if (k == _this.options.sorting) {
                _this.toggleOrder();
            }
            _this.options.sorting = k;
            IG.objList.setOrder(_this.config.page, k, _this.options.order);
        }
    };

    //Sets query string and search language:
    this.setQuery = function(s, lang) {
    	if (lang != undefined) {
    		_this.options.searchLang = lang;
    	} else {
    		_this.options.searchLang = IG.customerConfig.defaultContentLanguage;
    	}
        _this.options.query = s;
    };




    //Refreshes headers:
    this.refreshHeader = function() {
        $.each($("#" + _this.id).find(".ig-objectlist-header th"), function(i, n) {
            if (_this.options.sorting == "") {
                $(n)
                    .removeClass("sortby")
                    .removeClass("sort-desc")
                    .removeClass("sort-asc");
            }
            else {
                if ($(n).data("name") == _this.options.sorting) {
                    $(n).addClass("sortby");
                    if (_this.options.order == "ASC") {
                        $(n)
                            .addClass("sort-asc")
                            .removeClass("sort-desc");
                    }
                    else {
                        $(n)
                            .addClass("sort-desc")
                            .removeClass("sort-asc");
                    }
                }
                else {
                    $(n)
                        .removeClass("sortby")
                        .removeClass("sort-desc")
                        .removeClass("sort-asc");
                }
            }
        });
    };

    //Refreshes list items:
    this.refresh = function(callback) {
        IG.objList.setFilter(_this.config.page, _this.options.filters);

        //Show loading animation:
        var loadingId = "loading-" + _this.id;
        IG.gui.loading.show(_this.config.parentObject, loadingId, 1);

        //Hide loading animation:
        var fnCallback = function() {
            _this.refreshHeader();
            if ($.isFunction(callback)) {
                callback();
            }
            IG.gui.loading.hide(loadingId);
        };

        var limit = _this.getMaxVisibleRows();
        prepareSearch(limit);

        _this.loadCount(function() {
            var filterIds = false;
            if (_this.config.orderByList) {
                filterIds = _this.config.orderByList.slice(0, limit);
            }

            _this.load(0, limit, _this.options.filterMode, filterIds, function(objects) {
                desiredResultCount = 0;

                // sort the results according to the given option "orderByList"
                if (_this.config.orderByList) {
                    objects = _this.sortObjects(objects, _this.config.orderByList);
                }

                _this.scrolling.reset();
                _this.scrolling.maxRow = objects.length;
                _this.render(objects, fnCallback);
            });
        });
    };


    // Build language selector
    this.renderSearchLanguageSelector = function(options) {
        if (!options.searchLang || typeof options.searchLang != "string") options.searchLang = IG.customerConfig.defaultContentLanguage;
        var lang_selected = 0;
        if (IG.fn.objSize(IG.customerConfig.contentLanguages) > 1) {

            var objLangSel = document.createElement("a");
            $(objLangSel)
                    .addClass("hubber-select btn-icon btn-icon-right")
                    .attr('href', '#')
                    .attr('onclick', 'return false');

            var objLangSpan = document.createElement("span");
            $(objLangSpan)
                .text(IG.customerConfig.contentLanguages[options.searchLang])
                .addClass('btn-text')
                .appendTo(objLangSel);

            var languagesTip = document.createElement("div");
            $(languagesTip).addClass('language-selector-list');

            $.each(IG.customerConfig.contentLanguages, function(lang_key, lang_name) {
                var langObj = document.createElement("a");
                $(langObj)
                    .addClass(lang_key)
                    .attr('data-lang', lang_key)
                    .html(lang_name)
                    .click(function() {
                        $(objLangSpan).text(lang_name);
                        $(objLangSel).qtip('hide');
                        lang_selected = lang_key;
                        if (options.onChange && $.isFunction(options.onChange)) options.onChange(lang_key);
                    })
                    .appendTo(languagesTip);
            });

            var qtipKeyHandler = function(e) {
                var elem = false,
                    selector = $('.language-selector-list');
                switch (e.keyCode) {
                    case 38:   //up
                        if (lang_selected != 0)
                            elem = selector.find('.'+lang_selected).prev();
                        if (!elem || elem.length == 0)
                            elem = selector.find('a').last();
                        break;
                    case 40:   //down
                        if (lang_selected != 0)
                            elem = selector.find('.'+lang_selected).next();
                        if (!elem || elem.length == 0)
                            elem = selector.find('a').first();
                        break;
                    case 13:
                    case 9:
                    case 27:  // close
                        $(objLangSel).qtip('hide');
                        break
                }
                if (elem) {
                    lang_selected = elem.attr('data-lang');
                    elem.addClass('current').siblings().removeClass('current');
                    $(objLangSpan).text(IG.customerConfig.contentLanguages[lang_selected]);
                    if (options.onChange && $.isFunction(options.onChange)) options.onChange(lang_selected); 
                }
            };


            //Attach qtip to type selector:
            $(objLangSel).qtip({
                content: {
                    text: $(languagesTip)
                },
                show: {
                    delay: 50,
                    event: 'click'
                },
                hide: { event: 'unfocus'},
                position: {
                    my: 'top center',
                    at: 'bottom center',
                    adjust: {
                        y: 2
                    }
                },
                style: {
                    classes: 'qtip-select',
                    width: '174px',
                    def: false,
                    background: 'none',
                    tip: {
                        width: 10,
                        height: 6
                    }
                },
                api: {
                    onShow: function(e) {
                        $(document).bind('keydown.language-selector', qtipKeyHandler);
                    },
                    onHide: function(e) {
                        $(document).unbind('keydown.language-selector', qtipKeyHandler);
                    }
                }
            });
            
            if (options.searchLang != IG.customerConfig.defaultContentLanguage) {
                $(objLangSel).addClass('search-lang-deco');
            }

            return $(objLangSel);
        }
        return null;
    };


    // Render document type selector
    this.renderDocumentTypeSelector = function(options) {

        if (typeof options === 'undefined') {
            return false;
        }
        if (!$.isArray(options.docTypes) || options.docTypes.length == 0) {
            return false;
        }
        var _this = this;
        var docTypeSelected = options.docTypes[0];

        if (options.docTypes.length > 1) {
            var elemDocTypeSel = document.createElement("a");
            $(elemDocTypeSel)
                .addClass("document-type-selector hubber-select btn-icon btn-icon-right")
                .attr('href', '#')
                .attr('onclick', 'return false')
                .html('<span class="btn-text ellipsis">' +IG.getDocumentTypeName(IG.label.get('INFOOBJECT_TYPE_SEARCH_ALL'))+ '</span>');

            var elemSpanDocType = document.createElement("span");
            $(elemSpanDocType).appendTo(elemDocTypeSel);

            var elemTipDocTypes = document.createElement("div");
            $(elemTipDocTypes).addClass('doc-type-selector-list');


            // Add an option for searching all available types
            var elemADocType = document.createElement("a");
            $(elemADocType)
                .attr('id', IG.constants.DOCUMENT_TYPES_ALL)
                .attr('data-doc-type', IG.constants.DOCUMENT_TYPES_ALL)
                .text(IG.label.get('INFOOBJECT_TYPE_SEARCH_ALL'))
                .click(function() {
                    $(elemDocTypeSel).html('<span class="btn-text ellipsis">'
                        + IG.getDocumentTypeName(IG.label.get('INFOOBJECT_TYPE_SEARCH_ALL'))
                        + '</span>').qtip('hide');
                    docTypeSelected = IG.constants.DOCUMENT_TYPES_ALL;
                    if (options.onChange && $.isFunction(options.onChange)) options.onChange(IG.constants.DOCUMENT_TYPES_ALL);
                    _this.setDocumentType(options.docTypes); // all available document types
                })
                .appendTo(elemTipDocTypes);


            $.each(options.docTypes, function(i, docType) {

                var elemADocType = document.createElement("a");
                $(elemADocType)
                    .attr('id','doc-type-'+docType)
                    .attr('data-doc-type', docType)
                    .text(IG.getDocumentTypeName(docType))
                    .click(function() {
                        $(elemDocTypeSel).html(
                            '<span class="btn-text ellipsis">' + IG.getDocumentTypeName(docType) + '</span>'
                        ).qtip('hide');
                        docTypeSelected = docType;
                        if (options.onChange && $.isFunction(options.onChange)) options.onChange(docType);
                        _this.setDocumentType(docType);
                    })
                    .appendTo(elemTipDocTypes);
            });

            $(elemTipDocTypes).find('a').first().addClass('first-qtip-list-ele');
            $(elemTipDocTypes).find('a').last().addClass('last-qtip-list-ele');

            var qtipKeyHandler = function(e) {
                var elem = false,
                    selector = $('.doc-type-selector-list');
                switch (e.keyCode) {
                    case 38:   //up
                        if (docTypeSelected != 0) {
                            elem = $('#doc-type-'+docTypeSelected).prev();
                        }
                        if (!elem || elem.length === 0)
                            elem = selector.find('a').last();
                        break;
                    case 40:   //down
                        if (docTypeSelected != 0) {
                            elem = $('#doc-type-'+docTypeSelected).next();
                        }
                        if (!elem || elem.length === 0)
                            elem = selector.find('a').first();
                        break;
                    case 13:
                    case 9:
                    case 27:  // close
                        $(elemDocTypeSel).qtip('hide');
                        break
                }
                if (elem) {
                    docTypeSelected = elem.attr('data-doc-type');
                    elem.addClass('current').siblings().removeClass('current');
                    $(elemDocTypeSel).html(docTypeSelected);

                    if (options.onChange && $.isFunction(options.onChange)) options.onChange(docTypeSelected);
                }
            };
            //Attach qtip to type selector:
            $(elemDocTypeSel).qtip({
                content: {
                    text: $(elemTipDocTypes),
                    title: false
                },
                show: {
                    delay:0,
                    event: 'focus click'
                },
                hide: {
                    event: 'unfocus',
                    delay: 200
                },
                position: {
                    my: 'top center',
                    at: 'bottom center',
                    adjust: {
                        y: 2
                    }
                },
                style: {
                    classes: 'qtip-select',
                    width: '130',
                    def: false,
                    background: 'none',
                    tip: {
                        width: 10,
                        height: 6
                    }
                },
                api: {
                    onShow: function(e) {
                        $(document).bind('keydown.document-type-selector', qtipKeyHandler);
                    },
                    onHide: function(e) {
                        $(document).unbind('keydown.document-type-selector', qtipKeyHandler);
                    }
                }
            });
        } else {
            var elemDocTypeSel = document.createElement("span");
            $(elemDocTypeSel)
                .addClass("document-type-selector-single")
                .text(IG.getDocumentTypeName(options.docTypes[0]));
        }


        var elemWrap = document.createElement('div');
        $(elemWrap)
            .addClass('document-type-selector-wrap')
            .html('<label>'+IG.label.get('GLOBAL_DOCUMENT_TYPE')+'</label>')
            .append(elemDocTypeSel);

        return $(elemWrap);
    };





    this.renderDocumentCreator = function(options) {
        options = (typeof options !== 'undefined' ? options : {});
        options.onCreate = ($.isFunction(options.onCreate) ? options.onCreate : function() {});
        options.onOpen = ($.isFunction(options.onOpen) ? options.onOpen : function() {});
        if (!$.isArray(options.docTypes) || options.docTypes.length == 0) {
            return false;
        }
        options.language = (typeof options.language !== 'undefined' ? options.language : IG.customerConfig['defaultContentLanguage']);

        var currentDocType = null;
        var infoObject = null;
        var objHeadlineEditor = null;

        function buildInDocCreationForm() {
            var elemWrap = document.createElement('div');
            $(elemWrap)
                .attr('id', 'inline-doc-create')
                .append('<span class="ui-helper-hidden-accessible"><input type="text"/></span>');


            var elemDocTypeLabel = document.createElement('label');
            $(elemDocTypeLabel)
                .addClass('')
                .text(IG.label.get('GLOBAL_DOCUMENT_TYPE') + ': ')
                .appendTo(elemWrap);

            if (options.docTypes.length > 1) {
                var elemDocTypeSel = document.createElement("a");
                $(elemDocTypeSel)
                    .addClass("document-type-selector hubber-select btn-icon btn-icon-right")
                    .attr('href', '#')
                    .attr('onclick', 'return false')
                    .appendTo(elemWrap);

                if (currentDocType !== null) {
                    $(elemDocTypeSel).html('<span class="btn-text ellipsis">' + IG.getDocumentTypeName(currentDocType) + '</span>');
                    infoObject.type = currentDocType;
                } else {
                    $(elemDocTypeSel)
                        .html('<span class="btn-text ellipsis">' +IG.label.get('CHOOSE_DOCUMENT_TYPE')+ '</span>')
                }

                var elemTipDocTypes = document.createElement("div");
                $(elemTipDocTypes).addClass('doc-type-selector-list');

                $.each(options.docTypes, function(i, docType) {

                    var elemADocType = document.createElement("a");
                    $(elemADocType)
                        .attr('id','doc-type-'+docType)
                        .attr('data-doc-type', docType)
                        .text(IG.getDocumentTypeName(docType))
                        .click(function() {
                            $(elemDocTypeSel).html(
                                '<span class="btn-text ellipsis">' + IG.getDocumentTypeName(docType) + '</span>'
                            ).qtip('hide');
                            currentDocType = infoObject.type = docType;
                            if (options.onChange && $.isFunction(options.onChange)) options.onChange(docType);
                            _this.setDocumentType(docType);
                        })
                        .appendTo(elemTipDocTypes);
                });

                $(elemTipDocTypes).find('a').first().addClass('first-qtip-list-ele');
                $(elemTipDocTypes).find('a').last().addClass('last-qtip-list-ele');



                //Attach qtip to type selector:
                $(elemDocTypeSel).qtip({
                    content: {
                        text: $(elemTipDocTypes),
                        title: false
                    },
                    show: {
                        delay:0,
                        event: 'focus click'
                    },
                    hide: {
                        event: 'unfocus',
                        delay: 200
                    },
                    position: {
                        my: 'top center',
                        at: 'bottom center',
                        adjust: {
                            y: 2
                        }
                    },
                    style: {
                        classes: 'qtip-select',
                        width: $(elemDocTypeSel).width(),
                        def: false,
                        background: 'none',
                        tip: {
                            width: 10,
                            height: 6
                        }
                    }
                });
            } else {
                infoObject.type = currentDocType = options.docTypes[0];

                var elemDocTypeSingle = document.createElement('span');
                $(elemDocTypeSingle)
                    .addClass('doc-type-single-val')
                    .text(IG.getDocumentTypeName(options.docTypes[0]))
                    .appendTo(elemWrap);
            }



            // Headline editor
            var elemHeadlineLabel = document.createElement('h3');
            $(elemHeadlineLabel)
                .appendTo(elemWrap)
                .text(IG.label.get("info_object_headline"));

            var elemHeadlineWrap = document.createElement('div');
            $(elemHeadlineWrap)
                .attr('id', 'ig-inline-document-headline')
                .appendTo(elemWrap);

            window.setTimeout(function() {
                objHeadlineEditor = new IG.Editor({
                    key: "headline",
                    language: options.language,
                    cssClass: 'input-editor',
                    container: $("#ig-inline-document-headline").get(0),
                    texts: infoObject.headline,
                    singleline: true,
                    requestfocus: true,
                    onchange: function(s, language) {
                        if (infoObject.headline[language] != s) {
                            infoObject.headline[language] = s;
                        }
                    }
                });
                IG.EditorController.register(objHeadlineEditor);
            }, 10);
            return elemWrap;
        }

        function destroyEditor() {
            if (objHeadlineEditor !== null) {
                IG.EditorController.destroyEditorById(objHeadlineEditor.id);
            }
        }


        var elemBtnA = document.createElement('a');
        $(elemBtnA)
            .addClass('btn-small btn-dark btn-left')
            .html('<span>'+ IG.label.get('infoobject_create_new') +'</span>')
            .click(function(e) {
                e.preventDefault();
                var displayStartTime = IG.fn.dateToTimestamp(IG.getCurrentUTCDate());
                var displayEndTime = IG.getCurrentUTCDate();
                displayEndTime.setUTCMonth(displayEndTime.getUTCMonth() + 1);
                displayEndTime = IG.fn.dateToTimestamp(displayEndTime);
                infoObject = IG.fn.createRawInfoObject(
                    { type: options.docTypes[0] },
                    displayStartTime,
                    displayEndTime
                );
                infoObject.type = currentDocType;
                //Set headline, subheadline and body texts:
                $.each(IG.customerConfig.contentLanguages, function(i) {
                    infoObject.headline[i] = "";
                    infoObject.subHeadline[i] = "";
                    infoObject.body[i] = "";
                    infoObject.location[i] = "";
                });
                delete infoObject.id; // new infoobjects must not contain an id

                var bCreateClicked = false;

                $( "#modal-window" )
                    .html(buildInDocCreationForm())
                    .dialog({
                        close: function() {
                            destroyEditor();
                        },
                        title: IG.label.get('infoobject_create_new'),
                        modal: true,
                        position: { my: "center", at: "center", of: window, collision: 'fit' },
                        width: 460,
                        height: 'auto',
                        buttons: [
                            {
                                text: IG.label.get('cancel'),
                                click: function() {
                                    $( "#modal-window").dialog('close');
                                }
                            },
                            {
                                text: IG.label.get('global_create'),
                                click: function() {
                                    // ignore multiple clicks
                                    if (bCreateClicked) {
                                        return false;
                                    }
                                    bCreateClicked = true;

                                    if (infoObject.headline[options.language].length == 0) {
                                        bCreateClicked = false; // reset
                                        IG.showErrorDialog(IG.label.get('MISSING_HEADLINE_INLINE_CREATE'));
                                    } else if (infoObject.type == null) {
                                        bCreateClicked = false; // reset
                                        IG.showErrorDialog(IG.label.get('GLOBAL_MISSING_DOCUMENT_TYPE'));
                                    } else {
                                        IG.gui.loading.show();
                                        IG.request({
                                            method: "updateInfoObject",
                                            params: [infoObject, true, false, options.language],
                                            post: true,
                                            success: function(response) {
                                                IG.gui.loading.hide();
                                                bCreateClicked = false; // reset
                                                if (response.result) {
                                                    options.onCreate(response.data);
                                                    $( "#modal-window").dialog('close');
                                                } else {
                                                    console.log('CREATE INLINE, success but negative result:', response);
                                                }
                                            },
                                            error: function(err) {
                                                IG.gui.loading.hide();
                                                IG.showErrorDialog(err);
                                            }
                                        });
                                    }
                                    return true;
                                }
                            }
                        ],

                        // reset to defaults
                        minWidth: 150,
                        maxWidth: false,
                        minHeight: 150,
                        maxHeight: false
                    });

            });
        this.elem = elemBtnA;

        this.setDocType = function(documentType) {
            if (documentType !== null && options.docTypes.indexOf(documentType) > -1) {
                currentDocType = documentType;
            } else {
                currentDocType = null;
            }
        };

        return this;
    };




    //Renders colgroups (for keeping column widths):
    this.renderColGroups = function() {
        var s = '';
        s += '<colgroup>';
        if (_this.config.subelements) {
            s += '<col style="width: 20px" />';
        }

        tableWidth = 0;
        var hasAutoWidthCol = false;

        $.each(_this.config.columns, function(i, n) {
            s += '<col' + (n.width != "auto" ? ' style="width: ' + n.width + '"' : '') + ' />';

            if (n.width == "auto") {
                hasAutoWidthCol = true;
            }

            if (n.width !== 'auto') {
                var w = n.width.split('px');
                if (w[0] && !isNaN(parseInt(w[0]))) {
                    tableWidth += parseInt(w[0], 10);
                }

            }
        });
        if (!hasAutoWidthCol) {
            tableWidth = null;
        }

        s += '</colgroup>';
        return s;
    };


    //Renders the table header:
    this.renderHeader = function() {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-objectlist-header");
        
        

        if (!_this.config.scrolling) {
            $(objDiv).addClass("no-scrolling");
        }

        var objTable = document.createElement("table");
        $(objTable)
            .addClass("ig-objectlist-tbl-header")
            .append(_this.renderColGroups())
            .appendTo(objDiv);

        //Build table header:
        var objTHead = document.createElement("thead");
        $(objTHead).appendTo(objTable);

        var objTR = document.createElement("tr");
        $(objTR).appendTo(objTHead);


        if (_this.config.subelements) {
            var objTHsub = document.createElement("th");
            $(objTHsub)
                .addClass("sub")
                // .css('width', '20px')
                .appendTo(objTR);
        }

        //Build table header cells:
        $.each(_this.config.columns, function(i, n) {
            var objTH = document.createElement("th");
            $(objTH)
                .data("name", n.name)
                .attr("title", n.title)
                .text(n.title)
                .appendTo(objTR);

            if (n.className != "") {
                $(objTH).addClass(n.className);
            }

            //Attach qtip to span:
            if (typeof n.tip != "undefined") {

                window.setTimeout(function() {
                    var objTip = $(objTH).qtip({
                        content: {
                            text: $(n.tip.content),
                            title: false
                        },
                        hide: {
                            fixed: true,
                            delay: 200,
                            event: 'click mouseleave'
                        },
                        position: {
                            my: 'top left',
                            at: 'bottom left',
                            adjust: {
                                y: 5
                            }
                        },
                        style: {
                            classes: "qtip-menu qtip-list-header",
                            def: false,
                            width: $(objTH).width() + 30,
                            height: n.tip.height,
                            tip: false
                        }
                    });

                    //Store this qtip in our list:
                    _this.tips.push(objTip);
                }, 0);


            }

            //Column is sortable:
            if (n.sortable) {
                $(objTH).addClass("sortable");

                if (n.name == _this.options.sorting) {
                    $(objTH).addClass("sortby");

                    if (_this.options.order == "ASC") {
                        $(objTH).addClass("sort-asc");
                    }
                    else if (_this.options.order == "DESC") {
                        $(objTH).addClass("sort-desc");
                    }
                }

                $(objTH).click(function() {
                    _this.setSorting(n.name);
                    _this.refresh();
                });

            }
        });

        return objDiv;
    };




    //Get item row with id:
    this.getItemRow = function(id) {
        var objTR = null;
        if (_this.itemsTableBody != null) {
            $.each($(_this.itemsTableBody).find("tr"), function(i, n) {
                if ($(n).data("id") == id) {
                    objTR = n;
                    return false; //break loop
                }
            });
        }
        return objTR;
    };

    //Renders a single item:
    this.renderItem = function(n, bSub, parentObj) {
        if (typeof n !== 'undefined') {

            // cache title:

            var objTR = document.createElement("tr");
            $(objTR)
                .addClass((bSub ? "sub" : "") + "item")
                .data("id", n.id["id"])
                .data("idObj", n.id)
                .data("parentId", null)
                .addClass('ITEM_'+n.id["id"])
                .hover(
                    function() {
                        $(this).addClass("hl");
                    },
                    function() {
                        $(this).removeClass("hl");
                    }
                );

            if (bSub && parentObj && parentObj.id) {
                $(objTR).data('parentId', parentObj.id);
            }

            //Create subelements toggler if needed:
            if (_this.config.subelements) {
                var objTDsub = document.createElement("td");
                $(objTDsub)
                    .addClass("sub")
                    .appendTo(objTR);

                var bHasSubelements = false;

                //Subelements exist:
                if (!bSub) {
                    if (typeof n.referencedInfoObjectIds != "undefined") {
                        if (typeof n.referencedInfoObjectIds.length != "undefined") {
                            if (n.referencedInfoObjectIds.length > 0) {
                                bHasSubelements = true;
                            }
                        }
                    }
                }

                if (bHasSubelements) {
                    var objSubToggler = document.createElement("span");
                    $(objSubToggler)
                        .addClass("sub")
                        .click(function() {
                            var _me = this;
                            _this.toggleSubItems(n, objTR, function() {
                                $(objTR).toggleClass("sub-open");
                                $(_me).toggleClass("sub-open");
                            });
                        })
                        .appendTo(objTDsub);
                }
                else {
                    $(objTDsub).html("&nbsp;");
                }
            }


            $.each(_this.config.columns, function(j, m) {
                var objTD = document.createElement("td");
                $(objTD).appendTo(objTR);

                if ($.isFunction(m.renderTitle)) {
                    $(objTD).attr("title", m.renderTitle(n));
                }

                if (m.className != "") {
                    $(objTD).addClass(m.className);
                }
/*
                if (typeof m.width !== 'undefined' && m.width !== '') {
                    $(objTD).css('width', m.width);
                } else {
                    $(objTD).css('width', 'auto');
                }*/

                if (m.sortable) {
                    if (m.name == _this.options.sorting) {
                        $(objTD).addClass("sortby");
                    }
                }


                if ($.isFunction(m.render)) {
                    $(objTD).append(m.render(n));
                }
                else {
                    $(objTD).append("?");
                }
            });

            return objTR;
        }
    };


    //Removes a row from list
    this.removeRow = function(id, cb) {
        if (typeof id == "object") {
            id = id["id"];
        }
        if (typeof id == "string") {
            if (id != "") {
                $.each($(_this.itemsTableBody).find("tr"), function(i, n) {
                    if ($(n).data("id") == id) {
                        $(n).remove();

                        _this.itemcount -= 1;
                        if (_this.config.adjustHeight) {
                            //$(_this.itemsContent).height(_this.config.rowHeight * _this.itemcount);

                        }

                        if ($.isFunction(cb)) {
                            cb();
                        }

                        return false; //break loop;
                    }
                });
                var rows = $(_this.itemsTableBody).find("tr").length;
                _this.scrolling.maxRow = rows;
                var maxRows = _this.getMaxVisibleRows();
                if (rows < maxRows - 2) {
                    $(_this.itemsContent).height(_this.config.rowHeight * $(_this.itemsTableBody).find("tr").length);
                }
            }
        }
    };


    //Manually append a row to table body:
    this.appendRow = function(object, bIncCount) {
        var bDoAdd = true;

        if (_this.config.orderByList) {
            // bDoAdd = _this.config.orderByList.length === $(_this.itemsTableBody).children().length;

            var lastRenderedChild = $(_this.itemsTableBody).children().last();
            if (lastRenderedChild) {
                var lastItemId = lastRenderedChild.data('id');
                if (typeof _this.config.orderByList[_this.config.orderByList.length-2] === 'undefined') {
                    bDoAdd = true;
                } else {
                    var secondLastInListId = _this.config.orderByList[_this.config.orderByList.length-2].id;
                    bDoAdd = lastItemId === secondLastInListId;
                }

            }
        }

        if (bDoAdd) {
            bIncCount = (typeof bIncCount == "boolean" ? bIncCount : false);
            var objTR = _this.renderItem(object);
            if (objTR != null) {
                $(_this.itemsTableBody).append(objTR);
                if (bIncCount) {
                    _this.items.push(object);
                    _this.itemcount++;
                }
            }
        }

    };

    //Manually prepend a row to table body:
    this.prependRow = function(object, bIncCount) {
        bIncCount = (typeof bIncCount == "boolean" ? bIncCount : false);
        var objTR = _this.renderItem(object);
        if (objTR != null) {
            $(_this.itemsTableBody).prepend(objTR);
            if (bIncCount) {
                _this.items.push(object);
                _this.itemcount++;
            }
        }
    };

    /**
     * Get array of id objects. No support for sub-elements.
     * @returns {Array}
     */
    this.getItemIds = function() {
        var res = [];
        if (_this.config.orderByList) {
            res = _this.config.orderByList;
        } else {
            $.each($(_this.itemsTableBody).find("tr.item"), function(i, n) {
                res.push($(n).data("idObj"));
            });
        }
        return res;
    };




    this.showThrobber = function(bDown, throbDelta) {
        if (_this.throbber != null) {

            //show throbber - position before or after loaded items depending on scroll direction
            var t = _this.scrolling.stop;
            var h = Math.min($(_this.itemsScroller).height(), throbDelta * _this.config.rowHeight);


            //Scrolled up - position throbber before items - but within current view.
            if (!bDown) {
                $(_this.throbber)
                .css({
                    "top": t + "px",
                    "height": h + "px"
                })
                .show();
            }
            //Scrolled down - position throbber after items - but within current view.
            else {
                t = Math.max($(_this.itemsTable).position().top + $(_this.itemsTable).height(), _this.scrolling.stop);

                $(_this.throbber)
                .css({
                    "top": t + "px",
                    "height": h + "px"
                })
                .show();
            }
        }
    };

    this.hideThrobber = function() {
        if (_this.throbber != null) {
            $(_this.throbber).hide();
        }
    };


    //Handles item scrolling:
    this.scrollItems = function(obj, callback) {
        if (obj) {
            IG.debug("IG.ObjectList.scrolling, startRow: " + _this.scrolling.startRow() + ", stopRow: " + _this.scrolling.stopRow() + ", deltaRow: " + _this.scrolling.deltaRow() + ", minRow: " + _this.scrolling.minRow + ", maxRow: " + _this.scrolling.maxRow);

            //Number of items to retrieve:
            var limit = 0;

            //Offset of items to retrieve:
            var offset = 0;

            //Get the max number of visible rows:
            var availableRows = _this.getMaxVisibleRows();

            //Scroll start row:
            var startRow = _this.scrolling.startRow();

            //Scroll stop row:
            var stopRow = _this.scrolling.stopRow();

            //Scroll delta:
            var delta = _this.scrolling.deltaRow();

            var throbDelta = delta;

            //Currently rendered min and max row offset:
            var minRow = _this.scrolling.minRow;
            var maxRow = _this.scrolling.maxRow;


            //Function to perform after load:
            var fn = function(objects) {
                //Behavior depends on the amount and direction scrolled
            };

            var filterIds = false;

            if (delta == 0)
                return;

            //Scrolled no more than max allowed:
            //if (Math.abs(delta) <= _this.config.maxrows) {

                //Scroll up:
                if (delta < 0) {
                    IG.debug("IG.ObjectList.scrollItems, scroll UP");

                    if (stopRow < minRow) {
                        limit = minRow - stopRow;
                        throbDelta = minRow - stopRow;

                        // limit = Math.min(availableRows, _this.config.maxrows);
                    }

                    if (limit > 0) {
                        offset = Math.max(minRow - limit, 0);

                        if (_this.config.orderByList) {
                            filterIds = _this.config.orderByList.slice(offset, offset+limit);
                        }


                        fn = function(objects) {
                            IG.debug("IG.ObjectList.load, offset: " + offset + ", limit: " + limit);
                            if (typeof objects != "undefined") {
                                _this.scrolling.minRow = offset;

                                //Create new table rows to insert:
                                var arrTR = [];
                                $.each(objects, function(i, n) {
                                    var objTR = _this.getItemRow(n.id["id"]);
                                    if (objTR == null) {
                                        arrTR.push(_this.renderItem(n, false));
                                    }
                                });

                                //Prepend new rows
                                $(_this.itemsTableBody).prepend(arrTR);

                                //Change top positioning                                
                                $(_this.itemsTable).css("top", _this.scrolling.stop + "px");
                            }
                        };
                    }
                }
                //Scroll down:
                else if (delta > 0) {
                    IG.debug("IG.ObjectList.scrollItems, scroll DOWN");


                    //Scrolled past last rendered row:
                    if (stopRow > maxRow) {
                        throbDelta = stopRow - maxRow;

                        //limit = stopRow - maxRow;
                        limit = Math.round(Math.max(availableRows, _this.config.maxrows / 2));
                    }
                    //Scrolled within visible area:
                    else if (maxRow - stopRow < availableRows) {
                        throbDelta = availableRows - (maxRow - stopRow);

                        //limit = availableRows - (maxRow - stopRow);
                        limit = Math.round(Math.max(availableRows, _this.config.maxrows / 2));
                    }

                    //Got any items to retrieve?
                    if (limit > 0) {
                        offset = maxRow;

                        if (_this.config.orderByList) {
                            filterIds = _this.config.orderByList.slice(offset, offset+limit);
                        }


                        fn = function(objects) {
                            IG.debug("IG.ObjectList.load, offset: " + offset + ", limit: " + limit);
                            if (typeof objects != "undefined") {
                                _this.scrolling.maxRow += objects.length;

                                //Append table rows:
                                $.each(objects, function(i, n) {
                                    var objTR = _this.getItemRow(n.id["id"]);
                                    if (objTR == null) {
                                        $(_this.itemsTableBody).append(_this.renderItem(n, false));
                                    }
                                });
                            }
                        };
                    }
                }
           // }
            //Scrolled more than max allowed:
            else {
                IG.debug("IG.ObjectList.scrollItems, delta > max");

                //limit = Math.min(availableRows, _this.config.maxrows);
                limit = Math.round(Math.max(availableRows, _this.config.maxrows / 2));
                if (limit > 0) {
                    offset = stopRow;

                    fn = function(objects) {
                        IG.debug("IG.ObjectList.load, offset: " + offset + ", limit: " + limit);

                        if (typeof objects != "undefined") {
                            _this.scrolling.minRow = offset;
                            _this.scrolling.maxRow = Math.max(offset + objects.length - 1, offset);

                            //Replace content and set top positioning:
                            var arrTR = [];
                            $.each(objects, function(i, n) {
                                arrTR.push(_this.renderItem(n, false));
                            });
                            $(_this.itemsTableBody).html(arrTR);
                            $(_this.itemsTable).css("top", _this.scrolling.stop + "px");
                        }
                    };
                }
            }



            if (limit > 0) {
                //Make sure not to fectch data while busy fetching data for a previous scroll:
                if (!_this.scrolling.busy) {
                    _this.scrolling.busy = true;

                    prepareSearch(limit);

                    _this.showThrobber((delta > 0), throbDelta);
                    _this.load(offset, limit, _this.options.filterMode, filterIds, function(objects) {
                        desiredResultCount = 0;
                        _this.hideThrobber();

                        // sort the results according to the given option "orderByList"
                        if (_this.config.orderByList) {
                            objects = _this.sortObjects(objects, _this.config.orderByList);
                        }

                        fn(objects);

                        _this.scrolling.busy = false;

                        IG.debug("IG.ObjectList.scrollItems done, minRow: " + _this.scrolling.minRow + ", maxRow: " + _this.scrolling.maxRow);

                        if ($.isFunction(callback)) {
                            callback();
                        }
                    });
                }
                else {
                    if ($.isFunction(callback)) {
                        callback();
                    }
                }
            }
            else {
                if ($.isFunction(callback)) {
                    callback();
                }
            }
        }
    };


    //Returns the current number of rows (excluding subelements):
    this.getNumberOfRows = function() {
        return $(_this.itemsTableBody).children("tr.item").length;
    };

    //Returns the available height for items:
    this.getAvailableHeight = function(forceRefresh) {
        if (rowHeight === null || forceRefresh === true) {
            var p = _this.config.parentObject;
            rowHeight = $(p).height() - (2*16 + 2*8 + 14 + 1) - 28;
            // console.log('[getAvailableHeight]', $(p).height(), rowHeight);
        }
        return rowHeight;
    };

    //Returns the max number of visible rows given current list height:
    this.getMaxVisibleRows = function() {
        return Math.ceil(_this.getAvailableHeight(true) / _this.config.rowHeight);
    };

    //Renders the table items:
    this.renderItems = function(objects) {
        IG.debug("ObjectList.renderItems, id: " + _this.id);

        var p = _this.config.parentObject;
        var h = _this.getAvailableHeight();

        //Create scrollable container:
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-objectlist-items-wrap");

        if (_this.config.adjustHeight) {
            $(objDiv).height(h-1);
        }

        if (!_this.config.scrolling) {
            $(objDiv).addClass("no-scrolling");
        }


        //Handle scrolling if enabled:
        if (_this.config.scrolling) {
            //Adjust height on window resize:
            if (_this.config.adjustHeight) {
                $(window).resize(function() {
                    $(objDiv).height(_this.getAvailableHeight(true));
                });
            }

            _this.itemsScroller = objDiv;

            var objDivThrobber = document.createElement("div");
            $(objDivThrobber)
                .addClass("ig-objectlist-throbber")
                .appendTo(_this.itemsScroller);
            _this.throbber = objDivThrobber;


            $(objDiv)
                .bind("scrollstart", function() {
                    if (!_this.scrolling.busy) {
                        _this.scrolling.start = _this.scrolling.stop;
                    }
                })
                .bind("scrollstop", function() {
                    if (!_this.scrolling.busy) {
                        _this.scrolling.stop = $(this).scrollTop();
                        _this.scrolling.delta = _this.scrolling.stop - _this.scrolling.start;

                        _this.scrollItems($(this), _this.events.scrolled());
                    }
                });
        }


        //Create inner container for elements with total height:
        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-objectlist-items-content")
            .appendTo(objDiv);

        /*if (_this.config.scrolling) {
            if (_this.config.adjustHeight) {
                $(objDivContent).height(_this.config.rowHeight * _this.itemcount);
            }
        }*/

        _this.itemsContent = objDivContent;

        //Create table with items:
        var objTable = document.createElement("table");
        $(objTable)
            .addClass("ig-objectlist-items")
            .append(_this.renderColGroups())
            .appendTo(objDivContent);
        _this.itemsTable = objTable;

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);
        _this.itemsTableBody = objTBody;


        //Make table rows sortable by drag n drop:
        if (_this.config.sortable) {
            var itemStartIndex = 0;
            var itemEndIndex = 0;
            var itemMovedCount = 0;

            $(objTBody).sortable({
                containment: "parent",
                axis: "y",
                tolerance: "pointer",
                handle: "span.sort",
                opacity: 0.7,
                start: function(e, ui) {
                    var elem = ui.item;
                    if (elem) {
                        itemStartIndex = elem.index();
                    }
                },
                stop: function(e, ui) {
                    var elem = ui.item;
                    // var parentElem = $(this)[0]; // parentElem, parentElem.childElementCount

                    if (elem) {
                        itemEndIndex = elem.index();
                        itemMovedCount = itemEndIndex-itemStartIndex;

                        if (_this.config.orderByList) {
                            var oldTotalIndex = _this.getIndexOfItem(elem.data("id"));
                            if (oldTotalIndex) {
                                var newTotalIndex = oldTotalIndex + itemMovedCount;
                                _this.config.orderByList.splice(newTotalIndex, 0, _this.config.orderByList.splice(oldTotalIndex, 1)[0]);
                            }
                        }
                    }
                    _this.config.afterSort();

                },
                helper: function(e, tr) {
                    var objHelper = document.createElement("div");
                    $(objHelper)
                        .addClass("ig-objectlist-helper")
                        .height($(tr).outerHeight())
                        .width($(tr).outerWidth());

                    return objHelper;
                }
            });
            $(objTBody).disableSelection();
        }

        if (typeof objects != "undefined") {
            if (typeof objects.length != "undefined") {
                $.each(objects, function(i, n) {
                    $(objTBody).append(_this.renderItem(n, false));
                });
            }
        }

        return objDiv;
    };


    //Renders the table:
    this.render = function(objects, callback) {

        IG.debug("IG.ObjectList.render, callback: " + callback);

        if (_this.config.parentObject != null) {
            var objDiv = $("#" + _this.id).get(0);

            //Not rendered yet - build both parent, header and items:
            if (typeof objDiv === 'undefined' || objDiv === null) {
                objDiv = document.createElement("div");
                $(objDiv)
                    .addClass("ig-objectlist")
                    .attr("id", _this.id)
                    .append(_this.renderHeader())
                    .append(_this.renderItems(objects));

                /*window.setTimeout(function() {
                    $(objDiv).append(_this.renderItems(objects));
                }, 2500);*/

                if (tableWidth) {
                    $(objDiv).css('min-width', (tableWidth+140) +'px')
                }

                $(_this.config.parentObject)
                    .empty()
                    .append(objDiv);

            }
            //Already rendered - keep header but re-render items:
            else {
                $(objDiv)
                    .children("div.ig-objectlist-items-wrap:first")
                    .replaceWith(_this.renderItems(objects));
            }
        }

        if ($.isFunction(callback)) {
            IG.debug("IG.ObjectList.render, isFunction(callback)");
            callback();
        }
    };



    //Loads total count according to options and config:
    this.loadCount = function(callback) {
        var sorts = {};

        if (_this.options.sorting.length > 0) {
            sorts[_this.options.sorting] = _this.options.order;
        }

        var filters = $.extend(true, {}, _this.options.filters);

        if ($.isArray(filters.owners)) {
            var ownersCpy = filters.owners.slice(0);
            filters.ownerId = {
                '$in': ownersCpy
            };
            delete filters.owners;
        }

        var params = [];
        var bRequest = true;

        // In case there is an empty ID-filter, we assume the count is 0
        // If we don't the server will ignore the filter
        if (typeof filters.ids !== 'undefined' && filters.ids.length == 0) {
            bRequest = false;
            _this.itemcount = 0;
        }

        switch (_this.config.methodName) {
            case "searchGalleries":
                params = [IG.config.language, _this.options.searchLang, _this.options.query, true, filters, sorts, 1, 0];
                break;

            case "searchInfoObjects":
                params = [IG.config.language, _this.options.searchLang, _this.options.query, true, filters, sorts, 1, 0, _this.options.filterMode];
                break;

            case "searchUsers":
                params = [IG.config.language, _this.options.query, true, filters, sorts, 1, 0];
                break;

            case "searchChannels":
                params = [IG.config.language, _this.options.searchLang, _this.options.query, true, filters, sorts, 1, 0];
                break;

            case "searchMedia":
                params = [IG.config.language, _this.options.searchLang, _this.options.query, true, filters, sorts, 1, 0];
                break;

            default:
                bRequest = false;
                break;
        }

        if (bRequest) {
            IG.request({
                method: _this.config.methodName,
                params: params,
                success: function(response) {
                    if (response.result) {
                        var c = parseInt(response.data["count"], 10);
                        if (isNaN(c)) {
                            c = 0;
                        }
                        _this.origItemCount = c;
                        _this.itemcount = c;
                    }
                    if ($.isFunction(callback)) {
                        callback();
                    }
                },
                error: function(err) {
                    if ($.isFunction(callback)) {
                        callback();
                    }
                }
            });
        }
        else {
            if ($.isFunction(callback)) {
                callback();
            }
        }
    };




    //Renders subitems table:
    this.renderSubItems = function(objects) {
        var objTable = document.createElement("table");
        $(objTable)
            .addClass("ig-objectlist-items")
            .append(_this.renderColGroups);

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);

        if (typeof objects != "undefined") {
            $.each(objects, function(i, n) {
                $(objTBody).append(_this.renderItem(n, true));
            });
        }

        return objTable;
    };


    //Loads subelements for the provided object:
    this.loadSubelements = function(object, callback) {
        if (_this.config.methodName == "searchInfoObjects") {

            //Set sorting:
            var sorts = {};
            if (_this.options.sorting.length > 0) {
                sorts[_this.options.sorting] = _this.options.order;
            }

            //Set filtering:
            var filters = $.extend(true, {}, _this.options.filters);
            delete filters.parentsOnly;

            if (typeof object.referencedInfoObjectIds != "undefined") {
                filters.ids = object.referencedInfoObjectIds;
            }
            else {
                filters.ids = object.matchingReferencedInfoObjectIds;
            }

            //Set params:
            var params = [IG.config.language, _this.options.searchLang, "", true, filters, sorts, 0, 0];

            //Send request
            IG.request({
                method: _this.config.methodName,
                params: params,
                success: function(response) {
                    var responseData = response.data["data"];
                    callback(responseData);
                },
                error: function(err) {
                    //error occured fetc
                }
            });
        }
    };


    //Toggles the subitems given parent object and its table row:
    this.toggleSubItems = function(object, objTRref, callback) {
        var bLoading = false;
        if ($(objTRref).data("subitems") == object.id["id"]) {
            bLoading = true;
        }

        if (!bLoading) {
            $(objTRref).data("subitems", object.id["id"]);

            //Load the subelements
            _this.loadSubelements(object, function(objects) {

                //Add subelements rows:
                if (typeof objects != "undefined") {
                    $.each(objects, function(i, n) {
                        var subRow = _this.renderItem(n, true, object);
                        $(subRow).addClass(object.id.id);
                        $(subRow).insertAfter($(objTRref));
                    });
                }

                //Perform callback:           
                callback();
            });
        }
        //Subitems row exists: show or hide it:
        else {
            $("." + object.id.id).toggle();
            callback();
        }
    };

    //Loads data according to offset, limit, options and config:
    this.load = function(offset, limit, filterMode, filterIds, callback) {
        filterMode = (typeof filterMode === 'undefined' ? 1 : filterMode);
        filterMode = ( (filterMode !== 1 && filterMode !== 2) ? 1 : filterMode );

        recursionCounter++;

        var sorts = {};
        if (_this.options.sorting.length > 0) {
            sorts[_this.options.sorting] = _this.options.order;
        }

        var filters = $.extend(true, {}, _this.options.filters);

        var bRequest = true;

        if (filterIds && $.isArray(filterIds)) {
            if (filterIds.length == 0) {
                bRequest = false;
            } else {
                offset = 0;
                limit = filterIds.length;
                filters.ids = filterIds;

                desiredResultCount = filterIds.length;
            }
        }
        if (limit < 0) {
            console.error('[IG.ObjectList.load] limit is less than 0: ', limit);
            if ($.isFunction(callback)) {
                callback([]);
            }
            return false;
        }

        if ($.isArray(filters.owners)) {
            var ownersCpy = filters.owners.slice(0);
            filters.ownerId = {
                '$in': ownersCpy
            };
            delete filters.owners;
        }

        var params = [];

        var afterLoad = function(objects) {};

        if (bRequest) {
            switch (_this.config.methodName) {
                case "searchGalleries":
                    params = [IG.config.language, _this.options.searchLang, _this.options.query, true, filters, sorts, limit, offset];
                    break;

                case "searchInfoObjects":
                    params = [IG.config.language, _this.options.searchLang, _this.options.query, true, filters, sorts, limit, offset, filterMode];
                    afterLoad = function(infoObjects) {
                        $.each(infoObjects, function(i, io) {
                            IG.documentTitleCache.set(io.id, io.headline);
                        });
                    };
                    break;

                case "searchUsers":
                    params = [IG.config.language, _this.options.query, true, filters, sorts, limit, offset];
                    break;

                case "searchChannels":
                    params = [IG.config.language, _this.options.searchLang, _this.options.query, true, filters, sorts, limit, offset];
                    afterLoad = function(channels) {
                        $.each(channels, function(i, channel) {
                            IG.channelNameCache.set(channel.id, channel.name);
                        });
                    };
                    break;

                case "searchMedia":
                    params = [IG.config.language, _this.options.searchLang, _this.options.query, true, filters, sorts, limit, offset];
                    break;

                default:
                    bRequest = false;
                    break;
            }
        }


        if (bRequest) {

            if (_this.itemcount > 0) {
                var loadingId = "loading-" + _this.id;
                IG.gui.loading.show(_this.config.parentObject, loadingId, true);
                IG.request({
                    method: _this.config.methodName,
                    params: params,
                    success: function(response) {
                        IG.gui.loading.hide(loadingId);
                        if (response.result) {
                            afterLoad(response.data.data);
                            if ($.isFunction(callback)) {
                                callback(response.data.data);
                            }
                        } else {
                            if ($.isFunction(callback)) {
                                callback([]);
                            }
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog("ObjectList error: " + err);
                        if ($.isFunction(callback)) {
                            callback([]);
                        }
                    }
                });
            }
            else {
                if ($.isFunction(callback)) {
                    callback([]);
                }
            }
        }
        else {
            if (_this.itemcount > 0 && _this.items) {
                if ($.isFunction(callback)) {
                    callback(_this.items);
                }
            } else {
                if ($.isFunction(callback)) {
                    callback([]);
                }
            }
        }
    };

    this.sortObjects = function(objects, listToSortAfter) {
        var orderedChs = [];

        $.each(listToSortAfter, function(i, searchObjID){
            var searchObjID = IG.getId(searchObjID.id);

            for (var j=0; j<objects.length; j++) {
                var resC = objects[j];
                var resCid = IG.getId(resC.id);

                if (searchObjID == resCid) {
                    orderedChs.push(resC);
                    objects.splice(j,1);
                    break;
                }
            }
        });
        return orderedChs;
    };
    
    

    //Initialize list
    this.init = function(callback) {
        var limit = (_this.config.scrolling ? Math.ceil(_this.getAvailableHeight() / _this.config.rowHeight) : 0);
        limit = Math.round(limit * 1.5);
        if (typeof opts.startLimit != "undefined") {
            limit = opts.startLimit;
        }
        prepareSearch(limit);

        var filterIds = false;
        if (_this.config.orderByList) {
            filterIds = _this.config.orderByList.slice(0, limit);
        }

        //Get total count:
        _this.loadCount(function() {
            //Load limited number of items:
            _this.load(0, limit, _this.options.filterMode, filterIds, function(objects) {
                desiredResultCount = 0;

                _this.scrolling.minRow = 0;
                _this.scrolling.maxRow = objects.length;


                //_this.options.offset = 0;

                // sort the results according to the given option "orderByList"
                if (_this.config.orderByList) {
                    objects = _this.sortObjects(objects, _this.config.orderByList);
                }

                //Render:
                _this.render(objects);

                //Perform callback:
                if ($.isFunction(callback)) {
                    callback();
                }
                
                //Correct the table header padding
                var listW = $('.ig-objectlist').width();
                var listContentW = $('.ig-objectlist-items-content').width();
                var wDiff = listW - listContentW;
                $('div.ig-objectlist-header').css({
                    'padding-right': wDiff+"px"
                });
            });
        });
    };

    return this;
};
