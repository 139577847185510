import React, {
  useEffect,
  useCallback,
  useMemo,
  FC,
  ReactNode,
  ComponentProps,
} from "react";
import {
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
  OrderedListExtension,
  BulletListExtension,
  NodeFormattingExtension,
} from "remirror/extensions";
import {
  useKeymap,
  Remirror,
  useRemirror,
  EditorComponent,
  Toolbar,
  ToggleBoldButton,
  ToggleItalicButton,
  ToggleUnderlineButton,
  ListButtonGroup,
  TextAlignmentButtonGroup,
  OnChangeHTML,
} from "@remirror/react";
import { Schema } from "prosemirror-model";

type EditorProps = {
  initialContent: string;
  onChange: (html: string) => void;
  formatting?: boolean;
}

export function TextEditor(props: EditorProps) {
  const { initialContent, onChange, formatting } = props;
  const { manager, state } = useRemirror({
    extensions: () => [
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new OrderedListExtension(),
      new BulletListExtension({}),
      new NodeFormattingExtension({}),
    ],
    content: initialContent,
    selection: "end",
    stringHandler: "html",
  });
  return (
    <div className="remirror-theme">
      <Remirror manager={manager} initialContent={state}>
        <>
          {formatting && <Toolbar>
            <ToggleBoldButton />
            <ToggleItalicButton />
            <ToggleUnderlineButton />
            <TextAlignmentButtonGroup />
            <ListButtonGroup />
          </Toolbar>}
          <EditorComponent />
        </>
        <OnChangeHTML onChange={onChange} />
      </Remirror>
    </div>
  );
}

export function SinglelineEditor(props: EditorProps) {
  const { initialContent, onChange } = props;
  const { manager, state } = useRemirror({
    extensions: () => [],
    content: initialContent,
    selection: "end",
    stringHandler: "html",
    schema: new Schema({
      nodes: {
        doc: {
          content: "singleline*",
        },
        singleline: {
          content: "text*",
          toDOM() {
            return ["p", 0];
          },
          parseDOM: [{ tag: "p" }],
        },
        text: {
          group: "inline",
        },
      },
      marks: {},
    }),
  });

  return (
    <div className="remirror-theme singleline">
      <Remirror
        manager={manager}
        initialContent={state}
        hooks={[
          () => {
            useKeymap("Enter", () => true);
          },
        ]}
      >
        <EditorComponent />
        <OnChangeHTML onChange={onChange} />
      </Remirror>
    </div>
  );
}
