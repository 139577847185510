// import 'jquery-ui/dist/jquery-ui';
import 'jquery-ui-bundle/jquery-ui'
import date from "./date.js";

/* Global Infogalleri object - DO NOT RENAME! */
globalThis.IG = window.IG || { };
var IG = globalThis.IG;

/* Sets frequency for refreshing current session (minutes): */
IG.sessionRefreshInterval = 1;


globalThis.isDate = date.isDate;
globalThis.dateAdd = date.dateAdd;
globalThis.dateDiff = date.dateDiff;
globalThis.datePart = date.datePart;
globalThis.weekdayName = date.weekdayName;
globalThis.monthName = date.monthName;
globalThis.weekdayName = date.weekdayName;
globalThis.IsDate = date.IsDate;
globalThis.DataAdd = date.DateAdd;
globalThis.DateDiff = date.DateDiff;
globalThis.DatePart = date.DatePart;
globalThis.WeekdayName = date.WeekdayName;
globalThis.MonthName = date.MonthName;



/* Returns appropriate service urls for given objects */
IG.urls = {
    preview: {
        channel: function(gIdObj, cIdObj, width, height, callback) {
            var gallery = new IG.Gallery();
            IG.getGalleryById(gIdObj, function(objGallery) {
                if (objGallery.moduleId) {
                    var objModule = gallery.fn.getModuleById(objGallery.moduleId.id);
                    if (objModule) {
                        if (objModule.type) {
                            if (objModule.type=='qml_1') {
                                callback("redia-infogalleri://publish.dev.redia.dk/ajax.php?customer=" + gIdObj.customerId +
                                    "&auth=" + IG.userConfig.authenticationCookie +
                                    "&previewType=channel" +
                                    "&galleryId=" + gIdObj.customerId + "::" + gIdObj.id +
                                    "&channelId=" + cIdObj.customerId + "::" + cIdObj.id +
                                    "&width=" + width +
                                    "&height=" + height);
                            }
                        }
                    }
                    if (IG.config.prodMode) {
                        callback("https://webgalleri.infogalleri.net/viewchannel/" + gIdObj.customerId + "/" + gIdObj.id + "/" + cIdObj.customerId + "/" + cIdObj.id + "/");
                    }
                    else {
                        callback("http://webgalleri.infogalleri.dev/viewchannel/" + gIdObj.customerId + "/" + gIdObj.id + "/" + cIdObj.customerId + "/" + cIdObj.id + "/");
                    }

                } else {
                    callback(null);
                }
            });
        },
        gallery: function(idObj, width, height, callback) {
            var gallery = new IG.Gallery();
            IG.getGalleryById(idObj, function(objGallery) {
                if (objGallery.moduleId) {
                    var objModule = gallery.fn.getModuleById(objGallery.moduleId.id);
                    if (objModule) {
                        if (objModule.type) {
                            if (objModule.type=='qml_1') {
                                callback("redia-infogalleri://publish.dev.redia.dk/ajax.php?customer=" +
                                    idObj.customerId +
                                    "&auth=" + IG.userConfig.authenticationCookie +
                                    "&previewType=gallery" +
                                    "&galleryId=" + idObj.customerId + "::" + idObj.id +
                                    "&width=" + width +
                                    "&height=" + height);
                                return false;
                            }
                        }
                    }
                    if (IG.config.prodMode) {
                        callback("https://webgalleri.infogalleri.net/viewgallery/" + idObj.customerId + "/" + idObj.id + "/");
                    }
                    else {
                        callback("http://webgalleri.infogalleri.dev/viewgallery/" + idObj.customerId + "/" + idObj.id + "/");
                    }
                } else {
                    callback(null);
                }
            });
        },
        infoobject: function(gIdObj, iIdObj, width, height, callback) {
            var gallery = new IG.Gallery();
            IG.getGalleryById(gIdObj, function(objGallery) {
                if (objGallery.moduleId) {
                    var objModule = gallery.fn.getModuleById(objGallery.moduleId.id);
                    if (objModule) {
                        if (objModule.type) {
                            if (objModule.type=='qml_1') {
                                callback("redia-infogalleri://publish.dev.redia.dk/ajax.php?customer=" + gIdObj.customerId +
                                    "&auth=" + IG.userConfig.authenticationCookie +
                                    "&previewType=object" +
                                    "&objectId=" + iIdObj.customerId + "::" + iIdObj.id +
                                    "&galleryId=" + gIdObj.customerId + "::" + gIdObj.id +
                                    "&width=" + width +
                                    "&height=" + height);
                            }
                        }

                    }
                    if (IG.config.prodMode) {
                        callback("https://webgalleri.infogalleri.net/viewelement/" + gIdObj.customerId + "/" + gIdObj.id  + "/" + iIdObj.customerId + "/" + iIdObj.id + "/");
                    }
                    else {
                        callback("http://webgalleri.infogalleri.dev/viewelement/" + gIdObj.customerId + "/" + gIdObj.id  + "/" + iIdObj.customerId + "/" + iIdObj.id + "/");
                    }
                } else {
                    callback(null);
                }
            });
        }
    },
    rss: function(idObj) {
        return `${IG.customerConfig.feedsRootUrl}index.php?customerId=${idObj.customerId}&id=${idObj.id}&mode=channel`;
    },
    editor: {
        install: "/flash/playerProductInstall.swf",
        flash: "/flash/RediaEditor.swf"
    },
    player: {
        flash: "/flash/player.swf"
    }
};


/***************************************/
/**** Date, time, calendar settings ****/

/* Date and time formats (PHP style - for details, check js/date.js) */
IG.dateFormat = {
    "da": "d-m-Y",
    "de": "d-m-Y",
    "en": "m/d/Y"
};

IG.dateTimeFormat = {
    "da": "d-m-Y H:i",
    "de": "d-m-Y H:i",
    "en": "m/d-Y H:i"
};


/* JQuery supported date formats 
 * See: http://docs.jquery.com/UI/Datepicker/formatDate */
IG.dateFormatJQuery = {
    "da": "d-m-yy",
    "de": "d-m-yy",
    "en": "m/d/yy"
};

/* Currently no better way of findind the format */
IG.clockFormat = {
	"da": 24,
	"de": 24,
	"en": 12
};

/* Month and day names for calendar: */
//Jauary, February, ...
IG.monthNames = {
    "da": ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
    "de": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    "en": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
};
IG.monthNamesShort = {
    "da": ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
    "de": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    "en": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};

//Sunday, Monday, ...
IG.dayNames = {
    "da": ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
    "de": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    "en": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
};
IG.dayNamesShort = {
    "da": ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
    "de": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    "en": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
};
IG.clockFormat = {
    "da": 24,
    "de": 24,
    "en": 12
};

/// Recurring language

IG.recurringMonthType = {
    "da": {day_in_week: "Dag i ugen", day_in_month: "Dag i måneden"},
    "de": {day_in_week: "Tag in der Woche", day_in_month: "Tag im Monat"},
    "en": {day_in_week: "Day of week", day_in_month: "Day of month"}
};

/*IG.recurringCountLabel = {
    "da": 'Antal gentagelser:',
    "de": 'Wiederholungszahl:',
    "en": "Repetition Count:"
};*/

IG.recurringFrequencyLabel = {
    "da": {day: "Dag", week: "Uge", month: "Måned", year: "År"},
    "de": {day: "Tag", week: "Woche", month: "Monat", year: "Jahr"},
    "en": {day: "Day", week: "Week", month: "Month", year: "Year"}
};

IG.recurringFrequencyPicker = {
    da: {none: "aldrig", daily: "dagligt", weekly: "ugentligt", monthly: "månedligt", yearly: "årligt"},
    de: {none: "nicht", daily: "täglich", weekly: "wöchentlich", monthly: "monatlich", yearly: "jährlich"},
    en: {none: "none", daily: "daily", weekly: "weekly", monthly: "monthly", yearly: "yearly"}
};

IG.recurringFrequencyPickerLabel = {
    da: "Gentag:",
    de: "Wiederholt sich:",
    en: "Repeated every:"
};

IG.recurringWeekdayPickerLabel = {
    da: "Gentages på:",
    de: "Wiederholt am:",
    en: "Repeats on:"
};

IG.recurringRecurrencePickerLabel = {
    da: 'Gentages hver:',
    de: 'Wiederholt jeden:',
    en: 'Repeated every:'
};

IG.recurringDescriber = {
    da: {
        none: ['Gentages ikke', 'Gentages ikke 2', 'Gentages ikke 3'],
        daily: ["Dagligt", "Hver anden dag", "Hver {num} dag"],
        weekly: ["Ugentligt", 'Hver anden uge', 'Hver {num} uge'],
        monthly: ["Månedligt", 'Hver anden måned', 'Hver {num} måned'],
        yearly: ["Årligt", 'Hvert andet år', 'Hvert {num} år'],
        count: ["{num} gang", '{num} gange'],
        byday: ["første {day}", "anden {day}", "tredje {day}", "fjerde {day}", "femte {day}"],
        bymonthday: ["Den {num}", "{num}"],
        until: ["Indtil den {date}"]
    },
    de: {
        daily: ["Täglich", "Jeden zweiten Tag", "Alle {num} Tage"],
        weekly: ["Wöchentlich", 'Jede zweiten Woche', 'Alle {num} Wochen'],
        monthly: ["Monatlich", 'Jeden zweiten Monat', 'Alle {num} Monate'],
        yearly: ["Jährlich", 'Jedes zweite Jahr', 'Alle {num} Jahre'],
        count: ["Einmal", '{num}-mal'],
        byday: ["ersten {day}", "zweiten {day}", "dritten {day}", "vierten {day}", "fünften {day}"],
        bymonthday: ['Die {num}', "{num}"],
        until: ['Bis {date}']
    },
    en: {
        daily: ["Daily", "Every other day", "Every {num} days"],
        weekly: ["Weekly", 'Every other week', 'Every {num} weeks'],
        monthly: ["Monthly", 'Every other month', 'Every {num} months'],
        yearly: ["Every year", 'Every other year', 'Every {num} years'],
        count: ["once", 'twice', '{num} times'],
        byday: ["first {day}", "second {day}", "third {day}", "fourth {day}", "fifth {day}"],
        bymonthday: ['The {num}', "{num}"],
        until: ["Until {date}"]
    }
};

/**** Date, time, calendar settings ****/
/***************************************/



IG.constants = {
    DOCUMENT_TYPES_ALL: 'doc-type-hubber-all-types-42' // Used as a doc type key for all documents. The number is to avoid conflicts, as document type names can't contain numbers
};


// Polyfill for IE<9 from https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce
// Production steps of ECMA-262, Edition 5, 15.4.4.21
// Reference: http://es5.github.io/#x15.4.4.21
if (!Array.prototype.reduce) {
    Array.prototype.reduce = function(callback /*, initialValue*/) {
        'use strict';
        if (this == null) {
            throw new TypeError('Array.prototype.reduce called on null or undefined');
        }
        if (typeof callback !== 'function') {
            throw new TypeError(callback + ' is not a function');
        }
        var t = Object(this), len = t.length >>> 0, k = 0, value;
        if (arguments.length == 2) {
            value = arguments[1];
        } else {
            while (k < len && ! k in t) {
                k++;
            }
            if (k >= len) {
                throw new TypeError('Reduce of empty array with no initial value');
            }
            value = t[k++];
        }
        for (; k < len; k++) {
            if (k in t) {
                value = callback(value, t[k], k, t);
            }
        }
        return value;
    };
}
