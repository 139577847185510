import React from 'react'
import { createRoot } from "react-dom/client";
import { UploadMedia } from './components/MediaArchive/upload';

IG.MediaArchive = function(options) {
    options = (typeof options != "undefined" ? options : {});
    var hasContext = ($.type(options.hasContext) == 'boolean' ? options.hasContext : false);
    var onload = ($.isFunction(options.onload) ? options.onload : function() { });
    var onMediaInsert = (typeof options.onMediaInsert == "function" ? options.onMediaInsert : function() { });
    var onMediaRemoved = (typeof options.onMediaRemoved == "function" ? options.onMediaRemoved : function() { });
    var onResized = (typeof options.onResized == "function" ? options.onResized : function() { });
    this.searchLang = (typeof options.elementsLang == "string" ? options.elementsLang : IG.customerConfig.defaultContentLanguage);
    this.editMediaLang = (typeof options.elementsLang == "string" ? options.elementsLang : IG.customerConfig.defaultContentLanguage);
    
    var _this = this;
    
    var mediaListLangSel;
    
    this.mediaLoaded = false;
    this.filter = {
        tags: [],
        owners: []
    };
    this.swfu = {};
    this.resizeSlale = 1;
    this.height = 600;
    this.dialog = null;

    this.fn = {
        //toggles filter tag for searching
        toggleFilterTag: function(t) {
            if ($.inArray(t, _this.filter.tags) > -1) {
                _this.filter.tags = $.grep(_this.filter.tags, function(n, i) { return n != t });
            } else {
                _this.filter.tags.push(t);
            }
        }
    };

    this.getMediaTagsString = function(media) {
        var string = "";
        $.each(media.tags, function(i, t) {
            if (string == "") {
                string += t;
            } else {
                string += "," + t;
            }
        });
        return string;
    };

    this.showUploadProgress = function() {
        $("#media-upload-progress").show();
    };

    this.hideUploadProgress = function() {
        $("#media-upload-progress").hide();
    };

    /*Some of swf upload handlers, they are not implemented yet*/
    this.uploadSuccess = function(file, serverData) {
        var result = JSON.parse(serverData);
        if (result && result.result) {
            var dataObj = result.data[0];
            if (!dataObj || !dataObj.result) {
                $("#" + file.id).find('.progress-span').addClass('progress-span-error').text("-").width("100%");
                IG.showErrorDialog(dataObj.message ? dataObj.message : IG.label.get('unknown_error_popup_message'));
            } else {
                _this.IGMediaList.appendRow(result.data[0].data, true);
                //console.log(result);
                $("#" + file.id).find('.progress-span').text("100%").width("100%");
            }
        }
    };
    this.uploadStart = function(file) {
        // console.log('uploadStart');
    };
    this.fileQueued = function(file) {
        _this.showUploadProgress();
        var fileTr = document.createElement("tr");
        $(fileTr)
            .attr('id', file.id)
            .appendTo("#media-upload-files");
        var fileNameTd = document.createElement("td");
        $(fileNameTd)
            .css("overflow", "hidden")
            .appendTo(fileTr);
        var fileNameSpan = document.createElement("p");
        $(fileNameSpan)
            .addClass("name")
            .html(file.name)
            .attr("title", file.name)
            .appendTo(fileNameTd);

        var fileSizeTd = document.createElement("td");
        $(fileSizeTd)
            .html(Math.ceil(file.size / 1000) + " KB")
            .appendTo(fileTr);
        var fileProgressTd = document.createElement("td");
        $(fileProgressTd)
            .addClass("progress-td")
            .appendTo(fileTr);
        var fileProgressSpan = document.createElement("span");
        $(fileProgressSpan)
            .html("0%")
            .width(0)
            .addClass("progress-span")
            .appendTo(fileProgressTd);

        // console.log('fileQueued: ' + file.name);
    };
    this.fileQueueError = function(file, errorCode, message) {
        alert(errorCode + " " + message);
        // console.log('fileQueueError');
    };
    this.uploadError = function(file, errorCode, message) {
        alert(errorCode + " " + message);
        // console.log('uploadError' + " error code: " + errorCode + " message: " + message);
    };
    this.fileDialogComplete = function(numFilesSelected, numFilesQueued) {
        // console.log('fileDialogComplete');
        _this.swfu.startUpload();
    };
    this.uploadProgress = function(file, bytesLoaded, bytesTotal) {
        console.log(file, bytesLoaded, bytesTotal)
        var percent = Math.ceil((bytesLoaded / bytesTotal) * 100);
        if (percent == 100) {
            percent = 99;
        }
        $("#media-upload-files tr[id='" + file.id + "'] span")
            .html(percent + "%")
            .width(percent + "%");
        // console.log('uploadProgress ' + file.name + ", bytesLoaded: " + bytesLoaded + " bytesTotal:" + bytesLoaded);
    };
    this.uploadComplete = function(file, errorCode, message) {
        // console.log('uploadComplete: ' + file.name);
        this.startUpload();
    };
    this.queueComplete = function(numFilesUploaded) {
        // console.log(numFilesUploaded);
    };

    this.show = function(mediaToEdit) {

        _this.dialog = $( "#modal-window" )
            .html(_this.render())
            .dialog({
                modal: true,
                title: IG.label.get("media_main_title"),
                position: { my: "center", at: "center", of: window, collision: "fit" },
                width: 1020,
                height: 'auto',
                buttons: null,
                open: function( event, ui ) {
                    if (!_this.mediaLoaded) {
                        _this.IGMediaList.init(function() {
                            _this.mediaLoaded = true;
                            _this.registerFlashUpload();
                            onload();
                            _this.IGMediaList.refresh();
                            if (typeof mediaToEdit !== 'undefined' && mediaToEdit !== null) {
                                _this.initEditMedia(mediaToEdit);
                            }
                        });
                    } else {
                        options.onload();
                        _this.registerFlashUpload();
                        _this.IGMediaList.refresh();
                        if (typeof mediaToEdit !== 'undefined' && mediaToEdit !== null) {
                            _this.initEditMedia(mediaToEdit);
                        }
                    }
                },
                resize: function( event, ui ) {
                    $("#medialist-wrap")
                        .height($("#modal-window").height() - (150 + $("#media-tags").height()));
                    _this.IGMediaList.resize();
                    _this.height = $("#medialist-wrap").height();
                    onResized();
                },
                close: function(event, ui) {
                    $(".interspunkt").remove();
                },
                beforeClose: function( event, ui ) {
                    //var destroyed = _this.swfu.destroy();
                },

                // reset to defaults
                minWidth: 150,
                maxWidth: false,
                minHeight: 150,
                maxHeight: false
            });
    };

    this.registerFlashUpload = function() {
        var getPhpSessId = function() {
            var i, x, y, ARRcookies = document.cookie.split(";");
            for (i = 0; i < ARRcookies.length; i++) {
                x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
                y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
                x = x.replace(/^\s+|\s+$/g, "");
                if (x == "PHPSESSID") {
                    return unescape(y);
                }
            }
            return '';
        };

        try {
            const uploadContainer = document.querySelector("#ig-button-upload");
            const uploadRoot = createRoot(uploadContainer);
            uploadRoot.render(<UploadMedia onSuccess={_this.uploadSuccess} onStart={_this.fileQueued} onProgress={_this.uploadProgress} onError={_this.uploadError}/>);
            
            // _this.swfu = new SWFUpload({
            //     upload_url: "/upload.php?PHPSESSID=" + getPhpSessId(),
            //     flash_url: "flash/swfupload.swf",
            //     file_size_limit: "1024 MB",
            //     button_placeholder_id: "ig-button-upload",
            //     button_image_url: "images/btn-upload.png",
            //     button_cursor: -2,
            //     button_width: 160,
            //     button_height: 30,
            //     //button_text: 'Upload',
            //     button_window_mode: SWFUpload.WINDOW_MODE.OPAQUE,
            //     file_types_description: 'Media files',
            //     file_upload_limit: 0,
            //     file_queue_limit: 0,
            //     file_types: "*.mp4;*.jpg;*.gif;*.swf;*.png;*.mov;*.avi;*.wmv;*.mkv;*.mp3;*.flv;*.f4v;*.jpeg;*.pdf;*.mid;*.zip",

            //     upload_success_handler: _this.uploadSuccess,
            //     upload_start_handler: _this.uploadStart,
            //     file_queued_handler: _this.fileQueued,
            //     file_queue_error_handler: _this.fileQueueError,
            //     upload_error_handler: _this.uploadError,
            //     file_dialog_complete_handler: _this.fileDialogComplete,

            //     upload_progress_handler: _this.uploadProgress,
            //     upload_complete_handler: _this.uploadComplete,
            //     queue_complete_handler: _this.queueComplete,


            //     file_post_name: 'file',
            //     post_params: {
            //         PHPSESSID: getPhpSessId()
            //     }
            // });

        } catch(ex) {
            throw ex
        }

    };

    this.render = function() {

        /*var mediaArchive = document.createElement('div');
        ReactDOM.render(
            <MediaArchive></MediaArchive>,
            mediaArchive
        );
*/

        var mediaList = document.createElement("div");
        $(mediaList)
            .attr("id", "media-list");

        //upload progress
        $(mediaList)
            .append(_this.renderUploadProgress());


        var mediaListInner = document.createElement("div");
        $(mediaListInner)
            .attr("id", "media-list-inner")
            .appendTo(mediaList);

        var mediaToolsWrap = document.createElement("div");
        $(mediaToolsWrap)
            .addClass('form-wrap')
            .attr({ id: "medialist-tools-wrap" });



        // form row 1
        var objRow1 = document.createElement('div');
        $(objRow1)
            .addClass('media-tool-row')
            .appendTo(mediaToolsWrap);

        // form row 2
        var objRow2 = document.createElement('div');
        $(objRow2)
            .addClass('media-tool-row')
            .appendTo(mediaToolsWrap);

        // form row 3
        var objRow3 = document.createElement('div');
        $(objRow3)
            .addClass('media-tool-row')
            .appendTo(mediaToolsWrap);


        var objToolLblSearch = document.createElement('span');
        $(objToolLblSearch)
            .addClass('media-tool-lbl')
            .text(IG.label.get('MEDIA_SEARCH_MEDIA') + ':')
            .attr('title', IG.label.get('MEDIA_SEARCH_MEDIA'))
            .appendTo(objRow1);

        var searchImagesBtt = document.createElement("a");
        var objSrchInput = document.createElement("input");
        $(objSrchInput)
            .attr({
                "type": "text",
                "name": "searchimages-text",
                "id": "searchimages-text"
            })
            .addClass('input-text')
            .keyup(function(e) {
                var key = e.which ? e.which : e.keyCode;
                if (key == 13) {
                    $(searchImagesBtt).trigger("click");
                }
            })
            .appendTo(objRow1);

        mediaListLangSel = _this.renderSearchLanguageSelector({
            onChange: function(n) {
                _this.searchLang = n;
            },
            searchLang: _this.searchLang
        });
        if (mediaListLangSel) $(objRow1).append(mediaListLangSel);

        $(searchImagesBtt)
            .addClass('btn-normal btn-dark')
            .click(function() {
                var text = $(objSrchInput).val();
                _this.IGMediaList.setQuery(text, _this.searchLang);
                _this.IGMediaList.refresh();
            })
            .appendTo(objRow1);
        var searchImagesSpan = document.createElement("span");
        $(searchImagesSpan).text(IG.label.get("global_search"));
        $(searchImagesSpan).addClass('ig-button-span');
        $(searchImagesBtt).append(searchImagesSpan);


        // Create search filter - show all / show mine
        var objToolFilterWrap = document.createElement('div');
        $(objToolFilterWrap)
            .addClass('filter-options')
            .appendTo(objRow1);

        var objToolFilterAll = document.createElement('a');
        $(objToolFilterAll)
            .text(IG.label.get("media_all_filter"))
            .addClass('current')
            .click(function(e) {
                e.preventDefault();
                $(objToolFilterWrap).children('a').removeClass('current');
                $(this).addClass('current');
                _this.IGMediaList.removeFilter('owners');
                _this.IGMediaList.refresh();
            })
            .appendTo(objToolFilterWrap);

        var objToolFilterPipe = document.createElement('span');
        $(objToolFilterPipe)
            .addClass('list-pipe')
            .text('|')
            .appendTo(objToolFilterWrap);

        var objToolFilterMine = document.createElement('a');
        $(objToolFilterMine)
            .text(IG.label.get("media_only_mine_filter"))
            .click(function(e) {
                e.preventDefault();
                $(objToolFilterWrap).children('a').removeClass('current');
                $(this).addClass('current');

                _this.filter.owners = [IG.userConfig.userId];
                _this.IGMediaList.setFilter('owners', _this.filter.owners);

                _this.IGMediaList.refresh();
            })
            .appendTo(objToolFilterWrap);


        var objToolLblImport = document.createElement('span');
        $(objToolLblImport)
            .addClass('media-tool-lbl')
            .text(IG.label.get('MEDIA_IMPORT_MEDIA') + ':')
            .attr('title', IG.label.get('MEDIA_IMPORT_MEDIA'))
            .appendTo(objRow3);

        var videoDiv = document.createElement("div");
        $(videoDiv)
            .addClass("video")
            .appendTo(objRow3);

        var embedImput = document.createElement("input");
        $(embedImput)
            .attr({
                "type": "text",
                "name": "embed-video-imput",
                "id": "embed-video-imput",
                "value": IG.label.get("media_video_upload_watermark")
            })
            .addClass('input-text')
            .click(function() {
                $(this).val('');
            })
            .appendTo(videoDiv);


        var attachVideo = document.createElement("a");
        $(attachVideo)
                    .addClass('btn-normal btn-dark')
                    .click(function() {
                        var url = $(embedImput).val();
                        if (url != "" && url != IG.label.get("media_video_upload_watermark")) {
                            IG.gui.loading.show();
                            IG.request({
                                method: "importMedia",
                                params: [_this.searchLang, url, true], // IG.config.language
                                post: true,
                                success: function(object) {
                                    IG.gui.loading.hide();
                                    var obj = object.data;
                                    if (obj) {
                                        _this.IGMediaList.appendRow(obj, true);
                                    }
                                },
                                error: function(err) {
                                    IG.gui.loading.hide();
                                    IG.showErrorDialog(err);
                                }
                            });
                        } else {
                            var objDialog = IG.createOKDialog({
                                title: "",
                                text: IG.label.get('infoobject_rss_invalid_url')
                            });
                        }
                    });

        var attachVideoSpan = document.createElement("span");
        $(attachVideoSpan).text(IG.label.get("edit_infoobject_add_video"));
        $(attachVideoSpan).addClass('ig-button-span');
        $(attachVideo).append(attachVideoSpan);
        $(attachVideo).appendTo(videoDiv);


        var objToolLblUpload = document.createElement('span');
        $(objToolLblUpload)
            .addClass('media-tool-lbl')
            .text(IG.label.get('MEDIA_UPLOAD_MEDIA') + ':')
            .attr('title', IG.label.get('MEDIA_UPLOAD_MEDIA'))
            .appendTo(objRow2);

        var UploadImagesBtt = document.createElement("div");
        $(UploadImagesBtt)
            .attr('id', 'ig-button-upload')
            .appendTo(objRow2);


        $(mediaListInner).append(mediaToolsWrap);

        //now load tags
        var mediaTagsDiv = document.createElement("div");
        $(mediaTagsDiv)
            .attr("id", "media-tags")
            .appendTo(mediaListInner);

        var mediaTagsDivInner = document.createElement("div");
        $(mediaTagsDivInner)
            .attr("id", "media-tags-inner")
            .appendTo(mediaTagsDiv);

        $.each(IG.lists.tags, function(i, t) {
            var a = document.createElement("a");
            $(a)
                .addClass("media-tag-button")
                .text(i)
                .click(function() {
                    $(this).toggleClass("media-tag-button-clicked");
                    _this.fn.toggleFilterTag(i);
                    _this.IGMediaList.options.filters = _this.filter;
                    _this.IGMediaList.refresh();
                })
                .appendTo(mediaTagsDivInner);
        });

        var mediaListWrap = document.createElement("div");
        $(mediaListWrap).attr({ id: "medialist-wrap" });


        $(mediaListInner).append(mediaListWrap);

        _this.IGMediaList = new IG.MediaObjectList({
            methodName: "searchMedia",
            searchLang: _this.searchLang,
            adjustHeight: false
        });
        _this.IGMediaList.config.parentObject = mediaListWrap;
        _this.IGMediaList.config.columns = [{
        	name: "headline",
        	title: "",
        	className: "title ellipsis",
        	sortable: true,
        	width: "auto",
        	renderTitle: function(object) {
        		var s = "...";
        		if (typeof object.headline != "undefined") {
        			s = IG.fn.stripHTML(object.headline[_this.searchLang])
        		}
        		return s;
        	},
        	render: function(object) {
        		var objA = _this.renderMediaItem(object);
        		return objA;
        	}
        }];


        return $(mediaList);
    };

    // Build language selector
    this.renderSearchLanguageSelector = function(options) {
        if (!options.searchLang || typeof options.searchLang != "string") options.searchLang = IG.customerConfig.defaultContentLanguage;
        if (IG.fn.objSize(IG.customerConfig.contentLanguages) > 1) {


            var objLangSel = document.createElement("a");
            $(objLangSel)
                .addClass("hubber-select btn-icon btn-icon-right")
                .attr('href', '#')
                .attr('onclick', 'return false');

            var objLangSpan = document.createElement("span");
            $(objLangSpan)
                .text(IG.customerConfig.contentLanguages[options.searchLang])
                .addClass('btn-text')
                .appendTo(objLangSel);

            var languagesTip = document.createElement("div");
            $(languagesTip).addClass('language-selector-list');

            $.each(IG.customerConfig.contentLanguages, function(lang_key, lang_name) {
                var langObj = document.createElement("a");
                $(langObj)
                    .addClass(lang_key)
                    .attr('data-lang', lang_key)
                    .html(lang_name)
                    .click(function() {
                        $(objLangSpan).text(lang_name);
                        $(objLangSel).qtip('hide');
                        if (options.onChange && $.isFunction(options.onChange)) options.onChange(lang_key);
                    })
                    .appendTo(languagesTip);
            });

            /*var languagesTip = document.createElement("div");
            $(languagesTip).addClass('language-selector-list');

            $.each(IG.customerConfig.contentLanguages, function(i, n) {

                var langObj = document.createElement("a");
                $(langObj)
                    .addClass(i)
                    .html(n)
                    .click(function() {
                        $(".language-selector").html(n).qtip("hide");
                        if (options.onChange && $.isFunction(options.onChange)) options.onChange(i);

                    })
                    .appendTo(languagesTip);


            });

            $(languagesTip).find('a').first().addClass('first-qtip-list-ele');
            $(languagesTip).find('a').last().addClass('last-qtip-list-ele');*/

            //Attach qtip to type selector:
            $(objLangSel).qtip({
                content: {
                    text: $(languagesTip)
                },
                show: {
                    delay: 50,
                    event: 'click'
                },
                hide: { event: 'unfocus'},
                position: {
                    my: 'top center',
                    at: 'bottom center',
                    adjust: {
                        y:  2
                    }
                },
                style: {
                    classes: 'qtip-select',
                    width: '160px',
                    def: false,
                    background: 'none',
                    tip: {
                        width: 10,
                        height: 6
                    }
                }
            });

            if (options.searchLang != IG.customerConfig.defaultContentLanguage) {
                $(objLangSel).addClass('search-lang-deco');
            }

            return $(objLangSel);
        }
        return null;
    };

    this.renderUploadProgress = function() {
        //render upload progress
        var uploadProgressDiv = document.createElement("div");
        $(uploadProgressDiv)
            .attr('id', 'media-upload-progress');

        //close image
        var uploadProgressClose = document.createElement("img");
        $(uploadProgressClose).attr({
            "src": "images/close.png",
            "alt": "(x)",
            "width": 10,
            "height": 11,
            "class": 'close-upload-progress'
        })
            .click(function() {
                _this.hideUploadProgress();
            })
            .appendTo(uploadProgressDiv);

        var upload = "<div style='margin-right: 19px;'>";
        upload += "<table>";
        upload += "<colgroup>";
        upload += "<col />";
        upload += "<col style='width: 60px;' />";
        upload += "<col style='width: 50px;' />";
        upload += "</colgroup>";
        upload += "<thead>";
        upload += "<tr>";
        upload += "<th>";
        upload += IG.label.get("media_title");
        upload += "</th>";
        upload += "<th>";
        upload += IG.label.get("media_size");
        upload += "</th>";
        upload += "<th>";
        upload += IG.label.get("media_status");
        upload += "</th>";
        upload += "</tr>";
        upload += "</thead>";
        upload += "</table>";
        upload += "</div>";

        upload += "<div class='upload-files'>";
        upload += "<table>";
        upload += "<colgroup>";
        upload += "<col />";
        upload += "<col style='width: 60px;' />";
        upload += "<col style='width: 50px;' />";
        upload += "</colgroup>";
        upload += "<tbody id='media-upload-files'>";

        upload += "</tbody>";
        upload += "</table>";
        upload += "</div>";
        $(uploadProgressDiv)
            .append(upload);

        return $(uploadProgressDiv);
    };

    this.renderEditMedia = function(media) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "edit-media")
            .addClass('ig-edit-section');

        var objEditMediaHeader = document.createElement('h4');
        $(objEditMediaHeader)
            .text(IG.label.get('media_edit'))
            .appendTo(objDiv);

        var objDivInner = document.createElement("div");
        $(objDivInner)
            .attr("id", "edit-media-inner")
            .appendTo(objDiv);


        var objEditMedColLeft = document.createElement("div");
        $(objEditMedColLeft)
            .addClass("edit-media-col-left")
            .appendTo(objDivInner);

        var mediaContainer = document.createElement("div");
        $(mediaContainer)
                .addClass('media-container')
                .appendTo(objEditMedColLeft);
        var imgContainer = document.createElement("div");
        $(imgContainer)
            .addClass('img-container')
            .appendTo(mediaContainer);

        var type = media.type;
        //        if (type == "image") {
        var imgSrc = document.createElement("img");
        var imgLabel = null;
        var imgUrl = IG.fn.getMediaThumbnailUrl(media, function(label) {
            imgLabel = label;
        });
        $(imgSrc)
            .attr({
                'src': imgUrl,
                'title': imgLabel
            })
            .appendTo(imgContainer);



        var btnCancel = document.createElement("a");
        $(btnCancel)
            .addClass("btn-normal btn-dark btn-full-width")
            .html('<span class="ig-button-span">' + IG.label.get("cancel") + '</span>')
            .click(function() {
                var mediaListDiv = $("#media-list-inner");
                $(mediaListDiv).slideDown();
                $(objDiv).slideUp(400, function() { $(this).remove(); });
            })
            .appendTo(objEditMedColLeft);

        var btnSave = document.createElement("a");
        $(btnSave)
            .addClass("btn-normal btn-dark btn-full-width")
            .css('marginBottom', '40px')
            .html('<span class="ig-button-span">' + IG.label.get("global_save") + '</span>')
            .click(function() {
                $(objDiv).addClass("edit-media-loading");
                IG.update.media({
                    media: media,
                    success: function(data) {
                        $(objDiv).removeClass("edit-media-loading");
                        var mediaListDiv = $("#media-list-inner");
                        $(mediaListDiv).slideDown();
                        $(objDiv).slideUp(400, function() { $(this).remove(); });
                        _this.IGMediaList.refresh();
                        _this.refreshTagButtons();
                    },
                    error: function(err) {
                        $(objDiv).removeClass("edit-media-loading");
                        IG.showErrorDialog(err);
                    }
                });

                $(mediaListLangSel).text(_this.searchLang);
                return false;
            })
            .appendTo(objEditMedColLeft);


        // Button: open interest points
        var btnInterestPnts = document.createElement('a');
        $(btnInterestPnts)
            .addClass('btn-normal btn-light btn-full-width')
            .html('<span class="ig-button-span">' + IG.label.get("media_interspunkt") + '</span>')
            .click(function(event) {
                event.preventDefault();
                _this.showInterspunkt(media);
                return false;
            })
            .appendTo(objEditMedColLeft);


        // button: delete media
        var btnDelete = document.createElement('a');
        $(btnDelete)
            .addClass('btn-normal btn-pink btn-full-width')
            .html('<span class="ig-button-span">' + IG.label.get("media_delete") + '</span>')
            .click(function(event) {
                event.preventDefault();
                _this.deleteMedia({
                    media: media,
                    target: $(this),
                    callback: function() {
                        var mediaListDiv = $("#media-list-inner");
                        $(mediaListDiv).slideDown();
                        $(objDiv).slideUp(400, function() { $(this).remove(); });
                    }
                });
                return false;
            })
            .appendTo(objEditMedColLeft);





        // Render right column


        var objEditMedColRight = document.createElement("div");
        $(objEditMedColRight)
            .addClass('edit-media-col-right')
            .appendTo(objDivInner);

        //render buttons
        var buttonsDiv = document.createElement("div");
        $(buttonsDiv)
                .addClass("buttons")
                .appendTo(objEditMedColRight);

        var mediaObjLangSel = _this.renderSearchLanguageSelector({
            onChange: function(n) {
                _this.editMediaLang = n;
                $("#edit-media").remove();

                var editDiv = _this.renderEditMedia(media);
                var mediaListDiv = $("#media-list-inner");
                $(editDiv).appendTo($(mediaListDiv).parent());
            },
            searchLang: _this.editMediaLang
        });
        if (mediaObjLangSel) $(buttonsDiv).append(mediaObjLangSel);



        //render name
        var nameSection = document.createElement("div");
        $(nameSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var nameTitle = document.createElement("div");
        $(nameTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_created_by"))
                    .appendTo(nameSection);
        var nameValue = document.createElement("div");
        var user = $.grep(IG.lists.users, function(n, i) {
            return n.id.id == media.ownerId.id;
        });
        $(nameValue)
                .addClass("edit-value")
                .html(typeof user[0] !== 'undefined' ? user[0].name : '<em>'+IG.label.get('GLOBAL_MISSING_EDITOR_NAME')+'</em>')
                .appendTo(nameSection);


        //render media ID
        var idSection = document.createElement("div");
        $(idSection)
            .addClass("edit-section")
            .appendTo(objEditMedColRight);
        var idTitle = document.createElement("div");
        $(idTitle)
            .addClass("edit-title")
            .text(IG.label.get("MEDIA_ID") + ':')
            .appendTo(idSection);
        var idValue = document.createElement("div");
        $(idValue)
            .addClass("edit-value")
            .html('<em>'+media.id.id+'</em>')
            .appendTo(idSection);


        //render title
        var titleSection = document.createElement("div");
        $(titleSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var titleTitle = document.createElement("div");
        $(titleTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_title"))
                    .appendTo(titleSection);
        var titleValue = document.createElement("div");
        $(titleValue)
                .addClass("edit-value")
                .appendTo(titleSection);
        var inputTitle = document.createElement("input");
        $(inputTitle)
            .attr({
                "id": "input-title",
                "type": "text"
            })
            .addClass('input-text')
            .val(media.name[_this.editMediaLang])
            .change(function() {
                media.name[_this.editMediaLang] = $(this).val();
            })
            .appendTo(titleValue);

        //render artist
        /* var artistSection = document.createElement("div");
        $(artistSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var artistTitle = document.createElement("div");
        $(artistTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_artist"))
                    .appendTo(artistSection);
        var artistValue = document.createElement("div");
        $(artistValue)
                .addClass("edit-value")
                .appendTo(artistSection);
        var artistInput = document.createElement("input");
        $(artistInput)
                .attr({
                    "id": "input-artist",
                    "type": "text"
                })
                .val(media.artist[_this.editMediaLang])
                .change(function() {
                	media.artist[_this.editMediaLang] = $(this).val();
                })
                .appendTo(artistValue); */
		
        //render year
        var yearSection = document.createElement("div");
        $(yearSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var yearTitle = document.createElement("div");
        $(yearTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_year"))
                    .appendTo(yearSection);
        var yearValue = document.createElement("div");
        $(yearValue)
                .addClass("edit-value")
                .appendTo(yearSection);
        var yearInput = document.createElement("input");
        $(yearInput)
            .attr({
                "id": "input-year",
                "type": "text"
            })
            .val(media.year)
            .change(function() {
                media.year = $(this).val();
            })
            .addClass('input-text')
            .appendTo(yearValue);

        //render in the picture
        var inThePicSection = document.createElement("div");
        $(inThePicSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var inThePicTitle = document.createElement("div");
        $(inThePicTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_in_the_picture"))
                    .appendTo(inThePicSection);
        var inThePicValue = document.createElement("div");
        $(inThePicValue)
                .addClass("edit-value")
                .appendTo(inThePicSection);
        var inThePicInput = document.createElement("textarea");
        $(inThePicInput)
            .addClass('input-text-area')
                .attr({
                    "id": "input-inthepicture",
                    "cols": 42,
                    "rows": 8
                })
                .val(media.inThePicture[_this.editMediaLang])
                .change(function() {
                    media.inThePicture[_this.editMediaLang] = $(this).val();
                })
                .appendTo(inThePicValue);

        //render description
        var descriptionSection = document.createElement("div");
        $(descriptionSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var descriptionTitle = document.createElement("div");
        $(descriptionTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_description"))
                    .appendTo(descriptionSection);
        var descriptionValue = document.createElement("div");
        $(descriptionValue)
                .addClass("edit-value")
                .appendTo(descriptionSection);
        var descriptionInput = document.createElement("textarea");
        $(descriptionInput)
            .addClass('input-text-area')
                .attr({
                    "id": "input-inthepicture",
                    "cols": 42,
                    "rows": 8
                })
                .val(media.description[_this.editMediaLang])
                .change(function() {
                    media.description[_this.editMediaLang] = $(this).val();
                })
                .appendTo(descriptionValue);

        //render source
        var sourceSection = document.createElement("div");
        $(sourceSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var sourceTitle = document.createElement("div");
        $(sourceTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_source"))
                    .appendTo(sourceSection);
        var sourceValue = document.createElement("div");
        $(sourceValue)
                .addClass("edit-value")
                .appendTo(sourceSection);
        var sourceInput = document.createElement("input");
        $(sourceInput)
            .attr({
                "id": "input-source",
                "type": "text"
            })
            .val(media.source[_this.editMediaLang])
            .change(function() {
                media.source[_this.editMediaLang] = $(this).val();
            })
            .addClass('input-text')
            .appendTo(sourceValue);


        //render tags
        var tagsSection = document.createElement("div");
        $(tagsSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var tagsTitle = document.createElement("div");
        $(tagsTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_tags"))
                    .appendTo(tagsSection);
        var tagsValue = document.createElement("div");
        $(tagsValue)
                .addClass("edit-value")
                .appendTo(tagsSection);

        var ul = document.createElement("ul");
        $(ul)
                .addClass("ul-tags")
                .appendTo(tagsValue);

        var tagInput = document.createElement("input");

        var fnAddRemoveTag = function(tag) {
            var added = false;
            $.each(media.tags, function(i, t) {
                if (t == tag) {
                    added = true;
                }
            });
            if (added) { //removeit
                media.tags = $.grep(media.tags, function(t, i) { return t != tag; });
            } else {
                media.tags.push(tag);
            }
        };

        var fnRenderTag = function(tagName, css) {
            var selected = false;
            $.each(media.tags, function(i, t) {
                if (t == tagName) {
                    selected = true;
                }
            });
            if (selected) {
                css = "selected " + css;
            }

            var li = document.createElement("li");
            $(li)
                    .addClass(css)
                    .appendTo(ul)
                    .click(function() {
                        fnAddRemoveTag(tagName);
                        $(tagInput).val(_this.getMediaTagsString(media));
                        $(this).toggleClass("selected");
                    });

            var a = document.createElement("a");
            $(a)
                    .text(tagName)
                    .appendTo(li);
        };

        $.each(IG.lists.tags, function(i, t) {
            fnRenderTag(i, "");
        });

        $(tagInput)
                .addClass("tag-value")
                .addClass('input-text')
                .attr({
                    "id": "input-tag",
                    "type": "text"
                })
                .val(_this.getMediaTagsString(media))
                .keyup(function(e) {
                    //build media tags again
                    media.tags = [];
                    $(ul).find("li").removeClass("selected");
                    $(ul).find("li.new").remove();

                    var allTags = $(this).val();
                    if (allTags == "") return false;

                    $.each(allTags.split(','), function(i, t) {
                        if (t == "") return;    //continue the loop
                        media.tags.push(t);

                        var inTags = false;
                        $.each(IG.lists.tags, function(i, gt) {
                            if (t == i) {
                                inTags = true;
                                return false; //break the loop
                            }
                        });
                        if (inTags) {
                            $.each($(ul).find("li a"), function(i, a) {
                                if ($(a).text() == t) {
                                    if (!$(a).parent().hasClass("selected")) {
                                        $(a).parent().addClass("selected");
                                    }
                                }
                            });
                        } else {
                            //first check if there is newly created tag
                            fnRenderTag(t, "new");
                        }
                    });
                })
                .appendTo(tagsValue);


        //render type
        var typeSection = document.createElement("div");
        $(typeSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var typeTitle = document.createElement("div");
        $(typeTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_type"))
                    .appendTo(typeSection);
        var typeValue = document.createElement("div");
        $(typeValue)
                .addClass("edit-value")
                .html(media.type)
                .appendTo(typeSection);

        //render dimensions
        var dimensionsSection = document.createElement("div");
        $(dimensionsSection)
                    .addClass("edit-section")
                    .appendTo(objEditMedColRight);
        var dimensionsTitle = document.createElement("div");
        $(dimensionsTitle)
                    .addClass("edit-title")
                    .html(IG.label.get("media_edit_dimensions"))
                    .appendTo(dimensionsSection);
        var dimensionsValue = document.createElement("div");
        $(dimensionsValue)
                .addClass("edit-value")
                .html(media.originalMediaFile.width + "X" + media.originalMediaFile.height)
                .appendTo(dimensionsSection);

        

        return objDiv;
    };
    
    this.refreshTagButtons = function() {
    	IG.request({
        	method: 'getTags',
		    params: [_this.searchLang, IG.config.customerId],
		    success: function(response) {
		        if (response.result) {
		            IG.lists.tags = response.data;
		            
		            // Re-render tag buttons:
		            var mediaTagsDivInner = $('#media-tags-inner');
		        	mediaTagsDivInner.html('');

		            $.each(IG.lists.tags, function(i, t) {
		                var a = document.createElement("a");
		                $(a)
		                    .addClass("media-tag-button")
		                    .text(i)
		                    .click(function() {
		                        $(this).toggleClass("media-tag-button-clicked");
		                        _this.fn.toggleFilterTag(i);
		                        _this.IGMediaList.options.filters = _this.filter;
		                        _this.IGMediaList.refresh();
		                    })
		                    .appendTo(mediaTagsDivInner);
		            });
		        }
		    },
		    error: function(err) {
		        IG.showErrorDialog(err);
		    }
        });
    };

    this.initEditMedia = function(media) {
        _this.hideUploadProgress();
        _this.editMediaLang = _this.searchLang;

        var editDiv = _this.renderEditMedia(media);
        var mediaListDiv = $("#media-list-inner");
        var height = $(mediaListDiv).height();

        $(mediaListDiv)
            .slideUp();
        $(editDiv)
            .hide()
            .appendTo($(mediaListDiv).parent())
            .css('height', parseInt(height)-2)
            .slideDown();
    };

    this.deleteMedia = function(options) {
        if (typeof options.media === 'undefined' || options.media === null) {
            IG.showErrorDialog('Missing media to delete.');
            return false;
        }
        options.target = (typeof options.target !== 'undefined' ? options.target : window);
        options.callback = ($.isFunction(options.callback) ? options.callback : function(){});

        //check how many times this media item is referenced in infoobjects
        //and show appropriate message
        var loadingId = "loading-" + options.media.id.id;
        IG.gui.loading.show(undefined, loadingId, 1);
        IG.request({
            method: "getMediaReferencingInfoObjectIds",
            params: [_this.searchLang, options.media.id], // IG.config.language
            success: function(response) {
                IG.gui.loading.hide(loadingId);
                if (response.result) {
                    //Create new modal dialog and show it:
                    var text = IG.label.get("media_delete_popup_message_name").replace("{media_name}", " \"" + options.media.name[_this.searchLang] + "\"");
                    if (response.data.length > 1) {
                        text = IG.label.get("media_delete_popup_message_referencedby")
                            .replace("{media_name}", " \"" + options.media.name[_this.searchLang] + "\"")
                            .replace("{reference_count}", (response.data.length-1));
                    }


                    var objDialog = IG.createConfirmDialog({
                        title: IG.label.get("media_delete_popup_title"),
                        text: text,
                        onConfirm: function() {
                            IG.remove.media({
                                id: options.media.id,
                                success: function(data) {
                                    _this.IGMediaList.removeRow(options.media.id);
                                    onMediaRemoved(options.media);
                                    objDialog.close();
                                    options.callback();
                                },
                                error: function(err) {
                                    objDialog.close();
                                    IG.showErrorDialog(err);
                                }
                            });
                        },
                        target: options.target
                    });
                }
            },
            error: function(err) {
                IG.gui.loading.hide();
                IG.showErrorDialog(err);
            }
        });
        return true;
    };

    this.renderMediaItem = function(media) {
        var mediaContainer = document.createElement("div");
        $(mediaContainer).addClass('media-container');
        var imgContainer = document.createElement("div");
        $(imgContainer).addClass('img-container');

        var imgSrc = document.createElement("img");
        var imgLabel = null;
        var imgUrl = IG.fn.getMediaThumbnailUrl(media, function(label) {
            imgLabel = label;
        });
        $(imgSrc).attr({
            'src': imgUrl,
            'title': imgLabel
        });
        
        var $successCheckContainer = $('<div class="success-check"></div>');
        $(imgContainer).append($successCheckContainer);
        
        $(imgContainer).append(imgSrc);

        var btnContainer = document.createElement('div');
        $(btnContainer)
            .addClass('media-obj-buttons')
            .appendTo(imgContainer);

        var hideBtnContainer = false;

        $(imgContainer)
            .mouseover(function() {
                if (!hideBtnContainer)
                    $(btnContainer).show();
            })
            .mouseout(function() {
                $(btnContainer).hide();
            });


        if (hasContext) {
            var objSp = document.createElement("span");
            $(objSp).addClass('indaset')
                .text(IG.label.get("media_indaset"))
                .attr('title', IG.label.get("media_indaset"))
                .click(function() {
                    onMediaInsert(media);
                    $(btnContainer).fadeOut();
                    hideBtnContainer= true;
                    $successCheckContainer.fadeIn(200, function () {
                        setTimeout(function () {
                            $successCheckContainer.fadeOut();
                            hideBtnContainer = false;
                        }, 1000)
                    });
                });
            $(btnContainer).append(objSp);
        }

        var objSp = document.createElement("span");
        $(objSp)
            .addClass('rediger')
            .text(IG.label.get("media_edit"))
            .attr('title', IG.label.get("media_edit"))
            .click(function() {
                _this.initEditMedia(media);
            });
        $(btnContainer).append(objSp);

        var objSp = document.createElement("span");
        $(objSp)
            .addClass('slet')
            .text(IG.label.get("media_delete"))
            .attr('title', IG.label.get("media_delete"))
            .click(function() {
                _this.deleteMedia({
                    media: media,
                    target: $(this)
                });
            });
        $(btnContainer).append(objSp);

        if (media.type == "image") {
            var objSp = document.createElement("span");
            $(objSp)
                .addClass('interpunkter')
                .text(IG.label.get("media_interspunkt"))
                .click(function() {
                    _this.showInterspunkt(media);
                })
                .appendTo(btnContainer);
        }

      const downloadUrl = media.originalMediaFile.url + '&download=1';
      var objSp = $(`<a title="${IG.label.get("media_download_item")}" class="download" target="_blank" href=${downloadUrl}>${IG.label.get("media_download_item")}</a>`)
      $(btnContainer).append(objSp);


        $(mediaContainer).append(imgContainer);

        var imgTitle = document.createElement("p");
        $(imgTitle).text(media.name[_this.searchLang]);
        $(mediaContainer).append(imgTitle);

        return mediaContainer;
    };

    this.showUnsavedChangesDialog = function() {
        //Make sure to completely remove any previously created instances:
        var objDiv = $("#infoobjectchanged-div").get(0);
        if (objDiv) {
            $(objDiv).remove();
            $(document).off('keypress', enterListener);
        }

        //Then (re)create it:
        objDiv = document.createElement("div");
        $(objDiv)
                            .attr("id", "delete-div")
                            .addClass("delete");

        var objDivContent = document.createElement("div");
        $(objDivContent).addClass("content");

        var pContent = document.createElement("p");
        $(pContent)
            .text(IG.label.get("media_item_points_of_interests_changed"))
            .appendTo(objDiv);

        $(objDiv).append(objDivContent);

        return $(objDiv);
    };


    // #mark

    this.showInterspunkt = function(media) {
        var needsDialog = false;

        var objDiv = $("#interspunkt");
        if (objDiv != null) {
            $(objDiv).remove();
        }
        objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "interspunkt")
            .addClass("interspunkt")
            // .css("top", _this.dialog.parent().position().top) // TODO, Hubber update. Should I remove?
            .draggable({ handle: ".draggable" });


        var closeFunc = function() {
            //Create new modal dialog:
            if (needsDialog) {

                $( "#modal-window-dialog" )
                    .html(_this.showUnsavedChangesDialog())
                    .dialog({
                        modal: true,
                        title: '',
                        dialogClass: "no-close",
                        position: { my: "center", at: "center", of: window },
                        width: 300,
                        height: 'auto',
                        buttons: [
                            {
                                text: IG.label.get("ok"),
                                click: function() {
                                    $(objDiv).remove();
                                    if (IG.visibleDialogs['interspunkt']) delete IG.visibleDialogs['interspunkt'];
                                    $( this ).dialog( "close" );
                                }
                            },
                            {
                                text: IG.label.get("cancel"),
                                click: function() {
                                    $( "#modal-window-dialog" ).dialog( "close" );
                                }
                            }
                        ],

                        // reset to defaults
                        minWidth: 150,
                        maxWidth: false,
                        minHeight: 150,
                        maxHeight: false
                    });
            } else {
                $(objDiv).remove();
                if (IG.visibleDialogs['interspunkt']) delete IG.visibleDialogs['interspunkt'];
            }
        };



        var imgContainerDiv = document.createElement("div");
        $(imgContainerDiv)
            .addClass("interspunkt-image-container")
            .appendTo(objDiv);



        //calculate width and height and scale of the image
        var wHeight = $(window).height();
        var wWidth = $(window).width();
        var iWidth = media.originalMediaFile.width;
        var iHeight = media.originalMediaFile.height;
        var scale = 0;
        _this.resizeSlale = 1;
        if (iHeight > wHeight) {
            _this.resizeSlale = (wHeight - 200) / iHeight;

            //calculate new width
            var newWidth = iWidth * _this.resizeSlale;
            while (newWidth > wWidth - 100) {
                _this.resizeSlale -= 0.01;
                newWidth = iWidth * _this.resizeSlale;
            }
        } else if (iWidth > wWidth) {
            _this.resizeSlale = (wWidth - 200) / iWidth;
        }

        $(imgContainerDiv)
            .height(iHeight * _this.resizeSlale)
            .width(iWidth * _this.resizeSlale);

        var x1, x2, y1, y2;
        var resizing = false;

        //create and resize media in one click
        var imgMedia = document.createElement("img");
        imgMedia.ondragstart = function() { return false; };
        $(imgMedia)
            .attr("id", "img");

        var fnCreateNewRectangle = function(e) {
            needsDialog = true;
            $(".interspunkt-buttons").css("display", "none");
            var item = {
                id: IG.fn.getRand(),
                x: e.pageX - $(imgMedia).parent().offset().left,
                y: e.pageY - $(imgMedia).parent().offset().top,
                width: 10,
                height: 10,
                description: {}
            };
            var rectangleDiv = fnBuildRectangleDiv(item, $(imgContainerDiv));
            $(rectangleDiv)
                .appendTo($(imgMedia).parent())
                .attr("id", "newly-created")
                .css({
                    top: e.pageY - $(imgMedia).parent().offset().top,
                    left: e.pageX - $(imgMedia).parent().offset().left
                })
                .fadeIn();
        };
        var t = 0;
        var fnMouseMove = function(e) {
            var w = e.pageX - $(imgMedia).offset().left - x1;
            var h = e.pageY - $(imgMedia).offset().top - y1;
            if (resizing && $(imgMedia).ismouseover()) {
                t++;
                // if (t%20 == 0) console.log('move', w, h, x1, y1);
                var css = {};
                if (w<0) {
                    css.width= Math.abs(w);
                    css.left = 'auto';
                    css.right= $(imgMedia).width() - x1;
                } else {
                    css.width= w;
                    css.left = x1;
                    css.right= 'auto';
                }
                if (h<0) {
                    css.height= Math.abs(h);
                    css.top = 'auto';
                    css.bottom= $(imgMedia).height() - y1;
                } else {
                    css.height= h;
                    css.top = y1;
                    css.bottom= 'auto';
                }
                $("#newly-created").css(css);
            }
        };

        var fnMouseDown = function(e) {
            fnCreateNewRectangle(e);
            x1 = e.pageX - $(imgMedia).offset().left;
            y1 = e.pageY - $(imgMedia).parent().offset().top;
            resizing = true;
            $(imgMedia).bind("mousemove", function(event) {
                fnMouseMove(event);
            });
        };

        var fnMouseClick = function(e) {
            fnCreateNewRectangle(e);
        };

        var fnStopResizing = function() {
            resizing = false;
            $("#newly-created").removeAttr("id");
        };

        $(document).mouseup(function(e) {
            fnStopResizing();
        });


        $(imgMedia)
            .attr( "src", media.originalMediaFile['url'])
            .addClass("interspunkt-img")
            .mouseup(function(e) {
                fnStopResizing();
            })
            .mousedown(function(e) {
                fnMouseDown(e);
            })
            .appendTo(imgContainerDiv);

        var fnBuildRectangleDiv = function(item, containment) {
            var objDiv = document.createElement("div");
            $(objDiv)
                .addClass("rectangle")
                .width(item.width)
                .height(item.height)
                .css("top", item.y)
                .css("left", item.x)
                .append($('<span></span>', {'class': 'rectangle-border n'}))
                .append($('<span></span>', {'class': 'rectangle-border s'}))
                .append($('<span></span>', {'class': 'rectangle-border e'}))
                .append($('<span></span>', {'class': 'rectangle-border w'}))
                .mousemove(function(e) {
                    fnMouseMove(e);
                })
                .mouseup(function(e) {
                    fnStopResizing();
                })
                .draggable({ containment: $(containment) })
                .resizable({
                    containment: $(containment),
                    handles: 'ne,se,nw,sw,n,e,s,w'
                });

            var objId = document.createElement("input");
            $(objId)
                .attr({
                    "type": "hidden"
                })
                .val(item.id)
                .appendTo(objDiv);

            //delete button
            var deleteBtn = document.createElement("img");
            $(deleteBtn).attr({
                "src": "images/close.png",
                "alt": "(x)",
                "width": 15,
                "height": 16,
                "class": 'close-poll-stat-interspunkt'
            })
            .addClass("rectangle-delete")
            .click(function() {
                var before = media.regions.length;
                media.regions = $.grep(media.regions, function(r, i) { return r.id != $(objId).val() });
                var after = media.regions.length;
                if (before != after) {
                    IG.update.media({
                        media: media,
                        success: function(res) {

                        },
                        error: function(err) {
                            IG.showErrorDialog(err);
                        }
                    });
                }
                //delete element
                $(this).parent().remove();
            })
            .appendTo(objDiv);

            var objDivArea = document.createElement("div");
            $(objDivArea)
                .addClass("interspunkt-buttons")
                .css({
                    display: item.description[_this.searchLang] ? "block" : "none"
                })
                .appendTo(objDiv);

            $(objDiv)
                .click(function(e) {
                    //$(".interspunkt-buttons").css("display", "none");
                    var area = $(this).find(".interspunkt-buttons");
                    if (area.is(':visible') && e.target.tagName != "TEXTAREA" && !$(this).find(".interspunkt-buttons textarea").val())
                        area.hide();
                    else area.show();
                });


            var divTextArea = document.createElement("div");
            $(divTextArea)
                .appendTo(objDivArea);
            var textAreay = document.createElement("textarea");
            $(textAreay)
                .attr({
                    "id": "region-text",
                    "cols": 3,
                    "rows": 3,
                    "placeholder": IG.label.get("media_interspunkt_comment")
                })
                .val(item.description[_this.searchLang])
                .appendTo(divTextArea);

            return $(objDiv);
        };

        $.each(media.regions, function(i, r) {
            var regionCopy = $.extend({}, r);
            regionCopy.x = r.x * _this.resizeSlale;
            regionCopy.y = r.y * _this.resizeSlale;
            regionCopy.width = r.width * _this.resizeSlale;
            regionCopy.height = r.height * _this.resizeSlale;
            $(imgContainerDiv).append(fnBuildRectangleDiv(regionCopy, $(imgContainerDiv)));
        });
        IG.visibleDialogs['interspunkt'] = {
            time: new Date().getTime(),
            closeFunc: closeFunc
        };



        // NEW Hubber Style #mark

        $( "#modal-window-poi" )
            .html(objDiv)
            .dialog({
                modal: true,
                title: IG.label.get("media_interspunkter_title") + " " + media.name[_this.searchLang],
                dialogClass: "no-close",
                position: { my: "center", at: "center", of: window },
                width: 'auto',
                height: 'auto',
                buttons: [
                    {
                        text: IG.label.get("ok"),
                        click: function() {
                            media.regions = [];

                            $(".rectangle").each(function(index) {
                                var imgPosition = $(this).position();
                                var item = {};
                                item.description = {};

                                item.x = Math.round(imgPosition.left / _this.resizeSlale);
                                item.y = Math.round(imgPosition.top / _this.resizeSlale);
                                item.width = Math.round($(this).width() / _this.resizeSlale);
                                item.height = Math.round($(this).height() / _this.resizeSlale);
                                item.description[_this.searchLang] = $($(this).find("#region-text").get(0)).val();

                                media.regions.push(item);
                            });

                            if (media.regions.length > 0) {
                                IG.gui.loading.show();
                                IG.update.media({
                                    media: media,
                                    success: function(res) {
                                        needsDialog = false;
                                        $(objDiv).remove();
                                        IG.gui.loading.hide();
                                    },
                                    error: function(err) {
                                        IG.gui.loading.hide();
                                        $(objDiv).remove();
                                        IG.showErrorDialog(err);
                                    }
                                });
                            }
                            $(".interspunkt-buttons").css("display", "none");
                            if (IG.visibleDialogs['interspunkt']) delete IG.visibleDialogs['interspunkt'];
                            $( this ).dialog( "close" );
                        }
                    },
                    {
                        text: IG.label.get("cancel"),
                        click: function(event) {
                            event.preventDefault();

                            //Create new modal dialog:
                            if (needsDialog) {
                                $( "#modal-window-dialog" )
                                    .html(_this.showUnsavedChangesDialog())
                                    .dialog({
                                        modal: true,
                                        title: '',
                                        dialogClass: "no-close",
                                        position: { my: "center", at: "center", of: window },
                                        width: 300,
                                        height: 'auto',
                                        buttons: [
                                            {
                                                text: IG.label.get("ok"),
                                                click: function() {
                                                    $(objDiv).remove();
                                                    if (IG.visibleDialogs['interspunkt']) delete IG.visibleDialogs['interspunkt'];
                                                    $( "#modal-window-dialog" ).dialog( "close" );
                                                    $( "#modal-window-poi" ).dialog( "close" );
                                                }
                                            },
                                            {
                                                text: IG.label.get("cancel"),
                                                click: function() {
                                                    $( "#modal-window-dialog" ).dialog( "close" );
                                                }
                                            }
                                        ],

                                        // reset to defaults
                                        minWidth: 150,
                                        maxWidth: false,
                                        minHeight: 150,
                                        maxHeight: false
                                    });
                            } else {
                                $(objDiv).remove();
                                if (IG.visibleDialogs['interspunkt']) delete IG.visibleDialogs['interspunkt'];
                                $( "#modal-window-poi" ).dialog( "close" );
                            }
                        }
                    }
                ],

                // reset to defaults
                minWidth: 650,
                maxWidth: false,
                minHeight: 450,
                maxHeight: false
            });
    };
};
