/* Generic ObjectList */

IG.MediaObjectList = function(opts) {
    var _this = this;
    opts = (typeof opts != "undefined" ? opts : {});

    this.id = "objectlist-" + IG.fn.getTimestamp() + "-" + Math.floor(Math.random() * 1000);
    
    this.searchLang = (typeof opts.searchLang == "string" ? opts.searchLang : IG.customerConfig.defaultContentLanguage);

    this.itemcount = 0;
    this.items = [];
    this.tips = [];

    this.headerTable = null;


    this.throbber = null;
    this.itemsScroller = null;
    this.itemsContent = null;
    this.itemsTable = null;
    this.itemsTableBody = null;


    //Configuration:
    this.config = {
        //Columns specifications (width, type, title, rendering):
        columns: opts.columns,

        //HTML element to render inside:
        parentObject: opts.parentObject,

        //Method name to call (also used to derive appropriate parameters):
        methodName: opts.methodName,

        //Row height in pixels (used to calculate number of rows to load upon scrolling):
        rowHeight: 197,

        //Enable scrolling based loading of data:
        scrolling: (typeof opts.scrolling == "boolean" ? opts.scrolling : true),

        //Enable sortable rows:
        sortable: (typeof opts.sortable == "boolean" ? opts.sortable : false),

        //Set function to perform after sorting:
        afterSort: (typeof opts.afterSort == "function" ? opts.afterSort : function() { }),

        //Auto-adjust height on window-resize?
        adjustHeight: (typeof opts.adjustHeight == "boolean" ? opts.adjustHeight : true),

        //Max number of items to retrieve upon scrolling:
        maxrows: 96,       //will be devided by number of items per row

        // items per row
        itemsInRow: 4,



        //Enable subelements (InfoObjects):
        subelements: (typeof opts.subelements == "boolean" ? opts.subelements : false)
    };


    //Search options:
    this.options = {
        sorting: "",
        order: "ASC",
        query: "",
        filters: {},


        offset: 0,
        limit: _this.config.maxrows
    };



    //Object holding current scrolling information:
    this.scrolling = {
        busy: false,

        start: 0,
        stop: 0,
        delta: 0,

        startRow: function() {
            return Math.ceil(this.start / _this.config.rowHeight);
        },
        stopRow: function() {
            return Math.ceil(this.stop / _this.config.rowHeight);
        },
        deltaRow: function() {
            var delta = (this.stop - this.start) / _this.config.rowHeight;
            if (delta < 0 && delta > -1) {
                delta = -1;
            }
            return Math.ceil(delta);
        },


        minRow: 0, //Current min row
        maxRow: 0, //Current max row


        reset: function() {
            this.busy = false;
            this.start = 0;
            this.stop = 0;
            this.delta = 0;
            this.minRow = 0;
            this.maxRow = 0;
        }
    };




    //Removes a row from list
    this.removeRow = function(id) {
        if (typeof id == "object") {
            id = id["id"];
        }
        if (typeof id == "string") {
            if (id != "") {
                $.each($(_this.itemsTableBody).find("tr"), function(i, n) {
                    if ($(n).data("id") == id) {
                        $(n).remove();

                        _this.itemcount -= 1;

                        if (_this.config.adjustHeight) {
                            $(_this.itemsContent).height(_this.getTotalHeight());
                        }

                        return false; //break loop;
                    }
                });
            }
        }
    };



    //Checks if an id filter exists (compares id field):
    this.hasIdFilter = function(k, id) {
        var bFound = false;
        if ($.isArray(_this.options.filters[k])) {
            $.each(_this.options.filters[k], function(i, n) {
                if (n["id"] == id["id"]) {
                    bFound = true;
                    return false; //break loop;
                }
            });
        }
        return bFound;
    };

    //Adds an id filter
    this.addIdFilter = function(k, id) {
        if (!_this.hasIdFilter(k, id)) {
            if ($.isArray(_this.options.filters[k])) {
                _this.options.filters[k].push(id);
            }
            else {
                _this.options.filters[k] = [id];
            }
            return true;
        }
        return false;
    };

    //Removes an id filter:
    this.removeIdFilter = function(k, id) {
        if (_this.hasIdFilter(k, id)) {
            _this.options.filters[k] = $.grep(_this.options.filters[k], function(n, i) {
                return n["id"] != id["id"];
            });

            if (_this.options.filters[k].length == 0) {
                delete _this.options.filters[k];
            }
            return true;
        }
        return false;
    };

    //Set filter: 
    this.setFilter = function(k, v) {
        _this.options.filters[k] = v;
    };

    //Gets a filter value
    this.getFilter = function(k) {

        var res = "";
        $.each(_this.options.filters, function(i, n) {
            if (i == k) {
                res = n;
                return false; //break loop
            }
        });

        return res;
    };

    //Removes filter:
    this.removeFilter = function(k) {
        delete _this.options.filters[k];
    };

    //Set sorting order:
    this.setOrder = function(o) {
        if (o != null) {
            switch (o) {
                case "ASC":
                case "DESC":
                    _this.options.order = o;
                    break;
            }
        }
    };

    //Toggles order (ASC/DESC):
    this.toggleOrder = function() {
        _this.options.order = (_this.options.order == "ASC" ? "DESC" : "ASC");
    };

    //Sets sorting key:
    this.setSorting = function(k) {
        if (k != null) {
            if (k == _this.options.sorting) {
                _this.toggleOrder();
            }
            _this.options.sorting = k;
        }
    };


    //Sets query string:
    this.setQuery = function(s, lang) {
    	if (lang != undefined) {
    		_this.searchLang = lang;
    	}
        _this.options.query = s;
    };




    //Refreshes headers:
    this.refreshHeader = function() {
        $.each($("#" + _this.id).find(".ig-objectlist-header th"), function(i, n) {
            if (_this.options.sorting == "") {
                $(n)
                    .removeClass("sortby")
                    .removeClass("sort-desc")
                    .removeClass("sort-asc");
            }
            else {
                if ($(n).data("name") == _this.options.sorting) {
                    $(n).addClass("sortby");
                    if (_this.options.order == "ASC") {
                        $(n)
                            .addClass("sort-asc")
                            .removeClass("sort-desc");
                    }
                    else {
                        $(n)
                            .addClass("sort-desc")
                            .removeClass("sort-asc");
                    }
                }
                else {
                    $(n)
                        .removeClass("sortby")
                        .removeClass("sort-desc")
                        .removeClass("sort-asc");
                }
            }
        });
    };

    //Refreshes list items:
    this.refresh = function() {

        //Show loading animation:
        var loadingId = "loading-" + _this.id;
        IG.gui.loading.show(_this.config.parentObject, loadingId, 1);

        //Hide loading animation:
        var fnCallback = function() {
            _this.refreshHeader();
            IG.gui.loading.hide(loadingId);
        };

        var limit = _this.getMaxVisibleRows() * _this.config.itemsInRow;

        _this.loadCount(function() {
            _this.load(0, limit, function(objects) {
                _this.scrolling.reset();
                _this.scrolling.maxRow = objects.length / _this.config.itemsInRow;
                _this.render(objects, fnCallback);
            });
        });
    };


    //Renders colgroups (for keeping column widths):
    this.renderColGroups = function() {
        var s = '';
        s += '<colgroup>';
        if (_this.config.subelements) {
            s += '<col style="width: 20px" />';
        }
        $.each(_this.config.columns, function(i, n) {
            s += '<col' + (n.width != "auto" ? ' style="width: ' + n.width + '"' : '') + ' />';
        });
        s += '</colgroup>';
        return s;
    };


    //Renders the table header:
    this.renderHeader = function() {
        return;
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-objectlist-header");

        if (!_this.config.scrolling) {
            $(objDiv).addClass("no-scrolling");
        }

        var objTable = document.createElement("table");
        $(objTable)
            .addClass("ig-objectlist-header")
            .append(_this.renderColGroups())
            .appendTo(objDiv);

        //Build table header:
        var objTHead = document.createElement("thead");
        $(objTHead).appendTo(objTable);

        var objTR = document.createElement("tr");
        $(objTR).appendTo(objTHead);


        if (_this.config.subelements) {
            var objTHsub = document.createElement("th");
            $(objTHsub)
                .addClass("sub")
                .appendTo(objTR);
        }

        //Build table header cells:
        $.each(_this.config.columns, function(i, n) {
            var objTH = document.createElement("th");
            $(objTH)
                .data("name", n.name)
                .attr("title", n.title)
                .text(n.title)
                .appendTo(objTR);

            if (n.className != "") {
                $(objTH).addClass(n.className);
            }

            //Attach qtip to span:
            if (typeof n.tip != "undefined") {

                var objTip = $(objTH).qtip({
                    content: {
                        text: $(n.tip.content),
                        title: {
                            text: n.title,
                            button: true
                        }
                    },
                    hide: { fixed: true },
                    position: {
                        corner: {
                            target: "bottomMiddle",
                            tooltip: "topMiddle"
                        }
                    },
                    style: {
                        name: "objectlist",
                        width: $(objTH).width + 20,
                        height: n.tip.height,
                        "overflow-y": n.tip.overflow,
                        padding: "0 20px 0 0"
                    }
                });

                //Store this qtip in our list:
                _this.tips.push(objTip);
            }

            //Column is sortable:
            if (n.sortable) {
                $(objTH).addClass("sortable");

                if (n.name == _this.options.sorting) {
                    $(objTH).addClass("sortby");

                    if (_this.options.order == "ASC") {
                        $(objTH).addClass("sort-asc");
                    }
                    else if (_this.options.order == "DESC") {
                        $(objTH).addClass("sort-desc");
                    }
                }

                $(objTH).click(function() {
                    _this.setSorting(n.name);
                    _this.refresh();
                });

            }
        });

        return objDiv;
    };




    //Get item row with id:
    this.getItemRow = function(id) {
        var objTR = null;
        if (_this.itemsTableBody != null) {
            $.each($(_this.itemsTableBody).find("tr"), function(i, n) {
                if ($(n).data("id") == id) {
                    objTR = n;
                    return false; //break loop
                }
            });
        }
        return objTR;
    };

    //Renders a single item:
    this.renderItem = function(n, bSub) {
        var objTR = document.createElement("tr");
        $(objTR)
            .addClass((bSub ? "sub" : "") + "item")
            .css("float", "left")
            .data("id", n.id["id"])
            .data("idObj", n.id)
            .hover(
                function() {
                    $(this).addClass("hl");
                },
                function() {
                    $(this).removeClass("hl");
                }
            );

        //Create subelements toggler if needed:
        if (_this.config.subelements) {
            var objTDsub = document.createElement("td");
            $(objTDsub)
                .addClass("sub")
                .appendTo(objTR);

            var bHasSubelements = false;

            //Subelements exist:
            if (!bSub) {
                if (typeof n.referencedInfoObjectIds != "undefined") {
                    if (typeof n.referencedInfoObjectIds.length != "undefined") {
                        if (n.referencedInfoObjectIds.length > 0) {
                            bHasSubelements = true;
                        }
                    }
                }
            }

            if (bHasSubelements) {
                var objSubToggler = document.createElement("span");
                $(objSubToggler)
                    .addClass("sub")
                    .click(function() {
                        var _me = this;
                        _this.toggleSubItems(n, objTR, function() {
                            $(objTR).toggleClass("sub-open");
                            $(_me).toggleClass("sub-open");
                        });
                    })
                    .appendTo(objTDsub);
            }
            else {
                $(objTDsub).html("&nbsp;");
            }
        }


        $.each(_this.config.columns, function(j, m) {
            var objTD = document.createElement("td");
            $(objTD).appendTo(objTR);

            if ($.isFunction(m.renderTitle)) {
                $(objTD).attr("title", m.renderTitle(n));
            }

            if (m.className != "") {
                $(objTD).addClass(m.className);
            }

            if (m.sortable) {
                if (m.name == _this.options.sorting) {
                    $(objTD).addClass("sortby");
                }
            }


            if ($.isFunction(m.render)) {
                $(objTD).append(m.render(n));
            }
            else {
                $(objTD).append("?");
            }
        });

        return objTR;
    };


    //Manually append a row to table body:
    this.appendRow = function(object, bIncCount) {
        bIncCount = (typeof bIncCount == "boolean" ? bIncCount : false);
        var objTR = _this.renderItem(object);
        if (objTR != null) {
            $(_this.itemsTableBody).prepend(objTR);
            if (bIncCount) {
                _this.items.push(object);
                _this.itemcount++;
            }
        }
    };

    //Manually prepend a row to table body:
    this.prependRow = function(object, bIncCount) {
        bIncCount = (typeof bIncCount == "boolean" ? bIncCount : false);
        var objTR = _this.renderItem(object);
        if (objTR != null) {
            $(_this.itemsTableBody).prepend(objTR);
            if (bIncCount) {
                _this.items.push(object);
                _this.itemcount++;
            }
        }
    };

    //Get array of id objects for each (rendered!) item row (no support for sub-elements):
    this.getItemIds = function() {
        var res = [];

        $.each($(_this.itemsTableBody).find("tr.item"), function(i, n) {
            res.push($(n).data("idObj"));
        });

        return res;
    };


    this.showLoading = function() {
        if (_this.throbber) {
            $(_this.throbber).show();
        }
    };

    this.hideLoading = function() {
        if (_this.throbber != null) {
            $(_this.throbber).hide();
        }
    };

    this.showThrobber = function(bDown, throbDelta) {
        if (_this.throbber != null) {

            //show throbber - position before or after loaded items depending on scroll direction
            var t = _this.scrolling.stop;
            var h = Math.min($(_this.itemsScroller).height(), throbDelta * _this.config.rowHeight);


            //Scrolled up - position throbber before items - but within current view.
            if (!bDown) {
                $(_this.throbber)
                .css({
                    "top": t + "px",
                    "height": h + "px"
                })
                .show();
            }
            //Scrolled down - position throbber after items - but within current view.
            else {
                t = Math.max($(_this.itemsTable).position().top + $(_this.itemsTable).height(), _this.scrolling.stop);

                $(_this.throbber)
                .css({
                    "top": t + "px",
                    "height": h + "px"
                })
                .show();
            }
        }
    };

    this.hideThrobber = function() {
        if (_this.throbber != null) {
            $(_this.throbber).hide();
        }
    };


    //Handles item scrolling:
    this.scrollItems = function(obj, callback) {
        if (obj) {
            IG.debug("IG.ObjectList.scrolling, startRow: " + _this.scrolling.startRow() + ", stopRow: " + _this.scrolling.stopRow() + ", deltaRow: " + _this.scrolling.deltaRow() + ", minRow: " + _this.scrolling.minRow + ", maxRow: " + _this.scrolling.maxRow);

            //Number of items to retrieve:
            var limit = 0;

            //Offset of items to retrieve:
            var offset = 0;

            var scrollStart = _this.scrolling.start;
            var scrollEnd = _this.scrolling.stop;

            //Get the max number of visible rows:
            var availableRows = _this.getMaxVisibleRows();

            //Scroll start row:
            var startRow = Math.abs(_this.scrolling.startRow());

            //Scroll stop row:
            var stopRow = Math.abs(_this.scrolling.stopRow());

            //Scroll delta:
            var delta = _this.scrolling.deltaRow();

            var throbDelta = delta;

            //Currently rendered min and max row offset:
            var minRow = _this.scrolling.minRow;
            var maxRow = _this.scrolling.maxRow;


            //Function to perform after load:
            var fn = function() {
                //Behavior depends on the amount and direction scrolled
            };


            //Scrolled no more than max allowed:
            //if (Math.abs(delta) <= _this.config.maxrows) {

                //Scroll up:
                if (delta < 0) {
                    IG.debug("IG.ObjectList.scrollItems, scroll UP");

                    if (stopRow < minRow) {
                        limit = (minRow - stopRow) * _this.config.itemsInRow;
                        throbDelta = (minRow - stopRow) * _this.config.itemsInRow;

                        // limit = Math.min(availableRows, _this.config.maxrows);
                    }

                    if (limit > 0) {
                        offset = Math.max((minRow * _this.config.itemsInRow) - limit, 0);

                        fn = function(objects) {
                            IG.debug("IG.ObjectList.load, offset: " + offset + ", limit: " + limit);
                            if (typeof objects != "undefined") {
                                _this.scrolling.minRow = offset / _this.config.itemsInRow;

                                //Create new table rows to insert:
                                var arrTR = [];
                                $.each(objects, function(i, n) {
                                    var objTR = _this.getItemRow(n.id["id"]);
                                    if (objTR == null) {
                                        arrTR.push(_this.renderItem(n, false));
                                    }
                                });

                                //Prepend new rows
                                $(_this.itemsTableBody).prepend(arrTR);

                                //Change top positioning                                
                                $(_this.itemsTable).css("top", _this.scrolling.stop + "px");
                            }
                        };
                    }
                }
                //Scroll down:
                else if (delta > 0) {
                    IG.debug("IG.ObjectList.scrollItems, scroll DOWN");

                    //Scrolled past last rendered row:
                    if (stopRow > maxRow) {
                        throbDelta = stopRow - maxRow;

                        //limit = stopRow - maxRow;
                        limit = Math.round(Math.max(availableRows, _this.config.maxrows / 2));
                    }
                    //Scrolled within visible area:
                    else if (maxRow - stopRow < availableRows) {
                        throbDelta = availableRows - (maxRow - stopRow);

                        //limit = availableRows - (maxRow - stopRow);
                        limit = Math.round(Math.max(availableRows, _this.config.maxrows / 2));
                    }

                    //Got any items to retrieve?
                    if (limit > 0) {
                        offset = maxRow * _this.config.itemsInRow;

                        fn = function(objects) {
                            IG.debug("IG.ObjectList.load, offset: " + offset + ", limit: " + limit);
                            if (typeof objects != "undefined") {
                                _this.scrolling.maxRow += Math.ceil(objects.length / _this.config.itemsInRow);

                                //Append table rows:
                                $.each(objects, function(i, n) {
                                    var objTR = _this.getItemRow(n.id["id"]);
                                    if (objTR == null) {
                                        $(_this.itemsTableBody).append(_this.renderItem(n, false));
                                    }
                                });
                            }
                        };
                    }
                }
            //}
            //Scrolled more than max allowed:
            else {
                IG.debug("IG.ObjectList.scrollItems, delta > max");

                //limit = Math.min(availableRows, _this.config.maxrows);
                limit = Math.round(Math.max(availableRows, _this.config.maxrows / 2));
                if (limit > 0) {
                    offset = stopRow * _this.config.itemsInRow;

                    fn = function(objects) {
                        IG.debug("IG.ObjectList.load, offset: " + offset + ", limit: " + limit);

                        if (typeof objects != "undefined") {
                            _this.scrolling.minRow = stopRow;
                            if (objects.length % _this.config.itemsInRow == 0) {
                                _this.scrolling.maxRow = Math.ceil(stopRow + (objects.length / _this.config.itemsInRow));
                            } else {
                                _this.scrolling.maxRow = Math.ceil(stopRow + (objects.length / _this.config.itemsInRow)) - 1;
                            }

                            //Replace content and set top positioning:
                            var arrTR = [];
                            $.each(objects, function(i, n) {
                                arrTR.push(_this.renderItem(n, false));
                            });
                            $(_this.itemsTableBody).html(arrTR);
                            $(_this.itemsTable).css("top", _this.scrolling.stop + "px");
                        }
                    };
                }
            }



            if (limit > 0) {
                //Make sure not to fectch data while busy fetching data for a previous scroll:
                if (!_this.scrolling.busy) {
                    _this.scrolling.busy = true;

                    _this.showThrobber((delta > 0), throbDelta);
                    _this.load(offset, limit, function(objects) {
                        _this.hideThrobber();

                        fn(objects);

                        _this.scrolling.busy = false;

                        IG.debug("IG.ObjectList.scrollItems done, minRow: " + _this.scrolling.minRow + ", maxRow: " + _this.scrolling.maxRow);

                        if ($.isFunction(callback)) {
                            callback();
                        }
                    });
                }
                else {
                    if ($.isFunction(callback)) {
                        callback();
                    }
                }
            }
            else {
                if ($.isFunction(callback)) {
                    callback();
                }
            }
        }
    };


    //Returns the current number of rows (excluding subelements):
    this.getNumberOfRows = function() {
        return $(_this.itemsTableBody).children("tr.item").length;
    };

    //Returns the available height for items:
    this.getAvailableHeight = function() {
        var p = _this.config.parentObject;
        var innerHeight = $(p).innerHeight();
        return innerHeight - (16 + 2 * 10 + 2 * 1);
    };

    //Returns the max number of visible rows given current list height:
    this.getMaxVisibleRows = function() {
        return Math.ceil(_this.getAvailableHeight() / _this.config.rowHeight);
    };

    this.getTotalHeight = function() {
        return (_this.itemcount / _this.config.itemsInRow) * _this.config.rowHeight;
    };

    this.resize = function() {
        $(_this.itemsScroller).height(_this.getAvailableHeight());
    };

    //Renders the table items:
    this.renderItems = function(objects) {
        IG.debug("ObjectList.renderItems, id: " + _this.id);

        var p = _this.config.parentObject;
        var h = _this.getAvailableHeight();


        //Create scrollable container:
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-objectlist-items");

        if (_this.config.adjustHeight) {
            // $(objDiv).height(h);
        }

        if (!_this.config.scrolling) {
            $(objDiv).addClass("no-scrolling");
        }


        //Handle scrolling if enabled:
        if (_this.config.scrolling) {
            //Adjust height on window resize:
            if (_this.config.adjustHeight) {
                $(window).resize(function() {
                    $(objDiv).height(_this.getAvailableHeight());
                });
            }

            _this.itemsScroller = objDiv;

            var objDivThrobber = document.createElement("div");
            $(objDivThrobber)
                .addClass("ig-objectlist-throbber")
                .appendTo(_this.itemsScroller);
            _this.throbber = objDivThrobber;


            $(objDiv)
                .bind("scrollstart", function() {
                    if (!_this.scrolling.busy) {
                        _this.scrolling.start = _this.scrolling.stop;
                    }
                })
                .bind("scrollstop", function() {
                    if (!_this.scrolling.busy) {
                        _this.scrolling.stop = $(this).scrollTop();
                        _this.scrolling.delta = _this.scrolling.stop - _this.scrolling.start;

                        _this.scrollItems($(this), function() {
                            //...
                        });
                    }
                });
        }


        //Create inner container for elements with total height:
        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-objectlist-items-content")
            .appendTo(objDiv);

        if (_this.config.scrolling) {
            if (_this.config.adjustHeight) {
                $(objDivContent).height(_this.getTotalHeight());
            }
        }

        _this.itemsContent = objDivContent;

        //Create table with items:
        var objTable = document.createElement("table");
        $(objTable)
            .addClass("ig-objectlist-items")
            .append(_this.renderColGroups())
            .appendTo(objDivContent);
        _this.itemsTable = objTable;

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);
        _this.itemsTableBody = objTBody;


        //Make table rows sortable by drag n drop:
        if (_this.config.sortable) {

            $(objTBody).sortable({
                containment: "parent",
                axis: "y",
                tolerance: "pointer",
                handle: "span.sort",
                opacity: 0.7,
                stop: function(e, ui) {
                    _this.config.afterSort()
                },
                helper: function(e, tr) {
                    var objHelper = document.createElement("div");
                    $(objHelper)
                        .addClass("ig-objectlist-helper")
                        .height($(tr).outerHeight())
                        .width($(tr).outerWidth());

                    return objHelper;
                }
            });
            $(objTBody).disableSelection();
        }

        if (typeof objects != "undefined") {
            if (typeof objects.length != "undefined") {
                $.each(objects, function(i, n) {
                    $(objTBody).append(_this.renderItem(n, false));
                });
            }
        }

        return objDiv;
    };


    //Renders the table:
    this.render = function(objects, callback) {
        IG.debug("IG.ObjectList.render, callback: " + callback);

        if (_this.config.parentObject != null) {
            var objDiv = $("#" + _this.id).get(0);

            //Not rendered yet - build both parent, header and items:
            if (objDiv == null) {
                objDiv = document.createElement("div");
                $(objDiv)
                    .addClass("ig-objectlist")
                    .attr("id", _this.id)
                    .append(_this.renderHeader())
                    .append(_this.renderItems(objects));

                $(_this.config.parentObject)
                    .empty()
                    .append(objDiv);
            }
            //Already rendered - keep header but re-render items:
            else {
                $(objDiv).children("div.ig-objectlist-items:first")
                    .replaceWith(_this.renderItems(objects));
            }
        }

        if ($.isFunction(callback)) {
            IG.debug("IG.ObjectList.render, isFunction(callback)");
            callback();
        }
    };



    //Loads total count according to options and config:
    this.loadCount = function(callback) {
        var sorts = {};
        sorts[_this.options.sorting] = _this.options.order;

        var filters = $.extend(true, {}, _this.options.filters);

        var params = [];
        var bRequest = true;

        switch (_this.config.methodName) {
            case "searchGalleries":
                params = [IG.config.language, _this.searchLang, _this.options.query, true, filters, sorts, 1, 0];
                break;

            case "searchInfoObjects":
                if (_this.options.query != "") {
                    filters.parentsOnly = true;
                }
                else {
                    delete filters.parentsOnly;
                }

                params = [IG.config.language, _this.searchLang, _this.options.query, true, filters, sorts, 1, 0];
                break;

            case "searchUsers":
                params = [IG.config.language, _this.options.query, true, filters, sorts, 1, 0];
                break;

            case "searchChannels":
                params = [IG.config.language, _this.searchLang, _this.options.query, true, filters, sorts, 1, 0];
                break;

            case "searchMedia":
                filters.hidden = false;
                params = [IG.config.language, _this.searchLang, _this.options.query, true, filters, sorts, 1, 0];
                break;

            default:
                bRequest = false;
                break;
        }

        if (bRequest) {
            IG.request({
                method: _this.config.methodName,
                params: params,
                success: function(response) {
                    if (response.result) {
                        var c = parseInt(response.data["count"], 10);
                        if (isNaN(c)) {
                            c = 0;
                        }
                        _this.itemcount = c;

                    }
                    if ($.isFunction(callback)) {
                        callback();
                    }
                },
                error: function(err) {
                    if ($.isFunction(callback)) {
                        callback();
                    }
                }
            });
        }
        else {
            if ($.isFunction(callback)) {
                callback();
            }
        }
    };




    //Renders subitems table:
    this.renderSubItems = function(objects) {
        var objTable = document.createElement("table");
        $(objTable)
            .addClass("ig-objectlist-items")
            .append(_this.renderColGroups);

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);

        if (typeof objects != "undefined") {
            $.each(objects, function(i, n) {
                $(objTBody).append(_this.renderItem(n, true));
            });
        }

        return objTable;
    };


    //Loads subelements for the provided object:
    this.loadSubelements = function(object, callback) {
        if (_this.config.methodName == "searchInfoObjects") {

            //Set sorting:
            var sorts = {};
            sorts[_this.options.sorting] = _this.options.order;

            //Set filtering:
            var filters = $.extend(true, {}, _this.options.filters);
            delete filters.parentsOnly;

            if (typeof object.matchingReferencedInfoObjectIds != "undefined") {
                filters.ids = object.matchingReferencedInfoObjectIds;
            }
            else {
                filters.ids = object.referencedInfoObjectIds;
            }

            //Set params:
            var params = [IG.config.language, _this.searchLang, "", true, filters, sorts, 0, 0];

            //Send request
            IG.request({
                method: _this.config.methodName,
                params: params,
                success: function(response) {
                    var responseData = response.data["data"];
                    callback(responseData);
                },
                error: function(err) {
                    //error occured fetc
                }
            });
        }
    };


    //Toggles the subitems given parent object and its table row:
    this.toggleSubItems = function(object, objTRref, callback) {
        //Check next sibling of the referring row:
        var objTR = $(objTRref).next("tr").get(0);
        if (objTR != null) {
            //Is the sibling a subitems row with matching data id?
            if ($(objTR).data("id") != "subitems-" + object.id["id"]) {
                objTR = null;
            }
        }

        //Subitems row not found - create it:
        if (objTR == null) {
            var bLoading = false;
            if ($(objTRref).data("sub-loading") == "1") {
                bLoading = true;
            }

            if (!bLoading) {
                $(objTRref).data("sub-loading", "1");

                objTR = document.createElement("tr");
                $(objTR)
                    .addClass("subitems")
                    .data("id", "subitems-" + object.id["id"]);

                var objTD = document.createElement("td");
                $(objTD)
                    .text("FIXME: LOAD SUBITEMS")
                    .attr("colspan", _this.config.columns.length + 1)
                    .appendTo(objTR);

                var objTable = document.createElement("table");
                $(objTable).appendTo(objTD);

                var objTBody = document.createElement("tbody");
                $(objTBody).appendTo(objTable);

                //Load the subelements
                _this.loadSubelements(object, function(objects) {
                    //Append subelements:
                    $(objTD).html(_this.renderSubItems(objects));

                    //Add subelements row:
                    $(objTRref).after(objTR);

                    //Show subelements row:
                    $(objTR).toggle();

                    //Perform callback:           
                    callback();
                });
            }
        }
        //Subitems row exists: show or hide it:
        else {
            $(objTR).toggle();
            callback();
        }
    };

    //Loads data according to offset, limit, options and config:
    this.load = function(offset, limit, callback) {
        var sorts = {};
        sorts[_this.options.sorting] = _this.options.order;

        var filters = $.extend(true, {}, _this.options.filters);

        var params = [];

        var bRequest = true;

        switch (_this.config.methodName) {
            case "searchGalleries":
                params = [IG.config.language, _this.searchLang, _this.options.query, true, filters, sorts, limit, offset];
                break;

            case "searchInfoObjects":
                if (_this.options.parentsOnly != "undefined") {
                    filters.parentsOnly = true;
                }
                else {
                    delete filters.parentsOnly;
                }

                params = [IG.config.language, _this.searchLang, _this.options.query, true, filters, sorts, limit, offset];
                break;

            case "searchUsers":
                params = [IG.config.language, _this.options.query, true, filters, sorts, limit, offset];
                break;

            case "searchChannels":
                params = [IG.config.language, _this.searchLang, _this.options.query, true, filters, sorts, limit, offset];
                break;

            case "searchMedia":
                filters.hidden = false;
                params = [IG.config.language, _this.searchLang, _this.options.query, true, filters, sorts, limit, offset];
                break;

            default:
                bRequest = false;
                break;
        }

        if (bRequest) {
            if (_this.itemcount > 0) {
                IG.request({
                    method: _this.config.methodName,
                    params: params,
                    success: function(response) {
                        if (response.result) {
                            var result = [];

                            // Clean up
                            if (_this.config.methodName === 'searchMedia') {
                                $.each(response.data['data'], function(i, medium) {
                                    result.push(IG.fn.cleanUpMedium(medium));
                                });
                            } else {
                                result = response.data["data"];
                            }


                            if ($.isFunction(callback)) {
                                callback(result);
                            }
                        }
                        else {
                            if ($.isFunction(callback)) {
                                callback([]);
                            }
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog("ObjectList error: " + err);
                        if ($.isFunction(callback)) {
                            callback([]);
                        }
                    }
                });
            }
            else {
                if ($.isFunction(callback)) {
                    callback([]);
                }
            }
        }
        else {
            if (_this.itemcount > 0 && _this.items) {
                if ($.isFunction(callback)) {
                    callback(_this.items);
                }
            } else {
                if ($.isFunction(callback)) {
                    callback([]);
                }
            }
        }
    };


    //Initialize list
    this.init = function(callback) {
        var limit = (_this.config.scrolling ? Math.ceil(_this.getAvailableHeight() / _this.config.rowHeight) : 0) * _this.config.itemsInRow;

        //Get total count:
        _this.loadCount(function() {
            //Load limited number of items:
            _this.load(0, limit, function(objects) {

                _this.scrolling.minRow = 0;
                _this.scrolling.maxRow = Math.abs(objects.length / _this.config.itemsInRow);




                //_this.options.offset = 0;

                //Render:
                _this.render(objects);

                //Perform callback:
                if ($.isFunction(callback)) {
                    callback();
                }
            });
        });
    };

    return this;
};
