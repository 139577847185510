/* Basic plaintext input field (may have multiple language-dependent values) */
IG.TextField = function(options) {
    var _this = this;
    this.id = "";
    this.name = "";
    this.obj = null;

    this.options = (typeof options == "object" ? options : {});

    //Single valued:
    this.options.text = (typeof options.text == "string" ? options.text : "");

    //Multiple-valued:
    this.options.texts = (typeof options.texts != "undefined" ? options.texts : null);

    //Sets default language (if handling multiple-valued):
    this.options.language = (typeof options.language == "string" ? options.language : IG.config.language);

    //Readonly?
    this.options.readonly = (typeof options.readonly == "boolean" ? options.readonly : false);

    this.options.onchange = (typeof options.onchange == "function" ? options.onchange : function() { });

    this.options.name = (typeof options.name == "string" ? options.name : "");
    this.options.id = (typeof options.id == "string" ? options.id : "");


    this.setLanguage = function(language) {
        _this.options.language = language;
        if (_this.options.texts != null) {
            _this.setText(_this.options.texts[language]);
        }
        else {
            _this.setText(_this.options.text);
        }
    };

    this.getText = function() {
        if (_this.obj) {
            return $(_this.obj).val();
        }
        return "";
    };

    this.setText = function(s) {
        if (_this.obj) {
            $(_this.obj).val(s);
        }
    };


    this.render = function() {
        _this.id = _this.options.id;
        if (_this.id == "") {
            _this.id = "ig-textfield-" + IG.fn.getRand();
        }
        _this.name = _this.options.name;
        if (_this.name == "") {
            _this.name = _this.id;
        }



        var objSpan = document.createElement("span");
        $(objSpan).addClass("input");

        var objInput = document.createElement("input");
        $(objInput)
            .attr({
                "type": "text",
                "name": _this.name,
                "id": _this.id
            })
            .addClass('input-text')
            .appendTo(objSpan);

        //Set initial ext:
        if (_this.options.texts != null) {
            var s;
            if (typeof _this.options.texts[_this.options.language] === 'undefined') {
                s = _.first(_.values(_this.options.texts));
            }
            else {
                s = _this.options.texts[_this.options.language];
            }

            $(objInput).val(s);
        }
        else {
            $(objInput).val(_this.options.text);
        }

        //Readonly?
        if (_this.options.readonly) {
            $(objInput).attr("readonly", "readonly");
        }
        else {
            $(objInput).change(function() {
                if (_this.options.texts != null) {
                    _this.options.onchange(_this.options.language, $(this).val());
                }
                else {
                    _this.options.onchange($(this).val());
                }
            });
        }


        _this.obj = objInput;

        return objSpan;
    };

    return this;
};
