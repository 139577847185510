IG.RecurringDate = function(options) {
    var this_ = this;
    var untilFormat = 'YYYYMMDDTHHmmssZZ';
    var currentLocaleData = moment.localeData();

    var recurringCountStore = null;

    // localize the order of the weekdays
    var firstDoW = currentLocaleData.firstDayOfWeek();
    var weekdaysData = [
        { i: 0, key: "SU", lblMin: "", lbl: "" },
        { i: 1, key: "MO", lblMin: "", lbl: "" },
        { i: 2, key: "TU", lblMin: "", lbl: "" },
        { i: 3, key: "WE", lblMin: "", lbl: "" },
        { i: 4, key: "TH", lblMin: "", lbl: "" },
        { i: 5, key: "FR", lblMin: "", lbl: "" },
        { i: 6, key: "SA", lblMin: "", lbl: "" }
    ];

    var weekdaysLocalized = moment.weekdays();
    var weekdaysMinLocalized = moment.weekdaysMin();
    // reorder the days - localized
    for (var j=0; j<firstDoW; j++) {
        weekdaysData.push(weekdaysData.shift());
    }
    // Set the localized name of the day
    $.each(weekdaysData, function(i, info) {
        info.lbl = weekdaysLocalized[info.i];
        info.lblMin = weekdaysMinLocalized[info.i];
    });



    this.dataStore = {
        'value': {
            dtstart: moment().unix(),
            rrules: [{}]
            // recurring: false
            // dtend: 0 // we don't use the dtend field in Hubber
        }
    };

    var isDefined = function(name) {
        return typeof(name) != 'undefined';
    };

    var getRule = function(name) {
        if (!isDefined(this_.dataStore.value.rrules[0])) { return null; }
        if (!isDefined(this_.dataStore.value.rrules[0][name])) { return null; }
        return this_.dataStore.value.rrules[0][name];
    };

    var setRule = function(name, value) {
        if(isDefined(value)) {
            if (this_.dataStore.value.rrules.length === 0) {
                this_.dataStore.value.rrules.push({});
            }
            this_.dataStore.value.rrules[0][name] = value;
        } else {
            if (this_.dataStore.value.rrules.length > 0) {
                delete this_.dataStore.value.rrules[0][name];
            }
        }
        // console.log(name, value, this_.dataStore.value, this_.dataStore.value.rrules[0]);
    };

    var resetRules = function() {
        this_.dataStore.value.rrules = [];
    };


    var getWeekOfMonth = function(m) {
        en = moment.localeData('en');
        var name = en.weekdays(m).slice(0, 2).toUpperCase();
        var first = m.clone().subtract(m.date()-1, 'days');
        if(first.day() <= m.day()) {
            return (Math.floor(m.date()/7) + 1) + name;
        } else {
            return (Math.ceil(m.date()/7)) + name;
        }
    };

    var adjustUntil = function() {
        var dtstartMoment = moment(this_.dataStore.value.dtstart, 'X');
        if (!dtstartMoment.isValid()) { return }

        // adjust the UNTIL rrule if it's before dtstart:
        var untilVal = getRule('UNTIL');

        if (untilVal !== null) {
            var untilMoment = moment(untilVal, untilFormat);
            if (!untilMoment.isValid()) {
                untilMoment = moment(untilVal)
            }
            if (untilMoment.isValid() && dtstartMoment.isAfter(untilMoment, 'day')) {
                setRule('UNTIL', dtstartMoment.format(untilFormat));
            }
        }

        if (recurringCountStore !== null) {
            recurringCountStore.setUntilMinDate( dtstartMoment );
        }
    };

    this.deserializeRRules = function(rules) {
        var result = [];
        jQuery.each(rules, function(index, rrule_string) {
            var rules = {};
            jQuery.each(rrule_string.split(';'), function(i, rule) {
                var pieces = rule.split('=');
                switch(pieces[0]) {
                    case 'BYMONTHDAY':
                    case 'BYDAY':
                        rules[pieces[0]] = pieces[1].split(',');
                        break;
                    default:
                        rules[pieces[0]] = pieces[1];
                        break;
                }

            });
            result.push(rules);
        });

        return result;
    };

    this.serializeRRules = function(rules) {
        var result = [];
        jQuery.each(rules, function(i, ruleset) {
            var rules = [];
            jQuery.each(ruleset, function(key, value) {
                switch(key) {
                    case 'BYMONTHDAY':
                    case 'INTERVAL':
                        // only take numeric values above 1
                        var intVal = parseInt(value);
                        if (_.isFinite(intVal) && intVal > 1) {
                            rules.push([key, intVal].join('='));
                        }
                        break;
                    case 'BYDAY':
                        if (_.isArray(value) && value.length > 0) {
                            rules.push([key, value.join(',')].join('='));
                        }
                        break;
                    default:
                        if (key.length > 0 && ((_.isString(value) && value.length > 0 ) || !_.isUndefined(value) ) ) {
                            rules.push([key, value].join('='));
                        }
                        break;
                }
            });
            result.push(rules.join(';'));
        });
        return result;
    };

    this.save = function (callback) {
        var value = this_.dataStore.value;
        var serialized = this_.serializeRRules(value.rrules);
        callback(this_.describe, {
            dtstart: value.dtstart,
            rrules: serialized
            // recurring: value.recurring
            // dtend: 0
        });
    };

    this.updateData = function(data) {
        if(data && isDefined(data.rrules)) {
            this_.dataStore['value'] = _.cloneDeep(data);
            this_.dataStore.value['rrules'] = this_.deserializeRRules(this_.dataStore.value.rrules || []);
        }
        adjustUntil();
    };

    this.setTimestamp = function(date) {
        this_.dataStore.value['dtstart'] = date;
        if(getRule('FREQ') == 'monthly') {
            if (getRule('BYDAY')) {
                setRule('BYDAY', [getWeekOfMonth(moment(this_.dataStore.value.dtstart, 'X'))]);
            } else if (getRule('BYMONTHDAY')) {
                setRule('BYMONTHDAY', [moment(this_.dataStore.value.dtstart, 'X').date()]);
            }
        }
        adjustUntil();
    };

    this.getDtStart = function() {
        return this_.dataStore.value.dtstart;
    };

    if(isDefined(options.current)) {
        this.updateData(_.cloneDeep(options.current));
    }

    var nth_or_last = function(list, index, replacements) {
        replacements = replacements || {};
        var item  = list[index-1];
        var outstring = typeof item == 'undefined' ? list[list.length-1] : item;

        for(var key in replacements) {
            if(replacements.hasOwnProperty(key) && typeof replacements[key] !== 'undefined') {
                outstring = outstring.replace(new RegExp('{' + key + '}', 'g'), replacements[key].toString());
            }
        }
        return outstring;
    };

    // Return a readable string version of the rruleset in the current language.
    this.describe = function() {
        var translation = IG.recurringDescriber[IG.config.language];
        var stringFuncs = {
            'none': function(i) { return translation.none[0]; },
            'daily': function(i){
                if (!isDefined(i)) {
                    return nth_or_last(translation.daily, 1, {num: 1});
                }
                return nth_or_last(translation.daily, i, {num: i});
            },
            'weekly': function(i){ return nth_or_last(translation.weekly, i, {num: i}); },
            'monthly': function(i){ return nth_or_last(translation.monthly, i, {num: i}); },
            'yearly': function(i){ return nth_or_last(translation.yearly, i, {num: i}); },
            'count': function(i){ return nth_or_last(translation.count, i, {num: i})},
            'until': function(val){
                try {
                    var dateParsed = '';
                    if (moment(val, untilFormat).isValid()) {
                        dateParsed = moment(val, untilFormat);
                    } else {
                        dateParsed = moment(val)
                    }
                    return nth_or_last(translation.until, 0, {date: dateParsed.format('LL')})
                } catch(e) {
                    console.log(e);
                    return '';
                }
            },
            'byday': function(i){
                var re = /^([+-]?\d)?(\w{2})$/i;

                var weekdaysDataMapped = {};
                $.each(weekdaysData, function(j, wDay) {
                    weekdaysDataMapped[wDay.key] = wDay;
                });

                var out = [];
                jQuery.each(i, function(index, day) {
                    var match = re.exec(day);
                    if (match !== null) {
                        var num = match[1];
                        var name = match[2];
                        if(num) {
                            out.push(nth_or_last(translation.byday, num, {day: weekdaysDataMapped[name].lbl }));
                        } else {
                            out.push( weekdaysDataMapped[name.toUpperCase()].lbl);
                        }
                    }
                });
                return out.join(', ');
            },
            'bymonthday': function(dateStrings) {

                // To have momentJS localize part of the string, we create a date based on dtstart to iterate over.
                var dtstartMoment = moment(this_.dataStore.value.dtstart, 'X');

                var outstring = [];
                jQuery.each(dateStrings, function(idx, date) {
                    var value = parseInt(date, 10);
                    dtstartMoment.date(value); // set the new date
                    outstring.push(nth_or_last(translation.bymonthday, idx+1, {num: dtstartMoment.format('Do')}));
                });
                return outstring.join(', ');
            }
        };
        if(this_.dataStore['value']['rrules'].length == 0 || !getRule('FREQ')) { return IG.label.get('RECURRING.NO_REPEAT'); }
        var description = this_.dataStore['value']['rrules'][0];

        var output = [];
        var stringFunc = stringFuncs[description['FREQ'].toLowerCase()];
        output.push(stringFunc(description['INTERVAL']));

        var tryValue = function(value) {
            if(value in description) {
                var descPart = stringFuncs[value.toLowerCase()](description[value]);
                if (descPart.length > 0) { output.push(descPart); }
            }
        };

        tryValue('BYDAY');
        tryValue('BYMONTHDAY');
        tryValue('UNTIL');
        tryValue('COUNT');

        return output.join(' - ');
    };

    this.createUIRecurringSpan = function(callback) {
        var section = $('<tr>');
        $('<th>').text(IG.recurringFrequencyPickerLabel[IG.config.language]).appendTo(section);
        var body = $('<td>').appendTo(section);

        var select = $('<select>').appendTo(body);
        select.on("change", function(e) {
            var chosen = select.find('option:selected')[0];
            var value = chosen && chosen.value;

            if (value === 'none') {
                resetRules();
            } else {
                setRule('FREQ', value);
                // Setting to anything else than 'none', so set a least count
                setRule('COUNT', 1);
            }
            callback(value);
        });
        var default_ = getRule('FREQ');
        var repeatOrder = ['none', 'daily', 'weekly', 'monthly', 'yearly'];
        jQuery.each(repeatOrder, function(index, type) {
            var translation = IG.recurringFrequencyPicker[IG.config.language][type];
            var option = $('<option>').attr("value", type).text(translation).appendTo(select);
            if(type == default_) {
                option.attr('selected', true);
            }
        });

        return section;
    };

    this.createUIRepeatedEvery = function(callback) { // text = "Dag", "Uge", "Måned", "År"
        var section = $('<tr>');
        $('<th>').text(IG.recurringRecurrencePickerLabel[IG.config.language]).appendTo(section);
        var body = $('<td>').appendTo(section);

        var handler = function(e) {
            var chosen = select.find('option:selected')[0];
            setRule('INTERVAL', chosen && chosen.value);
            callback();
        };

        var select = $('<select>').appendTo(body);

        section.setAndSelect = function(text, value) {
            select.off('change', handler);
            select.empty();
            var default_ = getRule('INTERVAL') || value;
            for(var i=1; i<31; i++) {
                var option = $('<option>').attr("value", i.toString()).text(i.toString() + " " + text).appendTo(select);
                if(i == parseInt(default_)) {
                    option.attr('selected', true);
                }
            }
            select.on("change", handler);
            setRule('INTERVAL', default_);
            callback();
        };

        section.setAndSelect('Dag', 1);
        return section;
    };

    this.createUIweekdayPicker = function(callback) {
        var section = $('<tr>');
        $('<th>').text(IG.recurringWeekdayPickerLabel[IG.config.language]).appendTo(section);
        var body = $('<td>').appendTo(section);
        var chosenDays = getRule('BYDAY') || [];

        jQuery.each(weekdaysData, function(index, wDay) {
            $('<input type="checkbox">')
                .attr("id", ":wd" + wDay.i)
                .attr("value", wDay.key)
                .attr("title", wDay.lblMin)
                .attr('checked', chosenDays.indexOf(wDay.key) > -1)
                .appendTo(body);
            $('<label>')
                .attr("for", ":wd" + wDay.i)
                .attr("title", wDay.lblMin)
                .text(wDay.lblMin[0])
                .appendTo(body)
        });

        var setValue = function(e) {
            var chosen = [];
            jQuery.each(body.find('input:checked'), function(i, checkbox) {
                chosen.push(checkbox.value);
            });
            setRule('BYDAY', chosen);
            callback();
        };

        section.resetAndHide = function() {
            setRule('BYDAY');
            section.hide()
        };

        section.setValueShow = function () {
            setValue();
            section.show()
        };

        body.find('input').on("click", setValue);

        return section;
    };

    this.createUIRecurringDay = function(callback) {
        var section = $('<tr>');
        $('<th>').appendTo(section);
        var body = $('<td>').appendTo(section);


        var str = IG.recurringMonthType[IG.config.language].day_in_week;
        $('<input type="radio">')
            .attr('name', 'recurringType')
            .attr('title', str)
            .attr('id', ':rd_week')
            .prop('checked', !!getRule('BYDAY'))
            .on('click', function(e) {
                callback('day_in_week');
            }).appendTo(body);
        $('<label>')
            .attr("for", ":rd_week")
            .attr("title", str)
            .text(str)
            .appendTo(body);

        str = IG.recurringMonthType[IG.config.language].day_in_month;
        var default_ = $('<input type="radio">')
            .attr('name', 'recurringType')
            .attr('id', ':rd_month')
            .attr('title', str)
            .prop('checked', !!getRule('BYMONTHDAY'))
            .on('click', function(e) {
                callback('day_in_month');
            })
            .appendTo(body);
        var other = $('<label>')
            .attr("for", ":rd_month")
            .attr("title", str)
            .text(str)
            .appendTo(body);


        section.resetAndHide = function() {
            callback();
            section.hide();
        };

        section.setValueShow = function() {
            callback('day_in_month');
            default_.click();
            section.show();
        };

        /*if(!getRule('BYMONTHDAY') && !getRule('BYDAY')) {
            callback('day_in_month');
            default_.click();
        }*/

        return section;
    };

    this.createUIRecurringCount = function(callback) {
        var section = $('<tr>');
        $('<th>').text(IG.label.get('RECURRING.LBL.END_TYPE')).appendTo(section);
        var body = $('<td>').appendTo(section);

        var selectEndType = $('<select/>').appendTo(body);
        var elemCountWrap = $('<div/>').addClass('inline').appendTo(body);
        var selectCount = $('<select>').appendTo(elemCountWrap);
        elemCountWrap.append( $('<span/>').text(IG.label.get('RECURRING.COUNT_TIMES')) );

        var dateVal = getRule('UNTIL');
        var dateParsed = moment();
        if (dateVal) {
            if (moment(dateVal, untilFormat).isValid()) {
                dateParsed = moment(dateVal, untilFormat);
            } else {
                dateParsed = moment(dateVal)
            }
        }

        var selectDate = $('<input>').attr({
            "type": "text",
            "readonly": "readonly",
            "value": dateParsed.format('YYYY-MM-DD')
        })
            .addClass('datepicker')
            .appendTo(body).hide()
            .datepicker({
                dateFormat: 'yy-mm-dd',
                changeMonth: true,
                changeYear: true,
                firstDay: 1,
                showOtherMonths: true,
                yearRange: "c-20:c+20",
                prevText: "<",
                nextText: ">",
                dayNamesMin: IG.dayNamesShort[IG.config.language],
                dayNames: IG.dayNames[IG.config.language],
                dayNamesShort: IG.dayNamesShort[IG.config.language],
                monthNames: IG.monthNames[IG.config.language],
                monthNamesShort: IG.monthNamesShort[IG.config.language],
                onSelect: function(dateText, inst) {
                    var dateParsed = moment(dateText, 'YYYY-MM-DD');
                    setRule('UNTIL', dateParsed.format(untilFormat));
                }
            });


        var toggleEndType = function(value) {
            var defaultCnt = getRule('COUNT');
            var defaultUntil = getRule('UNTIL');

            switch (value) {
                case 'count':
                    selectEndType.val('count');
                    elemCountWrap.removeClass('hidden');
                    selectDate.hide();
                    defaultCnt !== null ? setRule('COUNT', defaultCnt) : setRule('COUNT', 1);
                    selectCount.val(getRule('COUNT'));
                    setRule('UNTIL'); // remove
                    break;

                case 'until':
                    selectEndType.val('until');
                    var dateParsed = moment();
                    if (defaultUntil !== null) {
                        if (moment(defaultUntil, untilFormat).isValid()) {
                            dateParsed = moment(defaultUntil, untilFormat);
                        } else {
                            dateParsed = moment(defaultUntil)
                        }
                    }

                    elemCountWrap.addClass('hidden');
                    selectDate.show();
                    defaultUntil !== null ? setRule('UNTIL', defaultUntil) : setRule('UNTIL', dateParsed.format(untilFormat));
                    setRule('COUNT'); // remove
                    break;

                case 'no_rule':

                    break;
            }

        };

        var endTypeHandler = function() {
            var chosen = selectEndType.find('option:selected')[0];
            toggleEndType(chosen.value);
        };

        // On init
        (function() {
            var optionAfter = $('<option/>').attr('value', 'count').text(IG.label.get('RECURRING.LBL.END_AFTER_COUNT')).appendTo(selectEndType);
            var optionOnDate = $('<option/>').attr('value', 'until').text(IG.label.get('RECURRING.LBL.END_ON_DATE')).appendTo(selectEndType);
            selectEndType.on('change', endTypeHandler);

            var currentRule = getRule('COUNT') !== null ? 'count' : (getRule('UNTIL') ? 'until' : 'no_rule');
            if (currentRule === 'no_rule') {
                setRule('COUNT', 1);
                currentRule = 'COUNT';
            }
            toggleEndType(currentRule);
        })();


        var countHandler = function(e) {
            var chosen = selectCount.find('option:selected')[0];
            setRule('COUNT', chosen.value);
            callback();
        };


        (function() {
            selectCount.off('change', countHandler);
            selectCount.empty();
            var default_ = getRule('COUNT');
            for(var i=1; i<31; i++) {
                var option = $('<option>').attr("value", i.toString()).text(i.toString()).appendTo(selectCount);
                if(i==default_) {
                    option.attr('selected', true);
                }
            }
            selectCount.on("change", countHandler);
            default_ !== null ? setRule('COUNT', default_) : null;

            callback();
        })();

        section.setValueShow = function() {
            section.show();

            var currentRule = getRule('COUNT') ? 'count' : (getRule('UNTIL') ? 'until' : 'no_rule');
            if (currentRule === 'no_rule') {
                setRule('COUNT', 1);
                currentRule = 'COUNT';
            }
            toggleEndType(currentRule);
        };

        /**
         * Set the minimum date for the UNTIL datepicker.
         * @param {moment} dateMoment
         */
        section.setUntilMinDate = function(dateMoment) {
            selectDate.datepicker( "option", "minDate", dateMoment.toDate() );
        };

        return section;
    };

    this.createUIEndType = function() {
        var section = $('<tr/>');
        $('<th/>').text('')
    };

    this.createUI = function(callback) {
        var section = $('<div class="content recurring-form">');
        var table = $('<table></table>').appendTo(section);

        var repeatEveryStore = this_.createUIRepeatedEvery(function(value) {});
        var weekdayPickerStore = this_.createUIweekdayPicker(function(value) {});
        recurringCountStore = this_.createUIRecurringCount(function(value) {});
        var recurringDayStore = this_.createUIRecurringDay(function(value) {
            switch(value) {
                case 'day_in_week':
                    setRule('BYDAY', [getWeekOfMonth(moment(this_.dataStore.value.dtstart, 'X'))]);
                    setRule('BYMONTHDAY');
                    break;
                case 'day_in_month':
                    setRule('BYDAY');
                    setRule('BYMONTHDAY', [moment(this_.dataStore.value.dtstart, 'X').date()]);
                    break;
                default:
                    setRule('BYDAY');
                    setRule('BYMONTHDAY');
                    break;
            }
        });

        var recurringSpanStore = this_.createUIRecurringSpan(function(value) {
            var recurringFrequencyLabel = IG.recurringFrequencyLabel[IG.config.language];
            weekdayPickerStore.resetAndHide();
            recurringDayStore.resetAndHide();

            repeatEveryStore.show();
            recurringCountStore.setValueShow();

            // if (value !== 'none') { setRule('FREQ', value) }
            switch(value) {
                case 'none':
                    repeatEveryStore.hide();
                    recurringCountStore.hide();
                    resetRules();
                    break;
                case 'daily':
                    repeatEveryStore.setAndSelect(recurringFrequencyLabel.day, 1);
                    setRule('BYMONTHDAY');
                    setRule('BYDAY');
                    break;
                case 'weekly':
                    repeatEveryStore.setAndSelect(recurringFrequencyLabel.week, 1);
                    weekdayPickerStore.setValueShow();
                    setRule('BYMONTHDAY');
                    break;
                case 'monthly':
                    repeatEveryStore.setAndSelect(recurringFrequencyLabel.month, 1);
                    recurringDayStore.setValueShow();
                    break;
                case 'yearly':
                    repeatEveryStore.setAndSelect(recurringFrequencyLabel.year, 1);
                    setRule('BYMONTHDAY');
                    setRule('BYDAY');
                    break;
                default:
                    break;
            }
            // console.log(this_.serializeRRules(this_.dataStore.value.rrules));
        });

        // Add Title
        // Add Repeat: daily weekly monthly yearly
        recurringSpanStore.appendTo(table);
        // Add Repeat every: n times
        repeatEveryStore.appendTo(table).hide();
        // Depending on daily weekly monthly yearly: add/replace or clear: checkboxes or day of month/week
        weekdayPickerStore.appendTo(table).hide();
        recurringDayStore.appendTo(table).hide();
        // Add repeat count.
        recurringCountStore.appendTo(table).hide();
        recurringCountStore.setUntilMinDate( moment(this_.dataStore.value.dtstart, 'X') );

        switch(getRule('FREQ')) {
            case 'daily':
                repeatEveryStore.setAndSelect(IG.recurringFrequencyLabel[IG.config.language].day, getRule('INTERVAL'));
                repeatEveryStore.show();
                recurringCountStore.show();
                break;
            case 'weekly':
                repeatEveryStore.setAndSelect(IG.recurringFrequencyLabel[IG.config.language].week, getRule('INTERVAL'));
                weekdayPickerStore.show();
                repeatEveryStore.show();
                recurringCountStore.show();
                break;
            case 'monthly':
                repeatEveryStore.setAndSelect(IG.recurringFrequencyLabel[IG.config.language].month, getRule('INTERVAL'));
                recurringDayStore.show();
                repeatEveryStore.show();
                recurringCountStore.show();
                break;
            case 'yearly':
                repeatEveryStore.setAndSelect(IG.recurringFrequencyLabel[IG.config.language].year, getRule('INTERVAL'));
                repeatEveryStore.show();
                recurringCountStore.show();
        }

        return section;
    };
};