import React from "react";

export function Button(
  props: { label: string } & React.HTMLProps<HTMLAnchorElement>
) {
  const { label, ...otherProps } = props;
  return (
    <a className="btn-normal btn-dark" {...otherProps}>
      <span>{`${label}`}</span>
    </a>
  );
}
