IG.Users = function() {
    var _this = this;


    this.fn = {
        //Checks if the given user u has a permission p:
        userHasPermission: function(u, p) {
            var b = false;
            if (u != null && p != null && (typeof u != "undefined") && (typeof p == "string")) {
                if ($.isArray(u.permissions)) {
                    b = ($.inArray(p, u.permissions) > -1);
                }
            }
            return b;
        },

        //Adds a permission p to user u:
        addUserPermission: function(u, p, cb) {
            var bFound = false;

            if (!_this.fn.userHasPermission(u, p)) {
                // Copy the user object
                var uUpdated = $.extend(true, {}, u);
            uUpdated.permissions.push(p);

                IG.update.profile({
                    object: uUpdated,
                    success: function(object) {
                        u = object;
                        if ($.isFunction(cb)) {
                            cb(true);
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                        if ($.isFunction(cb)) {
                            cb(false);
                        }
                    }
                });
            }
            else {
                if ($.isFunction(cb)) {
                    cb(false);
                }
            }
        },

        //Removes a permission p from user u:
        removeUserPermission: function(u, p, cb) {
            var bFound = false;
            if (_this.fn.userHasPermission(u, p)) {
                var uUpdated = $.extend(true, {}, u);

                uUpdated.permissions = $.grep(uUpdated.permissions, function(n, i) {
                    return n != p;
                });

                IG.update.profile({
                    object: uUpdated,
                    success: function(object) {
                        u = object;
                        if ($.isFunction(cb)) {
                            cb(true);
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                        if ($.isFunction(cb)) {
                            cb(false);
                        }
                    }
                });
            }
            else {
                if ($.isFunction(cb)) {
                    cb(false);
                }
            }
        },

        //Renames a usergroup given id and new name:
        renameUserGroup: function(id, s) {

        },

        //Removes a usergroup given id:
        removeUserGroup: function(id) {

        },

        updateUser: function(id) {
            var firstName = $("#ig-user-name");
            var middleName = $("#ig-user-middlename");
            var kod = $("#ig-user-kodeord");
            var errorCss = 'ig-user-error';
            var submit = true;
            if (firstName.val() == '') {
                firstName.parent().addClass(errorCss);
                submit = false;
            } else {
                firstName.parent().removeClass(errorCss);
            }
            if (middleName.val() == '') {
                middleName.parent().addClass(errorCss);
                submit = false;
            } else {
                middleName.parent().removeClass(errorCss);
            }
            if (!submit) return false;
            IG.gui.loading.show();

            var u = IG.getUserById(id);
            u.name = firstName.val();
            u.userName = middleName.val();
            u.password = kod.val();

            IG.update.user({
                object: u,
                success: function(object) {
                    IG.gui.loading.hide();
                    $("#ig-user-select").find("option:selected").each(function() {
                        $(this).text(u.name);
                    });
                    _this.userUpdated = true;

                    // Wait a few seconds to let DB update
                    window.setTimeout(function() {
                        IG.users.loadUsers();
                    }, 3000);
                },
                error: function(err) {
                    IG.gui.loading.hide();
                    IG.showErrorDialog(err);
                }
            });
        },

        //Creates a new user
        createUser: function() {
            var firstName = $("#ig-user-name");
            var middleName = $("#ig-user-middlename");
            var kod = $("#ig-user-kodeord");
            var errorCss = 'ig-user-error';
            var submit = true;
            if (firstName.val() == '') {
                firstName.parent().addClass(errorCss);
                submit = false;
            } else {
                firstName.parent().removeClass(errorCss);
            }
            if (middleName.val() == '') {
                middleName.parent().addClass(errorCss);
                submit = false;
            } else {
                middleName.parent().removeClass(errorCss);
            }
            if (kod.val() == '') {
                kod.parent().addClass(errorCss);
                submit = false;
            } else {
                kod.parent().removeClass(errorCss);
            }
            if (!submit) return false;
            IG.gui.loading.show();
            IG.create.user({
                name: firstName.val(),
                middlename: middleName.val(),
                kod: kod.val(),
                success: function(object) {
                    IG.gui.loading.hide();
                    var newUserOption = document.createElement("option");
                    $(newUserOption)
                            .text(firstName.val())
                            .val(object.id.id)
                            .attr('selected', true)
                            .appendTo($("#ig-user-select"));
                    $("#ig-user-delete").show();
                    $("#ig-user-update span").html(IG.label.get('global_update'));
                    _this.userUpdated = true;

                    // Wait a few seconds to let DB update
                    window.setTimeout(function() {
                        IG.users.loadUsers();
                    }, 3000);
                },
                error: function(err) {
                    IG.gui.loading.hide();
                    IG.showErrorDialog(err);
                }
            });
        },

        //Removes a user:
        removeUser: function(id) {

        },

        //Adds a user to a usergroup giver user id and group id:
        addUserToGroup: function(uId, gId) {

        },

        //Removes a user from a usergroup given user id and group id:
        removeUserFromGroup: function(uId, gId) {

        }

    };

    this.fnAfterRender = [];


    this.config = {
        hashkey: "users",
        selectedtab: "brugerrettigheder",
        selecteduser: ""
    }

    this.userUpdated = false;

    this.hash = function() {
        var h = _this.config.hashkey + "?tab=" + _this.config.selectedtab;
        if (_this.config.selecteduser != "") {
            h += "&user=" + _this.config.selecteduser;
        }
        return h;
    };


    this.refresh = function() {
        _this.fnAfterRender = [];
        _this.render();
    };

    this.IGObjectList = {};


    //Renders permissions tab content:
    this.renderPermissions = function(bAdmin) {
        var objDiv = document.createElement("div");

        //Determine available height for list:
        var fnGetAvailableHeight = function(obj) {
            var h = $(window).height() - $(obj).offset().top - 36; //ish...
            if (h < 200) {
                h = 200;
            }
            return h;
        };

        var objDivList = document.createElement("div");
        $(objDivList)
            .addClass("hubber-list-wrap")
            .attr("id", "ig-users-permissions-list")
            .height($(window).height() - 500) //FIXME: better calculation of height!
            .appendTo(objDiv);

        $(window).resize(function() {
            $(objDivList).height(fnGetAvailableHeight(objDivList));
        });



        //Handles clicks on a permissions checkbox given the checkbox, user object and permission:
        var fnTogglePermission = function(cb, u, p) {
            var loadingId = "loading-toggle-permissions-" + IG.fn.getTimestamp();
            IG.gui.loading.show(objDivList, loadingId, 1);

            //Get the user in our current global list of users since we'll be modifying that:
            u = IG.getUserById(IG.getId(u.id));

            //Checkbox was checked - attempt to add the permission:
            if ($(cb).prop("checked")) {
                _this.fn.addUserPermission(u, p, function(bSuccess) {
                    if (!bSuccess) {
                        $(cb).prop("checked", false);
                    }
                    IG.gui.loading.hide(loadingId);
                });
            }

            //Checkbox was unchecked - attempt to remove permission:
            else {
                _this.fn.removeUserPermission(u, p, function(bSuccess) {
                    if (!bSuccess) {
                        $(cb).prop("checked", true);
                    }
                    IG.gui.loading.hide(loadingId);
                });
            }
        };

        this.IGObjectList = new IG.ObjectList({
            methodName: "searchUsers",
                    parentObject: objDivList,
            scrolling: true,
            filters: {'enabled': true},
            columns: [
                {
                    name: "name",
                    title: IG.label.get("users_name"),
                    className: "ellipsis",
                    sortable: true,
                    width: "auto",
                    renderTitle: function(object) {
                        var s = "...";
                        if (typeof object.name == "string") {
                            s = object.name;
                        }
                        return s;
                    },
                    render: function(object) {
                        var s = "...";
                        if (typeof object.name == "string") {
                            s = object.name;
                        }
                        if (typeof object.enabled !== 'undefined' && !object.enabled) {
                            s += ' (' + IG.label.get('USERS_USER_DISABLED') + ')';
                        }
                        return s;
                    }
                },
                {
                    name: "",
                    title: IG.label.get("users_media_administration"),
                    className: "ellipsis",
                    sortable: false,
                    width: "12%",
                    render: function(object) {
                        var p = "OP_MEDIA_ADMIN";

                        if (typeof object.enabled !== 'undefined' && object.enabled) {
                            var objInp = document.createElement("input");
                            $(objInp)
                                .attr({
                                    "type": "checkbox",
                                    "name": "media",
                                    "id": "media-" + IG.getId(object.id)
                                });
                            if (_this.fn.userHasPermission(object, p)) {
                                $(objInp).prop("checked", true);
                            }

                            if (bAdmin) {
                                $(objInp).change(function() {
                                    fnTogglePermission(this, object, p);
                                });
                            } else {
                                $(objInp).prop("disabled", true);
                            }

                            return objInp;
                        }
                        return null;
                    }
                },
                {
                    name: "",
                    title: IG.label.get("users_infoobjects_administration"),
                    className: "ellipsis",
                    sortable: false,
                    width: "12%",
                    render: function(object) {
                        var p = "OP_INFO_OBJECT_ADMIN";

                        if (typeof object.enabled !== 'undefined' && object.enabled) {
                            var objInp = document.createElement("input");
                            $(objInp)
                                .attr({
                                    "type": "checkbox",
                                    "name": "infoobjects",
                                    "id": "infoobjects-" + IG.getId(object.id)
                                });

                            if (_this.fn.userHasPermission(object, p)) {
                                $(objInp).prop("checked", true);
                            }

                            if (bAdmin) {
                                $(objInp).change(function() {
                                    fnTogglePermission(this, object, p);
                                });
                            } else {
                                $(objInp).prop("disabled", true);
                            }

                            return objInp;
                        }
                        return null;
                    }
                },
                {
                    name: "",
                    title: IG.label.get("users_channel_adminstration"),
                    className: "ellipsis",
                    sortable: false,
                    width: "12%",
                    render: function(object) {
                        var p = "OP_CHANNEL_ADMIN";

                        if (typeof object.enabled !== 'undefined' && object.enabled) {
                            var objInp = document.createElement("input");
                            $(objInp)
                                .attr({
                                    "type": "checkbox",
                                    "name": "channels",
                                    "id": "channels-" + IG.getId(object.id)
                                });

                            if (_this.fn.userHasPermission(object, p)) {
                                $(objInp).prop("checked", true);
                            }

                            if (bAdmin) {
                                $(objInp).change(function() {
                                    fnTogglePermission(this, object, p);
                                });
                            } else {
                                $(objInp).prop("disabled", true);
                            }

                            return objInp;
                        }
                        return null;
                    }
                },
                {
                    name: "",
                    title: IG.label.get("users_gallery_adminstration"),
                    className: "ellipsis",
                    sortable: false,
                    width: "12%",
                    render: function(object) {
                        var p = "OP_GALLERY_ADMIN";

                        if (typeof object.enabled !== 'undefined' && object.enabled) {
                            var objInp = document.createElement("input");
                            $(objInp)
                                .attr({
                                    "type": "checkbox",
                                    "name": "galleries",
                                    "id": "galleries-" + IG.getId(object.id)
                                });

                            if (_this.fn.userHasPermission(object, p)) {
                                $(objInp).prop("checked", true);
                            }

                            if (bAdmin) {
                                $(objInp).change(function() {
                                    fnTogglePermission(this, object, p);
                                });
                            }
                            else {
                                $(objInp).prop("disabled", true);
                            }

                            return objInp;
                        }
                        return null;
                    }
                },
                {
                    name: "",
                    title: IG.label.get("users_group_adminstration"),
                    className: "ellipsis",
                    sortable: false,
                    width: "12%",
                    render: function(object) {
                        var p = "OP_GROUP_ADMIN";

                        if (typeof object.enabled !== 'undefined' && object.enabled) {
                            var objInp = document.createElement("input");
                            $(objInp)
                                .attr({
                                    "type": "checkbox",
                                    "name": "users",
                                    "id": "groups-" + IG.getId(object.id)
                                });

                            if (_this.fn.userHasPermission(object, p)) {
                                $(objInp).prop("checked", true);
                            }

                            if (bAdmin) {
                                $(objInp).change(function() {
                                    fnTogglePermission(this, object, p);
                                });
                            }
                            else {
                                $(objInp).prop("disabled", true);
                            }

                            return objInp;
                        }
                        return null;
                    }
                },
                {
                    name: "",
                    title: IG.label.get("users_user_adminstration"),
                    className: "ellipsis",
                    sortable: false,
                    width: "12%",
                    render: function(object) {
                        var p = "OP_USER_ADMIN";

                        var b = bAdmin;
                        if (b) {
                            //Make sure we cannot set or unset OP_USER_ADMIN for ourselves:
                            b = (IG.getId(IG.userConfig.userId) != IG.getId(object.id));
                        }

                        if (typeof object.enabled !== 'undefined' && object.enabled) {
                            var objInp = document.createElement("input");
                            $(objInp)
                                .attr({
                                    "type": "checkbox",
                                    "name": "users",
                                    "id": "users-" + IG.getId(object.id)
                                });

                            if (_this.fn.userHasPermission(object, p)) {
                                $(objInp).prop("checked", true);
                            }

                            if (b) {
                                $(objInp).change(function() {
                                    fnTogglePermission(this, object, p);
                                });
                            }
                            else {
                                $(objInp).prop("disabled", true);
                            }

                            return objInp;
                        }
                        return null;
                    }
                },
                {
                    name: "",
                    title: IG.label.get("users_text_administration"),
                    className: "ellipsis",
                    sortable: false,
                    width: "12%",
                    render: function(object) {
                        var p = "OP_TEXT_ADMIN";

                        if (typeof object.enabled !== 'undefined' && object.enabled) {
                            var objInp = document.createElement("input");
                            $(objInp)
                                .attr({
                                    "type": "checkbox",
                                    "name": "texts",
                                    "id": "texts-" + IG.getId(object.id)
                                });

                            if (_this.fn.userHasPermission(object, p)) {
                                $(objInp).prop("checked", true);
                            }

                            if (bAdmin) {
                                $(objInp).change(function() {
                                    fnTogglePermission(this, object, p);
                                });
                            }
                            else {
                                $(objInp).prop("disabled", true);
                            }

                            return objInp;
                        }
                        return null;
                    }
                }
            ]
        });


        //initialize list after main content has been rendered:
        _this.fnAfterRender.push(function() {
            _this.IGObjectList.init();
            IG.gui.loading.hide();
        });

        return objDiv;
    };


    this.renderGroups = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
           .addClass("ig-groups");

        var inputDiv = document.createElement("div");
        var inputName = document.createElement("input");
        $(inputName)
            .addClass('input-text')
            .attr({
                "id": "ig-new-group-name",
                "type": "text"
            })
            .appendTo(inputDiv)
            .wrap("<span class='input'></span>");

        var newGroupBtn = document.createElement("a");
        $(newGroupBtn)
            .addClass("btn-normal btn-dark btn-space-l")
            .html("<span>" + IG.label.get("users_create_group") + "</span>")
            .appendTo(inputDiv)
            .click(function() {
                if ($(inputName).val() != "") {     //validation
                    IG.gui.loading.show();
                    IG.create.userGroup({
                        name: $(inputName).val(),
                        success: function(data) {
                            //append new item to groups tab
                            var groupsTab = $("#groups");
                            var newGroupDiv = _this.renderGroupItemDiv(data);
                            $(groupsTab)
                                .prepend($(newGroupDiv));
                            IG.gui.loading.hide();
                        },
                        error: function(err) {
                            IG.gui.loading.hide();
                            IG.showErrorDialog(err);
                        }
                    });
                } else {
                    $(inputName).parent().addClass("ig-user-error");
                }
            });

        $(inputDiv)
            .appendTo(objDiv);

        var groupsDiv = document.createElement("div");
        $(groupsDiv)
            .attr("id", "groups")
            .addClass("ig-group-items");
        
        $.each(IG.lists.groups, function(i, item) {
            $(_this.renderGroupItemDiv(item))
               .appendTo($(groupsDiv));
        });

        $(groupsDiv)
            .appendTo(objDiv);

        return objDiv;
    };

    this.renderGroupItemDiv = function(item) {        
        var groupItemDiv = document.createElement("div");
        $(groupItemDiv)
                .addClass("ig-group-item");

        var groupUsersDiv = document.createElement("div");
        $(groupUsersDiv).addClass("ig-group-users");

        var groupDescDiv = document.createElement("div");

        var delGroupBtn = document.createElement("a");
        $(delGroupBtn)
                .appendTo(groupDescDiv)
                .addClass("btn-normal btn-dark btn-space-r")
                .html("<span class='input'>" + IG.label.get("global_delete") + "</span>")
                .click(function() {
                    var a = $(this);
                    //Create new modal dialog:
                    var objDialog = IG.createConfirmDialog({
                        title: IG.label.get("users_delete_user_group_popup_title"),
                        text: IG.label.get("users_delete_user_group_popup_title"),
                        onConfirm: function() {
                            IG.gui.loading.show();
                            IG.remove.userGroup({
                                id: item.id,
                                success: function(data) {
                                    a.parents("div.ig-group-item:first").remove();
                                    objDialog.close();
                                    IG.gui.loading.hide();
                                },
                                error: function(err) {
                                    objDialog.close();
                                    IG.gui.loading.hide();
                                    IG.showErrorDialog(err);
                                }
                            });
                        }
                    });
                });

        var renameGroupBtn = document.createElement("a");
        $(renameGroupBtn)
                .appendTo(groupDescDiv)
                .addClass("btn-normal btn-dark btn-space-r")
                .html("<span class='input'>" + IG.label.get("users_rename") + "</span>")
                .click(function() {
                    var a = $(this);
                    var name = a.parents("div.ig-group-item:first").find("input[type=text]:first");
                    if (name.val() != '') {
                        IG.gui.loading.show();
                        IG.update.userGroup({
                            name: name.val(),
                            id: item.id,
                            userIds: item.userIds,
                            success: function(data) {
                                IG.gui.loading.hide();
                            },
                            error: function(err) {
                                IG.gui.loading.hide();
                                IG.showErrorDialog(err);
                            }
                        });
                    } else {
                        name.addClass('ig-user-error');
                    }
                });

        var nameInput = document.createElement("input");
        $(nameInput)
            .attr({
                "id": "name" + item.id.id,
                "type": "text",
                "value": item.name
            })
            .appendTo(groupDescDiv)
            .addClass('input-text')
            .wrap("<span class='input'></span>");




        var userSelect = document.createElement('a');
        $(userSelect)
            .addClass('hubber-select btn-icon btn-icon-right btn-space-l')
            .html('<span class="btn-text">' + IG.label.get("users_add_user") + '</span>')
            .appendTo(groupDescDiv);

        // Create selector content
        var objUserSelectTip = document.createElement('div');
        $(objUserSelectTip).addClass('selector-list');

        $.each(IG.lists.users, function(i, u) {
            var add = true;
            $.each(item.userIds, function(i, e) {
                if (e.id == u.id.id) {
                    add = false;
                }
            });
            if (add) {
                var opts = document.createElement("a");
                $(opts)
                    .text(u.name)
                    .data('user_id', u.id.id)
                    .appendTo(objUserSelectTip);
            }
        });
        $(userSelect)
            .qtip({
                content: {
                    text: objUserSelectTip
                },
                show: {
                    delay: 50,
                    event: 'click'
                },
                hide: { event: 'unfocus'},
                position: {
                    my: 'top center',
                    at: 'bottom center',
                    adjust: {
                        y: 2
                    }
                },
                style: {
                    classes: 'qtip-select',
                    width: 120,
                    def: false,
                    background: 'none',
                    tip: {
                        width: 10,
                        height: 6
                    }
                }
            });


        $(objUserSelectTip).on('click', 'a', function(e) {
            e.preventDefault();
            var self = $(this),
                userId = self.data('user_id');

            $(userSelect).qtip('hide');

            var user = IG.getUserById( userId );
            item.userIds.push(user.id);

            IG.gui.loading.show();
            IG.update.userGroup({
                id: item.id,
                name: item.name,
                userIds: item.userIds,

                success: function(data) {
                    IG.gui.loading.hide();
                    var userDiv = _this.renderUserInGroup(IG.getUserById(userId), item.id.id, objUserSelectTip);
                    $(userDiv).appendTo(groupUsersDiv);

                    self.remove();
                },
                error: function(err) {
                    IG.gui.loading.hide();
                    IG.showErrorDialog(err);
                }
            });
        });


        $(groupDescDiv).appendTo(groupItemDiv);
        $.each(item.userIds, function(i, u) {
            var user = IG.getUserById(u);
            if (user !== null) {
                var userDiv = _this.renderUserInGroup(user, item.id.id, objUserSelectTip);
                $(userDiv).appendTo(groupUsersDiv);
            }
        });
        $(groupUsersDiv).appendTo(groupItemDiv);
        return groupItemDiv;
    };


    this.renderUserInGroup = function(item, groupId, objSelect) {
        var group = IG.getUserGroupById(groupId);

        var usersDiv = document.createElement("div");
        $(usersDiv)
            .addClass("ig-group-user");

        var removeUserFromGroupBtn = document.createElement("a");
        $(removeUserFromGroupBtn)
                .addClass("btn-small btn-dark btn-space-r")
                .attr({
                    "id": item.id.id
                })
                .html("<span class='input'>" + IG.label.get("global_remove") + "</span>")
                .appendTo($(usersDiv))
                .click(function() {
                    var a = $(this);
                    //remove this user from group
                    $.each($(group.userIds), function(i, temp) {
                        if (item.id.id == temp.id) {
                            group.userIds.splice(i, 1);
                        }
                    });
                    IG.gui.loading.show();
                    IG.update.userGroup({
                        id: group.id,
                        name: group.name,
                        userIds: group.userIds,
                        success: function(data) {
                            //add it back as option


                            var opts = document.createElement("a");
                            $(opts)
                                .text(item.name)
                                .data('user_id', item.id.id)/*
                                .click(function(e) {
                                    e.preventDefault();
                                    var self = $(this),
                                        userId = self.data('user_id');

                                    $(userSelect).qtip('hide');

                                    var user = IG.getUserById( userId );
                                    group.userIds.push(user.id);

                                    IG.gui.loading.show();
                                    IG.update.userGroup({
                                        id: group.id,
                                        name: group.name,
                                        userIds: group.userIds,

                                        success: function(data) {
                                            IG.gui.loading.hide();
                                            var userDiv = _this.renderUserInGroup(IG.getUserById(userId), group.id.id, objSelect);
                                            $(userDiv).appendTo(groupUsersDiv);

                                            self.remove();
                                        },
                                        error: function(err) {
                                            IG.gui.loading.hide();
                                            IG.showErrorDialog(err);
                                        }
                                    });
                                })*/
                                .appendTo(objSelect);

                            a.parent().remove();
                            IG.gui.loading.hide();

                        },
                        error: function(err) {
                            IG.gui.loading.hide();
                            IG.showErrorDialog("Error deleting user: " + err);
                        }
                    });
                });

        var userNameSpan = document.createElement("span");
        $(userNameSpan)
            .html(item.name)
            .appendTo(usersDiv);
        return usersDiv;
    };


    this.renderUsers = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("clearfix");

        if (bWrite) {

            var objDivPassword = document.createElement("div");
            $(objDivPassword)
                .addClass("ig-column-left")
                .appendTo(objDiv);

            var objTitle = document.createElement("h3");
            $(objTitle)
                .attr('id', 'ig-user-title')
                .addClass("ig-title")
                .text(IG.label.get("users_create_new_user"))
                .appendTo(objDivPassword);

            var s = ''
                + '<table>'
                + '<tbody>'
                + '<tr>'
                + '<td class="lbl"><label for="ig-user-select">' + IG.label.get("users_edit_user") + ':</label></td>'
                + '<td>'
                + '<select id="ig-user-select" class="hubber-select">'
                + '<option value="-1" selected="selected">' + IG.label.get("users_new_user") + '</option>';
            $.each(IG.lists.users, function(i, item) {
                if (!item.hasOwnProperty('enabled') || item.enabled) {
                    s += '<option value=' + item.id.id + '>' + item.name + '</option>';
                }

            });
            s += '</select>'
                + '</td>'
                + '</tr>'
                + '<tr>'
                + '<td class="lbl"><label for="ig-user-name">' + IG.label.get("users_user_name") + ':</label></td>'
                + '<td><span class="input"><input type="text" id="ig-user-name" class="input-text" /></span></td>'
                + '</tr>'
            //+ '<tr>'
            //+ '<td class="lbl"><label for="ig-user-lastname">' + 'Efternavn' + ':</label></td>' //FIXME: label
            //+ '<td><span class="input"><input type="text" id="ig-user-lastname" /></span></td>'
            //+ '</tr>'
                + '<tr>'
                + '<td class="lbl"><label for="ig-user-middlename">' + IG.label.get("users_user_username") + ':</label></td>'
                + '<td><span class="input"><input type="text" id="ig-user-middlename" class="input-text" /></span></td>'
                + '</tr>'
                + '<tr>'
                + '<td class="lbl"><label for="ig-user-kodeord">' + IG.label.get("users_new_password") + ':</label></td>'
                + '<td><span class="input"><input type="password" id="ig-user-kodeord" class="input-text" /></span></td>'
                + '</tr>'
                + '<tr>'
                + '<td class="lbl">&nbsp</td>'
                + '<td><a id="ig-user-update" class="btn-normal btn-dark btn-space-b"><span>' + IG.label.get("global_create") + '</span></a>'
                + '<br/>'
                + '<a id="ig-user-delete" class="btn-normal btn-pink"><span>' + IG.label.get("users_delete_user") + '</span></a></td>'
                + '</tr>'
                + '</tbody>'
                + '</table>';

            $(objDivPassword).append(s);
        }

        return objDiv;
    };


    this.renderTransferOwnership = function(bWrite) {
        var objDiv = document.createElement("div");

        var objTitle = document.createElement("h3");
        $(objTitle)
            .attr('id', 'ig-user-title')
            .addClass("ig-title")
            .text(IG.label.get("users_transfer_ownership"))
            .appendTo(objDiv);

        var listWrapFromOuter = document.createElement('div');
        $(listWrapFromOuter)
            .addClass('container-50')
            .appendTo(objDiv);

        var objHeaderFrom = document.createElement('h4');
        $(objHeaderFrom)
            .text(IG.label.get('users_change_owner_from'))
            .appendTo(listWrapFromOuter);

        var listWrapToOuter = document.createElement('div');
        $(listWrapToOuter)
            .addClass('container-50')
            .appendTo(objDiv);

        var objHeaderTo = document.createElement('h4');
        $(objHeaderTo)
            .text(IG.label.get('users_change_owner_to'))
            .appendTo(listWrapToOuter);

        var objDivListFrom = document.createElement("div");
        $(objDivListFrom)
            .addClass("hubber-list-wrap")
            .attr("id", "ig-users-ownership-list-from")
            .appendTo(listWrapFromOuter);

        var listUserFrom = new IG.ObjectList({
            methodName: "searchUsers",
            parentObject: objDivListFrom,
            scrolling: true,
            filters: {'enabled': false},
            columns: [
                {
                    name: 'select',
                    title: IG.label.get(''),
                    width: '30px',
                    render: function(object) {
                        var objRadioBtn = document.createElement('input');
                        $(objRadioBtn)
                            .attr('type', 'radio')
                            .attr('name', 'radio-group-from-user')
                            .val(object.id.customerId+'::'+object.id.id);
                        return objRadioBtn;
                    }
                },
                {
                    name: "name",
                    title: IG.label.get("users_name"),
                    className: "ellipsis",
                    sortable: true,
                    width: "auto",
                    renderTitle: function(object) {
                        var s = "...";
                        if (typeof object.name == "string") {
                            s = object.name;
                        }
                        return s;
                    },
                    render: function(object) {
                        var s = "...";
                        if (typeof object.name == "string") {
                            s = object.name;
                        }
                        if (typeof object.enabled !== 'undefined' && !object.enabled) {
                            s += ' (' + IG.label.get('USERS_USER_DISABLED') + ')';
                        }
                        return s;
                    }
                }
            ]
        });


        var objDivListTo = document.createElement("div");
        $(objDivListTo)
            .addClass("hubber-list-wrap")
            .attr("id", "ig-users-ownership-list-to")
            .appendTo(listWrapToOuter);

        var listUserTo = new IG.ObjectList({
            methodName: "searchUsers",
            parentObject: objDivListTo,
            scrolling: true,
            filters: {'enabled': true},
            columns: [
                {
                    name: 'select',
                    title: IG.label.get(''),
                    width: '30px',
                    render: function(object) {
                        var objRadioBtn = document.createElement('input');
                        $(objRadioBtn)
                            .attr('type', 'radio')
                            .attr('name', 'radio-group-to-user')
                            .val(object.id.customerId+'::'+object.id.id);
                        return objRadioBtn;
                    }
                },
                {
                    name: "name",
                    title: IG.label.get("users_name"),
                    className: "ellipsis",
                    sortable: true,
                    width: "auto",
                    renderTitle: function(object) {
                        var s = "...";
                        if (typeof object.name == "string") {
                            s = object.name;
                        }
                        return s;
                    },
                    render: function(object) {
                        var s = "...";
                        if (typeof object.name == "string") {
                            s = object.name;
                        }
                        if (typeof object.enabled !== 'undefined' && !object.enabled) {
                            s += ' (' + IG.label.get('USERS_USER_DISABLED') + ')';
                        }
                        return s;
                    }
                }
            ]
        });

        //initialize list after main content has been rendered:
        _this.fnAfterRender.push(function() {
            listUserFrom.init();
            listUserTo.init();
            IG.gui.loading.hide();
        });



        var obtBtnTransfer = document.createElement('a');
        $(obtBtnTransfer)
            .addClass('btn-normal btn-dark')
            .prepend('<span>' + IG.label.get('transfer_ownership_btn') + '</span>')
            .appendTo(objDiv)
            .click(function(e) {
                e.preventDefault();

                var fromVal = $("input[name=radio-group-from-user]:checked").val();
                var toVal = $("input[name=radio-group-to-user]:checked").val();

                if (fromVal && toVal) {
                    if (fromVal === toVal) {
                        IG.showErrorDialog(IG.label.get('USER_TRANSFER_ERROR_SAME_USER'));
                        return;
                    }

                    var fromId = IG.getIdFromString(fromVal);
                    var toId = IG.getIdFromString(toVal);

                    if (!fromId || !toId) {
                        IG.showErrorDialog(IG.label.get('USER_TRANSFER_ERROR_INVALID_IDS'));
                        return;
                    }

                    //Create new modal dialog:
                    var objDialog = IG.createConfirmDialog({
                        title: IG.label.get("users_transfer_ownership_popup_title"),
                        text: "",
                        onConfirm: function() {
                            IG.gui.loading.show();


                            // TODO: send request
                            console.log('TRANSFER', fromId, toId);
                            window.setTimeout(function() {
                                IG.gui.loading.hide();
                                objDialog.close();
                            }, 500);

                        }
                    });
                } else {
                    IG.showErrorDialog(IG.label.get('USER_TRANSFER_ERROR_NONE_CHOSEN'));
                }


            });


        return objDiv;
    };





    this.render = function() {

        var bAdmin = IG.userHasPermission("OP_USER_ADMIN");
        var bCreate = IG.userHasPermission("OP_USER_CREATE") || bAdmin;
        var bWrite = IG.userHasPermission("OP_USER_WRITE") || bAdmin;
        var bDelete = IG.userHasPermission("OP_USER_DELETE") || bAdmin;


        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "ig-users");

        //Create header:
        var objDivHeader = document.createElement("div");
        $(objDivHeader)
            .attr("id", "ig-users-header")
            .appendTo(objDiv);

        //Create headline:
        var objH2 = document.createElement("h2");
        $(objH2)
            .text(IG.label.get("users_headline"))
            .appendTo(objDivHeader);

        //Build tabs bar:
        var objTabs = document.createElement("div");
        $(objTabs)
            .addClass("ig-tabs")
            .attr("id", "ig-users-tabs")
            .appendTo(objDiv);

        var objTabsContainer = document.createElement("span");
        $(objTabsContainer)
            .addClass("ig-tabs-content")
            .attr("id", "ig-users-tabs-content")
            .appendTo(objTabs);

        var objTabItemPermissions = document.createElement("a");
        $(objTabItemPermissions)
            .html('<span>' + IG.label.get("users_tab_permisions") + '</span>')
            .appendTo(objTabsContainer);

        var objTabItemGroups = document.createElement("a");
        $(objTabItemGroups)
            .html('<span>' + IG.label.get("users_tab_groups") + '</span>')
            .appendTo(objTabsContainer);

        var objTabItemUsers = document.createElement("a");
        $(objTabItemUsers)
            .html('<span>' + IG.label.get("users_tab_users") + '</span>')
            .appendTo(objTabsContainer);

        /* TODO: Remove comment when ownership transfer is ready
        var objTabItemTransferOwnership = document.createElement('a');
        $(objTabItemTransferOwnership)
            .html('<span>' + IG.label.get("users_tab_transfer_ownership") + '</span>')
            .appendTo(objTabsContainer); */

        //Build tabs data items:
        var objDivTabsData = document.createElement("div");
        $(objDivTabsData).addClass("ig-tabs-data");

        var objTabDataPermissions = document.createElement("div");
        $(objTabDataPermissions)
            .attr("id", "ig-users-permissions")
            .addClass("ig-tabs-data-item")
            .append(_this.renderPermissions(bWrite))
            .appendTo(objDivTabsData);

        var objTabDataGroups = document.createElement("div");
        $(objTabDataGroups)
            .attr("id", "ig-users-groups")
            .addClass("ig-tabs-data-item")
            .append(_this.renderGroups(bWrite))
            .appendTo(objDivTabsData);

        var objTabDataUsers = document.createElement("div");
        $(objTabDataUsers)
            .attr("id", "ig-users-users")
            .addClass("ig-tabs-data-item")
            .append(_this.renderUsers(bWrite))
            .appendTo(objDivTabsData);

        var objTabDataOwnership = document.createElement("div");
        $(objTabDataOwnership)
            .attr("id", "ig-users-ownership")
            .addClass("ig-tabs-data-item clearfix")
            .append(_this.renderTransferOwnership(bWrite))
            .appendTo(objDivTabsData);

        $(objTabItemPermissions).click(function() {
            _this.refreshTabContent(bWrite);

            _this.config.selectedtab = "brugerrettigheder";
            $.address.value(_this.hash());

            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataPermissions;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
        });

        $(objTabItemGroups).click(function() {
            _this.config.selectedtab = "gruppestyring";
            $.address.value(_this.hash());

            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataGroups;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");

            _this.refreshTabContent(bWrite);
        });

        $(objTabItemUsers).click(function() {
            _this.config.selectedtab = "brugeradministration";
            $.address.value(_this.hash());

            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataUsers;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
        });

        /* TODO: Remove comment when ownership transfer is ready
        $(objTabItemTransferOwnership).click(function(e) {
            e.preventDefault();
            e.stopPropagation();

            _this.config.selectedtab = "ejerskab";
            $.address.value(_this.hash());

            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            $(objTabDataOwnership).addClass("current");
            $(objTabDataOwnership).siblings().removeClass("current");
        }); */

        var selectedTab = typeof ($.address.parameter("tab")) != "undefined" ? $.address.parameter("tab").split('|')[0] : _this.config.selectedtab;
        switch (selectedTab.toLowerCase()) {
            case "brugerrettigheder":
                $([objTabItemPermissions, objTabDataPermissions]).addClass("current");
                break;
            case "gruppestyring":
                $([objTabItemGroups, objTabDataGroups]).addClass("current");
                break;
            case "brugeradministration":
                $([objTabItemUsers, objTabDataUsers]).addClass("current");
                break;
            default:
                _this.config.selectedtab = "brugerrettigheder";
                $([objTabItemPermissions, objTabDataPermissions]).addClass("current");
                break;
        }
        _this.config.selectedtab = selectedTab;

        $(objDiv).append(objDivTabsData);

        $("#ig-data").html(objDiv);

        IG.gui.loading.hide();

        $.each(_this.fnAfterRender, function(i, n) {
            if ($.isFunction(n)) {
                n();
            }
        });

        //create button click
        $("#ig-user-update").click(function() {
            var id = $("#ig-user-select").val();
            if (id == -1) {
                _this.fn.createUser();
            }
            else {
                _this.fn.updateUser(id);
            }
        });

        //users select change
        $("#ig-user-select").change(function() {
            $("#ig-users-users span").removeClass('ig-user-error');
            var id = $(this).val();
            if (id == -1) {
                //hide update button
                $("#ig-user-name").val('');
                $("#ig-user-middlename").val('');
                $("#ig-user-kodeord").val('');
                $("#ig-user-delete").hide();
                $("#ig-user-update span").text(IG.label.get("global_create"));
                $("#ig-user-title").text(IG.label.get("users_create_new_user"));
            }
            else {
                _this.config.selecteduser = id;
                $.address.value(_this.hash());
                var user = IG.getUserById(id);
                if (user) {
                    $("#ig-user-name").val(user.name);
                    $("#ig-user-middlename").val(user.userName);
                    $("#ig-user-kodeord").val(user.password);
                    $("#ig-user-delete").show();
                    $("#ig-user-update span").html(IG.label.get("global_update"));
                    $("#ig-user-title").text(IG.label.get("users_update_users"));
                }
            }
        });

        //check if we are updating a user
        var userId = typeof ($.address.parameter("user")) != "undefined" ? $.address.parameter("user").split('|')[0] : _this.config.selecteduser;
        if (userId != "") {
            var option = $("#ig-user-select option[value=" + userId + "]").attr("selected", "selected").trigger("change");
        }

        //delete user
        $("#ig-user-delete").click(function() {
            var _me = this;
            var item = IG.getUserById($("#ig-user-select").val());

            //Create new modal dialog:
            var objDialog = IG.createConfirmDialog({
                title: IG.label.get("users_delete_user_popup_title"),
                text: "",
                onConfirm: function() {
                    IG.remove.user({
                        id: item.id,
                        success: function() {
                            $("#ig-user-name").val('');
                            $("#ig-user-middlename").val('');
                            $("#ig-user-kodeord").val('');
                            $("#ig-user-delete").hide();
                            var elemSelect = $("#ig-user-select");
                            if (elemSelect && elemSelect.length > 0) {
                                elemSelect.find("option[value=" + item.id.id + "]").remove();
                                elemSelect.find('option[value=-1]').attr('selected', true);
                            }
                            objDialog.close();
                            _this.userUpdated = true;
                        },
                        error: function(err) {
                            objDialog.close();
                            IG.showErrorDialog(err);
                        }
                    });
                },
                target: $(_me)
            });
        });
    };

    this.refreshTabContent = function(bWrite) {
        if (_this.userUpdated) {
            IG.gui.loading.show();
            _this.IGObjectList.refresh();
            $("div#ig-users-groups div:first")
                    .replaceWith(_this.renderGroups(bWrite));
            IG.gui.loading.hide();
            _this.userUpdated = false;
        }
    };

    this.init = function() {
        _this.render();
        //$.address.value(_this.hash());
    }

};

IG.showEditUsers = function(options) {
    //FIXME: check if current user has permission to view this
    //var bList = IG.userHasPermission("OP_USER_LIST");
    //if (bList) {

    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);

    IG.gui.loading.show();

    //Create infoobject list if needed:
    var objUsers = _IG.objects.get("users");
    if (objUsers == null) {
        objUsers = _IG.objects.create("users", new _IG.Users());
        objUsers.init();
    }
    else {
        objUsers.refresh({ history: false });
    }


    IG.gui.setTitle(IG.label.get("users_headline"));

    //Add to history:
    if (options.history) {
        _IG.location.setCurrent(objUsers.hash());
    }

    $('#ig-user-menu-sections').find('a.users').parent().addClass('menu-active');
};
