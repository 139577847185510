IG.Profile = function() {
    var _this = this;
    this.item = null;

    this.itemHasChanged = false;
    this.elementsLang = 'da'; //default labels language

    this.hash = function() {
        var h = "profile";
        return h;
    };

    //Sets the currently loaded item
    this.setItem = function(item) {
        _this.item = item;
    };


    this.userPermissions = {
        'INFO_OBJECT': {
            'userCount' : 0,
            'limit' : 30,
            'offset' : 0
        },
        'CHANNEL': {
            'userCount' : 0,
            'limit' : 30,
            'offset' : 0
        },
        'GALLERY': {
            'userCount' : 0,
            'limit' : 30,
            'offset' : 0
        }
    };



    //Updates/saves the Profile:
    this.updateProfile = function(object) {
        var updatedProfileObject = (typeof object != "undefined" ? object : null);

        if (updatedProfileObject == null) {
            updatedProfileObject = $.extend(true, {}, _this.item);
        }

        //var loadingId = "loading-" + IG.fn.getTimestamp();
        var fnOnStart = function() {
            //IG.gui.loading.show($("#ig-profile"), loadingId, 1);
            IG.gui.loading.show();
        };

        var fnOnEnd = function() {
            //IG.gui.loading.hide(loadingId);
            IG.gui.loading.hide();
        };

        fnOnStart();

        IG.update.profile({
            object: updatedProfileObject,
            success: function(object) {
                fnOnEnd();

                //FIXME: really reload profile?
                IG.showEditProfile({
                    object: object,
                    history: false
                });
            },
            error: function(err) {
                IG.showErrorDialog(err);
                fnOnEnd();
            }
        });

    },

    //Updates password:
    this.updatePassword = function(objPwd1, objPwd2) {
        var p1 = $(objPwd1).val();
        var p2 = $(objPwd2).val();

        if (p1 == "" || p2 == "") {
            //Either is empty
            IG.showErrorDialog(IG.label.get("profile_retype_password"));
        }
        else if (p1 != p2) {
            //Not equal
            IG.showErrorDialog(IG.label.get("profile_password_missmatch"));
        }
        else {
            //OK
            var updatedProfileObject = $.extend(true, {}, _this.item);
            updatedProfileObject.password = p1;

            _this.updateProfile(updatedProfileObject);
        }
    },

    //Holds basic functions for adding/removing properties:
    this.fn = {

        //Check if the provided group/user with id has default create permission:
        hasDefaultCreatePermission: function(pType, pKey, id) {
            var b = false;

            if (typeof _this.item.defaultCreatePermissions != "undefined") {
                if (typeof _this.item.defaultCreatePermissions[pType] != "undefined") {
                    if (typeof _this.item.defaultCreatePermissions[pType][pKey] != "undefined") {
                        if ($.isArray(_this.item.defaultCreatePermissions[pType][pKey])) {
                            $.each(_this.item.defaultCreatePermissions[pType][pKey], function(i, n) {
                                if (n.id == id) {
                                    b = true;
                                    return false; //break loop;
                                }
                            });
                        }
                    }
                }
            }
            return b;
        },


        //Add default create permission for the given group/user:
        addDefaultCreatePermission: function(pType, pKey, id) {
            if (!_this.fn.hasDefaultCreatePermission(pType, pKey, id)) {

                if (typeof _this.item.defaultCreatePermissions == "undefined") {
                    _this.item.defaultCreatePermissions = {};
                }

                if (typeof _this.item.defaultCreatePermissions[pType] != "object") {
                    _this.item.defaultCreatePermissions[pType] = {};
                }

                if (!$.isArray(_this.item.defaultCreatePermissions[pType][pKey])) {
                    _this.item.defaultCreatePermissions[pType][pKey] = [];
                }

                _this.item.defaultCreatePermissions[pType][pKey].push({ "customerId": IG.config.customerId, "id": id });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },


        //Remove default create permission for the given group/user:
        removeDefaultCreatePermission: function(pType, pKey, id) {
            if (_this.fn.hasDefaultCreatePermission(pType, pKey, id)) {
                _this.item.defaultCreatePermissions[pType][pKey] = $.grep(_this.item.defaultCreatePermissions[pType][pKey], function(n, i) {
                    return n.id != id;
                });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        }
    };




    this.loadProfile = function(obj, cb) {
        _this.setItem(obj);
        cb();
    };

    //Fetch the full Profile object:
    this.loadProfileById = function(id, fnSuccess, fnError) {
        var filterOpts = { ids:
		[
			{
			    id: id,
			    customerId: IG.userConfig.userId.customerId
			}
		]
        };

        IG.request({
            method: "searchUsers",
            params: [IG.config.language, "", false, filterOpts, {}, 1, 0],
            success: function(response) {
                if (response.result) {
                    var responsedata = response.data["data"];
                    var retObj = responsedata[0];

                    if ((typeof retObj != "undefined") && retObj != null) {
                        _this.setItem(retObj);
                        fnSuccess();
                    }
                    else {
                        if ($.isFunction(fnError)) {
                            fnError(IG.label.get("profile_unknown"));
                        }
                    }
                }
                else {
                    if ($.isFunction(fnError)) {
                        fnError(IG.label.get("profile_error_retreiving"));
                    }
                }
            },
            error: function(err) {
                if ($.isFunction(fnError)) {
                    fnError(IG.label.get("profile_error_retreiving") + err);
                }
            }
        });
    };



    this.renderBasic = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-two-columns clearfix");

        if (bWrite) {

            var objDivPassword = document.createElement("div");
            $(objDivPassword)
                .addClass("ig-column-left")
                .appendTo(objDiv);

            var objTitle = document.createElement("h3");
            $(objTitle)
                .addClass("ig-title")
                .text(IG.label.get("profile_password")) 
                .appendTo(objDivPassword);


            var s = ''
                + '<table>'
                + '<tbody>'
                + '<tr>'
                + '<td class="lbl"><label for="ig-profile-newpassword-1">' + IG.label.get("profile_enter_new_password") + ':</label></td>' 
                + '<td><span class="input"><input type="password" name="ig-profile-newpassword-1" id="ig-profile-newpassword-1" class="input-text" /></span></td>'
                + '</tr>'
                + '<tr>'
                + '<td class="lbl"><label for="ig-profile-newpassword-2">' + IG.label.get("profile_enter_new_password_again") + ':</label></td>' 
                + '<td><span class="input"><input type="password" name="ig-profile-newpassword-2" id="ig-profile-newpassword-2" class="input-text" /></span></td>'
                + '</tr>'
                + '</tbody>'
                + '</table>';

            $(objDivPassword).append(s);

            var objPwd1 = $(objDivPassword).find("#ig-profile-newpassword-1").get(0);
            var objPwd2 = $(objDivPassword).find("#ig-profile-newpassword-2").get(0);

            var objDivButtons = document.createElement("div");
            $(objDivButtons)
                .addClass("buttons")
                .appendTo(objDivPassword);

            var btnChange = document.createElement("a");
            $(btnChange)
                .addClass("btn-normal btn-pink")
                .html('<span>' + IG.label.get("global_change") + '</span>')
                .click(function() {
                    _this.updatePassword(objPwd1, objPwd2);
                    return false;
                })
                .appendTo(objDivPassword);
        }

        return objDiv;
    };



    //Builds InfoObject permissions settings:
    this.renderSettingsInfoObjects = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("right_menu_info_objects"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content ig-two-columns clearfix")
            .appendTo(objDiv);

        var objDivLeftColumn = document.createElement("div");
        $(objDivLeftColumn)
            .appendTo(objDivContent);

        var objDivLeftColumnContent = document.createElement("div");
        $(objDivLeftColumnContent)
            .addClass("ig-column-left")
            .appendTo(objDivLeftColumn);

        var IGInfoObjectPermissionsGroups = new IG.PermissionsBox({
            type: "groups",
            key: "INFO_OBJECT",
            title: IG.label.get("edit_infoobject_permissions_groups"),
            items: IG.lists.groups,
            permissions: _this.item.defaultCreatePermissions.groups,
            write: bWrite,
            fnAdd: _this.fn.addDefaultCreatePermission,
            fnRemove: _this.fn.removeDefaultCreatePermission
        });

        $(objDivLeftColumnContent).append(IGInfoObjectPermissionsGroups.render());


        var objDivRightColumn = document.createElement("div");
        $(objDivRightColumn)
            .appendTo(objDivContent);

        var objDivRightColumnContent = document.createElement("div");
        $(objDivRightColumnContent)
            .addClass("ig-column-right")
            .appendTo(objDivRightColumn);

        IG.users.loadEnabled(_this.userPermissions['INFO_OBJECT'], function(userData) {
            if (userData) {
                var IGInfoObjectPermissionsUsers = new IG.PermissionsBox({
                    type: "users",
                    key: "INFO_OBJECT",
                    title: IG.label.get("edit_infoobject_permissions_users"),
                    items: userData.data,
                    count: _this.userPermissions['INFO_OBJECT'].count,
                    permissions: _this.item.defaultCreatePermissions.users,
                    write: bWrite,
                    fnAdd: _this.fn.addDefaultCreatePermission,
                    fnRemove: _this.fn.removeDefaultCreatePermission,
                    fnLoadMore: function(callback) {
                        _this.userPermissions['INFO_OBJECT'].offset += _this.userPermissions['INFO_OBJECT'].limit;

                        IG.users.loadEnabled(_this.userPermissions['INFO_OBJECT'], function(userData) {
                            if ($.isFunction(callback)) {
                                callback(userData.data);
                            }
                        });
                    }
                });

                $(objDivRightColumnContent).append(IGInfoObjectPermissionsUsers.render());
            }
        });

        return objDiv;
    };


    //Builds channels permissions settings:
    this.renderSettingsChannels = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("right_menu_channels"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content ig-two-columns clearfix")
            .appendTo(objDiv);

        var objDivLeftColumn = document.createElement("div");
        $(objDivLeftColumn)
            .appendTo(objDivContent);

        var objDivLeftColumnContent = document.createElement("div");
        $(objDivLeftColumnContent)
            .addClass("ig-column-left")
            .appendTo(objDivLeftColumn);

        var IGInfoObjectPermissionsGroups = new IG.PermissionsBox({
            type: "groups",
            key: "CHANNEL",
            title: IG.label.get("edit_infoobject_permissions_groups"),
            items: IG.lists.groups,
            permissions: _this.item.defaultCreatePermissions.groups,
            write: bWrite,
            fnAdd: _this.fn.addDefaultCreatePermission,
            fnRemove: _this.fn.removeDefaultCreatePermission
        });

        $(objDivLeftColumnContent).append(IGInfoObjectPermissionsGroups.render());


        var objDivRightColumn = document.createElement("div");
        $(objDivRightColumn)
            .appendTo(objDivContent);

        var objDivRightColumnContent = document.createElement("div");
        $(objDivRightColumnContent)
            .addClass("ig-column-right")
            .appendTo(objDivRightColumn);

        IG.users.loadEnabled(_this.userPermissions['CHANNEL'], function(userData) {
            if (userData) {
                var IGInfoObjectPermissionsUsers = new IG.PermissionsBox({
                    type: "users",
                    key: "CHANNEL",
                    title: IG.label.get("edit_infoobject_permissions_users"),
                    items: userData.data,
                    count: _this.userPermissions['CHANNEL'].count,
                    permissions: _this.item.defaultCreatePermissions.users,
                    write: bWrite,
                    fnAdd: _this.fn.addDefaultCreatePermission,
                    fnRemove: _this.fn.removeDefaultCreatePermission,
                    fnLoadMore: function(callback) {
                        _this.userPermissions['CHANNEL'].offset += _this.userPermissions['CHANNEL'].limit;

                        IG.users.loadEnabled(_this.userPermissions['CHANNEL'], function(userData) {
                            if ($.isFunction(callback)) {
                                callback(userData.data);
                            }
                        });
                    }
                });

                $(objDivRightColumnContent).append(IGInfoObjectPermissionsUsers.render());
            }
        });



        return objDiv;
    };


    //Builds channels permissions settings:
    this.renderSettingsGalleries = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("right_menu_galleries"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content ig-two-columns clearfix")
            .appendTo(objDiv);

        var objDivLeftColumn = document.createElement("div");
        $(objDivLeftColumn)
            .appendTo(objDivContent);

        var objDivLeftColumnContent = document.createElement("div");
        $(objDivLeftColumnContent)
            .addClass("ig-column-left")
            .appendTo(objDivLeftColumn);

        var IGInfoObjectPermissionsGroups = new IG.PermissionsBox({
            type: "groups",
            key: "GALLERY",
            title: IG.label.get("edit_infoobject_permissions_groups"),
            items: IG.lists.groups,
            permissions: _this.item.defaultCreatePermissions.groups,
            write: bWrite,
            fnAdd: _this.fn.addDefaultCreatePermission,
            fnRemove: _this.fn.removeDefaultCreatePermission
        });

        $(objDivLeftColumnContent).append(IGInfoObjectPermissionsGroups.render());


        var objDivRightColumn = document.createElement("div");
        $(objDivRightColumn)
            .appendTo(objDivContent);

        var objDivRightColumnContent = document.createElement("div");
        $(objDivRightColumnContent)
            .addClass("ig-column-right")
            .appendTo(objDivRightColumn);


        IG.users.loadEnabled(_this.userPermissions['GALLERY'], function(userData) {
            if (userData) {
                var IGInfoObjectPermissionsUsers = new IG.PermissionsBox({
                    type: "users",
                    key: "GALLERY",
                    title: IG.label.get("edit_infoobject_permissions_users"),
                    items: userData.data,
                    count: _this.userPermissions['GALLERY'].count,
                    permissions: _this.item.defaultCreatePermissions.users,
                    write: bWrite,
                    fnAdd: _this.fn.addDefaultCreatePermission,
                    fnRemove: _this.fn.removeDefaultCreatePermission,
                    fnLoadMore: function(callback) {
                        _this.userPermissions['GALLERY'].offset += _this.userPermissions['GALLERY'].limit;

                        IG.users.loadEnabled(_this.userPermissions['GALLERY'], function(userData) {
                            if ($.isFunction(callback)) {
                                callback(userData.data);
                            }
                        });
                    }
                });

                $(objDivRightColumnContent).append(IGInfoObjectPermissionsUsers.render());
            }
        });

        return objDiv;
    };


    this.renderSettings = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass('clearfix');


        var objDivSettings = document.createElement("div");
        $(objDivSettings)
            .appendTo(objDiv);


        var objTitle = document.createElement("h3");
        $(objTitle)
        .addClass("ig-title")
        .text(IG.label.get("profile_access_settings"))
        .appendTo(objDivSettings);


        $(objDivSettings)
            .append(_this.renderSettingsInfoObjects(bWrite))
            .append(_this.renderSettingsChannels(bWrite))
            .append(_this.renderSettingsGalleries(bWrite))
            ;



        if (bWrite) {
            var objDivButtons = document.createElement("div");
            $(objDivButtons)
            .addClass("buttons")
            .appendTo(objDivSettings);

            var btnSave = document.createElement("a");
            $(btnSave)
            .addClass("btn-normal btn-pink")
            .html('<span>' + IG.label.get("global_save") + '</span>')
            .click(function() {
                _this.updateProfile();
                return false;
            })
            .appendTo(objDivButtons);
        }


        return objDiv;
    };

    this.render = function(cb) {
        var bWrite = (IG.userConfig.userId["id"] == _this.item.id["id"]);


        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "ig-profile");

        //Create header:
        var objDivHeader = document.createElement("div");
        $(objDivHeader)
            .attr("id", "ig-profile-header")
            .appendTo(objDiv);

        //Create headline:
        var objH2 = document.createElement("h2");
        $(objH2)
            .text(IG.label.get("profile_edit_profile"))
            .appendTo(objDivHeader);


        //Build tabs bar:
        var objTabs = document.createElement("div");
        $(objTabs)
            .addClass("ig-tabs")
            .attr("id", "ig-profile-tabs")
            .appendTo(objDiv);

        var objTabsContainer = document.createElement("span");
        $(objTabsContainer)
            .addClass("ig-tabs-content")
            .attr("id", "ig-profile-tabs-content")
            .appendTo(objTabs);

        var objTabItemBasic = document.createElement("a");
        $(objTabItemBasic)
            .html('<span>' + IG.label.get("profile_tab_basic_information") + '</span>')
            .appendTo(objTabsContainer);

        var objTabItemSettings = document.createElement("a");
        $(objTabItemSettings)
            .html('<span>' + IG.label.get("profile_tab_setttings") + '</span>')
            .appendTo(objTabsContainer);



        //Build tabs data items:
        var objDivTabsData = document.createElement("div");
        $(objDivTabsData).addClass("ig-tabs-data");


        var objTabDataBasic = document.createElement("div");
        $(objTabDataBasic)
            .attr("id", "ig-profile-basic")
            .addClass("ig-tabs-data-item")
            .append(_this.renderBasic(bWrite))
            .appendTo(objDivTabsData);

        var objTabDataSettings = document.createElement("div");
        $(objTabDataSettings)
            .attr("id", "ig-profile-settings")
            .addClass("ig-tabs-data-item")
            .append(_this.renderSettings(bWrite))
            .appendTo(objDivTabsData);


        $([objTabItemSettings, objTabDataSettings]).addClass("current");




        $(objTabItemBasic).click(function() {
            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataBasic;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
        });

        $(objTabItemSettings).click(function() {
            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataSettings;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
        });



        $(objDiv).append(objDivTabsData);

        $("#ig-data").html(objDiv);
        IG.gui.loading.hide();

        if ($.isFunction(cb)) {
            cb();
        }
    };


    this.init = function(options) {
        options.id = (typeof options.id != "undefined" ? options.id : null);
        options.object = (typeof options.object != "undefined" ? options.object : null);

        $.each(_this.userPermissions, function(key, permObjData) {
            permObjData.count = 0;
            permObjData.offset = 0;
        });

        //What to do when done loading object:
        var fnSuccess = function() {
            _this.render(function() {

            });
        };

        var fnError = function(err) {
            if (typeof err == "string") {
                IG.showErrorDialog(err, function() {
                    //Go to root/dashboard:
                    IG.location.front();
                });
            }
            IG.gui.loading.hide();
        };


        //Got a full object - use this:
        if (options.object != null) {
            _this.loadProfile(options.object, fnSuccess);
        }
        //Else load by provided id:
        else {
            _this.loadProfileById(options.id, fnSuccess, fnError);
        }


    };

    return this;
};

IG.showEditProfile = function(options) {
    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);
    options.object = (typeof options.object != "undefined" ? options.object : null);
    options.id = (typeof options.id != "undefined" ? options.id : null);

    var objEditProfileObject = _IG.objects.get("profile");
    if (objEditProfileObject == null) {
        objEditProfileObject = _IG.objects.create("profile", new _IG.Profile());
    }


    //Parse url hash params if no id or object provided in options:
    if (options.id == null && options.object == null) {
        var urlParams = (typeof $.address.parameter(objEditProfileObject.hash()) != 'undefined' ? $.address.parameter(objEditProfileObject.hash()).split('|') : '');
        if (urlParams.length > 0) {
            var urlId = urlParams[0];

            if (urlId != "") {
                options.id = urlId;
            }
        }
    }

    IG.gui.setTitle(IG.label.get("profile_edit_profile"));

    //Determine options to send to init function:
    var loadOptions = {};

    if (options.object != null) {
        loadOptions.object = options.object;
    }
    else {
        loadOptions.id = options.id;
    }


    //Add history state?
    if (options.history) {
        var h = objEditProfileObject.hash();
        h += "?" + h + "=" + (options.object != null ? options.object.id["id"] : options.id);
        _IG.location.setCurrent(h);
    }

    IG.gui.loading.show();
    objEditProfileObject.init(loadOptions);

    $('.menu-user').addClass('menu-active');
};






//Displays password reset dialog (checks provided token)
IG.showPasswordResetDialog = function() {
    //Check provided token:
    var token = $.address.parameter("token");
    token = (typeof token == "string" ? token : "");

    if (token != "") {

        //Prepare dialog content:
        var fnBuildDialogContent = function(bSuccess) {
            var objDiv = document.createElement("div");
            $(objDiv).addClass("password-reset");

            var objText = document.createElement("p");
            $(objText).appendTo(objDiv);

            //Password was reset:
            if (bSuccess) {
                $(objText).text(IG.label.get("profile_reset_password"));
            }
            //Password was not reset:
            else {
                $(objText).text(IG.label.get("profile_reset_password_failed"));
            }

            return objDiv;
        };

        //Send request:
        IG.request({
            method: "resetPassword",
            params: [IG.config.customerId, IG.config.language, token],
            success: function(response) {
                $( "#modal-window-dialog" )
                    .html(fnBuildDialogContent(response.result))
                    .dialog({
                        modal: true,
                        dialogClass: "no-close",
                        title: IG.label.get("profile_reset_password_dialog_title"),
                        position: { my: "center", at: "center", of: window },
                        width: 340,
                        height: 'auto',
                        buttons: [
                            {
                                text: IG.label.get("ok"),
                                click: function() {
                                    $(this).dialog('close');
                                    IG.location.setCurrent(null); // important
                                    IG.location.front();
                                }
                            }
                        ],

                        // reset to defaults
                        minWidth: 150,
                        maxWidth: false,
                        minHeight: 150,
                        maxHeight: false
                    });
            },
            error: function(err) {
                IG.showErrorDialog(err);
            }
        });

    }
    else {
        IG.location.front();
    }
};