
IG.Gallery = function() {
    var _this = this;

    var existingChannelsList = null;

    var nullString = '__null__';

    this.item = null;

    this.isNew = false;
    this.itemHasChanged = false;
    this.elementsLang = IG.config.language; // 'da'; //default labels language
    this.searchLang = IG.customerConfig.defaultContentLanguage;

    _this.mediaArchiveHeight = 600;

    //this.infoObjects = []; //currently shown infoObjects (loaded by item.infoObjectIds)

    this.fnAfterRender = [];

    this.hash = function() {
        return "gallery";
    };

    //Sets the currently loaded item
    this.setItem = function(item) {
        _this.fnAfterRender = [];
        _this.item = item;
    };


    this.userPermissions = {
        'userCount' : 0,
        'limit' : 30,
        'offset' : 0
    };

    var blacklist = {
        data: {},
        add: function(id) {
            if (typeof this.data[id] === 'undefined') {
                this.data[id] = id;
            }
        },
        remove: function(id) {
            if (typeof this.data[id] !== 'undefined') {
                delete this.data[id];
            }
        },
        exists: function(id) {
            return (typeof this.data[id] !== 'undefined');
        },
        empty: function() {
            this.data = {};
        }
    };

    //Updates/saves the gallery:
    this.updateGallery = function(options) {
        options = (typeof options != "undefined" ? options : {});
        options.success = (typeof options.success == "function" ? options.success : function() { });
        options.error = (typeof options.error == "function" ? options.error : function() { });

        var updatedGalleryObject = $.extend(true, {}, _this.item);
        if (_this.isNew) {
            delete updatedGalleryObject.id;
        }

        var fnOnStart = function() {
            IG.gui.loading.show();
        };

        var fnOnEnd = function() {
            IG.gui.loading.hide();
        };

        fnOnStart();

        IG.update.gallery({
            object: updatedGalleryObject,
            success: function(object) {
                _this.item = object;
                fnOnEnd();
                if (_this.isNew) {
                    IG.lists.galleries[object.id.id] = object;
                    IG.showEditGallery({
                        object: object,
                        isNew: false,
                        searchLang: _this.elementsLang
                    });
                } else {
                    $("#content-gallery-title").text(IG.label.get("gallery_content_title") + ' "' + _this.item.name[_this.elementsLang] + '"');

                    // update the list
                    if (existingChannelsList !== null) {
                        existingChannelsList.setOrderByList(_this.item.channelIds);
                    }

                    options.success(object);
                }
            },
            error: function(err) {
                fnOnEnd();
                IG.showErrorDialog("Error saving: " + err);
                options.error();
            }
        });

    };

    //Holds basic functions for adding/removing properties
    this.fn = {
        //Check if permission exists:
        hasPermission: function(pType, pKey, id) {
            var b = false;
            if (typeof _this.item.permissions[pType][pKey] !== "undefined") {
                $.each(_this.item.permissions[pType][pKey], function(i, n) {
                    if (n.id == id) {
                        b = true;
                        return false; //break loop;
                    }
                });
            }
            return b;
        },
        //Adds a permission:
        addPermission: function(pType, pKey, id) {
            if (!_this.fn.hasPermission(pType, pKey, id)) {
                if (typeof _this.item.permissions[pType][pKey] === "undefined") {
                    _this.item.permissions[pType][pKey] = [];
                }
                _this.item.permissions[pType][pKey].push({ "customerId": IG.config.customerId, "id": id });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },
        //Removes a permission:
        removePermission: function(pType, pKey, id) {
            if (_this.fn.hasPermission(pType, pKey, id)) {
                _this.item.permissions[pType][pKey] = $.grep(_this.item.permissions[pType][pKey], function(n, i) {
                    return n.id != id;
                });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },

        //Sets the name text for the given language:
        setName: function(lang, str) {
            _this.item.name[lang] = str;
            _this.itemHasChanged = true;
        },

        //Sets the description text
        setDescription: function(lang, str) {
            _this.item.description[lang] = str;
            _this.itemHasChanged = true;
        },

        //Sets the title text
        setTitle: function(lang, str) {
            _this.item.title[lang] = str;
            _this.itemHasChanged = true;
        },

        //Sets item to interactive or not:
        setInteractive: function(b) {
            _this.item.interactive = b;
            _this.itemHasChanged = true;
        },

        setTouchscreen: function(b) {
            _this.item.touchscreen = b;
            _this.itemHasChanged = true;
        },

        //Gets the module given string id:
        getModuleById: function(id) {
            var res = null;

            if ($.isArray(IG.lists.modules)) {
                $.each(IG.lists.modules, function(i, n) {
                    if (IG.getId(n.id) == id) {
                        res = n;
                        return false; //break loop
                    }
                });
            }
            return res;
        },

        //Sets module id:
        setModuleId: function(id) {
            if (id === nullString) {
                _this.item.moduleId = null;
                return;
            }

            var moduleId = null;
            if (typeof id == "string") {
                var module = _this.fn.getModuleById(id);
                if (module != null) {
                    moduleId = module.id;
                }
            }
            else if (typeof id == "object") {
                moduleId = id;
            }

            if (moduleId != null) {
                _this.item.moduleId = moduleId;
            }
        },


        getSkinById: function(id) {
            var res = null;
            id = IG.getId(id);

            if ($.isArray(IG.lists.skins)) {
                $.each(IG.lists.skins, function(i, n) {
                    if (IG.getId(n.id) == id) {
                        res = n;
                        return false; //break loop
                    }
                });
            }

            return res;
        },

        //Sets skin id:
        setSkinId: function(id) {
            if (id === nullString) {
                _this.item.skinId = null;
                return;
            }

            var skinId = null;
            if (typeof id == "string") {
                var skin = _this.fn.getSkinById(id);
                if (skin != null) {
                    skinId = skin.id;
                }
            } else if (typeof id == "object") {
                skinId = id;
            }

            if (skinId != null) {
                _this.item.skinId = skinId;
            }
        },

        //Sets item rss enabled property:
        setRssEnabled: function(b) {
            _this.item.rssEnabled = b;
            _this.itemHasChanged = true;
        },
        //Sets RSS url for item:
        setRssUrl: function(s) {
            _this.item.rssUrl = s;
            _this.itemHasChanged = true;
        },
        //Sets human readable name for RSS:
        setRssName: function(s) {
            _this.item.rssName = s;
            _this.itemHasChanged = true;
        },


        //Check if the gallery has the channel, given id:
        hasChannelId: function(id) {
            var bFound = false;
            if ($.isArray(_this.item.channelIds)) {
                $.each(_this.item.channelIds, function(i, n) {
                    if (IG.IdsEqual(id, n)) {
                        bFound = true;
                        return false; //break loop
                    }
                });
            }
            return bFound;
        },

        //Add a channel id:
        addChannelId: function(id) {
            if (!_this.fn.hasChannelId(id)) {
                if (!$.isArray(_this.item.channelIds)) {
                    _this.item.channelIds = [];
                }
                _this.item.channelIds.push(id);
                _this.itemHasChanged = true;
                // update the list
                if (existingChannelsList !== null) {
                    existingChannelsList.setOrderByList(_this.item.channelIds);
                }
                return true;
            }
            return false;
        },

        //Remove a channel id:
        removeChannelId: function(id) {
            if (_this.fn.hasChannelId(id)) {
                _this.item.channelIds = $.grep(_this.item.channelIds, function(n, i) {
                    return !IG.IdsEqual(id, n);
                });
                _this.itemHasChanged = true;
                // update the list
                if (existingChannelsList !== null) {
                    existingChannelsList.setOrderByList(_this.item.channelIds);
                }
                return true;
            }
            return false;
        },

        //Resorts the channel ids according to provided list of ids:
        sortChannelIds: function(ids) {
            _this.item.channelIds = IG.fn.sortIds(ids, _this.item.channelIds);
            _this.itemHasChanged = true;
        },


        fnAddChannel: function(object) {
            if (_this.fn.addChannelId(object.id)) {
                blacklist.add(object.id.id);

                existingChannelsList.appendRow(object, true);
            }
        },



        renderAddGalleryBtn: function(object, objContainer, bWrite) {
            $(objContainer).html('');

            var objEm = document.createElement('em');
            $(objEm)
                .addClass('ellipsis ig-list-item-message');

            if (bWrite && !blacklist.exists(object.id.id)) {
                var objA = document.createElement("a");
                $(objA)
                    .addClass("btn-small btn-dark")
                    .html('<span>' + IG.label.get("global_add") + '</span>')
                    .click(function() {
                        _this.fn.fnAddChannel(object);
                        _this.updateGallery();

                        $(this).hide(); // hide the button
                        $(objEm)
                            .attr('title', IG.label.get('LIST_ITEM_ADDED'))
                            .text(IG.label.get('LIST_ITEM_ADDED'));
                        $(objContainer)
                            .html(objEm);

                        return false;
                    })
                    .appendTo(objContainer);

            } else if (!bWrite) {
                $(objEm)
                    .attr('title', IG.label.get('LIST_ITEM_NO_WRITE_ACCESS'))
                    .text(IG.label.get('LIST_ITEM_NO_WRITE_ACCESS'));
                $(objContainer)
                    .html(objEm);
            } else {
                $(objEm)
                    .attr('title', IG.label.get('LIST_ITEM_ADDED'))
                    .text(IG.label.get('LIST_ITEM_ADDED'));
                $(objContainer)
                    .html(objEm);
            }
        }
    };

    this.removeItemChannelsFromList = function(objList) {
        $.each(_this.item.channelIds, function(i, c) {
            objList.removeRow(c);
        });
    };

    //Builds the save button, if user has permission:
    this.renderButtons = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("ig-edit-section buttons");

        var btnSave = document.createElement("a");
        $(btnSave)
            .addClass("btn-normal btn-dark")
            .click(function() {
                _this.updateGallery({
                success: function(object) {
                        //Stay on editpage
                        /*IG.showEditGallery({
                            object: object,
                            history: false,
                            searchLang: _this.elementsLang
                        });*/
                    }
                });
                return false;
            })
            .html('<span>' + IG.label.get("global_save") + '</span>')
            .appendTo(objDiv);

        return objDiv;
    };


    //Holds references to IG.TextField objects
    this.textFields = [];



    //Renders the language selector/toggler:
    this.renderLanguageSelector = function() {
        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("ig-languages")
            .attr("id", "ig-gallery-languages")
            .attr("style", "display: none;");

        //Toggle language (ie. visible text fields):
        var fnToggleLanguage = function(key) {
            _this.elementsLang = key;


            //Text fields:
            $.each(_this.textFields, function(i, n) {
                n.setLanguage(key);
            });


            var arrLangDep = $("#ig-gallery span.language-dependent");
            if (arrLangDep.length > 0) {
                $.each(arrLangDep, function(i, n) {
                    if ($(n).hasClass(key)) {
                        $(n).css("display", "block");
                    }
                    else {
                        $(n).css("display", "none");
                    }
                });
            }

            // Change the shown channel title:
            $("#content-gallery-title").text(IG.label.get("gallery_content_title") + ' "' + _this.item.name[_this.elementsLang] + '"');
            
            // Reload associated channels:
            if (_this.item.channelIds.length > 0) {
                _this.existingChannelsList.init();
            }
        };

        //Set click event for each language:	  
        if (IG.fn.objSize(IG.customerConfig.contentLanguages) > 1) {
            $.each(IG.customerConfig.contentLanguages, function(key, name) {
                var objSpan = document.createElement("span");
                $(objSpan)
	                .addClass("ig-language " + key)
	                .attr("title", name)
	                .click(function() {
	                    fnToggleLanguage(key);
	                    $(this).addClass("current");
	                    $(this).siblings("span").removeClass("current");
	                })
	                .appendTo(objDiv);

                if (key == _this.elementsLang) {
                    $(objSpan).addClass("current");
                }
            });
        }

        return objDiv;
    };
    
    this.showLanguageSelector = function() {
        $('#ig-gallery-languages').show();
    };
    
    this.hideLanguageSelector = function() {
        $('#ig-gallery-languages').hide();
    };


    //Renders gallery name fields:
    this.renderGalleryName = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-subsection");

        var objLbl = document.createElement("div");
        $(objLbl)
            .addClass("lbl")
            .text(IG.label.get("gallery_name"))
            .appendTo(objDiv);

        var IGTextField = new IG.TextField({
            id: "gallery-name",
            texts: _this.item.name,
            language: _this.elementsLang,
            readonly: !bWrite,
            onchange: function(language, s) {
                _this.fn.setName(language, s);
            }
        });

        _this.textFields.push(IGTextField);
        $(objDiv).append(IGTextField.render());

        return objDiv;
    };

    //Renders gallery description fields:
    this.renderGalleryDescription = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-subsection");

        var objLbl = document.createElement("div");
        $(objLbl)
                .addClass("lbl")
                .text(IG.label.get("gallery_description"))
                .appendTo(objDiv);

        var IGTextField = new IG.TextField({
            id: "gallery-description",
            texts: _this.item.description,
            language: _this.elementsLang,
            readonly: !bWrite,
            onchange: function(language, s) {
                _this.fn.setDescription(language, s);
            }
        });

        _this.textFields.push(IGTextField);
        $(objDiv).append(IGTextField.render());

        return objDiv;
    };

    this.renderGalleryTitle = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-subsection ig-divided");

        var objLbl = document.createElement("div");
        $(objLbl)
            .addClass("lbl")
            .text(IG.label.get("gallery_title"))
            .appendTo(objDiv);

        var IGTextField = new IG.TextField({
            id: "gallery-title",
            texts: _this.item.title,
            language: _this.elementsLang,
            readonly: !bWrite,
            onchange: function(language, s) {
                _this.fn.setTitle(language, s);
            }
        });

        _this.textFields.push(IGTextField);
        $(objDiv).append(IGTextField.render());

        return objDiv;
    };

    //Renders gallery type checkboxes:
    this.renderGalleryType = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-subsection ig-divided");

        var objInteractive = document.createElement("div");
        $(objInteractive)
            .appendTo(objDiv);

        var objInteractiveInput = document.createElement("input");
        $(objInteractiveInput)
            .attr({
                "type": "checkbox",
                "name": "gallery-interactive",
                "id": "gallery-interactive"
            })
            .appendTo(objInteractive);

        if (!bWrite) {
            $(objInteractiveInput)
                .prop("disabled", true);
        }

        var objInteractiveLabel = document.createElement("label");
        $(objInteractiveLabel)
            .attr("for", "gallery-interactive")
            .text(IG.label.get("gallery_interactive"))
            .appendTo(objInteractive);

        var objTouchScreen = document.createElement("div");
        $(objTouchScreen)
            .appendTo(objDiv);

        var objTouchScreenInput = document.createElement("input");
        $(objTouchScreenInput)
            .attr({
                "type": "checkbox",
                "name": "gallery-touchscreen",
                "id": "gallery-touchscreen"
            })
            .appendTo(objTouchScreen);

        if (!bWrite) {
            $(objTouchScreenInput)
                .prop("readonly", true);
        }

        var objTouchScreenLabel = document.createElement("label");
        $(objTouchScreenLabel)
            .attr("for", "gallery-touchscreen")
            .text(IG.label.get("gallery_touchscreen"))
            .appendTo(objTouchScreen);

        if (_this.item.interactive) {
            $(objInteractiveInput).prop("checked", true);
            if (_this.item.touchscreen) {
                $(objTouchScreenInput).prop("checked", true);
            }
        } else {
            $(objTouchScreenInput)
                .hide();
            $(objTouchScreenLabel)
                .hide();
        }

        $(objTouchScreenInput).prop("checked", !!_this.item.touchscreen);

        if (bWrite) {
            $(objInteractiveInput).change(function() {
                if ($(this).prop("checked")) {
                    _this.fn.setInteractive(true);
                    $(objTouchScreenInput)
                        .show();
                    $(objTouchScreenLabel)
                        .show();
                } else {
                    _this.fn.setInteractive(false);
                    _this.fn.setTouchscreen(false);
                    $(objTouchScreenInput)
                        .prop("checked", false)
                        .hide();
                    $(objTouchScreenLabel)
                        .hide();
                }
            });

            $(objTouchScreenInput)
                .click(function() {
                    if ($(this).prop("checked")) {
                        _this.fn.setTouchscreen(true);
                    } else {
                        _this.fn.setTouchscreen(false);
                    }
                });
        }


        return objDiv;
    };


    //Renders gallery module and looks:
    this.renderGalleryModule = function(bWrite) {
        var objDiv = document.createElement("div");
        var defaultModule = null; // a fallback when no module was previously chosen

        $(objDiv)
            .attr("id", "ig-gallery-modules-skins")
            .addClass("ig-edit-subsection ig-divided");
        
        if (!$.isArray(IG.lists.modules) || IG.lists.modules.length == 0) {
            var ele = document.createElement("div");
            $(ele)
                .html(IG.label.get("gallery_missing_modules"))
                .appendTo(objDiv);
        } else if (!$.isArray(IG.lists.skins) || IG.lists.skins.length == 0) {
            var ele = document.createElement("div");
            $(ele)
                .html(IG.label.get("gallery_missing_skins"))
                .appendTo(objDiv);
        } else {
            /*if (_this.isNew) {
                _this.fn.setModuleId(IG.lists.modules[0].id);
                if (IG.lists.modules[0].skinIds.length > 0) {
                    _this.fn.setSkinId(IG.lists.modules[0].skinIds[0]);
                }
            }*/

            var objLabelModules = document.createElement("label");
            $(objLabelModules)
                .attr("for", "ig-gallery-modules")
                .text(IG.label.get("gallery_module"))
                .appendTo(objDiv);

            var objSelectModules = document.createElement("select");
            $(objSelectModules)
                .attr("id", "ig-gallery-modules")
                .appendTo(objDiv)
                .addClass('hubber-select')
                .wrap('<span class="input-wrap"></span>');

            var objLabelSkins = document.createElement("label");
            $(objLabelSkins)
                .attr("for", "ig-gallery-skins")
                .text(IG.label.get("gallery_skins"))
                .appendTo(objDiv);
            
            var objSelectSkins = document.createElement("select");
            $(objSelectSkins)
                .attr("id", "ig-gallery-skins")
                .appendTo(objDiv)
                .addClass('hubber-select btn-space-b')
                .wrap('<span class="input-wrap"></span>');


            var fnPopulateSkins = function(moduleId) {
                var hasSkins = false;

                $(objSelectSkins).empty();
                //Given the id, fetch the module:
                if (moduleId != null) {
                    var module = _this.fn.getModuleById(IG.getId(moduleId));
                    if (module == null && $.isArray(IG.lists.modules) && IG.lists.modules.length > 0) {
                        module = IG.lists.modules[0];
                    }

                    if (module != null) {
                        //Fetch skins associated with module:
                        if ($.isArray(module.skinIds)) {
                            $.each(module.skinIds, function(i, n) {
                                //Verify it is a legal skin:
                                var skin = _this.fn.getSkinById(n);
                                if (skin != null) {
                                    //Add the option:
                                    var objOption = document.createElement("option");
                                    $(objOption)
                                        .text(skin.name)
                                        .val(IG.getId(skin.id))
                                        .appendTo(objSelectSkins);

                                    if (typeof _this.item.skinId != "undefined") {
                                        if (_this.item.skinId != null) {
                                            if (IG.getId(_this.item.skinId) == IG.getId(skin.id)) {
                                                $(objOption).attr("selected", "selected");
                                            }
                                        }
                                    }
                                    hasSkins = true;
                                }
                            });
                            if ($(objSelectSkins).children('option').length == 0) {
                                $(objLabelSkins).text(IG.label.get("gallery_skins") + " " + IG.label.get('gallery_missing_mod_skin_association'));
                            } else {
                                $(objLabelSkins).text(IG.label.get("gallery_skins"));
                            }
                        }
                    }
                }

                if (!hasSkins) {
                    var objNoSkinsOption = document.createElement("option");
                    $(objNoSkinsOption)
                        .text(IG.label.get('no_gallery_skin'))
                        .val(nullString)
                        .appendTo(objSelectSkins)
                        .attr("selected", "selected");
                }
            };

            var fnPopulateModules = function() {
                var objNoModuleOption = document.createElement("option");
                $(objNoModuleOption)
                    .text(IG.label.get('no_module'))
                    .val(nullString)
                    .appendTo(objSelectModules);

                if (_this.item.moduleId === null) {
                    $(objNoModuleOption).attr("selected", "selected");
                }

                $.each(IG.lists.modules, function(i, n) {
                    var objOption = document.createElement("option");
                    var mName = (n.name != null ? n.name : "Unnamed module");
                    
                    $(objOption)
                        .text(mName)
                        .val(IG.getId(n.id))
                        .appendTo(objSelectModules);

                    if (typeof _this.item.moduleId !== "undefined" && _this.item.moduleId !== null) {
                        if (IG.getId(_this.item.moduleId) == IG.getId(n.id)) {
                            $(objOption).attr("selected", "selected");
                        }
                    }
                });
            };
            
            fnPopulateModules();
            fnPopulateSkins(_this.item.moduleId ? _this.item.moduleId : defaultModule);

            //Populate modules and skins selects:
            if (!bWrite) {
                $(objSelectModules).attr("disabled", "disabled");
                $(objSelectSkins).attr("disabled", "disabled");
            } else {
                $(objSelectModules).change(function() {
                    var module = _this.fn.getModuleById($(this).val());
                    if (module !== null) {
                        _this.fn.setModuleId(module.id);
                        fnPopulateSkins(module.id);
                        if (module.skinIds.length > 0) {
                            _this.fn.setSkinId(module.skinIds[0]);
                        }
                    } else {
                        _this.fn.setModuleId(nullString);
                        _this.fn.setSkinId(nullString);
                        fnPopulateSkins(null);
                    }
                });

                $(objSelectSkins).change(function() {
                    var skin = _this.fn.getSkinById($(this).val());
                    if (skin != null) {
                        _this.fn.setSkinId(skin.id);
                    }
                });
            }
        }

        return objDiv;
    };


    this.renderGalleryRSS = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "ig-gallery-rss")
            .addClass("ig-edit-subsection ig-divided");


        var objDivCheckbox = document.createElement("div");
        $(objDivCheckbox).appendTo(objDiv);


        //Build checkbox:
        var objInput = document.createElement("input");
        $(objInput)
            .attr({
                "type": "checkbox",
                "name": "gallery-rss",
                "id": "gallery-rss"
            })
            .appendTo(objDivCheckbox);

        if (_this.item.rssEnabled) {
            $(objInput).prop("checked", true);
        }


        var objLabel = document.createElement("label");
        $(objLabel)
            .attr("for", "gallery-rss")
            .text(IG.label.get("RSS"))
            .appendTo(objDivCheckbox);


        //Build additional options:
        var objDivRSSproperties = document.createElement("div");
        $(objDivRSSproperties)
            .addClass("ig-properties")
            .appendTo(objDiv);

        if (_this.item.rssEnabled) {
            $(objDivRSSproperties).css("display", "block");
        }



        //RSS feed url:
        var objLabelRSSUrl = document.createElement("label");
        $(objLabelRSSUrl)
            .text(IG.label.get("gallery_rss_feed_address"))
            .attr("for", "ig-gallery-rss-url")
            .appendTo(objDivRSSproperties);

        var objInputRSSUrl = document.createElement("input");
        $(objInputRSSUrl)
            .attr({
                "type": "text",
                "name": "ig-gallery-rss-url",
                "id": "ig-gallery-rss-url"
            })
            .val(_this.item.rssUrl)
            .appendTo(objDivRSSproperties)
            .wrap('<span class="input"></span>');

        if (bWrite) {
            $(objInputRSSUrl).change(function() {
                _this.fn.setRssUrl($(this).val());
            });
        } else {
            $(objInputRSSUrl)
                    .attr("readonly", "readonly");
        }


        //RSS name (human readable name of rss feed):
        var objLabelRSSname = document.createElement("label");
        $(objLabelRSSname)
            .text(IG.label.get("gallery_rss_name"))
            .attr("for", "ig-gallery-rss-name")
            .appendTo(objDivRSSproperties);

        var objInputRSSname = document.createElement("input");
        $(objInputRSSname)
            .attr({
                "type": "text",
                "name": "ig-gallery-rss-name",
                "id": "ig-gallery-rss-name"
            })
            .val(_this.item.rssName)
            .appendTo(objDivRSSproperties)
            .wrap('<span class="input"></span>');

        if (bWrite) {
            $(objInputRSSname).change(function() {
                _this.fn.setRssName($(this).val());
            });
        } else {
            $(objInputRSSname)
                .attr("readonly", "readonly");
        }


        //RSS media icon:
        if (bWrite) {
            var objDivButtons = document.createElement("div");
            $(objDivButtons)
                .addClass("buttons")
                .appendTo(objDivRSSproperties);

            var btnSelect = document.createElement("a");
            $(btnSelect)
                .addClass("btn-normal btn-dark btn-space-r")
                .html('<span>' + IG.label.get("gallery_select_rss_icon") + '</span>')
                .click(function() {
                    var mediaArchive = new IG.MediaArchive({
                        hasContext: true,
                        height: _this.mediaArchiveHeight,
                        onMediaInsert: function(media) {
                            $("#icon-div").empty();
                            var iconObj = _this.renderIcon(media);
                            $(iconObj)
                                .appendTo("#icon-div");
                            _this.item.rssIconMediaId = media.id;
                        },
                        onResized: function() {
                            _this.mediaArchiveHeight = mediaArchive.height;
                        }
                    });
                    mediaArchive.show();
                    return false;
                })
                .appendTo(objDivButtons);

            var btnRemove = document.createElement("a");
            $(btnRemove)
                .addClass("btn-normal btn-dark")
                .html('<span>' + IG.label.get("gallery_remove_rss_icon") + '</span>')
                .click(function() {
                    _this.item.rssIconMediaId = null;
                    $("#icon-div").empty();
                })
                .appendTo(objDivButtons);

            var iconDiv = document.createElement("div");
            $(iconDiv)
                .attr("id", "icon-div")
                .appendTo(objDivButtons);

        }

        if (IG.getId(_this.item.rssIconMediaId) == "" || IG.getId(_this.item.rssIconMediaId) == null) {
            $(btnRemove).addClass("hidden");
        } else {
            _this.loadMediaObject(_this.item.rssIconMediaId, function(media) {
                if (media != null) {
                    var iconObj = _this.renderIcon(media);
                    $(iconObj)
                         .appendTo(iconDiv);
                }
            });
        }



        if (bWrite) {
            $(objInput).change(function() {
                if ($(this).prop("checked")) {
                    _this.fn.setRssEnabled(true);
                    $(objDivRSSproperties).show();
                } else {
                    _this.fn.setRssEnabled(false);
                    $(objDivRSSproperties).hide();
                }
            });
        }
        else {
            $(objInput).prop("disabled", true);
        }



        return objDiv;
    };


    //Renders permissions:
    this.renderGalleryPermissions = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-subsection");

        var objH3 = document.createElement("h3");
        $(objH3)
            .text(IG.label.get("gallery_permisions"))
            .appendTo(objDiv);

        var groupPermisions = {};
        var userPermisions = {};

        if (_this.isNew) {//if it is new we need to copy permisions from user profile
            var user = $.grep(IG.lists.users, function(u, i) { return u.id.id == IG.userConfig.userId.id; });
            if (user[0]) { // Created an js error otherwise
                groupPermisions = user[0].defaultCreatePermissions.groups;
                userPermisions = user[0].defaultCreatePermissions.users;
            }
        } else {
            groupPermisions = _this.item.permissions.groups;
            userPermisions = _this.item.permissions.users;
        }


        var IGPermissionsGroups = new IG.PermissionsBox({
            type: "groups",
            key: "GALLERY",
            title: IG.label.get("gallery_group_permision_box_title"),
            items: IG.lists.groups,
            permissions: groupPermisions,
            write: bWrite,
            fnAdd: _this.fn.addPermission,
            fnRemove: _this.fn.removePermission,
            fnHasPermission: _this.fn.hasPermission
        });

        $(objDiv)
            .append(IGPermissionsGroups.render());

        IG.users.loadEnabled(_this.userPermissions, function(userData) {
            if (userData) {
                var IGPermissionsUsers = new IG.PermissionsBox({
                    type: "users",
                    key: "GALLERY",
                    title: IG.label.get("gallery_user_permision_box_title"),
                    items: userData.data,
                    count: _this.userPermissions.count,
                    permissions: userPermisions,
                    write: bWrite,
                    fnAdd: _this.fn.addPermission,
                    fnRemove: _this.fn.removePermission,
                    fnHasPermission: _this.fn.hasPermission,
                    fnLoadMore: function(callback) {
                        _this.userPermissions.offset += _this.userPermissions.limit;

                        IG.users.loadEnabled(_this.userPermissions, function(userData) {
                            if ($.isFunction(callback)) {
                                callback(userData.data);
                            }
                        });
                    }
                });

                $(objDiv).append(IGPermissionsUsers.render())
            }
        });





        return objDiv;
    };


    //Renders basic content tab contents:
    this.renderBasicContent = function(bWrite) {
        var objDiv = document.createElement("div");

        if (_this.item != null) {
            var objDivBasic = document.createElement("div");
            $(objDivBasic)
                .addClass("ig-edit-section")
                .appendTo(objDiv);




            var objDivTwoCol = document.createElement("div");
            $(objDivTwoCol)
                .addClass("ig-two-columns clearfix")
                .appendTo(objDivBasic);

            var objDivLeft = document.createElement("div");
            $(objDivLeft).appendTo(objDivTwoCol);

            var objDivLeftContent = document.createElement("div");
            $(objDivLeftContent)
                .addClass("ig-column-left")
                .append(_this.renderGalleryName(bWrite))
                .append(_this.renderGalleryDescription(bWrite))
                .append(_this.renderGalleryTitle(bWrite))
                .append(_this.renderGalleryType(bWrite))
                .append(_this.renderGalleryModule(bWrite))
                .append(_this.renderGalleryRSS(bWrite))
                .appendTo(objDivLeft);

            var objDivRight = document.createElement("div");
            $(objDivRight).appendTo(objDivTwoCol);

            var objDivRightContent = document.createElement("div");
            $(objDivRightContent)
                .addClass("ig-column-right")
                .append(_this.renderGalleryPermissions(bWrite))
                .appendTo(objDivRight);



            if (bWrite) {
                $(objDivBasic).append(_this.renderButtons());
            }
        }

        return objDiv;
    };






    //Renders content tab contents:
    this.renderContent = function(bWrite) {
        var objDiv = document.createElement("div");

        if (_this.item != null) {


            var objTitle = document.createElement("h3");
            $(objTitle)
                .attr("id", "content-gallery-title")
                .addClass("ig-title")
                .text(IG.label.get("gallery_content_title") + ' "' + _.first(_.values(_this.item.name)) + '"')
                .appendTo(objDiv);


            var objDivExistingChannels = document.createElement("div");
            $(objDivExistingChannels)
                .css({
                    "display": 'inline-block',
                    "maxHeight": "400px", // TODO should be in a CSS file
                    "minHeight": "200px", // TODO should be in a CSS file
                    "height": "100%"
                })
                .appendTo(objDiv);





            var addChannelsList = new IG.ObjectList({
                methodName: "searchChannels",
                startLimit: 50,
                page: "gallery-avail" // for remembering filters in IG.objList
            });
            if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                addChannelsList.setQuery(undefined, _this.searchLang);
            }

            var fnRemoveChannel = function(object) {
                if (_this.fn.removeChannelId(object.id)) {
                    existingChannelsList.removeRow(object.id);

                    blacklist.remove(object.id.id);

                    if (object.name[_this.searchLang] && object.name[_this.searchLang]!="") {

                        var objList = $('#'+addChannelsList.id);
                        var objItemRow = objList.find('.ITEM_'+object.id.id);
                        if (objItemRow[0]) {
                            var objItemBtns = objItemRow.find('td.buttons div');
                            if (objItemBtns[0]) {
                                _this.fn.renderAddGalleryBtn(object, objItemBtns[0], bWrite);
                            }
                        }

                    }
                }
            };

            var fnAfterSort = function() {
                //Fetch id objects from ObjectList and sort channelIds list:
                _this.fn.sortChannelIds(existingChannelsList.getItemIds());
                _this.updateGallery({
                    error: function(err) {
                        IG.gui.loading.hide();
                        IG.showErrorDialog(err);
                    }
                });
            };


            var channelIds = [];
            if (_this.item.channelIds.length > 0) {
                $.each(_this.item.channelIds, function(i, cid) {
                    channelIds.push(cid);
                    blacklist.add(cid.id);
                });
            }

            existingChannelsList = new IG.ObjectList({
                parentObject: objDivExistingChannels,
                scrolling: true,
                methodName: "searchChannels",
                filters: { ids: channelIds },
                orderByList: channelIds,
                adjustHeight: true,
                sortable: true,
                startLimit: 50,
                afterSort: fnAfterSort,
                columns: [
                    {
                        name: "",
                        title: IG.label.get("channel_list_channel_name"),
                        className: "title ellipsis",
                        sortable: false,
                        width: "auto",
                        render: function(object) {
                            var s;
                            if (typeof object.name[_this.searchLang] === 'undefined') {
                                s = _.first(_.values(object.name));
                            }
                            else {
                                s = IG.fn.stripHTML(object.name[_this.searchLang]);
                            }

                            var objA = document.createElement("a");
                            $(objA)
                                .text(s)
                                .attr("title", s)
                                .click(function() {
                                    IG.showEditChannel({
                                        id: object.id["id"],
                                        history: true,
                                        searchLang: _this.elementsLang
                                    });
                                    return false;
                                });
                            return objA;
                        }

                    },
                    {
                        name: "",
                        title: IG.label.get("channel_list_channel_type"),
                        className: "",
                        sortable: false,
                        width: "110px",
                        render: function(object) {
                            var ordinary = IG.label.get("channel_list_channel_type_ordinary");
                            var shared = IG.label.get("channel_list_channel_type_shared");
                            return object.id.customerId == IG.config.customerId ? ordinary : shared;
                        }
                    },
                    {
                        name: "",
                        title: IG.label.get("channel_list_no_of_elements"),
                        className: "numeric",
                        sortable: false,
                        width: "80px",
                        render: function(object) {
                            return object.infoObjectCount;
                        }
                    },
                    {
                        name: "",
                        title: "",
                        className: "buttons",
                        sortable: false,
                        width: "90px",
                        render: function(object) {
                            if (bWrite) {
                                var res = [];

                                //Delete button:
                                var btnDelete = document.createElement("a");
                                $(btnDelete)
                                    .addClass("btn-small btn-dark")
                                    .html('<span>' + IG.label.get("global_remove") + '</span>')
                                    .click(function() {
                                        fnRemoveChannel(object);
                                        _this.updateGallery();
                                        return false;
                                    });

                                res.push(btnDelete);

                                //Sorting icon:
                                var iconSort = document.createElement("span");
                                $(iconSort).addClass("sort");

                                res.push(iconSort);
                                return res;
                            }
                            else {
                                return "";
                            }
                        }
                    }
                ]
            });
            _this.existingChannelsList = existingChannelsList;
            existingChannelsList.init(function() {
                addChannelsList.setSorting('name', 'ASC');
                addChannelsList.init(function() {
                    // _this.removeItemChannelsFromList(addChannelsList);
                });
            });


            var objDivAddChannelList = document.createElement("div");

            //Search:
            var objDivSearch = document.createElement("div");
            $(objDivSearch).appendTo(objDiv);

            var objTitleSearch = document.createElement("h3");
            $(objTitleSearch)
                .addClass("ig-title")
                .text(IG.label.get("gallery_add_channel"))
                .appendTo(objDivSearch);

            var objDivSearchContent = document.createElement("div");
            $(objDivSearchContent)
                .addClass("ig-search")
                .attr("id", "channel-search")
                .appendTo(objDivSearch);

            var searchLang = _this.searchLang;

            var objInpSearch = document.createElement("input");
            $(objInpSearch)
                .attr({
                    "type": "text",
                    "name": "keywords",
                    "id": "gallery-search-keywords",
                    "value": IG.label.get("gallery_search_watermark")
                })
                .addClass('search')
                .focus(function() {
                    var v = $(this).val();
                    if (v == this.defaultValue) {
                        $(this).val("");
                    }
                    $(this)
                        .addClass("search-default")
                        .removeClass("search-default-deco");
                })
                .blur(function() {
                    var v = $(this).val();
                    if (v == "" || v == this.defaultValue) {
                        $(this).val(this.defaultValue);
                    }
                    $(this)
                        .addClass("search-default-deco")
                        .removeClass("search-default");
                })
                .keyup(function(e) {
                    var v = $(this).val();
                    var key = e.which ? e.which : e.keyCode;
                    if (key == 13) {
                        var query = (v != this.defaultValue ? v : '');
                        _this.searchLang = searchLang;
                        if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                            $(objLangSel).addClass('search-lang-deco');
                        } else $(objLangSel).removeClass('search-lang-deco');
                        addChannelsList.setQuery(query, _this.searchLang);
                        addChannelsList.refresh(function() {
                            _this.removeItemChannelsFromList(addChannelsList);
                        });
                        
                    }
                })
                .appendTo(objDivSearchContent);

            // Language selector for search query
            var objLangSel = addChannelsList.renderSearchLanguageSelector({
                onChange: function(n) {
                    searchLang = n;
                },
                searchLang: searchLang
            });
            if (objLangSel) $(objDivSearchContent).append(objLangSel);

            var btnSearch = document.createElement("a");
            $(btnSearch)
                .addClass("btn-normal btn-dark")
                .html('<span>' + IG.label.get("global_search") + '</span>')
                .click(function() {
                    var v = $(objInpSearch).val();
                    var query = (v != objInpSearch.defaultValue ? v : '');
                    _this.searchLang = searchLang;
                    if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                        $(objLangSel).addClass('search-lang-deco');
                    } else $(objLangSel).removeClass('search-lang-deco');

                    addChannelsList.setQuery(query, _this.searchLang);
                    addChannelsList.refresh(function() {
                        _this.removeItemChannelsFromList(addChannelsList);
                    });

                    return false;
                })
                .appendTo(objDivSearchContent);


            $(objDivAddChannelList)
                .attr("id", "gallery-channel-list")
                .appendTo(objDiv);

           
            addChannelsList.config.parentObject = objDivAddChannelList;
            addChannelsList.config.columns = [
                {
                    name: "name",
                    title: IG.label.get("channel_list_channel_name"),
                    className: "title ellipsis",
                    sortable: true,
                    width: "auto",
                    render: function(object) {
                        var s;
                        if (typeof object.name[_this.searchLang] === 'undefined') {
                            s = _.first(_.values(object.name));
                        }
                        else {
                            s = IG.fn.stripHTML(object.name[_this.searchLang]);
                        }

                        var objA = document.createElement("a");
                        $(objA)
                            .text(s)
                            .attr("title", s)
                            .click(function() {
                                IG.showEditChannel({
                                    id: object.id["id"],
                                    history: true,
                                    searchLang: _this.searchLang
                                });
                                return false;
                            });
                        return objA;
                    }
                },
                {
                    name: "customerId", //FIXME: sorting name?
                    title: IG.label.get("channel_list_channel_type"),
                    className: "",
                    sortable: false,
                    width: "110px",
                    render: function(object) {
                        var ordinary = IG.label.get("channel_list_channel_type_ordinary");
                        var shared = IG.label.get("channel_list_channel_type_shared");
                        return object.id.customerId == IG.config.customerId ? ordinary : shared;
                    }
                },
                {
                    name: "", //FIXME: sorting name?
                    title: IG.label.get("channel_list_no_of_elements"),
                    className: "numeric",
                    sortable: false,
                    width: "80px",
                    render: function(object) {
                        return object.infoObjectCount;
                    }
                },
                {
                    name: "",
                    title: "",
                    className: "buttons",
                    sortable: false,
                    width: "80px",
                    render: function(object) {
                        var objContainer = document.createElement('div');
                        _this.fn.renderAddGalleryBtn(object, objContainer, bWrite);
                        return objContainer;
                    }
                }
            ];


        }

        return objDiv;
    };


    //Renders meta tab contents:
    this.renderMetaContent = function(bWrite) {
        var objDiv = document.createElement("div");

        var objTitle = document.createElement("h3");
        $(objTitle)
            .addClass("ig-title")
            .text(IG.label.get("gallery_metadata"))
            .appendTo(objDiv);


        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section")
            .appendTo(objDiv);

        var s = ''
                    + '<table>'
                    + '<thead>'
                    + '<tr>'
                    + '<th>' + IG.label.get("gallery_metadata_key") + '</th>'
                    + '<th>' + IG.label.get("gallery_metadata_value") + '</th>'
                    + '</tr>'
                    + '</thead>'
                    + '<tbody>'
                    + '</tbody>'
                    + '</table>';

        $(objDivContent).append(s);

        var objTBody = $(objDivContent).find("tbody:first");
        if (objTBody) {
            var metadata = jQuery.extend({}, _this.item.metadata);
            if (typeof metadata.GALLERY_DESKTOP_WIDTH != "undefined" && typeof metadata.GALLERY_DESKTOP_HEIGHT != "undefined") {
                metadata.GALLERY_DESKTOP_RESOLUTION = metadata.GALLERY_DESKTOP_WIDTH+"x"+metadata.GALLERY_DESKTOP_HEIGHT;
                delete metadata.GALLERY_DESKTOP_WIDTH
                delete metadata.GALLERY_DESKTOP_HEIGHT
            }
            $.each(metadata, function(key, value) {
                var objTR = document.createElement("tr");
                $(objTR).appendTo(objTBody);

                var objTDkey = document.createElement("td");
                $(objTDkey)
                            .text(IG.label.get(key))
                            .appendTo(objTR);

                var objTDval = document.createElement("td");
                $(objTDval)
                            .text(value)
                            .appendTo(objTR);
            });
        }


        return objDiv;
    };

    this.renderIcon = function(myIcon) {
        var imageWrapper = document.createElement("div");
        $(imageWrapper).addClass('media-div-image-wrapper');

        if (myIcon != null) {
            if (typeof myIcon.id != 'undefined')
                $(imageWrapper).attr({ id: myIcon.id['id'] });

            if (typeof myIcon.originalMediaFile != 'undefined') {
                var image = document.createElement("img");
                var imgLabel = null;
                var imgUrl = IG.fn.getMediaThumbnailUrl(myIcon, function(label) {
                    imgLabel = label;
                });

                $(image).attr({
                    'src': imgUrl,
                    'title': imgLabel
                });
                $(imageWrapper).append(image);
            }
        }
        var caption = document.createElement("span");

        if (myIcon != null) {
            $(caption).text(myIcon.name[IG.config.language]);
        }
        $(imageWrapper).append(caption);
        return $(imageWrapper);
    };

    this.render = function(cb) {
        var bAdmin = IG.userHasPermission("OP_GALLERY_ADMIN");
        var bWrite = IG.userHasPermission("OP_GALLERY_WRITE", _this.item) || bAdmin;
        var bDelete = IG.userHasPermission("OP_GALLERY_DELETE", _this.item) || bAdmin;

        var objDiv = $("#ig-gallery").get(0);
        if (objDiv != null) {
            $(objDiv).remove(); //Must remove to also kill all bound events to children etc.
        }
        objDiv = document.createElement("div");
        $(objDiv).attr("id", "ig-gallery");

        //Create header:
        var objDivHeader = document.createElement("div");
        $(objDivHeader)
            .attr("id", "ig-gallery-header")
            .appendTo(objDiv);

        $(objDivHeader).append(_this.renderLanguageSelector());

        //Create headline:
        var objH2 = document.createElement("h2");
        $(objH2)
            .text(_this.isNew ? IG.label.get("gallery_create") : IG.label.get("gallery_edit"))
            .appendTo(objDivHeader);


        //Build tabs bar:
        var objTabs = document.createElement("div");
        $(objTabs)
            .addClass("ig-tabs")
            .attr("id", "ig-gallery-tabs")
            .appendTo(objDiv);

        var objTabsContainer = document.createElement("span");
        $(objTabsContainer)
            .addClass("ig-tabs-content")
            .attr("id", "ig-gallery-tabs-content")
            .appendTo(objTabs);

        var objTabItemBasic = document.createElement("a");
        $(objTabItemBasic)
            .html('<span>' + IG.label.get("gallery_tab_basic_info") + '</span>')
            .appendTo(objTabsContainer);

        if (!this.isNew) {
            var objTabItemContent = document.createElement("a");
            $(objTabItemContent)
                .html('<span>' + IG.label.get("gallery_tab_content") + '</span>')
                .appendTo(objTabsContainer);
        }

        var objTabItemMeta = document.createElement("a");
        $(objTabItemMeta)
            .html('<span>' + 'Meta' + '</span>')
            .appendTo(objTabsContainer);



        //Build tabs data items:
        var objDivTabsData = document.createElement("div");
        $(objDivTabsData).addClass("ig-tabs-data");


        var objTabDataBasic = document.createElement("div");
        $(objTabDataBasic)
            .attr("id", "ig-gallery-basic")
            .addClass("ig-tabs-data-item")
            .append(_this.renderBasicContent(bWrite))
            .appendTo(objDivTabsData);

        var objTabDataContent = document.createElement("div");
        $(objTabDataContent)
            .attr("id", "ig-gallery-content")
            .addClass("ig-tabs-data-item")
            .append(_this.renderContent(bWrite))
            .appendTo(objDivTabsData);

        var objTabDataMeta = document.createElement("div");
        $(objTabDataMeta)
            .attr("id", "ig-gallery-meta")
            .addClass("ig-tabs-data-item")
            .append(_this.renderMetaContent(bWrite))
            .appendTo(objDivTabsData);

        if (_this.isNew) {
            $([objTabItemBasic, objTabDataBasic]).addClass("current");

        }
        else {
            $([objTabItemContent, objTabDataContent]).addClass("current");
        }


        $(objTabItemBasic).click(function() {
            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataBasic;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
            
            _this.showLanguageSelector();
        });

        $(objTabItemContent).click(function() {
            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataContent;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
            
            _this.hideLanguageSelector();
        });

        $(objTabItemMeta).click(function() {
            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataMeta;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
            
            _this.hideLanguageSelector();
        });

        $(objDiv).append(objDivTabsData);


        $("#ig-data").html(objDiv);

        $.each(_this.fnAfterRender, function(i, n) {
            if ($.isFunction(n)) {
                n();
            }
        });

        if ($.isFunction(cb)) {
            cb();
        };

    };

    this.loadMediaObject = function(id, callback) {
        IG.request({
            method: "searchMedia",
            params: [IG.config.language, IG.config.language, "", true, { ids: [id] }, {}, 0, 0],
            success: function(response) {
                var responseData = response.data["data"];
                if ($.isFunction(callback)) {
                    if (responseData.length > 0) {
                        callback(responseData[0]);
                    } else {
                        callback({})
                    }
                }
            },
            error: function(err) {
                if ($.isFunction(callback)) {
                    callback({});
                }
            }
        });
    };


    this.loadGallery = function(obj, cb) {
        _this.setItem(obj);
        cb();
    };

    //Fetch the full Channel object:
    this.loadGalleryById = function(id, fnSuccess, fnError) {
        var filterOpts = { ids:
		[
			{
			    id: id,
			    customerId: IG.userConfig.userId.customerId
			}
		]
        };

        IG.batchRequest({
            requests: [
                {
                    method: "searchGalleries",
                    params: [IG.config.language, _this.elementsLang, "", false, filterOpts, {}, 1, 0],
                    success: function(response) {
                        if (response.result) {
                            var responsedata = response.data["data"];
                            var retObj = responsedata[0];

                            if ((typeof retObj != "undefined") && (retObj != null)) {
                                retObj = IG.fn.cleanUpGallery(retObj);
                                _this.setItem(retObj);
                            }
                            else {
                                if ($.isFunction(fnError)) {

                                    fnError(IG.label.get("gallery_unknown"));
                                }
                            }
                        }
                        else {
                            if ($.isFunction(fnError)) {
                                fnError(IG.label.get("gallery_error_loading"));
                            }
                        }
                    },
                    error: function(err) {
                        if ($.isFunction(fnError)) {
                            fnError(IG.label.get("gallery_error_loading") + err);
                        }
                    }
                },
                {
                    method: "getModules",
                    params: [IG.userConfig.userId.customerId, IG.config.language],
                    success: function(response) {
                        if (response.result) {
                            //Refresh global list of modules:
                            IG.lists.modules = response.data;
                        }
                    }
                },
                {
                    method: "getSkins",
                    params: [IG.userConfig.userId.customerId, IG.config.language],
                    success: function(response) {
                        if (response.result) {
                            //Refresh global list of skins:
                            IG.lists.skins = response.data;
                        }
                    }
                }
            ],
            success: function(response) {
                fnSuccess();
            },
            error: function(err) {
                alert(err);
            }

        });
    };


    this.init = function(options) {
        options.id = (typeof options.id != "undefined" ? options.id : null);
        options.object = (typeof options.object != "undefined" ? options.object : null);
        options.isNew = (typeof options.isNew == "boolean" ? options.isNew : false);
        options.elementsLang = (typeof options.elementsLang == "string" ? options.elementsLang : IG.customerConfig.defaultContentLanguage);
        _this.elementsLang =  options.elementsLang;
        _this.searchLang = options.elementsLang;

        _this.isNew = options.isNew;

        _this.userPermissions.count = 0;
        _this.userPermissions.offset = 0;

        blacklist.empty();

        //What to do when done loading object:
        var fnSuccess = function() {
            _this.render(function() {
                //Show elements with text for selected language
                $('.language-dependent.' + _this.elementsLang).css("display", "block");
                IG.gui.loading.hide();
            });
        };

        var fnError = function(err) {
            if (typeof err == "string") {
                IG.showErrorDialog(err, function() {
                    //Go to root/dashboard:
                    IG.location.front();
                });
            }
            IG.gui.loading.hide();
        };

        //Got a full object - use this:
        if (options.object != null) {
            _this.loadGallery(options.object, fnSuccess);
        }
        //Else load by provided id:
        else {
            _this.loadGalleryById(options.id, fnSuccess, fnError);
        }

    };

    return this;
};


IG.showEditGallery = function(options) {
    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);
    options.id = (typeof options.id != "undefined" ? options.id : null);
    options.object = (typeof options.object != "undefined" ? options.object : null);
    options.isNew = (typeof options.isNew == "boolean" ? options.isNew : false);
    options.searchLang = (typeof options.searchLang == "string" ? options.searchLang : IG.customerConfig.defaultContentLanguage);

    IG.debug("IG.showEditGallery, id: " + options.id);
    
    // Google Analytics:
    // IG.config.prodMode ? _gaq.push(['_trackPageview']) : null;

    var objEditGalleryObject = _IG.objects.get("gallery");
    if (objEditGalleryObject == null) {
        objEditGalleryObject = _IG.objects.create("gallery", new _IG.Gallery())
    }

    //Parse url hash params if no id or object provided in options:
    if (options.id == null && options.object == null) {
        var urlParams = (typeof $.address.parameter(objEditGalleryObject.hash()) != 'undefined' ? $.address.parameter(objEditGalleryObject.hash()).split('|') : '');
        if (urlParams.length > 0) {
            var urlId = urlParams[0];

            if (urlId != "") {
                options.id = urlId;

                if (urlId == "0") {
                    IG.create.gallery({
                        success: function(object) {
                            options.object = object;
                            options.isNew = true;
                        }
                    });
                }
            }
        }
    }


    IG.gui.setTitle(options.isNew ? IG.label.get("gallery_create") : IG.label.get("gallery_edit"));


    //Determine options to send to init function:
    var loadOptions = {
        isNew: options.isNew,
        elementsLang: options.searchLang
    };

    if (options.object != null) {
        loadOptions.object = options.object;
    }
    else {
        loadOptions.id = options.id;
    }

    //Add history state?
    if (options.history) {
        var h = objEditGalleryObject.hash();
        if (options.isNew) {
            h += "?" + h + "=0";
        } else {
            h += "?" + h + "=" + (options.object != null ? options.object.id["id"] : options.id);
        }
        _IG.location.setCurrent(h);
    }

    IG.gui.loading.show();
    objEditGalleryObject.init(loadOptions);

    $('#ig-user-menu-sections').find('a.galleries').parent().addClass('menu-active');
};
