(function($) {
    jQuery.mlp = { x: 0, y: 0 }; // Mouse Last Position
    $(document).mousemove(function(e) {
        jQuery.mlp = { x: e.pageX, y: e.pageY }
    });
    function notNans(value) {
        if (isNaN(value)) {
            return 0;
        } else {
            return value
        }
    }
    $.fn.ismouseover = function(overThis) {
        var result;
        this.eq(0).each(function() {
            var offSet = $(this).offset();
            var w = Number($(this).width())
            + notNans(Number($(this).css("padding-left").replace("px", "")))
            + notNans(Number($(this).css("padding-right").replace("px", "")))
            + notNans(Number($(this).css("border-right-width").replace("px", "")))
            + notNans(Number($(this).css("border-left-width").replace("px", "")));
            var h = Number($(this).height())
            + notNans(Number($(this).css("padding-top").replace("px", "")))
            + notNans(Number($(this).css("padding-bottom").replace("px", "")))
            + notNans(Number($(this).css("border-top-width").replace("px", "")))
            + notNans(Number($(this).css("border-bottom-width").replace("px", "")));
            if (offSet.left < jQuery.mlp.x && offSet.left + w > jQuery.mlp.x
             && offSet.top < jQuery.mlp.y && offSet.top + h > jQuery.mlp.y) {
                result = true;
            } else {
                result = false;
            }
        });
        return result;
    };
})(jQuery); 