/**/
IG.PermissionsBox = function(options) {
    var _this = this;

    this.options = (typeof options != "undefined" ? options : {});

    //Type: users, groups
    options.type = (typeof options.type != "undefined" ? options.type : "");

    //Key: INFO_OBJECT, CHANNEL, GALLERY, ...
    options.key = (typeof options.key != "undefined" ? options.key : "");

    //Ops: operations, defaults to READ, WRITE, DELETE
    options.ops = (typeof options.ops != "undefined" ? options.ops : {
        "R": "READ",
        "W": "WRITE",
        "D": "DELETE"
    });

    options.count = (typeof options.count !== 'undefined' ? options.count : -1);
    options.fnLoadMore = (typeof options.fnLoadMore && $.isFunction(options.fnLoadMore) ? options.fnLoadMore : function(cb) {});


    //Title to display above list:
    options.title = (typeof options.title != "undefined" ? options.title : "&nbsp;");
    options.title = (options.title !== "" ? options.title : '&nbsp;');

    //Items for which to set permissions:
    options.items = (typeof options.items != "undefined" ? options.items : []);

    //Currently set permissions
    options.permissions = (typeof options.permissions != "undefined" ? options.permissions : {});

    //Write permission?
    options.write = (typeof options.write == "boolean" ? options.write : false);

    //Add and remove functions:
    options.fnAdd = (typeof options.fnAdd == "function" ? options.fnAdd : function() { });
    options.fnRemove = (typeof options.fnRemove == "function" ? options.fnRemove : function() { });
    options.fnHasPermission = (typeof options.fnHasPermission == "function" ? options.fnHasPermission : function() { });


    this.render = function() {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-permissions");


        var sHeaders = '<table><thead><tr>';
        sHeaders += '<th>' + options.title + '</th>';
        $.each(options.ops, function(i, n) {
            sHeaders += '<th class="permission-col">' + i + '</th>';
        });
        sHeaders += '</tr></thead></table>';



        var objDivHeaders = document.createElement("div");
        $(objDivHeaders)
            .addClass("ig-permissions-headers")
            .html(sHeaders)
            .appendTo(objDiv);

        var objDivItems = document.createElement("div");
        $(objDivItems)
            .addClass("ig-permissions-items")
            .appendTo(objDiv);

        var objTable = document.createElement("table");
        $(objTable).appendTo(objDivItems);

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);


        //Loop through items for which to set permissions:
        renderItems(options.items);





        function renderItems(items) {
            $.each(items, function(i, n) {
                var objTR = document.createElement("tr");
                $(objTR)
                    .attr("title", n.name)
                    .appendTo(objTBody);


                //Create title:
                var objTDtitle = document.createElement("td");
                $(objTDtitle)
                    .addClass("ellipsis")
                    .text(n.name)
                    .appendTo(objTR);


                //Loop through available operations:
                $.each(options.ops, function(j, m) {

                    //Create cell for each operation:
                    var objTD = document.createElement("td");
                    $(objTD)
                        .addClass("cb permission-col")
                        .appendTo(objTR);

                    //Create checkbox:
                    var objInp = document.createElement("input");
                    $(objInp)
                        .attr({
                            "type": "checkbox",
                            "name": options.type + "-" + j,
                            "value": n.id["id"]
                        })
                        .appendTo(objTD);


                    //Handle checkbox clicks:
                    if (!options.write) {
                        $(objInp).prop("disabled", true);
                    }
                    else {
                        $(objInp).change(function() {
                            if ($(this).prop("checked")) {
                                options.fnAdd(options.type, "OP_" + options.key + "_" + m, n.id["id"]);
                            } else {
                                options.fnRemove(options.type, "OP_" + options.key + "_" + m, n.id["id"]);
                            }
                        });
                    }


                    //Check this checkbox if current entity has read permissions:
                    //if (options.permissions.length) {
                    if (typeof options.permissions != "undefined") {
                        if (typeof options.permissions["OP_" + options.key + "_" + m] != "undefined") {
                            $.each(options.permissions["OP_" + options.key + "_" + m], function(k, o) {
                                if (n.id["id"] == o.id) {
                                    $(objInp).prop("checked", true);
                                    options.fnAdd(options.type, "OP_" + options.key + "_" + m, n.id["id"]);
                                    return false; //break loop
                                }
                            });
                        }
                    }


                });


            });
        }


        if (options.count > -1 && options.items.length < options.count) {
            // Add a load more button:
            var objTrLoadNext = document.createElement("tr");
            $(objTrLoadNext)
                .appendTo(objTBody);
            var objTdLoadNext = document.createElement('td');
            $(objTdLoadNext)
                .attr("colspan", 4)
                .appendTo(objTrLoadNext);
            var objALoadNext = document.createElement('a');
            $(objALoadNext)
                .text(IG.label.get('USER_PERMISSIONS_LOAD_MORE'))
                .addClass('ig-list-load-more-button')
                .attr('href', '#')
                .appendTo(objTdLoadNext)
                .click(function(e) {
                    e.preventDefault();

                    options.fnLoadMore(function(items) {
                        if ($.isArray(items)) {
                            options.items = options.items.concat(items);

                            renderItems(items);

                            if (options.items.length >= options.count) {
                                $(objTrLoadNext).hide();
                            } else {
                                $(objTBody).append(objTrLoadNext);
                            }
                        }

                    });

                    return false;
                });
        }


        return objDiv;
    };


    return this;
};