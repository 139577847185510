IG.Utils = {};

IG.Utils.hasValidFlashVersion = function() {
    return swfobject.hasFlashPlayerVersion('10.1');
};

IG.Utils.hasValidBrowser = function() {
    switch (BrowserDetect.browser) {
        case 'Chrome':
            return true;
            break;
        case 'Explorer':
            return BrowserDetect.version >= 7;
            break;
        case 'Firefox':
            return BrowserDetect.version >= 3.6;
            break;
        case 'Safari':
            return true;
            break;
        case 'Mozilla': // IE11
            return BrowserDetect.version >= 11.0;
            break;
    }
    return false;
};

IG.Utils.hasValidResolution = function() {
    return (screen.width >= 1024) && (screen.height >= 768);
}
