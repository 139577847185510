IG.VideoPlayer = function(opts) {
    var _this = this;

    this.options = typeof opts != "undefined" ? opts : {};
    this.options.container = typeof opts.container != "undefined" ? opts.container : null;
    this.options.media = typeof opts.media != "undefined" ? opts.media : null;
    this.options.width = typeof opts.width != "undefined" ? opts.width : 242;
    this.options.height = typeof opts.height != "undefined" ? opts.height : 182;

    this.init = function() {
        if (_this.options.container != null) {
            if (_this.options.media != null) {
                var containerId = $(_this.options.container).attr("id");

                //make inner container for flash
                var objDiv = $(document.createElement("div"));
                if (containerId && containerId != "") {
                    objDiv.attr("id", "flash-player-" + containerId);
                } else {
                    objDiv.attr("id", "flash-player");
                }

                objDiv
                    .appendTo(_this.options.container);

                var flashvars = {
                    file: _this.options.media.originalMediaFile.youtubeUrl,
                    autostart: 'true'
                };

                var params = {
                    allowfullscreen: 'true',
                    allowscriptaccess: 'always'
                };

                var attributes = {
                    id: objDiv.attr("id"),
                    name: objDiv.attr("id"),
                    align: "middle"
                };

                //Embed the flash object:
                swfobject.embedSWF(
                    IG.urls.player.flash,
                    objDiv.attr("id"),
                    _this.options.width,
                    _this.options.height,
                    "",
                    "",
                    flashvars,
                    params,
                    attributes,
                    function(e) {

                    }
                );
            }
        }
    };
    return this;
};