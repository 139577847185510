IG.DateTimePicker = function(options) {
    options = (typeof options != "undefined" ? options : {});

    console.log(options);

    var previousUnixDate = (typeof options.current == "number" ? parseInt(options.current) : moment().unix());
    var showTime = ($.type(options.showTime) === "boolean" ? options.showTime : true);
    var displayFormat = (_.isString(options.displayFormat) ? options.displayFormat : 'lll');
    
    var currentDateMoment = moment.unix(previousUnixDate);
    currentDateMoment.second(0);
    currentDateMoment.millisecond(0);

    this.getContent = function() {
        return buildPicker();
    };

    this.save = function(cb) {
        cb( currentDateMoment.unix(), currentDateMoment.format(displayFormat), currentDateMoment.clone());
    };
    
    var buildPicker = function() {
        var objWrapper = document.createElement('div');
        $(objWrapper)
            .addClass('content');
        
        var objDtpWrapper = document.createElement('div');
        $(objDtpWrapper)
            .attr('id', 'date-time-picker-wrap');
        $(objWrapper).append(objDtpWrapper);
        
        var objDatpicker = document.createElement('div');
        $(objDatpicker)
            .attr('id', 'date-time-picker')
            .appendTo(objDtpWrapper);
        
        var objTimeWrap = document.createElement('div');
        $(objTimeWrap)
            .attr('id', 'date-time-time-wrap')
            .appendTo(objDtpWrapper);

        if (!showTime) {
            $(objTimeWrap).hide();
        }
        
        var clockFormat = IG.clockFormat[IG.config.language];
        var objSelectH = document.createElement('select');
        $(objSelectH)
            .attr('id', 'date-time-picker-h')
            .appendTo(objTimeWrap);
        if (clockFormat == 12) {
            $(objSelectH).addClass('date-time-picker-12-h');
        }
        
        var objClockColon = document.createElement('span');
        $(objClockColon)
            .attr('id', 'date-time-picker-colon')
            .text(':')
            .appendTo(objTimeWrap);
        
        var objSelectM = document.createElement('select');
        $(objSelectM)
            .attr('id', 'date-time-picker-m')
            .appendTo(objTimeWrap);
        
        var prevH = currentDateMoment.hour();

        var clockArr = IG.DateTimePicker.hours[clockFormat];
        $.each(clockArr, function(i, val) {
           var selected = "";
           if (prevH === i) {
               selected = 'selected="selected"';
           }
           var eleOpt = $('<option '+selected+'/>', {});
               eleOpt
                   .val(i)
                   .text(val)
                   .appendTo(objSelectH);
        }); 
            
        
        var prevM = currentDateMoment.minute();
        for (var i=0; i<60; i++) {
            var padVal = IG.DateTimePicker.pad(i, 2, 0);
            var selected = "";
            if (prevM === i) {
                selected = 'selected="selected"';
            }
            var eleOpt = $('<option '+selected+'/>', {});
            eleOpt
                .val(i)
                .text(padVal)
                .appendTo(objSelectM);

        }
        
        $(objSelectH).change(function(evt) {
            var val = $(this).val();
            currentDateMoment.hour(val);
        });
        $(objSelectM).change(function(evt) {
            var val = $(this).val();
            currentDateMoment.minute(val);
        });
        
        
        window.setTimeout(function() {
            $('#date-time-picker').datepicker({
                defaultDate: currentDateMoment.toDate(),
                changeMonth: true,
                changeYear: true,
                firstDay: 1,
                nextText: '>',
                prevText: '<',
                showOtherMonths: true,
                selectOtherMonths: false,
                dayNamesMin: IG.dayNamesShort[IG.config.language],
                monthNamesShort: IG.monthNamesShort[IG.config.language],
                onSelect: function(dateText, inst) {
                    var h = $('#date-time-picker-h').val();
                    var m = $('#date-time-picker-m').val();

                    currentDateMoment.year(inst.selectedYear);
                    currentDateMoment.month(inst.selectedMonth);
                    currentDateMoment.date(inst.selectedDay);
                    currentDateMoment.hour(h);
                    currentDateMoment.minute(m);
                },
                'onChangeMonthYear': function(year, month, inst) {
                    currentDateMoment.year(year);
                    currentDateMoment.month(month-1);
                    $(this).datepicker('setDate', currentDateMoment.toDate());
                }
            });
        }, 10);
        return $(objWrapper);
    };
};

IG.DateTimePicker.pad = function(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

IG.DateTimePicker.formats = {
    "da": "dd-MM-yyyy HH:mm",
    "de": "dd-MM-yyyy HH:mm",
    "en": "d/M/yyyy h:mm tt"
};

IG.DateTimePicker.hours = {
    "12": ['12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'],
    "24": ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
};
