import React from 'react';
import moment from 'moment';

export const StatusLabel = ({ updateTime }) => {
    let updateTimeMoment = updateTime > 0 ? moment.unix(updateTime) : false;
    let statusClassName = '';
    let statusLabel = '';
    let visible = true;

    if (updateTimeMoment) {
        let okStatusTime = moment().subtract(15, 'minutes');
        let badStatusTime = moment().subtract(24, 'hours');

        if (updateTimeMoment.isAfter(okStatusTime)) {
            statusClassName = 'success';
        }
        if (updateTimeMoment.isBefore(okStatusTime)) {
            statusClassName = 'warning';
        }
        if (updateTimeMoment.isBefore(badStatusTime)) {
            statusClassName = 'error';
        }
        statusLabel = updateTimeMoment.fromNow();
    } else {
        statusLabel = IG.label.get('status_monitor_last_updated_unkown');
        visible = false;
    }

    return (
        <div
            style={{ visibility: visible ? 'visible' : 'hidden' }}
            title={IG.label.get('status_monitor_last_updated') + ' ' + statusLabel}
            className={statusClassName + ' status-monitor'}
        >
            <span className="status-led" />
            <span className="status-text">{statusLabel}</span>
        </div>
    );
};

