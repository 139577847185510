IG.Channel = function() {
    var _this = this;

    this.item = null;

    this.isNew = false;
    this.itemHasChanged = false;
    this.elementsLang = IG.customerConfig.defaultContentLanguage; //default labels language
    this.searchLang = IG.customerConfig.defaultContentLanguage;

    this.fnAfterRender = [];

    this.bAdmin = false;
    this.bWrite = false;

    this.dateFilter = {
        date: new Date(),
        key: "today"
    };

    this.IGObjectList = null;

    this.allowRecursiveLoad = true;

    this.infoObjContainerOptions = {
        'limit' : 30,
        'offset' : 0,
        'left' : 0,
        'timer': null,
        'scrollDelay' : 100,
        'itemCountLeft': 0,
        'itemCountVisible' : 0,
        'itemCountRight' : 0,
        'direction': 'right',
        'reset' : function() {
            this.offset = 0;
            this.left = 0;
        }
    };

    this.userPermissions = {
        'userCount' : 0,
        'limit' : 30,
        'offset' : 0
    };

    var blacklist = {
        data: {},
        add: function(id) {
            if (typeof this.data[id] === 'undefined') {
                this.data[id] = id;
            }
        },
        remove: function(id) {
            if (typeof this.data[id] !== 'undefined') {
                delete this.data[id];
            }
        },
        exists: function(id) {
            return (typeof this.data[id] !== 'undefined');
        },
        empty: function() {
            this.data = {};
        }
    };

    this.allInfoObjects = {
        'count': 0,
        'data': {},
        'sortedAndFiltered' : [],
        'lastVisible' : {},
        'get' : function() { // TODO what is this?
            var _me = this;
        },
        'addInfoObject' : function(infoObject) {
            if (typeof this.data[infoObject.id.id] === 'undefined') {
                this.count++; // it's new so increase
            }
            blacklist.add(infoObject.id.id);
            this.data[infoObject.id.id] = infoObject;
            this.sortAndFilter();
        },
        'addArray' : function(infoObjects) {
            var _me = this;
            if (!$.isArray(infoObjects)) {
                return false;
            }
            $.each(infoObjects, function(i, io) {
                if (typeof _me.data[io.id.id] === 'undefined') {
                    _me.count++; // it's new so increase
                }
                blacklist.add(io.id.id);
                _me.data[io.id.id] = io;
            });
            _me.sortAndFilter();
        },
        'remove' : function(id) {
            if (typeof this.data[id.id] === 'undefined') {
                this.count--; // decrease
            }
            blacklist.remove(id.id);
            delete this.data[id.id];
            this.sortAndFilter();
        },
        'empty': function() {
            blacklist.empty();
            this.data = {};
            this.count = 0;
            this.sortedAndFiltered = [];
            this.lastVisible = {};
        },
        'getArray' : function() {
            var _me = this;
            var resArray = [];
            $.each( _me.data, function(id, io) {
                resArray.push(io);
            });
            return resArray;
        },
        'sortAndFilter' : function(callback) {
            var _me = this;
            var sorted = IG.fn.sortObjects(_this.item.infoObjectIds, _me.getArray());
            _me.sortedAndFiltered = _this.filterInfoObjects(sorted);

            // Update the document count in the date filter tab:
            $('#tab_date_filter_all').text(IG.label.get("channel_all") + ' (' + _this.item.infoObjectIds.length + ')');

            if ($.isFunction(callback)) {
                callback();
            }
        },
        'getLastVisible' : function() {
            return this.lastVisible;
        },
        'getVisible' : function() {
            var _me = this;
            var visible = this.sortedAndFiltered.slice(
                _this.infoObjContainerOptions.itemCountLeft,
                _this.infoObjContainerOptions.itemCountLeft+_this.infoObjContainerOptions.itemCountVisible
            );
            _me.lastVisible = {};
            $.each(visible, function(i, obj) {
                _me.lastVisible[obj.id.id] = obj.id.id;
            });
            return visible;
        }
    };

    this.hash = function() {
        var h = "channel";
        return h;
    };

    //Sets the currently loaded item
    this.setItem = function(item) {
        _this.item = item;
    };

    //Updates/saves the channel:
    this.updateChannel = function(options) {
        options = (typeof options != "undefined" ? options : {});
        options.success = (typeof options.success == "function" ? options.success : function() { });

        var updatedChannelObject = $.extend(true, {}, _this.item);
        //Reset last modified
        updatedChannelObject.updated = 0;

        if (_this.isNew) {
            delete updatedChannelObject.id;
        }

        var fnOnStart = function() {
            IG.gui.loading.show();
        };

        var fnOnEnd = function() {
            IG.gui.loading.hide();
        };

        fnOnStart();

        IG.update.channel({
            object: updatedChannelObject,
            success: function(object) {
                _this.setItem(object);
                fnOnEnd();
                if (_this.isNew) {
                    IG.showEditChannel({
                        object: object,
                        history: true,
                        searchLang: _this.elementsLang
                    });
                } else {
                    $("#channel-content-title").text(IG.label.get("channel_todays_info_objects_title") + ' "' + _this.item.name[_this.elementsLang] + '"');
                    options.success();
                }
            },
            error: function(err) {
                fnOnEnd();
                IG.showErrorDialog("Error saving: " + err);
            }
        });
    };


    //Holds basic functions for adding/removing properties:
    this.fn = {
        //Check if permission exists:
        hasPermission: function(pType, pKey, id) {
            var b = false;
            if (typeof _this.item.permissions[pType][pKey] !== "undefined") {
                $.each(_this.item.permissions[pType][pKey], function(i, n) {
                    if (n.id == id) {
                        b = true;
                        return false; //break loop;
                    }
                });
            }
            return b;
        },
        //Adds a permission:
        addPermission: function(pType, pKey, id) {
            if (!_this.fn.hasPermission(pType, pKey, id)) {
                if (typeof _this.item.permissions[pType][pKey] === "undefined") {
                    _this.item.permissions[pType][pKey] = [];
                }
                _this.item.permissions[pType][pKey].push({ "customerId": IG.config.customerId, "id": id });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },
        //Removes a permission:
        removePermission: function(pType, pKey, id) {
            if (_this.fn.hasPermission(pType, pKey, id)) {
                _this.item.permissions[pType][pKey] = $.grep(_this.item.permissions[pType][pKey], function(n, i) {
                    return n.id != id;
                });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },

        //Sets the name text for the given language:
        setName: function(lang, str) {
            _this.item.name[lang] = str;
            _this.itemHasChanged = true;
        },

        //Sets the description text
        setDescription: function(lang, str) {
            _this.item.description[lang] = str;
            _this.itemHasChanged = true;
        },


        //Checks if channel has an InfoObject:
        hasInfoObjectId: function(id) {
            var bFound = false;

            if (id != null) {
                $.each(_this.item.infoObjectIds, function(i, n) {
                    if (n.id == id["id"]) {
                        bFound = true;
                        return false; //break loop
                    }
                });
            }
            return bFound;
        },

        addInfoObject: function(object) {
            if (_this.fn.addInfoObjectId(object.id)) {
                _this.allInfoObjects.addInfoObject(object);
                return true;
            }
            return false;
        },

        //Adds an InfoObject:
        addInfoObjectId: function(id) {
            if (!_this.fn.hasInfoObjectId(id)) {
                _this.item.infoObjectIds.push(id);
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },

        removeInfoObject: function(object) {
            if (_this.fn.removeInfoObjectId(object.id)) {
                _this.allInfoObjects.remove(object.id);

                var objList = $('#'+_this.IGObjectList.id);
                var objItemRow = objList.find('.ITEM_'+object.id.id);

                console.log(objList, objItemRow);

                if (objItemRow[0]) {
                    var objItemBtns = objItemRow.find('td.buttons-add div');
                    if (objItemBtns[0]) {
                        _this.fn.renderAddChannelBtn(object, objItemBtns[0], _this.bWrite);
                    }
                }

                return true;
            }
            return false;
        },

        //Removes an InfoObject:
        removeInfoObjectId: function(id) {
            if (_this.fn.hasInfoObjectId(id)) {
                //Only keep InfoObjects with different id:
                _this.item.infoObjectIds = $.grep(_this.item.infoObjectIds, function(n, i) {
                    return n["id"] != id["id"];
                });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },


        //Checks if customer id is already in shares list:
        hasCustomerId: function(id) {
            var bFound = false;
            if ($.isArray(_this.item.sharedWithCustomerIds)) {
                bFound = ($.inArray(id, _this.item.sharedWithCustomerIds) > -1);
            }
            return bFound;
        },

        //Adds customer id to shared list:
        addCustomerId: function(id) {
            if (!_this.fn.hasCustomerId(id)) {
                if (!$.isArray(_this.item.sharedWithCustomerIds)) {
                    _this.item.sharedWithCustomerIds = [];
                }
                _this.item.sharedWithCustomerIds.push(id);
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },

        //Removes customer id from shared list:
        removeCustomerId: function(id) {
            if (_this.fn.hasCustomerId(id)) {
                if ($.isArray(_this.item.sharedWithCustomerIds)) {
                    _this.item.sharedWithCustomerIds = $.grep(_this.item.sharedWithCustomerIds, function(n, i) {
                        return n != id
                    });
                }
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },

        isInfoObjectParent: function(infoobject) {
            return (typeof infoobject.referencingInfoObjectIds == "undefined" || infoobject.referencingInfoObjectIds.length == 0 ? true : false);
        },


        renderAddChannelBtn: function(object, objContainer, bWrite) {
            $(objContainer).html('').addClass('ellipsis');

            var objEm = document.createElement('em');
            $(objEm)
                .addClass('ig-list-item-message');

            if (bWrite && !blacklist.exists(object.id.id)) {
                //if it is a parent object
                if (_this.fn.isInfoObjectParent(object)) {
                    var objA = document.createElement("a");
                    $(objA)
                        .addClass("btn-small btn-light")
                        .html('<span>' + IG.label.get("global_add") + ' +</span>')
                        .click(function() {
                            if (_this.fn.addInfoObject(object)) {

                                _this.updateChannel({
                                    success: function() {
                                        padInfoObjectContainer(function() {
                                            _this.renderInfoObjects({}, function() { });
                                        });

                                        $(objA).hide(); // hide the button
                                        $(objEm)
                                            .attr('title', IG.label.get('LIST_ITEM_ADDED'))
                                            .text(IG.label.get('LIST_ITEM_ADDED'));
                                        $(objContainer)
                                            .html(objEm);
                                    }
                                });
                            }
                            return false;
                        })
                        .appendTo(objContainer);
                }
            } else if (!bWrite) {
                $(objEm)
                    .attr('title', IG.label.get('LIST_ITEM_NO_WRITE_ACCESS'))
                    .text(IG.label.get('LIST_ITEM_NO_WRITE_ACCESS'));
                $(objContainer)
                    .html(objEm);
            } else {
                $(objEm)
                    .attr('title', IG.label.get('LIST_ITEM_ADDED'))
                    .text(IG.label.get('LIST_ITEM_ADDED'));
                $(objContainer)
                    .html(objEm);
            }
        }
    };


    //Holds references to IG.TextField objects
    this.textFields = [];

    //Renders the language selector/toggler:
    this.renderLanguageSelector = function() {
        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("ig-languages")
            .attr("id", "ig-channel-languages");


        //Toggle language (ie. visible text fields):
        var fnToggleLanguage = function(key) {
            _this.elementsLang = key;

            //Text fields:
            $.each(_this.textFields, function(i, n) {
                n.setLanguage(key);
            });


            //Text fields:
            var arrLangDep = $("#ig-channel span.language-dependent");
            if (arrLangDep.length > 0) {
                $.each(arrLangDep, function(i, n) {
                    if ($(n).hasClass(key)) {
                        $(n).css("display", "block");
                    }
                    else {
                        $(n).css("display", "none");
                    }
                });
            }
            
            // Change the shown channel title:
            $("#channel-content-title").text(IG.label.get("channel_todays_info_objects_title") + ' "' + _this.item.name[_this.elementsLang] + '"');

            var idPortion = _this.item.infoObjectIds.slice(_this.infoObjContainerOptions.offset,
                _this.infoObjContainerOptions.offset + _this.infoObjContainerOptions.limit);
            var options = {
                ids: idPortion
            };

            // Reload infoObjects with the new language
            _this.loadInfoObjects(options, function(objects) {
                padInfoObjectContainer(function() {
                    _this.renderInfoObjects(objects, function() {

                    });
                });
            });
        };

        //Set click event for each language:	  
        if (IG.fn.objSize(IG.customerConfig.contentLanguages) > 1) {
            $.each(IG.customerConfig.contentLanguages, function(key, name) {
                var objSpan = document.createElement("span");
                $(objSpan)
	                    .addClass("ig-language " + key)
	                    .attr("title", name)
	                    .click(function() {
	                        fnToggleLanguage(key);
	                        $(this).addClass("current");
	                        $(this).siblings("span").removeClass("current");
	                    })
	                    .appendTo(objDiv);

                if (key == _this.elementsLang) {
                    $(objSpan).addClass("current");
                }
            });
        }

        return objDiv;
    };


    //Renders channel name row for basic content:
    this.renderChannelName = function(bWrite) {
        var objTR = document.createElement("tr");

        var objTDlbl = document.createElement("td");
        $(objTDlbl)
            .addClass("lbl")
            .text(IG.label.get("channel_name")) //Kanalens navn
            .appendTo(objTR);

        var objTDinp = document.createElement("td");
        $(objTDinp).appendTo(objTR);

        var IGTextField = new IG.TextField({
            id: "channel-name",
            texts: _this.item.name,
            language: _this.elementsLang,
            readonly: !bWrite,
            onchange: function(language, s) {
                _this.fn.setName(language, s);
            }
        });

        _this.textFields.push(IGTextField);
        $(objTDinp).append(IGTextField.render());


        return objTR;
    };

    //Renders descriptions row of basic content:
    this.renderChannelDescription = function(bWrite) {
        var objTR = document.createElement("tr");

        var objTDlbl = document.createElement("td");
        $(objTDlbl)
            .addClass("lbl")
            .text(IG.label.get("channel_description"))
            .appendTo(objTR);

        var objTDinp = document.createElement("td");
        $(objTDinp).appendTo(objTR);

        var IGTextField = new IG.TextField({
            id: "channel-description",
            texts: _this.item.description,
            language: _this.elementsLang,
            readonly: !bWrite,
            onchange: function(language, s) {
                _this.fn.setDescription(language, s);
            }
        });

        _this.textFields.push(IGTextField);
        $(objTDinp).append(IGTextField.render());

        return objTR;
    };

    //Renders editors row of basic content:
    this.renderChannelEditors = function(bWrite) {
        var objTR = document.createElement("tr");

        var objTDlbl = document.createElement("td");
        $(objTDlbl)
            .addClass("lbl")
            .text(IG.label.get("channel_editors"))
            .appendTo(objTR);

        var objTDinp = document.createElement("td");

        var groupPermisions = {};
        var userPermisions = {};
        if (_this.isNew) {//if it is new we need to copy permisions from user profile
            var user = $.grep(IG.lists.users, function(u, i) { return u.id.id == IG.userConfig.userId.id; });
            groupPermisions = user[0].defaultCreatePermissions.groups;
            userPermisions = user[0].defaultCreatePermissions.users;
        } else {
            groupPermisions = _this.item.permissions.groups;
            userPermisions = _this.item.permissions.users;
        }

        var IGPermissionsGroups = new IG.PermissionsBox({
            type: "groups",
            key: "CHANNEL",
            title: IG.label.get("channel_group_box_title"),
            items: IG.lists.groups,
            permissions: groupPermisions,
            write: bWrite,
            fnAdd: _this.fn.addPermission,
            fnRemove: _this.fn.removePermission,
            fnHasPermission: _this.fn.hasPermission
        });

        $(objTDinp)
            .append(IGPermissionsGroups.render())
            .appendTo(objTR);

        IG.users.loadEnabled(_this.userPermissions, function(userData) {
            if (userData) {
                var IGPermissionsUsers = new IG.PermissionsBox({
                    type: "users",
                    key: "CHANNEL",
                    title: IG.label.get("channel_users_box_title"),
                    items: userData.data,
                    count: _this.userPermissions.count,
                    permissions: userPermisions,
                    write: bWrite,
                    fnAdd: _this.fn.addPermission,
                    fnRemove: _this.fn.removePermission,
                    fnHasPermission: _this.fn.hasPermission,
                    fnLoadMore: function(callback) {
                        _this.userPermissions.offset += _this.userPermissions.limit;

                        IG.users.loadEnabled(_this.userPermissions, function(userData) {
                            if ($.isFunction(callback)) {
                                callback(userData.data);
                            }
                        });
                    }
                });

                $(objTDinp)
                    .append(IGPermissionsUsers.render());

            }
        });
        return objTR;
    };


    //Renders channel sharing block - iff this is enabled for customer and user:
    this.renderChannelSharing = function(bWrite) {
        IG.debug("Channel.renderChannelSharing");

        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-section edit-section-margin");

        var objH3sharing = document.createElement("h4");
        $(objH3sharing)
            .text(IG.label.get("channel_sharing_title")) //Delg
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content")
            .appendTo(objDiv);


        var objTable = document.createElement("table");
        $(objTable)
            .addClass("sharing")
            .appendTo(objDivContent);

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);

        var objTR = document.createElement("tr");
        $(objTR).appendTo(objTBody);

        var objTDlbl = document.createElement("td");
        $(objTDlbl)
            .addClass("lbl")
            .appendTo(objTR);

        var objTDavailable = document.createElement("td");
        $(objTDavailable)
            .addClass("select")
            .appendTo(objTR);

        var objTDbuttons = document.createElement("td");
        $(objTDbuttons)
            .addClass("buttons")
            .appendTo(objTR);

        var objTDselected = document.createElement("td");
        $(objTDselected)
            .addClass("select")
            .appendTo(objTR);



        //Create multi-select with available customer ids:
        var objSelectAvailable = document.createElement("select");
        $(objSelectAvailable)
            .attr({
                "name": "available",
                "id": "ig-channel-share-available",
                "size": "5",
                "multiple": "multiple"
            })
            .appendTo(objTDavailable)
            .wrap('<span class="input"></span>');

        if (!bWrite) {
            $(objSelectAvailable).attr("disabled", "disabled");
        }

        //Populates select with available customer ids:
        var fnPopulateAvailable = function() {
            $(objSelectAvailable).empty();
            $.each(IG.customerConfig.sharingTargets, function(i, n) {
                var bFound = false;

                $.each(_this.item.sharedWithCustomerIds, function(j, m) {
                    if (i == m) {
                        bFound = true;
                        return false; //break loop
                    }
                });

                if (!bFound) {
                    var objOption = document.createElement("option");
                    $(objOption)
                        .text(n)
                        .val(i)
                        .appendTo(objSelectAvailable);
                }
            });
        };

        fnPopulateAvailable();



        //Create buttons container:
        var objDivButtons = document.createElement("div");
        $(objDivButtons)
            .addClass("buttons")
            .appendTo(objTDbuttons);


        //Create select with selected customer ids:
        var objSelect = document.createElement("select");
        $(objSelect)
            .attr({
                "name": "selected",
                "id": "ig-channel-share-selected",
                "size": "5",
                "multiple": "multiple"
            })
            .appendTo(objTDselected)
            .wrap('<span class="input"></span>');

        if (!bWrite) {
            $(objSelect).attr("disabled", "disabled");
        }

        //Populates selected with the selected customer ids:
        var fnPopulateSelected = function() {
            $(objSelect).empty();
            $.each(IG.customerConfig.sharingTargets, function(i, n) {
                var bFound = false;
                $.each(_this.item.sharedWithCustomerIds, function(j, m) {
                    if (i == m) {
                        bFound = true;
                        return false;
                    }
                });

                if (bFound) {
                    var objOption = document.createElement("option");
                    $(objOption)
                        .text(n)
                        .val(i)
                        .appendTo(objSelect);
                }
            });
        };

        fnPopulateSelected();


        //Create add button:
        var btnAdd = document.createElement("a");
        $(btnAdd)
            .addClass("btn-normal btn-dark")
            .html('<span>' + IG.label.get("global_add") + ' &gt;</span>')
            .appendTo(objDivButtons);

        if (bWrite) {
            $(btnAdd).click(function() {
                $.each($(objSelectAvailable).find("option:selected"), function(i, n) {
                    var id = $(n).val();
                    if (_this.fn.addCustomerId(id)) {
                        var objOption = $(n).clone();
                        $(objOption)
                            .removeAttr("selected")
                            .appendTo(objSelect);
                        $(n).remove();
                    }
                });
                return false;
            });
        }
        else {
            $(btnAdd).addClass("disabled");
        }


        //Create remove button:
        var btnRemove = document.createElement("a");
        $(btnRemove)
            .addClass("btn-normal btn-dark")
            .html('<span>&lt; ' + IG.label.get("global_remove") + '</span>')
            .appendTo(objDivButtons);

        if (bWrite) {
            $(btnRemove).click(function() {
                $.each($(objSelect).find("option:selected"), function(i, n) {
                    var id = $(n).val();
                    if (_this.fn.removeCustomerId(id)) {
                        $(n).remove();
                        fnPopulateAvailable();
                    }
                });
                return false;
            });
        }
        else {
            $(btnRemove).addClass("disabled");
        }

        return objDiv;
    };


    //Builds the save button, if user has permission:
    this.renderButtons = function(bWrite) {
        var objTable = document.createElement("table");
        var objTr = document.createElement("tr");
        $(objTr)
            .appendTo(objTable);

        var objTdLbl = document.createElement("td");
        $(objTdLbl)
            .addClass("lbl")
            .appendTo(objTr);

        var objTdBtn = document.createElement("td");
        $(objTdBtn)
            .appendTo(objTr);

        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("ig-edit-section buttons")
            .appendTo(objTable);

        var btnSave = document.createElement("a");
        $(btnSave)
            .addClass("btn-normal btn-dark")
            .click(function() {
                _this.updateChannel({
                    success: function(object) {
                        // Stay on edit tab
                        /*IG.showEditChannel({
                            object: object,
                            history: false,
                            searchLang: _this.elementsLang
                        });*/
                    }
                });

                return false;
            })
            .html('<span>' + IG.label.get("global_save") + '</span>')
            .appendTo(objTdBtn);

        return objTable;
    };

    /* Builds basic content: */
    this.renderBasicContent = function(bWrite) {
        var objDiv = document.createElement("div");

        if (_this.item != null) {
            var objDivBasic = document.createElement("div");
            $(objDivBasic)
                .addClass("ig-edit-section")
                .appendTo(objDiv);

            var objTable = document.createElement("table");
            $(objTable)
                .append(_this.renderChannelName(bWrite))
                .append(_this.renderChannelDescription(bWrite))
                .append(_this.renderChannelEditors(bWrite))
                .appendTo(objDivBasic);

            $(objDivBasic).append(_this.renderChannelSharing(bWrite));


            if (bWrite) {
                $(objDivBasic).append(_this.renderButtons());
            }
        }

        return objDiv;
    };




    //Refreshes the list of associated InfoObjects:
    this.refreshInfoObjects = function(cb) {
        IG.debug("Channel.refreshInfoObjects");

        var idPortion = _this.item.infoObjectIds.slice(_this.infoObjContainerOptions.offset,
            _this.infoObjContainerOptions.offset + _this.infoObjContainerOptions.limit);
        var options = {
            ids: idPortion
        };

        _this.loadInfoObjects(options, function(objects) {
            padInfoObjectContainer(function() {
                _this.renderInfoObjects(objects, function() {
                    if ($.isFunction(cb)) {
                        cb();
                    }
                });
            });

        });
    };




    //Renders the hover options for an InfoObject item in list:
    this.renderInfoObjectOptions = function(object, parentObj) {
        var objOptions = document.createElement("span");
        $(objOptions).addClass("menu");

        var objEdit = document.createElement("span");
        $(objEdit)
            .addClass("menu-item")
            .text(IG.label.get("global_edit"))
            .click(function() {
                //FIXME: check if Channel has been updated!
                IG.showEditInfoObject({
                    id: object.id["id"],
                    history: true
                });
            })
            .appendTo(objOptions);


        if (_this.bWrite) {
            var objRemove = document.createElement("span");
            $(objRemove)
                .addClass("menu-item")
                .text(IG.label.get("global_remove"))
                .click(function() {
                    if (_this.fn.removeInfoObject(object)) {
                        _this.updateChannel({
                            success: function() {
                                $(parentObj).remove();
                                padInfoObjectContainer(function() {
                                    _this.renderInfoObjects({}, function() { });
                                });
                            }
                        });
                    }
                    return false;
                })
                .appendTo(objOptions);
        }
        return objOptions;
    };  

    //Renders a single InfoObject for the list of associated InfoObjects:
    this.renderInfoObject = function(object) {

        var sTitle = IG.fn.stripHTML(object.headline[_this.elementsLang]);

        var objItem = document.createElement("span");
        $(objItem)
            .addClass("item")
            .attr({
                "id": object.id.id,
                "title": sTitle
            })
            .click(function() {

            })
            .hover(
                function() {
                    var _me = this;
                    $(this).append(_this.renderInfoObjectOptions(object, _me));
                },
                function() {
                    $(this).children(".menu").remove();
                }
            );
        
        if (!object.published) {
            var objFade = document.createElement("div");
            $(objFade)
                .addClass("fade")
                .appendTo(objItem)
                .html('<span class="fade">'+IG.fn.getInfoObjectStatus(object).caption+'</span>');
        }
        
        var objImgBlock = document.createElement("span");
        $(objImgBlock)
            .addClass("img")
            .appendTo(objItem);


        var objImg = document.createElement("img");
        $(objImg)
            .attr({
                "src": (object.thumbnailUrl != "" ? object.thumbnailUrl : "images/blank.gif"),
                "width": "116"
            })
            .appendTo(objImgBlock);


        var objText = document.createElement("span");
        $(objText)
            .addClass("text")
            .text(sTitle)
            .appendTo(objItem)
            .click(function() {
                //FIXME: check if Channel has been updated!            
                IG.showEditInfoObject({
                    id: object.id["id"],
                    history: true
                });
            });

        var objExpires = document.createElement("span");
        $(objExpires)
            .addClass("expires")
            .text(IG.label.get("global_expires") + ': ' + IG.fn.formatTime(object.displayEndTime))
            .appendTo(objItem)
            .click(function() {
                //FIXME: check if Channel has been updated!            
                IG.showEditInfoObject({
                    id: object.id["id"],
                    history: true
                });
            });

        var docTypeName = IG.label.get("info_object_list_" + object.type + "_info_object");
        if (docTypeName == "info_object_list_" + object.type + "_info_object") {
            var obj = IG.getDocumentType(object.type);
            if (obj !== null) {
                docTypeName = (typeof obj.name[IG.config.language] !== 'undefined'
                    && obj.name[IG.config.language] !== null
                    ? obj.name[IG.config.language]
                    : IG.label.get('doc_type_unknown_translation'));
            }
        }

        var objType = document.createElement("span");
        $(objType)
            .addClass("expires ellipsis")
            .text(IG.label.get('info_object_list_type') + ': ' + docTypeName)
            .appendTo(objItem)
            .click(function() {
                IG.showEditInfoObject({
                    id: object.id["id"],
                    history: true
                });
            });

        return objItem;
    };


    //Filters the InfoObjects according to selected date:
    this.filterInfoObjects = function(objects) {
        var dt = new Date();
        var res = [];
        if (objects.length > 0) {
            switch (_this.dateFilter.key) {
                case "future":
                    dt = dateAdd("d", 1, dt);
                    dt.setHours(0, 0, 0, 0);

                    res = $.grep(objects, function(n, i) {
                        var dtStart = IG.fn.timestampToDate(n.displayStartTime);
                        var dtEnd = IG.fn.timestampToDate(n.displayEndTime);
                        dtStart.setHours(0, 0, 0, 0);
                        dtEnd.setHours(0, 0, 0, 0);

                        return (dtStart >= dt);
                    });

                    break;

                case "date":
                    dt = _this.dateFilter.date;
                    dt.setHours(0, 0, 0, 0);

                    res = $.grep(objects, function(n, i) {
                        var dtStart = IG.fn.timestampToDate(n.displayStartTime);
                        var dtEnd = IG.fn.timestampToDate(n.displayEndTime);
                        dtStart.setHours(0, 0, 0, 0);
                        dtEnd.setHours(0, 0, 0, 0);

                        return (dtStart <= dt) && (dtEnd >= dt);
                    });

                    break;

                case "all":
                    res = objects;

                    break;

                //Today's items:                                       
                default:
                    var startTime = new Date(dt.setHours(0, 0, 0, 0));
                    var endTime = new Date(dt.setHours(23, 59, 59, 999));

                    res = $.grep(objects, function(n, i) {
                        var dtStart = IG.fn.timestampToDate(n.displayStartTime);
                        var dtEnd = IG.fn.timestampToDate(n.displayEndTime);
                        return (dtEnd.getTime() >= startTime.getTime() && dtStart.getTime() <= endTime.getTime());
                    });
                    break;
            }

        }
        return res;
    };

    //Renders the list of associated InfoObjects:
    this.renderInfoObjects = function(options, cb) {
        var objDiv = $("#channel-infoobjects-wrap").get(0);
        options = (typeof options !== 'undefined' ? options : {});
        options.redraw = (typeof options.redraw !== 'undefined' ? options.redraw : false);
        if (objDiv) {
            var lastVisible = _this.allInfoObjects.getLastVisible();
            var filteredObjects = _this.allInfoObjects.getVisible();
            var renderList = [];

            if (options.redraw) {
                lastVisible = {};
                $(objDiv).empty();
            }

            $.each(filteredObjects, function(i, n) {
                // $(objDiv).append($(_this.renderInfoObject(n)).hide().fadeIn());
                if (typeof lastVisible[n.id.id] === 'undefined') {
                    // Doesn't already exist, so add
                    renderList.push(_this.renderInfoObject(n));

                } else {
                    // Is already shown
                    delete lastVisible[n.id.id];
                }

            });

            if (renderList.length > 0) {
                if (_this.infoObjContainerOptions.direction == 'left') {
                    for (var i=renderList.length; i>-1; i--) {
                        var objEle = renderList[i];
                        $(objDiv).prepend(objEle);
                        $(objEle).hide().fadeIn();

                        // remove one old:
                        for (var objDel in lastVisible) {
                            $('#'+objDel).remove();
                            delete lastVisible[objDel];
                            break;
                        }
                    }
                } else {
                    $.each(renderList, function(i, objEle) {
                        $(objDiv).append(objEle);
                        $(objEle).hide().fadeIn();

                        // remove one old:
                        for (var objDel in lastVisible) {
                            $('#'+objDel).remove();
                            delete lastVisible[objDel];
                            break;
                        }
                    });
                }

            }
            // make sure the list is empty:
            $.each(lastVisible, function(i, objId) {
                $('#'+objId).remove();
            });
        }
        if ($.isFunction(cb)) {
            cb();
        }
    };


    // Listen for scrolling on the horizontal infoObject list
    this.enableScrollListening = function() {

        // Listen for scroll events and limit loading with a timer
        $('#channel-infoobjects').scroll(function(event) {
            var _me = $(this);
            var scrollLeft = event.target.scrollLeft;



            if (_this.infoObjContainerOptions.timer) {
                clearTimeout(_this.infoObjContainerOptions.timer);
            }

            _this.infoObjContainerOptions.timer = setTimeout(function () {



                if (scrollLeft > _this.infoObjContainerOptions.left) {
                    _this.infoObjContainerOptions.direction = 'right';
                } else {
                    _this.infoObjContainerOptions.direction = 'left';
                }

                padInfoObjectContainer(_this.renderInfoObjects);

                _this.infoObjContainerOptions.left = scrollLeft;
            }, _this.infoObjContainerOptions.scrollDelay);


        });
    };

    // Made it private
    var padInfoObjectContainer = function(callback) {
        var itemCountLeft = 0,
            itemCountVisible = 0,
            itemCountRight = 0;

        var totalItemCount = _this.allInfoObjects.sortedAndFiltered.length;

        var container = $('#channel-infoobjects');
        var containerWrap = $('#channel-infoobjects-wrap');
        var scrollLeft = container.scrollLeft();
        var item = containerWrap.children('.item').get(0);
        var itemWidth = (typeof item !== 'undefined' ? $(item).outerWidth(true) : 121);
        var totalWidth = totalItemCount * itemWidth;

        var containmentWidth = 0;
        itemCountVisible = Math.ceil(container.width() / itemWidth) + 2;
        itemCountVisible = (itemCountVisible > totalItemCount ? totalItemCount : itemCountVisible);
        _this.infoObjContainerOptions.itemCountVisible = itemCountVisible;
        if (container.width() > totalWidth) {
            containmentWidth = totalWidth;
        } else {
            containmentWidth = itemCountVisible * itemWidth;
        }

        itemCountLeft = Math.floor(scrollLeft/itemWidth) - 1;
        itemCountLeft = ( (itemCountLeft<0 ) ? 0 : itemCountLeft);
        itemCountLeft = ( (itemCountLeft+itemCountVisible) > totalItemCount ? totalItemCount-itemCountVisible : itemCountLeft);
        _this.infoObjContainerOptions.itemCountLeft = itemCountLeft;
        var paddingLeft = itemCountLeft * itemWidth;

        itemCountRight = totalItemCount - itemCountLeft - itemCountVisible;
        itemCountRight = ( (itemCountRight<0 ) ? 0 : itemCountRight);
        // itemCountRight = ( (itemCountLeft+itemCountVisible) > totalItemCount ? 0 : itemCountRight);
        _this.infoObjContainerOptions.itemCountRight = itemCountRight;
        var paddingRight = itemCountRight * itemWidth;

        if (paddingRight <= 0) {
            paddingRight = 19;
        }

        containerWrap
            .width(containmentWidth)
            .css({
                'padding-left': paddingLeft+'px',
                'padding-right': paddingRight+'px'
            });

        if ($.isFunction(callback)) {
            callback();
        }
    };

    // private method that's recursively loading all infoObjects
    var recursiveInfoObjectLoader = function() {

        if (_this.item.infoObjectIds.length <= 0 || !_this.allowRecursiveLoad) {
            return false;
        }
        if (_this.infoObjContainerOptions.offset < _this.item.infoObjectIds.length) {
            var idPortion = _this.item.infoObjectIds.slice(_this.infoObjContainerOptions.offset,
                _this.infoObjContainerOptions.offset + _this.infoObjContainerOptions.limit);
            var options = {
                proceedRequests: true,
                ids: idPortion
            };
            _this.loadInfoObjects(options, function(objects) {
                padInfoObjectContainer(function() {
                    _this.renderInfoObjects({}, function() {
                        if (options.proceedRequests) {
                            // Don't be too greedy - IE doesn't like it
                            window.setTimeout(function() {
                                recursiveInfoObjectLoader();
                            }, 50);
                        }
                    });
                });
            });
        }
    };

    //Loads the InfoObjects associated with this channel:
    this.loadInfoObjects = function(options, cb) {

        if (_this.item.infoObjectIds.length > 0) {
            //FIXME: filter by supplied options:
            //FIXME: how to filter by date?! Clientside?

            IG.request({
                method: "searchInfoObjects",
                params: [
                    IG.config.language,                 // language
                    _this.elementsLang,                 // search language
                    "",                                 // query
                    false,                              // light weight
                    (typeof options.ids !== 'undefined' ? { ids: options.ids } : {}),  // filters
                    {'headline': 'asc'},                                 // sorts
                    0,      // limit
                    0      // offset
                ],
                success: function(response) {
                    var responseData = response.data["data"];
                    _this.allInfoObjects.addArray(responseData);

                    // increment offset after load:
                    _this.infoObjContainerOptions.offset += _this.infoObjContainerOptions.limit;

                    if ($.isFunction(cb)) {
                        cb({});
                    }
                    options.proceedRequests = true;
                },
                error: function(err) {
                    options.proceedRequests = false;
                    if ($.isFunction(cb)) {
                        cb([]);
                    }
                }
            });
        }
        else {
            if ($.isFunction(cb)) {
                cb([]);
            }
        }
    };


    /* Builds main content: */
    this.renderContent = function(bWrite) {
        var objDiv = document.createElement("div");

        if (_this.item != null) {

            _this.IGObjectList = new IG.ObjectList({
                startLimit: 50,
                methodName: "searchInfoObjects",
                subelements: false,
                filters: {
                    parentsOnly: true
                },
                page: "channel-avail"
            });


            //FIXME: separate class and functions for InfoObjects content slider!?

            //Create title/headline:
            var objTitle = document.createElement("h3");
            $(objTitle)
                .attr("id", "channel-content-title")
                .addClass("ig-title")
                .text(IG.label.get("channel_todays_info_objects_title") + ' "' + _.first(_.values(_this.item.name)) + '"')
                .appendTo(objDiv);

            //Build content filter:
            var objDivContentFilter = document.createElement("div");
            $(objDivContentFilter)
                .addClass("ig-filter filter-options")
                .attr("id", "channel-content-filter")
                .html('<span>' + IG.label.get("channel_show_for") + '</span>')
                .appendTo(objDiv);


            //All future content:
            var objAfuture = document.createElement("a");
            $(objAfuture)
                .text(IG.label.get("channel_all_future"))
                .click(function() {
                    var _me = this;
                    _this.dateFilter.key = "future";

                    //FIXME: set date filtering:
                    _this.allInfoObjects.sortAndFilter();
                    padInfoObjectContainer(function() {
                        _this.renderInfoObjects({
                            redraw: true
                        }, function() {
                            $(_me).addClass("current");
                            $(_me).siblings("a").removeClass("current");
                            $(objTitle).text(IG.label.get("channel_future_info_objects_title") + ' "' + _this.item.name[_this.elementsLang] + '"');
                        });
                    });


                    return false;
                })
                .appendTo(objDivContentFilter);

            $(objDivContentFilter).append('<span class="list-pipe">|</span>');


            //Today's content:
            var objAtoday = document.createElement("a");
            $(objAtoday)
                .addClass("current")
                .text(IG.label.get("channel_today"))
                .click(function() {
                    var _me = this;

                    _this.dateFilter.key = "today";
                    _this.dateFilter.date = new Date();

                    _this.allInfoObjects.sortAndFilter();
                    padInfoObjectContainer(function() {
                        _this.renderInfoObjects({
                            redraw: true
                        }, function() {
                            $(_me).addClass("current");
                            $(_me).siblings("a").removeClass("current");
                            $(objTitle).text(IG.label.get("channel_todays_info_objects_title") + ' "' + _this.item.name[_this.elementsLang] + '"');
                        });
                    });

                    return false;
                })
                .appendTo(objDivContentFilter);
            $(objDivContentFilter).append('<span class="list-pipe">|</span>');


            //All content:
            var objAAll = document.createElement("a");
            $(objAAll)
                .text(IG.label.get("channel_all") + ' (' + _this.item.infoObjectIds.length + ')')
                .attr('id', 'tab_date_filter_all')
                .click(function() {
                    var _me = this;
                    _this.dateFilter.key = "all";

                    _this.allInfoObjects.sortAndFilter();
                    padInfoObjectContainer(function() {
                        _this.renderInfoObjects({redraw: true}, function() {
                            $(_me).addClass("current");
                            $(_me).siblings("a").removeClass("current");
                            $(objTitle).text(IG.label.get("channel_all_info_objects_title") + ' "' + _this.item.name[_this.elementsLang] + '"');
                        });
                    });

                    return false;
                })
                .appendTo(objDivContentFilter);
            $(objDivContentFilter).append('<span class="list-pipe">|</span>');


            var objAdt = document.createElement("a");
            $(objAdt).appendTo(objDivContentFilter);


            $(objAdt)
                .text(IG.label.get("channel_specific_date_infoobjects"))
                .click(function(event) {
                    event.preventDefault();

                    var dtp = new IG.DateTimePicker({
                        current: _this.item.displayStartTime,
                        showTime: false,
                        displayFormat: 'LL'
                    });

                    $( "#modal-window" )
                        .html(dtp.getContent())
                        .dialog({
                            modal: true,
                            title: '',
                            position: { my: "center", at: "center", of: window, collision: 'fit' },
                            width: 'auto',
                            height: 'auto',
                            buttons: [
                                {
                                    text: IG.label.get("ok"),
                                    click: function() {
                                        dtp.save(function(UTCtimeStampSecs, formattedStr) {

                                            _this.dateFilter.key = "date";
                                            _this.dateFilter.date = new Date(UTCtimeStampSecs*1000);
                                            _this.allInfoObjects.sortAndFilter();
                                            padInfoObjectContainer(function() {
                                                _this.renderInfoObjects({redraw: true}, function() {
                                                    $(objAdt).addClass("current");
                                                    $(objAdt).siblings("a").removeClass("current");
                                                    $(objTitle).text(IG.label.get("channel_specific_date_info_objects_title") + ' '
                                                        + _this.item.name[_this.elementsLang] + ", " + IG.label.get("global_on") + " "
                                                        + formattedStr);
                                                });
                                            });

                                            $( "#modal-window" ).dialog( "close" );
                                        });
                                    }
                                },
                                {
                                    text: IG.label.get("cancel"),
                                    click: function() {
                                        $( this ).dialog( "close" );
                                    }
                                }
                            ],

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 150,
                            maxHeight: false
                        });


                    /* TODO remove, Lars
                    //Trigger date selector overlay by setting focus to hidden input field:
                    $(objInputDt).trigger("focus");
                    return false;
                    */
                });




            //Infoobject slider:
            var objDivInfoObjects = document.createElement("div");
            $(objDivInfoObjects)
                .addClass("clearfix")
                .attr("id", "channel-infoobjects")
                .appendTo(objDiv);

            var objDivChanInfoObjectsContainer = document.createElement('div');
            $(objDivChanInfoObjectsContainer)
                .attr('id', 'channel-infoobjects-wrap')
                .appendTo(objDivInfoObjects);

            var objDivInfoObjectList = document.createElement("div");


            //Search:
            var objDivSearch = document.createElement("div");
            $(objDivSearch).appendTo(objDiv);

            var objTitleSearch = document.createElement("h3");
            $(objTitleSearch)
                .addClass("ig-title")
                .text(IG.label.get("channel_add_info_object"))
                .appendTo(objDivSearch);

            var objDivSearchContent = document.createElement("div");
            $(objDivSearchContent)
                .addClass("ig-search")
                .attr("id", "channel-search")
                .appendTo(objDivSearch);

            var searchLang = _this.searchLang;

            var objInpSearch = document.createElement("input");
            $(objInpSearch)
                .attr({
                    "type": "text",
                    "name": "keywords",
                    "id": "channel-search-keywords",
                    "value": IG.label.get("channel_search_infoobjects_watermark"),
                    "class": 'search'
                })
                .focus(function() {
                    var v = $(this).val();
                    if (v == this.defaultValue) {
                        $(this).val("");
                    }
                    $(this)
                        .addClass("search-default")
                        .removeClass("search-default-deco");
                })
                .blur(function() {
                    var v = $(this).val();
                    if (v == "" || v == this.defaultValue) {
                        $(this).val(this.defaultValue);
                    }
                    $(this)
                        .addClass("search-default-deco")
                        .removeClass("search-default");
                })
                .keyup(function(e) {
                    var v = $(this).val();
                    var key = e.which ? e.which : e.keyCode;
                    if (key == 13) {
                        var query = (v != objInpSearch.defaultValue ? v : '');
                        _this.searchLang = searchLang;
                        if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                            $(objLangSel).addClass('search-lang-deco');
                        } else $(objLangSel).removeClass('search-lang-deco');
                        _this.IGObjectList.setQuery(query, _this.searchLang);
                        _this.IGObjectList.refresh(function() {
                        });

                    }
                })
                .appendTo(objDivSearchContent);

            // Language selector for search query
            var objLangSel = _this.IGObjectList.renderSearchLanguageSelector({
                onChange: function(n) {
                    searchLang = n;
                },
                searchLang: searchLang
            });
            if (objLangSel) $(objDivSearchContent).append(objLangSel);

            var btnSearch = document.createElement("a");
            $(btnSearch)
                .addClass("btn-normal btn-dark")
                .html('<span>' + IG.label.get("global_search") + '</span>')
                .click(function() {
                    var v = $(objInpSearch).val();
                    var query = (v != objInpSearch.defaultValue ? v : '');
                    _this.searchLang = searchLang;
                    if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                        $(objLangSel).addClass('search-lang-deco');
                    } else $(objLangSel).removeClass('search-lang-deco');

                    _this.IGObjectList.setQuery(query, _this.searchLang);
                    _this.IGObjectList.refresh(function() {
                    });
                    return false;
                })
                .appendTo(objDivSearchContent);



            $(objDivInfoObjectList)
                .attr("id", "channel-infoobject-list")
                .appendTo(objDiv);

            

            _this.IGObjectList.config.parentObject = objDivInfoObjectList;
            _this.IGObjectList.config.columns = [
                {
                    name: "",
                    title: "",
                    className: "buttons-add",
                    sortable: false,
                    width: "100px",
                    render: function(object) {
                        var objContainer = document.createElement('div');
                        _this.fn.renderAddChannelBtn(object, objContainer, bWrite);
                        return objContainer;
                    }
                },
                {
                    name: "headline",
                    title: IG.label.get("info_object_list_title"),
                    className: "title ellipsis",
                    sortable: true,
                    width: "auto",
                    renderTitle: function(object) {
                        return IG.fn.stripHTML(object.headline[_this.searchLang]);
                    },
                    render: function(object) {
                        var objA = document.createElement("a");
                        $(objA)
                            .text(IG.fn.stripHTML(object.headline[_this.searchLang]))
                            .click(function() {
                                //FIXME: check if current channel has been changed?
                            	
                                IG.showEditInfoObject({
                                    id: object.id["id"],
                                    history: true,
                                    searchLang: _this.searchLang
                                });
                                return false;
                            });
                        return objA;
                    }
                },
                {
                    name: "infoObjectType",
                    title: IG.label.get("info_object_list_type"),
                    className: "type ellipsis",
                    width: "85px",
                    sortable: true,
                    render: function(object) {
                        var docTypeName = IG.label.get("info_object_list_" + object.type + "_info_object");
                        if (docTypeName == "info_object_list_" + object.type + "_info_object") {
                            var obj = IG.getDocumentType(object.type);
                            if (obj !== null) {
                                docTypeName = (typeof obj.name[IG.config.language] !== 'undefined'
                                    && obj.name[IG.config.language] !== null
                                    ? obj.name[IG.config.language]
                                    : IG.label.get('doc_type_unknown_translation'));
                            }
                        }
                        return docTypeName;
                    }
                },
                {
                    name: "lastModified",
                    title: IG.label.get("info_object_list_last_modified"),
                    width: "130px",
                    className: "",
                    sortable: false,
                    render: function(object) {
                        return IG.fn.formatTime(object.lastModified);
                    }
                },
                {
                    name: "displayStartTime",
                    title: IG.label.get("info_object_list_displayed_from"),
                    width: "130px",
                    className: "",
                    sortable: false,
                    render: function(object) {
                        return IG.fn.formatTime(object.displayStartTime);
                    }
                },
                {
                    name: "published",
                    title: IG.label.get("info_object_list_status"),
                    width: "100px",
                    className: "status",
                    sortable: true,
                    render: function(object) {
                        var objStatus = IG.fn.getInfoObjectStatus(object);
                        var objSpan = document.createElement("span");
                        $(objSpan)
                            .addClass("status " + objStatus.statusClass)
                            .text(objStatus.caption)
                            .attr("title", objStatus.caption);

                        return objSpan;
                    }
                },
                {
                    name: "editor",
                    title: IG.label.get("info_object_list_editor"),
                    width: "110px",
                    className: "editor ellipsis",
                    sortable: false,
                    renderTitle: function(object) {
                        var objEditor = { name: "" };
                        if (object.editor) {
                            objEditor = IG.getUserById(object.editor["id"]);
                        }
                        if (objEditor === null) {
                            return IG.label.get('GLOBAL_MISSING_EDITOR_NAME');
                        }
                        return objEditor.name;
                    },
                    render: function(object) {
                        var objEditor = { name: "" };
                        if (object.editor) {
                            objEditor = IG.getUserById(object.editor["id"]);
                        }
                        var objSpan = document.createElement("span");
                        $(objSpan)
                            .css("white-space", "nowrap")   //ie7 fix for nowrap in td
                            .append((objEditor === null ? IG.label.get('GLOBAL_MISSING_EDITOR_NAME') : objEditor.name));
                        return $(objSpan);
                    }
                }
            ];

            if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                _this.IGObjectList.setQuery(undefined, _this.searchLang);
            }

            //Add function to perform after rendering:
            _this.fnAfterRender.push(function() {
                _this.IGObjectList.setSorting('headline', 'ASC');
                _this.IGObjectList.init(function() {
                });
            });
        }

        return objDiv;
    };






    //Renders channel information
    this.render = function(cb) {
        IG.debug("Channel.render");

        //FIXME: special handling of channels - ie. shared?
        _this.bAdmin = IG.userHasPermission("OP_CHANNEL_ADMIN");
        _this.bWrite = IG.userHasPermission("OP_CHANNEL_WRITE", _this.item) || _this.bAdmin;

        var objDiv = $("#ig-channel").get(0);
        if (objDiv != null) {
            $(objDiv).remove(); //Must remove to also kill all bound events to children etc.
        }
        objDiv = document.createElement("div");
        $(objDiv).attr("id", "ig-channel");


        //Create header:
        var objDivHeader = document.createElement("div");
        $(objDivHeader)
            .attr("id", "ig-channel-header")
            .appendTo(objDiv);

        //Create headline:
        var objH2 = document.createElement("h2");
        $(objH2)
            .text(_this.isNew ? IG.label.get("channel_create") : IG.label.get("channel_edit"))
            .appendTo(objDivHeader);

        $(objDivHeader).append(_this.renderLanguageSelector());

        //Build tabs bar:
        var objTabs = document.createElement("div");
        $(objTabs)
            .addClass("ig-tabs")
            .attr("id", "ig-channel-tabs")
            .appendTo(objDiv);

        var objTabsContainer = document.createElement("span");
        $(objTabsContainer)
            .addClass("ig-tabs-content")
            .attr("id", "ig-channel-tabs-content")
            .appendTo(objTabs);

        var objTabItemBasic = document.createElement("a");
        $(objTabItemBasic)
            .html('<span>' + IG.label.get("channel_basicinfo_tab_title") + '</span>')
            .appendTo(objTabsContainer);

        if (!_this.isNew) {
            var objTabItemContent = document.createElement("a");
            $(objTabItemContent)
            .html('<span>' + IG.label.get("channel_content_tab_title") + '</span>')
            .appendTo(objTabsContainer);
        }


        //Build tabs data items:
        var objDivTabsData = document.createElement("div");
        $(objDivTabsData).addClass("ig-tabs-data");

        var objTabDataBasic = document.createElement("div");
        $(objTabDataBasic)
            .attr("id", "ig-channel-basic")
            .addClass("ig-tabs-data-item")
            .append(_this.renderBasicContent(_this.bWrite))
            .appendTo(objDivTabsData);

        if (!_this.isNew) {
            var objTabDataContent = document.createElement("div");
            $(objTabDataContent)
                .attr("id", "ig-channel-content")
                .addClass("ig-tabs-data-item")
                .append(_this.renderContent(_this.bWrite))
                .appendTo(objDivTabsData);
        }



        if (_this.isNew) {
            $([objTabItemBasic, objTabDataBasic]).addClass("current");

        }
        else {
            $([objTabItemContent, objTabDataContent]).addClass("current");
        }

        $(objTabItemBasic).click(function() {
            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataBasic;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
        });

        $(objTabItemContent).click(function() {
            $(this).addClass("current");
            $(this).siblings().removeClass("current");

            var objDiv = objTabDataContent;
            $(objDiv).addClass("current");
            $(objDiv).siblings().removeClass("current");
        });

        $(objDiv).append(objDivTabsData);



        $("#ig-data").html(objDiv);


        $.each(_this.fnAfterRender, function(i, n) {
            if ($.isFunction(n)) {
                n();
            }
        });

        if ($.isFunction(cb)) {
            cb();
        }

    };


    this.loadChannel = function(obj, cb) {
        IG.debug("IG.Channel.loadChannel");

        _this.setItem(obj);
        cb();
    };


    //Fetch the full Channel object:
    this.loadChannelById = function(id, fnSuccess, fnError) {
        var idTemp = id;
        if (typeof idTemp.id != "undefined") {
            idTemp = idTemp.id;
        }
        IG.debug("IG.Channel.loadChannelById");
        IG.load.channel({
            id: idTemp,
            elementsLang: _this.elementsLang,
            success: function(object) {
            	if (object.infoObjectIds.length != undefined && object.infoObjectIds.length > 0) {
            		$.each(object.infoObjectIds, function(i, val) {
            			if (val != undefined && val.id != undefined && typeof val.id == "string" && val.id.length < 1) {
            				object.infoObjectIds.splice(i, 1);
            			}
            		});
            	} else {
            		//console.log('not array');
            	}
                _this.setItem(object);
                fnSuccess();
            },
            error: function(err) {
                fnError(err);
            }
        });
    };

    this.makeInfoObjectsSortable = function() {
        var dragStarPos = -1;

        //make infoobjects sortable
        $("#channel-infoobjects-wrap").sortable({
            start: function(e, ui) {
                dragStarPos = $('#'+ui.item.get(0).id).index();
            },
            stop: function(e, ui) {
                var container = $("#channel-infoobjects-wrap");
                var movedId = ui.item.get(0).id;
                var newRelativeIndex = -1;
                $("#channel-infoobjects-wrap span.item").each(function(i) {
                    if (movedId == $(this).attr("id")) {
                        newRelativeIndex = i;
                        return false;
                    }
                });

                // If it hasn't been moved, cancel the sort
                if (dragStarPos == newRelativeIndex) {
                    return false;
                }

                if (newRelativeIndex > -1) {
                    // Find the full id
                    var fullId = null;
                    var oldMediaIds = _this.item.infoObjectIds;
                    $.each(oldMediaIds, function(j, oid) {
                        if (oid.id == movedId) {
                            fullId = oid;
                            oldMediaIds.splice(j,1); // remove ID
                            return false;
                        }
                    });
                    var newTotalIndex = _this.infoObjContainerOptions.itemCountLeft + newRelativeIndex;

                    var siblingLeft = (newRelativeIndex-1 < 0 ? undefined : container.children('span.item').get(newRelativeIndex-1));
                    var siblingRight = container.children('span.item').get(newRelativeIndex+1);

                    // There is still a left sibling to place it next to:
                    if (typeof siblingLeft !== 'undefined') {
                        var sLeftId = $(siblingLeft).attr('id');
                        newTotalIndex = 0;
                        $.each(oldMediaIds, function(j, oid) {
                            if (oid.id == sLeftId) {
                                newTotalIndex = j+1;
                                return false;
                            }
                        });
                    }
                    // There is no one to the left - look for the right
                    else if (typeof siblingRight !== 'undefined') {
                        var sRightId = $(siblingRight).attr('id');
                        newTotalIndex = 0;
                        $.each(oldMediaIds, function(j, oid) {
                            if (oid.id == sRightId) {
                                newTotalIndex = j;
                                return false;
                            }
                        });
                    }

                    oldMediaIds.splice(newTotalIndex, 0, fullId);
                    _this.item.infoObjectIds = oldMediaIds;


                    IG.gui.loading.show();
                    _this.updateChannel({
                        success: function(obj) {
                            _this.allInfoObjects.sortAndFilter();
                            padInfoObjectContainer(function() {
                                _this.renderInfoObjects({}, function() { });
                            });
                            IG.gui.loading.hide();
                        }
                    });
                }
            },
            axis: 'x',
            scroll: true,
//            zIndex: 5,
//            forcePlaceholderSize: true,
            // forceHelperSize: true,
            // placeholder: "chan-sortable-placeholder",
            containment: "#channel-infoobjects-wrap",
            
            sort: function (event, ui) {
                var that = $(this),
                    w = ui.helper.outerWidth();
                that.children().each(function () {
                    if ($(this).hasClass('ui-sortable-helper') || $(this).hasClass('ui-sortable-placeholder')) 
                        return true;
                    // If overlap is more than half of the dragged item
                    var dist = Math.abs(ui.position.left - $(this).position().left),
                        before = ui.position.left > $(this).position().left;
                    if ((w - dist) > (w / 2) && (dist < w)) {
                        if (before)
                            $('.ui-sortable-placeholder', that).insertBefore($(this));
                        else
                            $('.ui-sortable-placeholder', that).insertAfter($(this));
                        return false;
                    }
                });
            }
        }).disableSelection();
    };

    this.init = function(options) {
        _this.fnAfterRender = [];
        _this.allInfoObjects.empty(); // empty the list before populating it again
        _this.allInfoObjects.visible = _this.infoObjContainerOptions.limit;
        _this.infoObjContainerOptions.reset();
        _this.allowRecursiveLoad = true;

        _this.userPermissions.count = 0;
        _this.userPermissions.offset = 0;

        _this.dateFilter.key = "today";
        _this.dateFilter.date = new Date();
        
        options.id = (typeof options.id != "undefined" ? options.id : null);
        options.object = (typeof options.object != "undefined" ? options.object : null);
        options.isNew = (typeof options.isNew == "boolean" ? options.isNew : false);
        options.elementsLang = (typeof options.elementsLang == "string" ? options.elementsLang : IG.customerConfig.defaultContentLanguage);
        _this.elementsLang =  options.elementsLang;
        _this.searchLang = options.elementsLang;

        IG.debug("IG.Channel.init, id: " + options.id + ", object: " + options.object + ", isNew: " + options.isNew);

        _this.isNew = options.isNew;

        //What to do when done loading object:
        var fnSuccess = function() {
            var idPortion = _this.item.infoObjectIds.slice(_this.infoObjContainerOptions.offset,
                _this.infoObjContainerOptions.offset + _this.infoObjContainerOptions.limit);
            var options = {
                ids: idPortion
            };

            //Load associated InfoObjects:
            _this.loadInfoObjects(options, function(objects) {
                _this.render(function() {
                    _this.allInfoObjects.sortAndFilter(function() {
                        padInfoObjectContainer(function() {
                            _this.renderInfoObjects(objects);
                        });
                        _this.enableScrollListening();

                        IG.gui.loading.hide();

                        // start the auto-loader:
                        window.setTimeout(function() {
                            recursiveInfoObjectLoader();
                        }, 500);
                    });

                    if (_this.bWrite) { // FIXME sortable
                        _this.makeInfoObjectsSortable();
                    }


                });
            });
        };

        var fnError = function(err) {
            if (typeof err == "string") {
                IG.showErrorDialog(err, function() {
                    //Go to root/dashboard:
                    IG.location.front();
                });
            }
            IG.gui.loading.hide();
        };


        //Got a full object - use this:
        if (options.object != null) {
            _this.loadChannel(options.object, fnSuccess);
        }
        //Else load by provided id:
        else {
            _this.loadChannelById(options.id, fnSuccess, fnError);
        }


    };

    this.unloadObject = function() {
        _this.allowRecursiveLoad = false;
        // _this.infoObjContainerOptions.reset();

        // Give some time to stop the loader:
        window.setTimeout(function() {
            _this.allInfoObjects.empty();
        }, 1000);
    };

    return this;
};


IG.showEditChannel = function(options) {
    var _IG = this;
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);
    options.id = (typeof options.id != "undefined" ? options.id : null);
    options.object = (typeof options.object != "undefined" ? options.object : null);
    options.isNew = (typeof options.isNew == "boolean" ? options.isNew : false);
    options.searchLang = (typeof options.searchLang == "string" ? options.searchLang : IG.customerConfig.defaultContentLanguage);
    
    // Google Analytics:
    // IG.config.prodMode ? _gaq.push(['_trackPageview']) : null;
    
    var objEditChannelObject = _IG.objects.get("channel");
    if (objEditChannelObject == null) {
        objEditChannelObject = _IG.objects.create("channel", new _IG.Channel());
    }



    //Parse url hash params if no id or object provided in options:
    if (options.id == null && options.object == null) {
        var urlParams = (typeof $.address.parameter(objEditChannelObject.hash()) != 'undefined' ? $.address.parameter(objEditChannelObject.hash()).split('|') : '');
        if (urlParams.length > 0) {
            var urlId = urlParams[0];

            if (urlId != "") {
                options.id = urlId;
                if (urlId == "0") {
                    IG.create.channel({
                        success: function(obj) {
                            options.object = obj;
                            options.isNew = true;
                        }
                    })
                }
            }
        }
    }


    IG.gui.setTitle(options.isNew ? IG.label.get("channel_create") : IG.label.get("channel_edit"));



    //Determine options to send to init function:
    var loadOptions = {
        isNew: options.isNew,
        elementsLang: options.searchLang
    };

    if (options.object != null) {
        loadOptions.object = options.object;
    }
    else {
        loadOptions.id = options.id;
    }

    //Add history state?
    if (options.history) {
        var h = objEditChannelObject.hash();
        if (options.isNew) {
            h += "?" + h + "=0";
        } else {
            h += "?" + h + "=" + (options.object != null ? options.object.id["id"] : options.id);
        }
        _IG.location.setCurrent(h);
    }

    IG.gui.loading.show();
    objEditChannelObject.init(loadOptions);

    $('#ig-user-menu-sections').find('a.channels').parent().addClass('menu-active');
};
