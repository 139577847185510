import React from 'react';

export const StatusLabelLegend = () => {
    return (
        <div className="status-label-legend">
            <h5 className="legend-header">Screendump af galleri</h5>
            <div>
                <p><span className="success status-led"/> Modtaget planmæssigt</p>
            </div>
            <div>
                <p><span className="warning status-led"/> Mere end 15 minutter siden (tjek internetforbindelsen)</p>
            </div>
            <div>
                <p><span className="error status-led"/> Mere end 24 timer siden (tjek om computeren er tændt og har internetforbindelse)</p>
            </div>
        </div>
    );
};
