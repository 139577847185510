IG.InfoObject = function() {
    var _this = this;
    this.hash = function() {
        return "infoobject";
    };

    this.item = {}; //the item loaded
    this.mediaItem = {};  //media item beeing edited through media archive
    this.itemMedia = [];
    this.itemHasChanged = false;

    this.tabData = [];

    this.infoObjectFields = null;

    this.isNew = false;
    this.isChildObj = false;
    this.parentObj = null;

    this.isChannelAdmin = IG.userHasPermission("OP_CHANNEL_ADMIN");

    this.elementsLang = IG.customerConfig.defaultContentLanguage; //'da'; //default labels language
    this.searchLang = IG.customerConfig.defaultContentLanguage;

    this.mediaArchive = null;
    this.mediaArchiveHeight = 600;

    this.userPermissions = {
        'userCount' : 0,
        'limit' : 30,
        'offset' : 0
    };

    this.infoObjectFields = null;

    this.setItem = function(initem) {
        _this.tabData = [];
        _this.item = initem;
        var hasMetadata = false;
        for (var md in _this.item.metadata) {
            hasMetadata = true;
        }
        if (!hasMetadata) {
            _this.item.metadata = {};
        }
        _this.itemHasChanged = false;
    };

    //Holds basic functions for setting/adding/removing properties:
    this.fn = {
        //Sets headline text for a given language:
        setHeadline: function(lang, str) {
            _this.item.headline[lang] = str;
            _this.itemHasChanged = true;
        },

        //Sets subheadline text for a given language:
        setSubHeadline: function(lang, str) {
            _this.item.subHeadline[lang] = str;
            _this.itemHasChanged = true;
        },

        //Sets body text for a given language:
        setBody: function(lang, str) {
            _this.item.body[lang] = str;
            _this.itemHasChanged = true;
        },


        //Sets display starttime for item:
        setDisplayStartTime: function(str) {
            _this.item.displayStartTime = IG.fn.dateStrToTimeStamp(str);
            _this.itemHasChanged = true;
        },

        //Sets display endtime for item:
        setDisplayEndTime: function(str) {
            _this.item.displayEndTime = IG.fn.dateStrToTimeStamp(str);
            _this.itemHasChanged = true;
        },


        //Adds a poll option:
        addPollOption: function(opt) {
            if (opt) {
                _this.item.pollOptions.push(opt);
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },

        //Sets the language specific value of a poll option:
        setPollOption: function(id, lang, val, isTemp) {
            isTemp = (typeof isTemp !== 'undefined' ? !!isTemp : false);
            $.each(_this.item.pollOptions, function(i, n) {
                if ( (!isTemp && n.id == id) || (isTemp && n.tmpId == id)) {
                    n.text[lang] = val;
                    _this.itemHasChanged = true;
                    return false; //break loop
                }
            });
        },

        //Removes a poll option:
        removePollOption: function(id, isTemp) {
            isTemp = (typeof isTemp !== 'undefined' ? !!isTemp : false);
            _this.item.pollOptions = $.grep(_this.item.pollOptions, function(n) {
                if (isTemp) {
                    return n.tmpId !== id;
                }
                return n.id !== id;
            });
            _this.itemHasChanged = true;
        },

        //Sets arrangement start time for item:
        setArrangementStartTime: function(str) {
            _this.item.arrangementStartTime = IG.fn.dateStrToTimeStamp(str);
            _this.itemHasChanged = true;
        },

        //Sets arrangement end time for item:
        setArrangementEndTime: function(str) {
            _this.item.arrangementEndTime = IG.fn.dateStrToTimeStamp(str);
            _this.itemHasChanged = true;
        },


        //Check if comment with given id exists:
        hasComment: function(id) {
            var bFound = false;
            $.each(_this.item.comments, function(i, n) {
                if (n.id == id) {
                    bFound = true;
                    return false; //break loop
                }
            });
            return bFound;
        },

        //Add a comment
        addComment: function() {
            _this.itemHasChanged = true;
            //Currently not supported
        },

        //Removes a comment from item
        removeComment: function(id) {
            var bFound = _this.fn.hasComment(id);
            if (bFound) {
                _this.item.comments = $.grep(_this.item.comments, function(n, i) {
                    return n.id != id;
                });
                _this.itemHasChanged = true;
            }
            return bFound;
        },


        //Sets url for item (drops existing urls in array and replaces with a single url):
        setUrl: function(s) {
            _this.item.urls = [];
            if (s != "") {
                _this.item.urls.push(s);
            }
            _this.itemHasChanged = true;
        },

        //Check if item has url:
        hasUrl: function(s) {
            var bFound = false;
            if ($.isArray(_this.item.urls)) {
                bFound = ($.inArray(s, _this.item.urls) > -1);
            }
            return bFound;
        },

        //Adds an url to item:
        addUrl: function(s) {
            if (!_this.fn.hasUrl(s)) {
                if (!$.isArray(_this.item.urls)) {
                    _this.item.urls = [];
                }
                _this.item.urls.push(s);
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },

        //Removes an url from item:
        removeUrl: function(s) {
            if (_this.fn.hasUrl(s)) {
                _this.item.urls = $.grep(_this.item.urls, function(n, i) {
                    return n != s;
                });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },

        //Checks if item has the geolocation:
        hasGeoLocation: function(obj) {
            var bFound = false;

            $.each(_this.item.geoLocations, function(i, n) {
                if (IG.fn.geoLocationsEqual(obj, n)) {
                    bFound = true;
                    return false; //break loop
                }
            });

            return bFound;
        },

        //Adds a geolocation:
        addGeoLocation: function(obj) {
            var bFound = _this.fn.hasGeoLocation(obj);
            var newIndex = -1;
            if (!bFound) {
                newIndex = _this.item.geoLocations.push(obj);
                newIndex--;
                _this.itemHasChanged = true;
            }
            return newIndex;
        },

        //Removes a geolocation:
        removeGeoLocation: function(obj) {
            var bFound = _this.fn.hasGeoLocation(obj);
            if (bFound) {
                _this.item.geoLocations = $.grep(_this.item.geoLocations, function(n, i) {
                    return !IG.fn.geoLocationsEqual(obj, n);
                });
                _this.itemHasChanged = true;
            }
            return bFound;
        },


        //Check if item has the metadata:
        hasMetadata: function(key) {
            for (var metadata in _this.item.metadata) {
                if (metadata == key) {
                    return true;
                }
            }
            return false;
        },

        //Add metadata to item:
        addMetadata: function(key, value) {
            if (key != "") {
                if (!_this.fn.hasMetadata(key)) {
                    _this.item.metadata[key] = value;
                    _this.itemHasChanged = true;
                    return true;
                }
            }
            return false;
        },

        //clears all metadata key/value pairs
        clearMetadata: function() {
            _this.item.metadata = {};
        },

        //Removes metadata from item:
        removeMetadata: function(key) {
            if (typeof _this.item.metadata === 'object' && typeof _this.item.metadata[key] !== 'undefined') {
                delete _this.item.metadata[key];
                return true;
            }
            return false;
        },


        //Check if item has the category:
        hasCategory: function(c) {
            var bFound = false;
            $.each(_this.item.categoryIds, function(i, n) {
                if (n['id'] == c.id['id']) {
                    bFound = true;
                    return false; //break loop
                }
            });
            return bFound;
        },
        //Add a category:
        addCategory: function(c) {
            var bFound = _this.fn.hasCategory(c);
            if (!bFound) {
                _this.item.categoryIds.push(c.id);
                _this.itemHasChanged = true;
            }
            return !bFound;
        },
        //Remove a category:
        removeCategory: function(c) {
            var bFound = _this.fn.hasCategory(c);
            if (bFound) {
                //Only keep the categories with different id:
                _this.item.categoryIds = $.grep(_this.item.categoryIds, function(n, i) {
                    return n['id'] != c.id['id'];
                });
                _this.itemHasChanged = true;
            }
            return bFound;
        },

        //Check if item has the channel:
        hasChannel: function(c) {
            var hasIt = false;
            $.each(_this.item.channelIds, function(i, n) {
                if (n.id == c.id) {
                    hasIt = true;
                    return true; //break the loop
                }
            });
            return hasIt;
        },
        //Adds a channel:
        addChannel: function(c) {
            if (!_this.fn.hasChannel(c)) {
                _this.item.channelIds.push(c);
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },
        //Removes a channel:
        removeChannel: function(c) {
            if (_this.fn.hasChannel(c)) {
                //Only keep channels with different id:
                _this.item.channelIds = $.grep(_this.item.channelIds, function(n, i) {
                    return n.id != c.id;
                });
                _this.itemHasChanged = true;
            }
            return false;
        },

        //Check if permission exists:
        hasPermission: function(pType, pKey, id) {
            var b = false;
            if (typeof _this.item.permissions[pType][pKey] !== "undefined") {
                $.each(_this.item.permissions[pType][pKey], function(i, n) {
                    if (n.id == id) {
                        b = true;
                        return false; //break loop;
                    }
                });
            }
            return b;
        },
        //Adds a permission:
        addPermission: function(pType, pKey, id) {
            if (!_this.fn.hasPermission(pType, pKey, id)) {
                if (typeof _this.item.permissions[pType][pKey] === "undefined") {
                    _this.item.permissions[pType][pKey] = [];
                }
                _this.item.permissions[pType][pKey].push({ "customerId": IG.config.customerId, "id": id });
                // _this.itemHasChanged = true;
                return true;
            }
            return false;
        },
        //Removes a permission:
        removePermission: function(pType, pKey, id) {
            if (_this.fn.hasPermission(pType, pKey, id)) {
                _this.item.permissions[pType][pKey] = $.grep(_this.item.permissions[pType][pKey], function(n, i) {
                    return n.id != id;
                });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },


        //Sets template:
        setTemplateId: function(t) {
            _this.item.templateId = t.id;
            _this.itemHasChanged = true;
        },



        //Sets the content source:
        setContentSource: function(id) {
            if (id != null && (typeof id != "undefined")) {
                _this.item.source = id;
            }
            else {
                _this.item.source = {};
            }
            _this.itemHasChanged = true;
        },

        //Removes the content source:
        removeContentSource: function() {
            if (typeof _this.item.source.id != "undefined") {
                _this.item.source = {};
                _this.itemHasChanged = true;
            }
        },


        //Sets exposureDuration:
        setExposureDuration: function(d) {
            d = parseInt(d, 10);
            if (isNaN(d)) {
                d = 30;
            }
            _this.item.exposureDuration = d;
            _this.itemHasChanged = true;
            return d;
        },

        //Check if item has a tag:
        hasTag: function(t) {
            return $.inArray(t, _this.item.tags) > -1;
        },
        //Adds a tag:
        addTag: function(t) {
            t = $.trim(t);
            if (t != "") {
                if (!_this.fn.hasTag(t)) {
                    _this.item.tags.push(t);
                    _this.itemHasChanged = true;
                    return true;
                }
            }
            return false;
        },
        //Removes a tag:
        removeTag: function(t) {
            if (_this.fn.hasTag(t)) {
                _this.item.tags = $.grep(_this.item.tags, function(n, i) {
                    return (n != t);
                });
                _this.itemHasChanged = true;
                return true;
            }
            return false;
        },
        isParent: function() {
            return (typeof _this.item.referencingInfoObjectIds == "undefined" || _this.item.referencingInfoObjectIds.length == 0 ? true : false);
        },

        getMediaThumbnailClass: function(mediaThumb) {
            if (mediaThumb.url) {
                var ratio = mediaThumb.width / mediaThumb.height;
                if (ratio > 1.8) {
                    return 'thumb-preview-contain';
                }
                return 'thumb-preview-cover';
            }
            return '';
        }
    };

    /* Update the current InfoObject: */
    this.updateInfoObject = function(options) {
        // Requiere a headline in at least one language
        var hasHeadline = false;
        for (var langKey in _this.item.headline) {
            if (_this.item.headline.hasOwnProperty(langKey)) {
                if (_this.item.headline[langKey].length > 0) {
                    hasHeadline =  true;
                    break;
                }
            }
        }
        if (!hasHeadline) {
            IG.showMissingHeadlineDialog();
            return false;
        }

        // Validate custom document fields
        if (this.infoObjectFields !== null) {
            var errorMessages = this.infoObjectFields.validateFields();
            if (errorMessages.length > 0) {
                IG.showErrorDialog(' - ' + errorMessages.join("<br/>\n - "));
                return false;
            }
        }

        options = (typeof options != 'undefined' ? options : {});
        options.onStart = $.isFunction(options.onStart) ? options.onStart : function() { };
        options.onEnd = $.isFunction(options.onEnd) ? options.onEnd : function() { };
        options.forceSync = (typeof options.forceSync !== 'undefined' ? !!options.forceSync : false);

        if (typeof options.published != 'undefined' && typeof options.published == 'boolean') {
        	options.published = options.published;
        } else {
        	if (!_this.fn.isParent() && typeof _this.parentObj.published == 'boolean') {
        		options.published = _this.parentObj.published;
        	}
        }
        // options.published = ((typeof options.published != 'undefined' && typeof options.published == 'boolean') ? options.published : undefined);

        //Check if creating a duplicate/copy of the current InfoObject:
        var bDuplicate = (typeof options.duplicate != "undefined" ? options.duplicate : false);

        options.onStart();

        //Clone our current InfoObject item:
        var updatedInfoObject = $.extend(true, {}, _this.item);
        if (typeof options.published != 'undefined') {
            updatedInfoObject.published = options.published;
        }

        IG.gui.loading.show();

        //Reset last modified:
        updatedInfoObject.lastModified = 0;
        if (_this.isNew) {
            delete updatedInfoObject.id;
        }

        if (bDuplicate) {
            IG.copy.infoobject({
                id: updatedInfoObject.id,
                success: function(newObject) {
                    options.onEnd(newObject);
                    _this.item = newObject;
                    _this.itemHasChanged = false;
                    //Edit the copied InfoObject:
                    IG.showEditInfoObject({
                        object: newObject,
                        history: true,
                        searchLang: _this.searchLang
                    });
                },
                error: function(err) {
                    options.onEnd(null);
                    IG.showErrorDialog(err);
                }
            });
        }
        else {
            IG.update.infoobject({
                object: updatedInfoObject,
                forceSync: options.forceSync,
                isParent: _this.fn.isParent(),
                success: function(object) {
                    IG.gui.loading.hide();
                    if (_this.isNew) {
                        //we should update tabData with the new id we got from the object
                        var newTab = $.grep(_this.tabData, function(n, i) { return n.id == 0; })[0];
                        newTab.id = object.id;
                        newTab.headline = object.headline;

                        //if we are adding new subelement we must reference it to the parent element and then show it
                        if (!_this.fn.isParent()) {
                            _this.parentObj.referencedInfoObjectIds.push(object.id);
                            _this.createReference(_this.parentObj.referencedInfoObjectIds, function() {
                                object.referencingInfoObjectIds.push(_this.parentObj.id);

                                //we have to update sort order
                                _this.updateReference(function() {
                                    //Then reload the new InfoObject:
                                    IG.showEditInfoObject({
                                        object: object,
                                        history: true,
                                        searchLang: _this.searchLang
                                    });
                                });
                            });
                        } else {
                            IG.showEditInfoObject({
                                object: object,
                                history: true,
                                searchLang: _this.searchLang
                            });
                        }

                        return options.onEnd(object);
                    }

                    _this.itemHasChanged = false;
                    _this.item = object;

                    //update tab title
                    var headlineId = $("#ig-infoobject-headline").attr("name");
                    var title = IG.fn.stripHTML(IG.EditorController.getText(headlineId));

                    // Fall back to another language
                    if (title === null || title.length === 0) {
                        var defLang = IG.customerConfig.defaultContentLanguage;
                        if (typeof _this.item.headline[defLang] !== 'undefined' && _this.item.headline[defLang].length > 0) {
                            // choose the default language
                            title = _this.item.headline[defLang];
                        } else {
                            // Choose the next best
                            $.each(IG.customerConfig.contentLanguages, function(langKey) {
                                if (typeof _this.item.headline[langKey] !== 'undefined' && _this.item.headline[langKey].length > 0) {
                                    title = _this.item.headline[langKey];
                                    return false;
                                }
                            });
                            // or put an "empty" string
                            title = '__MISSING_TITLE__';
                        }
                    }
                    $("#ig-infoobject-tabs-container .current span").text(title);

                    //update last modified date
                    $("#last-modified-date")
                        .text(IG.fn.formatTime(_this.item.lastModified));

                    //update last modified by row:
                    var editor = IG.getUserById(IG.userConfig.userId);
                    $("#lastModifiedByUserId")
                        .parent()
                        .css("display", "table-row")
                        .text(editor.name);
                    $("#info-object-status")
                        .html('<span>' + _this.getItemStatusText() + '</span>')
                    options.onEnd(object);
                },
                error: function(err) {
                    IG.gui.loading.hide();
                    IG.showErrorDialog(err);
                    options.onEnd(null);
                }
            });

        }
    };


    this.createReference = function(childIds, callback) {
        var parentId = _this.getTopInfoObjectId();

        IG.request({
            method: "setInfoObjectReferencedInfoObjectIds",
            params: [parentId, childIds],
            success: function(response) {
                if (response.result) {
                    if ($.isFunction(callback)) {
                        callback();
                    }
                }
            },
            error: function(err) {
                IG.showErrorDialog(err);
            }
        });
    };

    this.updateReference = function(callback) {
        var parentId = _this.tabData[0].id;
        var childIds = [];
        var childTabs = $.grep(_this.tabData, function(n, i) {
            console.log(n,i);
            return i != 0 && n.id != 0; });
        $.each(childTabs, function(i, n) {
            childIds.push(n.id);
        });
        IG.gui.loading.show();
        IG.request({
            method: "setInfoObjectReferencedInfoObjectIds",
            params: [parentId, childIds],
            success: function(response) {
                if (response.result) {
                    _this.parentObj.referencedInfoObjectIds = childIds;
                    IG.gui.loading.hide();
                    if ($.isFunction(callback)) {
                        callback();
                    }
                }
            },
            error: function(err) {
                IG.gui.loading.hide();
                IG.showErrorDialog(err);
            }
        });
    };

    /* Create a new InfoObject as a child infoobject: */
    this.createInfoObject = function(intype) {
            IG.gui.loading.show();
        IG.create.infoobject({
            type: intype,
            parentid: _this.parentObj.id,
            forceSync: true,
            displayStart: _this.item.displayStartTime,
            success: function(object) {
                //Create a reference between this new InfoObject and its parent:
                IG.showEditInfoObject({
                    object: object,
                    history: true,
                    parentid: _this.parentObj.id.id,
                    type: intype,
                    isNew: true,
                    searchLang: _this.searchLang,
                    isChildObj: true
                });
            },
            error: function(err) {
                IG.showErrorDialog(err);
            }
        });
    };


    /* Checks if the supplied InfoObject has a parent (ie. has any referencingInfoObjectIds) and returns the first */
    this.getInfoObjectParentId = function(obj) {
        var id = null;
        if (obj) {
            if (obj.referencingInfoObjectIds) {
                if (obj.referencingInfoObjectIds.length > 0) {
                    id = obj.referencingInfoObjectIds[0];
                }
            }
        }
        return id;
    };

    //Returns the id of the top InfoObject (may be the item itself):
    this.getTopInfoObjectId = function() {
        var id = _this.getInfoObjectParentId(_this.item);
        if (id == null) {
            id = _this.item.id;
        }
        return id;
    };


    //Loads InfoObject given full, deep object:
    this.loadInfoObject = function(obj, callback) {
        IG.debug("IG.InfoObject.loadInfoObject");
        _this.setItem(obj);
        callback();
    };

    //Loads an InfoObject given id:
    this.loadInfoObjectById = function(inId, fnSuccess, fnError) {
        IG.debug("IG.InfoObject.loadInfoObjectById");
        IG.load.infoobject({
            id: inId,
            searchLang: (_this.isChildObj ? IG.customerConfig.defaultContentLanguage : _this.searchLang),
            success: function(object) {
                _this.setItem(object);
                fnSuccess();
            },
            error: function(err) {
                fnError(err);
            }
        });
    };


    /*
    Function : addMedia
    Adds media to the interface
    Paremeters :
    mediaId - id object, the id of the media that should be added
    */
    this.addMedia = function(myMedia, isNew) {
        if (myMedia === null) {
            console.error('InfoObject.addMedia, ERROR: myMedia is null');
            return false;
        }

        var imageContainer = document.createElement("div");
        $(imageContainer)
            .addClass('media-div-image-container');

        var imageWrapper = document.createElement("div");
        $(imageWrapper).addClass('media-div-image-wrapper');

        if (myMedia != null) {

            if (typeof myMedia.id != "undefined") {
                $(imageWrapper)
                    .data("mediaId", myMedia.id)
                    .addClass('med-id-' + myMedia.id.id) // for referencing all media with a given media ID
                    .attr('id', 'med-id-' + myMedia.id.id + '-' + Math.round(Math.random() * 1000000)); // for referencing af specific div
            }

            if (typeof myMedia.originalMediaFile != 'undefined') {
                var image = document.createElement("img");
                var imgLabel = null;
                var imgUrl = IG.fn.getMediaThumbnailUrl(myMedia, function(label) {
                    imgLabel = label;
                });
                $(image).attr({
                    'src': imgUrl,
                    'title': imgLabel
                })
                    .addClass('thumb-preview-'+myMedia.type)
                    .addClass( _this.fn.getMediaThumbnailClass(myMedia) );

                $(imageWrapper).append(image);
            }
        }

        var bAdmin = IG.userHasPermission("OP_INFO_OBJECT_ADMIN");
        var bWrite = IG.userHasPermission("OP_INFO_OBJECT_WRITE", _this.item) || bAdmin;
        if (bWrite) {
            var caption = document.createElement("span");

            if (myMedia != null) {
                $(caption).text(myMedia.name[IG.config.language]);

                // Add qtip popup for media titles
                $(caption).qtip({

                    content: {
                        text: (function () {
                            var divElm = document.createElement("div");
                            divElm.innerHTML = "<div id='info-object-popup-" + myMedia.id['id'] + "'>" +
                                "<div>" + myMedia.name[IG.config.language] + "</div></div>";
                            return divElm.innerHTML;
                        })(),
                        title: false,
                        button: false
                    },
                    position: {
                        my: 'left middle',
                        at: 'right middle',
                        adjust: {
                            x: -8
                        }
                    },
                    style: {
                        tip: {
                            width: 10,
                            height: 6
                        },
                        classes: 'qtip-title',
                        def: false
                    }
                });

            }
            $(imageWrapper).append(caption);

            var caption2 = document.createElement("span");
            $(caption2).text(IG.label.get("global_remove"));
            $(caption2).addClass('hover-btn hover-btn-1');
            $(caption2).click(function() {
                _this.removeMedia(myMedia, $(this).parent());
                _this.itemHasChanged = true;
                return false;
            });

            var caption3 = document.createElement("span");
            $(caption3).text(IG.label.get("VIEW_MEDIA"));
            $(caption3).addClass('hover-btn hover-btn-2');
            $(caption3).click(function(event) {
                event.preventDefault();
                if (_this.mediaArchive !== null) {
                    _this.mediaArchive.show(myMedia);
                }
                return false;
            });

            $(imageWrapper).append([caption2, caption3]);
        }

        $(imageContainer).append(imageWrapper);
        $('#media-div-images').append(imageContainer);

        if (isNew) {
        	_this.itemHasChanged = true;
        }
        return true;
    };

    this.removeMedia = function(media, mediaDiv) {
        //remove only div that is clicked, or else remove all divs with the media id
        if (!mediaDiv) {
            mediaDiv = $(".media-div-images div.med-id-" + media.id["id"]);
        }

        if (mediaDiv.length > 1) {
            // Remove all media from the info object with the media ID
            _this.item.mediaIds = $.grep(_this.item.mediaIds, function(n) {
                return n.id != media.id["id"];
            });
        } else if (mediaDiv.length == 1) {
            // Remove a single medium
            var index = mediaDiv.parent().index();
            if (index >= 0) {
                var currentM = _this.item.mediaIds[index];
                if (currentM.id === media.id["id"]) {
                    _this.item.mediaIds.splice(index, 1);
                }
            } else {
                console.error('Trying to remove a single media without any valid index (' + index + '). Media: ', media);
            }
        }

        // Remove the media from the DOM
        $.each(mediaDiv, function() {
            $(this).parent().remove();
        });
    };

    /*
    Function : registerFlashEditors
    Create flash text editors, headline and subheadline are inline text editors,
    that is why is true the last parameter in the register function
    */
    this.registerFlashEditors = function() {
        //Create headline editor:
        var objHeadlineEditor = new IG.Editor({
            key: "headline",
            language: _this.elementsLang,
            cssClass: 'input-editor',
            container: $("#ig-infoobject-headline").get(0),
            texts: _this.item.headline,
            singleline: true,
            requestfocus: true,
            onchange: function(s, language) {
                if (_this.item.headline[language] != s) {
                    _this.itemHasChanged = true;
                    _this.fn.setHeadline(language, s);
                }
            }
        });
        IG.EditorController.register(objHeadlineEditor);


        //Create subheadline editor:
        var objSubHeadlineEditor = new IG.Editor({
            key: "subheadline",
            cssClass: 'input-editor',
            container: $("#ig-info_object-subheadline").get(0),
            texts: _this.item.subHeadline,
            language: _this.elementsLang,
            singleline: true,
            onchange: function(s, language) {
                if (_this.item.subHeadline[language] != s) {
                    _this.itemHasChanged = true;
                    _this.fn.setSubHeadline(language, s);
                }
            }
        });
        IG.EditorController.register(objSubHeadlineEditor);


        if (_this.item.type != "poll") {
            //Create body editor:
            var objBodyEditor = new IG.Editor({
                key: "body",
                cssClass: 'input-editor',
                container: $("#ig-infoobject-body").get(0),
                texts: _this.item.body,
                language: _this.elementsLang,
                height: 200,
                formatting: true,
                enableSoftHyphen: false,
                onchange: function(s, language) {
                    if (_this.item.body[language] != s) {
                        _this.itemHasChanged = true;
                        _this.fn.setBody(language, s);
                    }
                }
            });
            IG.EditorController.register(objBodyEditor);
        }

        if (_this.item.type == "arrangement") {
            var objLocationEditor = new IG.Editor({
                container: $("#ig-infoobject-location").get(0),
                cssClass: 'input-editor',
                texts: _this.item.location,
                language: _this.elementsLang,
                singleline: true,
                change: function(s, language) {
                    if (_this.item.location[language] != s) {
                        _this.itemHasChanged = true;
                        _this.item.location[language] = s;
                    }
                }
            });
            IG.EditorController.register(objLocationEditor);
        }

    };

    this.batchload = function(callback) {
        IG.batchRequest({
            requests: [
				{
				    method: 'searchMedia',
				    params: [IG.config.language, IG.config.language, "", true, filterOpts, {}, 0, 0],
				    success: function(response) {
				        if (response.result) {
				            responsedata = response.data['data'];
				        }
				    }
				}
			],
            success: function(response) {
                if ($.isFunction(callback)) {
                    callback();
                }
            }
        });
    };






    //Renders the tab items:
    this.renderTabs = function() {
        var objDiv = document.createElement("div");
        globalThis.$(objDiv)
		    .addClass("ig-tabs-content")
		    .attr("id", "ig-infoobject-tabs-container")
            .disableSelection();

        /*var childItems = document.createElement("span");
        $(childItems)
            .addClass('sub-tabs-wrap')
            .sortable({
                items: '> .child-item',
                stop: function(e, ui) {
                    var temp = [_this.tabData[0]];
                    $("#ig-infoobject-tabs-container span.ui-sortable a").each(function(index) {
                        var id = $(this).attr("id");
                        $.each(_this.tabData, function(i, n) {
                            if (!id && n.id == 0) {
                                temp.push(n);
                                return false;
                            } else {
                                if (n.id.id == id) {
                                    temp.push(n);
                                    return false;
                                }
                            }
                        });
                    });
                    _this.tabData = temp;
                    _this.updateReference();
                },
                containment: "parent"
            })
            .disableSelection();*/

        $.each(_this.tabData, function(i, n) {
            var objA = document.createElement("a");
            $(objA)
                .attr("id", n.id.id);
            $(objA)
            	.data("isChildObj", n.isChildObj);

            var titleStr = IG.fn.stripHTML(n.headline[IG.config.language]);
            if (titleStr == "") {
                titleStr = IG.label.get("infoobject_new_title");
            }
            var objSpan = document.createElement("span");
            $(objSpan)
                .addClass("ellipsis")
                .text(titleStr)
                .attr('title', titleStr)
                .data("id", n.id == 0 ? "0" : n.id["id"])
                .appendTo(objA);

            if (!_this.isNew) {
                if (n.id["id"] == _this.item.id["id"]) {
                    $(objA).addClass("current");
                }
            } else {
                if (n.id == 0) {
                    $(objA).addClass("current");
                }
            }

            $(objA)
                .click(function() {
                    if (_this.itemHasChanged) {
                        IG.createConfirmDialog({
                            text: IG.label.get("right_menu_infoobject_changed_popup_text"),
                            onConfirm: function() {
                                $( "#modal-window-dialog" ).dialog( "close" );
                                if (n.id == 0) {
                                    IG.showEditInfoObject({
                                        object: _this.item,
                                        isNew: true,
                                        searchLang: _this.searchLang,
                                        isChildObj: (typeof $(this).data("isChildObj") != "undefined" ? $(this).data("isChildObj") : false)
                                    });
                                }
                                else {
                                    IG.showEditInfoObject({
                                        id: n.id["id"],
                                        history: true,
                                        searchLang: _this.searchLang,
                                        isChildObj: (typeof $(this).data("isChildObj") != "undefined" ? $(this).data("isChildObj") : false)
                                    });
                                }
                                // TODO _this.isChildObj = (typeof $(this).data("isChildObj") != "undefined" ? $(this).data("isChildObj") : false);
                                return false;
                            }
                        });
                    } else {
                        if (n.id == 0) {
                            IG.showEditInfoObject({
                                object: _this.item,
                                isNew: true,
                                searchLang: _this.searchLang,
                                isChildObj: (typeof $(this).data("isChildObj") != "undefined" ? $(this).data("isChildObj") : false)
                            });
                        }
                        else {
                            IG.showEditInfoObject({
                                id: n.id["id"],
                                history: true,
                                searchLang: _this.searchLang,
                                isChildObj: (typeof $(this).data("isChildObj") != "undefined" ? $(this).data("isChildObj") : false)
                            });
                        }
                        // TODO _this.isChildObj = (typeof $(this).data("isChildObj") != "undefined" ? $(this).data("isChildObj") : false);
                        return false;
                    }
                });

            if (!n.isChildObj) {
                $(objA).addClass('parent-item');
            } else {
                $(objA).addClass('child-item');
            }
            $(objA).appendTo(objDiv);
        });



        if (_this.tabData.length > 2) {

            $(objDiv).sortable({
                items: '> .child-item',
                distance: 4,
                // tolerance: 'pointer',
                stop: function(e, ui) {
                    var temp = [_this.tabData[0]];
                    $("#ig-infoobject-tabs-container a.child-item").each(function(index) {
                        var id = $(this).attr("id");
                        $.each(_this.tabData, function(i, n) {
                            if (!id && n.id == 0) {
                                temp.push(n);
                                return false;
                            } else if (n.id.id == id) {
                                temp.push(n);
                                return false;
                            }
                        });
                    });
                    _this.tabData = temp;
                    _this.updateReference();
                },
                containment: "parent"
            });
        }

        // $(childItems).appendTo(objDiv);

        return objDiv;
    };



    //Initializes the basic parent InfoObject data:
    this.initParentInfoObject = function(cb) {
        IG.debug("initParentInfoObject");
        var parentId = _this.getInfoObjectParentId(_this.item);

        //No parent, ie. the InfoObject is top level:
        if (parentId == null) {
            _this.parentObj = _this.item;
            cb();
        }
        //Found a parent id - fetch basic data:
        else {
            IG.request({
                method: "searchInfoObjects",
                params: [IG.config.language, IG.config.language, "", false, { ids: [parentId] }, {}, 1, 0],
                success: function(response) {
                    if (response.result) {
                        var responseData = response.data["data"];
                        _this.parentObj = responseData[0];
                    }
                    cb();
                },
                error: function(err) {
                    cb();
                }
            });
        }
    };

    //Creates the InfoObject tabs:
    this.initTabs = function(cb) {
        IG.debug("initTabs");

        _this.tabData = [];

        //Is the currently item the parent level InfoObject?
        var parentId = _this.parentObj.id;

        //Get the InfoObject ids given a parent InfoObject id:
        var fnLoadTabIds = function(parentId, cb) {
            IG.debug("fnLoadTabIds");

            IG.request({
                method: "searchInfoObjects",
                params: [IG.config.language, IG.config.language, "", false, { ids: [parentId] }, {}, 0, 0],
                success: function(response) {
                    var responseData = response.data["data"];
                    var obj = responseData[0];

                    // TODO her kommer et fuldt object tilbage ??
                    // console.log(obj);

                    if (obj) {
                        var res = [];
                        //res.push(parentId);
                        var cIds = obj.referencedInfoObjectIds;
                        if (cIds) {
                            $.each(cIds, function(i, n) {
                                res.push(n);
                            });
                        }
                        cb(res);
                    }
                },
                error: function(err) {
                    IG.showErrorDialog(err);
                }
            });
        };

        //Loads the lightweight InfoObjects:
        var fnLoadTabs = function(ids, cb) {
            IG.debug("fnLoadTabs");
            if (ids.length == 0) {
                cb([]);
            } else {
                var s = "";
                $.each(ids, function(i, n) {
                    s += n.id + ", "
                });
                IG.request({
                    method: "searchInfoObjects",
                    params: [IG.config.language, IG.config.language, "", false, { ids: ids }, {}, 0, 0],
                    success: function(response) {
                        cb(response.data["data"]);
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                });
            }
        };

        _this.tabData = [{ id: _this.parentObj.id, headline: _this.parentObj.headline, isChildObj: false}];


        var fnLoadTabIdsCallback = function (objects) {
            //sort it the way it is in parent infoobject
            $.each(_this.parentObj.referencedInfoObjectIds, function(i, o) {
                var obj = $.grep(objects, function(k, i) {
                    return o.id == k.id.id;
                })[0];
                if (typeof obj != "undefined") {
                    _this.tabData.push({
                        id: obj.id,
                        headline: obj.headline,
                        isChildObj: true
                    });
                }

            });

            IG.debug(_this.tabData.length + " tabs loaded");

            //if parent id is there and is new
            //it means we are loading new sub element so add it manually
            if (_this.isNew) {
                _this.tabData.push({
                    id: 0,
                    headline: _this.item.headline,
                    isChildObj: true
                });
            }
            cb();
        };


        if (parentId != 0 && _this.parentObj.referencedInfoObjectIds.length > 0) {
            fnLoadTabIds(parentId, function(ids) {
                fnLoadTabs(ids, fnLoadTabIdsCallback);
            });
        } else if (parentId != 0) {
            fnLoadTabs([], fnLoadTabIdsCallback);
        } else {
            cb();
        }
    };


    this.init = function(options) {

        options.id = (typeof options.id != "undefined" ? options.id : null);
        options.object = (typeof options.object != "undefined" ? options.object : null);
        options.isNew = (typeof options.isNew == "boolean" ? options.isNew : false);
        options.searchLang = (typeof options.searchLang == "string" ? options.searchLang : IG.customerConfig.defaultContentLanguage);
        _this.searchLang = options.searchLang;
        _this.elementsLang =  options.searchLang;
        _this.isNew = options.isNew;

        _this.userPermissions.count = 0;
        _this.userPermissions.offset = 0;

        //What to do when done loading object:
        var fnSuccess = function() {
        	/* Check if the infoObject type is NOT a standard type
        	 * and build this.item.fieldGroups object
        	*/
        	if ($.inArray(_this.item.type, IG.standardInfoObjectTypes) == -1) {
        		$.each(IG.lists.documenttypes, function(i, docType) {
        			// console.log("DEBUG #1 pre-building this.item.fieldGroups",i,docType);
        			if (docType.typeName == _this.item.type) {
        				// Check if fieldgroups does not exists:
        			    if (typeof _this.item.fieldGroups === 'undefined' || _this.item.fieldGroups === null || $.isArray(_this.item.fieldGroups)) {
        			        _this.item.fieldGroups = {};
        			    }

                        //For each field group add a new object with the groups identifier as key
                        $.each(docType.fieldGroups, function(j, fieldG) {
                        	// Check that the group exists or if it's a wrong type (might be that a new one was added)
                            if (typeof _this.item.fieldGroups[fieldG.groupName] === 'undefined'
                                    || _this.item.fieldGroups[fieldG.groupName] === null
                                    || $.isArray(_this.item.fieldGroups[fieldG.groupName])) {
                                _this.item.fieldGroups[fieldG.groupName] = {};
                            }

                        	$.each(fieldG.fields, function(k, field) {
                        		// Check that the field in the group exists and has the correct format
                        	    if (typeof _this.item.fieldGroups[fieldG.groupName][field.fieldName] === 'undefined'
                                        || _this.item.fieldGroups[fieldG.groupName][field.fieldName] === null) {
                        	        _this.item.fieldGroups[fieldG.groupName][field.fieldName] = {};
                                }
                        	});
                        });
                    }
        		});
        	}
        	// console.log("Done building: this.item.fieldGroups: ",_this.item.fieldGroups);



            _this.initParentInfoObject(function() {
                //Init tabs:
                _this.initTabs(function() {
                    //Then show InfoObject:
                    _this.render(function() {
                        _this.fnAfterRender();
                    });
                });
            });
        };


        var fnError = function(err) {

            if (typeof err == "string") {
                IG.showErrorDialog(err, function() {
                    //Go to root/dashboard:
                    IG.location.front();
                });
            }
            IG.gui.loading.hide();
        };

        //Got a full object - use this:
        if (options.object != null) {
            _this.loadInfoObject(options.object, fnSuccess);
        }
        //Else load by provided id:
        else {
            _this.loadInfoObjectById(options.id, fnSuccess, fnError);
        }



    };

    this.fnAfterRender = function() {
        IG.gui.loading.hide();

        var bAdmin = IG.userHasPermission("OP_INFO_OBJECT_ADMIN");
        var bWrite = IG.userHasPermission("OP_INFO_OBJECT_WRITE", _this.item) || bAdmin;
        if (!bWrite) {
            //show readonly dialog
            _this.showReadOnlyDialog();
        }

        //load media objects
        _this.loadMediaObjects(function(objects) {
            $.each(_this.item.mediaIds, function(i, m) {
                $.each(objects, function(j, o) {
                    if (m.id == o.id.id) {
                        _this.addMedia(o, false);
                        return false;
                    }
                });
            });
        });

        //after DOM is generated, register flash editors
        _this.registerFlashEditors();


        //add sortable media
        $(".media-div-images").sortable({
            stop: function(e, ui) {
                var newMediaIds = [];
                $("#ig-infoobject .media-div-images .media-div-image-wrapper").each(function(index) {
                    var currentId = $(this).data('mediaId');
                    if (typeof currentId.id != "undefined" && typeof currentId.customerId != "undefined") {
                        newMediaIds.push(currentId);
                    }
                });
                _this.item.mediaIds = newMediaIds;
                _this.itemHasChanged = true;
            },
            axis: 'x',
            containment: "parent",
            sort: function (event, ui) {
                var that = $(this),
                    w = ui.helper.outerWidth();
                that.children().each(function () {
                    if ($(this).hasClass('ui-sortable-helper') || $(this).hasClass('ui-sortable-placeholder'))
                        return true;
                    // If overlap is more than half of the dragged item
                    var dist = Math.abs(ui.position.left - $(this).position().left),
                        before = ui.position.left > $(this).position().left;
                    if ((w - dist) > (w / 2) && (dist < w)) {
                        if (before)
                            $('.ui-sortable-placeholder', that).insertBefore($(this));
                        else
                            $('.ui-sortable-placeholder', that).insertAfter($(this));
                        return false;
                    }
                });
            }
        });
        $(".media-div-images").disableSelection();

        $('.language-dependent.' + _this.elementsLang).show();

        _this.itemHasChanged = false;
        IG.gui.loading.hide();


        if (_this.isNew && _this.isChildObj && IG.customerConfig.defaultContentLanguage != _this.searchLang) {
        	_this.item.headline = new Object();
        	_this.item.headline[IG.customerConfig.defaultContentLanguage] = "# Default headline #";
        }
    };

    this.showReadOnlyDialog = function() {
        var okDialog = IG.createOKDialog({
            title: IG.label.get("infoobject_readonly_mode_dialog_title"),
            text: IG.label.get("infoobject_readonly_mode")
        });

        setTimeout(function() {
            okDialog.close();
        }, 15000);
    };

    //Renders headline blocks:
    this.renderHeadline = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass('ig-infoobject-headline');

        var objTitle = document.createElement("p");
        $(objTitle)
	        .text(IG.label.get("info_object_headline"))
	        .appendTo(objDiv);

        var objDivInput = document.createElement("div");
        $(objDivInput)
            .attr("id", "ig-infoobject-headline")
            .appendTo(objDiv);

        if (!bWrite) {
            var objDivReadOnly = document.createElement("div");
            $(objDivReadOnly)
                .attr("id", "ig-infoobject-headline-readonly")
                .addClass("ig-infoobject-headline-readonly")
                .appendTo(objDivInput);
        }

        return objDiv;
    };

    //Renders subheadline blocks:
    this.renderSubHeadline = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass('ig-infoobject-headline');

        var objTitle = document.createElement("p");
        $(objTitle)
		        .text(IG.label.get("info_object_subheadline"))
		        .appendTo(objDiv);

        var objDivInput = document.createElement("div");
        $(objDivInput)
            .attr("id", "ig-info_object-subheadline")
            .appendTo(objDiv);

        if (!bWrite) {
            var objDivReadOnly = document.createElement("div");
            $(objDivReadOnly)
                .attr("id", "ig-info_object-subheadline-readonly")
                .addClass("ig-info_object-subheadline-readonly")
                .appendTo(objDivInput);
        }

        return objDiv;
    };


    //Renders body blocks:
    this.renderBody = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-infoobject-body");

        var objDivInput = document.createElement("div");
        $(objDivInput)
            .attr("id", "ig-infoobject-body")
            .appendTo(objDiv);

        //add read-only div overlay if needed
        if (!bWrite) {
            var objDivReadOnly = document.createElement("div");
            $(objDivReadOnly)
                .addClass("ig-infoobject-body-readonly")
                .attr("id", "ig-infoobject-body-readonly")
                .appendTo(objDiv);
        }

        return objDiv;
    };


    this.renderCustomGroups = function(bWrite) {
        var _this = this;
        var result = [];
        _this.infoObjectFields = new IG.InfoObjectFields(_this, _this.searchLang);

        $.each(IG.lists.documenttypes, function(i, docType) {
            if (docType.typeName == _this.item.type) {
                // console.log("DEBUG *1",i, docType);

                $.each(docType.fieldGroups, function(j, fieldG) {
                	// console.log("DEBUG *2",j, fieldG);

                	if (typeof fieldG.name[IG.config.language] != "undefined") {
                		var objDiv = document.createElement("div");
                        $(objDiv)
                            .addClass("ig-edit-section collapsable open");

                        var objH4 = document.createElement("h4");
                        $(objH4)
                            .text(fieldG.name[IG.config.language])
                            .append('<span class="collapse-arrow"></span>')
                            .appendTo(objDiv);



                        var newField = document.createElement("div");
                        $(newField)
                            .addClass("ig-edit-section-content");

                        var hasAnyFieldInGroup = false;
                        $.each(fieldG.fields, function(k, field) {

                            if (bWrite) {
                                // Hidden field (render if the parameter is not defined or true)
                                if ($.type(field.parameters['hidden-in-ui']) !== 'boolean' || !field.parameters['hidden-in-ui']) {
                                    hasAnyFieldInGroup = true;

                                    var objTable = document.createElement("table");
                                    $(objTable).addClass('custom-field').appendTo(newField);

                                    var objTBody = document.createElement("tbody");
                                    $(objTBody).appendTo(objTable);

                                    _this.infoObjectFields.createField(field, fieldG, objTBody);
                                }
                            }
                        });

                        if (hasAnyFieldInGroup) {
                            newField != null ? $(objDiv).append(newField) : null;
                            result.push(objDiv);
                        }
                	}
                });
            }
        });
        return result;
    };

    /* Renders poll specific fields */

    this.renderPollFields = function(bWrite) {
        var _this = this;
        var objDiv = document.createElement("div");
        $(objDiv)
                .attr("id", "infoobject-poll")
                .addClass("ig-edit-section");

        var objDivContent = document.createElement("div");
        $(objDivContent)
                .addClass("ig-edit-section-content")
                .appendTo(objDiv);


        var objTitle = document.createElement("p");
        $(objTitle)
                .text(IG.label.get("edit_infoobject_poll"))
                .appendTo(objDivContent);

        var objDivItems = document.createElement("div");
        $(objDivItems)
            .attr('id', 'poll-options-container')
            .addClass("items")
            .appendTo(objDivContent);

        var objDivButtons = document.createElement("div");
        $(objDivButtons)
            .addClass("buttons")
            .appendTo(objDivContent);


        //Builds poll results dialog contrent
        var fnBuildPollResultsDialogContent = function() {
            var objDiv = document.createElement("div");
            $(objDiv).addClass("poll-results");

            //Dialog headline:
            var objH2 = document.createElement("h2");
            $(objH2)
                    .text(IG.label.get("info_object_poll_results"))
                    .appendTo(objDiv);


            //Dialog contents:
            var objDivContent = document.createElement("div");
            $(objDivContent)
                    .addClass("content")
                    .appendTo(objDiv);

            var totalVotes = 0;
            $.each(_this.item.pollOptions, function(i, n) {
                totalVotes += n.votes;
            });

            //Poll option row:
            var objTable = document.createElement("table");
            $(objTable).appendTo(objDivContent);

            $.each(_this.item.pollOptions, function(i, n) {
                var objTR = document.createElement("tr");
                $(objTR).appendTo(objTable);

                var objTDopt = document.createElement("td");
                $(objTDopt)
                        .text(n.text[_this.elementsLang])
                        .appendTo(objTR);

                var objTDvotes = document.createElement("td");
                $(objTDvotes)
                        .addClass("votes")
                        .text(n.votes + ' (' + IG.fn.toFixed(totalVotes > 0 ? (n.votes / totalVotes) * 100 : 0, 2) + '%)')
                        .appendTo(objTR);
            });

            var objTRtotal = document.createElement("tr");
            $(objTRtotal)
                    .addClass("total")
                    .appendTo(objTable);

            var objTDopt = document.createElement("td");
            $(objTDopt)
                    .text(IG.label.get("info_object_poll_total"))
                    .appendTo(objTRtotal);

            var objTDvotes = document.createElement("td");
            $(objTDvotes)
                    .addClass("votes")
                    .text(totalVotes)
                    .appendTo(objTRtotal);

            return objDiv;
        };

        //Shows poll results:
        var fnShowPollResults = function() {
            $( "#modal-window-dialog" )
                .html(fnBuildPollResultsDialogContent())
                .dialog({
                    modal: true,
                    title: '',
                    dialogClass: "no-close",
                    position: { my: "center", at: "center", of: window },
                    width: 'auto',
                    height: 'auto',
                    buttons: [{
                        text: IG.label.get("ok"),
                        click: function() {
                            $( this ).dialog( "close" );
                        }
                    }],

                    // reset to defaults
                    minWidth: 150,
                    maxWidth: false,
                    minHeight: 150,
                    maxHeight: false

                });
        };


        //Create show poll results button:
        var btnResults = document.createElement("a");
        $(btnResults)
                .addClass("btn-normal btn-dark btn-space-r")
                .html('<span>' + IG.label.get("edit_infoobject_see_poll_results") + '</span>')
                .click(function() {
                    fnShowPollResults();
                    return false;
                })
                .appendTo(objDivButtons);

        if (bWrite) {
            //Create add option button:
            var btnAdd = document.createElement("a");
            $(btnAdd)
                .addClass("btn-normal btn-dark")
                .html('<span>' + IG.label.get("edit_infoobject_add_poll_option") + '</span>')
                .click(function() {
                    var opt = {
                        tmpId: "tmp-" + (new Date()).getTime(), // only for frontend use
                        text: {},
                        votes: 0
                    };
                    $.each(IG.customerConfig.contentLanguages, function(key, name) {
                        opt.text[key] = "";
                    });

                    if (_this.fn.addPollOption(opt)) {
                        _this.fnAddPollOption(opt, $(objDivItems), bWrite);
                    }

                    return false;
                })
                .appendTo(objDivButtons);
        }

        this.renderPollOptions($(objDivItems), bWrite);
        return objDiv;
    };

    this.renderPollOptions = function(objPollOptions, bWrite) {
        if (objPollOptions && objPollOptions.length > 0) {
            objPollOptions.empty();
            $.each(_this.item.pollOptions, function(i, n) {
                _this.fnAddPollOption(n, objPollOptions, bWrite);
            });
        }
    };

    this.fnAddPollOption = function(opt, parentElement, bWrite) {

        var strDefault = IG.label.get("infoobject_add_poll");

        //Create container for poll option:
        var objDivOption = document.createElement("div");
        $(objDivOption)
            .addClass("ig-poll-option");

        //Create language specific containers:
        $.each(IG.customerConfig.contentLanguages, function(key, name) {
            var objDivOptionLang = document.createElement("div");
            $(objDivOptionLang)
                .addClass("language-dependent " + key)
                .appendTo(objDivOption);


            var objInput = document.createElement("input");


            //Create language specific input field:
            $(objInput)
                .attr({
                    "type": "text",
                    "name": "poll-option",
                    'class': 'input-text'
                })

                .appendTo(objDivOptionLang)
                .wrap('<span class="input"></span>');


            if (!bWrite) {
                $(objInput).attr("readonly", "readonly");
            }
            else {
                $(objInput)
                    .click(function() {
                        $(this).removeClass("deco");

                        var v = $(this).val();
                        if (v == strDefault) {
                            $(this).val("");
                        }
                        else {
                            $(this).select();
                        }
                    })
                    .focus(function() {
                        $(this).removeClass("deco");
                    })
                    .blur(function() {
                        var v = $(this).val();

                        if (v == "") {
                            v = strDefault;
                            $(this).val(v);
                        }

                        if (v == strDefault) {
                            $(this).addClass("deco");
                        }

                        _this.fn.setPollOption(
                            (typeof opt.id !== 'undefined' ? opt.id : opt.tmpId),
                            key,
                            (v == strDefault ? "" : v),
                            (typeof opt.tmpId !== 'undefined')
                        );
                    })
            }

            var strValue = opt.text[key];
            if (strValue == "") {
                $(objInput)
                    .val(strDefault)
                    .addClass("deco");
            }
            else {
                $(objInput).val(strValue);
            }

            $(objDivOptionLang).css("display", (key == _this.elementsLang ? "block" : "none"));
        });


        if (bWrite) {
            //Create delete button:
            var btnDelete = document.createElement("a");
            $(btnDelete)
                .addClass("ig-plainbutton btn-dark delete")
                .text(IG.label.get("global_delete"))
                .click(function() {
                    if (typeof opt.tmpId !== 'undefined') {
                        _this.fn.removePollOption(opt.tmpId, true);
                    } else {
                        _this.fn.removePollOption(opt.id);
                    }
                    $(objDivOption).remove();
                    return false;
                })
                .appendTo(objDivOption);
        }

        $(objDivOption).appendTo(parentElement);
        return opt;
    };



    /* Renders arrangement specific fields */

    this.renderArrangementLocation = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-infoobject-headline");

        var objTitle = document.createElement("p");
        $(objTitle)
	        .text(IG.label.get("info_object_arragment_title"))
	        .appendTo(objDiv);

        var objDivInput = document.createElement("div");
        $(objDivInput)
            .attr("id", "ig-infoobject-location")
            .appendTo(objDiv);

        if (!bWrite) {
            var objDivReadOnly = document.createElement("div");
            $(objDivReadOnly)
                .attr("id", "ig-infoobject-location-readonly")
                .addClass("ig-infoobject-location-readonly")
                .appendTo(objDivInput);
        }

        return objDiv;
    };

    this.renderArrangementTimes = function(bWrite) {
        var dateDisplayFormat = 'LLL';
        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "infoobject-arrangement")
            .addClass("ig-edit-section");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("info_object_arrangement_times"))
            .appendTo(objDiv);


        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content")
            .appendTo(objDiv);


        var objTable = document.createElement("table");
        $(objTable).appendTo(objDivContent);

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);


        var objTRstart = document.createElement("tr");
        $(objTRstart).appendTo(objTBody);

        var objTDstartLbl = document.createElement("td");
        $(objTDstartLbl)
            .addClass("lbl")
            .text(IG.label.get("info_object_arrangement_starts"))
            .appendTo(objTRstart);

        var objTDstart = document.createElement("td");
        $(objTDstart).appendTo(objTRstart);


        var objInputStart = document.createElement("input");
        $(objInputStart)
            .attr({
                "type": "text",
                "id": "arrangement-start-time",
                "readonly": "readonly",
                "value": moment(_this.item.arrangementStartTime*1000).format(dateDisplayFormat),
                'class': 'input-text'
            })
            .addClass("date-time")
            .appendTo(objTDstart);


        if (bWrite) {
            $(objInputStart)
                .addClass("datepicker")
                .click(function(event) {

                    var _input = $(this);
                    var dtp = new IG.DateTimePicker({
                        current: _this.item.arrangementStartTime,
                        displayFormat: dateDisplayFormat
                    });

                    $( "#modal-window" )
                        .html(dtp.getContent())
                        .dialog({
                            modal: true,
                            title: '',
                            position: { my: "center", at: "center", of: window },
                            width: 315,
                            height: 'auto',
                            buttons: [
                                {
                                    text: IG.label.get("ok"),
                                    click: function() {
                                        dtp.save(function(UTCtimeStampSecs, formattedStr) {
                                            _input.val(formattedStr);
                                            _this.item.arrangementStartTime = UTCtimeStampSecs;
                                            _this.itemHasChanged = true;
                                            $( "#modal-window" ).dialog( "close" );
                                        });
                                    }
                                },
                                {
                                    text: IG.label.get("cancel"),
                                    click: function() {
                                        $( this ).dialog( "close" );
                                    }
                                }
                            ],

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 150,
                            maxHeight: false
                        });

                });
        }


        var objTRend = document.createElement("tr");
        $(objTRend).appendTo(objTBody);

        var objTDendLbl = document.createElement("td");
        $(objTDendLbl)
        .addClass("lbl")
        .text(IG.label.get("info_object_arrangement_ends"))
        .appendTo(objTRend);

        var objTDend = document.createElement("td");
        $(objTDend).appendTo(objTRend);


        var objInputEnd = document.createElement("input");
        $(objInputEnd)
        .attr({
            "type": "text",
            "id": "arrangement-end-time",
            "readonly": "readonly",
            "value": moment(_this.item.arrangementEndTime*1000).format(dateDisplayFormat),
            'class': 'input-text'
        })
        .addClass("date-time")
        .appendTo(objTDend);

        if (bWrite) {
            $(objInputEnd)
                .addClass("datepicker")
                .click(function(event) {

                    var _input = $(this);
                    var dtp = new IG.DateTimePicker({
                        current: _this.item.arrangementEndTime,
                        displayFormat: dateDisplayFormat
                    });

                    $( "#modal-window" )
                        .html(dtp.getContent())
                        .dialog({
                            modal: true,
                            title: '',
                            position: { my: "center", at: "center", of: window },
                            width: 315,
                            height: 'auto',
                            buttons: [
                                {
                                    text: IG.label.get("ok"),
                                    click: function() {
                                        dtp.save(function(UTCtimeStampSecs, formattedStr) {
                                            _input.val(formattedStr);
                                            _this.item.arrangementEndTime = UTCtimeStampSecs;
                                            _this.itemHasChanged = true;
                                            $( "#modal-window" ).dialog( "close" );
                                        });
                                    }
                                },
                                {
                                    text: IG.label.get("cancel"),
                                    click: function() {
                                        $( this ).dialog( "close" );
                                    }
                                }
                            ],

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 150,
                            maxHeight: false
                        });
                });
        }

        return objDiv;
    };


    /* Renders media block: */

    this.renderMediaTMP = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "infoobject-media")/*
            .addClass("ig-edit-section")*/;

        //Create media archive block:
        var mediaDiv = document.createElement("div");
        $(mediaDiv).addClass('media-div clearfix');

        if (bWrite) {
            var mediaTitle = document.createElement("div");
            $(mediaTitle)
                .addClass("media-title")
                .html(IG.label.get("media_click_to_add") + ' <span class="pink">+</span>')
                .appendTo(mediaDiv);
        }

        var imagesDiv = document.createElement("div");
        $(imagesDiv)
            .attr('id', 'media-div-images')
            .addClass('media-div-images');

        $(mediaDiv).append(imagesDiv);

        //render div below media items
        var addVideoDiv = document.createElement("div");
        $(addVideoDiv)
            .addClass("media-tools clearfix");

        //browse media if user has permisions
        if (bWrite) {

        	var mediaArchiveShown = false; // used to prevent multi-click

            var browseImagesBtt = document.createElement("a");
            $(browseImagesBtt).addClass('btn-normal btn-dark');

            var browseImagesSpan = document.createElement("span");
            $(browseImagesSpan).text(IG.label.get("edit_infoobject_media_archive"));
            $(browseImagesBtt)
                .append(browseImagesSpan)
                .appendTo(addVideoDiv);
            var oldValue = $(browseImagesSpan).text();

            _this.mediaArchive = new IG.MediaArchive({
                hasContext: true,
                elementsLang: _this.elementsLang,
                height: _this.mediaArchiveHeight,
                onload: function() {
                    $(browseImagesSpan).text(oldValue);
                },
                onMediaInsert: function(media) {
                    _this.item.mediaIds[_this.item.mediaIds.length] = media.id;
                    _this.itemHasChanged = true;
                    _this.addMedia(media, true);
                },
                onMediaRemoved: function(media) {
                    _this.removeMedia(media);
                },
                onResized: function() {
                    _this.mediaArchiveHeight = _this.mediaArchive.height;
                }
            });


            $(browseImagesBtt).click(function() {
                $(browseImagesSpan).text(IG.label.get("media_opening"));

                _this.mediaArchive.show();
                mediaArchiveShown = false;
            });

            var mediaToolsWrap = document.createElement("div");
            $(mediaToolsWrap).attr({ id: "medialist-tools-wrap" });

            $(mediaDiv).click(function() {
            	if (!mediaArchiveShown) {
            		mediaArchiveShown = true;
            		$(browseImagesBtt).trigger("click");
            	}
            });
        }

        $(objDiv).append(mediaDiv);
        $(objDiv).append(addVideoDiv);

        //End media block
        return objDiv;
    };

    this.loadMediaObjects = function(callback) {
        if (_this.item.mediaIds.length > 0) {
            IG.request({
                method: "searchMedia",
                params: [IG.config.language, IG.config.language, "", true, { ids: _this.item.mediaIds }, {}, 0, 0],
                success: function(response) {
                    _this.itemMedia = response.data["data"];
                    if ($.isFunction(callback)) {
                        callback(_this.itemMedia);
                    }
                },
                error: function(err) {
                    if ($.isFunction(callback)) {
                        callback([]);
                    }
                }
            });
        } else {
            if ($.isFunction(callback)) {
                callback([]);
            }
        }
    };

    /* Renders external references block: */
    this.renderUrls = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("ig-edit-section collapsable")
            .attr("id", "ig-infoobject-urls");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_external_links"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content")
            .appendTo(objDiv);

        var objTable = document.createElement("table");
        $(objTable).appendTo(objDivContent);

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);

        var objTR = document.createElement("tr");
        $(objTR).appendTo(objTBody);

        var objTDlbl = document.createElement("td");
        $(objTDlbl)
            .addClass("lbl")
            .html('<label for="infoobject-url">' + IG.label.get("edit_infoobject_read_moore") + '</label>')
            .appendTo(objTR);

        var objTDinp = document.createElement("td");
        $(objTDinp).appendTo(objTR);

        var objInput = document.createElement("input");
        $(objInput)
            .attr({
                "type": "text",
                "name": "url",
                "id": "infoobject-url"
            })
            .appendTo(objTDinp)
            .wrap('<span class="input"></span>');

        if (bWrite) {
            $(objInput).change(function() {
                _this.fn.setUrl($(this).val());
            });
        }
        else {
            $(objInput).attr("readonly", "readonly");
        }

        if ($.isArray(_this.item.urls) && _this.item.urls.length > 0) {
            $(objInput).val(_this.item.urls[0]);
        }

        /*
        //Create input field and button for adding new url:
        if (bWrite) {

            var objTable = document.createElement("table");
        $(objTable).appendTo(objDivContent);

            var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);

            var objTR = document.createElement("tr");
        $(objTR).appendTo(objTBody);

            var objTDlbl = document.createElement("td");
        $(objTDlbl)
        .addClass("lbl")
        .html('<label for="infoobject-url">' + IG.label.get("edit_infoobject_read_moore") + '</label>')
        .appendTo(objTR);

            var objTDinp = document.createElement("td");
        $(objTDinp).appendTo(objTR);

            var objTDbtn = document.createElement("td");
        $(objTDbtn)
        .addClass("buttons")
        .appendTo(objTR);


            var objInp = document.createElement("input");
        $(objInp)
        .attr({
        "type": "text",
        "name": "infoobject-url",
        "id": "infoobject-url"
        })
        .appendTo(objTDinp)
        .wrap('<span class="input"></span>');


            var objDivItems = document.createElement("div");
        $(objDivItems)
        .addClass("items")
        .appendTo(objDivContent);





        //Appends an external reference to list
        var fnAddUrl = function(s) {
        var objTable = $(objDivItems).children("table:first").get(0);
        if (objTable == null) {
        objTable = document.createElement("table");
        $(objTable).appendTo(objDivItems);
        }

                var objTR = document.createElement("tr");
        $(objTR).appendTo(objTable);

                var objTDlbl = document.createElement("td");
        $(objTDlbl)
        .addClass("lbl")
        .appendTo(objTR);

                var objTD = document.createElement("td");
        $(objTD)
        .addClass("ellipsis")
        .attr("title", s)
        .text(s)
        .appendTo(objTR);


                if (bWrite) {
        var objTDdel = document.createElement("td");
        $(objTDdel)
        .addClass("delete")
        .appendTo(objTR);

                    var btnDel = document.createElement("a");
        $(btnDel)
        .addClass("ig-tinybutton")
        .html('<span>' + 'Fjern' + '</span>') //FIXME: label
        .click(function() {
        _this.fn.removeUrl(s);
        $(objTR).remove();
        return false;
        })
        .appendTo(objTDdel);
        }
        };


            //Create add button:
        var btnAdd = document.createElement("a");
        $(btnAdd)
        .addClass("btn-normal btn-dark")
        .html('<span>' + 'Tilføj' + '</span>')
        .click(function() {
        var v = $(objInp).val();
        if (IG.fn.isValidUrl(v)) {
        if (_this.fn.addUrl(v)) {
        fnAddUrl(v);
        $(objInp).val("");
        }
        }
        return false;
        })
        .appendTo(objTDbtn);


            $(objInp).keyup(function(e) {
        var key = e.which ? e.which : e.keyCode;
        if (key == 13) {
        $(btnAdd).trigger("click");
        }
        });


        }
        */


        return objDiv;
    };


    /* Renders comments block: */
    this.renderComments = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_comments"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
                .addClass("ig-edit-section-content")
                .appendTo(objDiv);

        //No comments to display:
        if (_this.item.comments.length == 0) {
            var objP = document.createElement("p");
            $(objP)
                    .text(IG.label.get("edit_infoobject_comments_count").replace("\{0\}", "0"))
                    .appendTo(objDivContent);
        }
        //Some comments to display:
        else {
            var objTable = document.createElement("table");
            $(objTable)
                    .addClass("comments")
                    .appendTo(objDivContent);

            var objTBody = document.createElement("tbody");
            $(objTBody).appendTo(objTable);

            $.each(_this.item.comments, function(i, n) {
                var objTR = document.createElement("tr");
                $(objTR).appendTo(objTBody);

                var objTDauthor = document.createElement("td");
                $(objTDauthor)
                        .text(n.author)
                        .appendTo(objTR);

                var objTDcomment = document.createElement("td");
                $(objTDcomment)
                        .text(n.body)
                        .appendTo(objTR);

                if (bWrite) {
                    var objTDdel = document.createElement("td");
                    $(objTDdel)
                            .addClass("delete")
                            .appendTo(objTR);

                    var objADel = document.createElement("a");
                    $(objADel)
                            .addClass("btn-small btn-dark")
                            .html('<span>' + IG.label.get("global_delete") + '</span>')
                            .click(function() {
                                _this.fn.removeComment(n.id);
                                $(objTR).remove();
                                return false;
                            })
                            .appendTo(objTDdel)
                }
            });
        }
        return objDiv;
    };


    //Renders Geolocation box:
    this.renderGeoLocation = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
	            .attr("id", "infoobject-geolocations")
	            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("infoobject_geolocation"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
                .addClass("ig-edit-section-content")
                .appendTo(objDiv);


        if (bWrite) {
            //Build HTML contents:
            var s = ''
                    + '<table class="geo">'
                    + '<tbody>'

            //Name
                    + '<tr>'
                    + '<td class="lbl"><label for="geo-name">' + IG.label.get("edit_infoobject_location_name") + '</label></td>'
                    + '<td><span class="input"><input type="text" name="geo-name" id="geo-name" class="wide" value="" /></span></td>'
                    + '</tr>'

            //Address1
                    + '<tr>'
                    + '<td class="lbl"><label for="geo-address1">' + IG.label.get("infoobject_geolocation_address1") + '</label></td>'
                    + '<td><span class="input"><input type="text" name="geo-address1" id="geo-address1" class="wide" value="" /></span></td>'
                    + '</tr>'

            //Address2
                    + '<tr>'
                    + '<td class="lbl"><label for="geo-address2">' + IG.label.get("infoobject_geolocation_address2") + '</label></td>'
                    + '<td><span class="input"><input type="text" name="geo-address2" id="geo-address2" class="wide" value="" /></span></td>'
                    + '</tr>'

            //City
                    + '<tr>'
                    + '<td class="lbl"><label for="geo-city">' + IG.label.get("edit_infoobject_location_city") + '</label></td>'
                    + '<td><span class="input"><input type="text" name="geo-city" id="geo-city" class="wide" value="" /></span></td>'
                    + '</tr>'

            //Zip
                    + '<tr>'
                    + '<td class="lbl"><label for="geo-zip">' + IG.label.get("edit_infoobject_location_post_number") + '</label></td>'
                    + '<td><span class="input"><input type="text" name="geo-zip" id="geo-zip" class="wide" value="" /></span></td>'
                    + '</tr>'

            //Find coordinates button
                    + '<tr>'
                    + '<td class="lbl"></td>'
                    + '<td class="buttons"><a class="btn-normal btn-dark" id="geo-findcoords"><span>' + IG.label.get("edit_infoobject_find_coordinates") + '</span></a></td>'
                    + '</tr>'

            //Latitude
                    + '<tr>'
                    + '<td class="lbl"><label for="geo-latitude">' + IG.label.get("edit_infoobject_width") + '</label></td>'
                    + '<td><span class="input"><input type="text" name="geo-latitude" id="geo-latitude" class="wide" value="" /></span></td>'
                    + '</tr>'

            //Longitude
                    + '<tr>'
                    + '<td class="lbl"><label for="geo-longitude">' + IG.label.get("edit_infoobject_length") + '</label></td>'
                    + '<td><span class="input"><input type="text" name="geo-longitude" id="geo-longitude" class="wide" value="" /></span></td>'
                    + '</tr>'

            //Add geolocation button and save changes button
                    + '<tr>'

                    + '<td class="lbl"></td>'
                    + '<td class="buttons">'
                        + '<a class="btn-normal btn-dark" id="geo-edit-cancel" style="display: none;"><span>' + IG.label.get("cancel") + '</span></a>'
                        + '<a class="btn-normal btn-dark" id="geo-edit-save" style="display: none;"><span>' + IG.label.get("global_update") + '</span></a>'
                        + '<a class="btn-normal btn-dark" id="geo-addgeolocation"><span>' + IG.label.get("edit_infoobject_add_geolocation") + '</span></a>'
                    + '</td>'

                    + '</tr>'



                    + '</tbody>'
                    + '</table>';

            //Append HTML contents:
            $(objDivContent).append(s);
        }

        //Set values, attach event handlers etc.
        var objName = $(objDivContent).find("#geo-name").get(0);
        var objAddress1 = $(objDivContent).find("#geo-address1").get(0);
        var objAddress2 = $(objDivContent).find("#geo-address2").get(0);
        var objCity = $(objDivContent).find("#geo-city").get(0);
        var objZip = $(objDivContent).find("#geo-zip").get(0);

        var btnFindCoords = $(objDivContent).find("#geo-findcoords").get(0);
        var objLat = $(objDivContent).find("#geo-latitude").get(0);
        var objLng = $(objDivContent).find("#geo-longitude").get(0);


        //Builds geolocation HTML:
        var fnAddGeoLocation = function(geo, geoIndex) {

            //Build HTML content:
            var s = ''
                    + '<table>'
                    + '<tbody>'

            //Name
                    + '<tr class="name">'
                    + '<td class="lbl">' + IG.label.get("edit_infoobject_location_name") + '</td>'
                    + '<td><span class="input ellipsis"></span></td>'
                    + '</tr>'

            //Address1
                    + '<tr class="address1">'
                    + '<td class="lbl">' + IG.label.get("infoobject_geolocation_address1") + '</td>'
                    + '<td><span class="input ellipsis"></span></td>'
                    + '</tr>'

            //Address2
                    + '<tr class="address2">'
                    + '<td class="lbl">' + IG.label.get("infoobject_geolocation_address2") + '</td>'
                    + '<td><span class="input ellipsis"></span></td>'
                    + '</tr>'

            //City
                    + '<tr class="city">'
                    + '<td class="lbl">' + IG.label.get("edit_infoobject_location_city") + '</td>'
                    + '<td><span class="input ellipsis"></span></td>'
                    + '</tr>'

            //Zip
                    + '<tr class="zip">'
                    + '<td class="lbl">' + IG.label.get("edit_infoobject_location_post_number") + '</td>'
                    + '<td><span class="input ellipsis"></span></td>'
                    + '</tr>'

            //Latitude
                    + '<tr class="latitude">'
                    + '<td class="lbl">' + IG.label.get("edit_infoobject_width") + '</td>'
                    + '<td><span class="input ellipsis"></span></td>'
                    + '</tr>'

            //Longitude
                    + '<tr class="longitude">'
                    + '<td class="lbl">' + IG.label.get("edit_infoobject_length") + '</td>'
                    + '<td><span class="input ellipsis"></span></td>'
                    + '</tr>'


                    + '</tbody>'
                    + '</table>';

            //Create geolocation html item:
            var objDiv = document.createElement("div");
            $(objDiv)
                    .addClass("ig-geolocation-item")
                    .html(s)
                    .appendTo(objDivContent);


            var objSpanName = $(objDiv).find("tr.name span.input:first").get(0);
            var strName = geo.name[IG.config.language];
            if (strName != "") {
                $(objSpanName).text(strName);
            }
            else {
                $(objSpanName).html("&nbsp;");
            }


            var objSpanAddress1 = $(objDiv).find("tr.address1 span.input:first").get(0);
            if (geo.address1 != "") {
                $(objSpanAddress1).text(geo.address1);
            }
            else {
                $(objSpanAddress1).html("&nbsp;");
            }


            var objSpanAddress2 = $(objDiv).find("tr.address2 span.input:first").get(0);
            if (geo.address2 != "") {
                $(objSpanAddress2).text(geo.address2);
            }
            else {
                $(objSpanAddress2).html("&nbsp");
            }

            var objSpanCity = $(objDiv).find("tr.city span.input:first").get(0);
            if (geo.city != "") {
                $(objSpanCity).text(geo.city);
            }
            else {
                $(objSpanCity).html("&nbsp;");
            }

            var objSpanZip = $(objDiv).find("tr.zip span.input:first").get(0);
            if (geo.zip != "") {
                $(objSpanZip).text(geo.zip);
            }
            else {
                $(objSpanZip).html("&nbsp;");
            }


            var objSpanLat = $(objDiv).find("tr.latitude span.input:first").get(0);
            if (geo.latitude != "") {
                $(objSpanLat).text(geo.latitude);
            }
            else {
                $(objSpanLat).html("&nbsp;");
            }

            var objSpanLng = $(objDiv).find("tr.longitude span.input:first").get(0);
            if (geo.longitude != "") {
                $(objSpanLng).text(geo.longitude);
            }
            else {
                $(objSpanLng).html("&nbsp;");
            }


            //Create buttons for this geolocation item:
            var objDivButtons = document.createElement("div");
            $(objDivButtons)
                .addClass("buttons")
                .appendTo(objDiv);


            //Create lookup button (triggers Google maps overlay):
            var btnLookup = document.createElement("a");
            $(btnLookup)
                .addClass("btn-normal btn-dark btn-space-r")
                .html('<span>' + IG.label.get("infoobject_geolocation_see_map") + '</span>')
                .click(function(event) {
                    event.preventDefault();

                    var strAddress = geo.latitude + "," + geo.longitude
                    var mapUrl = "https://map.infogalleri.net/?showButton=true&address=" + strAddress;
                    $( "#modal-window" )
                        .html('<iframe id="preview-frame" frameborder="0" hspace="0" scrolling="auto" ' +
                            'src="'+mapUrl+'"></iframe>')
                        .dialog({
                            modal: true,
                            position: { my: "center", at: "center", of: window },
                            width: 800,
                            height: 600,
                            closeOnEscape: true,
                            close: null,

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 150,
                            maxHeight: false,
                            buttons: null,
                            title: ''
                        });
                })
                .appendTo(objDivButtons);


            if (bWrite) {
                //Create edit button
                var btnEdit = document.createElement("a");
                $(btnEdit)
                        .addClass("btn-normal btn-dark btn-space-r")
                        .html('<span>' + IG.label.get("global_edit") + '</span>')
                        .click(function(event) {
                            // Put the current info into the input fields and focus there
                            if (typeof geoIndex !== "undefined" && geoIndex !== null) {
                                fnEditGeoLocation(geoIndex);
                            }

                        })
                        .appendTo(objDivButtons);

                //Create delete button:
                var btnDelete = document.createElement("a");
                $(btnDelete)
                        .addClass("btn-normal btn-dark")
                        .html('<span>' + IG.label.get("global_remove") + '</span>')
                        .click(function() {
                            _this.fn.removeGeoLocation(geo);
                            $(objDiv).remove();
                            return false;
                        })
                        .appendTo(objDivButtons);

            }

        };

        var currentLoc = null;

        var fnEditGeoLocation = function(geoIndex) {
            currentLoc = _this.item.geoLocations[geoIndex];

            $('#geo-addgeolocation').hide();
            $('#geo-edit-save').show();
            $('#geo-edit-cancel').show();

            $('#infoobject-geolocations input').val("");
            $('#geo-name').val(currentLoc.name[IG.config.language]).focus();
            $('#geo-address1').val(currentLoc.address1);
            $('#geo-address2').val(currentLoc.address2);
            $('#geo-city').val(currentLoc.city);
            $('#geo-zip').val(currentLoc.zip);
            $('#geo-latitude').val(currentLoc.latitude);
            $('#geo-longitude').val(currentLoc.longitude);

        }


        if (bWrite) {
            //Setup clicks on add geolocation button:
            var btnAddGeo = $(objDivContent).find("#geo-addgeolocation").get(0);
            $(btnAddGeo).click(function() {

                var newGeoLocation = {
                    "latitude": parseFloat($(objLat).val().replace(",", ".")),
                    "longitude": parseFloat($(objLng).val().replace(",", ".")),
                    "name": {
                        "da": $(objName).val(), //FIXME: is this correct?!
                        "en": $(objName).val()
                    },
                    "address1": $(objAddress1).val(),
                    "address2": $(objAddress2).val(),
                    "city": $(objCity).val(),
                    "zip": $(objZip).val()
                };

                var b = true;
                $.each([objLat, objLng, objName], function(i, n) {
                    if ($(n).val() == "") {
                        b = false;
                        $(n).parents("tr:first").addClass("error");
                    }
                    else {
                        $(n).parents("tr:first").removeClass("error");
                    }
                });
                if (b) {
                    var newGeoLocIndex = _this.fn.addGeoLocation(newGeoLocation);
                    if (typeof newGeoLocIndex == "number" && newGeoLocIndex != -1) {
                        fnAddGeoLocation(newGeoLocation, newGeoLocIndex);
                        $('#infoobject-geolocations input').val("");
                    }
                }

                return false;
            });


            //Setup modal window for find coordinates button:
            $(btnFindCoords).click(function(event) {
                event.preventDefault();

                var s = "";
                $.each([objAddress1, objAddress2, objCity, objZip], function(i, n) {
                    if ($(n).val() != "") {
                        s += (s != "" ? ", " : "") + $(n).val();
                    }
                });
                var mapUrl = "https://map.infogalleri.net/?showButton=true&address=" + encodeURIComponent(s);
                $( "#modal-window" )
                    .html('<iframe id="preview-frame" frameborder="0" hspace="0" scrolling="auto" ' +
                        'src="'+mapUrl+'"></iframe>')
                    .dialog({
                        modal: true,
                        position: { my: "center", at: "center", of: window },
                        width: 800,
                        height: 600,
                        closeOnEscape: true,
                        close: null,

                        // reset to defaults
                        minWidth: 150,
                        maxWidth: false,
                        minHeight: 150,
                        maxHeight: false,
                        buttons: null,
                        title: ''
                    });

            });


            // Setup save edit click
            var btnEditGeo = $(objDivContent).find("#geo-edit-save").get(0);
            $(btnEditGeo).click(function(event) {
                event.preventDefault();

                var b = true;
                $.each([objLat, objLng, objName], function(i, n) {
                    if ($(n).val() == "") {
                        b = false;
                        $(n).parents("tr:first").addClass("error");
                    }
                    else {
                        $(n).parents("tr:first").removeClass("error");
                    }
                });

                if (b) {
                    currentLoc.name = {
                        "da": $(objName).val(), //FIXME: is this correct?!
                        "en": $(objName).val()
                    };
                    currentLoc.address1 = $(objAddress1).val();
                    currentLoc.address2 = $(objAddress2).val();
                    currentLoc.city = $(objCity).val();
                    currentLoc.zip = $(objZip).val();
                    currentLoc.latitude = parseFloat($(objLat).val().replace(",", "."));
                    currentLoc.longitude = parseFloat($(objLng).val().replace(",", "."));

                    _this.itemHasChanged = true;

                    $('#infoobject-geolocations input').val("");
                    $('#geo-addgeolocation').show();
                    $('#geo-edit-save').hide();
                    $('#geo-edit-cancel').hide();

                    // Rebuild list
                    $('.ig-geolocation-item').remove();
                    if (_this.item.geoLocations.length > 0) {
                        $.each(_this.item.geoLocations, function(i, n) {
                            fnAddGeoLocation(n, i);
                        });
                    }
                }

            });

            // Setup cancel edit geo click
            var btnCancelEditGeo = $(objDivContent).find("#geo-edit-cancel").get(0);
            $(btnCancelEditGeo).click(function(event) {
                event.preventDefault();

                $('#infoobject-geolocations input').val("");
                $('#geo-addgeolocation').show();
                $('#geo-edit-save').hide();
                $('#geo-edit-cancel').hide();
            });


            //Unregister postmessage listener if it exists:
            pm.unbind("latlngMessage");

            //Register postmessage listener for the map API
            pm.bind("latlngMessage", function(data) {
                $(objLat).val(data.lat);
                $(objLng).val(data.lng);
                $( "#modal-window").dialog('close');
            });
        }

        //Add existing geoLocations to HTML:
        if (_this.item.geoLocations.length > 0) {
            $.each(_this.item.geoLocations, function(i, n) {
                fnAddGeoLocation(n, i);
                // fnAddGeoLocation(objDivContent, n);
            });
        }

        return objDiv;
    };

    this.getItemStatusText = function() {
        return IG.fn.getInfoObjectStatus(_this.item).caption;
    };

    //Renders publish box:
    //FIXME: check current user permissions.
    //FIXME: clean up markup and CSS.
    //FIXME: date selector buttons styling.
    this.renderPublish = function(bWrite) {
        var dateDisplayFormat = 'lll';
        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "infoobject-publish")
            .addClass("ig-edit-section");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_publish"))
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content")
            .appendTo(objDiv);

        var objTable = document.createElement("table");
        $(objTable).appendTo(objDivContent);

        var objTBody = document.createElement("tbody");
        $(objTBody).appendTo(objTable);


        //Build status row:
        var statusText = _this.getItemStatusText();
        var objTRstatus = document.createElement("tr");
        $(objTRstatus)
	        .addClass("status")
	        .appendTo(objTBody);

        var objTDstatusLbl = document.createElement("td");
        $(objTDstatusLbl)
	        .html(IG.label.get("edit_infoobject_status"))
	        .appendTo(objTRstatus);
        var objTDstatus = document.createElement("td");
        $(objTDstatus)
	        .attr("id", "info-object-status")
	        .html('<span>' + statusText + '</span>')
	        .appendTo(objTRstatus);


        //Build display start time row:
        var objTRstart = document.createElement("tr");
        $(objTRstart)
	        .addClass("start-time")
	        .appendTo(objTBody);

        var objTDstartLbl = document.createElement("td");
        $(objTDstartLbl)
	        .addClass("lbl")
	        .text(IG.label.get("edit_infoobject_display_start_time") + ':')
	        .appendTo(objTRstart);

        var objTDstart = document.createElement("td");
        $(objTDstart).appendTo(objTRstart);

        var objInputStart = document.createElement("input");
        $(objInputStart)
	        .addClass("date-time")
	        .attr({
	            "type": "text",
	            "id": "datepicker_from",
	            "readonly": "readonly",
	            "value": moment(_this.item.displayStartTime*1000).format(dateDisplayFormat)
	        })
	        .appendTo(objTDstart);

        // If it's a child, show the value from the parent
        if (!_this.fn.isParent() && _this.parentObj !== null) {
            $(objInputStart).val( IG.fn.formatTime(_this.parentObj.displayStartTime) );
        }

        if (bWrite && _this.fn.isParent()) {
            $(objInputStart)
                .addClass("datepicker")
                .click(function(event) {
                    var _input = $(this);
                    var dtp = new IG.DateTimePicker({
                        current: _this.item.displayStartTime,
                        displayFormat: dateDisplayFormat
                    });

                    $( "#modal-window" )
                        .html(dtp.getContent())
                        .dialog({
                            modal: true,
                            title: '',
                            position: { my: "right top", at: "right bottom", of: objInputStart, collision: 'fit' },
                            width: 315,
                            height: 'auto',
                            buttons: [
                                {
                                    text: IG.label.get("ok"),
                                    click: function() {
                                        dtp.save(function(UTCtimeStampSecs, formattedStr) {
                                            _input.val(formattedStr);
                                            _this.item.displayStartTime = UTCtimeStampSecs;
                                            _this.itemHasChanged = true;
                                            $( "#modal-window" ).dialog( "close" );
                                        });
                                    }
                                },
                                {
                                    text: IG.label.get("cancel"),
                                    click: function() {
                                        $( this ).dialog( "close" );
                                    }
                                }
                            ],

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 150,
                            maxHeight: false
                        });
                });
        }


        //Build display end time row:
        var objTRend = document.createElement("tr");
        $(objTRend)
            .addClass("end-time")
            .appendTo(objTBody);

        var objTDendLbl = document.createElement("td");
        $(objTDendLbl)
            .addClass("lbl")
            .text(IG.label.get("edit_infoobject_arangement_to") + ':')
            .appendTo(objTRend);

        var objTDend = document.createElement("td");
        $(objTDend).appendTo(objTRend);

        var objInputEnd = document.createElement("input");
        $(objInputEnd)
            .attr({
                "type": "text",
                "id": "datepicker_to",
                "readonly": "readonly",
                "value": moment(_this.item.displayEndTime*1000).format(dateDisplayFormat)
            })
            .addClass("date-time")
            .appendTo(objTDend);

        // If it's a child, show the value from the parent
        if (!_this.fn.isParent() && _this.parentObj !== null) {
            $(objInputEnd).val( IG.fn.formatTime(_this.parentObj.displayEndTime) );
        }

        if (bWrite && _this.fn.isParent()) {
            $(objInputEnd)
                .addClass("datepicker")
                .click(function(event) {
                    event.preventDefault();

                    var _input = $(this);
                    var dtp = new IG.DateTimePicker({
                        current: _this.item.displayEndTime,
                        displayFormat: dateDisplayFormat
                    });

                    $( "#modal-window" )
                        .html(dtp.getContent())
                        .dialog({
                            modal: true,
                            title: '',
                            position: { my: "right top", at: "right bottom", of: objInputEnd, collision: 'fit' },
                            width: 315,
                            height: 'auto',
                            buttons: [
                                {
                                    text: IG.label.get("ok"),
                                    click: function() {
                                        dtp.save(function(UTCtimeStampSecs, formattedStr) {
                                            _input.val(formattedStr);
                                            _this.item.displayEndTime = UTCtimeStampSecs;
                                            _this.itemHasChanged = true;
                                            $( "#modal-window" ).dialog( "close" );
                                        });
                                    }
                                },
                                {
                                    text: IG.label.get("cancel"),
                                    click: function() {
                                        $( this ).dialog( "close" );
                                    }
                                }
                            ],

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 150,
                            maxHeight: false
                        });

                });
        }


        //Build creator row:
        var creator = IG.getUserById(_this.item.editor);
        var objTRcreator = document.createElement("tr");
        $(objTRcreator).appendTo(objTBody);

        var objTDcreatorLbl = document.createElement("td");
        $(objTDcreatorLbl)
            .addClass("lbl")
            .text(IG.label.get("infoobject_createdby"))
            .appendTo(objTRcreator);

        var objTDcreator = document.createElement("td");
        $(objTDcreator)
            .html(creator != null ? creator.name : '<em>'+IG.label.get('GLOBAL_MISSING_EDITOR_NAME')+'</em>')
            .appendTo(objTRcreator);

        //Build created date row:
        var objTRcreated = document.createElement("tr");
        $(objTRcreated).appendTo(objTBody);

        var objTDcreatedLbl = document.createElement("td");
        $(objTDcreatedLbl)
                .addClass("lbl")
                .text(IG.label.get("edit_infoobject_date_created") + ':')
                .appendTo(objTRcreated);

        var objTDcreated = document.createElement("td");
        $(objTDcreated)
                .text(IG.fn.formatTime(_this.item.created))
                .appendTo(objTRcreated);



        // Build change ownership button
        if (IG.userHasPermission('OP_USER_ADMIN')) {
            var newOwnerObj = null;

            var objTrOwnership = document.createElement('tr');
            $(objTrOwnership).appendTo(objTBody);

            var objTdOwnershipLbl = document.createElement('td');
            $(objTdOwnershipLbl).appendTo(objTrOwnership);

            var objTdOwnership = document.createElement('td');
            $(objTdOwnership).appendTo(objTrOwnership);

            var objAChangeOwner = document.createElement('a');
            $(objAChangeOwner)
                .text(IG.label.get('CHANGE_OWNER_LINK'))
                /* TODO: uncomment when ready
                .appendTo(objTdOwnership) */
                .click(function(e) {
                    e.preventDefault();
                    newOwnerObj = null;
                    if (_this.isNew) {
                        IG.showErrorDialog(IG.label.get('infoobject_save_before_changing_owner'))
                        return;
                    }

                    console.log('TRANSFER:', _this.item.id);

                    var waitingForReq = false;

                    // Open dialog
                    $( "#modal-window" )
                        .html(_this.buildChangeOwnerDialogContent())
                        .dialog({
                            title: IG.label.get("CHANGE_OWNER_DIALOG_TITLE"),
                            modal: true,
                            position: { my: "center", at: "center", of: window },
                            width: 800,
                            height: 'auto',
                            buttons: [{
                                text: IG.label.get("ok"),
                                click: function() {
                                    var _this = this;
                                    if (waitingForReq) return;


                                    // validate input
                                    var toVal = $("input[name=radio-group-to-user]:checked").val();
                                    if (!toVal) {
                                        IG.showErrorDialog(IG.label.get('ITEM_NEW_USER_ERROR_NONE_CHOSEN'));
                                        return;
                                    }
                                    var toId = IG.getIdFromString(toVal);
                                    if (!toId) {
                                        IG.showErrorDialog(IG.label.get('USER_TRANSFER_ERROR_INVALID_IDS'));
                                        return;
                                    }
                                    if (IG.IdsEqual(creator.id, toId)) {
                                        IG.showErrorDialog(IG.label.get('USER_TRANSFER_ERROR_SAME_USER'));
                                        return;
                                    }


                                    waitingForReq = true;
                                    IG.gui.loading.show(_this);


                                    // TODO: request server or update the Info Object and save later
                                    window.setTimeout(function() {
                                        $.each(IG.lists.users, function(i, n) {
                                            if (IG.IdsEqual(n.id, toId)) {
                                                console.log(n);
                                                newOwnerObj = n;
                                            }
                                        });

                                        if (newOwnerObj) {
                                            $(objTDcreator).html(newOwnerObj.name);
                                        }

                                        waitingForReq = false; // reset
                                        IG.gui.loading.hide();
                                        $(_this).dialog( "close" );
                                    }, 1000);
                                }
                            }]
                        });

                });
        }



        var objTRmodifiedBy = document.createElement("tr");
        $(objTRmodifiedBy).appendTo(objTBody);

        var objTDmodifiedByLbl = document.createElement("td");
        $(objTDmodifiedByLbl)
                    .text(IG.label.get("infoobject_modifiedby"))
                    .appendTo(objTRmodifiedBy);

        var objTDmodifiedBy = document.createElement("td");
        $(objTDmodifiedBy)
            .attr("id", "lastModifiedByUserId")
            .appendTo(objTRmodifiedBy);

        //Build last modified by row:
        if (_this.item.lastModifiedByUserId) {
            var editorUsr = IG.getUserById(_this.item.lastModifiedByUserId.id);
            if (editorUsr != null) {
                $(objTDmodifiedBy).text(editorUsr.name);
            } else {
                $(objTDmodifiedBy).html('<em>'+IG.label.get('GLOBAL_MISSING_EDITOR_NAME')+'</em>')
            }
        } else {
            $(objTRmodifiedBy).css("display", "none");
        }

        //Build last modified date row:
        var objTRlastModified = document.createElement("tr");
        $(objTRlastModified).appendTo(objTBody);

        var objTDlastModifiedLbl = document.createElement("td");
        $(objTDlastModifiedLbl)
                .addClass("lbl")
                .text(IG.label.get("edit_infoobject_date_modified") + ':')
                .appendTo(objTRlastModified);

        var objTDlastModified = document.createElement("td");
        $(objTDlastModified)
                .attr("id", "last-modified-date")
                .text(IG.fn.formatTime(_this.item.lastModified))
                .appendTo(objTRlastModified);




        //Create buttons
        if (bWrite) {
            var objDivButtons = document.createElement("div");
            $(objDivButtons)
                .addClass("buttons")
                .appendTo(objDivContent);

            var objDivButtonsContent = document.createElement("div");
            $(objDivButtonsContent)
                .addClass("content clearfix")
                .appendTo(objDivButtons);


            //Create save button:
            var btnSave = document.createElement("a");
            $(btnSave)
                .addClass("btn-normal btn-dark btn-space-r save")
                .html('<span>' + IG.label.get("edit_infoobject_save") + '</span>')
                .click(function(e) {
                    e.preventDefault();

                    window.setTimeout(function() {
                        _this.updateInfoObject({
                            onStart: function() {
                                IG.gui.loading.show();
                            },
                            onEnd: function(object) {
                                if (object && object.type === 'poll') {
                                    _this.renderPollOptions($('#poll-options-container'), bWrite);
                                }
                                if (object) {
                                    IG.documentTitleCache.set(object.id, object.headline);
                                }
                                IG.gui.loading.hide();
                            }
                        });
                    }, 1);
                })
                .appendTo(objDivButtonsContent);


            //Create draft/publish button:
            //FIXME: check current state of InfoObject (draft/published)
            //Toggle label and behavor!?

            if (_this.fn.isParent()) {
                var btnDraft = document.createElement("a");
                $(btnDraft)
		            .addClass("btn-normal btn-pink")
		            .html('<span>' + (_this.item.published ? IG.label.get("infoobject_unpublish") : IG.label.get("infoobject_publish")) + '</span>')
		            .click(function() {
		                if (_this.isNew) {
		                    // Create a new inforObject when it isn't saved before:
                            _this.updateInfoObject({
                                onStart: function() {
                                    IG.gui.loading.show();
                                },
                                onEnd: function(object) {
                                    if (object && object.type === 'poll') {
                                        _this.renderPollOptions($('#poll-options-container'), bWrite);
                                    }
                                    IG.gui.loading.hide();
                                },
                                published: true
                            });
                            return false;
		                }

		                var a = $(this);
		                _this.item.published = !_this.item.published;
		                _this.updateInfoObject({
		                    onStart: function() {
		                        IG.gui.loading.show();
		                    },
		                    onEnd: function(object) {
		                        IG.gui.loading.hide();
		                        a.html('<span>' + (_this.item.published ? IG.label.get("infoobject_unpublish") : IG.label.get("infoobject_publish")) + '</span>')
		                        $(objTDstatus)
	                                .html('<span>' + _this.getItemStatusText() + '</span>');
		                        $(objTDlastModified)
                                    .text(IG.fn.formatTime(Math.round((new Date()).getTime() / 1000)));

                                if (object && object.type === 'poll') {
                                    _this.renderPollOptions($('#poll-options-container'), bWrite);
                                }
		                    }
		                });
		            })
		            .appendTo(objDivButtonsContent);
            }

            var buttonHolder = document.createElement("div");
            $(buttonHolder).addClass('button-holder');
        }

        return objDiv;
    };



    //Renders permissions box:
    this.renderPermissions = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_permissions_title"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content")
            .appendTo(objDiv);

        var groupPermisions = {};
        var userPermisions = {};
        if (_this.isNew) {//if it is new we need to copy permisions from user profile
            var user = $.grep(
                IG.lists.users,
                function(u, i) {
                    return u.id.id == IG.userConfig.userId.id;
                }
            );
            groupPermisions = user[0].defaultCreatePermissions.groups;
            userPermisions = user[0].defaultCreatePermissions.users;
        } else {
            groupPermisions = _this.item.permissions.groups;
            userPermisions = _this.item.permissions.users;
        }

        var IGPermissionsGroups = new IG.PermissionsBox({
            type: "groups",
            key: "INFO_OBJECT",
            title: IG.label.get("edit_infoobject_permissions_groups"),
            items: IG.lists.groups,
            permissions: groupPermisions,
            write: bWrite,
            fnAdd: _this.fn.addPermission,
            fnRemove: _this.fn.removePermission,
            fnHasPermission: _this.fn.hasPermission
        });

        $(objDivContent)
            .append(IGPermissionsGroups.render());

        IG.users.loadEnabled(_this.userPermissions, function(userData) {
            if (userData) {
                var IGPermissionsUsers = new IG.PermissionsBox({
                    type: "users",
                    key: "INFO_OBJECT",
                    title: IG.label.get("edit_infoobject_permissions_users"),
                    items: userData.data,
                    count: _this.userPermissions.count,
                    permissions: userPermisions,
                    write: bWrite,
                    fnAdd: _this.fn.addPermission,
                    fnRemove: _this.fn.removePermission,
                    fnHasPermission: _this.fn.hasPermission,
                    fnLoadMore: function(callback) {
                        _this.userPermissions.offset += _this.userPermissions.limit;

                        IG.users.loadEnabled(_this.userPermissions, function(userData) {
                            if ($.isFunction(callback)) {
                                callback(userData.data);
                            }
                        });
                    }
                });

                $(objDivContent)
                    .append(IGPermissionsUsers.render())
            }
        });





        return objDiv;
    };

    this.renderPreview = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "infoobject-preview")
            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_gallery_preview"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content clearfix")
            .appendTo(objDiv);


        var objDivSelects = document.createElement("div");
        $(objDivSelects)
            .addClass("selects")
            .appendTo(objDivContent);

        var objDivButtons = document.createElement("div");
        $(objDivButtons)
            .addClass("buttons")
            .appendTo(objDivContent);


        //Build ratio select:
        var objSelectRatio = document.createElement("select");
        $(objSelectRatio)
            .attr("name", "ratio")
            .appendTo(objDivSelects)
            .addClass('hubber-select hubber-select-wide')
            .wrap('<span class="input"></span>');

        //Populate with available aspect ratios:
        $.each(IG.customerConfig.previewAspectRatios, function(i, n) {
            var objOption = document.createElement("option");
            $(objOption)
                .text(i)
                .val(i)
                .appendTo(objSelectRatio);
        });

        //Build resolution select:
        var objSelectRes = document.createElement("select");
        $(objSelectRes)
            .attr("name", "resolution")
            .appendTo(objDivSelects)
            .addClass('hubber-select hubber-select-wide')
            .wrap('<span class="input"></span>');

        //Populate with available resolutions for selected ratio:
        $(objSelectRes).append( IG.gui.checkSizes($(objSelectRatio).val()) );

        //Re-populate with available resolutions when changing ratio:
        $(objSelectRatio).change(function() {
            var resolutions = IG.gui.checkSizes($(this).val());

            $(objSelectRes).html("");
            $.each(resolutions, function(i, n) {
                $(objSelectRes).append(n);
            });
        });


        //Build gallery select:
        var objSelectGall = document.createElement("select");
        $(objSelectGall)
            .attr("name", "gallery")
            .appendTo(objDivSelects)
            .addClass('hubber-select hubber-select-wide')
            .wrap('<span class="input"></span>');

        //Populate with available galleries:
        //Sort galleries by their names
        var sorted = _.sortBy(IG.lists.galleries, 'name.' + IG.config.language);
        $.each(sorted, function(i, n) {
            var objOption = document.createElement("option");
            $(objOption)
                .val(n.id['id'])
                .attr("title", n.name[IG.config.language])
                .text(n.name[IG.config.language])
                .appendTo(objSelectGall);
        });


        //Build preview-button:
        var saveAndPreviewText = IG.label.get("edit_infoobject_save_and_preview");
        if (!bWrite) {
            saveAndPreviewText = IG.label.get("infoobject_preview");
        }
        var btnPreview = document.createElement("a");
        $(btnPreview)
            .addClass("btn-normal btn-dark")
            .html('<span>' + saveAndPreviewText + '</span>')
            .click(function() {
                var ratio = $(objSelectRatio).val();
                var resolution = $(objSelectRes).val();

                var rx = /^(\d+)[^\d](\d+)$/i;
                var w = 0;
                var h = 0;

                //Specific resolution:
                if (rx.test(resolution)) {
                    w = parseInt(resolution.replace(rx, "$1"), 10);
                    h = parseInt(resolution.replace(rx, "$2"), 10);
                }
                //Fullscreen:
                else {
                    //Adjust to match aspect ratio and fit inside screen:

                    w = $(window).width() - 160;
                    h = $(window).height() - 200;


                    var ratX = parseInt(ratio.replace(rx, "$1"), 10);
                    var ratY = parseInt(ratio.replace(rx, "$2"), 10);

                    var rat = ratX / ratY;
                    var ratWin = w / h;

                    //Window wider than that of selected aspect ratio:
                    if (ratWin > rat) {
                        //Fit to height:
                        w = h * rat;
                    }
                    //Window narrower than that of selected aspect ratio:
                    else if (ratWin < rat) {
                        //Fit to width:
                        h = w / rat;
                    }

                    w = Math.round(w);
                    h = Math.round(h);
                }

                var galleryId = $(objSelectGall).val();
                if (galleryId !== null) {
                    IG.getGalleryById(galleryId, function(objGallery) {
                        //if it is not readonly object, save it first and than preview it
                        if (bWrite) {
                            _this.updateInfoObject({
                                forceSync: true,
                                onStart: function() {

                                },
                                onEnd: function(object) {
                                    if (object && object.type === 'poll') {
                                        _this.renderPollOptions($('#poll-options-container'), bWrite);
                                    }
                                    IG.gui.loading.hide();

                                    // get a preview url:
                                    IG.urls.preview.infoobject(objGallery.id, object.id, w, h, function(url) {
                                        if (url !== null) {
                                            if (objGallery != null) {
                                                IG.showPreview({
                                                    href: url,
                                                    width: w,
                                                    height: h
                                                });
                                            }

                                        } else {
                                            IG.showErrorDialog(IG.label.get('gallery_missing_modules'));
                                        }
                                    });

                                }
                            });
                        } else {        //just preview it, don't save it
                            IG.urls.preview.infoobject(objGallery.id, _this.item.id, w, h, function(url) {
                                if (url !== null) {
                                    if (objGallery != null) {
                                        IG.showPreview({
                                            href: url,
                                            width: w,
                                            height: h
                                        });
                                    }

                                } else {
                                    IG.showErrorDialog(IG.label.get('gallery_missing_modules'));
                                }
                            });
                        }
                    });
                } else {
                    IG.showErrorDialog(IG.label.get('gallery_unknown'));
                }

                return false;
            })
            .appendTo(objDivButtons);

        return objDiv;
    };




    /* Renders channel selection box: */
    this.renderChannels = function(bWrite) {
    	if (_this.fn.isParent()) {

	        var objDiv = document.createElement("div");
	        $(objDiv)
		            .attr("id", "infoobject-channels")
		            .addClass("ig-edit-section collapsable");

	        var objH4 = document.createElement("h4");
	        $(objH4)
                .text(IG.label.get("edit_infoobject_channels_title"))
                .append('<span class="collapse-arrow"></span>')
                .appendTo(objDiv);

	        var objDivContent = document.createElement("div");
	        $(objDivContent)
	                .addClass("ig-edit-section-content clearfix")
	                .appendTo(objDiv);




	        if (bWrite) {

	            var objP = document.createElement("p");
	            $(objP)
	                    .text(IG.label.get("infoobject_add_channel"))
	                    .appendTo(objDivContent);

	            var objDivSelects = document.createElement("div");
	            $(objDivSelects)
	                    .addClass("selects")
	                    .appendTo(objDivContent);

	            var objSelect = document.createElement("select");
	            $(objSelect)
                    .attr("name", "channels")
                    .appendTo(objDivSelects)
                    .addClass('hubber-select')
                    .wrap('<span class="input"></span>');

                //sort channels by name
	            IG.lists.channels.sort(function(a, b) {
	                if (typeof a.name[_this.searchLang] !== "undefined" && typeof b.name[_this.searchLang] !== "undefined") {
	                    if (a.name[_this.searchLang] == b.name[_this.searchLang]) { return 0; }
	                    if (a.name[_this.searchLang] > b.name[_this.searchLang]) {
	                        // if a should come after b, return 1
	                        return 1;
	                    } else {
	                        // if b should come after a, return -1
	                        return -1;
	                    }
	                }
                    return 0;
	            });

	            $.each(IG.lists.channels, function(i, n) {

	                var hasWriteAccess = false;

	                if (_this.isChannelAdmin) {
	                    hasWriteAccess = true;
	                } else {
	                    $.each(n.permissions.users.OP_CHANNEL_WRITE, function(j, usId) {
	                        if (IG.userConfig.userId.id == usId.id) {
	                            hasWriteAccess = true;
	                        }
	                    });
	                    if (!hasWriteAccess) {
	                        hasWriteAccess = IG.userHasPermission("OP_CHANNEL_WRITE", n);
	                    }

	                }


                    if (hasWriteAccess) {
                        var inList = false;
                        $.each(_this.item.channelIds, function(j, c) {
                            if (c.id == n.id.id) {
                                inList = true;
                                return false; //break the loop
                            }
                        });



                        var channelTitle = false,
                            defLang = IG.customerConfig.defaultContentLanguage;
                        if (!inList) {
                            if (typeof n.name[_this.searchLang] !== "undefined" && n.name[_this.searchLang].length > 0) {
                                // Choose the title in the chosen language
                                channelTitle = n.name[_this.searchLang];
                            } else if (typeof n.name[defLang] !== "undefined" && n.name[defLang].length > 0) {
                                // Choose the title in the default language
                                channelTitle = n.name[defLang];
                            } else {
                                // Choose the first possible
                                $.each(n.name, function(j, titleVersion) {
                                    if (typeof titleVersion !== "undefined" && titleVersion.length > 0) {
                                        channelTitle = titleVersion;
                                        return false;
                                    }
                                });
                            }
                        }

                        if (channelTitle) {
                            var objOption = document.createElement("option");
                            $(objOption)
                                .text(channelTitle)
                                .val(n.id.id)
                                .appendTo(objSelect);
                        }
                    }

	            });
	            var objDivButtons = document.createElement("div");
	            $(objDivButtons)
	                    .addClass("buttons")
	                    .appendTo(objDivContent);

	        }


	        var objDivChannels = document.createElement("div");
	        $(objDivChannels)
	                .addClass("channels")
	                .appendTo(objDivContent);


	        //Adds HTML elements for a given channel:
	        var fnAddChannel = function(id) {
	            var c = IG.getChannelById(id);

	            if (c != null) {
	                var channelTitle = false,
                        defLang = IG.customerConfig.defaultContentLanguage;

                    if (typeof c.name[_this.searchLang] !== "undefined" && c.name[_this.searchLang].length > 0) {
                        // Choose the title in the chosen language
                        channelTitle = c.name[_this.searchLang];
                    } else if (typeof c.name[defLang] !== "undefined" && c.name[defLang].length > 0) {
                        // Choose the title in the default language
                        channelTitle = c.name[defLang];
                    } else {
                        // Choose the first possible
                        $.each(c.name, function(j, titleVersion) {
                            if (typeof titleVersion !== "undefined" && titleVersion.length > 0) {
                                channelTitle = titleVersion;
                                return false;
                            }
                        });
                    }
	            }


	            if (c != null && channelTitle) {
	                //Check for table:
	                var objTable = $(objDivChannels).children("table:first").get(0);
	                if (objTable == null) {
	                    objTable = document.createElement("table");
	                    $(objTable).appendTo(objDivChannels);
	                }

	                //Create channel row:
	                var objTR = document.createElement("tr");
	                $(objTR)
	                        .attr("title", channelTitle)
	                        .appendTo(objTable);


	                //Create channel title cell:
	                var objTDtitle = document.createElement("td");
	                $(objTDtitle)
	                        .addClass("ellipsis")
	                        .appendTo(objTR);

	                //Create link to edit channel object:
	                var objA = document.createElement("a");
	                $(objA)
	                        .text(channelTitle)
	                        .click(function() {
	                            if (IG.infoObjectChanged()) {
                                    IG.showInfoObjectChangedDialog({
                                        confirm: function() {
                                            IG.showEditChannel({
                                                item: c,
                                                id: c.id.id,
                                                history: true
                                            });
                                        }
                                    });
                                } else {
                                    IG.showEditChannel({
                                        item: c,
                                        id: c.id.id,
                                        history: true
                                    });
                                }



	                        })
	                        .appendTo(objTDtitle);

	                //remove option from select
	                $("option[value='"+c.id.id+"']", objSelect).remove();

	                if (bWrite) {
	                    //Create channel button cell:
	                    var objTDdelete = document.createElement("td");
	                    $(objTDdelete)
	                            .addClass("buttons")
	                            .appendTo(objTR);

	                    var hasWriteAccess = false;
	                    if (_this.isChannelAdmin) {
	                        hasWriteAccess = true;
	                    } else {
	                        $.each(c.permissions.users.OP_CHANNEL_WRITE, function(k, usId) {
	                            if (IG.userConfig.userId.id == usId.id) {
	                                hasWriteAccess = true;
	                            }
	                        });

	                        if (!hasWriteAccess) {
	                            hasWriteAccess = IG.userHasPermission("OP_CHANNEL_WRITE", c);
	                        }
	                    }

                        if (hasWriteAccess) {
    	                    //Create delete button:
    	                    var btnDelete = document.createElement("a");
    	                    $(btnDelete)
    	                            .addClass("btn-small btn-dark")
    	                            .html('<span>' + IG.label.get("global_remove") + '</span>')
    	                            .click(function() {
    	                                _this.fn.removeChannel(c.id);
    	                                $(objTR).remove();
    	                                var objOption = document.createElement("option");
    	                                $(objOption)
    	                                    .text(c.name[_this.searchLang])
    	                                    .val(c.id.id)
    	                                    .appendTo(objSelect);
    	                                return false;
    	                            })
    	                            .appendTo(objTDdelete);
                        }
	                }
	            }
	        };


	        if (bWrite) {
	            //Create add to channel-button:
	            var btnAdd = document.createElement("a");
	            $(btnAdd)
	                    .addClass("btn-normal btn-dark")
	                    .html('<span>' + IG.label.get("edit_infoobject_add_channel") + '</span>')
	                    .click(function() {

	                        var b = true;
	                        var id = $(objSelect).val();
	                        var cId = IG.getChannelById(id);
	                        //Check if channel has already been addded:
	                        $.each(_this.item.channelIds, function(i, n) {
                                if (n.id == id) {
	                                b = false;
	                                return false; //break loop
	                            }
	                        });

	                        //Add channel:
	                        if (b && cId) {
	                            if (_this.fn.addChannel(cId.id)) {
                                    _this.itemHasChanged = true;
	                                fnAddChannel(cId.id);
	                            }
	                        }

	                        return false;
	                    })
	                    .appendTo(objDivButtons);
	        }


	        //Append already selected channels:
	        $.each(_this.item.channelIds, function(i, n) {
	            fnAddChannel(n);
	        });

	        return objDiv;
    	}
    };


    this.buildChangeOwnerDialogContent = function() {
        var objDiv = document.createElement("div");
        $(objDiv).attr('id', 'ig-users-ownership-list-to');


        var objHeaderTo = document.createElement('h4');
        $(objHeaderTo)
            .text(IG.label.get('CHANGE_OWNER_DIALOG_TEXT'))
            .appendTo(objDiv);

        var objDivListTo = document.createElement("div");
        $(objDivListTo)
            .addClass("hubber-list-wrap")
            .height('280px')
            .appendTo(objDiv);

        var listUserTo = new IG.ObjectList({
            methodName: "searchUsers",
            parentObject: objDivListTo,
            scrolling: true,
            filters: {'enabled': true},
            columns: [
                {
                    name: 'select',
                    title: IG.label.get(''),
                    width: '30px',
                    render: function(object) {
                        var objRadioBtn = document.createElement('input');
                        $(objRadioBtn)
                            .attr('type', 'radio')
                            .attr('name', 'radio-group-to-user')
                            .val(object.id.customerId+'::'+object.id.id);
                        return objRadioBtn;
                    }
                },
                {
                    name: "name",
                    title: IG.label.get("users_name"),
                    className: "ellipsis",
                    sortable: true,
                    width: "auto",
                    renderTitle: function(object) {
                        var s = "...";
                        if (typeof object.name == "string") {
                            s = object.name;
                        }
                        return s;
                    },
                    render: function(object) {
                        var s = "...";
                        if (typeof object.name == "string") {
                            s = object.name;
                        }
                        if (typeof object.enabled !== 'undefined' && !object.enabled) {
                            s += ' (' + IG.label.get('USERS_USER_DISABLED') + ')';
                        }
                        return s;
                    }
                }
            ]
        });




        /*var obtBtnTransfer = document.createElement('a');
        $(obtBtnTransfer)
            .addClass('btn-normal btn-dark')
            .prepend('<span>' + IG.label.get('transfer_ownership_btn') + '</span>')
            .appendTo(objDiv)
            .click(function(e) {
                e.preventDefault();

                var fromVal = $("input[name=radio-group-from-user]:checked").val();
                var toVal = $("input[name=radio-group-to-user]:checked").val();

                if (fromVal && toVal) {
                    if (fromVal === toVal) {
                        IG.showErrorDialog(IG.label.get('USER_TRANSFER_ERROR_SAME_USER'));
                        return;
                    }

                    var fromId = IG.getIdFromString(fromVal);
                    var toId = IG.getIdFromString(toVal);

                    if (!fromId || !toId) {
                        IG.showErrorDialog(IG.label.get('USER_TRANSFER_ERROR_INVALID_IDS'));
                        return;
                    }

                    //Create new modal dialog:
                    var objDialog = IG.createConfirmDialog({
                        title: IG.label.get("users_transfer_ownership_popup_title"),
                        text: "",
                        onConfirm: function() {
                            IG.gui.loading.show();


                            // TODO: send request
                            console.log('TRANSFER', fromId, toId);
                            window.setTimeout(function() {
                                IG.gui.loading.hide();
                                objDialog.close();
                            }, 500);

                        }
                    });
                } else {
                    IG.showErrorDialog(IG.label.get('USER_TRANSFER_ERROR_NONE_CHOSEN'));
                }


            });*/

        listUserTo.init();
        return objDiv;
    };




    /* Build RSS dialog content: */
    this.buildRSSDialogContent = function() {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("rss");


        //Close icon:
        var objSpanClose = document.createElement("span");
        $(objSpanClose)
                .addClass("icon-close")
                .click(function() {
                    //FIXME: close qtip dialog - normal fashion?
                    IG.closeModalDialog()
                })
                .appendTo(objDiv);

        //Search block:
        var objDivSearch = document.createElement("div");
        $(objDivSearch)
                .addClass("rss-search")
                .appendTo(objDiv);


        var objLabel = document.createElement("label");
        $(objLabel)
                .text(IG.label.get("infoobject_rss_address"))
                .attr("for", "rss-address")
                .appendTo(objDivSearch);



        //RSS address input field:
        var objInput = document.createElement("input");
        $(objInput)
                .attr({
                    "type": "text",
                    "name": "rss-address",
                    "id": "rss-address",
                    'class' : 'input-text'
                })
                .appendTo(objDivSearch)
                .wrap('<span class="input"></span>');



        //RSS get button:
        var btnGet = document.createElement("a");
        $(btnGet)
                .addClass("btn-normal btn-dark")
                .html('<span>' + IG.label.get("infoobject_rss_download") + '</span>')
                .appendTo(objDivSearch);



        //Results header:
        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("infoobject_rss_result"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDivSearch);

        //Search results block:
        var objDivSearchResults = document.createElement("div");
        $(objDivSearchResults)
                .addClass("rss-search-results")
                .attr("id", "rss-search-results")
                .appendTo(objDiv);


        //Displays RSS feed error message:
        var fnRSSerror = function(s) {
            var objDivSearchResults = $("#rss-search-results").get(0);
            $(objDivSearchResults).empty();

            var objDiv = document.createElement("div");
            $(objDiv)
                    .addClass("error")
                    .text(s)
                    .appendTo(objDivSearchResults);
        };

        //Imports a single RSS feeed item:
        var fnImportRSS = function(item) {
            IG.debug("fnImportRSS, " + item.title + ", " + item.description);

            //Replace headline
            var title = (typeof item.title == "string" ? item.title : "...");
            var subHeadlineId = $("#ig-info_object-subheadline").attr("name");

            IG.EditorController.setText(subHeadlineId, title);

            //Replace body:
            var bodyId = $("#ig-infoobject-body").attr("name");
            IG.EditorController.setText(bodyId, item.description);

            //Replace external link
            $("#infoobject-url").val(item.link);


            $.each(item.mediaUrls, function(i, u) {
                IG.request({
                    method: "importMedia",
                    params: [IG.config.language, u, true],
                    //post: true,
                    success: function(object) {
                        var obj = object.data;
                        if (obj) {
                            _this.item.mediaIds[_this.item.mediaIds.length] = obj.id;
                            _this.addMedia(obj, false);
                        }
                    },
                    error: function(err) {
                        IG.showErrorDialog(err);
                    }
                });
            });
        };

        //Parse RSS feed data:
        var fnParseRSS = function(data) {
            var objDivSearchResults = $("#rss-search-results").get(0);
            $(objDivSearchResults).empty();


            $.each(data, function(i, n) {
                //Create item:
                var objDiv = document.createElement("div");
                $(objDiv)
                        .addClass("item clearfix")
                        .hover(
                            function() {
                                $(this).addClass("current");
                            },
                            function() {
                                $(this).removeClass("current");
                            }
                        );

                //Create title block:
                var objDivTitle = document.createElement("div");
                var text = (typeof n.title == "string" ? n.title : "...");
                $(objDivTitle)
                        .addClass("title")
                        .text(text)
                        .appendTo(objDiv);

                //Create buttons block:
                var objDivButtons = document.createElement("div");
                $(objDivButtons)
                        .addClass("buttons")
                        .appendTo(objDiv);

                //Create description block:
                var objDesc = document.createElement("div");
                $(objDesc)
                        .addClass("description")
                        .text(n.description)
                        .appendTo(objDiv);

                if (n.mediaUrls.length > 0) {
                    var objImages = document.createElement("div");
                    $(objImages)
                            .addClass("rss-images")
                            .appendTo(objDesc);
                    $.each(n.mediaUrls, function(i, u) {
                        var imgContainer = document.createElement("div");
                        $(imgContainer)
                                .addClass("rss-image-container")
                                .appendTo(objImages);
                        var img = document.createElement("img");
                        $(img).attr({
                            "class": "rss-image",
                            "src": u,
                            "height": "107px"
                        })
                            .appendTo(imgContainer);
                    });
                }

                //Create show description button:
                var btnShow = document.createElement("a");
                $(btnShow)
                        .addClass("btn-small btn-dark btn-space-r")
                        .html('<span>' + IG.label.get("infoobject_rss_item_show") + '</span>')
                        .click(function() {
                            var objSpan = $(this).children("span:first").get(0);
                            var imgsDiv = $(this).find("div.rss-images").get(0);
                            if ($(objDesc).css("display") != "block") {
                                $(objDesc).css("display", "block");
                                $(objSpan).text(IG.label.get("infoobject_rss_item_hide"));

                                $(imgsDiv).css("display", "block");
                            }
                            else {
                                $(objDesc).css("display", "none");
                                $(objSpan).text(IG.label.get("infoobject_rss_item_show"));

                                $(imgsDiv).css("display", "none");
                            }

                            return false;
                        })
                        .appendTo(objDivButtons);

                //Create import button:
                var btnImport = document.createElement("a");
                $(btnImport)
                        .addClass("btn-small btn-dark")
                        .html('<span>' + IG.label.get("infoobject_rss_item_import") + '</span>')
                        .click(function() {
                            fnImportRSS(n);
                            return false;
                        })
                        .appendTo(objDivButtons);

                //Finally append new item:
                $(objDiv).appendTo(objDivSearchResults);

            });
        };

        //Get RSS feed:
        var fnGetRSSFeed = function() {
            var objInput = $("#rss-address").get(0);
            var url = $(objInput).val();

            if (IG.fn.isValidUrl(url)) {
                //sample urls :
                //http://feeds.bbci.co.uk/news/world/rss.xml
                //http://rss.cnn.com/rss/edition.rss


                var idLoading = "rss-loading";
                var objDivSearchResults = $("#rss-search-results").get(0);
                $(objDivSearchResults).empty();

                IG.gui.loading.show(objDivSearchResults, idLoading, 1);

                IG.request({
                    method: "getRssFeed",
                    params: [IG.config.language, url],
                    success: function(response) {
                        IG.gui.loading.hide(idLoading);

                        if (response.result) {
                            fnParseRSS(response.data);
                        }
                        else {
                            fnRSSerror(response.message);
                        }
                    },
                    error: function(err) {
                        IG.gui.loading.hide(idLoading);
                    }
                });

            }
            else {
                fnRSSerror(IG.label.get("infoobject_rss_invalid_url"));
            }
        };

        //Attach event handlers:
        $(objInput).keyup(function(e) {
            var key = e.which ? e.which : e.keyCode;
            if (key == 13) {
                fnGetRSSFeed();
            }
        });

        $(btnGet).click(function() {
            fnGetRSSFeed();
            return false;
        });



        return objDiv;
    };

    /* Renders RSS box: */
    this.renderRSS = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
	            .attr("id", "infoobject-rss")
	            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_rss_feed"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
                .addClass("ig-edit-section-content")
                .appendTo(objDiv);

        if (bWrite) {
            var btnImport = document.createElement("a");
            $(btnImport)
                    .addClass("btn-normal btn-dark")
                    .html('<span>' + IG.label.get("infoobject_rss_importfrom") + '</span>')
                    .click(function() {

                        $( "#modal-window" )
                            .html(_this.buildRSSDialogContent())
                            .dialog({
                                title: IG.label.get("infoobject_rss_download_from"),
                                modal: true,
                                position: { my: "center", at: "center", of: window },
                                width: 800,
                                height: 'auto',
                                buttons: null
                            });

                        return false;
                    })
                    .appendTo(objDivContent);
        }

        return objDiv;
    };





    //Display template selection dialog:
    this.selectTemplate = function() {


        $( "#modal-window" )
            .html(_this.buildTemplateDialogContent())
            .dialog({
                title: '',
                modal: true,
                position: { my: "center", at: "center", of: window },
                width: 724,
                height: 556,
                buttons: null
            });
    };

    //Builds thumbnail for currently select template:
    this.buildTemplateThumbnail = function(myTemplate) {
        var bAdmin = IG.userHasPermission("OP_INFO_OBJECT_ADMIN") || (_this.item.editor.id == IG.userConfig.userId.id);
        var bWrite = IG.userHasPermission("OP_INFO_OBJECT_WRITE", _this.item) || bAdmin;

        var objDiv = document.createElement("div");
        $(objDiv).attr("id", "template-thumbnail-block");

        var objImg = document.createElement("img");
        $(objImg)
                .attr({
                    "id": "template-thumbnail",
                    "src": myTemplate.thumbnailUrl,
                    "alt": myTemplate.name
                })
                .appendTo(objDiv);

        if (bWrite) {
            $(objImg)
                    .click(function() {
                        _this.selectTemplate();
                    })
        }

        var objDivTemplate = document.createElement("div");
        $(objDivTemplate)
                .attr("id", "template-name")
                .text(myTemplate.name)
                .appendTo(objDiv);

        return objDiv
    };

    //Builds content for template selection dialog:
    this.buildTemplateDialogContent = function() {
        var objDiv = document.createElement("div");
        $(objDiv).addClass("template");

        //Close icon:
        var objSpanClose = document.createElement("span");
        $(objSpanClose)
                .addClass("icon-close")
                .click(function() {
                    IG.closeModalDialog();
                })
                .appendTo(objDiv);

        //Dialog headline:
        var objH2 = document.createElement("h2");
        $(objH2)
                .text(IG.label.get("edit_infoobject_choose_template"))
                .appendTo(objDiv);


        //Create container for template items:
        var objDivTemplates = document.createElement("div");
        $(objDivTemplates)
                .attr("id", "template-list")
                .addClass("list clearfix")
                .appendTo(objDiv);


        //Handle click on a template (ie. selecting a template):
        var fnClickTemplate = function(t) {
            if (t != null) {
                _this.fn.setTemplateId(t);

                var objThumbnail = $("#template-thumbnail-block").get(0);
                if (objThumbnail != null) {
                    $(objThumbnail).replaceWith(_this.buildTemplateThumbnail(t));
                }
                else {
                    $("#infoobject-template .ig-edit-section-content").prepend(_this.buildTemplateThumbnail(t));
                }

                IG.closeModalDialog();
            }
        };

        $.each(IG.lists.templates, function(i, n) {
            var objDivItem = document.createElement("div");
            $(objDivItem)
                    .click(function() {
                        fnClickTemplate(n);
                    })
                    .addClass("item")
                    .appendTo(objDivTemplates);

            var objDivImg = document.createElement("div");
            $(objDivImg)
                    .addClass("thumbnail")
                    .appendTo(objDivItem);

            var objImg = document.createElement("img");
            $(objImg)
                    .attr({
                        "src": n.thumbnailUrl,
                        "alt": n.name
                    })
                    .appendTo(objDivImg);

            var objDivTitle = document.createElement("div");
            $(objDivTitle)
                    .addClass("title")
                    .text(n.name)
                    .appendTo(objDivItem);
        });


        return objDiv;
    };

    //Renders templates box:
    this.renderTemplate = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
	            .attr("id", "infoobject-template")
	            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_template"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
                .addClass("ig-edit-section-content")
                .appendTo(objDiv);

        //Build thumbnail and text for currently selected template:
        var myTemplate = null;
        if (_this.item.templateId != null) {
            myTemplate = IG.getTemplateById(_this.item.templateId.id);
        }
        if (myTemplate != null) {
            $(objDivContent).append(_this.buildTemplateThumbnail(myTemplate));
        }

        if (bWrite) {
            var objDivButtons = document.createElement("div");
            $(objDivButtons)
                    .addClass("buttons")
                    .appendTo(objDivContent);

            //Build select template button:
            var btnSelect = document.createElement("a");
            $(btnSelect)
                    .addClass("btn-normal btn-dark")
                    .html('<span>' + IG.label.get("edit_infoobject_select_template") + '</span>')
                    .click(function() {
                        _this.selectTemplate();
                        return false;
                    })
                    .appendTo(objDivButtons);
        }

        return objDiv;
    };





    //Renders currently selected content source given id:
    this.renderContentSource = function(id) {
        var n = IG.getContentSourceById(id);
        if (n != null) {
            var objDiv = document.createElement("div");
            $(objDiv).addClass("item");

            var objDivTitle = document.createElement("div");
            $(objDivTitle)
                .addClass("title")
                .text(n.organisation)
                .appendTo(objDiv);

            var objDivImg = document.createElement("div");
            $(objDivImg)
                    .addClass("img")
                    .appendTo(objDiv);

            var url = "images/blank.gif";
            if (typeof n.logoThumbnailUrl == "string") {
                if (n.logoThumbnailUrl != "") {
                    url = n.logoThumbnailUrl;
                }
            }

            var objImg = document.createElement("img");
            $(objImg)
                    .attr({
                        "src": url,
                        "width": "207"
                    })
                    .appendTo(objDivImg);

            return objDiv;
        }
        return null;
    };

    //Builds content sources / logo box
    this.renderContentSources = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "infoobject-contentsources")
            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_logo"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content")
            .appendTo(objDiv);

        if (typeof _this.item.source != "undefined" && _this.item.source !== null) {
            if (typeof _this.item.source.id != "undefined") {
                $(objDivContent).append(_this.renderContentSource(_this.item.source));
            }
        }


        if (bWrite) {
            var objDivButtons = document.createElement("div");
            $(objDivButtons)
                .addClass("buttons")
                .appendTo(objDivContent);


            //Builds content sources dialog content:
            var fnBuildContentSourcesDialogContent = function() {
                var objDiv = document.createElement("div");
                $(objDiv).addClass("contentsource");

                //Close icon:
                var objSpanClose = document.createElement("span");
                $(objSpanClose)
                    .addClass("icon-close")
                    .click(function() {
                        IG.closeModalDialog();
                    })
                    .appendTo(objDiv);


                //Create container for content source items:
                var objDivContentSources = document.createElement("div");
                $(objDivContentSources)
                    .attr("id", "contentsource-list")
                    .addClass("list clearfix")
                    .appendTo(objDiv);

                var fnClickContentSource = function(nId) {
                    var id = IG.getId(nId);
                    if (id != "") {
                        _this.fn.setContentSource(nId);

                        var objDiv = $("#infoobject-contentsources .ig-edit-section-content").get(0);
                        if (objDiv) {
                            $("#btn-normal-remove-content-source").removeClass("hidden");
                            $(objDiv).find("div.item").remove();
                            $(objDiv).prepend(_this.renderContentSource(id));
                        }
                    }
                    IG.closeModalDialog();
                };

                $.each(IG.lists.contentsources, function(i, n) {
                    var objDivItem = document.createElement("div");
                    $(objDivItem)
                        .addClass("item")
                        .click(function() {
                            fnClickContentSource(n.id);
                        })
                        .appendTo(objDivContentSources);

                    var objDivTitle = document.createElement("div");
                    $(objDivTitle)
                        .addClass("title")
                        .text(n.organisation)
                        .appendTo(objDivItem);


                    var objDivImg = document.createElement("div");
                    $(objDivImg)
                        .addClass("img")
                        .appendTo(objDivItem);

                    var url = "images/blank.gif";
                    if (typeof n.logoThumbnailUrl == "string") {
                        if (n.logoThumbnailUrl != "") {
                            url = n.logoThumbnailUrl;
                        }
                    }

                    var objImg = document.createElement("img");
                    $(objImg)
                        .attr({
                            "src": url,
                            "width": "207"
                        })
                        .appendTo(objDivImg);
                });
                return objDiv;
            };

            var btnRemove = document.createElement("a");
            $(btnRemove)
                .addClass("btn-normal btn-dark btn-space-r" + (IG.getId(_this.item.source) == "" ? " hidden" : ""))
                .attr("id", "btn-normal-remove-content-source")
                .html('<span>' + IG.label.get("global_remove") + '</span>')
                .click(function() {
                    _this.fn.removeContentSource();
                    $(objDiv).find("div.item").remove();
                    $(this).addClass("hidden");
                    return false;
                })
                .appendTo(objDivButtons);


            var btnChange = document.createElement("a");
            $(btnChange)
                .addClass("btn-normal btn-dark")
                .html('<span>' + IG.label.get("global_change") + '</span>')
                .click(function() {

                    $( "#modal-window" )
                        .html(fnBuildContentSourcesDialogContent())
                        .dialog({
                            title: IG.label.get("edit_infoobject_logo"),
                            modal: true,
                            position: { my: "center", at: "center", of: window },
                            width: 724,
                            height: 556,
                            buttons: null
                        });

                    return false;
                })
                .appendTo(objDivButtons);
        }


        return objDiv;
    };


    //Builds exposure box:
    this.renderExposure = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
	            .attr("id", "infoobject-exposure")
	            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_exposure_title"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
                .addClass("ig-edit-section-content")
                .appendTo(objDiv);

        var objLabel = document.createElement("label");
        $(objLabel)
                .attr("for", "infoobject-exposure-seconds")
                .text(IG.label.get("edit_infoobject_exposure_time"))
                .appendTo(objDivContent);

        var objInput = document.createElement("input");
        $(objInput)
                .attr({
                    "type": "text",
                    "name": "exposure",
                    "id": "infoobject-exposure-seconds"
                })
                .val(_this.item.exposureDuration)
                .appendTo(objDivContent)
                .wrap('<span class="input"></span>');


        if (!bWrite) {
            $(objInput).attr("readonly", "readonly");
        }
        else {
            $(objInput)
                    .blur(function() {
                        //Update exposureDuration property:
                        var v = $(this).val();
                        $(this).val(_this.fn.setExposureDuration(v));
                    })
        }


        var objLegend = document.createElement("span");
        $(objLegend)
                .addClass("legend")
                .text(IG.label.get("edit_infoobject_exposure_seconds")) //FIXME: wrong translation
                .appendTo(objDivContent);

        return objDiv;
    };



    //Renders metadata box:
    this.renderMetadata = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
	            .attr("id", "infoobject-metadata")
	            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("infoobject_metadata"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
                .addClass("ig-edit-section-content")
                .appendTo(objDiv);

        var objInputKey = null;
        var objInputValue = null;
        var objDivButtons = null;

        if (bWrite) {

            //Build form for creating new metadata item:
            var s = ''
                    + '<table class="table-two-cols">'
                    + '<tbody>'
                    + '<tr>'
                    + '<td class="key">'
                    + '<label for="infoobject-medadata-key">' + IG.label.get("infoobject_metadata_key") + ':</label>'
                    + '<span class="input"><input type="text" name="key" id="infoobject-metadata-key" value="" /></span>'
                    + '</td>'
                    + '<td class="value">'
                    + '<label for="infoobject-metadata-value">' + IG.label.get("infoobject_metadata_value") + ':</label>'
                    + '<span class="input"><input type="text" name="value" id="infoobject-metadata-value" value="" /></span>'
                    + '</td></tr>'
                    + '</tbody>'
                    + '</table>';


            $(objDivContent).append(s);

            objInputKey = $(objDivContent).find("#infoobject-metadata-key").get(0);
            objInputValue = $(objDivContent).find("#infoobject-metadata-value").get(0);


            objDivButtons = document.createElement("div");
            $(objDivButtons)
                .addClass("buttons")
                .appendTo(objDivContent);
        }


        //Create list of existing items:
        var objDivItems = document.createElement("div");
        $(objDivItems)
                .addClass("items")
                .appendTo(objDivContent);

        //Local function for creating row for metadata item:
        var fnAddMetadata = function(key, value) {
            var objTable = $(objDivItems).children("table:first").get(0);
            if (objTable == null) {
                objTable = document.createElement("table");
                $(objTable)
                    .appendTo(objDivItems);
            }

            var fnRefreshMetadata = function() {
                _this.fn.clearMetadata();
                $(objTable).find("tr").each(function(index) {
                    var key = $(this).find("input.meta-key-input").val();
                    var value = $(this).find("input.meta-value-input").val();
                    if (key == "" || value == "") {
                        $(this).remove();
                    } else {
                        _this.fn.addMetadata(key, value);
                    }
                });
            };

            var objTR = document.createElement("tr");
            $(objTR)
                .appendTo(objTable);

            var objTDkey = document.createElement("td");
            $(objTDkey)
                .attr("title", key)
                .addClass("key-column")
                .appendTo(objTR);

            if (!bWrite) {
                $(objTDkey)
                    .addClass("ellipsis")
                    .text(key);
            } else {
                var objTDkeyInput = document.createElement("input");
                $(objTDkeyInput)
                    .attr("type", "text")
                    .addClass("meta-key-input input-text-small")
                    .val(key)
                    .change(function() {
                        fnRefreshMetadata();
                    })
                    .appendTo(objTDkey);
            }

            var objTDvalue = document.createElement("td");
            $(objTDvalue)
                .attr("title", value)
                .addClass("value-column")
                .appendTo(objTR);

            if (!bWrite) {
                $(objTDvalue)
                    .addClass("ellipsis")
                    .text(value);
            } else {
                var objTDValueInput = document.createElement("input");
                $(objTDValueInput)
                    .attr("type", "text")
                    .addClass("meta-value-input input-text-small")
                    .val(value)
                    .change(function() {
                        fnRefreshMetadata();
                    })
                    .appendTo(objTDvalue);
            }

            var objTDdel = document.createElement("td");
            $(objTDdel)
                .addClass("delete")
                .appendTo(objTR);

            var btnDel = document.createElement("a");
            $(btnDel)
                .addClass("btn-small btn-dark")
                .html('<span>' + IG.label.get("global_remove") + '</span>')
                .click(function() {
                    _this.fn.removeMetadata(key);
                    $(objTR).remove();
                    return false;
                })
                .appendTo(objTDdel);
        };


        if (bWrite) {
            //Create "Add" button:
            var btnAdd = document.createElement("a");
            $(btnAdd)
                .addClass("btn-small btn-dark btn-space-r")
                .addClass("ig-infoobject-add")
                .html('<span>' + IG.label.get("global_add") + '</span>')
                .click(function() {
                    var k = $(objInputKey).val();
                    var v = $(objInputValue).val();

                    if (_this.fn.addMetadata(k, v)) {
                        fnAddMetadata(k, v);
                    }

                    return false;
                })
                .appendTo(objDivButtons);

            //create show bigger fields button
            var btnShowBigger = document.createElement("a");
            $(btnShowBigger)
                .addClass("btn-small btn-dark")
                .addClass("ig-infoobject-bigger-fields")
                .html('<span>' + IG.label.get("infoobject_show_bigger_fields") + '</span>')
                .click(function(event) {
                    event.preventDefault();

                    $( "#modal-window" )
                        .html(_this.renderMetadataForDialog(bWrite))
                        .dialog({
                            modal: false,
                            title: '',
                            position: { my: "center", at: "center", of: window },
                            width: 'auto',
                            height: 'auto',
                            buttons: null,
                            closeOnEscape: true,

                            // reset to defaults
                            minWidth: 150,
                            maxWidth: false,
                            minHeight: 280,
                            maxHeight: false
                        });
                })
                .appendTo(objDivButtons);


            //Setup eventhandlers for input fields:
            $(objInputKey)
                    .keyup(function(e) {
                        var key = e.which ? e.which : e.keyCode;
                        if (key == 13) {
                            $(objInputValue).focus();
                        }
                    });

            $(objInputValue)
                    .keyup(function(e) {
                        var key = e.which ? e.which : e.keyCode;
                        if (key == 13) {
                            $(btnAdd).click();
                        }
                    });
        }


        //Append existing metatags to HTML:
        $.each(_this.item.metadata, function(i, n) {
            fnAddMetadata(i, n);
        });


        return objDiv;
    };

    this.renderMetadataForDialog = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("metadata-dialog");

        if (bWrite) {
            //form for adding new metadata fields
            var s = ''
                    + '<table class="inputs">'
                    + '<tbody>'
                    + '<tr>'
                    + '<td class="key">'
                    + '<label for="infoobject-medadata-key">' + IG.label.get("infoobject_metadata_key") + ':</label>'
                    + '<span class="input"><input type="text" class="input-text" name="key" id="infoobject-metadata-key" value="" /></span>'
                    + '</td>'
                    + '<td class="value">'
                    + '<label for="infoobject-metadata-value">' + IG.label.get("infoobject_metadata_value") + ':</label>'
                    + '<span class="input"><input type="text" class="input-text" name="value" id="infoobject-metadata-value" value="" /></span>'
                    + '</td></tr>'
                    + '</tbody>'
                    + '</table>';


            $(objDiv).append(s);

            var objInputKey = $(objDiv).find("#infoobject-metadata-key").get(0);
            var objInputValue = $(objDiv).find("#infoobject-metadata-value").get(0);

            var objDivButtons = document.createElement("div");
            $(objDivButtons)
                    .addClass("buttons")
                    .appendTo(objDiv);

            //Create list of existing items:
            var objDivItems = document.createElement("div");
            $(objDivItems)
                .addClass("items")
                .appendTo(objDiv);


            //Local function for creating row for metadata item:
            var fnAddMetadata = function(container, key, value) {
                var objTable = $(container).children("table:first").get(0);
                if (objTable == null) {
                    objTable = document.createElement("table");
                    $(objTable)
                        .appendTo(container);
                }

                var fnRefreshMetadata = function() {
                    _this.fn.clearMetadata();

                    var metaItems = $("#infoobject-metadata .items");
                    $(metaItems).find("table").empty();

                    $(objTable).find("tr").each(function(index) {
                        var key = $(this).find("input.meta-key-input").val();
                        var value = $(this).find("input.meta-value-input").val();
                        if (key == "" || value == "") {
                            $(this).remove();
                        } else {
                            _this.fn.addMetadata(key, value);
                            fnAddMetadata($(metaItems), key, value);
                        }
                    });
                };

                var objTR = document.createElement("tr");
                $(objTR)
                    .appendTo(objTable);

                var objTDkey = document.createElement("td");
                $(objTDkey)
                    .attr("title", key)
                    .addClass("key-column")
                    .appendTo(objTR);

                if (!bWrite) {
                    $(objTDkey)
                        .addClass("ellipsis")
                        .text(key);
                } else {
                    var objTDkeyInput = document.createElement("input");
                    $(objTDkeyInput)
                        .attr("type", "text")
                        .addClass("meta-key-input input-text-small")
                        .val(key)
                        .change(function() {
                            fnRefreshMetadata();
                        })
                        .appendTo(objTDkey);
                }

                var objTDvalue = document.createElement("td");
                $(objTDvalue)
                    .attr("title", value)
                    .addClass("value-column")
                    .appendTo(objTR);

                if (!bWrite) {
                    $(objTDvalue)
                        .addClass("ellipsis")
                        .text(value);
                } else {
                    var objTDValueInput = document.createElement("input");
                    $(objTDValueInput)
                        .attr("type", "text")
                        .addClass("meta-value-input input-text-small")
                        .val(value)
                        .change(function() {
                            fnRefreshMetadata();
                        })
                        .appendTo(objTDvalue);
                }

                var objTDdel = document.createElement("td");
                $(objTDdel)
                    .addClass("delete")
                    .appendTo(objTR);

                var btnDel = document.createElement("a");
                $(btnDel)
                    .addClass("btn-small btn-dark")
                    .html('<span>' + IG.label.get("global_remove") + '</span>')
                    .click(function() {
                        _this.fn.removeMetadata(key);
                        $("#infoobject-metadata .items table").find("tr").each(function(i) {
                            var keyInput = $(this).find("input.meta-key-input");
                            if (keyInput.val() == key) {
                                $(this).remove();
                            }
                        });
                        $(objTR).remove();
                        return false;
                    })
                    .appendTo(objTDdel);
            };

            var btnAdd = document.createElement("a");
            $(btnAdd)
                .addClass("btn-normal btn-dark")
                .addClass("ig-infoobject-add")
                .html('<span>' + IG.label.get("global_add") + '</span>')
                .css({
                	"margin-top" : "16px",
                	"margin-bottom" : "16px"
                })
                .click(function() {
                    var k = $(objInputKey).val();
                    var v = $(objInputValue).val();

                    if (_this.fn.addMetadata(k, v)) {
                        fnAddMetadata(objDivItems, k, v);
                        fnAddMetadata($("#infoobject-metadata .items"), k, v);
                    }

                    return false;
                })
                .appendTo(objDivButtons);

            /* TODO close button meta tag popup
             *
             * var btnClose = document.createElement("a");
            $(btnClose)
            	.addClass("btn-normal btn-dark")
            	.html('<span>' + IG.label.get("ok") + '</span>')
            	.click(function(event) {

            		return false;
            	})
            	.appendTo(objDivButtons);
			*/

            //Setup eventhandlers for input fields:
            $(objInputKey)
                    .keyup(function(e) {
                        var key = e.which ? e.which : e.keyCode;
                        if (key == 13) {
                            $(objInputValue).focus();
                        }
                    });

            $(objInputValue)
                    .keyup(function(e) {
                        var key = e.which ? e.which : e.keyCode;
                        if (key == 13) {
                            $(btnAdd).click();
                        }
                    });

            //Append existing metatags to HTML:
            $.each(_this.item.metadata, function(i, n) {
                fnAddMetadata(objDivItems, i, n);
            });
        }

        return $(objDiv);
    };


    //Renders categories box:
    this.renderCategories = function(bWrite) {
        var bAdmin = IG.userHasPermission("OP_INFO_OBJECT_ADMIN") || (_this.item.editor.id == IG.userConfig.userId.id);
        var bWrite = IG.userHasPermission("OP_INFO_OBJECT_WRITE", _this.item) || bAdmin;

        var objDiv = document.createElement("div");
        $(objDiv)
	            .attr("id", "infoobject-categories")
	            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_categories"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
            .addClass("ig-edit-section-content")
            .appendTo(objDiv);


        //Create container for selected categories:
        var objDivList = document.createElement("div");
        $(objDivList).addClass("list");


        var objItems = null;
        var objRoot = null;

        if (bWrite) {
            //Create tree-strcuture for existing categories:
            objItems = document.createElement("ul");
            objRoot = document.createElement("div");
            $(objRoot)
                    .addClass("category-lock")
                    .text(IG.label.get("edit_infoobject_choose_category"))
                    .append('<span class="closed"></span>')
                    .click(function() {
                        if ($(this).attr('class') == 'category-lock opened') {
                            $(this).removeClass('opened');
                            $(objItems).hide();
                            $(this).find(".opened").addClass('closed');
                            $(this).find(".opened").removeClass('opened');
                        }
                        else {
                            $(this).addClass('opened');
                            $(objItems).show();
                            $(this).find(".closed").addClass('opened');
                            $(this).find(".closed").removeClass('closed');
                        }
                    });
        }

        //Adds category to HTML:
        var fnAddCategory = function(n, path) {
            if (typeof path !== 'undefined' && path.length > 0) {
                path += ' | ';
            } else {
                path='';
            }

            var objTable = $(objDivList).children("table:first").get(0);
            if (objTable == null) {
                objTable = document.createElement("table");
                $(objTable).appendTo(objDivList);
            }

            //Create category row:
            var objTR = document.createElement("tr");
            $(objTR)
                    .attr("title", n.name[IG.config.language])
                    .appendTo(objTable);

            //Create category title cell:
            var objTDtitle = document.createElement("td");
            $(objTDtitle)
                .addClass("ellipsis")
                .attr('title', path + n.name[IG.config.language])
                .text(n.name[IG.config.language])
                .appendTo(objTR);


            if (bWrite) {
                //Create category button cell:
                var objTDdelete = document.createElement("td");
                $(objTDdelete)
                        .addClass("buttons")
                        .appendTo(objTR);

                //Create remove button:
                var btnRemove = document.createElement("a");
                $(btnRemove)
                        .addClass("btn-small btn-dark")
                        .html('<span>' + IG.label.get("edit_infoobject_remove_category") + '</span>')
                        .click(function() {
                            _this.fn.removeCategory(n);
                            $(objTR).remove();

                            //Rebuild the category selector
                            fnBuildCategorySelector();

                            return false;
                        })
                        .appendTo(objTDdelete);
            }
        };


        //Handle clicks on a category in structure
        if (bWrite) {
            var fnClickCategory = function(c, path) {
                var bFound = false;
                $.each(_this.item.categoryIds, function(i, n) {
                    if (n.id == c.id['id']) {
                        bFound = true;
                        return false;
                    }
                });
                if (!bFound) {
                    //Add HTML:
                    fnAddCategory(c, path);

                    //Add to list:
                    _this.fn.addCategory(c);

                    //Close tree structure:
                    $(objRoot).trigger("click");

                    //Rebuild the category selector
                    fnBuildCategorySelector();
                }
            };

            //Build subcategory structure:
            var fnBuildSubCategory = function(item, pathName) {

                var res = null;
                if (item.childCategories.length > 0) {

                    res = document.createElement("ul");
                    $.each(item.childCategories, function(i, n) {

                        var objLi = document.createElement("li");
                        $(objLi).appendTo(res);

                        var objA = document.createElement("a");
                        $(objA)
                            .text(IG.fn.truncate(n.name[IG.config.language], 15))
                            .click(function() {
                                fnClickCategory(n, pathName);
                                return false;
                            })
                            .appendTo(objLi);

                        var subItems = fnBuildSubCategory(n, pathName + ' | ' + n.name[IG.config.language]);
                        if (subItems != null) {
                            $(objLi)
                                .addClass("parent")
                                .append(subItems);
                        }
                    });
                }
                return res;
            };

            var fnBuildCategorySelector = function() {
                $(objItems).html(''); // clear

                //Populate tree structure:
                $.each(IG.lists.categories, function(i, n) {
                    var bFound = false;
                    $.each(_this.item.categoryIds, function(i, c) {
                        if (n.id['id'] == c.id && n.childCategories.length == 0) {
                            bFound = true;
                            return false;
                        }
                    });
                    if (!bFound) {
                        if (typeof n.name[IG.config.language] != "undefined") {
                            var objLI = document.createElement("li");
                            $(objLI).appendTo(objItems);

                            var text = IG.fn.truncate(n.name[IG.config.language], 18);
                            var objA = document.createElement("a");
                            $(objA)
                                .text(text)
                                .attr("title", n.name[IG.config.language])
                                .click(function() {
                                    fnClickCategory(n, '');
                                    return false;
                                })
                                .appendTo(objLI);

                            var subItems = fnBuildSubCategory(n, n.name[IG.config.language]);
                            if (subItems != null) {
                                $(objLI)
                                    .addClass("parent")
                                    .append(subItems);
                            }
                        }
                    }

                });
            };

            fnBuildCategorySelector();
        }

        var fnRecursiveCatPath = function(childCat, nodeCatId) {
            var bFound = false,
                result = '',
                ret = '';
            if (childCat.id.id !== nodeCatId) {
                if (childCat.childCategories.length > 0) {
                    $.each(childCat.childCategories, function(i, n) {
                        ret = '';
                        if (nodeCatId === n.id.id) {
                            bFound = true;
                            result = ' | ' + childCat.name[IG.config.language];
                            return false;
                        }
                        if (!bFound) {
                            ret = fnRecursiveCatPath(n, nodeCatId);
                            if (ret.length > 0) {
                                bFound = true;
                                result = ' | ' + childCat.name[IG.config.language] + ret;
                            } else {
                                result = '';
                            }
                        }
                    });
                }
            }
            return result;
        };

        var fnBuildCategoryPath = function(cat) {
            var result = '';
            var bFound = false;

            $.each(IG.lists.categories, function(i, n) {
                if (cat.id === n.id.id) {
                    result = '';
                    bFound = true;
                    return false;
                }
                if (!bFound) {
                    if (n.childCategories.length > 0) {
                        result = n.name[IG.config.language];
                        $.each(n.childCategories, function(i, n) {
                            result += fnRecursiveCatPath(n, cat.id);
                        });
                    }
                }
            });
            return result;
        };


        //Populate list with already selected categories:
        $.each(_this.item.categoryIds, function(i, n) {
            var path = fnBuildCategoryPath(n);
            var c = IG.getCategoryById(n);
            if (c != null) {
                fnAddCategory(c, path);
            } else {
                _this.fn.removeCategory(n);
            }
        });


        if (bWrite) {
            $(objRoot).appendTo(objDivContent);
            $(objItems).appendTo(objDivContent);
        }

        $(objDivList).appendTo(objDivContent);



        return objDiv;
    };


    //Renders tags box:
    this.renderTags = function(bWrite) {
        var objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "infoobject-tags")
            .addClass("ig-edit-section collapsable");

        var objH4 = document.createElement("h4");
        $(objH4)
            .text(IG.label.get("edit_infoobject_tags_title"))
            .append('<span class="collapse-arrow"></span>')
            .appendTo(objDiv);

        var objDivContent = document.createElement("div");
        $(objDivContent)
                .addClass("ig-edit-section-content")
                .appendTo(objDiv);

        var objDivItems = document.createElement("div");
        $(objDivItems).addClass("items");

        //Adds tag to HTML:
        var fnAddTag = function(s) {
            var objSpan = document.createElement("span");
            $(objSpan)
                .text(IG.fn.truncate(s, 30))
                .attr('title', s)
                .qtip({
                    content: {
                        text: s,
                        title: false,
                        button: false
                    },
                    position: {
                        my: 'top middle',
                        at: 'bottom middle'
                    },
                    style: {
                        tip: {
                            width: 10,
                            height: 6
                        },
                        classes: 'qtip-title',
                        def: false
                    }
                });

            if (bWrite) {
                var objDel = document.createElement("span");
                $(objDel)
                    .click(function() {
                        _this.fn.removeTag(s);
                        $(objSpan).remove();
                    })
                    .appendTo(objSpan);
            }

            $(objSpan).appendTo(objDivItems);
        };


        //Validate value - add if ok
        var fnDoAddTag = function(s) {
            if (_this.fn.addTag(s)) {
                fnAddTag(s);
            }
        };


        if (bWrite) {
            var objLabel = document.createElement("label");
            $(objLabel)
                    .attr("for", "infoobject-tag-text")
                    .text(IG.label.get("infoobject_tag"))
                    .appendTo(objDivContent);


            var objInput = document.createElement("input");
            $(objInput)
                .attr({
                    "type": "text",
                    "name": "tag",
                    "id": "infoobject-tag-text"
                })
                .keyup(function(e) {
                    var v = $(this).val();
                    var key = e.which ? e.which : e.keyCode;
                    //Enter:
                    if (key == 13) {
                        fnDoAddTag(v);
                    }
                })
                .appendTo(objDivContent)
                .wrap('<span class="input" style="margin-bottom: 10px;"></span>');

            var btnAdd = document.createElement("a");
            $(btnAdd)
                    .addClass("btn-normal btn-dark")
                    .html('<span>' + IG.label.get("edit_infoobject_add_tag") + '</span>')
                    .click(function() {
                        fnDoAddTag($(objInput).val());
                    })
                    .appendTo(objDivContent);
        }

        $.each(_this.item.tags, function(i, n) {
            fnAddTag(n);
        });

        $(objDivItems).appendTo(objDivContent);

        return objDiv;
    };





    //Renders buttons box:
    //FIXME: check permissions
    this.renderButtons = function(bWrite, bCreate, bDelete) {
        var fnBuildDeleteDialogContent = function() {
            var objDiv = document.createElement("div");
            $(objDiv).addClass("delete");

            var objP = document.createElement("p");
            $(objP)
                .text(IG.label.get("infoobject_delete"))
                .appendTo(objDiv);

            var objDivButtons = document.createElement("div");
            $(objDivButtons)
                .addClass("buttons")
                .appendTo(objDiv);


            var btnConfirm = document.createElement("a");
            $(btnConfirm)
                .addClass("btn-normal btn-dark")
                .html('<span>' + IG.label.get("ok") + '</span>')
                .click(function() {
                    IG.remove.infoobject({
                        id: _this.item.id,
                        parent: _this.fn.isParent(),
                        success: function() {
                            IG.closeModalDialog();
                            IG.showInfoObjectList({ history: true });
                        },
                        error: function(err) {
                            IG.showErrorDialog(err);
                        }
                    });
                    return false;
                })
                .appendTo(objDivButtons);

            var btnCancel = document.createElement("a");
            $(btnCancel)
                .addClass("btn-normal btn-dark")
                .html('<span>' + IG.label.get("cancel") + '</span>')
                .click(function() {
                    IG.closeModalDialog();
                    return false;
                })
                .appendTo(objDivButtons);


            return objDiv;
        };



        if (bWrite || bCreate) {
            var objDiv = document.createElement("div");

            $(objDiv)
                .attr("id", "infoobject-buttons")
                .addClass("buttons");

            if (bDelete) {

                var btnDelete = document.createElement("a");
                $(btnDelete)
	                    .addClass("btn-normal btn-light btn-full-width")
	                    .html('<span>' + IG.label.get("infoobject_delete2") + '</span>')
	                    .click(function() {
	                    	if (_this.isNew) {
	                    		// show the parent document if it's an unsaved child
	                    		IG.showEditInfoObject({
	                                id: _this.getTopInfoObjectId().id,
	                                history: true,
	                                searchLang: _this.searchLang
	                            });
	                    	} else {

                                var confDialog = IG.createConfirmDialog({
                                    title: "",
                                    text: IG.label.get("infoobject_delete"),
                                    onConfirm: function() {
                                        IG.remove.infoobject({
                                            id: _this.item.id,
                                            parent: _this.fn.isParent(),
                                            success: function() {
                                                confDialog.close();
                                                IG.showInfoObjectList({ history: true });
                                            },
                                            error: function(err) {
                                                confDialog.close();
                                                IG.showErrorDialog(err);
                                            }
                                        });
                                    }
                                });
	                    	}

	                        return false;
	                    })
	                    .appendTo(objDiv);
            }

            if (bCreate) {
                var btnCopy = document.createElement("a");
                $(btnCopy)
	                    .addClass("btn-normal btn-light btn-full-width")
	                    .html('<span>' + IG.label.get("infoobject_copy") + '</span>')
	                    .click(function() {
	                        _this.updateInfoObject({ duplicate: true });
	                        return false;
	                    })
	                    .appendTo(objDiv);
            }

        }

        return objDiv;
    };



    //Renders the language selector block:
    this.renderLanguageSelector = function() {
        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("ig-languages")
            .attr("id", "ig-infoobject-languages");


        //Toggle language (ie. visible headline, subheadline and body text fields):
        var fnToggleLanguage = function(key) {
            _this.elementsLang = key;
            _this.searchLang = key;

            IG.EditorController.setLanguage(key);
            if (_this.infoObjectFields !== null) {
                _this.infoObjectFields.setLanguage(key);
            }

            //Toggle headlines:
            var arrLangDep = $("#ig-infoobject-left-column div.language-dependent");
            if (arrLangDep.length > 0) {
                $.each(arrLangDep, function(i, n) {
                    if ($(n).hasClass(key)) {
                        $(n).css("display", "block");
                    }
                    else {
                        $(n).css("display", "none");
                    }
                });
            }
        };

        //Set click event for each language:
        if (IG.fn.objSize(IG.customerConfig.contentLanguages) > 1) {
            $.each(IG.customerConfig.contentLanguages, function(key, name) {
                var objSpan = document.createElement("span");
                $(objSpan)
	                .addClass("ig-language " + key)
	                .attr("title", name)
                    .text(key)
	                .click(function() {
	                    fnToggleLanguage(key);
	                    $(this).addClass("current");
	                    $(this).siblings("span").removeClass("current");
	                })
	                .appendTo(objDiv);

                if (key == _this.elementsLang) {
                    $(objSpan).addClass("current");
                }

                $(objDiv).append('<span class="list-pipe">|</span>');
            });
            $(objDiv).children('.list-pipe').last().remove();
        }

        return objDiv;
    };

    //Renders InfoObject type selector:
    this.renderTypeSelector = function() {
        //FIXME: check for supported functionality!

        var types = [
			    {
			        caption: IG.label.get("infoobject_add_standard_side"),
			        typeName: "standard",
			        key: "infoobjects",
			        enabled: false
			    },
			    {
			        caption: IG.label.get("infoobject_add_arangement_side"),
			        typeName: "arrangement",
			        key: "arrangements",
			        enabeld: false
			    },
			    {
			        caption: IG.label.get("infoobject_add_poll_side"),
			        typeName: "poll",
			        key: "polls",
			        enabled: false
			    },
			    {
                    caption: IG.label.get("infoobject_add_standard_side"),
                    typeName: "standard",
                    key: "customs",
                    enabled: false
                }
            ];

        //May we show the type selector?
        var bShowSelector = false;

        //Check each type and check if it is enabled for this customer:
        $.each(types, function(i, n) {
            n.enabled = IG.customerHasFunctionality(n.key);
            //At least one item is enabled - go ahead and show the selector:
            if (n.enabled) {
                bShowSelector = true;
            }
        });

        if (bShowSelector) {
            var objType = document.createElement("a");
            $(objType)
                .addClass("choose-type")
                .append('<span class="ellipsis">'+IG.label.get("infoobject_add_side")+'</span>')
                .append(' <span class="pink ellipsis">+</span>');

            var objTypeSpan = document.createElement("span");
            $(objTypeSpan).appendTo(objType);

            var typeTip = document.createElement("div");

            $.each(types, function(i, n) {
                if (n.enabled) {
                    var typeObj = document.createElement("a");
                    $(typeObj).appendTo(typeTip);

                    var typeObjspan = document.createElement("span");
                    $(typeObjspan)
                        .html(n.caption)
                        .click(function(event) {
                            event.preventDefault();
                            $(objType).qtip('hide');
                            if (_this.isNew) {
                                if (_this.fn.isParent()) {
                                    IG.createOKDialog({
                                        title: IG.label.get("infoobject_save_before_adding_children_title"),
                                        text: IG.label.get("infoobject_save_before_adding_children_text")
                                    });
                                } else {

                                }
                            } else {
                                _this.createInfoObject(n.typeName);
                            }
                            return false;
                        })
                        .appendTo(typeObj);
                }
            });

            // add appropriate classes to first and last element
            $(typeTip).find('a').last().addClass('last-list-ele');
            $(typeTip).find('a').first().addClass('first-list-ele');

            //Attach qtip to type selector:
            $(objType).qtip({
                content: {
                    text: $(typeTip),
                    title: {
                        text: ''
                    },
                    button: false
                },
                show: { event: 'click'},
                hide: { event: 'unfocus'},
                position: {
                    my: 'top left',
                    at: 'bottom left',
                    adjust: {
                        x: -10
                    }
                },
                style: {
                    classes: 'qtip-menu',
                    def: false,
                    tip: false
                }
            });

            return objType;
        }
    };






    //Renders main HTML for InfoObject
    this.render = function(cb) {
        var isCustomDocType = false;
        if (_this.item.type != 'standard' && _this.item.type != 'arrangement' && _this.item.type != 'poll') {
            isCustomDocType = true;
        }

        IG.debug("render");

        var bAdmin = IG.userHasPermission("OP_INFO_OBJECT_ADMIN");
        var bWrite = IG.userHasPermission("OP_INFO_OBJECT_WRITE", _this.item) || bAdmin;
        var bCreate = IG.userHasPermission("OP_INFO_OBJECT_CREATE") || bAdmin;
        var bDelete = IG.userHasPermission("OP_INFO_OBJECT_DELETE", _this.item) || bAdmin;

        var objDiv = $("#ig-infoobject").get(0);
        if (objDiv != null) {
            $(objDiv).remove(); //Must remove to also kill all bound events to children etc.
        }
        objDiv = document.createElement("div");
        $(objDiv)
            .attr("id", "ig-infoobject");

        //Create header:
        var objDivHeader = document.createElement("div");
        $(objDivHeader)
            .attr("id", "ig-infoobject-header")
            .appendTo(objDiv);


        //Create headline - FIXME: change when creating vs updating infoobject?
        //FIXME: change title text according to type of info object (standard, arrangement, poll) and
        var s = IG.label.get("edit_infoobject_type_"+_this.item.type);
        if (isCustomDocType) {
            s = IG.label.get("edit_infoobject") + ' (' + IG.getDocumentTypeName(_this.item.type) + ')';
        }
        if (_this.isNew) {
            s = IG.label.get("infoobject_create_new_"+_this.item.type);
            if (isCustomDocType) {
                s = IG.label.get("infoobject_create_new") + ' (' + IG.getDocumentTypeName(_this.item.type) + ')';
            }
        }

        var objH2 = document.createElement("h2");
        $(objH2)
	        .text(s)
            .attr('title', s)
            .addClass(_this.isNew ? 'doc-header-new' : 'doc-header-edit')
            .addClass('ellipsis')
	        .appendTo(objDivHeader);

        //Create language selector:
        $(objDivHeader).append(_this.renderLanguageSelector());


        //Create tabs block:
        var objTabs = document.createElement("div");
        $(objTabs)
	        .addClass("ig-tabs clearfix")
	        .attr("id", "ig-infoobject-tabs");



        //Create tabs content block:
        $(objTabs).append(_this.renderTabs());


        //Create type selector if user has write permission for this infoobject and general create permission:
        if (bCreate && bWrite) {
            $(objTabs).find('#ig-infoobject-tabs-container').append(_this.renderTypeSelector());
        }


        //Build main content table with two columns:
        var objContentTable = document.createElement("table");
        $(objContentTable)
	        .attr("id", "ig-infoobject-content-table")
	        .append(''
	            + '<colgroup>'
	            + '<col />'
	            + '<col style="width: 340px" />'
	            + '</colgroup>'
            );

        var objContentTablebody = document.createElement("tbody");
        var objContentTabletr = document.createElement("tr");


        //LEFT COLUMN:
        var objLeftCol = document.createElement("td");
        $(objLeftCol).attr("id", "ig-infoobject-left-column");


        switch (_this.item.type) {
            //Create poll specific interface elements:
            case "poll":
                $(objLeftCol).append(_this.renderHeadline(bWrite));
                $(objLeftCol).append(_this.renderSubHeadline(bWrite));
                $(objLeftCol).append(_this.renderBody(bWrite));
                $(objLeftCol).append(_this.renderPollFields(bWrite));
                break;

            //Create arrangement specific interface elements:
            case "arrangement":
                //Create headline, subheadline and body blocks:
                $(objLeftCol).append(_this.renderHeadline(bWrite));
                $(objLeftCol).append(_this.renderSubHeadline(bWrite));
                $(objLeftCol).append(_this.renderBody(bWrite));

                $(objLeftCol).append(_this.renderArrangementLocation(bWrite))
                $(objLeftCol).append(_this.renderArrangementTimes(bWrite));
                break;

            case "standard":
                $(objLeftCol).append(_this.renderHeadline(bWrite));
                $(objLeftCol).append(_this.renderSubHeadline(bWrite));
                $(objLeftCol).append(_this.renderBody(bWrite));

                break;

            default:
              //Create headline, subheadline and body blocks:
                $(objLeftCol).append(_this.renderHeadline(bWrite));
                $(objLeftCol).append(_this.renderCustomGroups(bWrite));
                break;
        }

        if (!isCustomDocType) {
            $(objLeftCol).append(_this.renderMediaTMP(bWrite));
            $(objLeftCol).append(_this.renderUrls(bWrite));
            $(objLeftCol).append(_this.renderComments(bWrite));
            $(objLeftCol).append(_this.renderGeoLocation(bWrite));
        }

        $(objContentTabletr).append(objLeftCol);


        //RIGHT COLUMN:
        var objRightCol = document.createElement("td");
        $(objRightCol).attr("id", "ig-infoobject-right-column");

        //FIXME: CHECK PERMISSIONS FOR EACH BLOCK:
        $(objRightCol).append(_this.renderPublish(bWrite)); // Enabled for custom document types
        $(objRightCol).append(_this.renderPermissions(bWrite)); // Enabled for custom document types
        $(objRightCol).append(_this.renderPreview(bWrite));
        $(objRightCol).append(_this.renderChannels(bWrite)); // Enabled for custom document types
        !isCustomDocType ? $(objRightCol).append(_this.renderRSS(bWrite)) : null;
        !isCustomDocType ? $(objRightCol).append(_this.renderTemplate(bWrite)) : null;
        !isCustomDocType ? $(objRightCol).append(_this.renderContentSources(bWrite)) : null;
        !isCustomDocType ? $(objRightCol).append(_this.renderExposure(bWrite)) : null;
        $(objRightCol).append(_this.renderMetadata(bWrite)); // Enabled for custom document types
        !isCustomDocType ? $(objRightCol).append(_this.renderCategories(bWrite)) : null;
        $(objRightCol).append(_this.renderTags(bWrite)); // Enabled for custom document types
        $(objRightCol).append(_this.renderButtons(bWrite, bCreate, bDelete)); // Enabled for custom document types



        $(objContentTabletr).append(objRightCol);

        $(objContentTablebody).append(objContentTabletr);
        $(objContentTable).append(objContentTablebody);

        $(objDiv).append(objTabs);
        $(objDiv).append(objContentTable);

        $("#ig-data").html(objDiv);

        if ($.isFunction(cb)) {
            cb();
        }

    };

    return this;
};


IG.showEditInfoObject = function(options) {
    var _IG = this;

    //Validate supplied options:
    options = (typeof options != "undefined" ? options : {});
    options.history = (typeof options.history == "boolean" ? options.history : false);
    options.id = (typeof options.id != "undefined" ? options.id : null);
    options.parentid = (typeof options.parentid != "undefined" ? options.parentid : null);
    options.object = (typeof options.object != "undefined" ? options.object : null);
    options.isNew = (typeof options.isNew == "boolean" ? options.isNew : false);
    options.searchLang = (typeof options.searchLang == "string" ? options.searchLang : IG.customerConfig.defaultContentLanguage);
    options.isChildObj = (typeof options.isChildObj != "undefined" ? options.isChildObj : false);

    // Google Analytics:
    // IG.config.prodMode ? _gaq.push(['_trackPageview']) : null;

    IG.EditorController.destroyEditors();


    var objEditInfoObject = _IG.objects.get("infoobject");
    //    if (objEditInfoObject == null) {
    objEditInfoObject = _IG.objects.create("infoobject", new _IG.InfoObject());
    //    }
    objEditInfoObject.isChildObj = options.isChildObj;


    //Parse url hash params if no id or object provided in options:
    if (options.id == null && options.object == null) {
        var urlParams = (typeof $.address.parameter(objEditInfoObject.hash()) != 'undefined' ? $.address.parameter(objEditInfoObject.hash()).split('|') : '');
        if (urlParams.length > 0) {
            var urlId = urlParams[0];

            if (urlId != "") {
            	options.id = urlId;

                if (urlId == "0") {
                    //this is newly created infoobject, create it again
                    var type = (typeof $.address.parameter("type") != 'undefined' ? $.address.parameter("type").split('|') : '')[0];
                    var parentIds = (typeof $.address.parameter("parentid") != 'undefined' ? $.address.parameter("parentid").split('|') : '');
                    var parentid = "";
                    if (parentIds.length > 0) {
                        parentid =
                        {
                            id: parentIds[0],
                            customerId: IG.config.customerId
                        };
                    }
                    var newInfoObject = IG.create.infoobject({
                        type: type,
                        parentid: parentid,
                        success: function(obj) {
                            options.object = obj;
                            options.isNew = true;
                        }
                    });
                }
            }
        }
    }

    //Determine options to send to init function:
    var loadOptions = {
        isNew: options.isNew,
        searchLang: options.searchLang
    };

    if (options.object != null) {
        loadOptions.object = options.object;
        loadOptions.id = options.object.id;
    }
    else {
        loadOptions.id = options.id;
    }


    //Add history state?
    if (options.history) {
        var h = objEditInfoObject.hash();
        if (options.isNew) {
            h += "?" + h + "=0&type=" + options.type;
            if (options.parentid != null) {
                h += "&parentid=" + options.parentid;
            }
        } else {
            h += "?" + h + "=" + (options.object != null ? options.object.id["id"] : options.id);
        }
        _IG.location.setCurrent(h);
    }

    IG.gui.loading.show();
    objEditInfoObject.init(loadOptions);
};
