//Enable close on esc keypress
IG.visibleDialogs = {};
window.jQuery(document).keydown(function(e) {
    var key = e.which ? e.which : e.keyCode;
    if (key == 27 && !$.isEmptyObject(IG.visibleDialogs)) {
        //Get most recent
        var dialog, id;
        $.each(IG.visibleDialogs, function(i, o) {
            if (!dialog || dialog.time < o.time) {
                dialog = o;
                id = i;
            }
        });
        if (dialog && dialog.closeFunc && $.isFunction(dialog.closeFunc)) {
            dialog.closeFunc();
        }
    }
});



IG.createOKDialog = function(options) {
    options = (typeof options != "undefined" ? options : {});
    options.title = (typeof options.title != "undefined" ? options.title : "");
    options.text = (typeof options.text != "undefined" ? options.text : "");

    var fnBuildOkDialog = function() {
        var objDiv = document.createElement("div");
        $(objDiv)
            .addClass("readonly-dialog");

        var objP = document.createElement("p");
        $(objP)
            .text(options.text)
            .appendTo(objDiv);

        return $(objDiv);
    };


    $( "#modal-window-dialog" )
        .html(fnBuildOkDialog())
        .dialog({
            modal: true,
            dialogClass: "no-close",
            title: options.title,
            position: { my: "center", at: "center", of: window },
            width: 300,
            height: 'auto',
            buttons: [
                {
                    text: IG.label.get("ok"),
                    click: function() {
                        $( this ).dialog( "close" );
                    }
                }
            ],

            // reset to defaults
            minWidth: 150,
            maxWidth: false,
            minHeight: 150,
            maxHeight: false
        });


    this.close = function() {
        $( "#modal-window-dialog" ).dialog( "close" );
    };

    return this;
};

IG.createConfirmDialog = function(options) {
    var dialog = {
        close: function() {
            $( "#modal-window-dialog" ).dialog( "close" )
        }
    };

    options = (typeof options != "undefined" ? options : {});
    options.title = (typeof options.title != "undefined" ? options.title : ""),
    options.text = (typeof options.text != "undefined" ? options.text : ""),
    options.onConfirm = (typeof options.onConfirm == 'function' ? options.onConfirm : function() {$( "#modal-window-dialog" ).dialog( "close" )}),
    options.onCancel = (typeof options.onCancel == 'function' ? options.onCancel : function() {$( "#modal-window-dialog" ).dialog( "close" )}),
    options.mytarget = (typeof options.target != "undefined" && options.target ? options.target : $(document));

    $( "#modal-window-dialog" )
        .html(options.text)
        .dialog({
            modal: true,
            dialogClass: "no-close",
            title: options.title,
            position: { my: "center", at: "center", of: options.mytarget, collision: 'fit' },
            width: 300,
            height: 'auto',
            buttons: [
                {
                    text: IG.label.get("ok"),
                    click: function() {
                        options.onConfirm();
                    }
                },
                {
                    text: IG.label.get("cancel"),
                    click: function() {
                        options.onCancel();
                    }
                }
            ],

            // reset to defaults
            minWidth: 150,
            maxWidth: false,
            minHeight: 150,
            maxHeight: false
        });

    return dialog;
};

IG.closeModalDialog = function() {
    $('#modal-window').dialog('close');
    $('#modal-window-dialog').dialog('close');
};


IG.showPreview = function(options) {
    IG.gui.blanket.hide();
    if (options.href.substr(0, 4)=="http") {
        $( "#modal-window" )
            .html('<iframe id="preview-frame" frameborder="0" hspace="0" scrolling="auto" wmode="direct" ' +
                'src="'+options.href+'" style="width: '+options.width+'px; height: '+options.height+'px;"></iframe>')
            .dialog({
                modal: true,
                position: { my: "center", at: "center", of: window },
                width: 'auto',
                height: 'auto',
                buttons: null,

                // reset to defaults
                minWidth: 150,
                maxWidth: false,
                minHeight: 150,
                maxHeight: false,
                title: ''
            });

    } else {
        window.location.href = options.href;
    }
};


IG.showErrorDialog = function(err, fnOnClose) {
    err = (typeof err == "string" ? err : IG.label.get("dialog_something_bad_happend"));
    fnOnClose = ($.isFunction(fnOnClose) ? fnOnClose : function() { });

    var objDiv = document.createElement("div");
    $(objDiv)
        .addClass("error")
        .html(err);


    $( "#modal-window-dialog" )
        .html(objDiv)
        .dialog({
            modal: true,
            dialogClass: "no-close",
            position: { my: "center", at: "center", of: window },
            width: 360,
            height: 'auto',
            buttons: [{
                text: IG.label.get("ok"),
                click: function() {
                    $( this ).dialog( "close" );
                    fnOnClose();
                }
            }],

            // reset to defaults
            minWidth: 150,
            maxWidth: false,
            minHeight: 150,
            maxHeight: false,
            title: ''
        });

    this.close = function() {
        $("#modal-window-dialog").dialog("close");
    };
    return this;
};
