// Simulates PHP's date function
Date.prototype.format = function(format) {
    var returnStr = '';
    var replace = Date.replaceChars;
    for (var i = 0; i < format.length; i++) {
        var curChar = format.charAt(i); if (i - 1 >= 0 && format.charAt(i - 1) == "\\") {
            returnStr += curChar;
        }
        else if (replace[curChar]) {
            returnStr += replace[curChar].call(this);
        } else if (curChar != "\\") {
            returnStr += curChar;
        }
    }
    return returnStr;
};

Date.replaceChars = {
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    longMonths: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    longDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

    // Day
    d: function() { return (this.getDate() < 10 ? '0' : '') + this.getDate(); },
    D: function() { return Date.replaceChars.shortDays[this.getDay()]; },
    j: function() { return this.getDate(); },
    l: function() { return Date.replaceChars.longDays[this.getDay()]; },
    N: function() { return this.getDay() + 1; },
    S: function() { return (this.getDate() % 10 == 1 && this.getDate() != 11 ? 'st' : (this.getDate() % 10 == 2 && this.getDate() != 12 ? 'nd' : (this.getDate() % 10 == 3 && this.getDate() != 13 ? 'rd' : 'th'))); },
    w: function() { return this.getDay(); },
    z: function() { var d = new Date(this.getFullYear(), 0, 1); return Math.ceil((this - d) / 86400000); }, // Fixed now
    // Week
    W: function() { var d = new Date(this.getFullYear(), 0, 1); return Math.ceil((((this - d) / 86400000) + d.getDay() + 1) / 7); }, // Fixed now
    // Month
    F: function() { return Date.replaceChars.longMonths[this.getMonth()]; },
    m: function() { return (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1); },
    M: function() { return Date.replaceChars.shortMonths[this.getMonth()]; },
    n: function() { return this.getMonth() + 1; },
    t: function() { var d = new Date(); return new Date(d.getFullYear(), d.getMonth(), 0).getDate() }, // Fixed now, gets #days of date
    // Year
    L: function() { var year = this.getFullYear(); return (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)); },       // Fixed now
    o: function() { var d = new Date(this.valueOf()); d.setDate(d.getDate() - ((this.getDay() + 6) % 7) + 3); return d.getFullYear(); }, //Fixed now
    Y: function() { return this.getFullYear(); },
    y: function() { return ('' + this.getFullYear()).substr(2); },
    // Time
    a: function() { return this.getHours() < 12 ? 'am' : 'pm'; },
    A: function() { return this.getHours() < 12 ? 'AM' : 'PM'; },
    B: function() { return Math.floor((((this.getUTCHours() + 1) % 24) + this.getUTCMinutes() / 60 + this.getUTCSeconds() / 3600) * 1000 / 24); }, // Fixed now
    g: function() { return this.getHours() % 12 || 12; },
    G: function() { return this.getHours(); },
    h: function() { return ((this.getHours() % 12 || 12) < 10 ? '0' : '') + (this.getHours() % 12 || 12); },
    H: function() { return (this.getHours() < 10 ? '0' : '') + this.getHours(); },
    i: function() { return (this.getMinutes() < 10 ? '0' : '') + this.getMinutes(); },
    s: function() { return (this.getSeconds() < 10 ? '0' : '') + this.getSeconds(); },
    u: function() {
        var m = this.getMilliseconds(); return (m < 10 ? '00' : (m < 100 ? '0' : '')) + m;
    },
    // Timezone
    e: function() { return "Not Yet Supported"; },
    I: function() { return "Not Yet Supported"; },
    O: function() { return (-this.getTimezoneOffset() < 0 ? '-' : '+') + (Math.abs(this.getTimezoneOffset() / 60) < 10 ? '0' : '') + (Math.abs(this.getTimezoneOffset() / 60)) + '00'; },
    P: function() { return (-this.getTimezoneOffset() < 0 ? '-' : '+') + (Math.abs(this.getTimezoneOffset() / 60) < 10 ? '0' : '') + (Math.abs(this.getTimezoneOffset() / 60)) + ':00'; }, // Fixed now
    T: function() { var m = this.getMonth(); this.setMonth(0); var result = this.toTimeString().replace(/^.+ \(?([^\)]+)\)?$/, '$1'); this.setMonth(m); return result; },
    Z: function() { return -this.getTimezoneOffset() * 60; },
    // Full Date/Time
    c: function() { return this.format("Y-m-d\\TH:i:sP"); }, // Fixed now
    r: function() { return this.toString(); },
    U: function() { return this.getTime() / 1000; }
};



/*

Name: jsDate
Desc: VBScript native Date functions emulated for Javascript
Author: Rob Eberhardt, Slingshot Solutions - http://slingfive.com/
History:
2005-08-04	v0.94		scrapped new dateDiff approach to better match VBScript's simplistic Y/M/Q
2005-08-03	v0.93		fixed dateDiff/leapyear bug with yyyy/m/q intervals
2004-11-26	v0.91		fixed datePart/ww bug, added weekdayName() & monthName()
2004-08-30	v0.9		brand new
	
*/

// used by dateAdd, dateDiff, datePart, weekdayName, and monthName
// note: less strict than VBScript's isDate, since JS allows invalid dates to overflow (e.g. Jan 32 transparently becomes Feb 1)
function isDate(p_Expression) {
    return !isNaN(new Date(p_Expression)); 	// <<--- this needs checking
}


// REQUIRES: isDate()
function dateAdd(p_Interval, p_Number, p_Date) {
    if (!isDate(p_Date)) { return "invalid date: '" + p_Date + "'"; }
    if (isNaN(p_Number)) { return "invalid number: '" + p_Number + "'"; }

    p_Number = new Number(p_Number);
    var dt = new Date(p_Date);
    switch (p_Interval.toLowerCase()) {
        case "yyyy": 
            {// year
                dt.setFullYear(dt.getFullYear() + p_Number);
                break;
            }
        case "q": 
            {		// quarter
                dt.setMonth(dt.getMonth() + (p_Number * 3));
                break;
            }
        case "m": 
            {		// month
                dt.setMonth(dt.getMonth() + p_Number);
                break;
            }
        case "y": 	// day of year
        case "d": 	// day
        case "w": 
            {		// weekday
                dt.setDate(dt.getDate() + p_Number);
                break;
            }
        case "ww": 
            {	// week of year
                dt.setDate(dt.getDate() + (p_Number * 7));
                break;
            }
        case "h": 
            {		// hour
                dt.setHours(dt.getHours() + p_Number);
                break;
            }
        case "n": 
            {		// minute
                dt.setMinutes(dt.getMinutes() + p_Number);
                break;
            }
        case "s": 
            {		// second
                dt.setSeconds(dt.getSeconds() + p_Number);
                break;
            }
        case "ms": 
            {		// second
                dt.setMilliseconds(dt.getMilliseconds() + p_Number);
                break;
            }
        default: 
            {
                return "invalid interval: '" + p_Interval + "'";
            }
    }
    return dt;
}



// REQUIRES: isDate()
// NOT SUPPORTED: firstdayofweek and firstweekofyear (defaults for both)
function dateDiff(p_Interval, p_Date1, p_Date2, p_firstdayofweek, p_firstweekofyear) {
    if (!isDate(p_Date1)) { return "invalid date: '" + p_Date1 + "'"; }
    if (!isDate(p_Date2)) { return "invalid date: '" + p_Date2 + "'"; }
    var dt1 = new Date(p_Date1);
    var dt2 = new Date(p_Date2);

    // get ms between dates (UTC) and make into "difference" date
    var iDiffMS = dt2.valueOf() - dt1.valueOf();
    var dtDiff = new Date(iDiffMS);

    // calc various diffs
    var nYears = dt2.getUTCFullYear() - dt1.getUTCFullYear();
    var nMonths = dt2.getUTCMonth() - dt1.getUTCMonth() + (nYears != 0 ? nYears * 12 : 0);
    var nQuarters = parseInt(nMonths / 3); //<<-- different than VBScript, which watches rollover not completion

    var nMilliseconds = iDiffMS;
    var nSeconds = parseInt(iDiffMS / 1000);
    var nMinutes = parseInt(nSeconds / 60);
    var nHours = parseInt(nMinutes / 60);
    var nDays = parseInt(nHours / 24);
    var nWeeks = parseInt(nDays / 7);


    // return requested difference
    var iDiff = 0;
    switch (p_Interval.toLowerCase()) {
        case "yyyy": return nYears;
        case "q": return nQuarters;
        case "m": return nMonths;
        case "y": 		// day of year
        case "d": return nDays;
        case "w": return nDays;
        case "ww": return nWeeks; 	// week of year	// <-- inaccurate, WW should count calendar weeks (# of sundays) between
        case "h": return nHours;
        case "n": return nMinutes;
        case "s": return nSeconds;
        case "ms": return nMilliseconds; // millisecond	// <-- extension for JS, NOT available in VBScript
        default: return "invalid interval: '" + p_Interval + "'";
    }
}



// REQUIRES: isDate(), dateDiff()
// NOT SUPPORTED: firstdayofweek and firstweekofyear (does system default for both)
function datePart(p_Interval, p_Date, p_firstdayofweek, p_firstweekofyear) {
    if (!isDate(p_Date)) { return "invalid date: '" + p_Date + "'"; }

    var dtPart = new Date(p_Date);
    switch (p_Interval.toLowerCase()) {
        case "yyyy": return dtPart.getFullYear();
        case "q": return parseInt(dtPart.getMonth() / 3) + 1;
        case "m": return dtPart.getMonth() + 1;
        case "y": return dateDiff("y", "1/1/" + dtPart.getFullYear(), dtPart); 		// day of year
        case "d": return dtPart.getDate();
        case "w": return dtPart.getDay(); // weekday
        case "ww": return dateDiff("ww", "1/1/" + dtPart.getFullYear(), dtPart); 	// week of year
        case "h": return dtPart.getHours();
        case "n": return dtPart.getMinutes();
        case "s": return dtPart.getSeconds();
        case "ms": return dtPart.getMilliseconds(); // millisecond	// <-- extension for JS, NOT available in VBScript
        default: return "invalid interval: '" + p_Interval + "'";
    }
}


// REQUIRES: isDate()
// NOT SUPPORTED: firstdayofweek (does system default)
function weekdayName(p_Date, p_abbreviate) {
    if (!isDate(p_Date)) { return "invalid date: '" + p_Date + "'"; }
    var dt = new Date(p_Date);
    var retVal = dt.toString().split(' ')[0];
    var retVal = Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday')[dt.getDay()];
    if (p_abbreviate == true) { retVal = retVal.substring(0, 3) } // abbr to 1st 3 chars
    return retVal;
}
// REQUIRES: isDate()
function monthName(p_Date, p_abbreviate) {
    if (!isDate(p_Date)) { return "invalid date: '" + p_Date + "'"; }
    var dt = new Date(p_Date);
    var retVal = Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December')[dt.getMonth()];
    if (p_abbreviate == true) { retVal = retVal.substring(0, 3) } // abbr to 1st 3 chars
    return retVal;
}









// ====================================

// bootstrap different capitalizations
function IsDate(p_Expression) {
    return isDate(p_Expression);
}
function DateAdd(p_Interval, p_Number, p_Date) {
    return dateAdd(p_Interval, p_Number, p_Date);
}
function DateDiff(p_interval, p_date1, p_date2, p_firstdayofweek, p_firstweekofyear) {
    return dateDiff(p_interval, p_date1, p_date2, p_firstdayofweek, p_firstweekofyear);
}
function DatePart(p_Interval, p_Date, p_firstdayofweek, p_firstweekofyear) {
    return datePart(p_Interval, p_Date, p_firstdayofweek, p_firstweekofyear);
}
function WeekdayName(p_Date) {
    return weekdayName(p_Date);
}
function MonthName(p_Date) {
    return monthName(p_Date);
}

export default {
    isDate: isDate,
    dateAdd: dateAdd,
    dateDiff: dateDiff,
    datePart: datePart,
    weekdayName: weekdayName,
    monthName: monthName,
    weekdayName:weekdayName,
    IsDate: IsDate,
    DataAdd: DateAdd,
    DateDiff: DateDiff,
    DatePart:DatePart,
    WeekdayName:WeekdayName,
    MonthName:MonthName
}
