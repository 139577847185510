IG.InfoObjectHelperLists = function() {};


IG.InfoObjectField = function(options) {
    options = (typeof options === 'undefined' ? {} : options);
    this.setLanguage = ($.isFunction(options.setLanguage) ? options.setLanguage : function() {});
    this.removeMedium = ($.isFunction(options.removeMedium) ? options.removeMedium : function() {});
    this.validateField = ($.isFunction(options.validateField) ? options.validateField : function() {});
};

IG.mediaCache = {
    data: {},
    set: function(medium) {

        if (typeof medium === 'object' && typeof medium.id !== 'undefined') {
            var idStr = IG.getId(medium.id);
            if (idStr) {
                this.data[idStr] = medium;
                return true;
            } else {
                return false;
            }
        }
        return false;
    },
    get: function(absMediumId) {
        var idStr = IG.getId(absMediumId);
        if (idStr && typeof this.data[idStr] !== 'undefined') {
            return this.data[idStr];
        } else {
            return false;
        }
    },
    remove: function(absMediumId) {
        var idStr = IG.getId(absMediumId);
        if (idStr && typeof this.data[idStr] !== 'undefined') {
            delete this.data[idStr];
            return true;
        } else {
            return false;
        }
    }
};

IG.InfoObjectFields = function (iObject, initLanguage) {
    IG.documentTitleCache.cleanUp();
    IG.channelNameCache.cleanUp();
		
    var igFields = [];
    var currentLanguage = initLanguage;

    var mediaArchiveShown = false; // used to prevent multi-click

    var isInt = function(value) {
        return !isNaN(parseInt(value,10)) && (parseFloat(value,10) == parseInt(value,10));
    };

    this.setLanguage = function(language) {
        IG.documentTitleCache.cleanUp();
        IG.channelNameCache.cleanUp();

        currentLanguage = language;
        $.each(igFields, function(i, f) {
            f.setLanguage(language);
        });

        // ask the cache to search for titles
        IG.documentTitleCache.triggerSearch();
        IG.channelNameCache.triggerSearch();
    };

    this.validateFields = function() {
        var fErrRes = null;
        var tmpErrors = [];
        $.each(igFields, function(i, f) {
            fErrRes = f.validateField();
            if (typeof fErrRes !== 'undefined' && fErrRes.length > 0) {
                tmpErrors = tmpErrors.concat(fErrRes);
            }
        });
        return tmpErrors;
    };

    this.mediumDeleted = function(mediumId) {
        if (mediumId === null || typeof mediumId === 'undefined' || typeof mediumId.id === 'undefined') {
            return false;
        }
        IG.mediaCache.remove(mediumId);
        $.each(igFields, function(i, f) {
            f.removeMedium(mediumId);
        });
        iObject.itemHasChanged = true;
    };


    /**
     *
     * @param validationType The kind of validation. E.g. is the type itself validated or is the existence of a value being confirmed.
     * @param field The definition of the field.
     * @param fieldValues All the current values of the field.
     * @param fieldLabel The label used to "point" the user to the field.
     * @returns {Array}
     */
    var getFieldErrorsByValidationType = function(validationType, paramValue, field, fieldValues, fieldLabel) {
        var result = [],
            errStr = false,
            languages = [],
            maxLength = 0;


        switch(validationType) {
            case 'type':
                // loop through all languages:
                $.each(IG.customerConfig.contentLanguages, function(langKey) {
                    if (typeof fieldValues[langKey] !== 'undefined') {

                        switch (field.typeName) {
                            case 'integer-signed':
                                var parsed = parseInt(fieldValues[langKey], 10);
                                if (fieldValues[langKey] !== null && ( _.isNaN( fieldValues[langKey]) || !_.isNumber(fieldValues[langKey]) ) ) {
                                    errStr = IG.label.get('FIELD_ERROR_'+field.typeName+'_invalid_type');
                                    languages.push(langKey);
                                }
                                break;

                            case 'integer-unsigned':
                                var parsed = parseInt(fieldValues[langKey], 10);
                                if (fieldValues[langKey] !== null && (parsed < 0 || _.isNaN( fieldValues[langKey]) || !_.isNumber(fieldValues[langKey]) ) ) {
                                    errStr = IG.label.get('FIELD_ERROR_'+field.typeName+'_invalid_type');
                                    languages.push(langKey);
                                }
                                break;

                            case 'float':
                                var parsed = parseFloat(fieldValues[langKey]);
                                if (fieldValues[langKey] !== null && (_.isNaN( fieldValues[langKey]) || !_.isNumber(fieldValues[langKey]) ) ) {
                                    errStr = IG.label.get('FIELD_ERROR_'+field.typeName+'_invalid_type');
                                    languages.push(langKey);
                                }
                                break;
                        }


                    }
                });

                break;

            case 'max-length':
                // loop through all languages:
                $.each(IG.customerConfig.contentLanguages, function(langKey) {
                    if (typeof fieldValues[langKey] !== 'undefined' && fieldValues[langKey].length > paramValue) {
                        errStr = IG.label.get('FIELD_ERROR_'+field.typeName+'_max-length');
                        maxLength = paramValue;
                        languages.push(langKey);
                    }
                });
                break;

            case 'required':
                if (paramValue !== true) {
                    // stop if the parameter has an invalid paramValue OR it is false
                    break;
                }
                if (field.typeName === 'plain-text') {
                    $.each(IG.customerConfig.contentLanguages, function(langKey) {
                        if (typeof fieldValues[langKey] === 'undefined' || fieldValues[langKey].length < 1 ) {
                            errStr = IG.label.get('FIELD_ERROR_'+field.typeName+'_required');
                            languages.push(langKey);
                        }
                    });
                }
                break;
        }

        if (errStr) {
            var langs = languages.reduce(function(a, b) {
                return a + '", "' + b;
            });
            errStr = errStr.replace("{field_name}", fieldLabel);
            errStr = errStr.replace("{languages}", '"'+langs+'"');
            errStr = errStr.replace('{max_chars}', maxLength);
            result.push(errStr);
        }

        return result;
    };



    /**
     *
     * @param field
     * @param fieldValues
     * @param {String} fieldLabel A textual reference to the field. E.g. "Group 1 - Field 3".
     * @returns {Array}
     */
    var getFieldErrors = function(field, fieldValues, fieldLabel) {
        // console.log(' getFieldErrors ->', field, fieldValues, fieldLabel);
        var result = [];

        // loop parameters for validation parameters
        $.each(field.parameters, function(k, value) {
            var testRes = getFieldErrorsByValidationType(
                k, value,
                field, fieldValues, fieldLabel
            );
            result = result.concat(testRes);
        });
        return result;
    };

    var createDescription = function(text, type) {
        var objDescription = null;
        switch (type) {

            case 'tr':
                objDescription = document.createElement('tr');
                $(objDescription).addClass('tr-input-row');

                var td = document.createElement('td');
                $(td)
                    .attr('colspan', 2)
                    .addClass('ig-field-description')
                    .text(text)
                    .appendTo(objDescription);
                break;


            case 'p':
                objDescription = document.createElement('p');
                $(objDescription)
                    .addClass('ig-field-description')
                    .text(text);

                break;
        }
        return objDescription;
    };

	this.createField = function(field, fieldGroup, destinationTBody) {
        var _thisObjectFields = this;


		switch (field.typeName) {

	        case "plain-text":

                // prepare field values
                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                $.each(IG.customerConfig.contentLanguages, function(langKey) {
                    // The translation does not exist:
                    if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] === 'undefined') {
                        // Check for a default text for the translation. Otherwise init with an empty string.
                        if (typeof field.parameters['default-texts'] !== 'undefined' && typeof field.parameters['default-texts'][langKey] !== 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] = field.parameters['default-texts'][langKey];
                        } else {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] = '';
                        }
                    }
                });

	            var objTRPlainText = document.createElement("tr");
	            $(objTRPlainText)
                    .addClass('tr-input-row')
                    .appendTo(destinationTBody);
	
	            var objTDlblPlainText = document.createElement("td");
	            $(objTDlblPlainText)
	                .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
	                .html('<label for="field-' + fieldGroup.groupName + '-' + field.fieldName +'">' + field.label[IG.config.language] + '</label>')
	                .appendTo(objTRPlainText);
	
	            var objTDinpPlainText = document.createElement("td");
	            $(objTDinpPlainText)
                    .addClass('td-input')
                    .appendTo(objTRPlainText);


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                var multiLine = (typeof field.parameters['multi-line'] !== 'undefined' ? !!field.parameters['multi-line'] : false);
                var objInputPlain = null;
                if (multiLine) {
                    objInputPlain = document.createElement("textarea");
                } else {
                    objInputPlain = document.createElement("input");
                    $(objInputPlain)
                        .attr({
                            "type": "text"
                        })
                }
	            $(objInputPlain)
                    .attr('id', "field-" + fieldGroup.groupName + '-' + field.fieldName)
	                .val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage])
	                .appendTo(objTDinpPlainText)
	                .wrap('<span class="'+ (multiLine ? 'input input-multi-line' : 'input') +'"></span>');


                // set the default value if it exists and the document is new
                if (iObject.isNew && typeof field.parameters['default-texts'] !== 'undefined'
                        && typeof field.parameters['default-texts'][currentLanguage] !== 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = field.parameters['default-texts'][currentLanguage];
                    $(objInputPlain).val(field.parameters['default-texts'][currentLanguage]);
                }


	            $(objInputPlain).change(function(event) {
	            	iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = $(this).val();
	            	iObject.itemHasChanged = true;
	            	// console.log('[InfoObjectFields.createField]', iObject.item.fieldGroups, Math.random());
	            });

                var fieldObjPlainText = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = '';
                        }
                        $(objInputPlain).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);

                        // set the default value if it exists and there is no current value
                        if (typeof field.parameters['default-texts'] !== 'undefined' && iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language].length === 0) {
                            if (field.parameters['default-texts'][language]) {
                                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = field.parameters['default-texts'][language];
                                $(objInputPlain).val(field.parameters['default-texts'][language]);
                            }
                        }
                    },

                    validateField: function() {
                        return getFieldErrors(
                            field,
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName],
                            fieldGroup.name[IG.config.language] + ' - ' + field.label[IG.config.language]
                        );
                    }
                });
                igFields.push(fieldObjPlainText);
	            
	            break;





            case "enum":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                        || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }

                /* For backward compatibility we need to check how field.parameters is constructed
                    If it contains the field 'enum-values' (preferred way) use that. Otherwise if it's a plain array
                    assume it's the labels/values for the enum. */
                var enumParams = [];
                if (typeof field.parameters['enum-values'] !== 'undefined') {
                    enumParams = field.parameters['enum-values'];
                } else if ($.isArray(field.parameters)) {
                    enumParams = field.parameters;
                }

                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);


                $.each(enumParams, function(i, par) {
                    if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                    }

                    var objInput = document.createElement("input");
                    $(objInput)
                        .attr({
                            "type": "radio",
                            'name': fieldGroup.groupName+'-'+field.fieldName,
                            'id': fieldGroup.groupName+'-'+field.fieldName+'-'+i,
                            'value' : i
                        })
                        .appendTo(objTDinp);
                    if (i === iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]) {
                        $(objInput).prop('checked', true);
                    }
                    $(objTDinp).append( (typeof par[IG.config.language] !== 'undefined' ? par[IG.config.language] : '-- not translated --') + '<br />');
                    $(objInput).change(function(event) {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = parseInt($(this).val());
                        iObject.itemHasChanged = true;

                    });
                });

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldObjEnum = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        $.each(enumParams, function(i, par) {
                            $('#'+fieldGroup.groupName+'-'+field.fieldName+'-'+i).prop('checked', false); // reset checked status
                            if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                            }
                            if (i == iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]) {
                                $('#'+fieldGroup.groupName+'-'+field.fieldName+'-'+i).prop('checked', true);
                            }
                        });
                    },

                    validateField: function() {
                        return getFieldErrors(
                            field,
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName],
                            fieldGroup.name[IG.config.language] + ' - ' + field.label[IG.config.language]
                        );
                    }
                });
                igFields.push(fieldObjEnum);

                break;





	        case "html-text":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                        || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = '';
                }

	            var objTR = document.createElement("tr");
	            $(objTR).addClass('tr-input-row').appendTo(destinationTBody);
	
	            var objTDlbl = document.createElement("td");
	            $(objTDlbl)
	                .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
	                .html('<label>' + field.label[IG.config.language] + '</label>')
	                .appendTo(objTR);
	
	            var objTDinp = document.createElement("td");
	            $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);
	
	            var objInput = document.createElement("div");
	            $(objInput)
	                .attr({
	                    "id": "field-"+field.fieldName
	                })
	                .css({
	                	width: "100%"
	                })
	                .appendTo(objTDinp);


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldObjHtmlTxt = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = '';
                        }
                    }
                });
                igFields.push(fieldObjHtmlTxt);

                // Delay the editor's setup
                window.setTimeout(function() {
                    var objBodyEditor = new IG.Editor({
                        key: "body",
                        cssClass: 'input-editor',
                        container: $("#"+"field-"+field.fieldName).get(0),
                        texts: iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName],
                        language: currentLanguage,
                        height: 200,
                        formatting: true,
                        enableSoftHyphen: false,
                        onchange: function(s, language) {
                            if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] != s) {
                                iObject.itemHasChanged = true;
                                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = s;
                            }
                        }
                    });
                    IG.EditorController.register(objBodyEditor);
                }, 0);
	            
	            break;




	            
            case "typed-reference":

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])
                        ||typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] !== 'object') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined'
                        || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === false) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }


	            var _this = this;
	            this.query = "";
	            this.searchLang = IG.customerConfig.defaultContentLanguage;
	            this.defaultSearchVal = IG.label.get("info_object_list_enter_search_term");
	            
	            //Determine available height for list:
	            var fnGetAvailableHeight = function(obj) {
	                var h = $(window).height() - $(obj).offset().top - 36; //ish...
	                if (h < 200) {
	                    h = 200;
	                }
	                return h;
	            };

	            
	            var fnStatusTipContent = function() {
	                var k = "publishState";

	                var objDiv = document.createElement("div");

	                var statuses = IG.fn.getInfoObjectStatuses();
	                $.each(statuses, function(i, s) {
	                    var bFound = IGObjectList.getFilter(k) == s.filter;

	                    var objA = document.createElement("a");
	                    if (bFound) {
	                        $(objA).addClass("active");
	                    }

	                    $(objA)
	                        .html('<span>' + s.caption + '</span>')
	                        .click(function() {
	                            if ($(this).hasClass("active")) {
	                                $(this).removeClass("active");
	                                IGObjectList.removeFilter(k, s.filter);
	                            }
	                            else {
	                                $(this).addClass("active");
	                                $(this).siblings("a").removeClass("active");
	                                IGObjectList.setFilter(k, s.filter);
	                            }
	                            IGObjectList.refresh();
	                        })
	                        .appendTo(objDiv);
	                });

	                return $(objDiv);
	            };
	            
	            //Render editor tip contents:
	            var fnEditorTipContent = function() {
	                var k = "owners";

	                var objDiv = document.createElement("div");

	                $.each(IG.lists.users, function(i, n) {
	                    var bFound = IGObjectList.hasIdFilter(k, n.id);

	                    var objA = document.createElement("a");
	                    if (bFound) {
	                        $(objA).addClass("active");
	                    }

	                    $(objA)
	                        .html('<span>' + n.name + '</span>')
	                        .click(function() {
	                            if ($(this).hasClass("active")) {
	                                $(this).removeClass("active");
	                                IGObjectList.removeIdFilter(k, n.id);
	                            }
	                            else {
	                                $(this).addClass("active");
	                                IGObjectList.addIdFilter(k, n.id);
	                            }
	                            IGObjectList.refresh();
	                        })
	                        .appendTo(objDiv);

	                });

	                return objDiv;
	            };
	            
	            //Render type tip contents:
	            var fnTypeTipContent = function() {
	                var k = "infoObjectType";
	                var currentFilter = IGObjectList.getFilter(k);

                    var objDiv = document.createElement("div");
                    $.each(field.referencedDocTypes, function(key, dt) {
                        var objA = document.createElement("a");
                        if (currentFilter == dt) {
                            $(objA).addClass("active");
                        }
                        $(objA)
                            .html('<span>' + IG.getDocumentTypeName(dt) + '</span>')
                            .click(function() {
                                if ($(this).hasClass("active")) {
                                    /* Don't allow removing a document type filter
                                    $(this).removeClass("active");
                                    IGObjectList.removeFilter(k);*/
                                } else {
                                    $(this).addClass("active");
                                    $(this).siblings("a").removeClass("active");
                                    IGObjectList.setFilter(k, dt);
                                }
                                IGObjectList.refresh();
                            })
                            .appendTo(objDiv);
                    });
	                return objDiv;
	            };
	            
	            
	            var objTrLabel = document.createElement("tr");
                $(objTrLabel)
                    .appendTo(destinationTBody); 
	            
                var objTdLabel = document.createElement("td");
                $(objTdLabel)
                    .attr("colspan", "2")
                    .html('<h3 class="ig-title">'+field.label[IG.config.language]+"</h3>")
                    .appendTo(objTrLabel);


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(objTdLabel).append(createDescription(field.description[IG.config.language], 'p'));
                }
                
                var objTrChosen = document.createElement("tr");
                $(objTrChosen)
                    .appendTo(destinationTBody);
                
                var objTdChosen = document.createElement("td");
                $(objTdChosen)
                    .attr("colspan", "2")
                    .appendTo(objTrChosen);
                
                var objDivChosenDocument = document.createElement("div");
                $(objDivChosenDocument)
                    .appendTo(objTdChosen);



                var renderChosenReference = function(options) {
                    options = (typeof options !== 'undefined' ? options : {});
                    options.id = (typeof options.id !== 'undefined' ? options.id : null);
                    var objA = document.createElement("a");


                    var translatedTitle = '<em>...</em>';
                    var cachedTitle = IG.documentTitleCache.get(options.id.id);
                    if (cachedTitle) {
                        if (typeof cachedTitle[_this.searchLang] !== 'undefined') {
                            translatedTitle = cachedTitle[_this.searchLang];
                        }
                        $(objA)
                            .html(translatedTitle)
                            .attr("title", translatedTitle);
                    } else {
                        $(objA)
                            .html('<em>' + IG.label.get('INFO_OBJECT_LIST_SEARCHING_FOR_TITLE') + ' ...</em>')
                            .attr("title", translatedTitle);

                        IG.documentTitleCache.registrer(options.id.id, function(titleObj) {
                            var titleTranslated = titleObj[_this.searchLang];
                            if (typeof titleTranslated === 'undefined') {
                                titleTranslated = IG.label.get('INFOOBJECT_HEADLINE_NOT_TRANSLATED');
                            }
                            $(objA)
                                .html(titleTranslated)
                                .attr("title", translatedTitle);
                        });
                    }


                    $(objDivChosenDocument).html('');


                    $(objA)
                        .addClass('ig-typed-ref-row-headline')
                        .click(function() {
                            if (IG.infoObjectChanged()) {
                                IG.showInfoObjectChangedDialog({
                                    confirm: function() {
                                        IG.showEditInfoObject({
                                            id: options.id["id"],
                                            history: true,
                                            searchLang: _this.searchLang
                                        });
                                    }
                                });
                            } else {
                                IG.showEditInfoObject({
                                    id: options.id["id"],
                                    history: true,
                                    searchLang: _this.searchLang
                                });
                            }

                        })
                        .appendTo(objDivChosenDocument);

                    var elemBtnRemoveRef = document.createElement("a");
                    $(elemBtnRemoveRef)
                        .addClass("btn-small btn-dark ig-typed-ref-rm-btn")
                        .html("<span>"+IG.label.get('global_remove')+"</span>")
                        .click(function(event) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                            iObject.itemHasChanged = true;
                            $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');


                            var objList = $('#'+IGObjectList.id);
                            var objItemRow = objList.find('.ITEM_'+options.id.id);
                            if (objItemRow[0]) {
                                var objItemBtns = objItemRow.find('td.buttons-add div');
                                if (objItemBtns[0]) {
                                    renderAddSingleReferenceBtn(options.id, objItemBtns[0]);
                                }
                            }
                        })
                        .appendTo(objDivChosenDocument);
                };


                // Initially find the currently chosen:
                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] != null) {
                    var id = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];

                    renderChosenReference({
                        id: id
                    });

                    // Delay the title search a bit to let other fields fields registrer for title updates
                    window.setTimeout(function() {
                        IG.documentTitleCache.triggerSearch(); // make sure the list is populated with titles
                    }, 10);
                } else {
                    $(objDivChosenDocument).html("<em>"+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+"</em>");
                }


	            //Create ObjectList:
	            var IGObjectList = new IG.ObjectList({
	                methodName: "searchInfoObjects",
	                subelements: true,
	                filters: {
	                    parentsOnly: true
                    },
                    sorting: 'lastModified',
                    order: 'DESC'
	            });
                IGObjectList.setDocumentType(field.referencedDocTypes[0]);

	            
	            var objTrSearch = document.createElement("td");
                $(objTrSearch)
                    .attr("colspan", "2")
                    .addClass('no-padding')
                    .appendTo(destinationTBody)
                    .wrap("<tr></tr>");
	            
	            var objSearchWrap = document.createElement("div");
	            $(objSearchWrap)
	                /*.css({
	                    "padding": "10px",
	                    "float": "left",
	                    "display": "block",
	                    "width": "100%",
	                    "height": "100px"
	                })*/
	                .appendTo(objTrSearch);
	            
	            var objLabel = document.createElement("h3");
	            $(objLabel)
	                .text(IG.label.get('global_search'))
	                .addClass("ig-title")
	                .appendTo(objSearchWrap);
	            
                //SEARCHBAR :
                var objSearch = document.createElement("div");
                $(objSearch)
                    .addClass("ig-search ig-search-tall clearfix")
                    .attr("id", "ig-infoobjects-search")
                    .appendTo(objSearchWrap);

                var displayVal = (_this.query != '' ? _this.query : _this.defaultSearchVal);
                var searchLang = _this.searchLang;

                var objSearchInput = document.createElement("input");
                $(objSearchInput)
                    .attr({
                        "type": "text",
                        "name": "search-infoobjects",
                        "id": "search-infoobjects-"+field.fieldName,
                        "value": displayVal
                    })
                    .addClass("search")
                    .click(function() {
                        if (_this.defaultSearchVal == $(this).val()) $(this).val('');
                        //some decoration stuff
                        $(this).removeClass('search-default-deco');
                        $(this).addClass('search-default');
                    })
                    .blur(function() {
                        if ($(this).val() == '') $(this).val(_this.defaultSearchVal);
                        $(this).removeClass('search-default');
                        $(this).addClass('search-default-deco');
                    })
                    .keyup(function(e) {
                        var v = $(this).val();
                        var key = e.which ? e.which : e.keyCode;
                        if (key == 13) {
                            _this.query = (v != _this.defaultSearchVal ? v : '');
                            _this.searchLang = searchLang;
                            if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                                $(objLangSel).addClass('search-lang-deco');
                            } else $(objLangSel).removeClass('search-lang-deco');
                            IGObjectList.setQuery(_this.query, _this.searchLang);
                            IGObjectList.refresh();
                        }
                    })
                    .appendTo(objSearch);



                var objLangSel = IGObjectList.renderSearchLanguageSelector({
                    onChange: function(n) {
                        searchLang = n;
                    },
                    searchLang: searchLang
                });
                if (objLangSel) $(objSearch).append(objLangSel);


                // Render button for creating documents from within the current document
                var docCreatorBtnRefSingle = new IGObjectList.renderDocumentCreator({
                    docTypes: field.referencedDocTypes,
                    onCreate: function(newDoc) {
                        IG.documentTitleCache.set(newDoc.id, newDoc.headline); // Add the headline to the cache to avoid searching
                        onSingleRefChooseClick(newDoc.id);
                        IGObjectList.refresh();
                    }
                });


                var docTypeRefChosen = null;
                var objDocTypeSel = IGObjectList.renderDocumentTypeSelector({
                    docTypes: field.referencedDocTypes,
                    onChange: function(docType) {
                        docTypeRefChosen = docType;
                        docCreatorBtnRefSingle.setDocType(docType);
                        // console.log('[objDocTypeSel.onchange], selected document type: ', docType);
                    }
                });
                if (objDocTypeSel) $(objSearch).append(objDocTypeSel);

                $(objSearch).append(docCreatorBtnRefSingle.elem);

                
                var objTdList = document.createElement("td");
                $(objTdList)
                    .attr("colspan", "2")
                    .addClass('ig-field-search-list-wrap')
                    .appendTo(destinationTBody)
                    .wrap("<tr></tr>");
                
                
                var objDivList = document.createElement("div");
                $(objDivList)
                    .addClass('ig-infoobjects-list')
                    .height(250) //FIXME: better calculation of height!
                    .appendTo(objTdList);

                /*$(window).resize(function() {
                    $(objDivList).height(fnGetAvailableHeight(objDivList));
                });*/


                IGObjectList.config.parentObject = objDivList;


                function onSingleRefChooseClick(objectAbsID) {
                    var prevVal = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = objectAbsID;
                    iObject.itemHasChanged = true;

                    renderChosenReference({
                        id: objectAbsID
                    });

                    if (prevVal !== null && typeof prevVal !== 'undefined') {
                        var objList = $('#'+IGObjectList.id);
                        var objItemRow = objList.find('.ITEM_'+prevVal.id);
                        if (objItemRow[0]) {
                            var objItemBtns = objItemRow.find('td.buttons-add div');
                            if (objItemBtns[0]) {
                                renderAddSingleReferenceBtn(prevVal, objItemBtns[0]);
                            }
                        }
                    }
                }
                

                let renderAddSingleReferenceBtn = (objectAbsID, objContainer) => {

                    $(objContainer).html('');

                    var objEm = document.createElement('em');
                    $(objEm).addClass('ellipsis ig-list-item-message');
                    var oldValue = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];

                    if (oldValue === null || typeof oldValue === 'undefined' || (typeof oldValue !== 'undefined' && oldValue.id !== objectAbsID.id)) {
                        var objBtn = document.createElement("a");
                        $(objBtn)
                            .addClass("btn-small btn-light")
                            .html("<span>"+IG.label.get('TYPE_REFERENCED_CHOOSE_DOCUMENT')+"</span>")
                            .click(function() {
                                onSingleRefChooseClick(objectAbsID);
                                $(objEm)
                                    .attr('title', IG.label.get('LIST_ITEM_CHOSEN'))
                                    .text(IG.label.get('LIST_ITEM_CHOSEN'));
                                $(objContainer).html(objEm);
                            })
                            .appendTo(objContainer);
                    } else {
                        $(objEm)
                            .attr('title', IG.label.get('LIST_ITEM_CHOSEN'))
                            .text(IG.label.get('LIST_ITEM_CHOSEN'));
                        $(objContainer).html(objEm);
                    }
                }


                IGObjectList.config.columns = [
                   {
                       name: "buttons",
                       title: "",
                       className: "ellipsis buttons-add",
                       sortable: false,
                       width: "70px",
                       render: function(object) {
                           var objContainer = document.createElement('div');
                           renderAddSingleReferenceBtn(object.id, objContainer);
                           return objContainer;
                       }
                   },
                   {
                       name: "headline",
                       title: IG.label.get("info_object_list_title"),
                       className: "title ellipsis",
                       sortable: true,
                       width: "auto",
                       renderTitle: function(object) {
                           var s = "...";
                           if (typeof object.headline != "undefined") {
                               s = IG.fn.stripHTML(object.headline[currentLanguage]);
                           }
                           return s;
                       },
                       render: function(object) {
                           var s = "...";
                           if (typeof object.headline != "undefined") {
                               s = IG.fn.stripHTML(object.headline[_this.searchLang]);
                           }

                           var objA = document.createElement("a");
                           $(objA)
                               .text(s)
                               .attr("title", s)
                               .click(function() {
                                   if (IG.infoObjectChanged()) {
                                       IG.showInfoObjectChangedDialog({
                                           confirm: function() {
                                               IG.showEditInfoObject({
                                                   id: object.id["id"],
                                                   history: true,
                                                   searchLang: _this.searchLang
                                               });
                                           }
                                       });
                                   } else {
                                       IG.showEditInfoObject({
                                           id: object.id["id"],
                                           history: true,
                                           searchLang: _this.searchLang
                                       });
                                   }

                               });
                           return objA;
                       }
                   },
                   {
                       name: "infoObjectType",
                       title: IG.label.get("info_object_list_type"),
                       className: "type ellipsis",
                       sortable: true,
                       width: "85px",
                       tip: {
                           overflow: "none",
                           content: fnTypeTipContent()
                       },
                       render: function(object) {
                           var s = IG.label.get("info_object_list_" + object.type + "_info_object");
                           if (s == "info_object_list_" + object.type + "_info_object") {
                               var obj = IG.getDocumentType(object.type);
                               if (obj !== null) {
                                   s = (typeof obj.name[currentLanguage] !== 'undefined'
                                       && obj.name[currentLanguage] !== null
                                       ? obj.name[currentLanguage]
                                       : IG.label.get('doc_type_unknown_translation'));
                               }
                           }
                           return s;
                       },
                       renderTitle: function(object) {
                           var s = IG.label.get("info_object_list_" + object.type + "_info_object");
                           if (s == "info_object_list_" + object.type + "_info_object") {
                               var obj = IG.getDocumentType(object.type);
                               if (obj !== null) {
                                   s = (typeof obj.name[currentLanguage] !== 'undefined'
                                       && obj.name[currentLanguage] !== null
                                       ? obj.name[currentLanguage]
                                       : IG.label.get('doc_type_unknown_translation'));
                               }
                           }
                           return s;
                       }
                   },
                   {
                       name: "lastModified",
                       title: IG.label.get("info_object_list_last_modified"),
                       className: "",
                       sortable: true,
                       width: "150px",
                       render: function(object) {
                           var s = "...";
                           if (typeof object.lastModified != "undefined") {
                               s = IG.fn.formatTime(object.lastModified);
                           }

                           return s;
                       }
                   },
                   {
                       name: "displayStartTime",
                       title: IG.label.get("info_object_list_displayed_from"),
                       className: "",
                       sortable: true,
                       width: "150px",
                       render: function(object) {
                           var s = "...";
                           if (typeof object.displayStartTime != "undefined") {
                               s = IG.fn.formatTime(object.displayStartTime);
                           }

                           return s;
                       }
                   },
                   {
                       name: "published",
                       title: IG.label.get("info_object_list_status"),
                       className: "status",
                       sortable: true,
                       width: "90px",
                       tip: {
                           maxheight: "300px",
                           overflow: "auto",
                           content: fnStatusTipContent()
                       },
                       render: function(object) {
                           var objStatus = IG.fn.getInfoObjectStatus(object);
                           var objSpan = document.createElement("span");
                           $(objSpan)
                               .addClass("status " + objStatus.statusClass)
                               .text(objStatus.caption)
                               .attr("title", objStatus.caption);

                           return objSpan;
                       }
                   },
                   {
                       name: "editor",
                       title: IG.label.get("info_object_list_editor"),
                       className: "editor ellipsis",
                       sortable: false,
                       width: "110px",
                       tip: {
                           maxheight: "300px",
                           overflow: "auto",
                           content: fnEditorTipContent()
                       },
                       renderTitle: function(object) {
                           var objEditor = { name: "" };
                           if (object.editor) {
                               objEditor = IG.getUserById(object.editor["id"]);
                           }
                           if (objEditor === null) {
                               return IG.label.get('GLOBAL_MISSING_EDITOR_NAME');
                           }
                           return objEditor.name;
                       },
                       render: function(object) {
                           var objEditor = { name: "" };
                           if (object.editor) {
                               objEditor = IG.getUserById(object.editor["id"]);
                           }
                           var objSpan = document.createElement("span");
                           $(objSpan)
                               .css("white-space", "nowrap")   //this is the ie 7 workarround (nowrap in td)
                               .append(objEditor === null ? IG.label.get('GLOBAL_MISSING_EDITOR_NAME') : objEditor.name);
                           return $(objSpan);
                       }
                   }];
                
                
                IGObjectList.refresh();

                var fieldObjTypedRef = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        var currentRefId = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language];

                        if (typeof currentRefId === 'undefined' || currentRefId === false) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }

                        if (typeof currentRefId !== 'undefined' && currentRefId !== null) {
                            renderChosenReference({
                                id: currentRefId
                            });
                        } else {
                            $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');
                        }

                        IGObjectList.refresh();
                    }
                });
                igFields.push(fieldObjTypedRef);
                
                
                break; // END: "typed-reference"








	        	
	        	
            case "date":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                    || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                    || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }


	        	
	        	var objTR = document.createElement("tr");
	            $(objTR).addClass('tr-input-row').appendTo(destinationTBody);
	
	            var objTDlbl = document.createElement("td");
	            $(objTDlbl)
	                .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
	                .html('<label>' + field.label[IG.config.language] + '</label>')
	                .appendTo(objTR);
	
	            var objTDinp = document.createElement("td");
	            $(objTDinp)
                    .addClass('td-input td-pos-relative')
                    .appendTo(objTR);



                var dateMoment = moment(); // For displaying: dateMoment.format('LL')
                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]) {
                    dateMoment = moment(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage], 'YYYY-MM-DD');
                } else {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = dateMoment.format('YYYY-MM-DD');
                }

                var objDateVisible = document.createElement('input');
                $(objDateVisible)
                    .attr('readonly', 'readonly')
                    .addClass("datepicker")
                    .addClass("date")
                    .val( dateMoment.format('LL') )
                    .appendTo(objTDinp)
                    .click(function(e) {
                        e.preventDefault();

                        $(dateInput).datepicker( "show" );
                    });


	        	

	        	var dateInput = document.createElement("input");
	            $(dateInput)
	    	        .addClass("date")
	    	        .addClass("datepicker")
	    	        .addClass("date-hidden")
	    	        .attr({
	    	            "type": "text",
	    	            "id": "field-"+field.fieldName,
	    	            "readonly": "readonly",
	    	            "value": dateMoment.format('YYYY-MM-DD') // IG.fn.formatTime(0)
	    	        })
	    	        .appendTo(objTDinp)
	            	.datepicker({
	            		dateFormat: 'yy-mm-dd', // IG.dateFormatJQuery[IG.config.language],
	            		changeMonth: true,
	                    changeYear: true,
	                    firstDay: 1,
	                    showOtherMonths: true,
	                    yearRange: "c-20:c+20",
	                    prevText: "<",
	                    nextText: ">",
	                    dayNamesMin: IG.dayNamesShort[IG.config.language],
	                    dayNames: IG.dayNames[IG.config.language],
	                    dayNamesShort: IG.dayNamesShort[IG.config.language],
	                    monthNames: IG.monthNames[IG.config.language],
	                    monthNamesShort: IG.monthNamesShort[IG.config.language],
	                    defaultDate: dateMoment.format('YYYY-MM-DD'), // initialDateString,
                        beforeShow: function(input, inst) {
                            $(inst.dpDiv).addClass('datepicker-date-only');
                        },
                        onSelect: function(dateText, inst) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = dateText;
                            iObject.itemHasChanged = true;
                            dateMoment = moment(dateText, 'YYYY-MM-DD');
                            $(objDateVisible).val( dateMoment.format('LL') );
                        }
	            	});


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldObjDate = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }

                        var currentDate = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language];
                        if (currentDate !== null && moment(currentDate).isValid()) {
                            dateMoment = moment(currentDate, 'YYYY-MM-DD');
                        } else {
                            dateMoment = moment(); // default to now
                        }
                        $(dateInput).datepicker('setDate', dateMoment.toDate());
                        $(objDateVisible).val( dateMoment.format('LL') );
                    }
                });
                igFields.push(fieldObjDate);
	            

	            break;















            /**
             * list-of-typed-reference
             */
            case "list-of-typed-reference":

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = [];
                }



                var _this = this;
                this.query = "";
                this.searchLang = IG.customerConfig.defaultContentLanguage;
                this.defaultSearchVal = IG.label.get("info_object_list_enter_search_term");

                //Determine available height for list:
                var fnGetAvailableHeight = function(obj) {
                    var h = $(window).height() - $(obj).offset().top - 36; //ish...
                    if (h < 200) {
                        h = 200;
                    }
                    return h;
                };

                var fnStatusTipContent = function() {
                    var k = "publishState";

                    var objDiv = document.createElement("div");

                    var statuses = IG.fn.getInfoObjectStatuses();
                    $.each(statuses, function(i, s) {
                        var bFound = IGObjectList.getFilter(k) == s.filter;

                        var objA = document.createElement("a");
                        if (bFound) {
                            $(objA).addClass("active");
                        }

                        $(objA)
                            .html('<span>' + s.caption + '</span>')
                            .click(function() {
                                if ($(this).hasClass("active")) {
                                    $(this).removeClass("active");
                                    IGObjectList.removeFilter(k, s.filter);
                                }
                                else {
                                    $(this).addClass("active");
                                    $(this).siblings("a").removeClass("active");
                                    IGObjectList.setFilter(k, s.filter);
                                }
                                IGObjectList.refresh();
                            })
                            .appendTo(objDiv);
                    });



                    return $(objDiv);
                };

                //Render editor tip contents:
                var fnEditorTipContent = function() {
                    var k = "owners";

                    var objDiv = document.createElement("div");

                    $.each(IG.lists.users, function(i, n) {
                        var bFound = IGObjectList.hasIdFilter(k, n.id);

                        var objA = document.createElement("a");
                        if (bFound) {
                            $(objA).addClass("active");
                        }

                        $(objA)
                            .html('<span>' + n.name + '</span>')
                            .click(function() {
                                if ($(this).hasClass("active")) {
                                    $(this).removeClass("active");
                                    IGObjectList.removeIdFilter(k, n.id);
                                }
                                else {
                                    $(this).addClass("active");
                                    IGObjectList.addIdFilter(k, n.id);
                                }
                                IGObjectList.refresh();
                            })
                            .appendTo(objDiv);

                    });

                    return objDiv;
                };

                //Render type tip contents:
                var fnTypeTipContent = function() {
                    var k = "infoObjectType";
                    var currentFilter = IGObjectList.getFilter(k);

                    var objDiv = document.createElement("div");

                    $.each(field.referencedDocTypes, function(key, dt) {
                        var objA = document.createElement("a");
                        if (currentFilter == dt) {
                            $(objA).addClass("active");
                        }
                        $(objA)
                            .html('<span>' + IG.getDocumentTypeName(dt) + '</span>')
                            .click(function() {
                                if ($(this).hasClass("active")) {
                                    /* Don't allow removing a document type filter
                                    $(this).removeClass("active");
                                    IGObjectList.removeFilter(k); */
                                }else {
                                    $(this).addClass("active");
                                    $(this).siblings("a").removeClass("active");
                                    IGObjectList.setFilter(k, dt);
                                }
                                IGObjectList.refresh();
                            })
                            .appendTo(objDiv);
                    });
                    return objDiv;
                };


                var objTrLabel = document.createElement("tr");
                $(objTrLabel)
                    .appendTo(destinationTBody);

                var objTdLabel = document.createElement("td");
                $(objTdLabel)
                    .attr("colspan", "2")
                    .html('<h3 class="ig-title">'+field.label[IG.config.language]+"</h3>")
                    .appendTo(objTrLabel);

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(objTdLabel).append(createDescription(field.description[IG.config.language], 'p'));
                }


                var objTrChosen = document.createElement("tr");
                $(objTrChosen)
                    .addClass('ig-list-of-typed-ref-chosen-wrap')
                    .appendTo(destinationTBody);

                var objTdChosen = document.createElement("td");
                $(objTdChosen)
                    .attr("colspan", "2")
                    .appendTo(objTrChosen);

                var objDivChosenDocument = document.createElement("div");
                $(objDivChosenDocument)
                    .addClass('ig-list-of-typed-ref-chosen-div')
                    .sortable({
                        handle: ".ig-list-drag-handle",
                        containment: 'parent',
                        axis: "y",
                        tolerance: "pointer",
                        update: function(event, ui) {
                            updateInternalRefList();
                        }
                    })
                    .appendTo(objTdChosen);



                var updateInternalRefList = function() {
                    var refRows = $(objDivChosenDocument).find('.ig-typed-ref-row');
                    var orderedIds = [],
                        idString = null,
                        id = null;
                    if (refRows) {
                        $.each(refRows, function(i, row) {
                            idString = $(row).attr('data-id');
                            id = IG.getIdFromString(idString)
                            if (id !== null) {
                                orderedIds.push(id);
                            }
                        });
                    }
                    if (orderedIds.length > 0) {
                        tempReferences.reorder(orderedIds);
                    }
                };

                //Create ObjectList:
                var IGObjectList = new IG.ObjectList({
                    methodName: "searchInfoObjects",
                    subelements: true,
                    filters: {
                        parentsOnly: true
                    },
                    sorting: 'lastModified',
                    order: 'DESC'
                });

                IGObjectList.setDocumentType(field.referencedDocTypes);


                var objTrSearch = document.createElement("td");
                $(objTrSearch)
                    .attr("colspan", "2")
                    .addClass('no-padding')
                    .appendTo(destinationTBody)
                    .wrap("<tr></tr>");

                var objSearchWrap = document.createElement("div");
                $(objSearchWrap)
                    /*.css({
                     "padding": "10px",
                     "float": "left",
                     "display": "block",
                     "width": "100%",
                     "height": "100px"
                     })*/
                    .appendTo(objTrSearch);

                var objLabel = document.createElement("h3");
                $(objLabel)
                    .text(IG.label.get('global_search'))
                    .addClass("ig-title")
                    .appendTo(objSearchWrap);

                //SEARCHBAR :
                var objSearch = document.createElement("div");
                $(objSearch)
                    .addClass("ig-search  ig-search-tall clearfix")
                    .attr("id", "ig-infoobjects-search")
                    .appendTo(objSearchWrap);

                var displayVal = (_this.query != '' ? _this.query : _this.defaultSearchVal);
                var searchLang = _this.searchLang;

                var objSearchInput = document.createElement("input");
                $(objSearchInput)
                    .attr({
                        "type": "text",
                        "name": "search-infoobjects",
                        "id": "search-infoobjects-"+field.fieldName,
                        "value": displayVal
                    })
                    .addClass("search")
                    .click(function() {
                        if (_this.defaultSearchVal == $(this).val()) $(this).val('');
                        //some decoration stuff
                        $(this).removeClass('search-default-deco');
                        $(this).addClass('search-default');
                    })
                    .blur(function() {
                        if ($(this).val() == '') $(this).val(_this.defaultSearchVal);
                        $(this).removeClass('search-default');
                        $(this).addClass('search-default-deco');
                    })
                    .keyup(function(e) {
                        var v = $(this).val();
                        var key = e.which ? e.which : e.keyCode;
                        if (key == 13) {
                            _this.query = (v != _this.defaultSearchVal ? v : '');
                            _this.searchLang = searchLang;
                            if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                                $(objLangSel).addClass('search-lang-deco');
                            } else $(objLangSel).removeClass('search-lang-deco');
                            IGObjectList.setQuery(_this.query, _this.searchLang);
                            IGObjectList.refresh();
                        }
                    })
                    .appendTo(objSearch);



                var objLangSel = IGObjectList.renderSearchLanguageSelector({
                    onChange: function(n) {
                        searchLang = n;
                    },
                    searchLang: searchLang
                });
                if (objLangSel) $(objSearch).append(objLangSel);



                // Render button for creating documents from within the current document
                var docCreatorBtnRefList = new IGObjectList.renderDocumentCreator({
                    docTypes: field.referencedDocTypes,
                    onCreate: function(newDoc) {
                        IG.documentTitleCache.set(newDoc.id, newDoc.headline); // Add the headline to the cache to avoid searching
                        tempReferences.add(newDoc.id, function(result) {
                            if (result) {
                                populateRefList();
                                IGObjectList.refresh();
                            }
                        });
                    }
                });

                var objDocTypeSel = IGObjectList.renderDocumentTypeSelector({
                    docTypes: field.referencedDocTypes,
                    onChange: function(docType) {
                        IGObjectList.refresh();
                        docCreatorBtnRefList.setDocType(docType);
                        // console.log('[objDocTypeSel.onchange], selected document type: ', docType);
                    }
                });
                if (objDocTypeSel) $(objSearch).append(objDocTypeSel);

                $(objSearch).append(docCreatorBtnRefList.elem);


                var objTdListWrap = document.createElement("tr");
                $(objTdListWrap)
                    .appendTo(destinationTBody);

                var objTdList = document.createElement("td");
                $(objTdList)
                    .addClass('ig-field-search-list-wrap')
                    .attr("colspan", "2")
                    .appendTo(objTdListWrap);


                var objDivList = document.createElement("div");
                $(objDivList)
                    .addClass('ig-infoobjects-list')
                    .height(250) //FIXME: better calculation of height!
                    .appendTo(objTdList);

                var objDivForceWidth = document.createElement('div');
                $(objDivForceWidth)
                    .addClass('list-force-with')
                    .appendTo(objDivList);


                var blacklist = {
                    data: {},
                    add: function(id) {
                        if (typeof this.data[id] === 'undefined') {
                            this.data[id] = id;
                        }
                    },
                    remove: function(id) {
                        if (typeof this.data[id] !== 'undefined') {
                            delete this.data[id];
                        }
                    },
                    exists: function(id) {
                        return (typeof this.data[id] !== 'undefined');
                    },
                    empty: function() {
                        this.data = {};
                    }
                };

                var tempReferences = {
                    refArray: [],
                    refObj: {},
                    tempBL: [],
                    addAll: function(allRefs) {
                        var _me = this;
                        _me.refArray = [];
                        _me.refObj = {};
                        _me.tempBL = [];

                        $.each(allRefs, function(i, refId) {
                            _me.refObj[refId.id] = refId;
                            _me.refArray.push(refId);
                            blacklist.add(refId.id);
                        });
                    },
                    add: function(refId, callback) {
                        var _me = this;
                        if (typeof this.refObj[refId.id] === 'undefined') {
                            _me.refObj[refId.id] = refId;
                            _me.refArray.push(refId);
                            iObject.itemHasChanged = true;
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = _me.refArray;

                            _me.tempBL = [];
                            $.each(_me.refArray, function(i, id) {
                                blacklist.add(id.id);
                            });
                            callback(true);
                        } else {
                            callback(false);
                        }
                    },
                    remove: function(refId) {
                        var _me = this;
                        delete this.refObj[refId.id];
                        blacklist.remove(refId.id);
                        $.each(this.refArray, function(i, searchRefId) {
                            if (IG.IdsEqual(refId,searchRefId)) {
                                _me.refArray.splice(i, 1);
                                return false;
                            }
                        });
                        iObject.itemHasChanged = true;
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = this.refArray;
                        _me.tempBL = [];
                        $.each(_me.refArray, function(i, id) {
                            blacklist.add(id.id);
                        });
                        IGObjectList.refresh();
                    },
                    getArray: function() {
                        return this.refArray;
                    },
                    reorder: function(newListOrdered) {
                        this.refArray = newListOrdered;
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = this.refArray;
                    }
                };

                var fetchEntryRowTitle = function(docId, callback) {
                    var translatedTitle = '<em>...</em>';

                    var cachedTitle = IG.documentTitleCache.get(docId);
                    if (cachedTitle) {
                        if (typeof cachedTitle[_this.searchLang] !== 'undefined') {
                            translatedTitle = cachedTitle[_this.searchLang];
                        }
                        callback(translatedTitle);
                    } else {
                        callback('<em>' + IG.label.get('INFO_OBJECT_LIST_SEARCHING_FOR_TITLE') + ' ...</em>');
                        IG.documentTitleCache.registrer(docId.id, function(titleObj) {
                            var titleTranslated = titleObj[_this.searchLang];
                            if (typeof titleTranslated === 'undefined') {
                                titleTranslated = IG.label.get('INFOOBJECT_HEADLINE_NOT_TRANSLATED');
                            }
                            callback(titleTranslated);
                        });
                    }
                };

                var createRefEntryRow = function(docId, objRow, callback) {

                    fetchEntryRowTitle(docId, function(title) {
                        if (title !== false) {
                            objRow
                                .addClass('ig-typed-ref-row')
                                .attr('data-id', docId["customerId"]+'::'+docId["id"])
                                .text('');

                            var objDragHandle = document.createElement('div');
                            $(objDragHandle)
                                .addClass('ig-list-drag-handle ig-ref-list-drag-handle')
                                .appendTo(objRow);

                            var objA = document.createElement("a");
                            $(objA)
                                .html(title)
                                .attr("title", title)
                                .addClass('ig-typed-ref-row-headline')
                                .click(function() {

                                    if (IG.infoObjectChanged()) {
                                        IG.showInfoObjectChangedDialog({
                                            confirm: function() {
                                                IG.showEditInfoObject({
                                                    id: docId["id"],
                                                    history: true,
                                                    searchLang: _this.searchLang
                                                });
                                            }
                                        });
                                    } else {
                                        IG.showEditInfoObject({
                                            id: docId["id"],
                                            history: true,
                                            searchLang: _this.searchLang
                                        });
                                    }
                                })
                                .appendTo(objRow);
                            // $(objDivChosenDocument).append(objA);

                            var elemBtnRemoveEntry = document.createElement("a");
                            $(elemBtnRemoveEntry)
                                .addClass("btn-small btn-dark ig-typed-ref-rm-btn")
                                .html("<span>"+IG.label.get('global_remove')+"</span>")
                                .click(function(event) {
                                    objRow.hide(200, function() {
                                        objRow.remove();
                                        tempReferences.remove(docId);
                                        if (tempReferences.getArray().length === 0) {
                                            $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');
                                        }
                                    });


                                })
                                .appendTo(objRow);

                            callback(objRow);
                        } else {
                            // Remove the references document from the list
                            tempReferences.remove(docId);
                            iObject.itemHasChanged = true;
                        }


                    });
                };

                var populateRefList = function() {
                    var list = tempReferences.getArray();

                    $(objDivChosenDocument).empty();
                    if (list.length === 0) {
                        $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');
                    } else {
                        $.each(list, function(i, docRefId) {
                            var objRow = document.createElement('div');
                            var objRowRef = $(objRow);
                            objRowRef
                                .addClass('ig-typed-ref-row')
                                .text('...');
                            $(objDivChosenDocument).append(objRowRef);

                            createRefEntryRow(docRefId, objRowRef, function(entryRow) {});
                        });

                        setTimeout(function(){
                            if (tempReferences.getArray().length === 0) {
                                $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');
                            }
                        }, 5000);
                    }
                };

                var renderAddReferenceBtn = function(object, objContainer) {
                    $(objContainer).html('');

                    var objEm = document.createElement('em');
                    $(objEm)
                        .addClass('ellipsis ig-list-item-message');

                    if (!blacklist.exists(object.id.id)) {
                        var objBtn = document.createElement("a");
                        $(objBtn)
                            .addClass("btn-small btn-light")
                            .html("<span>"+IG.label.get('global_add')+"</span>")
                            .click(function(event) {
                                tempReferences.add(object.id, function(result) {
                                    if (result) {
                                        populateRefList();

                                        $(objEm)
                                            .attr('title', IG.label.get('LIST_ITEM_ADDED'))
                                            .text(IG.label.get('LIST_ITEM_ADDED'));
                                        $(objContainer)
                                            .html(objEm);
                                    }
                                });

                            }).appendTo(objContainer);
                    } else {
                        $(objEm)
                            .attr('title', IG.label.get('LIST_ITEM_ADDED'))
                            .text(IG.label.get('LIST_ITEM_ADDED'));
                        $(objContainer)
                            .html(objEm);
                    }
                };

                // Initially populate the currently chosen:
                tempReferences.addAll(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]);
                window.setTimeout(function() {
                    populateRefList();

                    // Delay the title search a bit to let other fields fields registrer for title updates
                    window.setTimeout(function() {
                        IG.documentTitleCache.triggerSearch(); // make sure the list is populated with titles
                    }, 10);
                }, 10);

                IGObjectList.config.parentObject = objDivList;


                IGObjectList.config.columns = [
                    {
                        name: "buttons",
                        title: "",
                        className: "ellipsis buttons-add",
                        sortable: false,
                        width: "70px",
                        render: function(object) {
                            var objContainer = document.createElement('div');
                            renderAddReferenceBtn(object, objContainer);
                            return objContainer;
                        }
                    },
                    {
                        name: "headline",
                        title: IG.label.get("info_object_list_title"),
                        className: "title ellipsis",
                        sortable: true,
                        width: "auto",
                        renderTitle: function(object) {
                            var s = "...";
                            if (typeof object.headline != "undefined") {
                                s = IG.fn.stripHTML(object.headline[currentLanguage]);
                            }
                            return s;
                        },
                        render: function(object) {
                            var s = "...";
                            if (typeof object.headline != "undefined") {
                                s = IG.fn.stripHTML(object.headline[_this.searchLang]);
                            }

                            var objA = document.createElement("a");
                            $(objA)
                                .text(s)
                                .attr("title", s)
                                .click(function() {
                                    if (IG.infoObjectChanged()) {
                                        IG.showInfoObjectChangedDialog({
                                            confirm: function() {
                                                IG.showEditInfoObject({
                                                    id: object.id["id"],
                                                    history: true,
                                                    searchLang: _this.searchLang
                                                });
                                            }
                                        });
                                    } else {
                                        IG.showEditInfoObject({
                                            id: object.id["id"],
                                            history: true,
                                            searchLang: _this.searchLang
                                        });
                                    }

                                });
                            return objA;
                        }
                    },
                    {
                        name: "infoObjectType",
                        title: IG.label.get("info_object_list_type"),
                        className: "type ellipsis",
                        sortable: true,
                        width: "85px",
                        tip: {
                            overflow: "none",
                            content: fnTypeTipContent()
                        },
                        render: function(object) {
                            var s = IG.label.get("info_object_list_" + object.type + "_info_object");
                            if (s == "info_object_list_" + object.type + "_info_object") {
                                var obj = IG.getDocumentType(object.type);
                                if (obj !== null) {
                                    s = (typeof obj.name[currentLanguage] !== 'undefined'
                                        && obj.name[currentLanguage] !== null
                                        ? obj.name[currentLanguage]
                                        : IG.label.get('doc_type_unknown_translation'));
                                }
                            }
                            return s;
                        },
                        renderTitle: function(object) {
                            var s = IG.label.get("info_object_list_" + object.type + "_info_object");
                            if (s == "info_object_list_" + object.type + "_info_object") {
                                var obj = IG.getDocumentType(object.type);
                                if (obj !== null) {
                                    s = (typeof obj.name[currentLanguage] !== 'undefined'
                                        && obj.name[currentLanguage] !== null
                                        ? obj.name[currentLanguage]
                                        : IG.label.get('doc_type_unknown_translation'));
                                }
                            }
                            return s;
                        }
                    },
                    {
                        name: "lastModified",
                        title: IG.label.get("info_object_list_last_modified"),
                        className: "",
                        sortable: true,
                        width: "150px",
                        render: function(object) {
                            var s = "...";
                            if (typeof object.lastModified != "undefined") {
                                s = IG.fn.formatTime(object.lastModified);
                            }

                            return s;
                        }
                    },
                    {
                        name: "displayStartTime",
                        title: IG.label.get("info_object_list_displayed_from"),
                        className: "",
                        sortable: true,
                        width: "150px",
                        render: function(object) {
                            var s = "...";
                            if (typeof object.displayStartTime != "undefined") {
                                s = IG.fn.formatTime(object.displayStartTime);
                            }

                            return s;
                        }
                    },
                    {
                        name: "published",
                        title: IG.label.get("info_object_list_status"),
                        className: "status",
                        sortable: true,
                        width: "90px",
                        tip: {
                            maxheight: "300px",
                            overflow: "auto",
                            content: fnStatusTipContent()
                        },
                        render: function(object) {
                            var objStatus = IG.fn.getInfoObjectStatus(object);
                            var objSpan = document.createElement("span");
                            $(objSpan)
                                .addClass("status " + objStatus.statusClass)
                                .text(objStatus.caption)
                                .attr("title", objStatus.caption);

                            return objSpan;
                        }
                    },
                    {
                        name: "editor",
                        title: IG.label.get("info_object_list_editor"),
                        className: "editor ellipsis",
                        sortable: false,
                        width: "110px",
                        tip: {
                            maxheight: "300px",
                            overflow: "auto",
                            content: fnEditorTipContent()
                        },
                        renderTitle: function(object) {
                            var objEditor = { name: "" };
                            if (object.editor) {
                                objEditor = IG.getUserById(object.editor["id"]);
                            }
                            if (objEditor === null) {
                                return IG.label.get('GLOBAL_MISSING_EDITOR_NAME');
                            }
                            return objEditor.name;
                        },
                        render: function(object) {
                            var objEditor = { name: "" };
                            if (object.editor) {
                                objEditor = IG.getUserById(object.editor["id"]);
                            }
                            var objSpan = document.createElement("span");
                            $(objSpan)
                                .css("white-space", "nowrap")   //this is the ie 7 workarround (nowrap in td)
                                .append(objEditor === null ? IG.label.get('GLOBAL_MISSING_EDITOR_NAME') : objEditor.name);
                            return $(objSpan);
                        }
                    }];


                IGObjectList.refresh();


                var fieldObjListofTypedRef = new IG.InfoObjectField({

                    setLanguage: function(language) {
                        blacklist.empty();

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = [];
                        }

                        tempReferences.addAll(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]);
                        populateRefList();

                        IGObjectList.refresh();
                    }
                });
                igFields.push(fieldObjListofTypedRef);


	            break;














            case "boolean":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                    || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                    || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = false;
                }

                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label for="field-' + fieldGroup.groupName + '-' + field.fieldName +'">' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);

                var objCheckBox = document.createElement('input');
                $(objCheckBox)
                    .attr('type', 'checkbox')
                    .attr('id', "field-" + fieldGroup.groupName + '-' + field.fieldName)
                    .appendTo(objTDinp);

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === true) {
                    $(objCheckBox).prop('checked', true);
                } else {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = false;
                    $(objCheckBox).prop('checked', false);
                }

                $(objCheckBox).change(function() {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = $(this).prop('checked');
                    iObject.itemHasChanged = true;
                });

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                var fieldObjBool = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = false;
                        }
                        if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === true) {
                            $(objCheckBox).prop('checked', true);
                        } else {
                            $(objCheckBox).prop('checked', false);
                        }
                    }
                });
                igFields.push(fieldObjBool);

                break;



            case "integer-signed":


                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                        || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }

                // Set empty string values to null or try to convert string representations of numbers to Number
                $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName], function(l,val) {
                    if (_.isString(val)) {
                        if (val.length === 0) {
                            val = null;
                        } else {
                            var parsedVal = parseInt(val, 10);
                            if (_.isNumber(parsedVal) && !_.isNaN(parsedVal)) {
                                // There was a number value in the string
                                val = parsedVal;
                            } else {
                                // Otherwise set the value to a valid null
                                val = null;
                            }
                        }
                    }
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][l] = val;
                });

                // Default values
                $.each(IG.customerConfig.contentLanguages, function(langKey) {
                    // The translation does not exist:
                    if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] === 'undefined') {
                        // Check for a default value for the translation. Otherwise init with null.
                        if (typeof field.parameters['default-integers'] !== 'undefined' && typeof field.parameters['default-integers'][langKey] !== 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] = field.parameters['default-integers'][langKey];
                        } else {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] = null;
                        }
                    }
                });


                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label for="field-' + fieldGroup.groupName + '-' + field.fieldName +'">' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .attr('id', "field-" + fieldGroup.groupName + '-' + field.fieldName)
                    .appendTo(objTR);

                var objInput = document.createElement("input");
                $(objInput)
                    .attr({
                        "type": "text"
                    })
                    .val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage])
                    .appendTo(objTDinp)
                    .wrap('<span class="input"></span>');

                // set the default value if it exists and the document is new
                if (iObject.isNew && typeof field.parameters['default-integers'] !== 'undefined'
                        && typeof field.parameters['default-integers'][currentLanguage] !== 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = field.parameters['default-integers'][currentLanguage];
                    $(objInput).val(field.parameters['default-integers'][currentLanguage]);
                }

                $(objInput).change(function(event) {
                    var value = parseInt($(this).val(), 10);
                    if ($(this).val().length > 0 && _.isNumber(value) && !_.isNaN(value)) {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = value;
                        $(this).val(value);
                    } else {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                        $(this).val('');
                    }
                    iObject.itemHasChanged = true;
                });

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldObjIntSigned = new IG.InfoObjectField({
                    // On language change:
                    setLanguage: function(language) {
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }
                        $(objInput).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);

                        // set the default value if it exists and there is no current value
                        if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === null
                                && typeof field.parameters['default-integers'] !== 'undefined'
                                && typeof field.parameters['default-integers'][language] !== 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = field.parameters['default-integers'][language];
                            $(objInput).val(field.parameters['default-integers'][language]);
                        }

                    },
                    // called on update:
                    validateField: function() {
                        return getFieldErrorsByValidationType(
                            'type',
                            null,
                            field,
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName],
                            fieldGroup.name[IG.config.language] + ' - ' + field.label[IG.config.language]
                        );
                    }
                });
                igFields.push(fieldObjIntSigned);

                break;





            case "integer-unsigned":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                    || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                    || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }

                // Set empty string values to null or try to convert string representations of numbers to Number
                $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName], function(l,val) {
                    if (_.isString(val)) {
                        if (val.length === 0) {
                            val = null;
                        } else {
                            var parsedVal = parseInt(val, 10);
                            if (_.isNumber(parsedVal) && !_.isNaN(parsedVal)) {
                                // There was a number value in the string
                                val = parsedVal;
                            } else {
                                // Otherwise set the value to null
                                val = null;
                            }
                        }
                    }
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][l] = val;
                });

                // Default values
                $.each(IG.customerConfig.contentLanguages, function(langKey) {
                    // The translation does not exist:
                    if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] === 'undefined') {
                        // Check for a default value for the translation. Otherwise init with null.
                        if (typeof field.parameters['default-integers-unsigned'] !== 'undefined' && typeof field.parameters['default-integers-unsigned'][langKey] !== 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] = field.parameters['default-integers-unsigned'][langKey];
                        } else {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] = null;
                        }
                    }
                });

                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label for="field-' + fieldGroup.groupName + '-' + field.fieldName +'">' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);

                var objInput = document.createElement("input");
                $(objInput)
                    .attr({
                        "type": "text"
                    })
                    .attr('id', "field-" + fieldGroup.groupName + '-' + field.fieldName)
                    .val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage])
                    .appendTo(objTDinp)
                    .wrap('<span class="input"></span>');



                $(objInput).change(function(event) {
                    var value = parseInt($(this).val(), 10);
                    if ($(this).val().length > 0 && _.isNumber(value) && !_.isNaN(value)) {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = value;
                        $(this).val(value);
                    } else {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                        $(this).val('');
                    }
                    iObject.itemHasChanged = true;
                });

                // set the default value if it exists and the document is new
                if (iObject.isNew && typeof field.parameters['default-integers-unsigned'] !== 'undefined'
                        && typeof field.parameters['default-integers-unsigned'][currentLanguage] !== 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = field.parameters['default-integers-unsigned'][currentLanguage];
                    $(objInput).val(field.parameters['default-integers-unsigned'][currentLanguage]);
                }

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldObjIntUnsigned = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }
                        $(objInput).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);

                        // set the default value if it exists and there is no current value
                        if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === null
                                && typeof field.parameters['default-integers-unsigned'] !== 'undefined'
                                && typeof field.parameters['default-integers-unsigned'][language] !== 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = field.parameters['default-integers-unsigned'][language];
                            $(objInput).val(field.parameters['default-integers-unsigned'][language]);
                        }
                    },

                    // called on update:
                    validateField: function() {
                        return getFieldErrorsByValidationType(
                            'type',
                            null,
                            field,
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName],
                            fieldGroup.name[IG.config.language] + ' - ' + field.label[IG.config.language]
                        );
                    }
                });
                igFields.push(fieldObjIntUnsigned);

                break;




            case "float":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                    || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                    || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }

                // Clean up! Set empty string values to null or try to convert string representations of numbers to Number
                $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName], function(l,val) {
                    if (_.isString(val)) {
                        if (val.length === 0) {
                            val = null;
                        } else {
                            var parsedVal = parseFloat(val);
                            if (_.isNumber(parsedVal) && !_.isNaN(parsedVal)) {
                                // There was a number value in the string
                                val = parsedVal;
                            } else {
                                // Otherwise set the value to null
                                val = null;
                            }
                        }
                    }
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][l] = val;
                });

                // Set default values
                $.each(IG.customerConfig.contentLanguages, function(langKey) {
                    // The translation does not exist:
                    if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] === 'undefined') {
                        // Check for a default value for the translation. Otherwise init with null.
                        if (typeof field.parameters['default-floats'] !== 'undefined' && typeof field.parameters['default-floats'][langKey] !== 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] = field.parameters['default-floats'][langKey];
                        } else {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][langKey] = null;
                        }
                    }
                });

                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label for="field-' + fieldGroup.groupName + '-' + field.fieldName +'">' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);

                var objInput = document.createElement("input");
                $(objInput)
                    .attr({
                        "type": "text"
                    })
                    .attr('id', "field-" + fieldGroup.groupName + '-' + field.fieldName)
                    .addClass('foo-float')
                    .val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage])
                    .appendTo(objTDinp)
                    .wrap('<span class="input"></span>');


                // set the default value if it exists and the document is new
                if (iObject.isNew && typeof field.parameters['default-float'] !== 'undefined') {
                    $(objInput).val(field.parameters['default-float']);
                }

                $(objInput).change(function(event) {
                    var value = parseFloat($(this).val());
                    if ($(this).val().length > 0 && _.isNumber(value) && !_.isNaN(value)) {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = value;
                        $(this).val(value);
                    } else if ($(this).val().length === 0) {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                    } else {
                        // Just set the invalid value for later validation
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = $(this).val();
                    }
                    iObject.itemHasChanged = true;
                });

                // set the default value if it exists and the document is new
                if (iObject.isNew && typeof field.parameters['default-floats'] !== 'undefined'
                        && typeof field.parameters['default-floats'][currentLanguage] !== 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = field.parameters['default-floats'][currentLanguage];
                    $(objInput).val(field.parameters['default-floats'][currentLanguage]);
                }

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldObjFloat = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }
                        $(objInput).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);

                        // set the default value if it exists and there is no current value
                        if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === null
                                && typeof field.parameters['default-floats'] !== 'undefined'
                                && typeof field.parameters['default-floats'][language] !== 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = field.parameters['default-floats'][language];
                            $(objInput).val(field.parameters['default-floats'][language]);
                        }
                    },

                    // called on update:
                    validateField: function() {
                        return getFieldErrorsByValidationType(
                            'type',
                            null,
                            field,
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName],
                            fieldGroup.name[IG.config.language] + ' - ' + field.label[IG.config.language]
                        );
                    }
                });
                igFields.push(fieldObjFloat);

                break;





            case "email-address":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                    || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                    || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }

                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label for="field-' + fieldGroup.groupName + '-' + field.fieldName +'">' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);

                var objInput = document.createElement("input");
                $(objInput)
                    .attr({
                        "type": "text"
                    })
                    .attr('id', "field-" + fieldGroup.groupName + '-' + field.fieldName)
                    .val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage])
                    .appendTo(objTDinp)
                    .wrap('<span class="input"></span>');
                $(objInput).change(function(event) {
                    var value = $(this).val();

                    if (value.length > 0) {
                        $(this).val(value);
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = value;
                    } else {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                    }
                    iObject.itemHasChanged = true;
                });

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldObjEmail = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }
                        $(objInput).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);

                    }
                });
                igFields.push(fieldObjEmail);

                break;






            case "list-of-plain-text":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                        || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = [];
                }

                var timerUpdate = null;
                var getEntryRow = function(value) {
                    value = (typeof value === 'undefined' ? '' : value);

                    var elemDivRow = document.createElement('div');
                    $(elemDivRow)
                        .addClass('ig-plain-text-row');

                    var elemDragHandle = document.createElement('div');
                    $(elemDragHandle)
                        .addClass('ig-list-drag-handle ig-plain-text-drag-handle')
                        .appendTo(elemDivRow);

                    /*var elemSpanInput = document.createElement('span');
                    $(elemSpanInput)
                        .addClass('input-narrow')
                        .appendTo(elemDivRow);*/

                    var elemInput = document.createElement('input');
                    $(elemInput)
                        .attr('type', 'text')
                        .addClass('ig-plain-text-input input-text-small')
                        /*.change(function(e) {
                            window.clearTimeout(timerUpdate);
                            timerUpdate = window.setTimeout(function() {
                                updateInternalList();
                            }, 100);
                        })*/
                        .keyup(function(e) {
                            window.clearTimeout(timerUpdate);
                            timerUpdate = window.setTimeout(function() {
                                updateInternalList();
                            }, 300);
                        })
                        .val(value)
                        .appendTo(elemDivRow);





                    var elemBtnRemoveEntry = document.createElement("a");
                    $(elemBtnRemoveEntry)
                        .addClass("btn-small btn-dark ig-plain-text-btn")
                        .html("<span>"+IG.label.get('global_remove')+"</span>")
                        .click(function(event) {
                            $(elemDivRow).hide(200, function() {
                                $(elemDivRow).remove();
                                updateInternalList();
                            })
                        })
                        .appendTo(elemDivRow);

                    return elemDivRow;
                };

                var populateList = function() {
                    $(elemDivlistWrap).empty();
                    if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].length === 0) {
                        $(elemDivlistWrap).append(getEntryRow());
                    } else {
                        $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage], function(i, str) {
                            $(elemDivlistWrap).append(getEntryRow(str));
                        })
                    }
                };

                var updateInternalList = function() {
                    var inputFields = $(elemDivlistWrap).find('.ig-plain-text-input');
                    var tempList = [];
                    $.each(inputFields, function(i, inpField) {
                        if ($(inpField).val().length > 0) {
                            tempList.push($(inpField).val());
                            iObject.itemHasChanged = true;
                        }
                    });
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = tempList;
                };


                var elemTrListPlainTxt = document.createElement("tr");
                $(elemTrListPlainTxt).addClass('tr-input-row').appendTo(destinationTBody);

                // Label:
                var elemTdListPlainTxt = document.createElement("td");
                $(elemTdListPlainTxt)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(elemTrListPlainTxt);

                // Input:
                var elemTdListPlTxt = document.createElement("td");
                $(elemTdListPlTxt)
                    .addClass('td-input')
                    .appendTo(elemTrListPlainTxt);

                var elemDivlistWrap = document.createElement('div');
                $(elemDivlistWrap)
                    .addClass('ig-plain-text-entries-wrap')
                    .sortable({
                        handle: ".ig-list-drag-handle",
                        containment: 'parent',
                        axis: "y",
                        tolerance: "pointer",
                        update: function(event, ui) {
                            updateInternalList();
                        }
                    })
                    .appendTo(elemTdListPlTxt);


                var elemBtnAddRow = document.createElement("a");
                $(elemBtnAddRow)
                    .addClass("btn-small btn-dark")
                    .html("<span>"+IG.label.get('global_add')+"</span>")
                    .click(function(event) {
                        event.preventDefault();
                        $(elemDivlistWrap).append(getEntryRow());
                    })
                    .appendTo(elemTdListPlTxt);


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                populateList();


                var fieldObjListPlTxt = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = [];
                        }
                        populateList();
                    }
                });
                igFields.push(fieldObjListPlTxt);

                break;









            case "time":

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined'
                        || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === null) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = '00:00:00';
                }


                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);




                var currentTime = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];
                var timeString = '00:00:00';
                var hourChosen = 0;
                var minuteChosen = 0;


                var objTimeWrap = document.createElement('div');
                $(objTimeWrap)
                    .attr('id', "field-" + field.fieldName)
                    .appendTo(objTDinp);

                var clockFormat = IG.clockFormat[IG.customerConfig.defaultContentLanguage];
                var objSelectH = document.createElement('select');
                $(objSelectH)
                    .attr('id', 'time-picker-h')
                    .appendTo(objTimeWrap);
                if (clockFormat == 12) {
                    $(objSelectH).addClass('date-time-picker-12-h');
                }

                var objClockColon = document.createElement('span');
                $(objClockColon)
                    .attr('id', 'time-picker-colon')
                    .text(':')
                    .appendTo(objTimeWrap);

                var objSelectM = document.createElement('select');
                $(objSelectM)
                    .attr('id', 'time-picker-m')
                    .appendTo(objTimeWrap);




                $(objSelectH).change(function(evt) {
                    hourChosen = $(this).val();
                    formatTimeString();
                });
                $(objSelectM).change(function(evt) {
                    minuteChosen = $(this).val();
                    formatTimeString();
                });

                var formatTimeString = function() {
                    timeString = IG.DateTimePicker.pad(hourChosen, 2, 0) + ':' + IG.DateTimePicker.pad(minuteChosen, 2, 0) + ':00';
                    iObject.itemHasChanged = true;
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = timeString;
                };

                var setTimePicker = function(timeStr) {
                    if (timeStr !== null) {
                        var timeSplit = timeStr.split(':');
                        hourChosen = parseInt(timeSplit[0]);
                        minuteChosen = parseInt(timeSplit[1]);
                    }

                    $(objSelectH).empty();
                    $(objSelectM).empty();

                    var clockArr = IG.DateTimePicker.hours[clockFormat];
                    $.each(clockArr, function(i, val) {
                        var selected = "";
                        if (hourChosen === i) {
                            selected = 'selected="selected"';
                        }
                        var eleOpt = $('<option '+selected+'/>', {});
                        eleOpt
                            .val(i)
                            .text(val)
                            .appendTo(objSelectH);
                    });

                    for (var i=0; i<60; i++) {
                        var padVal = IG.DateTimePicker.pad(i, 2, 0);
                        var selected = "";
                        if (minuteChosen === i) {
                            selected = 'selected="selected"';
                        }
                        var eleOpt = $('<option '+selected+'/>', {});
                        eleOpt
                            .val(i)
                            .text(padVal)
                            .appendTo(objSelectM);

                    }
                };

                setTimePicker(currentTime);


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldTime = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = '00:00:00';
                        }
                        setTimePicker(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);
                    }
                });
                igFields.push(fieldTime);

                break;








            case "date-time":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                        || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }


                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);


                var initValueMoment = (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === null
                    ? moment() : moment.unix(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]));

                var inputField = document.createElement("input");
                $(inputField)
                    .attr({
                        "type": "text",
                        "readonly": "readonly"
                    })
                    .val(initValueMoment.format('LLL'))
                    .addClass("date-time")
                    .appendTo(objTDinp);

                $(inputField)
                    .addClass("datepicker")
                    .click(function(event) {

                        var _input = $(this);
                        var displayFormat = 'LLL';
                        var dtp = new IG.DateTimePicker({
                            current: iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage],
                            displayFormat: displayFormat
                        });

                        $( "#modal-window" )
                            .html(dtp.getContent())
                            .dialog({
                                modal: true,
                                title: '',
                                position: { my: "center", at: "center", of: window },
                                width: 300,
                                height: 'auto',
                                buttons: [
                                    {
                                        text: IG.label.get("ok"),
                                        click: function() {
                                            dtp.save(function(UTCtimeStampSecs, formattedStr) {
                                                _input.val(formattedStr);
                                                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = UTCtimeStampSecs;
                                                iObject.itemHasChanged = true;
                                                $( "#modal-window" ).dialog( "close" );
                                            });
                                        }
                                    },
                                    {
                                        text: IG.label.get("cancel"),
                                        click: function() {
                                            $( this ).dialog( "close" );
                                        }
                                    }
                                ],

                                // reset to defaults
                                minWidth: 150,
                                maxWidth: false,
                                minHeight: 150,
                                maxHeight: false
                            });
                    });


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                var fieldDateTime = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }
                        if (_.isNumber(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage])) {
                            initValueMoment = moment.unix(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]);
                        } else {
                            initValueMoment = moment(); // default to now
                        }
                        $(inputField).val( initValueMoment.format('LLL') );

                    }
                });
                igFields.push(fieldDateTime);

                break;



            case "recurring-date-time":
                var recurDateTimeDisplayFormat = 'dddd, LLL'; // "Sunday, September 4 1986 8:30 PM"

                // Does the field even exist?
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                    || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = {
                        dtstart: moment().unix(),
                        rrules: []
                    };
                }

                // Instantiate recurringDate class.
                var recurringDateTime = new IG.RecurringDate({
                    current: iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]
                });

                console.log(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]);


                // Setup of DOM tree for insertion
                var objTrRecDateTime = $('<tr>').addClass('tr-input-row').appendTo(destinationTBody);

                $('<td>')
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTrRecDateTime);

                var objTdInpRecDateTime = $('<td>')
                    .addClass('td-input td-pos-relative')
                    .appendTo(objTrRecDateTime);






                var inputFieldRecDateTime = document.createElement("input");
                $(inputFieldRecDateTime)
                    .attr({
                        "type": "text",
                        "readonly": "readonly"
                    })
                    .val(  moment(recurringDateTime.getDtStart(), 'X').format(recurDateTimeDisplayFormat) )
                    .addClass("date-time")
                    .appendTo(objTdInpRecDateTime);

                $(inputFieldRecDateTime)
                    .addClass("datepicker")
                    .click(function() {

                        var _input = $(this);
                        var dtp = new IG.DateTimePicker({
                            current: recurringDateTime.getDtStart(),
                            displayFormat: recurDateTimeDisplayFormat
                        });

                        $( "#modal-window" )
                            .html(dtp.getContent())
                            .dialog({
                                modal: true,
                                title: '',
                                position: { my: "center", at: "center", of: window },
                                width: 300,
                                height: 'auto',
                                buttons: [
                                    {
                                        text: IG.label.get("ok"),
                                        click: function() {
                                            dtp.save(function(UTCtimeStampSecs, formattedStr, dateMoment) {
                                                _input.val(formattedStr);
                                                iObject.itemHasChanged = true;
                                                $( "#modal-window" ).dialog( "close" );

                                                console.log(UTCtimeStampSecs, formattedStr, dateMoment);


                                                recurringDateTime.setTimestamp( dateMoment.unix() );
                                                recurringDateTime.save(function(description, data) {
                                                    $(inputFieldRecurDateTime).val(description);
                                                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = data;
                                                    iObject.itemHasChanged = true;
                                                });

                                            });
                                        }
                                    },
                                    {
                                        text: IG.label.get("cancel"),
                                        click: function() {
                                            $( this ).dialog( "close" );
                                        }
                                    }
                                ],

                                // reset to defaults
                                minWidth: 150,
                                maxWidth: false,
                                minHeight: 150,
                                maxHeight: false
                            });
                    });



                // Construct Input element for insertion into table data input cell
                var inputFieldRecurDateTime = $('<input>');
                inputFieldRecurDateTime
                    .attr({
                        "type": "text",
                        "readonly": "readonly"
                    })
                    .val(recurringDateTime.describe())
                    .addClass("date-time")
                    .addClass("recurringPicker")
                    .click(function(event) {
                        $( "#modal-window" )
                            .html(recurringDateTime.createUI)
                            .dialog({
                                modal: true,
                                title: '',
                                position: { my: "center", at: "center", of: window },
                                width: 425,
                                height: 'auto',
                                buttons: [
                                    {
                                        text: IG.label.get("ok"),
                                        click: function() {
                                            recurringDateTime.save(function(description, data) {
                                                inputFieldRecurDateTime.val(description);
                                                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = data;
                                                iObject.itemHasChanged = true;
                                                $( "#modal-window" ).dialog( "close" );
                                            });
                                        }
                                    },
                                    {
                                        text: IG.label.get("cancel"),
                                        click: function() {
                                            $( this ).dialog( "close" );
                                        }
                                    }
                                ],

                                // reset to defaults
                                minWidth: 150,
                                maxWidth: false,
                                minHeight: 150,
                                maxHeight: false
                            });
                    })
                    .appendTo(objTdInpRecDateTime);







                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                // Update fields on language change.
                var fieldRecurringDateTime = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = {
                                dtstart: moment().unix(), // now as unix timestamp in seconds
                                rrules: []
                            };
                        }
                        recurringDateTime.updateData(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);
                        var dateMoment = moment(recurringDateTime.getDtStart(), 'X');

                        // $(dateInputRecurDate).val( dateMoment.format("YYYY-MM-DD") );
                        // $(objRecurringDateVisible).val( dateMoment.format(recurDateDisplayFormat) );

                        $(inputFieldRecDateTime).val( dateMoment.format(recurDateTimeDisplayFormat) );
                        $(inputFieldRecurDateTime).val( recurringDateTime.describe() );

                    }
                });
                igFields.push(fieldRecurringDateTime);

                break;




            case "recurring-date":
                var recurDateDisplayFormat = 'dddd, LL'; // "Sunday, September 4 1986"

                // Does the field even exist? How did we get in here if it did not?
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                        || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                // Add a translation for the field if it does not exist
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = {
                        dtstart: moment().unix(),
                        rrules: []
                    };
                }


                // Clean up: older versions required a "recurring" boolean field. This must be removed if present.
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].recurring !== 'undefined') {
                    delete iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].recurring;
                }


                // Instantiate recurringDate class.
                var recurringDate = new IG.RecurringDate({
                    current: iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]
                });


                // Setup of DOM tree for insertion
                var objTR = $('<tr>').addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = $('<td>')
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = $('<td>')
                    .addClass('td-input td-pos-relative')
                    .appendTo(objTR);




                var dateInputRecurDate = document.createElement("input");

                var objRecurringDateVisible = document.createElement('input');
                $(objRecurringDateVisible)
                    .attr('readonly', 'readonly')
                    .addClass("datepicker")
                    .addClass("date")
                    .val( moment(recurringDate.getDtStart(), 'X').format(recurDateDisplayFormat) )
                    .appendTo(objTDinp)
                    .click(function(e) {
                        e.preventDefault();
                        $(dateInputRecurDate).datepicker( "show" );
                    });


                $(dateInputRecurDate)
                    .addClass("date")
                    .addClass("datepicker")
                    .addClass("date-hidden")
                    .attr({
                        "type": "text",
                        "id": "field-"+field.fieldName,
                        "readonly": "readonly",
                        "value": moment(recurringDate.getDtStart(), 'X').format('YYYY-MM-DD') // IG.fn.formatTime(0)
                    })
                    .appendTo(objTDinp)
                    .datepicker({
                        dateFormat: 'yy-mm-dd', // IG.dateFormatJQuery[IG.config.language],
                        changeMonth: true,
                        changeYear: true,
                        firstDay: 1,
                        showOtherMonths: true,
                        yearRange: "c-20:c+20",
                        prevText: "<",
                        nextText: ">",
                        dayNamesMin: IG.dayNamesShort[IG.config.language],
                        dayNames: IG.dayNames[IG.config.language],
                        dayNamesShort: IG.dayNamesShort[IG.config.language],
                        monthNames: IG.monthNames[IG.config.language],
                        monthNamesShort: IG.monthNamesShort[IG.config.language],
                        defaultDate: moment(recurringDate.getDtStart(), 'X').format('YYYY-MM-DD'),
                        beforeShow: function(input, inst) {
                            $(inst.dpDiv).addClass('datepicker-date-only');
                        },
                        onSelect: function(dateText, inst) {
                            // iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = dateText;
                            // iObject.itemHasChanged = true;
                            // dateMoment = moment(dateText, 'YYYY-MM-DD');
                            // $(objRecurringDateVisible).val( dateMoment.format('LL') );


                            var dateMoment = moment(dateText, 'YYYY-MM-DD');
                            $(objRecurringDateVisible).val( dateMoment.format(recurDateDisplayFormat) );
                            recurringDate.setTimestamp( dateMoment.unix() );
                            recurringDate.save(function(description, data) {
                                $(inputFieldRecurDate).val(description);
                                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = data;
                                iObject.itemHasChanged = true;
                            });
                        }
                    });





                // Construct Input element for insertion into table data input cell
                var inputFieldRecurDate = $('<input>');
                inputFieldRecurDate
                    .attr({
                        "type": "text",
                        "readonly": "readonly"
                    })
                    .val(recurringDate.describe())
                    .addClass("date-time")
                    .addClass("recurringPicker")
                    .click(function(event) {
                        $( "#modal-window" )
                            .html(recurringDate.createUI)
                            .dialog({
                                modal: true,
                                title: '',
                                position: { my: "center", at: "center", of: window },
                                width: 425,
                                height: 'auto',
                                buttons: [
                                    {
                                        text: IG.label.get("ok"),
                                        click: function() {
                                            recurringDate.save(function(description, data) {
                                                inputFieldRecurDate.val(description);
                                                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = data;
                                                iObject.itemHasChanged = true;
                                                $( "#modal-window" ).dialog( "close" );
                                            });
                                        }
                                    },
                                    {
                                        text: IG.label.get("cancel"),
                                        click: function() {
                                            $( this ).dialog( "close" );
                                        }
                                    }
                                ],

                                // reset to defaults
                                minWidth: 150,
                                maxWidth: false,
                                minHeight: 150,
                                maxHeight: false
                            });
                        })
                    .appendTo(objTDinp);


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                // Update fields on language change.
                var fieldRecurringDate = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = {
                                dtstart: moment().unix(), // now as unix timestamp in seconds
                                rrules: []
                            };
                        }
                        recurringDate.updateData(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);
                        var dateMoment = moment(recurringDate.getDtStart(), 'X');

                        $(dateInputRecurDate).val( dateMoment.format("YYYY-MM-DD") );
                        $(objRecurringDateVisible).val( dateMoment.format(recurDateDisplayFormat) );
                        $(inputFieldRecurDate).val( recurringDate.describe() );

                    }
                });
                igFields.push(fieldRecurringDate);

                break;








            case "media-reference":

                var mediaArchiveSingle = null;

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }


                var objTR = document.createElement("tr");
                $(objTR).addClass('tr-input-row').appendTo(destinationTBody);

                var objTDlbl = document.createElement("td");
                $(objTDlbl)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTR);

                var objTDinp = document.createElement("td");
                $(objTDinp)
                    .addClass('td-input')
                    .appendTo(objTR);


                var elemThumbWrap = document.createElement('div');
                $(elemThumbWrap)
                    .addClass('ig-media-single-ref-wrap')
                    .appendTo(objTDinp);


                var elemThumbContainer = document.createElement('div');
                $(elemThumbContainer)
                    .addClass('ig-media-single-ref-thumb ig-media-list-of-ref-wrap')
                    .appendTo(elemThumbWrap)
                    .click(function() {
                        if (!mediaArchiveShown) {
                            mediaArchiveShown = true;
                            $(elemBtnChoose).trigger("click");
                        }
                    });



                var elemTitleAddSingleMedia = document.createElement('div');
                $(elemTitleAddSingleMedia)
                    .addClass('media-title media-title-single')
                    .html(IG.label.get("media_click_to_add") + ' <span class="pink">+</span>')
                    .appendTo(elemThumbContainer);


                var loadMedium = function(mediumId, callback)  {

                    var cachedMedium = IG.mediaCache.get(mediumId);
                    if (cachedMedium)  {
                        if ($.isFunction(callback)) {
                            callback(cachedMedium);
                        }
                    } else {
                        IG.request({
                            method: "searchMedia",
                            params: [IG.config.language, currentLanguage, "", true, { ids: [mediumId] }, {}, 0, 0],
                            success: function(response) {
                                if (response.result) {
                                    if (response.data.count === 1) {
                                        var responseData = response.data["data"][0];
                                        if (typeof responseData !== 'undefined') {
                                            IG.mediaCache.set(responseData);
                                        }
                                        if ($.isFunction(callback)) {
                                            callback(responseData);
                                        }
                                    } else {
                                        // Assume that the medium does not exist any longer
                                        _thisObjectFields.mediumDeleted(mediumId);
                                    }
                                } else {
                                    if ($.isFunction(callback)) {
                                        callback(null);
                                    }
                                }



                            },
                            error: function(err) {
                                if ($.isFunction(callback)) {
                                    callback(null);
                                }
                            }
                        });
                    }
                };


                var renderMediumThumbnail = function() {
                    if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] !== 'undefined'
                            && iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] !== null) {

                        var mediumId = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];

                        loadMedium(mediumId, function(med) {


                            if (med != null) {

                                $(elemThumbContainer).children('.media-div-image-container').remove();

                                var imageContainer = document.createElement("div");
                                $(imageContainer)
                                    .addClass('media-div-image-container')
                                    .appendTo(elemThumbContainer);

                                var imageWrapper = document.createElement("div");
                                $(imageWrapper)
                                    .addClass('media-div-image-wrapper');


                                if (typeof med.id != "undefined") {
                                    $(imageWrapper).data("mediaId", med.id);
                                }

                                if (typeof med.originalMediaFile !== 'undefined') {
                                    var image = document.createElement("img");
                                    var imgLabel = null;
                                    var imgUrl = IG.fn.getMediaThumbnailUrl(med, function(label) {
                                        imgLabel = label;
                                    });
                                    $(image).attr({
                                        'src': imgUrl,
                                        'title' : imgLabel
                                    })
                                        .addClass('thumb-preview-'+med.type)
                                        .addClass( iObject.fn.getMediaThumbnailClass(med) );
                                    $(imageWrapper).append(image);
                                }

                                var caption = document.createElement("span");
                                $(caption).text(med.name[IG.config.language]);

                                // Add qtip popup for media titles
                                $(caption).qtip({

                                    content: {
                                        text: (function () {
                                            var divElm = document.createElement("div");
                                            divElm.innerHTML = "<div id='info-object-popup-" + med.id['id'] + "'>" +
                                                "<div>" + med.name[IG.config.language] + "</div></div>";
                                            return divElm.innerHTML;
                                        })(),
                                        title: false,
                                        button: false
                                    },
                                    position: {
                                        my: 'left middle',
                                        at: 'right middle',
                                        adjust: {
                                            x: -8
                                        }
                                    },
                                    style: {
                                        tip: {
                                            width: 10,
                                            height: 6
                                        },
                                        classes: 'qtip-title',
                                        def: false
                                    }
                                });



                                $(imageWrapper).append(caption);


                                var captionRemove = document.createElement("span");
                                $(captionRemove).text(IG.label.get("global_remove"));
                                $(captionRemove).addClass('hover-btn hover-btn-1');
                                $(captionRemove).click(function() {
                                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                                    $(imageContainer).remove();
                                    iObject.itemHasChanged = true;
                                    return false;
                                });

                                var captionView = document.createElement("span");
                                $(captionView).text(IG.label.get("VIEW_MEDIA"));
                                $(captionView).addClass('hover-btn hover-btn-2');
                                $(captionView).click(function(event) {
                                    event.preventDefault();
                                    if (mediaArchiveSingle !== null) {
                                        mediaArchiveSingle.show(med);
                                    }
                                    return false;
                                });

                                $(imageWrapper).append([captionRemove, captionView]);
                                $(imageContainer).append(imageWrapper);
                            }

                        });
                    } else {
                        $(elemThumbContainer).children('.media-div-image-container').remove();
                    }
                };

                var removeMedia = function(mediumId) {
                    $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName], function(lang, medId) {
                        if (IG.IdsEqual(mediumId, medId)) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][lang] = null;
                        }
                    });
                    renderMediumThumbnail();
                };


                var browseImagesSpan = document.createElement("span");
                $(browseImagesSpan).text(IG.label.get("edit_infoobject_media_archive"));
                $(browseImagesSpan).addClass('ig-button-span');
                var oldValue = $(browseImagesSpan).text();

                if (mediaArchiveSingle === null) {
                    mediaArchiveSingle = new IG.MediaArchive({
                        hasContext: true,
                        elementsLang: iObject.elementsLang,
                        height: iObject.mediaArchiveHeight,
                        onload: function() {
                            $(browseImagesSpan).text(oldValue);
                        },
                        onMediaInsert: function(media) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = media.id;
                            IG.mediaCache.set(media);
                            iObject.itemHasChanged = true;

                            renderMediumThumbnail();
                        },
                        onMediaRemoved: function(media) {
                            _thisObjectFields.mediumDeleted(media.id);
                        },
                        onResized: function() {
                            // TODO nothing?
                        }
                    });
                }

                var elemBtnChoose = document.createElement("a");
                $(elemBtnChoose)
                    .addClass("btn-small btn-dark")
                    .append(browseImagesSpan)
                    .click(function(event) {
                        $(browseImagesSpan).text(IG.label.get("media_opening"));
                        mediaArchiveSingle.show();
                        mediaArchiveShown = false;
                    })
                    .appendTo(objTDinp);


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                renderMediumThumbnail();

                var fieldMediaSingleRef = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }
                        renderMediumThumbnail();
                    },
                    removeMedium: function(mediumId) {
                        removeMedia(mediumId);
                    }
                });
                igFields.push(fieldMediaSingleRef);

                break;












            case 'list-of-media-reference':

                var mediaArchiveList = null;

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = []
                }



                var objTrMediaRefsRow = document.createElement("tr");
                $(objTrMediaRefsRow).addClass('tr-input-row').appendTo(destinationTBody);

                var objTdLabelMediaRefs = document.createElement("td");
                $(objTdLabelMediaRefs)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTrMediaRefsRow);

                var objTdMediaRefsContent = document.createElement("td");
                $(objTdMediaRefsContent)
                    .addClass('td-input')
                    .appendTo(objTrMediaRefsRow);


                var elemThumbMediaRefsWrap = document.createElement('div');
                $(elemThumbMediaRefsWrap)
                    .addClass('ig-media-list-of-ref-wrap')
                    .appendTo(objTdMediaRefsContent)
                    .click(function() {
                        if (!mediaArchiveShown) {
                            mediaArchiveShown = true;
                            $(elemBtnMediaRefsChoose).trigger("click");
                        }
                    });

                var elemTitleAdMedia = document.createElement('div');
                $(elemTitleAdMedia)
                    .addClass('media-title')
                    .html(IG.label.get("media_click_to_add") + ' <span class="pink">+</span>')
                    .appendTo(elemThumbMediaRefsWrap);


                var elemMediaRefsThumbContainer = document.createElement('div');
                $(elemMediaRefsThumbContainer)
                    .addClass('ig-media-list-of-ref-thumbs')
                    .sortable({
                        stop: function(e, ui) {
                            var newMediaIds = [];
                            $(elemMediaRefsThumbContainer).children(".media-div-image-container").each(function(index) {
                                var currentId = $(this).data('media-id');

                                if (typeof currentId.id !== "undefined" && typeof currentId.customerId !== "undefined") {
                                    newMediaIds.push(currentId);
                                }
                            });
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = newMediaIds;
                            iObject.itemHasChanged = true;

                        },
                        axis: 'x',
                        containment: "parent",
                        sort: function (event, ui) {
                            var that = $(this),
                                w = ui.helper.outerWidth();
                            that.children().each(function () {
                                if ($(this).hasClass('ui-sortable-helper') || $(this).hasClass('ui-sortable-placeholder'))
                                    return true;
                                // If overlap is more than half of the dragged item
                                var dist = Math.abs(ui.position.left - $(this).position().left),
                                    before = ui.position.left > $(this).position().left;
                                if ((w - dist) > (w / 2) && (dist < w)) {
                                    if (before)
                                        $('.ui-sortable-placeholder', that).insertBefore($(this));
                                    else
                                        $('.ui-sortable-placeholder', that).insertAfter($(this));
                                    return false;
                                }
                            });
                        }
                    })
                    .appendTo(elemThumbMediaRefsWrap);



                var loadMedia = function(mediumId, callback)  {

                    if (mediumId !== null && typeof mediumId !== 'undefined') {
                        var cachedMedium = IG.mediaCache.get(mediumId);
                        if (cachedMedium)  {
                            if ($.isFunction(callback)) {
                                callback(cachedMedium);
                            }
                        } else {
                            IG.request({
                                method: "searchMedia",
                                params: [IG.config.language, currentLanguage, "", true, { ids: [mediumId] }, {}, 0, 0],
                                success: function(response) {
                                    if (response.result) {
                                        if (response.data.count === 1) {
                                            var responseData = response.data["data"][0];
                                            if (typeof responseData !== 'undefined') {
                                                IG.mediaCache.set(responseData);
                                            }

                                            if ($.isFunction(callback)) {
                                                callback(responseData);
                                            }
                                        } else {
                                            // Assume that the medium does not exist any longer
                                            _thisObjectFields.mediumDeleted(mediumId);
                                        }
                                    } else {
                                        if ($.isFunction(callback)) {
                                            callback(null);
                                        }
                                    }

                                },
                                error: function(err) {
                                    if ($.isFunction(callback)) {
                                        callback(null);
                                    }
                                }
                            });
                        }
                    } else {
                        callback([]);
                    }
                };


                var renderMediaThumbnails = function() {

                    if ($.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage])) {
                        $(elemMediaRefsThumbContainer).empty();

                        $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage], function(i, mediumId) {

                            var imageContainer = document.createElement("div");
                            $(imageContainer)
                                .addClass('media-div-image-container')
                                .data('media-id', mediumId)
                                .appendTo(elemMediaRefsThumbContainer);


                            loadMedia(mediumId, function(med) {

                                if (med !== null && typeof med !== 'undefined' && med.id !== null && typeof med.id !== 'undefined') {

                                    var imageWrapper = document.createElement("div");
                                    $(imageWrapper)
                                        .addClass('media-div-image-wrapper');

                                    if (typeof med.originalMediaFile !== 'undefined') {
                                        var image = document.createElement("img");
                                        var imgLabel = null;
                                        var imgUrl = IG.fn.getMediaThumbnailUrl(med, function(label) {
                                            imgLabel = label;
                                        });
                                        $(image).attr({
                                            'src': imgUrl,
                                            'title': imgLabel
                                        })
                                            .addClass('thumb-preview-'+med.type)
                                            .addClass( iObject.fn.getMediaThumbnailClass(med) );
                                        $(imageWrapper).append(image);
                                    }


                                    var caption = document.createElement("span");
                                    $(caption).text(med.name[IG.config.language]);



                                    // Add qtip popup for media titles
                                    $(caption).qtip({

                                        content: {
                                            text: (function () {
                                                var divElm = document.createElement("div");
                                                divElm.innerHTML = "<div id='info-object-popup-" + med.id['id'] + "'>" +
                                                    "<div>" + med.name[IG.config.language] + "</div></div>";
                                                return divElm.innerHTML;
                                            })(),
                                            title: false,
                                            button: false
                                        },
                                        position: {
                                            my: 'left middle',
                                            at: 'right middle',
                                            adjust: {
                                                x: -8
                                            }
                                        },
                                        style: {
                                            tip: {
                                                width: 10,
                                                height: 6
                                            },
                                            classes: 'qtip-title',
                                            def: false
                                        }
                                    });




                                    $(imageWrapper).append(caption);

                                    var caption2 = document.createElement("span");
                                    $(caption2).text(IG.label.get("global_remove"));
                                    $(caption2).addClass('hover-btn hover-btn-1');
                                    $(caption2).click(function() {
                                        removeMediumRefs(med.id, i);
                                        return false;
                                    });

                                    var caption3 = document.createElement("span");
                                    $(caption3).text(IG.label.get("VIEW_MEDIA"));
                                    $(caption3).addClass('hover-btn hover-btn-2');
                                    $(caption3).click(function(event) {
                                        event.preventDefault();
                                        if (mediaArchiveList !== null) {
                                            mediaArchiveList.show(med);
                                        }
                                        return false;
                                    });

                                    $(imageWrapper).append([caption2, caption3]);
                                }
                                $(imageContainer).append(imageWrapper);

                            });
                        });

                    } else {
                        $(elemMediaRefsThumbContainer).empty();
                    }
                };

                var removeMediumRefs = function(absMediumId, index) {

                    if (typeof index !== 'undefined' && index !== null) {
                        var toDelete = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage][index];
                        if (IG.IdsEqual(toDelete, absMediumId)) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].splice(index, 1);
                            iObject.itemHasChanged = true;
                        } else {
                            console.error('Media remove failed: ', absMediumId);
                        }
                    } else {
                        $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName], function(lang, idList) {
                            // remove all with the given ID
                            var length = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][lang].length;
                            for (var i=length; i>-1; i--) {
                                var current = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][lang][i];
                                if (IG.IdsEqual(current, absMediumId)) {
                                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][lang].splice(i, 1);
                                }
                            }
                        });
                    }
                    renderMediaThumbnails();
                };


                var elemMediaRefsBrowseImagesSpan = document.createElement("span");
                $(elemMediaRefsBrowseImagesSpan).text(IG.label.get("edit_infoobject_media_archive"));
                $(elemMediaRefsBrowseImagesSpan).addClass('ig-button-span');
                var oldValue = $(elemMediaRefsBrowseImagesSpan).text();

                if (mediaArchiveList === null) {
                    mediaArchiveList = new IG.MediaArchive({
                        hasContext: true,
                        elementsLang: iObject.elementsLang,
                        height: iObject.mediaArchiveHeight,
                        onload: function() {
                            $(elemMediaRefsBrowseImagesSpan).text(oldValue);
                        },
                        onMediaInsert: function(media) {
                            if (media !== null) {
                                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].push(media.id);
                                IG.mediaCache.set(media);
                                iObject.itemHasChanged = true;
                            }
                            renderMediaThumbnails();
                        },
                        onMediaRemoved: function(media) {
                            _thisObjectFields.mediumDeleted(media.id);
                        },
                        onResized: function() {
                            // TODO nothing
                        }
                    });
                }


                var elemBtnMediaRefsChoose = document.createElement("a");
                $(elemBtnMediaRefsChoose)
                    .addClass("btn-small btn-dark")
                    .append(elemMediaRefsBrowseImagesSpan)
                    .click(function(event) {
                        $(elemMediaRefsBrowseImagesSpan).text(IG.label.get("media_opening"));
                        mediaArchiveList.show();
                        mediaArchiveShown = false;
                    })
                    .appendTo(objTdMediaRefsContent);


                // Preload media:
                var allMediaIds = [];
                var tmpMediaMap = {};
                $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName], function(lang, medList)  {
                    $.each(medList, function(i, mediumId) {
                        tmpMediaMap[mediumId.id] = mediumId;
                    });
                });
                $.each(tmpMediaMap, function(idKey, mediumId) {
                    allMediaIds.push(mediumId);
                });

                IG.request({
                    method: "searchMedia",
                    params: [IG.config.language, currentLanguage, "", true, { ids: allMediaIds }, {}, 0, 0],
                    success: function(response) {
                        if (response.result && response.data.count > 0) {
                            var responseData = response.data["data"];

                            $.each(responseData, function(i, medium) {
                                if (typeof medium !== 'undefined') {
                                    IG.mediaCache.set(medium);
                                }
                            });

                            renderMediaThumbnails();
                        }
                    },
                    error: function(err) {
                        if ($.isFunction(callback)) {
                            callback([]);
                        }
                    }
                });

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }

                var fieldMediaRefs = new IG.InfoObjectField({
                    setLanguage: function(language) {

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = [];
                        }
                        renderMediaThumbnails();
                    },
                    removeMedium: function(mediumId) {
                        removeMediumRefs(mediumId);
                    }
                });
                igFields.push(fieldMediaRefs);

                break;



            case "string":

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                    || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = '';
                }


                var objTRString = document.createElement("tr");
                $(objTRString).appendTo(destinationTBody);

                var objTDlblString = document.createElement("td");
                $(objTDlblString)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label for="field-' + fieldGroup.groupName + '-' + field.fieldName +'">' + field.label[IG.config.language] + '</label>')
                    .appendTo(objTRString);

                var objTDinpString = document.createElement("td");
                $(objTDinpString).appendTo(objTRString);

                var objInputString = document.createElement("input");
                $(objInputString)
                    .attr({
                        "type": "text",
                        'id': 'field-' + fieldGroup.groupName + '-' + field.fieldName
                    })
                    .val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage])
                    .appendTo(objTDinpString)
                    .wrap('<span class="input"></span>');
                $(objInputString).change(function(event) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = $(this).val();
                    iObject.itemHasChanged = true;
                });


                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                var fieldObjString = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = '';
                        }
                        $(objInputString).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language]);
                    }
                });
                igFields.push(fieldObjString);

                break;






            case "list-of-string":

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === 'undefined'
                        ||iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = [];
                }

                var timerStringListUpdate = null;
                var getStringListEntryRow = function(value) {
                    value = (typeof value === 'undefined' ? '' : value);

                    var elemDivRow = document.createElement('div');
                    $(elemDivRow)
                        .addClass('ig-plain-text-row');

                    /*var elemSpanInput = document.createElement('span');
                     $(elemSpanInput)
                     .addClass('input-narrow')
                     .appendTo(elemDivRow);*/

                    var elemDragHandle = document.createElement('div');
                    $(elemDragHandle)
                        .addClass('ig-list-drag-handle ig-plain-text-drag-handle')
                        .appendTo(elemDivRow);


                    var elemInput = document.createElement('input');
                    $(elemInput)
                        .attr('type', 'text')
                        .addClass('ig-plain-text-input input-text-small')
                        .keyup(function(e) {
                            window.clearTimeout(timerStringListUpdate);
                            timerStringListUpdate = window.setTimeout(function() {
                                updateInternalStringList();
                            }, 300);
                        })
                        .val(value)
                        .appendTo(elemDivRow);





                    var elemBtnRemoveEntry = document.createElement("a");
                    $(elemBtnRemoveEntry)
                        .addClass("btn-small btn-dark ig-plain-text-btn")
                        .html("<span>"+IG.label.get('global_remove')+"</span>")
                        .click(function(event) {
                            $(elemDivRow).hide(200, function() {
                                $(elemDivRow).remove();
                                updateInternalStringList();
                            })
                        })
                        .appendTo(elemDivRow);

                    return elemDivRow;
                };

                var populateStringList = function() {
                    $(elemDivlistWrap).empty();
                    if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].length === 0) {
                        $(elemDivlistWrap).append(getStringListEntryRow());
                    } else {
                        $.each(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage], function(i, str) {
                            $(elemDivlistWrap).append(getStringListEntryRow(str));
                        })
                    }
                };

                var updateInternalStringList = function() {
                    var inputFields = $(elemDivlistWrap).find('.ig-plain-text-input');
                    var tempList = [];
                    $.each(inputFields, function(i, inpField) {
                        if ($(inpField).val().length > 0) {
                            tempList.push($(inpField).val());
                            iObject.itemHasChanged = true;
                        }
                    });
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = tempList;
                };


                var elemTrListPlainTxt = document.createElement("tr");
                $(elemTrListPlainTxt).addClass('tr-input-row').appendTo(destinationTBody);

                // Label:
                var elemTdListPlainTxt = document.createElement("td");
                $(elemTdListPlainTxt)
                    .addClass("lbl")
                    .attr('title', field.label[IG.config.language])
                    .html('<label>' + field.label[IG.config.language] + '</label>')
                    .appendTo(elemTrListPlainTxt);

                // Input:
                var elemTdListPlTxt = document.createElement("td");
                $(elemTdListPlTxt)
                    .addClass('td-input')
                    .appendTo(elemTrListPlainTxt);

                var elemDivlistWrap = document.createElement('div');
                $(elemDivlistWrap)
                    .addClass('ig-plain-text-entries-wrap')
                    .sortable({
                        handle: ".ig-list-drag-handle",
                        containment: 'parent',
                        axis: "y",
                        tolerance: "pointer",
                        update: function(event, ui) {
                            updateInternalStringList();
                        }
                    })
                    .appendTo(elemTdListPlTxt);


                var elemBtnAddRow = document.createElement("a");
                $(elemBtnAddRow)
                    .addClass("btn-small btn-dark")
                    .html("<span>"+IG.label.get('global_add')+"</span>")
                    .click(function(event) {
                        event.preventDefault();
                        $(elemDivlistWrap).append(getStringListEntryRow());
                    })
                    .appendTo(elemTdListPlTxt);



                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(destinationTBody).append(createDescription(field.description[IG.config.language], 'tr'));
                }


                // on init
                populateStringList();

                var fieldObjStringList = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = [];
                        }
                        populateStringList();
                    }
                });
                igFields.push(fieldObjStringList);

                break;






            /**
             * TYPE: list-of-channel-reference
             */
            case "list-of-channel-reference":

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                    || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {

                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = [];
                }



                var _this = this;
                this.query = "";
                this.searchLang = IG.customerConfig.defaultContentLanguage;
                this.defaultSearchVal = IG.label.get("info_object_list_enter_search_term");

                //Determine available height for list:
                var fnGetAvailableHeight = function(obj) {
                    var h = $(window).height() - $(obj).offset().top - 36; //ish...
                    if (h < 200) {
                        h = 200;
                    }
                    return h;
                };

                var fnStatusTipContent = function() {
                    var k = "publishState";

                    var objDiv = document.createElement("div");

                    var statuses = IG.fn.getInfoObjectStatuses();
                    $.each(statuses, function(i, s) {
                        var bFound = IGObjectList.getFilter(k) == s.filter;

                        var objA = document.createElement("a");
                        if (bFound) {
                            $(objA).addClass("active");
                        }

                        $(objA)
                            .html('<span>' + s.caption + '</span>')
                            .click(function() {
                                if ($(this).hasClass("active")) {
                                    $(this).removeClass("active");
                                    IGObjectList.removeFilter(k, s.filter);
                                }
                                else {
                                    $(this).addClass("active");
                                    $(this).siblings("a").removeClass("active");
                                    IGObjectList.setFilter(k, s.filter);
                                }
                                IGObjectList.refresh();
                            })
                            .appendTo(objDiv);
                    });



                    return $(objDiv);
                };


                var objTrLabel = document.createElement("tr");
                $(objTrLabel)
                    .appendTo(destinationTBody);

                var objTdLabel = document.createElement("td");
                $(objTdLabel)
                    .attr("colspan", "2")
                    .html('<h3 class="ig-title">'+field.label[IG.config.language]+"</h3>")
                    .appendTo(objTrLabel);

                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(objTdLabel).append(createDescription(field.description[IG.config.language], 'p'));
                }

                var objTrChosen = document.createElement("tr");
                $(objTrChosen)
                    .addClass('ig-list-of-typed-ref-chosen-wrap')
                    .appendTo(destinationTBody);

                var objTdChosen = document.createElement("td");
                $(objTdChosen)
                    .attr("colspan", "2")
                    .appendTo(objTrChosen);

                var objDivChosenDocument = document.createElement("div");
                $(objDivChosenDocument)
                    .addClass('ig-list-of-typed-ref-chosen-div')
                    .sortable({
                        handle: ".ig-list-drag-handle",
                        containment: 'parent',
                        axis: "y",
                        tolerance: "pointer",
                        update: function(event, ui) {
                            updateInternalRefList();
                        }
                    })
                    .appendTo(objTdChosen);



                var updateInternalRefList = function() {
                    var refRows = $(objDivChosenDocument).find('.ig-typed-ref-row');
                    var orderedIds = [],
                        idString = null,
                        id = null;
                    if (refRows) {
                        $.each(refRows, function(i, row) {
                            idString = $(row).attr('data-id');
                            id = IG.getIdFromString(idString);
                            if (id !== null) {
                                orderedIds.push(id);
                            }
                        });
                    }
                    if (orderedIds.length > 0) {
                        tempReferences.reorder(orderedIds);
                    }
                };

                //Create ObjectList:
                var IGObjectList = new IG.ObjectList({
                    methodName: "searchChannels"
                });

                var objTrSearch = document.createElement("td");
                $(objTrSearch)
                    .attr("colspan", "2")
                    .addClass('no-padding')
                    .appendTo(destinationTBody)
                    .wrap("<tr></tr>");

                var objSearchWrap = document.createElement("div");
                $(objSearchWrap)
                    .appendTo(objTrSearch);

                var objLabel = document.createElement("h3");
                $(objLabel)
                    .text(IG.label.get('global_search'))
                    .addClass("ig-title")
                    .appendTo(objSearchWrap);

                //SEARCHBAR :
                var objSearch = document.createElement("div");
                $(objSearch)
                    .addClass("ig-search")
                    .attr("id", "ig-infoobjects-search")
                    .appendTo(objSearchWrap);

                var displayVal = (_this.query != '' ? _this.query : _this.defaultSearchVal);
                var searchLang = _this.searchLang;

                var objSearchInput = document.createElement("input");
                $(objSearchInput)
                    .attr({
                        "type": "text",
                        "name": "search-infoobjects",
                        "id": "search-infoobjects-"+field.fieldName,
                        "value": displayVal
                    })
                    .addClass("search")
                    .click(function() {
                        if (_this.defaultSearchVal == $(this).val()) $(this).val('');
                        //some decoration stuff
                        $(this).removeClass('search-default-deco');
                        $(this).addClass('search-default');
                    })
                    .blur(function() {
                        if ($(this).val() == '') $(this).val(_this.defaultSearchVal);
                        $(this).removeClass('search-default');
                        $(this).addClass('search-default-deco');
                    })
                    .keyup(function(e) {
                        var v = $(this).val();
                        var key = e.which ? e.which : e.keyCode;
                        if (key == 13) {
                            _this.query = (v != _this.defaultSearchVal ? v : '');
                            _this.searchLang = searchLang;
                            if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                                $(objLangSel).addClass('search-lang-deco');
                            } else $(objLangSel).removeClass('search-lang-deco');
                            IGObjectList.setQuery(_this.query, _this.searchLang);
                            IGObjectList.refresh();
                        }
                    })
                    .appendTo(objSearch);



                var objLangSel = IGObjectList.renderSearchLanguageSelector({
                    onChange: function(n) {
                        searchLang = n;
                    },
                    searchLang: searchLang
                });
                if (objLangSel) $(objSearch).append(objLangSel);

                var objDocTypeSel = IGObjectList.renderDocumentTypeSelector({
                    docTypes: field.referencedDocTypes,
                    onChange: function(docType) {
                        IGObjectList.refresh();
                        // console.log('[objDocTypeSel.onchange], selected document type: ', docType);
                    }
                });
                if (objDocTypeSel) $(objSearch).append(objDocTypeSel);


                var objTdListWrap = document.createElement("tr");
                $(objTdListWrap)
                    .appendTo(destinationTBody);

                var objTdList = document.createElement("td");
                $(objTdList)
                    .addClass('ig-field-search-list-wrap')
                    .attr("colspan", "2")
                    .appendTo(objTdListWrap);


                var objDivList = document.createElement("div");
                $(objDivList)
                    .addClass('ig-infoobjects-list')
                    .height(250) //FIXME: better calculation of height!
                    .appendTo(objTdList);

                var objDivForceWidth = document.createElement('div');
                $(objDivForceWidth)
                    .addClass('list-force-with')
                    .appendTo(objDivList);


                var blacklist = {
                    data: {},
                    add: function(id) {
                        if (typeof this.data[id] === 'undefined') {
                            this.data[id] = id;
                        }
                    },
                    remove: function(id) {
                        if (typeof this.data[id] !== 'undefined') {
                            delete this.data[id];
                        }
                    },
                    exists: function(id) {
                        return (typeof this.data[id] !== 'undefined');
                    },
                    empty: function() {
                        this.data = {};
                    }
                };

                var tempReferences = {
                    refArray: [],
                    refObj: {},
                    tempBL: [],
                    addAll: function(allRefs) {
                        var _me = this;
                        _me.refArray = [];
                        _me.refObj = {};
                        _me.tempBL = [];

                        $.each(allRefs, function(i, refId) {
                            _me.refObj[refId.id] = refId;
                            _me.refArray.push(refId);
                            blacklist.add(refId.id);
                        });
                    },
                    add: function(refId, callback) {
                        var _me = this;
                        if (typeof this.refObj[refId.id] === 'undefined') {
                            _me.refObj[refId.id] = refId;
                            _me.refArray.push(refId);
                            iObject.itemHasChanged = true;
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = _me.refArray;

                            _me.tempBL = [];
                            $.each(_me.refArray, function(i, id) {
                                blacklist.add(id.id);
                            });
                            callback(true);
                        } else {
                            callback(false);
                        }
                    },
                    remove: function(refId) {
                        var _me = this;
                        delete this.refObj[refId.id];
                        blacklist.remove(refId.id);
                        $.each(this.refArray, function(i, searchRefId) {
                            if (IG.IdsEqual(refId,searchRefId)) {
                                _me.refArray.splice(i, 1);
                                return false;
                            }
                        });
                        iObject.itemHasChanged = true;
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = this.refArray;
                        _me.tempBL = [];
                        $.each(_me.refArray, function(i, id) {
                            blacklist.add(id.id);
                        });
                        IGObjectList.refresh();
                    },
                    getArray: function() {
                        return this.refArray;
                    },
                    reorder: function(newListOrdered) {
                        this.refArray = newListOrdered;
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = this.refArray;
                    }
                };

                var fetchEntryRowName = function(docId, callback) {
                    var translatedTitle = '<em>...</em>';

                    var cachedTitle = IG.channelNameCache.get(docId);
                    if (cachedTitle) {
                        if (typeof cachedTitle[_this.searchLang] !== 'undefined') {
                            translatedTitle = cachedTitle[_this.searchLang];
                        }
                        callback(translatedTitle);
                    } else {
                        callback('<em>' + IG.label.get('CHANNEL_LIST_SEARCHING_FOR_NAME') + ' ...</em>');
                        IG.channelNameCache.registrer(docId.id, function(titleObj) {
                            var titleTranslated = titleObj[_this.searchLang];
                            if (typeof titleTranslated === 'undefined') {
                                titleTranslated = IG.label.get('CHANNEL_NAME_NOT_TRANSLATED');
                            }
                            callback(titleTranslated);
                        });
                    }
                };

                var createRefEntryRow = function(docId, objRow, callback) {

                    fetchEntryRowName(docId, function(title) {
                        if (title !== false) {
                            objRow
                                .addClass('ig-typed-ref-row')
                                .attr('data-id', docId["customerId"]+'::'+docId["id"])
                                .text('');

                            var objDragHandle = document.createElement('div');
                            $(objDragHandle)
                                .addClass('ig-list-drag-handle ig-ref-list-drag-handle')
                                .appendTo(objRow);

                            var objA = document.createElement("a");
                            $(objA)
                                .html(title)
                                .attr("title", title)
                                .attr("href", docId["customerId"]+'#/channel?channel='+docId["id"])
                                .addClass('ig-typed-ref-row-headline')
                                .click(function(e) {
                                    e.preventDefault();

                                    if (IG.infoObjectChanged()) {
                                        IG.showInfoObjectChangedDialog({
                                            confirm: function() {
                                                IG.showEditChannel({
                                                    id: docId["id"],
                                                    history: true,
                                                    searchLang: _this.searchLang
                                                });
                                            }
                                        });
                                    } else {
                                        IG.showEditChannel({
                                            id: docId["id"],
                                            history: true,
                                            searchLang: _this.searchLang
                                        });
                                    }
                                })
                                .appendTo(objRow);
                            // $(objDivChosenDocument).append(objA);

                            var elemBtnRemoveEntry = document.createElement("a");
                            $(elemBtnRemoveEntry)
                                .addClass("btn-small btn-dark ig-typed-ref-rm-btn")
                                .html("<span>"+IG.label.get('global_remove')+"</span>")
                                .click(function(event) {
                                    objRow.hide(200, function() {
                                        objRow.remove();
                                        tempReferences.remove(docId);
                                        if (tempReferences.getArray().length === 0) {
                                            $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');
                                        }
                                    });


                                })
                                .appendTo(objRow);

                            callback(objRow);
                        } else {
                            // Remove the references document from the list
                            tempReferences.remove(docId);
                            iObject.itemHasChanged = true;
                        }


                    });
                };

                var populateRefList = function() {
                    var list = tempReferences.getArray();

                    $(objDivChosenDocument).empty();
                    if (list.length === 0) {
                        $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');
                    } else {
                        $.each(list, function(i, docRefId) {
                            var objRow = document.createElement('div');
                            var objRowRef = $(objRow);
                            objRowRef
                                .addClass('ig-typed-ref-row')
                                .text('...');
                            $(objDivChosenDocument).append(objRowRef);

                            createRefEntryRow(docRefId, objRowRef, function(entryRow) {});
                        });

                        setTimeout(function(){
                            if (tempReferences.getArray().length === 0) {
                                $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');
                            }
                        }, 5000);
                    }
                };

                var renderAddReferenceBtn = function(object, objContainer) {
                    $(objContainer).html('');

                    var objEm = document.createElement('em');
                    $(objEm)
                        .addClass('ellipsis ig-list-item-message');

                    if (!blacklist.exists(object.id.id)) {
                        var objBtn = document.createElement("a");
                        $(objBtn)
                            .addClass("btn-small btn-light")
                            .html("<span>"+IG.label.get('global_add')+"</span>")
                            .click(function(event) {
                                tempReferences.add(object.id, function(result) {
                                    if (result) {
                                        populateRefList();

                                        $(objEm)
                                            .attr('title', IG.label.get('LIST_ITEM_ADDED'))
                                            .text(IG.label.get('LIST_ITEM_ADDED'));
                                        $(objContainer)
                                            .html(objEm);
                                    }
                                });

                            }).appendTo(objContainer);
                    } else {
                        $(objEm)
                            .attr('title', IG.label.get('LIST_ITEM_ADDED'))
                            .text(IG.label.get('LIST_ITEM_ADDED'));
                        $(objContainer)
                            .html(objEm);
                    }


                };

                // Initially populate the currently chosen:
                iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = _.filter(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage], function(item) {
                    return !_.isUndefined(item.id);
                });
                tempReferences.addAll(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]);
                window.setTimeout(function() {
                    populateRefList();

                    // Delay the title search a bit to let other fields fields registrer for title updates
                    window.setTimeout(function() {
                        IG.channelNameCache.triggerSearch(); // make sure the list is populated with titles
                    }, 10);
                }, 10);

                IGObjectList.config.parentObject = objDivList;


                IGObjectList.config.columns = [
                    {
                        name: "buttons",
                        title: "",
                        className: "ellipsis buttons-add",
                        sortable: false,
                        width: "70px",
                        render: function(object) {
                            var objContainer = document.createElement('div');
                            renderAddReferenceBtn(object, objContainer);
                            return objContainer;
                        }
                    },
                    {
                        name: "name",
                        title: IG.label.get("channel_list_channel_name"),
                        className: "title ellipsis",
                        sortable: true,
                        width: "auto",
                        render: function(object) {
                            var s = "...";
                            if (typeof object.name != "undefined") {
                                s = IG.fn.stripHTML(object.name[_this.searchLang]);
                            }

                            var objA = document.createElement("a");
                            $(objA)
                                .text(s)
                                .attr("title", s)
                                .click(function() {
                                    if (IG.infoObjectChanged()) {
                                        IG.showInfoObjectChangedDialog({
                                            confirm: function() {
                                                IG.showEditChannel({
                                                    id: object.id["id"],
                                                    history: true,
                                                    searchLang: _this.searchLang
                                                });
                                            }
                                        });
                                    } else {
                                        IG.showEditChannel({
                                            id: object.id["id"],
                                            history: true,
                                            searchLang: _this.searchLang
                                        });
                                        return false;
                                    }

                                });
                            return objA;
                        }
                    }];


                IGObjectList.refresh();


                var fieldObjListofTypedRef = new IG.InfoObjectField({

                    setLanguage: function(language) {
                        blacklist.empty();

                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = [];
                        }

                        tempReferences.addAll(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage]);
                        populateRefList();

                        IGObjectList.refresh();
                    }
                });
                igFields.push(fieldObjListofTypedRef);

                break;






            /**
             * TYPE: channel-reference;
             */
            case 'channel-reference':

                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                    || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])
                    ||typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] !== 'object') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }

                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined'
                    || iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === false) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }


                var _this = this;
                this.query = "";
                this.searchLang = IG.customerConfig.defaultContentLanguage;
                this.defaultSearchVal = IG.label.get("info_object_list_enter_search_term");

                //Determine available height for list:
                var fnGetAvailableHeight = function(obj) {
                    var h = $(window).height() - $(obj).offset().top - 36; //ish...
                    if (h < 200) {
                        h = 200;
                    }
                    return h;
                };


                var objTrLabel = document.createElement("tr");
                $(objTrLabel)
                    .appendTo(destinationTBody);

                var objTdLabel = document.createElement("td");
                $(objTdLabel)
                    .attr("colspan", "2")
                    .html('<h3 class="ig-title">'+field.label[IG.config.language]+"</h3>")
                    .appendTo(objTrLabel);

                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(objTdLabel).append(createDescription(field.description[IG.config.language], 'p'));
                }

                var objTrChosen = document.createElement("tr");
                $(objTrChosen)
                    .appendTo(destinationTBody);

                var objTdChosen = document.createElement("td");
                $(objTdChosen)
                    .attr("colspan", "2")
                    .appendTo(objTrChosen);

                var objDivChosenDocument = document.createElement("div");
                $(objDivChosenDocument)
                    .appendTo(objTdChosen);



                var renderChosenReference = function(options) {
                    options = (typeof options !== 'undefined' ? options : {});
                    options.id = (typeof options.id !== 'undefined' ? options.id : null);
                    var objA = document.createElement("a");


                    var translatedTitle = '<em>...</em>';
                    var cachedTitle = IG.channelNameCache.get(options.id.id);
                    if (cachedTitle) {
                        if (typeof cachedTitle[_this.searchLang] !== 'undefined') {
                            translatedTitle = cachedTitle[_this.searchLang];
                        }
                        $(objA)
                            .html(translatedTitle)
                            .attr("title", translatedTitle);
                    } else {
                        $(objA)
                            .html('<em>' + IG.label.get('CHANNEL_LIST_SEARCHING_FOR_NAME') + ' ...</em>')
                            .attr("title", translatedTitle);

                        IG.channelNameCache.registrer(options.id.id, function(titleObj) {
                            var titleTranslated = titleObj[_this.searchLang];
                            if (typeof titleTranslated === 'undefined') {
                                titleTranslated = IG.label.get('INFOOBJECT_HEADLINE_NOT_TRANSLATED');
                            }
                            $(objA)
                                .html(titleTranslated)
                                .attr("title", translatedTitle);
                        });
                    }


                    $(objDivChosenDocument).html('');


                    $(objA)
                        .addClass('ig-typed-ref-row-headline')
                        .click(function() {
                            if (IG.infoObjectChanged()) {
                                IG.showInfoObjectChangedDialog({
                                    confirm: function() {
                                        IG.showEditChannel({
                                            id: options.id["id"],
                                            history: true,
                                            searchLang: _this.searchLang
                                        });
                                        return false;
                                    }
                                });
                            } else {
                                IG.showEditChannel({
                                    id: options.id["id"],
                                    history: true,
                                    searchLang: _this.searchLang
                                });
                                return false;
                            }

                        })
                        .appendTo(objDivChosenDocument);

                    var elemBtnRemoveRef = document.createElement("a");
                    $(elemBtnRemoveRef)
                        .addClass("btn-small btn-dark ig-typed-ref-rm-btn")
                        .html("<span>"+IG.label.get('global_remove')+"</span>")
                        .click(function(event) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                            iObject.itemHasChanged = true;
                            $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');


                            var objList = $('#'+IGObjectList.id);
                            var objItemRow = objList.find('.ITEM_'+options.id.id);
                            if (objItemRow[0]) {
                                var objItemBtns = objItemRow.find('td.buttons-add div');
                                if (objItemBtns[0]) {
                                    renderAddSingleReferenceBtn(options.id, objItemBtns[0]);
                                }
                            }
                        })
                        .appendTo(objDivChosenDocument);
                };


                // Initially find the currently chosen:
                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] != null) {
                    var id = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];

                    renderChosenReference({
                        id: id
                    });

                    // Delay the title search a bit to let other fields fields registrer for title updates
                    window.setTimeout(function() {
                        IG.channelNameCache.triggerSearch(); // make sure the list is populated with titles
                    }, 10);
                } else {
                    $(objDivChosenDocument).html("<em>"+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+"</em>");
                }


                //Create ObjectList:
                var IGObjectList = new IG.ObjectList({
                    methodName: "searchChannels"
                });


                var objTrSearch = document.createElement("td");
                $(objTrSearch)
                    .attr("colspan", "2")
                    .appendTo(destinationTBody)
                    .addClass('no-padding')
                    .wrap("<tr></tr>");

                var objSearchWrap = document.createElement("div");
                $(objSearchWrap)
                    /*.css({
                     "padding": "10px",
                     "float": "left",
                     "display": "block",
                     "width": "100%",
                     "height": "100px"
                     })*/
                    .appendTo(objTrSearch);

                var objLabel = document.createElement("h3");
                $(objLabel)
                    .text(IG.label.get('global_search'))
                    .addClass("ig-title")
                    .appendTo(objSearchWrap);

                //SEARCHBAR :
                var objSearch = document.createElement("div");
                $(objSearch)
                    .addClass("ig-search")
                    .attr("id", "ig-infoobjects-search")
                    .appendTo(objSearchWrap);

                var displayVal = (_this.query != '' ? _this.query : _this.defaultSearchVal);
                var searchLang = _this.searchLang;

                var objSearchInput = document.createElement("input");
                $(objSearchInput)
                    .attr({
                        "type": "text",
                        "name": "search-infoobjects",
                        "id": "search-infoobjects-"+field.fieldName,
                        "value": displayVal
                    })
                    .addClass("search")
                    .click(function() {
                        if (_this.defaultSearchVal == $(this).val()) $(this).val('');
                        //some decoration stuff
                        $(this).removeClass('search-default-deco');
                        $(this).addClass('search-default');
                    })
                    .blur(function() {
                        if ($(this).val() == '') $(this).val(_this.defaultSearchVal);
                        $(this).removeClass('search-default');
                        $(this).addClass('search-default-deco');
                    })
                    .keyup(function(e) {
                        var v = $(this).val();
                        var key = e.which ? e.which : e.keyCode;
                        if (key == 13) {
                            _this.query = (v != _this.defaultSearchVal ? v : '');
                            _this.searchLang = searchLang;
                            if (_this.searchLang != IG.customerConfig.defaultContentLanguage) {
                                $(objLangSel).addClass('search-lang-deco');
                            } else $(objLangSel).removeClass('search-lang-deco');
                            IGObjectList.setQuery(_this.query, _this.searchLang);
                            IGObjectList.refresh();
                        }
                    })
                    .appendTo(objSearch);



                var objLangSel = IGObjectList.renderSearchLanguageSelector({
                    onChange: function(n) {
                        searchLang = n;
                    },
                    searchLang: searchLang
                });
                if (objLangSel) $(objSearch).append(objLangSel);

                var objDocTypeSel = IGObjectList.renderDocumentTypeSelector({
                    docTypes: field.referencedDocTypes,
                    onChange: function(docType) {
                        // console.log('[objDocTypeSel.onchange], selected document type: ', docType);
                    }
                });
                if (objDocTypeSel) $(objSearch).append(objDocTypeSel);



                var objTdList = document.createElement("td");
                $(objTdList)
                    .attr("colspan", "2")
                    .addClass('ig-field-search-list-wrap')
                    .appendTo(destinationTBody)
                    .wrap("<tr></tr>");


                var objDivList = document.createElement("div");
                $(objDivList)
                    .addClass('ig-infoobjects-list')
                    .height(250) //FIXME: better calculation of height!
                    .appendTo(objTdList);

                var objDivForceWidth = document.createElement('div');
                $(objDivForceWidth)
                    .addClass('list-force-with')
                    .appendTo(objDivList);


                /*$(window).resize(function() {
                 $(objDivList).height(fnGetAvailableHeight(objDivList));
                 });*/


                IGObjectList.config.parentObject = objDivList;


            renderAddSingleReferenceBtn = (objectAbsID, objContainer) => {

                $(objContainer).html('');

                var objEm = document.createElement('em');
                $(objEm).addClass('ellipsis ig-list-item-message');
                var oldValue = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];

                if (oldValue === null || typeof oldValue === 'undefined' || (typeof oldValue !== 'undefined' && oldValue.id !== objectAbsID.id)) {
                    var objBtn = document.createElement("a");
                    $(objBtn)
                        .addClass("btn-small btn-light")
                        .html("<span>"+IG.label.get('TYPE_REFERENCED_CHOOSE_DOCUMENT')+"</span>")
                        .click(function(event) {
                            var prevVal = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = objectAbsID;
                            iObject.itemHasChanged = true;

                            renderChosenReference({
                                id: objectAbsID
                            });

                            if (prevVal !== null && typeof prevVal !== 'undefined') {
                                var objList = $('#'+IGObjectList.id);
                                var objItemRow = objList.find('.ITEM_'+prevVal.id);
                                if (objItemRow[0]) {
                                    var objItemBtns = objItemRow.find('td.buttons-add div');
                                    if (objItemBtns[0]) {
                                        renderAddSingleReferenceBtn(prevVal, objItemBtns[0]);
                                    }
                                }
                            }

                            $(objEm)
                                .attr('title', IG.label.get('LIST_ITEM_CHOSEN'))
                                .text(IG.label.get('LIST_ITEM_CHOSEN'));
                            $(objContainer).html(objEm);
                        })
                        .appendTo(objContainer);
                } else {
                    $(objEm)
                        .attr('title', IG.label.get('LIST_ITEM_CHOSEN'))
                        .text(IG.label.get('LIST_ITEM_CHOSEN'));
                    $(objContainer).html(objEm);
                }
            }


                IGObjectList.config.columns = [
                    {
                        name: "buttons",
                        title: "",
                        className: "ellipsis buttons-add",
                        sortable: false,
                        width: "70px",
                        render: function(object) {
                            var objContainer = document.createElement('div');
                            renderAddSingleReferenceBtn(object.id, objContainer);
                            return objContainer;
                        }
                    },
                    {
                        name: "name",
                        title: IG.label.get("channel_list_channel_name"),
                        className: "title ellipsis",
                        sortable: true,
                        width: "auto",
                        render: function(object) {
                            var s = "...";
                            if (typeof object.name != "undefined") {
                                s = IG.fn.stripHTML(object.name[_this.searchLang]);
                            }

                            var objA = document.createElement("a");
                            $(objA)
                                .text(s)
                                .attr("title", s)
                                .click(function() {
                                    if (IG.infoObjectChanged()) {
                                        IG.showInfoObjectChangedDialog({
                                            confirm: function() {
                                                IG.showEditInfoObject({
                                                    id: object.id["id"],
                                                    history: true,
                                                    searchLang: _this.searchLang
                                                });
                                            }
                                        });
                                    } else {
                                        IG.showEditInfoObject({
                                            id: object.id["id"],
                                            history: true,
                                            searchLang: _this.searchLang
                                        });
                                    }

                                });
                            return objA;
                        }
                    }];


                IGObjectList.refresh();

                var fieldObjTypedRef = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        var currentRefId = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language];

                        if (typeof currentRefId === 'undefined' || currentRefId === false) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }

                        if (typeof currentRefId !== 'undefined' && currentRefId !== null) {
                            renderChosenReference({
                                id: currentRefId
                            });
                        } else {
                            $(objDivChosenDocument).html('<em>'+IG.label.get('TYPE_REFERENCED_NONE_CHOSEN')+'</em>');
                        }

                        IGObjectList.refresh();
                    }
                });
                igFields.push(fieldObjTypedRef);

                break;



            case "geolocation":
                // Geolocation is a translated field, so make sure it exists as an object:
                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                }

                var hasError = false;

                var objTR1 = document.createElement("tr");
                $(objTR1).addClass('tr-input-row').appendTo(destinationTBody);
                var objTR2 = document.createElement("tr");
                $(objTR2)
                    .addClass('geo-input-row')
                    .appendTo(destinationTBody);

                var objTDlbl1 = document.createElement("td");
                $(objTDlbl1)
                    .attr('colspan', 2)
                    .appendTo(objTR1);

                var objTDlbl2 = document.createElement("td");
                $(objTDlbl2)
                    .attr('colspan', 2)
                    .appendTo(objTR2);


                var objGeoLabel = document.createElement("h3");
                $(objGeoLabel)
                    .addClass('ig-title')
                    .text(field.label[IG.config.language])
                    .appendTo(objTDlbl1);

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(objTDlbl1).append(createDescription(field.description[IG.config.language], 'p'));
                }

                var objGeoLatFieldWrap = document.createElement('div');
                $(objGeoLatFieldWrap)
                    .addClass('ig-feild-wrap')
                    .appendTo(objTDlbl2);

                var objGeoLngFieldWrap = document.createElement('div');
                $(objGeoLngFieldWrap)
                    .addClass('ig-feild-wrap')
                    .appendTo(objTDlbl2);

                var geoLatObjId = 'lat' +'-'+fieldGroup.groupName+'-'+field.fieldName;
                var objGeoLblLat = document.createElement('label');
                $(objGeoLblLat)
                    .attr('for',geoLatObjId)
                    .text(IG.label.get('edit_infoobject_width') + ': ')
                    .appendTo(objGeoLatFieldWrap);
                var objGeoInpLat = document.createElement('input');
                $(objGeoInpLat)
                    .attr('id',geoLatObjId)
                    .addClass('input-text-small')
                    // .val('0.0')
                    .appendTo(objGeoLatFieldWrap)
                    .change(function(e) {
                        setCoordinates($(this).val(), $('#'+geoLngObjId).val());
                    });


                var geoLngObjId = 'lng' +'-'+fieldGroup.groupName+'-'+field.fieldName;
                var objGeoLblLng = document.createElement('label');
                $(objGeoLblLng)
                    .attr('for',geoLngObjId)
                    .text(IG.label.get('edit_infoobject_length') + ': ')
                    .appendTo(objGeoLngFieldWrap);
                var objGeoInpLng = document.createElement('input');
                $(objGeoInpLng)
                    .attr('id',geoLngObjId)
                    .addClass('input-text-small')
                    // .val('0.0')
                    .appendTo(objGeoLngFieldWrap)
                    .change(function(e) {
                        setCoordinates($('#'+geoLatObjId).val(), $(this).val());
                    });


                var elemBtnRemoveRef = document.createElement("a");
                $(elemBtnRemoveRef)
                    .addClass("btn-small btn-dark")
                    .html("<span>"+ IG.label.get("edit_infoobject_find_coordinates") +"</span>")
                    .click(function(event) {
                        if (!hasError) {
                            event.preventDefault();

                            var mapUrl = "https://map.infogalleri.net/?showButton=true";
                            var currentValObj = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];
                            if (currentValObj !== null && typeof currentValObj.lat !== 'undefined') {
                                var strAddress = currentValObj.lat + "," + currentValObj.lng;
                                mapUrl += "&address=" + strAddress;
                            }

                            $( "#modal-window" )
                                .html('<iframe id="preview-frame" frameborder="0" hspace="0" scrolling="auto" ' +
                                    'src="'+mapUrl+'"></iframe>')
                                .dialog({
                                    modal: true,
                                    position: { my: "center", at: "center", of: window },
                                    width: 800,
                                    height: 600,
                                    closeOnEscape: true,
                                    close: function( event, ui ) {
                                        pm.unbind('latlngMessage');
                                    },

                                    // reset to defaults
                                    minWidth: 150,
                                    maxWidth: false,
                                    minHeight: 150,
                                    maxHeight: false,
                                    buttons: null,
                                    title: ''
                                });
                            pm.bind("latlngMessage", function(data) {
                                pm.unbind('latlngMessage');
                                $( "#modal-window").dialog('close');
                                setCoordinates(data.lat, data.lng, true);
                            });
                        }
                    })
                    .appendTo(objTDlbl2);


                function setCoordinates(lat, lng, updateInputFields) {
                    if (typeof lat === 'undefined' || typeof lng === 'undefined') {
                        console.error('Latitude or longitude is not defined.');
                        return false;
                    }

                    updateInputFields = typeof updateInputFields === 'undefined' ? false : updateInputFields;

                    try {
                        if ((lat+'').indexOf(',') >= 0) {
                            lat = lat.replace(",", ".");
                        }
                        lat = parseFloat(lat);

                        if ((lng+'').indexOf(',') >= 0) {
                            lng = lng.replace(",", ".");
                        }
                        lng = parseFloat(lng);
                    } catch (error) {
                        console.log('ERROR', error);
                    }

                    // if only one of the fields is set when setCoordinates is called, set the other one to 0.0:
                    if (!isNaN(lat) && isNaN(lng)) {
                        lng = 0.0;
                    } else if (isNaN(lat) && !isNaN(lng)) {
                        lat = 0.0;
                    } else if (isNaN(lat) && isNaN(lng)) {
                        // both fields are empty, so set the value to null and return
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = null;
                        return true;
                    }

                    if (!isNaN(lat) && !isNaN(lng) && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
                        if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === null) {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = {
                                lat: 0.0,
                                lng: 0.0
                            };
                        }

                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].lat = lat;
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].lng = lng;

                        if (updateInputFields) {
                            $('#'+geoLatObjId).val(lat);
                            $('#'+geoLngObjId).val(lng);
                        }
                        iObject.itemHasChanged = true;
                    } else {
                        hasError = true;
                        IG.showErrorDialog(IG.label.get('EDIT_INFOOBJECT_GEO_INVALID_COORDINATES'), function() {
                            hasError = false;
                        });
                    }
                    return true;
                }


                // initiate
                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] !== null) {
                    window.setTimeout(function() {
                        $('#'+geoLatObjId).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].lat);
                        $('#'+geoLngObjId).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].lng);
                    }, 100);
                }


                var fieldObj = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        // Do clean up code if i.e. a new language has been added
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = null;
                        }

                        // if it has a value set it:
                        if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] !== null) {
                            $('#'+geoLatObjId).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language].lat);
                            $('#'+geoLngObjId).val(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language].lng);
                        } else {
                            $('#'+geoLatObjId).val('');
                            $('#'+geoLngObjId).val('');
                        }
                    }
                });
                igFields.push(fieldObj);

                break;








            case "list-of-geolocation":
                // Geolocation is a translated field, so make sure it exists as an object:
                if (iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] === null
                        || $.isArray(iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName])) {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName] = {};
                }
                if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] === 'undefined') {
                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = [];
                }


                var objTR1 = document.createElement("tr");
                $(objTR1).addClass('tr-input-row').appendTo(destinationTBody);
                var objTR2 = document.createElement("tr");
                $(objTR2)
                    .addClass('geo-input-row')
                    .appendTo(destinationTBody);

                var objTDlbl1 = document.createElement("td");
                $(objTDlbl1)
                    .attr('colspan', 2)
                    .appendTo(objTR1);

                var objTdGeoRows = document.createElement("td");
                $(objTdGeoRows)
                    .attr('colspan', 2)
                    .appendTo(objTR2);


                var objGeoLabel = document.createElement("h3");
                $(objGeoLabel)
                    .addClass('ig-title')
                    .text(field.label[IG.config.language])
                    .appendTo(objTDlbl1);

                // Add description
                if (typeof field.description[IG.config.language] !== 'undefined' && field.description[IG.config.language].length > 0) {
                    $(objTDlbl1).append(createDescription(field.description[IG.config.language], 'p'));
                }

                var objGeoRowsWrap = document.createElement("div");
                $(objGeoRowsWrap)
                    .addClass('geo-rows-wrap')
                    .sortable({
                        handle: ".ig-list-drag-handle",
                        containment: 'parent',
                        axis: "y",
                        tolerance: "pointer",
                        update: function(event, ui) {
                            updateGeoList();
                        }
                    })
                    .appendTo(objTdGeoRows);

                var elemBtnAddRow = document.createElement("a");
                $(elemBtnAddRow)
                    .addClass("btn-small btn-dark")
                    .html("<span>"+IG.label.get('global_add')+"</span>")
                    .click(function(event) {
                        event.preventDefault();
                        $(objGeoRowsWrap).append(buildRow());
                    })
                    .appendTo(objTdGeoRows);


                var rowCounter = 0;
                function buildRow(lat, lng) {
                    rowCounter++;

                    lat = (typeof lat === 'undefined' ? false : lat);
                    lng = (typeof lng === 'undefined' ? false : lng);

                    var geoLatObjId = 'lat' +'-'+fieldGroup.groupName+'-'+field.fieldName + '-' + rowCounter;
                    var geoLngObjId = 'lng' +'-'+fieldGroup.groupName+'-'+field.fieldName + '-' + rowCounter;

                    var rowWrap = document.createElement('div');
                    $(rowWrap)
                        .addClass('geo-row-wrap');

                    var elemDragHandle = document.createElement('div');
                    $(elemDragHandle)
                        .addClass('ig-list-drag-handle ig-plain-text-drag-handle')
                        .appendTo(rowWrap);

                    var objGeoLatFieldWrap = document.createElement('div');
                    $(objGeoLatFieldWrap)
                        .addClass('ig-feild-wrap')
                        .appendTo(rowWrap);

                    var objGeoLngFieldWrap = document.createElement('div');
                    $(objGeoLngFieldWrap)
                        .addClass('ig-feild-wrap')
                        .appendTo(rowWrap);


                    var objGeoLblLat = document.createElement('label');
                    $(objGeoLblLat)
                        .attr('for',geoLatObjId)
                        .text(IG.label.get('edit_infoobject_width') + ': ')
                        .appendTo(objGeoLatFieldWrap);
                    var objGeoInpLat = document.createElement('input');
                    $(objGeoInpLat)
                        .attr('id',geoLatObjId)
                        .addClass('geo-inp-lat input-text-small')
                        .appendTo(objGeoLatFieldWrap)
                        .change(function(e) {
                            setCoordinatesInList($(this).val(), $('#'+geoLngObjId).val(), $(this), $('#'+geoLngObjId));
                        });
                    if (lat) {
                        $(objGeoInpLat).val(lat);
                    }


                    var objGeoLblLng = document.createElement('label');
                    $(objGeoLblLng)
                        .attr('for',geoLngObjId)
                        .text(IG.label.get('edit_infoobject_length') + ': ')
                        .appendTo(objGeoLngFieldWrap);
                    var objGeoInpLng = document.createElement('input');
                    $(objGeoInpLng)
                        .attr('id',geoLngObjId)
                        .addClass('geo-inp-lng input-text-small')
                        .appendTo(objGeoLngFieldWrap)
                        .change(function(e) {
                            setCoordinatesInList($('#'+geoLatObjId).val(), $(this).val(), $('#'+geoLatObjId), $(this));
                        });
                    if (lng) {
                        $(objGeoInpLng).val(lng);
                    }



                    var elemBtnRemoveRef = document.createElement("a");
                    $(elemBtnRemoveRef)
                        .addClass("btn-small btn-dark btn-space-r")
                        .html("<span>"+ IG.label.get("edit_infoobject_find_coordinates") +"</span>")
                        .click(function(event) {
                            if (!hasError) {
                                event.preventDefault();

                                var mapUrl = "https://map.infogalleri.net/?showButton=true";

                                if ($(objGeoInpLat) && $(objGeoInpLat).val().length && $(objGeoInpLng) && $(objGeoInpLng).val().length > 0) {
                                    var strAddress = $(objGeoInpLat).val() + "," + $(objGeoInpLng).val();
                                    mapUrl += "&address=" + strAddress;
                                }

                                $( "#modal-window" )
                                    .html('<iframe id="preview-frame" frameborder="0" hspace="0" scrolling="auto" ' +
                                        'src="'+mapUrl+'"></iframe>')
                                    .dialog({
                                        modal: true,
                                        position: { my: "center", at: "center", of: window },
                                        width: 800,
                                        height: 600,
                                        closeOnEscape: true,
                                        close: function( event, ui ) {
                                            pm.unbind('latlngMessage');
                                        },

                                        // reset to defaults
                                        minWidth: 150,
                                        maxWidth: false,
                                        minHeight: 150,
                                        maxHeight: false,
                                        buttons: null,
                                        title: ''
                                    });
                                pm.bind("latlngMessage", function(data) {
                                    pm.unbind('latlngMessage');
                                    $( "#modal-window").dialog('close');
                                    setCoordinatesInList(data.lat, data.lng, $(objGeoInpLat), $(objGeoInpLng), true);
                                });
                            }
                        })
                        .appendTo(rowWrap);


                    var elemBtnRemoveEntry = document.createElement("a");
                    $(elemBtnRemoveEntry)
                        .addClass("btn-small btn-dark")
                        .html("<span>"+IG.label.get('global_remove')+"</span>")
                        .click(function(event) {
                            $(rowWrap).children().hide(50, function() {}); // avoid some flicker
                            $(rowWrap).hide(200, function() {
                                $(rowWrap).remove();
                                updateGeoList();
                            })
                        })
                        .appendTo(rowWrap);

                    return rowWrap;
                }


                function setCoordinatesInList(lat, lng, fieldLat, fieldLng, updateInputFields) {

                    var rawLat = lat,
                        rawLng = lng;


                    if (typeof lat === 'undefined' || typeof lng === 'undefined') {
                        console.error('Latitude or longitude is not defined.');
                        return false;
                    }

                    updateInputFields = (typeof updateInputFields === 'undefined' ? false : updateInputFields);

                    try {
                        if ((lat+'').indexOf(',') >= 0) {
                            lat = lat.replace(",", ".");
                        }
                        lat = parseFloat(lat);

                        if ((lng+'').indexOf(',') >= 0) {
                            lng = lng.replace(",", ".");
                        }
                        lng = parseFloat(lng);
                    } catch (error) {
                        console.log('ERROR', error);
                    }

                    // if only one of the fields is set when setCoordinatesInList is called, set the other one to 0.0:
                    if ( (isNaN(rawLat) && rawLat.length > 0) || (isNaN(rawLng) && rawLng.length > 0) ) {
                        // both fields are empty, so set the value to null and return
                        hasError = true;
                        IG.showErrorDialog(IG.label.get('EDIT_INFOOBJECT_GEO_INVALID_COORDINATES'), function() {
                            hasError = false;

                            if (isNaN(rawLat) && rawLat.length > 0) {
                                fieldLat.focus();
                            } else if (isNaN(rawLng) && rawLng.length > 0) {
                                fieldLng.focus();
                            }

                        });
                        return false;
                    } else if (!isNaN(lat) && isNaN(lng)) {
                        lng = 0.0;
                        fieldLng.val(lng);
                    } else if (isNaN(lat) && !isNaN(lng)) {
                        lat = 0.0;
                        fieldLat.val(lat);
                    }  else if (isNaN(lat) && isNaN(lng)) {
                        // both fields are empty, so set the value to null and return
                        updateGeoList();
                        return true;
                    }

                    if (!isNaN(lat) && !isNaN(lng) && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {

                        if (updateInputFields) {
                            fieldLat.val(lat);
                            fieldLng.val(lng);
                        }
                        updateGeoList();
                    } else {

                        hasError = true;
                        IG.showErrorDialog(IG.label.get('EDIT_INFOOBJECT_GEO_INVALID_COORDINATES'), function() {
                            hasError = false;

                            if (lat < -90 || lat > 90) {
                                fieldLat.focus();
                            } else if (lng < -180 || lng > 180) {
                                fieldLng.focus();
                            }

                        });
                    }
                    return true;
                }

                function updateGeoList() {
                    // traverse the dom elements with coordinates and update the model:
                    var rows = $(objGeoRowsWrap).children('.geo-row-wrap');
                    if (rows && rows.length > 0) {
                        iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage] = []; // empty
                        iObject.itemHasChanged = true;

                        var latFloat = 0.0,
                            lngFloat = 0.0;

                        $.each(rows, function(i, row) {
                            if ($(row).find('.geo-inp-lat').length > 0 && $(row).find('.geo-inp-lng').length > 0) {
                                latFloat = $(row).find('.geo-inp-lat').val();
                                lngFloat = $(row).find('.geo-inp-lng').val();

                                if (!isNaN(latFloat) && !isNaN(lngFloat) && latFloat.length > 0 && lngFloat.length > 0) {
                                    iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage].push({
                                        lat: parseFloat(latFloat),
                                        lng: parseFloat(lngFloat)
                                    });

                                }

                            }

                        });
                    }
                }


                // On init
                var startValues = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][currentLanguage];
                if ($.isArray(startValues) && startValues.length > 0) {
                    $.each(startValues, function(i, val) {
                        $(objGeoRowsWrap).append(buildRow(val.lat, val.lng));
                    });
                } else {
                    $(objGeoRowsWrap).append(buildRow()); // just put an empty row
                }

                var fieldObj = new IG.InfoObjectField({
                    setLanguage: function(language) {
                        // Do clean up code if i.e. a new language has been added
                        if (typeof iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] === 'undefined') {
                            iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language] = [];
                        }

                        // empty the list
                        $(objGeoRowsWrap).html('');

                        // if it is an array of values:
                        var values = iObject.item.fieldGroups[fieldGroup.groupName][field.fieldName][language];
                        if ($.isArray(values) && values.length > 0) {
                            $.each(values, function(i, val) {
                                $(objGeoRowsWrap).append(buildRow(val.lat, val.lng));
                            });
                        } else {
                            // Build the first empty row on init:
                            $(objGeoRowsWrap).append(buildRow());
                        }
                    }
                });
                igFields.push(fieldObj);

                break;
        }

	};


};