import React from "react";

export const StatusRefreshButton = ({ title }) => {
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        location.reload();
      }}
      className="status-monitor__refresh-button"
      title={title}
    ></a>
  );
};
